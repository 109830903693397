import { useSuspenseQuery } from "@tanstack/react-query";
import bankAccountsQueryHooks, {
  ROOT_BANK_ACCOUNTS_QUERY_KEY,
} from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import generateFakeData from "modules/bank-accounts/utils/generateFakeData";
import getTotalBalanceOfBankAccounts from "modules/bank-accounts/utils/getTotalBalanceOfBankAccounts";
import { DEMO_BUSINESS_GUID } from "modules/demo-account/constants";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { startOfBankingDay } from "utils/date";

import getHistoryWithTodayForMultipleAccounts, {
  BANK_ACCOUNT_NUM_DAYS_HISTORY,
} from "../utils/getHistoryWithTodayForMultipleAccounts";

const useBankAccountBalanceHistory = () => {
  const unitApi = useUnitApi();

  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });

  const bankAccountsToIncludeInTotalBalance = openBankAccounts.filter(
    (account) => account.highbeamType.includeInTotalBalance
  );

  // Allows us to seed balance graph for the demo account
  const businessGuid = useBusinessGuid();

  const { data } = useSuspenseQuery({
    queryKey: [ROOT_BANK_ACCOUNTS_QUERY_KEY, { businessGuid }],
    queryFn: async () => {
      const responses = await Promise.all(
        bankAccountsToIncludeInTotalBalance.map((bankAccount) =>
          unitApi.accountsEndOfDay.list({
            since: startOfBankingDay()
              .subtract(BANK_ACCOUNT_NUM_DAYS_HISTORY, "days")
              .format("YYYY-MM-DD"),
            accountId: bankAccount.unitCoDepositAccountId,
          })
        )
      );

      // Due to time zone discrepancies between the local client and Unit.co's backend, this can
      // return more than BANK_ACCOUNT_NUM_DAYS_HISTORY. However, we should only show the last
      // BANK_ACCOUNT_NUM_DAYS_HISTORY.

      const histories = responses.map((unitAccountEndOfDay) =>
        unitAccountEndOfDay.data.slice(0, BANK_ACCOUNT_NUM_DAYS_HISTORY)
      );

      const bankAccountsTotalBalance = getTotalBalanceOfBankAccounts(openBankAccounts);
      const historyWithToday = getHistoryWithTodayForMultipleAccounts(
        histories,
        bankAccountsTotalBalance
      );

      if (businessGuid === DEMO_BUSINESS_GUID) {
        return generateFakeData(historyWithToday);
      }

      return historyWithToday;
    },
  });

  return data;
};

export default useBankAccountBalanceHistory;
