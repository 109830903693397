import { useMutationState, MutationState, DefaultError } from "@tanstack/react-query";
import useBill from "modules/bills/queries/useBill";
import BillSyncRep from "reps/BillSyncRep";

import { makeMutationKey } from "../mutations/useBillSyncSyncMutation";
import shouldBillSyncBeDryRun from "../utils/shouldBillSyncBeDryRun";

const useBillSyncRunState = (billId: string) => {
  const bill = useBill(billId, { required: true });
  const dryRun = shouldBillSyncBeDryRun(bill);
  const mutationKey = makeMutationKey(billId, dryRun);

  const mutationStates = useMutationState<
    MutationState<BillSyncRep.Complete, DefaultError, void, unknown>
  >({
    filters: { mutationKey },
  });

  // Return the last mutation state, which is the most recent one.
  return mutationStates.length > 0 ? mutationStates[mutationStates.length - 1] : null;
};

export default useBillSyncRunState;
