import dayjs from "dayjs";
import useCreateKnowledgeBaseItemMutation from "modules/chat/mutations/useCreateKnowledgeBaseItemMutation";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { notify } from "ui/feedback/Toast";
import useMountEffect from "utils/customHooks/useMountEffect";

import ChatKnowledgeBaseItem from "./ChatKnowledgeBaseItem";

type Props = {
  businessGuid: string;
  handleDone: () => void;
  handleCancel: () => void;
};

const NewChatKnowledgeBaseItem: React.FC<Props> = ({ businessGuid, handleDone, handleCancel }) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const createdAt = useMemo(() => dayjs().toISOString(), []);
  const [value, setValue] = useState("");

  useMountEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    });
  });

  const { mutate, isPending } = useCreateKnowledgeBaseItemMutation({
    onSuccess: () => {
      handleDone();
    },
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
  });

  const handleStartEdit = () => {};

  const handleEndEdit = () => {
    handleCancel();
  };

  const handleEdit = (value: string) => {
    setValue(value);
  };

  const handleSave = useCallback(() => {
    if (isPending) return;
    mutate({ businessGuid, value });
  }, [businessGuid, isPending, mutate, value]);

  return (
    <ChatKnowledgeBaseItem
      createdAt={createdAt}
      editable
      edited
      isPending={isPending}
      textAreaRef={ref}
      value={value}
      handleStartEdit={handleStartEdit}
      handleEndEdit={handleEndEdit}
      handleEdit={handleEdit}
      handleSave={handleSave}
    />
  );
};

export default NewChatKnowledgeBaseItem;
