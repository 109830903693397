import { captureException } from "@sentry/react";
import env from "env";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { useMemo } from "react";
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptionsWithLinkToken,
  usePlaidLink,
} from "react-plaid-link";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import { localStorageLinkTokenState } from "state/localStorage/linkToken";
import { notify } from "ui/feedback/Toast";

import { localStoragePostPlaidOauthRedirectPath } from "../../state/localStorage/postPlaidOauthRedirectPath";

import useHighbeamApi from "./useHighbeamApi";

/**
 * Callback fired when Link succeeds and finalization against our backend is ongoing.
 */
export type HighbeamPlaidLinkOnSuccess = (
  metadata: PlaidLinkOnSuccessMetadata,
  finalizationPromise: Promise<void>,
  existingConnectionBeingUpdated?: PlaidConnectionRep.Complete
) => void;

const useHighbeamPlaidLink = ({
  linkToken,
  onSuccess,
  onError,
  onEarlyExit,
  existingConnectionBeingUpdated,
}: {
  linkToken: string | null;
  onSuccess?: HighbeamPlaidLinkOnSuccess;
  /**
   * Callback fired when Link exits with an error.
   */
  onError?: (error: PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => void;
  onEarlyExit?: () => void;
  existingConnectionBeingUpdated?: PlaidConnectionRep.Complete;
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const oauthFlowNeedsCompletion = searchParams.get("oauth_state_id") !== null;
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const setExistingLinkToken = useSetRecoilState(localStorageLinkTokenState);
  const setPostPlaidOauthRedirectPath = useSetRecoilState(localStoragePostPlaidOauthRedirectPath);

  const config: PlaidLinkOptionsWithLinkToken = useMemo(() => {
    return {
      onSuccess: async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
        const finalizationPromise = highbeamApi.plaid.createAccessToken(
          businessGuid,
          publicToken,
          metadata,
          !existingConnectionBeingUpdated
        );
        onSuccess?.(metadata, finalizationPromise, existingConnectionBeingUpdated);
      },
      onExit: (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
        if (error) {
          captureException(new Error(`Plaid link failed for businessGuid=${businessGuid}`), {
            extra: {
              businessGuid,
              error,
              metadata,
            },
          });
          onError?.(error, metadata);
        } else {
          onEarlyExit?.();
        }

        notify("info", "Trouble connecting your account?", {
          action: {
            text: "Explore common issues",
            onClick: () => {
              window.open("https://plaid.com/trouble-connecting/", "_blank")?.focus();
            },
          },
        });
      },
      token: linkToken,
      env: env.PLAID_ENVIRONMENT,
      ...(oauthFlowNeedsCompletion && { receivedRedirectUri: window.location.href }),
    };
  }, [
    linkToken,
    oauthFlowNeedsCompletion,
    highbeamApi.plaid,
    businessGuid,
    existingConnectionBeingUpdated,
    onSuccess,
    onError,
    onEarlyExit,
  ]);

  const { open: openPlaid, ready: isPlaidReady } = usePlaidLink(config);

  // We want to set the link token in case it is an oauth flow
  const openHighbeamPlaid = () => {
    setExistingLinkToken(linkToken!); // Should always be set if `isPlaidReady` is true
    if (!oauthFlowNeedsCompletion) {
      setPostPlaidOauthRedirectPath(location.pathname);
    }
    openPlaid();
  };

  return {
    openPlaid: openHighbeamPlaid,
    isPlaidReady: isPlaidReady,
  };
};

export default useHighbeamPlaidLink;
