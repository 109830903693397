import HighYieldTeaserImage from "assets/teasers/high-yield-teaser.png";
import Emoji from "components/Emoji";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import Teaser from "ui/data-display/Teaser";
import ButtonLink from "ui/inputs/ButtonLink";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./HighYieldTeaser.module.scss";

type Props = {
  account: BankAccountRep.Complete;
};

const HighYieldTeaser: React.FC<Props> = ({ account }) => {
  return (
    <Teaser>
      <Teaser.MainSection>
        <Emoji className={styles.emoji}>💰</Emoji>
        <div className={styles.teaserText}>
          <Heading2>Earn up to 4.04% APY</Heading2>
          <Text size={16} color={colors.grey[500]}>
            Deposit money to your High yield account and grow funds on Highbeam!{" "}
          </Text>
        </div>

        <ButtonLink
          className={styles.teaserButton}
          variant="primary"
          to="/transfer-money"
          state={{ toAccountGuid: account.guid }}
        >
          Deposit now
        </ButtonLink>
      </Teaser.MainSection>
      <Teaser.Hero>
        <img
          src={HighYieldTeaserImage}
          alt="High yield teaser"
          title="High yield teaser"
          width="100%"
        />
      </Teaser.Hero>
    </Teaser>
  );
};

export default HighYieldTeaser;
