import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import getBankAccountDisplayName from "modules/bank-accounts/utils/getBankAccountDisplayName";
import HighYieldPage from "pages/accounts/HighYieldPage";
import { Navigate, useParams } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import AccountAuthorizationsTable from "./AccountAuthorizationsTable";
import AccountDetailsAccountTransactionsSection from "./AccountDetailsAccountTransactionsSection";
import AccountDetailsIncomingPaymentsSection from "./AccountDetailsIncomingPaymentsSection";
import AccountDetailsPageHeader from "./AccountDetailsPageHeader";
import AccountInformation from "./AccountInformation";

const AccountDetailsPage = () => {
  const params = useParams();
  const accountGuid = params.accountGuid!;

  const accounts = bankAccountsQueryHooks.useData({ status: "all" });
  const account = accounts.find((account) => account.guid === accountGuid);

  if (!account) {
    return <Navigate to="/accounts" />;
  }

  const accountPage =
    account.highbeamType.name === "HighYield" ? (
      <HighYieldPage account={account} />
    ) : (
      <>
        <DashboardHeader>
          <Breadcrumbs>
            <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
            <Breadcrumbs.CurrentItem>
              {getBankAccountDisplayName(account, { showMaskedAccountNumber: false })}
            </Breadcrumbs.CurrentItem>
          </Breadcrumbs>
        </DashboardHeader>

        <DashboardPage>
          <AccountDetailsPageHeader account={account} />
          <AccountInformation account={account} />
          <AccountDetailsIncomingPaymentsSection
            unitCoDepositAccountId={account.unitCoDepositAccountId}
          />
          <AccountAuthorizationsTable accountId={account.unitCoDepositAccountId} />
          <AccountDetailsAccountTransactionsSection account={account} />
        </DashboardPage>
      </>
    );

  return accountPage;
};

export default AccountDetailsPage;
