import AnimatedSpinner from "ui/feedback/AnimatedSpinner";

import TextCell from "../cells/TextCell";

const UpdatingIndicatorCell = () => {
  return (
    <TextCell>
      <AnimatedSpinner /> Updating
    </TextCell>
  );
};

export default UpdatingIndicatorCell;
