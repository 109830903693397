import useBill from "modules/bills/queries/useBill";
import { useCallback } from "react";
import BillSyncRep from "reps/BillSyncRep";
import { useEventBus } from "utils/event-bus";

import useBillSyncQuery from "./useBillSyncQuery";
import useBillSyncRunDispatcher from "./useBillSyncRunDispatcher";
import { BILL_SYNC_RUN_SETTLED_EVENT, BillSyncRunSettledEvent } from "./useBillSyncRunManager";
import useBillSyncRunState from "./useBillSyncRunState";
import useBillSyncViewIsEnabled from "./useBillSyncViewIsEnabled";

class BillSyncErrors extends Error {}

// Certain actions, like saving a draft bill or submitting a bill for payment,
// require that the bill syncs before proceeding. This hook returns an async function
// that can be awaited to ensure the bill syncs before proceeding.
//
// When the function is called, the Promise it returns is resolved or rejected based on the
// following conditions:
// - If bill sync is not enabled, the Promise is resolved with null.
// - If we have a bill sync record in the query cache and there is no bill sync run
//   currently in flight, the Promise is resolved with the bill sync record as long
//   as it doesn't have errors. If it does have errors, the Promise is rejected with
//   an instance of BillSyncErrors.
// - If there is a bill sync run currently in flight, the Promise is resolved or rejected
//   based on the outcome of the bill sync run.
const useWaitForBillSync = (billId: string) => {
  const eventBus = useEventBus();

  const isEnabled = useBillSyncViewIsEnabled();
  const billSyncRunState = useBillSyncRunState(billId);
  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const { data: billSync } = useBillSyncQuery(billId);
  const bill = useBill(billId, { required: true });
  const waitForBillSync = useCallback(() => {
    return new Promise<BillSyncRep.Complete | null>((resolve, reject) => {
      // If bill sync is not enabled or the bill is closed for accounting, resolve immediately.
      if (!isEnabled || bill.isClosedForAccounting) {
        resolve(null);
        return;
      }

      const settleWithBillSync = (billSync: BillSyncRep.Complete) => {
        if (billSync.errors.length > 0) {
          reject(new BillSyncErrors());
        } else {
          resolve(billSync);
        }
      };

      // If we have a bill sync record in the query cache and there is no bill sync run
      // in the mutation cache (or it is idle), resolve with the bill sync record.
      if (billSync && (!billSyncRunState || billSyncRunState.status === "idle")) {
        settleWithBillSync(billSync);
        return;
      }

      if (billSyncRunState) {
        const { status, data, error } = billSyncRunState;

        if (status === "success" && data) {
          settleWithBillSync(data);
          return;
        }
        if (status === "error") {
          reject(error);
          return;
        }
      }

      const billSyncRunSettledHandler = (event: BillSyncRunSettledEvent) => {
        const detail = (event as BillSyncRunSettledEvent).detail;

        if (detail.billId !== billId) return;

        eventBus.removeEventListener(
          BILL_SYNC_RUN_SETTLED_EVENT,
          billSyncRunSettledHandler as EventListener
        );

        const { data, error } = detail;

        if (error) {
          reject(error);
        } else if (data) {
          settleWithBillSync(data);
        } else {
          reject(new Error("Bill sync run settled with no data"));
        }
      };

      eventBus.addEventListener(
        BILL_SYNC_RUN_SETTLED_EVENT,
        billSyncRunSettledHandler as EventListener
      );

      if (!billSyncRunState || billSyncRunState.status !== "pending") {
        dispatchBillSync();
      }
    });
  }, [isEnabled, bill, billSync, billSyncRunState, eventBus, billId, dispatchBillSync]);

  return waitForBillSync;
};

export default useWaitForBillSync;
