import { usePayeeQuery } from "modules/payees/queries/usePayee";
import { FC } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import NotFoundCell from "ui/table/cells/NotFoundCell";
import TextCell from "ui/table/cells/TextCell";

type Props = {
  payeeGuid: string;
  shimmerClassName?: string;
};

const PayeeNameCell: FC<Props> = ({ payeeGuid, shimmerClassName }) => {
  const { data: payee, isLoading } = usePayeeQuery(payeeGuid);

  if (isLoading) {
    return <Shimmer className={shimmerClassName} />;
  }

  if (payee) {
    return (
      <TextCell className="font-medium" darken>
        {payee.name}
      </TextCell>
    );
  }

  return <NotFoundCell />;
};

export default PayeeNameCell;
