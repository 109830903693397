import React from "react";
import { TextChatElement } from "reps/chat/ChatElement";
import cn from "utils/tailwind/cn";

type Props = {
  element: TextChatElement;
};

const ResponseMessageText: React.FC<Props> = ({ element }) => {
  return <span className={cn({ "font-bold": element.bold })}>{element.content}</span>;
};

export default ResponseMessageText;
