import { useQueryClient } from "@tanstack/react-query";
import BillSyncRep from "reps/BillSyncRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillSyncApi from "../api/useBillSyncApi";
import billSyncQueryHooks from "../queries/billSyncQueryHooks";

export const makeMutationKey = (billId: string, dryRun: boolean) => [
  "bill-sync",
  { billId, dryRun },
];

const useBillSyncSyncMutation = (
  billId: string,
  dryRun: boolean,
  additionalOptions?: MutationAdditionalOptions<BillSyncRep.Complete, void>
) => {
  const billSyncApi = useBillSyncApi();
  const queryClient = useQueryClient();
  const billSyncQueryKey = billSyncQueryHooks.useQueryKey({ billId });

  return useMutationWithDefaults(
    {
      mutationKey: makeMutationKey(billId, dryRun),
      mutationFn: () => billSyncApi.sync(billId, dryRun),
      onSuccess: (data) => {
        queryClient.setQueriesData<BillSyncRep.Complete>({ queryKey: billSyncQueryKey }, data);
      },
    },
    additionalOptions || {}
  );
};

export default useBillSyncSyncMutation;
