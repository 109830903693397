import { Authorization } from "@highbeam/unit-node-sdk";
import TransactionsV2 from "components/TransactionsV2";
import { CARD_TRANSACTIONS_TABLE_DATA_PAGE_SIZE } from "config/constants/filters";
import dayjs from "dayjs";
import { CARD_TRANSACTIONS_TABLE_TABS } from "modules/card-transactions-table/constants";
import useDownloadCardTransactionsCsvMutation from "modules/card-transactions-table/mutations/useDownloadCardTransactionsCsvMutation";
import { CardTransactionsTableTransaction } from "modules/card-transactions-table/queries/useCardTransactionsTableData";
import getCounterpartyVendor from "modules/counterparty-vendor/utils/getCounterpartyVendor";
import CardReversalTransactionRelatedTransactionComponent from "modules/transactions/components/CardReversalTransactionRelatedTransactionComponent";
import { Suspense } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Shimmer from "ui/feedback/Shimmer";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import AmountCell from "ui/table/cells/AmountCell";
import CounterpartyCell from "ui/table/cells/CounterpartyCell";
import DateTimeCell from "ui/table/cells/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import { useCardDetailsPageContext } from "../../providers/CardDetailsPageProvider";
import useCardDetailsPageSearchParams from "../../state/useCardDetailsPageSearchParams";

import CardTransactionsTableNotesCell from "./components/CardTransactionsTableNotesCell";
import CardTransactionsTableReceiptCell from "./components/CardTransactionsTableReceiptCell";
import useCardDetailsPageCardTransactionsTableData from "./hooks/useCardDetailsPageCardTransactionsTableData";

const narrowColumnClasses = "tablet:max-w-20";

const columns: Column<CardTransactionsTableTransaction | Authorization>[] = [
  {
    title: "Date",
    cellRender: ({ attributes }) => <DateTimeCell date={attributes.createdAt} />,
    width: 120,
  },
  {
    title: "Vendor",
    cellRender: (datum) => {
      const counterpartyVendor = getCounterpartyVendor(datum);

      if (counterpartyVendor) {
        return (
          <CounterpartyCell>
            {counterpartyVendor.name}
            <CounterpartyCell.Logo src={counterpartyVendor.logo} alt={counterpartyVendor.name} />
          </CounterpartyCell>
        );
      }

      switch (datum.type) {
        case "authorization":
        case "cardTransaction":
        case "purchaseTransaction":
          return <CounterpartyCell>{datum.attributes.merchant.name}</CounterpartyCell>;
        case "atmTransaction":
          return <CounterpartyCell>{datum.attributes.atmName}</CounterpartyCell>;
        case "cardReversalTransaction":
          return (
            <CardReversalTransactionRelatedTransactionComponent cardReversalTransaction={datum}>
              {({ relatedTransaction }) => (
                <CounterpartyCell>{relatedTransaction.attributes.merchant.name}</CounterpartyCell>
              )}
            </CardReversalTransactionRelatedTransactionComponent>
          );
      }
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: (datum) => {
      switch (datum.type) {
        case "authorization":
          return <AmountCell cents={datum.attributes.amount} direction="negative" />;
        case "cardTransaction":
        case "purchaseTransaction":
        case "atmTransaction":
        case "cardReversalTransaction":
          return (
            <AmountCell
              cents={datum.attributes.amount}
              direction={datum.attributes.direction === "Credit" ? "positive" : "negative"}
            />
          );
      }
    },
  },
  {
    title: "Receipts",
    align: TableColumnAlignment.RIGHT,

    // HACK(alex): We hide this column by default because Authorizations currently can't have receipts. We override `headerClassName` when the active tab is `Completed` which makes the column visible for transactions.
    headerClassName: "hidden",
    cellClassName: (datum) => {
      return datum.type === "authorization" ? "hidden" : narrowColumnClasses;
    },
    cellRender: (datum) => {
      switch (datum.type) {
        case "authorization":
          return null; // HACK(alex): This never gets rendered because `hidden` hides the column entirely.
        case "cardTransaction":
        case "purchaseTransaction":
        case "atmTransaction":
        case "cardReversalTransaction":
          return <CardTransactionsTableReceiptCell transaction={datum} />;
      }
    },
  },
  {
    title: "Notes",
    align: TableColumnAlignment.RIGHT,

    // HACK(alex): We hide this column by default because Authorizations currently can't have notes. We override `headerClassName` when the active tab is `Completed` which makes the column visible for transactions.
    headerClassName: "hidden",
    cellClassName: (datum) => {
      return datum.type === "authorization" ? "hidden" : narrowColumnClasses;
    },
    cellRender: (datum) => {
      switch (datum.type) {
        case "authorization":
          return null; // HACK(alex): This never gets rendered because `hidden` hides the column entirely.
        case "cardTransaction":
        case "purchaseTransaction":
        case "atmTransaction":
        case "cardReversalTransaction":
          return <CardTransactionsTableNotesCell transaction={datum} />;
      }
    },
  },
];

const SearchBar = () => {
  // TODO: Debounce the underlying search required
  const { search, setSearch, activeTab } = useCardDetailsPageSearchParams();
  const shouldDisableSearch = activeTab !== "Completed";

  return (
    <TransactionsV2.SearchBar
      disabled={shouldDisableSearch}
      wide
      value={search}
      onChange={setSearch}
    />
  );
};

const DatePickers = () => {
  const { since, setSince, until, setUntil } = useCardDetailsPageSearchParams();

  return (
    <TransactionsV2.DateRangePickers
      fromPicker={
        <TransactionsV2.FromDatePicker
          fromDate={since.toDate()}
          toDate={until.toDate()}
          setFromDate={(date) => setSince(dayjs(date))}
        />
      }
      toPicker={
        <TransactionsV2.ToDatePicker
          fromDate={since.toDate()}
          toDate={until.toDate()}
          setToDate={(date) => setUntil(dayjs(date))}
        />
      }
    />
  );
};

const PaginationInfo = () => {
  const { pagination } = useCardDetailsPageCardTransactionsTableData();

  return (
    <TransactionsV2.PaginationInfo
      count={Math.min(pagination.total, CARD_TRANSACTIONS_TABLE_DATA_PAGE_SIZE)}
      totalCount={pagination.total}
    />
  );
};

const TransactionsPageIndicator = () => {
  const { pagination } = useCardDetailsPageCardTransactionsTableData();
  const { page, setPage } = useCardDetailsPageSearchParams();
  const totalPages = Math.ceil(pagination.total / CARD_TRANSACTIONS_TABLE_DATA_PAGE_SIZE);
  if (totalPages <= 1) return null;

  return (
    <TransactionsV2.PageIndicator
      currentPage={page - 1}
      setCurrentPage={(value) => setPage(value + 1)}
      totalPages={totalPages}
    />
  );
};

const AllCardsTransactionsTable = () => {
  const { data } = useCardDetailsPageCardTransactionsTableData();

  // TODO(alex): Temporary hack. We can delete this once we stop using the `HighbeamCardTransaction` type.
  const { activeTab } = useCardDetailsPageSearchParams();
  const { card } = useCardDetailsPageContext();

  const navigate = useNavigate();
  const [params] = useSearchParams();

  return (
    <TransactionsV2.TransactionsTable
      data={data}
      rowKey={({ id }) => id}
      columns={
        activeTab === "Completed"
          ? columns.map((col) => ({ ...col, headerClassName: narrowColumnClasses })) // HACK(alex): See the HACK note in the `columns` definition.
          : columns
      }
      className="table-auto" // NB(alex): Prevents columns from all being the same size.
      onRowClick={(transaction) => {
        switch (activeTab) {
          case "Completed":
            const accountId = transaction.relationships.account.data.id;
            return navigate(
              `/cards/${card.id}/completed/${accountId}/${transaction.id}?${params}`,
              {
                state: DISABLE_SCROLL_TO_TOP_STATE,
              }
            );
          case "Canceled":
            return navigate(`/cards/${card.id}/canceled/${transaction.id}?${params}`, {
              state: DISABLE_SCROLL_TO_TOP_STATE,
            });
          case "Declined":
            return navigate(`/cards/${card.id}/declined/${transaction.id}?${params}`, {
              state: DISABLE_SCROLL_TO_TOP_STATE,
            });
        }
      }}
    />
  );
};

const FilterTabs = () => {
  const { activeTab, setActiveTab } = useCardDetailsPageSearchParams();

  return (
    <TransactionsV2.FilterTabs
      tabs={CARD_TRANSACTIONS_TABLE_TABS}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

const ExportButton = () => {
  const { card } = useCardDetailsPageContext();
  const { activeTab } = useCardDetailsPageSearchParams();
  const cardLast4Digits = card.attributes.last4Digits;
  const { search, since, until } = useCardDetailsPageSearchParams();

  const { mutate: download, isPending } = useDownloadCardTransactionsCsvMutation();

  const handleClick = async () => {
    download({
      cardId: card.id,
      cardLast4Digits: cardLast4Digits,
      search: search,
      since: since,
      until: until,
    });
  };

  if (activeTab !== "Completed") {
    return null;
  }

  return <TransactionsV2.ExportButton isLoading={isPending} onClick={handleClick} />;
};

const CardTransactionsTable = () => {
  return (
    <Suspense fallback={<Table.Loading columns={columns.map(({ title }) => ({ title }))} />}>
      <TransactionsV2
        title={<TransactionsV2.Title>Transactions</TransactionsV2.Title>}
        table={
          <Suspense fallback={<Table.Loading columns={columns.map(({ title }) => ({ title }))} />}>
            <AllCardsTransactionsTable />
          </Suspense>
        }
        filters={
          <TransactionsV2.Filters
            searchBar={<SearchBar />}
            dateRangePickers={<DatePickers />}
            paginationInfo={
              <Suspense fallback={<Shimmer />}>
                <PaginationInfo />
              </Suspense>
            }
            filterTabs={<FilterTabs />}
            exportButton={<ExportButton />}
          />
        }
        footer={
          <TransactionsV2.Footer
            pageIndicator={
              <Suspense fallback={<Shimmer />}>
                <TransactionsPageIndicator />
              </Suspense>
            }
          />
        }
      />
    </Suspense>
  );
};

export default CardTransactionsTable;
