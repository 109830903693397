import { CreditCard } from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import colors from "styles/colors";

import CashAccessLineIcon from "./assets/cash-access-line-icon.svg?react";

// NB(alex): This component needs to be audited / is still a WIP.

type Props = {
  capitalAccountType: CapitalAccountRep.Type;
  size?: number;
};

const CapitalAccountIcon: FC<Props> = ({ capitalAccountType, size = 24 }) => {
  switch (capitalAccountType) {
    case CapitalAccountRep.Type.ChargeCardOnly:
      return <CreditCard weight="light" color={colors.purple[500]} size={size} />;
    case CapitalAccountRep.Type.CashAccessOnly:
      // NB(alex): It would be nice to get a `CashAccessLineIcon` that doesn't have this extra padding.
      return <CashAccessLineIcon width={size} height={size} />;
  }
};

export default CapitalAccountIcon;
