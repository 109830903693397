import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const KNOWLEDGE_BASE_ITEMS_QUERY_KEY = "knowledgeBaseItems";

const useKnowledgeBaseItemsQueryKey = (businessGuid: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [KNOWLEDGE_BASE_ITEMS_QUERY_KEY, { businessGuid }],
    queryFn: () => {
      return highbeamApi.knowledgeBaseItem.listByBusiness(businessGuid);
    },
  });
};

const useKnowledgeBaseItems = (businessGuid: string) => {
  const { data } = useSuspenseQuery(useKnowledgeBaseItemsQueryKey(businessGuid));
  if (!data) throw new RequiredButNotFoundError();
  return data;
};

export default useKnowledgeBaseItems;
