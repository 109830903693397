import { useSuspenseQuery } from "@tanstack/react-query";
import { UNIT_CO_CHECK_DEPOSITS_QUERY_KEY } from "modules/unit-co-check-deposits/queries/useUnitCoCheckDepostsQueryOptions";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { FC, ReactNode, createContext, useContext } from "react";

type CheckDepositFlexpaneContextState = {
  checkDepositId: string;
  onClose: () => void;
};

const CheckDepositFlexpaneContext = createContext<CheckDepositFlexpaneContextState>(
  {} as CheckDepositFlexpaneContextState
);

type Props = {
  children: ReactNode;
  checkDepositId: string;
  onClose: () => void;
};

const CheckDepositFlexpaneProvider: FC<Props> = ({ children, checkDepositId, onClose }) => {
  return (
    <CheckDepositFlexpaneContext.Provider
      value={{
        checkDepositId: checkDepositId,
        onClose: onClose,
      }}
    >
      {children}
    </CheckDepositFlexpaneContext.Provider>
  );
};

export default CheckDepositFlexpaneProvider;

export const useCheckDepositFlexpaneContext = () => {
  const { checkDepositId, onClose } = useContext(CheckDepositFlexpaneContext);
  const unitApi = useUnitApi();

  const { data: checkDeposit } = useSuspenseQuery({
    queryKey: [UNIT_CO_CHECK_DEPOSITS_QUERY_KEY, { checkDepositId }],
    queryFn: async () => {
      const { data } = await unitApi.checkDeposits.get(checkDepositId);
      return data;
    },
  });

  return {
    checkDeposit,
    onClose,
  };
};
