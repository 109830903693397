import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import BillSummaryRep from "reps/BillSummaryRep";

type BillSummaryApi = {
  get: (billId: string) => Promise<BillSummaryRep.Complete | null>;
  search: (businessGuid: string) => Promise<BillSummaryRep.Complete[]>;
};

const builder: ApiBuilder<BillSummaryApi> = (api) => ({
  get: async (billId) => {
    const url = `/accounts-payable/bills/${billId}/summary`;
    return await api.get<BillSummaryRep.Complete>(url);
  },

  search: async (businessGuid) => {
    const qp = new URLSearchParams({ businessGuid: businessGuid });

    const url = `/accounts-payable/bills/summary?${qp}`;
    return (await api.get<BillSummaryRep.Complete[]>(url))!;
  },
});

const useBillSummaryApi = () => useBackendV2Api(builder);

export default useBillSummaryApi;
