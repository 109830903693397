import { zodResolver } from "@hookform/resolvers/zod";
import { CalendarBlank as CalendarBlankIcon } from "@phosphor-icons/react";
import dayjs, { Dayjs } from "dayjs";
import useRequiredActiveAccountingPlatformConnection from "modules/accounting-platforms/queries/useRequiredActiveAccountingPlatformConnection";
import { FC, useCallback, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import ApSettingsRep from "reps/ApSettingsRep";
import RutterConnectionRep from "reps/RutterConnectionRep";
import { notify } from "ui/feedback/Toast";
import DatePicker from "ui/inputs/DatePicker";
import Helper from "ui/inputs/Helper";
import ModalV4 from "ui/overlay/ModalV4";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import { formatDate, dateOnly } from "utils/date";
import { getRutterIcon } from "utils/rutter";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";
import cn from "utils/tailwind/cn";
import { z } from "zod";

import useUpdateApSettingsMutation from "../../mutations/useUpdateApSettingsMutation";
import { useApSettings } from "../../queries/apSettingsQueryHooks";

export type UpdateBooksClosedDateSchemaParams = {
  apSettings: ApSettingsRep.Complete;
  accountingPlatformConnection: RutterConnectionRep.RutterAccountingPlatformComplete;
};

export const makeUpdateBooksClosedDateFormSchema = (params: UpdateBooksClosedDateSchemaParams) => {
  const { apSettings, accountingPlatformConnection } = params;
  const { accountingPlatformCloseBooksDate } = apSettings;
  const accountingSoftwareName = getAccountingSoftwareName(
    accountingPlatformConnection.platformName
  );

  return z.object({
    closeBooksDate: z
      .custom<Dayjs | null>()
      .refine((value) => {
        if (accountingPlatformCloseBooksDate && !value) {
          return false;
        }
        // Allow null only if we don't have a books closed date from the accounting platform.
        return true;
      }, "Please set a books closed date.")
      .refine(
        (value) => {
          if (!accountingPlatformCloseBooksDate || !value) {
            return true;
          }
          return (
            value.isSame(accountingPlatformCloseBooksDate, "day") ||
            value.isAfter(accountingPlatformCloseBooksDate, "day")
          );
        },
        `You have a synced books closed date on ${formatDate(accountingPlatformCloseBooksDate, "MMM D")} from ${accountingSoftwareName}. You cannot set your books closed date to any day before ${formatDate(accountingPlatformCloseBooksDate, "MMM D")}.`
      ),
  });
};

type UpdateBooksClosedDateFormInputs = z.infer<
  ReturnType<typeof makeUpdateBooksClosedDateFormSchema>
>;

type BooksClosedDateSyncedInfoProps = {
  apSettings: ApSettingsRep.Complete;
  accountingPlatformConnection: RutterConnectionRep.RutterAccountingPlatformComplete;
  className?: string;
};

const BooksClosedDateSyncedInfo: FC<BooksClosedDateSyncedInfoProps> = ({
  apSettings,
  accountingPlatformConnection,
  className,
}) => {
  const { accountingPlatformCloseBooksDate } = apSettings;
  const accountingPlatformName = accountingPlatformConnection.platformName;
  const accountingSoftwareName = getAccountingSoftwareName(accountingPlatformName);

  return (
    <div className={cn("flex items-center justify-between", className)}>
      <ModalV4.Paragraph className="flex items-center gap-2.5 text-sm font-medium">
        <img src={getRutterIcon(accountingPlatformName)} alt={`${accountingSoftwareName} logo`} />
        Date synced from {accountingSoftwareName}
      </ModalV4.Paragraph>
      <ModalV4.Paragraph className="flex items-center gap-2 text-sm font-medium text-grey-900">
        <CalendarBlankIcon size={16} />
        {formatDate(accountingPlatformCloseBooksDate)}
      </ModalV4.Paragraph>
    </div>
  );
};

type UpdateBooksClosedDateModalContentProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const UpdateBooksClosedDateModalContent: FC<UpdateBooksClosedDateModalContentProps> = ({
  onSuccess,
}) => {
  const { segmentTrack } = useSegment();

  const apSettings = useApSettings();
  const { id: apSettingsId, closeBooksDate, accountingPlatformCloseBooksDate } = apSettings;
  const accountingPlatformConnection = useRequiredActiveAccountingPlatformConnection();

  const formSchema = useMemo(
    () =>
      makeUpdateBooksClosedDateFormSchema({
        apSettings,
        accountingPlatformConnection: accountingPlatformConnection,
      }),
    [apSettings, accountingPlatformConnection]
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateBooksClosedDateFormInputs>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      closeBooksDate: closeBooksDate ? dayjs(closeBooksDate) : null,
    },
  });

  const { mutate: updateApSettings, isPending: isUpdatingApSettings } = useUpdateApSettingsMutation(
    apSettingsId,
    {
      onSuccess: () => {
        notify("success", "Books closed date updated");
        segmentTrack(SEGMENT_EVENTS.BILL_PAY_BOOKS_CLOSED_DATE_MANUALLY_SET);
        onSuccess();
      },
      onError: (error) => {
        notify("error", error.message ?? "Failed to update books closed date");
      },
    }
  );

  const onSubmit = useCallback(
    (data: UpdateBooksClosedDateFormInputs) => {
      updateApSettings({ closeBooksDate: data.closeBooksDate?.format("YYYY-MM-DD") ?? null });
    },
    [updateApSettings]
  );

  return (
    <ModalV4.Form onSubmit={handleSubmit(onSubmit)}>
      <ModalV4.Header>Books closed date</ModalV4.Header>
      <ModalV4.Body>
        {accountingPlatformCloseBooksDate && (
          <BooksClosedDateSyncedInfo
            apSettings={apSettings}
            accountingPlatformConnection={accountingPlatformConnection}
            className="pb-9"
          />
        )}
        <div>
          <Controller
            name="closeBooksDate"
            control={control}
            render={({ field, fieldState }) => {
              const { value } = field;
              return (
                <>
                  <DatePicker
                    label="Books closed date"
                    isClearable={!accountingPlatformCloseBooksDate}
                    disabled={field.disabled}
                    maxDate={dateOnly().toDate()}
                    value={value ? value.toDate() : null}
                    onChange={(selected) => {
                      field.onChange(selected ? dayjs(selected) : selected);
                    }}
                    className="leading-4" // NB(lev): DatePicker label/input is optically misaligned without this leading set.
                    variant="no-date"
                    onCalendarClose={() => {
                      field.onBlur();
                    }}
                  />
                  {fieldState.error && (
                    <Helper iconVariant="error">{fieldState.error.message}</Helper>
                  )}

                  {isValid && closeBooksDate && value && dayjs(closeBooksDate).isAfter(value) && (
                    <Helper iconVariant="warning">
                      If you set the books closed date to {formatDate(value)}, it will unlock
                      editing and syncing for any bills with invoice dates between{" "}
                      {formatDate(value, "MMM D")} and {formatDate(closeBooksDate, "MMM D")}.
                    </Helper>
                  )}
                  {isValid && closeBooksDate && !value && fieldState.isDirty && (
                    <Helper iconVariant="warning">
                      If you clear the books closed date, editing and syncing will be unlocked for
                      all bills.
                    </Helper>
                  )}
                </>
              );
            }}
          />
        </div>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton disabled={!isValid} isLoading={isUpdatingApSettings}>
          Save changes
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton>Close</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = UpdateBooksClosedDateModalContentProps;

const UpdateBooksClosedDateModal: FC<Props> = (props) => (
  <ModalV4 onClose={props.onClose}>
    <UpdateBooksClosedDateModalContent {...props} />
  </ModalV4>
);

export default Object.assign(UpdateBooksClosedDateModal, {
  BooksClosedDateSyncedInfo: BooksClosedDateSyncedInfo,
});
