import { ArrowUpRight } from "@phosphor-icons/react";
import React from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import { Paragraph } from "ui/typography";

type Props = {
  children: string;
  onClick: () => void;
};

const ChatSuggestion: React.FC<Props> = ({ children, onClick }) => {
  return (
    <VirtualButton
      className="flex w-fit flex-row items-center gap-3 rounded-2xl border border-grey-200 bg-grey-50 px-2 py-1"
      onClick={onClick}
    >
      <Paragraph className="text-xs">{children}</Paragraph>
      <ArrowUpRight size={12} className="ml-auto" />
    </VirtualButton>
  );
};

export default ChatSuggestion;
