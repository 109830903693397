import { PlusCircle } from "@phosphor-icons/react";
import React from "react";
import ButtonLink from "ui/inputs/ButtonLink";
import { Span } from "ui/typography";

const ChatConversationNewChatButton: React.FC = () => {
  return (
    <ButtonLink
      variant="default"
      to="/chat"
      className="flex shrink-0 flex-row items-center gap-1 px-4 text-sm text-purple-500"
    >
      <PlusCircle size={16} />
      <Span className="text-inherit">New chat</Span>
    </ButtonLink>
  );
};

export default ChatConversationNewChatButton;
