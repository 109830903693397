import { Info } from "@phosphor-icons/react";
import plaidAccountsQueryHooks from "modules/plaid/queries/plaidAccountsQueryHooks";
import { useRefreshPlaidConnectionsQueries } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { usePlaidLinkToken } from "modules/plaid/queries/plaidLinkTokenQueryHooks";
import { Suspense } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import duplicatePlaidConnectionGuidState from "state/plaid/duplicateConnectionGuid";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import colors from "styles/colors";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";

const PlaidConnectionExistsModal = () => {
  const setIsPlaidExistingAccountModalOpen = useSetRecoilState(
    isPlaidExistingAccountModalOpenState
  );
  const duplicateConnectionGuid = useRecoilValue(duplicatePlaidConnectionGuidState);
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQueries();
  const refreshPlaidAccountsQuery = plaidAccountsQueryHooks.useRefreshQueries();
  const linkToken = usePlaidLinkToken({ existingConnectionGuid: duplicateConnectionGuid });

  const closeModal = () => {
    setIsPlaidExistingAccountModalOpen(false);
    refreshPlaidConnectionsQuery();
    refreshPlaidAccountsQuery();
  };
  const { openPlaid } = useHighbeamPlaidLink({
    linkToken,
    onSuccess: closeModal,
  });

  return (
    <Suspense fallback={null}>
      <Modal
        icon={<Info weight="fill" color={colors.blue} size={24} />}
        title="Connection already exists"
        buttonText="Continue"
        focusPrimaryButton
        showCancel
        onClick={openPlaid}
        isLoading={false}
        isPrimaryButtonDisabled={false}
        onClose={closeModal}
      >
        <Text size={14}>
          You have already connected this bank. Please click continue to add any new accounts to the
          existing connection.
        </Text>
      </Modal>
    </Suspense>
  );
};

export default PlaidConnectionExistsModal;
