import InternationalWireTransactionDetailsSection from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneDetailsSection/InternationalWireTransactionDetailsSection";
import { usePaymentInfoFlexpaneContext } from "dialogs/PaymentInfoFlexpane/context/PaymentInfoFlexpaneProvider";
import getIsInternationalWireUnitCoPayment from "modules/unit-co-payments/utils/getIsInternationalWireUnitCoPayment";

import AchPaymentDetailsSection from "../PaymentDetails/AchPaymentDetailsSection";
import WirePaymentDetailsSection from "../PaymentDetails/WirePaymentDetailsSection";

const PaymentInfoFlexpaneDetailsSection: React.FC = () => {
  const { payment } = usePaymentInfoFlexpaneContext();

  if (getIsInternationalWireUnitCoPayment(payment)) {
    return (
      <InternationalWireTransactionDetailsSection
        addenda={payment.attributes.description}
        internationalPaymentMetadataGuid={payment.attributes.tags.paymentMetadataGuid}
      />
    );
  } else if (payment.type === "achPayment") {
    return <AchPaymentDetailsSection payment={payment} />;
  } else if (payment.type === "wirePayment") {
    return <WirePaymentDetailsSection payment={payment} />;
  } else {
    return null;
  }
};

export default PaymentInfoFlexpaneDetailsSection;
