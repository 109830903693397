import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoAuthorizationsQueryOptions from "modules/unit-co-transactions/queries/useUnitCoAuthorizationsQueryOptions";
import useSearchParamNumber from "utils/search-params/useSearchParamNumber";

export const useCurrentPageSearchParam = () => useSearchParamNumber("pendingPage", 1);

export const AUTHORIZATIONS_PAGE_SIZE = 10;

type Params = {
  accountType?: "deposit" | "credit";
};

const useCardAuthorizations = (params?: Params) => {
  const [currentPage] = useCurrentPageSearchParam();

  const { data } = useSuspenseQuery(
    useUnitCoAuthorizationsQueryOptions({
      status: "Authorized",
      offset: AUTHORIZATIONS_PAGE_SIZE * (currentPage - 1),
      limit: AUTHORIZATIONS_PAGE_SIZE,
      includeNonAuthorized: true,
      ...(params?.accountType
        ? {
            filter: {
              accountType: params?.accountType,
            },
          }
        : {}),
    })
  );

  return data;
};

export default useCardAuthorizations;
