import { useApSettings } from "modules/ap-settings/queries/apSettingsQueryHooks";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";

// This hook returns a boolean indicating whether the bill sync feature should be
// visible to the user in the UI, based on the business's accounting sync settings
// as well as the user's role/permissions.
// Note that the actual running of bill syncs (as controlled via the `useBillSyncRunManager` hook),
// performs additional bill-specific logic to determine if the bill sync should be run.
// For example, we don't run bill syncs for bill that are closed for accounting,
// even if the accounting sync is enabled.
const useBillSyncViewIsEnabled = () => {
  const { accountingSyncEnabled } = useApSettings();

  // Don't run bill syncs if we're showing the employee view of a bill
  // (for employee > bill approvals).
  const shouldShowEmployeeView = useShouldShowEmployeeView();

  return shouldShowEmployeeView ? false : accountingSyncEnabled;
};

export default useBillSyncViewIsEnabled;
