import billSyncQueryHooks from "../queries/billSyncQueryHooks";

import useBillSyncViewIsEnabled from "./useBillSyncViewIsEnabled";

const useBillSyncQuery = (billId: string) => {
  const isEnabled = useBillSyncViewIsEnabled();

  return billSyncQueryHooks.useQuery({ billId, enabled: isEnabled });
};

export default useBillSyncQuery;
