import { Lightbulb } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import React from "react";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import ChatKnowledgeBase from "./ChatKnowledgeBase";

const ChatKnowledgeBasePage: React.FC = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/chat">AI Chat</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Knowledge Base</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.IconTile icon={<Lightbulb />} />
          <DashboardPage.Header.Title>
            [SUPERUSER] AI Chat: Knowledge Base
          </DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          <ChatKnowledgeBase />
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default ChatKnowledgeBasePage;
