/* eslint-disable functional/no-let */

import { ArrowLeft, PlusCircle, UserPlus, PencilSimpleLine } from "@phosphor-icons/react";
import { useRefreshBillLineItemsQuery } from "modules/bill-line-items/queries/useBillLineItems";
import useBillSyncRunDispatcher from "modules/bill-syncs/hooks/useBillSyncRunDispatcher";
import AchPaymentMethodFieldset from "modules/payee-payment-methods/components/AchPaymentMethodFieldset";
import DomesticWirePaymentMethodFieldset from "modules/payee-payment-methods/components/DomesticWirePaymentMethodFieldset";
import InternationalWirePaymentMethodRecoilFieldset from "modules/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset";
import useInternationalWirePaymentMethodRecoilHelpers from "modules/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset/useInternationalWirePaymentMethodRecoilHelpers";
import useAchPaymentMethodForm, {
  AchPaymentMethodFormInputs,
} from "modules/payee-payment-methods/forms/useAchPaymentMethodForm";
import useDomesticWirePaymentMethodForm, {
  DomesticWirePaymentMethodFormInputs,
} from "modules/payee-payment-methods/forms/useDomesticWirePaymentMethodForm";
import PayeeDetailsFieldset from "modules/payees/components/PayeeDetailsFieldset";
import usePayeeDetailsForm, {
  PayeeDetailsFormInputs,
} from "modules/payees/forms/usePayeeDetailsForm";
import useCreatePayeeMutation from "modules/payees/mutations/useCreatePayeeMutation";
import { FC, FormEvent, useCallback } from "react";
import PayeeRep from "reps/PayeeRep";
import Banner from "ui/data-display/Banner";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import Divider from "ui/data-display/Divider";
import UserAvatar from "ui/data-display/UserAvatar";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import SegmentControls from "ui/navigation/SegmentControls";
import Text from "ui/typography/Text";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import useUpdateBillMutation from "../../mutations/useUpdateBillMutation";
import useBill from "../../queries/useBill";
import { useRefreshBillPaymentMethodsQueries } from "../../queries/useBillPaymentMethods";
import useBillSuggestedPayee from "../../queries/useBillSuggestedPayee";
import useBillCreatePayeeFormDefaultPayeeName from "../../search-params/useBillCreatePayeeFormDefaultPayeeName";
import useBillDetailsPayeePaymentMethodTabs from "../../search-params/useBillDetailsPayeePaymentMethodTabs";

type Props = {
  billId: string;
  onBack: () => void;
  onSuccess: (createdPayee: PayeeRep.Complete) => void;
};

const BillCreatePayeeForm: FC<Props> = ({ onBack, onSuccess, billId }) => {
  const bill = useBill(billId, { required: true });
  const billInvoiceNumber = bill.invoiceNumber;
  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillPaymentMethodsQueries = useRefreshBillPaymentMethodsQueries();
  const suggestedPayee = useBillSuggestedPayee(billId);
  const achSuggestions = suggestedPayee?.paymentMethods.find(
    (paymentMethod) => paymentMethod.paymentMethod === "Ach"
  );

  const [defaultPayeeName] = useBillCreatePayeeFormDefaultPayeeName();

  const payeeDetailsForm = usePayeeDetailsForm({
    defaultValues: {
      payeeName: defaultPayeeName || suggestedPayee?.name || "",
      payeeEmail: suggestedPayee?.emailAddress || "",
      payeePhoneNumber: suggestedPayee?.phoneNumber || "",
    },
  });

  const achPaymentMethodForm = useAchPaymentMethodForm({
    suggestedPaymentMethod: achSuggestions ?? undefined,
  });

  const domesticWirePaymentMethodForm = useDomesticWirePaymentMethodForm({});

  const {
    internationalWireTransferMethod,
    isValidInternational,
    hasInputValues: hasInternationalInputValues,
  } = useInternationalWirePaymentMethodRecoilHelpers({
    payeeGuid: undefined,
  });

  const { segmentTrack } = useSegment();

  const { tabs, activeTab, setActiveTab } = useBillDetailsPayeePaymentMethodTabs("ach", {
    ach: {
      badge:
        !achPaymentMethodForm.isBlank() && !achPaymentMethodForm.formState.isValid
          ? "warning"
          : achSuggestions && "dot",
    },
    "domestic-wire": {
      badge:
        !domesticWirePaymentMethodForm.isBlank() &&
        !domesticWirePaymentMethodForm.formState.isValid &&
        "warning",
    },
    "international-wire": {
      badge: hasInternationalInputValues && !isValidInternational && "warning",
    },
  });

  const { mutateAsync: updateBill, isPending: isUpdatingBill } = useUpdateBillMutation(billId, {
    onError: () => {
      notify("error", "Something went wrong while updating the bill. Please try again.");
    },
  });

  const { mutateAsync: createPayee, isPending } = useCreatePayeeMutation({
    onSuccess: () => {
      segmentTrack(SEGMENT_EVENTS.BILL_PAY_PAYEE_CREATED);
    },
    onError: () => {
      notify("error", "Something went wrong while creating the payee. Please try again.");
    },
  });

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      let valid = true;
      let payeeDetailsFormData: PayeeDetailsFormInputs | null = null;

      try {
        payeeDetailsFormData = await new Promise<PayeeDetailsFormInputs>((resolve, reject) => {
          payeeDetailsForm.handleSubmit(resolve, reject)(e);
        });
      } catch (error) {
        valid = false;
      }

      const transferMethodBody = await (async () => {
        const body = {};
        let achPaymentMethodFormData: AchPaymentMethodFormInputs | null = null;
        let domesticWirePaymentMethodFormData: DomesticWirePaymentMethodFormInputs | null = null;

        if (!achPaymentMethodForm.isBlank()) {
          try {
            achPaymentMethodFormData = await new Promise<AchPaymentMethodFormInputs>(
              (resolve, reject) => {
                achPaymentMethodForm.handleSubmit(resolve, reject)(e);
              }
            );
            Object.assign(body, {
              achTransferMethod: {
                routingNumber: achPaymentMethodFormData.routingNumber,
                accountNumber: achPaymentMethodFormData.accountNumber,
              } satisfies PayeeRep.AchTransferMethod,
            });
          } catch (error) {
            valid = false;
          }
        }

        if (!domesticWirePaymentMethodForm.isBlank()) {
          try {
            domesticWirePaymentMethodFormData =
              await new Promise<DomesticWirePaymentMethodFormInputs>((resolve, reject) => {
                domesticWirePaymentMethodForm.handleSubmit(
                  // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
                  (data) => resolve(data as DomesticWirePaymentMethodFormInputs),
                  reject
                )(e);
              });

            Object.assign(body, {
              wireTransferMethod: {
                routingNumber: domesticWirePaymentMethodFormData.routingNumber,
                accountNumber: domesticWirePaymentMethodFormData.accountNumber,
              } satisfies PayeeRep.WireTransferMethod,
              // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
              address: {
                addressLine1: domesticWirePaymentMethodFormData.address.addressLine1?.value ?? "",
                addressLine2: domesticWirePaymentMethodFormData.address.addressLine2 || undefined,
                city: domesticWirePaymentMethodFormData.address.city,
                state: domesticWirePaymentMethodFormData.address.state?.value ?? "",
                zipCode: domesticWirePaymentMethodFormData.address.zipCode,
                country: domesticWirePaymentMethodFormData.address.country?.value ?? "",
              } satisfies PayeeRep.Address,
            });
          } catch (error) {
            valid = false;
          }
        }

        return body as Pick<
          PayeeRep.Creation,
          "achTransferMethod" | "wireTransferMethod" | "address"
        >;
      })();

      // HACK(alex): We're hacking this form to work with recoil state for international wire details, so we have to trigger form validation manually.
      if (
        activeTab === "international-wire" &&
        hasInternationalInputValues &&
        !isValidInternational
      ) {
        valid = false;
      }

      if (!valid || !payeeDetailsFormData) {
        return;
      }

      const createdPayee = await createPayee({
        name: payeeDetailsFormData.payeeName,
        emailAddress: payeeDetailsFormData.payeeEmail || undefined,
        phone: payeeDetailsFormData.payeePhoneNumber || undefined,
        defaultChartOfAccountId: payeeDetailsFormData.payeeDefaultChartOfAccountId || undefined,
        ...transferMethodBody,
        ...(activeTab === "international-wire" && internationalWireTransferMethod),
      });

      await updateBill({
        payeeGuid: createdPayee.guid,
      });

      refreshBillPaymentMethodsQueries();
      refreshBillLineItemsQuery();
      dispatchBillSync();

      onSuccess(createdPayee);
      notify("success", "Payee created");
    },
    [
      activeTab,
      hasInternationalInputValues,
      isValidInternational,
      createPayee,
      internationalWireTransferMethod,
      updateBill,
      refreshBillPaymentMethodsQueries,
      refreshBillLineItemsQuery,
      dispatchBillSync,
      onSuccess,
      payeeDetailsForm,
      achPaymentMethodForm,
      domesticWirePaymentMethodForm,
    ]
  );

  return (
    <form className="h-full" onSubmit={onSubmit}>
      <DetailsSidebarBody
        header={
          <DetailsSidebarBody.Header>
            <Button variant="ghost" paddingVariant="square" onClick={onBack}>
              <ArrowLeft size={24} weight="light" />
            </Button>

            <UserAvatar icon={<UserPlus />} size={44} color="grey" />

            <Text weight="medium" size={16} as="h3">
              Create payee
            </Text>
          </DetailsSidebarBody.Header>
        }
        main={
          <DetailsSidebarBody.Main>
            <DetailsSidebarBody.Section>
              {suggestedPayee && (
                <Banner
                  color="purple"
                  icon={<PencilSimpleLine size={24} />}
                  paragraph={
                    <>
                      We’ve pre-filled some of the payee info from{" "}
                      {billInvoiceNumber ? (
                        <>
                          invoice <Text as="strong">{billInvoiceNumber}</Text>
                        </>
                      ) : (
                        <>the invoice</>
                      )}
                      .
                    </>
                  }
                  className="mb-8"
                />
              )}

              <PayeeDetailsFieldset form={payeeDetailsForm} />
            </DetailsSidebarBody.Section>

            <Divider className="my-0" />

            <DetailsSidebarBody.Section>
              <Text size={14} weight="bold">
                Payment details
              </Text>

              <SegmentControls
                size="md"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={tabs}
                className="my-6"
              />

              {activeTab === "ach" && <AchPaymentMethodFieldset form={achPaymentMethodForm} />}
              {activeTab === "domestic-wire" && (
                <DomesticWirePaymentMethodFieldset form={domesticWirePaymentMethodForm} />
              )}
              {activeTab === "international-wire" && (
                <InternationalWirePaymentMethodRecoilFieldset />
              )}
            </DetailsSidebarBody.Section>
          </DetailsSidebarBody.Main>
        }
        footer={
          <DetailsSidebarBody.Footer className="flex flex-col gap-y-4">
            <Button type="submit" variant="primary" isLoading={isPending || isUpdatingBill}>
              <PlusCircle size={20} /> Create payee
            </Button>

            <Button variant="ghost" onClick={onBack}>
              Cancel
            </Button>
          </DetailsSidebarBody.Footer>
        }
      />
    </form>
  );
};

export default BillCreatePayeeForm;
