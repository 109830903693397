import { MINIMUM_REQUIREMENTS_CONTENT } from "modules/capital/dialogs/ApplicationRequirementsModal/ApplicationRequirementsSummary";
import { FC, ReactNode } from "react";
import CollapsibleAccordion from "ui/inputs/CollapsibleAccordion";
import { Heading3, Link, Paragraph } from "ui/typography";
import stringToReactKey from "utils/react-helpers/stringToReactKey";

type FaqType = {
  question: string;
  answer: ReactNode;
};

// NB(alex): We should figure out a way to have a single source of truth for these and to version control the FAQs.
// There are 4 different sources of truth for Capital FAQs:
// 1. Here
// 2. https://www.highbeam.co/capital
// 3. https://www.highbeam.co/faq#capital (meant to be a subset of https://www.highbeam.co/capital but currently isn't)
// https://www.highbeam.co/capital
// 4. The google doc we used as the initial source of truth for this FAQ (this one is more up-to-date now!) https://docs.google.com/document/d/1zGkbWp8aJZ1yO78J7ryXHtDSBRDasQ4IjE5MRDU8Wjc/edit?tab=t.0#heading=h.92x2nxbvwo8i
//
// Also worth noting there might be some marketing site only questions, such as "is banking required for highbeam capital".

const GENERAL_FAQS: FaqType[] = [
  {
    question: "What are the minimum requirements to qualify for capital?",
    answer: (
      <ol className="list-inside">
        <li>{MINIMUM_REQUIREMENTS_CONTENT.ecommRevenue}</li>
        <li>{MINIMUM_REQUIREMENTS_CONTENT.currentRatio}</li>
        <li>{MINIMUM_REQUIREMENTS_CONTENT.yearOverYearSalesTrend}</li>
        <li>{MINIMUM_REQUIREMENTS_CONTENT.cashRunway}</li>
        <li>{MINIMUM_REQUIREMENTS_CONTENT.shareholderEquity}</li>
      </ol>
    ),
  },
  {
    question: "What determines the limit?",
    answer: (
      <Paragraph>
        The amount is typically based on 1-1.5x of your sales minus any short-term debt. However,
        the final amount is adjusted depending on your business’s financial performance, including
        factors like profitability, cash flow, and overall financial health.
      </Paragraph>
    ),
  },
  {
    question: "How can I receive additional funding?",
    answer: (
      <Paragraph>
        If you have a line of credit with us, those are revolving so any payments made to us can
        always be redrawn. If you need additional funding beyond the amount you qualify for, you can
        request additional capital by submitting a new credit application.
      </Paragraph>
    ),
  },
  {
    question: "What are your fees?",
    answer: (
      <Paragraph>
        Highbeam is a no-fee banking platform. For credit, we believe in transparent pricing—there
        are no hidden charges. The only fee associated with our credit products is included in our
        clearly stated flat APR, and there are no prepayment penalties. Our APR is typically between
        18-24%.
      </Paragraph>
    ),
  },
  {
    question: "How is interest calculated?",
    answer: (
      <>
        <Paragraph>
          Interest is based on the annual percentage rate (APR) and accrues daily at a rate of APR ÷
          365.
        </Paragraph>
        <span className="mt-4 block">
          For example: For a $50,000 draw at 15% APR (approx. 1.25% monthly):
        </span>
        <ul className="list-inside list-disc">
          <li className="ml-0.5">
            Daily interest starts at $20.55/day (decreasing as you pay back)
          </li>
          <li className="ml-0.5">Total interest over 120 days: $1,243.15 (assuming no redraws)</li>
          <li className="ml-0.5">Total repayment: $51,243.15 ($427.03/day for 120 days)</li>
        </ul>
        <Paragraph className="mt-4">
          If you repay early, interest stops accruing immediately—saving you money. Unlike cash
          advance providers that charge upfront fees, Highbeam’s structure rewards early repayment.
          Learn more about cash advances here:{" "}
          <Link href="https://www.highbeam.co/blog/dont-rely-on-merchant-cash-advances-for-long-term-growth">
            Don’t Rely on Merchant Cash Advances for Long-Term Growth
          </Link>
          .
        </Paragraph>
      </>
    ),
  },
  {
    question: "Are there prepayment penalties?",
    answer: (
      <Paragraph>
        No, there are no prepayment penalties or hidden financing fees. At Highbeam, we prioritize
        transparent pricing — your only cost is the APR associated with your credit line. You can
        pay off your balance early without any additional charges.
      </Paragraph>
    ),
  },
  {
    // TODO: Do not show this on highbeam.co -- figure out how to version control with this in mind.
    question: "What are the requirements to maintain the Highbeam Capital?",
    answer: (
      <>
        <span className="block">There are a few requirements to maintain the line including:</span>
        <ul className="list-inside list-disc">
          <li className="ml-0.5">Primary operating banking on Highbeam</li>
          <li className="ml-0.5">Revenue flowing into Highbeam</li>
          <li className="ml-0.5">Quarterly financial reporting</li>
          <li className="ml-0.5">No missed payments</li>
          <li className="ml-0.5">
            Maintaining live connections to financial platforms such as external bank accounts and
            cards, stores, and accounting software.
          </li>
        </ul>
        <Paragraph className="mt-4">
          More detail can be found in your Highbeam Capital Agreement.
        </Paragraph>
      </>
    ),
  },
  {
    // TODO: Do not show this on highbeam.co -- figure out how to version control with this in mind.
    question: "What is the definition of “Primary operating banking”?",
    answer: (
      <ol className="list-inside list-decimal">
        <li>Revenue flowing into Highbeam bank accounts</li>
        <li>
          All payments made from Highbeam bank accounts, including but not limited to payroll, sales
          tax, credit cards, vendor, and supplier payments
        </li>
        <li>Minimum cash account balance as defined in your Highbeam Capital Agreement</li>
      </ol>
    ),
  },
  {
    question: "How does repayment work?",
    answer: (
      <>
        <span className="block">
          Repayment is structured as equal daily installments over 120 days for each draw you make
          from your credit line. For example:
        </span>
        <ul className="list-inside list-disc">
          <li className="ml-0.5">
            If you have $220K available, and you draw $120K, your daily repayment will be $1,000/day
            for 120 days.
          </li>
          <li className="ml-0.5">
            If you later draw an additional $100K, a new daily repayment of $833.33 will be added,
            bringing your total daily repayment to $1,833.33/day.
          </li>
        </ul>
        <Paragraph className="mt-4">
          Each draw creates its own 120-day repayment schedule, and daily payments reflect the
          combined total of all active draws.
        </Paragraph>
      </>
    ),
  },
  {
    question: "What is a revolving line of credit?",
    answer: (
      <Paragraph>
        A revolving line of credit allows you to borrow, repay, and then borrow again up to your
        approved limit during the draw period. For our credit lines, draw periods typically last 6
        months. For advances, the draw period is usually 30 days. Any amount repaid during the draw
        period becomes available to borrow again, offering flexibility to meet your financial needs.
      </Paragraph>
    ),
  },
  {
    question: "How long will my capital account be active?",
    answer: (
      <Paragraph>
        Highbeam Capital accounts typically have draw periods of 6 months, including Highbeam Cards.
        After the draw period, accounts will be refreshed. Limit sizing will be adjusted depending
        on credit and business financial performance.
      </Paragraph>
    ),
  },
  {
    question: "How often do you underwrite?",
    answer: (
      <Paragraph>
        Underwriting is done constantly on the account, but officially we underwrite once every
        quarter. Capital accounts won’t typically change but once every quarter.
      </Paragraph>
    ),
  },
  {
    question: "What can cause my capital limit to be decreased?",
    answer: (
      <Paragraph>
        In most cases, your limit will remain the same or grow as your business grows. However,
        limits may be decreased if there are significant changes to your business’s financial
        performance since the last review.
        <br />
        Capital limits are typically adjusted every 6 months, but a quarterly review may be
        conducted to assess financial health and performance.
      </Paragraph>
    ),
  },
  {
    question: "How can I access my monthly statements?",
    answer: (
      <Paragraph>
        Monthly statements are available for export as CSV from transactions. For help exporting,
        please reach out to <Link href="mailto:support@highbeam.co">support@highbeam.co</Link>.
      </Paragraph>
    ),
  },
  {
    question: "How do I manage my automatic repayments?",
    answer: (
      <Paragraph>
        All drawdowns are repaid over 120 days through daily automatic payments. If you need
        adjustments to this schedule, contact us at{" "}
        <Link href="mailto:capital@highbeam.co">capital@highbeam.co</Link>. In certain cases,
        temporary adjustments can be made. We’ll typically request your financials and a valid
        reason to review what options are available.
      </Paragraph>
    ),
  },
  {
    question: "Who can make draws and repayments on my account?",
    answer: (
      <Paragraph>
        Only admins are able to make draws on the account, and bookkeepers are able to make
        repayments.
      </Paragraph>
    ),
  },
  {
    question: "How long does it take to fund?",
    answer: (
      <Paragraph>
        It typically takes up 1-2 weeks from application to activation in order to receive funding.
        For active accounts, draws under $250K are funded immediately. Draws above that amount are
        typically immediate, but can sometimes take up to 1-2 business days.
      </Paragraph>
    ),
  },
];

const HIGHBEAM_CARDS_FAQS: FaqType[] = [
  {
    question: "How much cash back do I get?",
    answer: (
      <ul className="list-inside list-disc">
        {/* TODO: Get values from shared config */}
        <li className="ml-0.5">
          Cash Card: 2% cash back on all eligible ad spend, and 1% on everything else
        </li>
        <li className="ml-0.5">
          Flex Card: 1.5% cash back on all eligible ad spend, and 1% on everything else
        </li>
        <li className="ml-0.5">Extend Card: No cash back, but gets up to 60 days of float</li>
      </ul>
    ),
  },
  {
    question: "What is eligible ad spend?",
    answer: (
      <Paragraph>
        Eligible ad spend can be found at:{" "}
        <Link href="https://www.highbeam.co/legal/cashback-rewards-agreement">
          Cash back rewards agreement
        </Link>
        .
      </Paragraph>
    ),
  },
  {
    question: "When is cash back rewarded?",
    answer: (
      <Paragraph>Cash back is paid once a month at the end of each calendar month.</Paragraph>
    ),
  },
  {
    question: "How much free float do I get?",
    answer: (
      // TODO: Get these constants from a config.
      // TODO: Add `StatementCycleExplanation`?
      <ul className="ml-0.5 list-inside">
        <li className="ml-0.5">Cash Card: No float</li>
        <li className="ml-0.5">
          Flex Card: Up to 31 days of float. Monthly statement cycles, due one day after the
          statement period.
        </li>
        <li className="ml-0.5">
          Extend Card: Up to 60 days of float. Monthly statement cycles, due 30 days after the
          statement period.
        </li>
      </ul>
    ),
  },
  {
    // TODO: Do not show this on highbeam.co -- figure out how to version control with this in mind.
    question: "What fees are charged?",
    answer: (
      <Paragraph>
        There are no fees for Highbeam Cards. After the end of the current billing period, balances
        will become overdue and interest accruing based on the APR associated with your Highbeam
        Capital account.
      </Paragraph>
    ),
  },
  {
    question: "How does repayment work?",
    answer: (
      <Paragraph>
        At the end of each monthly statement cycle, your balance will become due 1 day or 30 days
        after the statement period. The balances will be paid off from your Highbeam or external
        bank accounts. If the payment is missed, the balance will become overdue. Balances that are
        overdue for more than 30 days can cause your card to be frozen.
      </Paragraph>
    ),
  },
  {
    question: "Do I get a physical card?",
    answer: (
      <Paragraph>
        Yes you will get a physical card and 15 virtual cards. As needed, you can request additional
        cards.
      </Paragraph>
    ),
  },
  {
    question:
      "What’s the difference between my Highbeam Capital limit and card spend limit (daily, monthly)? ",
    answer: (
      <>
        <Paragraph>
          Your Highbeam Capital limit is the total credit limit available across all of your cards.
          This is the maximum balance you can have out at once. You can set up multiple cards
          associated with your capital account, and the total balance across all cards would be
          limited to your Highbeam Capital limit.
        </Paragraph>
        <Paragraph className="mt-4">
          Separately, you can set up “spend limits” for each card to control the amount that is
          being spent on that card for any given time period (eg: daily, weekly, monthly). This is
          often used to control spend for a specific category, for example if you don’t want
          marketing spend to go over a certain amount each month. Spend limits are also often used
          for cards given to individual card holders to ensure they don’t spend over their allotted
          budget. Note that only admins are able to set and update spend limits.
        </Paragraph>
      </>
    ),
  },
];

type Props = {
  sections: ("general" | "highbeam-cards")[];
};

const CapitalFaqs: FC<Props> = ({ sections }) => {
  return (
    <>
      {sections.includes("general") && (
        <div>
          {GENERAL_FAQS.map((faq, index) => (
            <CollapsibleAccordion
              key={stringToReactKey(faq.question, index)}
              heading={faq.question}
            >
              <div className="text-sm leading-6">{faq.answer}</div>
            </CollapsibleAccordion>
          ))}
        </div>
      )}

      {sections.includes("highbeam-cards") && (
        <div>
          <Heading3 className="mb-1 mt-8">Highbeam Cards</Heading3>

          {HIGHBEAM_CARDS_FAQS.map((faq, index) => (
            <CollapsibleAccordion
              key={stringToReactKey(faq.question, index)}
              heading={faq.question}
            >
              <div className="text-sm leading-6">{faq.answer}</div>
            </CollapsibleAccordion>
          ))}
        </div>
      )}
    </>
  );
};

export default CapitalFaqs;
