import { Warning as WarningIcon } from "@phosphor-icons/react";
import { FC } from "react";
import CountBadge from "ui/data-display/CountBadge";
import { TabConfigBadge } from "utils/tabs/useTabState";

type Props = {
  badge: TabConfigBadge;
  isActive?: boolean;
};

const TabsBadge: FC<Props> = ({ badge, isActive }) => {
  if (typeof badge === "number") {
    return <CountBadge count={badge} backgroundColor={isActive ? "grey-700" : "grey-500"} />;
  }

  if (badge === "dot") {
    return <span aria-hidden="true" className="block size-1 rounded-full bg-purple-400" />;
  }

  if (badge === "warning") {
    return <WarningIcon role="alert" aria-label="!" size={16} className="text-red-400" />;
  }

  return <>{badge}</>;
};

export default TabsBadge;
