import { ClockClockwise } from "@phosphor-icons/react";
import Button from "ui/inputs/Button";
import useIsAllowedToSyncInsightsSheet from "utils/permissions/useIsAllowedToSyncInsightsSheet";

type Props = {
  onClick: () => void;
};

const CashFlowSyncSheetButton = ({ onClick }: Props) => {
  const isAllowedToSyncInsightsSheet = useIsAllowedToSyncInsightsSheet();
  return (
    <Button variant="secondary" disabled={!isAllowedToSyncInsightsSheet} onClick={onClick}>
      <ClockClockwise weight="light" size={24} />
      Sync to Google sheet
    </Button>
  );
};

export default CashFlowSyncSheetButton;
