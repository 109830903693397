import { useQuery } from "@tanstack/react-query";
import ConnectStores from "components/ConnectStores";
import MultiStep from "layouts/MultiStep";
import useConnectedStoresQueryOptions from "modules/connected-stores/queries/useConnectedStoresQueryOptions";
import { useNavigate } from "react-router-dom";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { SEGMENT_EVENTS, useTrackPageMount } from "utils/customHooks/useSegment";

import CreditApplicationBackButton from "../components/CreditApplicationBackButton";
import useGetNextPathname from "../hooks/useGetNextPathname";

const CONNECT_STORES_FORM_ID = "connect-stores-form";

const ConnectStoresView = () => {
  const navigate = useNavigate();
  const nextPathname = useGetNextPathname();
  const isSuperusering = useIsSuperusering();
  const allowSkipFeatureFlag = useFeatureFlag("CREDIT_ALLOW_SKIP_STORES");

  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname && connectedStores.length > 0) {
      navigate(nextPathname);
    }
  };

  useTrackPageMount(SEGMENT_EVENTS.CREDIT_APPLICATION_STARTED);

  return (
    <MultiStep.Form id={CONNECT_STORES_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Connected stores</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <ConnectStores redirectPathKey="capital" />
      </MultiStep.Section>

      <MultiStep.Controls>
        <CreditApplicationBackButton />

        <MultiStep.Controls.NextButton
          form={CONNECT_STORES_FORM_ID}
          disabled={connectedStores.length === 0}
        >
          Save and continue
        </MultiStep.Controls.NextButton>

        {/* An empty div is needed here to make sure MultiStep.Controls grid gets 3 children (Back button is null for this step) */}
        <div />

        {(isSuperusering || allowSkipFeatureFlag || process.env.NODE_ENV === "development") &&
          nextPathname && (
            <MultiStep.Controls.SkipButtonLink to={nextPathname} isSuperuserOnly={isSuperusering} />
          )}
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default ConnectStoresView;
