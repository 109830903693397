import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { ConversationSearch } from "reps/chat/ConversationSearch";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { CONVERSATIONS_QUERY_KEY } from "./useConversation";

export const useConversationsQueryOptions = () => {
  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [CONVERSATIONS_QUERY_KEY, { businessGuid, userGuid }],
    queryFn: () => {
      const search = { businessGuid, userGuid } satisfies ConversationSearch;
      return highbeamApi.conversation.search(search);
    },
  });
};

const useConversations = () => {
  const { data } = useSuspenseQuery(useConversationsQueryOptions());
  if (!data) throw new RequiredButNotFoundError();
  return data;
};

export default useConversations;
