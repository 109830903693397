import { ChatTeardropDots } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import { FC } from "react";
import colors from "styles/colors";
import VirtualButton from "ui/inputs/VirtualButton";
import { Paragraph } from "ui/typography";
import Text from "ui/typography/Text";

import OnboardingCard from "./OnboardingCard";

const OnboardingBankApplicationRejectedCard: FC = () => {
  const chat = useChatWidget();

  return (
    <OnboardingCard>
      <OnboardingCard.Header>
        <OnboardingCard.Heading1>Your banking application was not approved</OnboardingCard.Heading1>
      </OnboardingCard.Header>

      <OnboardingCard.Body className="pt-4">
        <Paragraph className="text-sm text-grey-600">
          Unfortunately, we cannot approve your Highbeam banking application. If you think there was
          a mistake or have questions, reach out to Highbeam support.
        </Paragraph>
      </OnboardingCard.Body>

      <OnboardingCard.Footer className="justify-start">
        <VirtualButton
          className="flex items-center gap-x-1"
          onClick={() => chat.message("I have a question about my banking application.")}
        >
          <ChatTeardropDots size={16} color={colors.purple[500]} />
          <Text size={12} weight="medium" color={colors.purple[500]}>
            Contact support
          </Text>
        </VirtualButton>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingBankApplicationRejectedCard;
