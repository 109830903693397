import useBusiness from "modules/business/queries/useBusiness";
import unitCoStatementsQueryHooks from "modules/unit-co-statements/queries/unitCoStatementsQueryHooks";
import Shimmer from "ui/feedback/ShimmerV2";
import Text from "ui/typography/Text";
import { pluralize } from "utils/string";

import styles from "./AccountsStatements.module.scss";

type Props = {
  accountId?: string;
};

const AccountStatementsCount: React.FC<Props> = ({ accountId }) => {
  const { unitCoCustomerId: customerId } = useBusiness();

  const { data, isLoading } = unitCoStatementsQueryHooks.useQuery({
    customerId: customerId ?? undefined,
    accountId,
  });

  if (isLoading) {
    return <Shimmer className="w-32" />;
  }

  const statements = data?.data ?? [];

  if (statements.length === 0) {
    return null;
  }

  return (
    <Text size={14} className={styles.count}>
      {pluralize(statements.length, "statement")} available.
    </Text>
  );
};

export default AccountStatementsCount;
