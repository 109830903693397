import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { UNIT_CO_TRANSACTIONS_QUERY_KEY } from "modules/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";

import { useTransactionInfoFlexpaneContext } from "../context/TransactionInfoFlexpaneProvider";

const useTransactionInfoFlexpaneTransaction = () => {
  const { unitCoDepositAccountId, transactionId } = useTransactionInfoFlexpaneContext();
  const unitApi = useUnitApi();

  const { data } = useSuspenseQuery({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, { unitCoDepositAccountId, transactionId }],
    queryFn: async () => {
      const response = await unitApi.transactions.get(unitCoDepositAccountId, transactionId);

      return response.data;
    },
  });

  return data;
};

export default useTransactionInfoFlexpaneTransaction;
