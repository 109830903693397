import useAccountingAccounts from "modules/accounting-accounts/queries/useAccountingAccounts";
import { useApSettings } from "modules/ap-settings/queries/apSettingsQueryHooks";
import { FC } from "react";

const PayeeDefaultChartOfAccountDescription: FC = () => {
  const { accountingSyncEnabled } = useApSettings();
  const accountingAccounts = useAccountingAccounts();
  const hasAccountingAccounts = accountingAccounts.length > 0;

  return (
    <p className="text-sm text-grey-600">
      {accountingSyncEnabled && hasAccountingAccounts ? (
        <>
          Choose a category from your accounting software. All new bills for this payee will be
          coded with this category by default, but you can edit them later.
        </>
      ) : (
        <>
          Please contact support to connect your accounting software to Highbeam Bill Pay. Once
          connected, you can choose a default accounting category for this payee.
        </>
      )}
    </p>
  );
};

export default PayeeDefaultChartOfAccountDescription;
