import React, { ReactNode } from "react";

import AiChatTableRow from "./AiChatTableRow";

type Props = {
  children: ReactNode;
};

const AiChatTableDataRow: React.FC<Props> = ({ children }) => {
  return <AiChatTableRow className="border-t">{children}</AiChatTableRow>;
};

export default AiChatTableDataRow;
