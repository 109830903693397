import {
  PlusCircle as PlusCircleIcon,
  Check as CheckIcon,
  X as XIcon,
} from "@phosphor-icons/react";
import ContactSupportToConnectAccounting from "modules/accounting-accounts/components/ContactSupportToConnectAccounting";
import useAccountingAccounts from "modules/accounting-accounts/queries/useAccountingAccounts";
import useActiveAccountingPlatformConnection from "modules/accounting-platforms/queries/useActiveAccountingPlatformConnection";
import { useApSettings } from "modules/ap-settings/queries/apSettingsQueryHooks";
import {
  BILL_INVOICE_UPLOADED_EVENT,
  BillInvoiceUploadedEvent,
} from "modules/bill-documents/mutations/useUploadBillInvoiceDocumentMutation";
import useCreateBillLineItemMutation from "modules/bill-line-items/mutations/useCreateBillLineItemMutation";
import inferBillLineItemCurrencyFromBill from "modules/bill-line-items/utils/inferBillLineItemCurrency";
import useBillSyncIsRunning from "modules/bill-syncs/hooks/useBillSyncIsRunning";
import useBillSyncQuery from "modules/bill-syncs/hooks/useBillSyncQuery";
import useBillSyncRunDispatcher from "modules/bill-syncs/hooks/useBillSyncRunDispatcher";
import useBillSyncRunEndpointError from "modules/bill-syncs/hooks/useBillSyncRunEndpointError";
import shouldBillSyncBeDryRun from "modules/bill-syncs/utils/shouldBillSyncBeDryRun";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import useBill from "modules/bills/queries/useBill";
import useUpdatePayeeForBillPayMutation from "modules/payees/mutations/useUpdatePayeeForBillPayMutation";
import { usePayeeQuery } from "modules/payees/queries/usePayee";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import BillSyncErrorRep from "reps/BillSyncErrorRep";
import PayeeRep from "reps/PayeeRep";
import RutterConnectionRep from "reps/RutterConnectionRep";
import Alert from "ui/feedback/Alert";
import Button from "ui/inputs/Button";
import { Paragraph, Strong, TextButton } from "ui/typography";
import { useEventBusDispatcher, useEventBusListener } from "utils/event-bus";
import { parseMoneyFloat, formatMoney } from "utils/money";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import { BILL_LINE_ITEM_UPDATED_EVENT, BillLineItemUpdatedEvent } from "../../events";
import { useRefreshBillLineItemsQuery } from "../../queries/useBillLineItems";
import BillLineItemsTable from "../BillLineItemsTable";

const SYNC_ERROR_MESSAGE_PREAMBLE = "Changes not synced to accounting.";

const renderSyncErrorMessagePreamble = (dryRun: boolean) =>
  dryRun ? "" : `${SYNC_ERROR_MESSAGE_PREAMBLE} `;

export const BILL_LINE_ITEMS_UPDATE_PAYEE_REQUESTED_EVENT = "billLineItemsEditPayeeRequested";

const makeBillLineItemsUpdatePayeeRequestedEvent = () =>
  new CustomEvent(BILL_LINE_ITEMS_UPDATE_PAYEE_REQUESTED_EVENT);

type BillSyncErrorAlertTextContentProps = {
  error: BillSyncErrorRep.Complete;
  bill: BillSummaryRep.Complete;
  accountingPlatformConnection: RutterConnectionRep.RutterAccountingPlatformComplete | null;
};

const BillSyncErrorAlertTextContent: FC<BillSyncErrorAlertTextContentProps> = ({
  error,
  bill,
  accountingPlatformConnection,
}) => {
  const { type, message } = error;
  const { payeeGuid, id: billId } = bill;
  const dryRun = shouldBillSyncBeDryRun(bill);
  const { data: payee } = usePayeeQuery(payeeGuid || undefined);
  const isSyncRunning = useBillSyncIsRunning(billId);
  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const dispatchEvent = useEventBusDispatcher();

  const resyncButton = (
    <TextButton disabled={isSyncRunning} onClick={() => dispatchBillSync()}>
      Resync
    </TextButton>
  );

  if (type === "BillPayeeMissing") {
    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}This bill does not have a payee. Please select from
        existing payees or add a new one.
      </>
    );
  }

  if (type === "LineItemAccountMissing") {
    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}Line items are missing accounting categories.{" "}
        {payee && !payee.defaultChartOfAccountId && (
          <>
            Select accounting categories or set a default category for <Strong>{payee.name}</Strong>
            .{" "}
            <TextButton onClick={() => dispatchEvent(makeBillLineItemsUpdatePayeeRequestedEvent())}>
              Edit payee details
            </TextButton>
            .
          </>
        )}
      </>
    );
  }

  if (type === "LineItemsTotalDiscrepancy") {
    const { billAmount, discrepancyAmount } = error;
    // A positive discrepancy amount means the line items total is greater than the bill amount.
    // A negative discrepancy amount means the line items total is less than the bill amount.
    const isGreaterThan = parseMoneyFloat(discrepancyAmount.amount) > 0;
    const formattedDiscrepancyAmount = formatMoney(discrepancyAmount, {
      showCurrencySymbol: true,
    });
    const formattedBillAmount = formatMoney(billAmount, {
      showCurrencySymbol: true,
      showTrailingCurrencyCode: true,
      dropZeroCents: false,
    });

    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}The line items total is{" "}
        <Strong>{formattedDiscrepancyAmount}</Strong> {isGreaterThan ? "greater" : "less"} than the
        bill amount of <Strong>{formattedBillAmount}</Strong>. Please edit line items or the bill
        amount so they match.
      </>
    );
  }

  if (type === "VendorNoLongerExists") {
    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}The payee in{" "}
        {accountingPlatformConnection
          ? getAccountingSoftwareName(accountingPlatformConnection.platformName)
          : "your accounting platform"}{" "}
        was either merged into another vendor or deleted. Please contact support to resolve.{" "}
        {resyncButton}
      </>
    );
  }

  if (type === "GenericError") {
    // For "GenericError" errors, the error message may come from Rutter.
    // Note that the Rutter error messages typically have a period at the end,
    // so we add one here conditionally.
    return (
      <>
        {renderSyncErrorMessagePreamble(dryRun)}
        {message}
        {message.endsWith(".") ? "" : "."} {resyncButton}
      </>
    );
  }

  return (
    <>
      {renderSyncErrorMessagePreamble(dryRun)}
      {message}.
    </>
  );
};

const BillSyncErrorAlertBase: FC<PropsWithChildren> = ({ children }) => (
  <Alert
    variant="warning"
    className="mb-4 last-of-type:border-b-grey-100 tablet:mb-0 tablet:last-of-type:border-b"
  >
    <Alert.Icon />
    <Alert.Text>{children}</Alert.Text>
  </Alert>
);

type BillSyncRunEndpointErrorAlertProps = {
  bill: BillSummaryRep.Complete;
};

const BillSyncRunEndpointErrorAlert: FC<BillSyncRunEndpointErrorAlertProps> = ({ bill }) => {
  const dryRun = shouldBillSyncBeDryRun(bill);
  const billId = bill.id;
  const isSyncRunning = useBillSyncIsRunning(billId);
  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  return (
    <BillSyncErrorAlertBase>
      {renderSyncErrorMessagePreamble(dryRun)}There was an error syncing this bill. Please try
      again.{" "}
      <TextButton disabled={isSyncRunning} onClick={() => dispatchBillSync()}>
        Resync
      </TextButton>
    </BillSyncErrorAlertBase>
  );
};

type BillSyncErrorAlertProps = {
  error: BillSyncErrorRep.Complete;
  bill: BillSummaryRep.Complete;
  accountingPlatformConnection: RutterConnectionRep.RutterAccountingPlatformComplete | null;
};

const BillSyncErrorAlert: FC<BillSyncErrorAlertProps> = ({
  error,
  bill,
  accountingPlatformConnection,
}) => (
  <BillSyncErrorAlertBase>
    <BillSyncErrorAlertTextContent
      error={error}
      bill={bill}
      accountingPlatformConnection={accountingPlatformConnection}
    />
  </BillSyncErrorAlertBase>
);

const dedupBillSyncErrors = (errors: BillSyncErrorRep.Complete[]) => {
  // We dedupe most error types because, if they occur multiple times, they are redundant.
  // This does *not* apply to all error types (e.g. "GenericError" errors).
  const seenErrorTypes = new Set<string>();

  return errors.filter((error) => {
    const { type } = error;

    if (type !== "GenericError") {
      if (seenErrorTypes.has(type)) {
        return false;
      }
      seenErrorTypes.add(type);
    }

    return true;
  });
};

const AccountingSyncNotEnabledAlert: FC = () => (
  <Alert className="mb-4 items-center border-b-grey-100 tablet:mb-0 tablet:border-b">
    <Alert.Icon />
    <Alert.Text>
      Please contact support to connect your accounting software to Highbeam Bill Pay.{" "}
      <ContactSupportToConnectAccounting />
    </Alert.Text>
  </Alert>
);

type ConfigurePayeeDefaultChartOfAccountPromptProps = {
  billId: string;
  payee: PayeeRep.Complete;
  accountingAccountId: string;
  onSuccess: () => void;
  onDismiss: () => void;
};

const ConfigurePayeeDefaultChartOfAccountPrompt: FC<
  ConfigurePayeeDefaultChartOfAccountPromptProps
> = ({ billId, payee, accountingAccountId, onSuccess, onDismiss }) => {
  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);

  const accountingAccounts = useAccountingAccounts();
  const accountingAccount = accountingAccounts.find(
    (accountingAccount) => accountingAccount.id === accountingAccountId
  );

  const { mutate: updatePayee, isPending: isUpdatingPayee } = useUpdatePayeeForBillPayMutation({
    onSuccess: async () => {
      await refreshBillLineItemsQuery();
      dispatchBillSync();
      onSuccess();
    },
  });

  const yes = useCallback(() => {
    updatePayee({
      payeeGuid: payee.guid,
      defaultChartOfAccountId: accountingAccountId,
    });
  }, [updatePayee, payee, accountingAccountId]);

  return (
    <div className="mb-4 flex items-center gap-4 last-of-type:border-b-grey-100 tablet:mb-0 tablet:p-4 tablet:last-of-type:border-b">
      <Paragraph className="text-sm">
        Set the default accounting category for <Strong>{payee.name}</Strong> to{" "}
        <Strong>{accountingAccount!.name}</Strong>?
      </Paragraph>
      <div className="inline-flex items-center gap-2">
        <Button size="xs" isLoading={isUpdatingPayee} onClick={() => yes()}>
          <CheckIcon className="size-4" />
          Yes
        </Button>

        {!isUpdatingPayee && (
          <Button variant="ghost" size="xs" onClick={() => onDismiss()}>
            <XIcon className="size-4" />
            No
          </Button>
        )}
      </div>
    </div>
  );
};

type Props = {
  billId: string;
};

const BillLineItemsContentFullView: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const currency = inferBillLineItemCurrencyFromBill(bill);
  const { payeeGuid } = bill;
  const { data: payee } = usePayeeQuery(payeeGuid || undefined);

  const { accountingSyncEnabled } = useApSettings();
  const accountingPlatformConnection = useActiveAccountingPlatformConnection();

  const { data: billSync } = useBillSyncQuery(billId);
  const billSyncErrorsToShow = useMemo(
    () => dedupBillSyncErrors(billSync?.errors ?? []),
    [billSync]
  );
  const hasBillSyncErrorsToShow = billSyncErrorsToShow.length > 0;
  const billSyncRunEndpointError = useBillSyncRunEndpointError(billId);

  const { mutate: createBillLineItem, isPending: isCreatingBillLineItem } =
    useCreateBillLineItemMutation(billId);

  const addItem = useCallback(() => {
    createBillLineItem({
      description: "",
      amount: {
        amount: "0",
        currency,
      },
      accountingAccountId: payee ? payee.defaultChartOfAccountId : null,
    });
  }, [createBillLineItem, currency, payee]);

  const [configurePayeeDefaultChartOfAccountState, setConfigurePayeeDefaultChartOfAccountState] =
    useState<{
      payee: PayeeRep.Complete;
      accountingAccountId: string;
    } | null>(null);

  const lineItemUpdatedHandler = useCallback(
    (event: BillLineItemUpdatedEvent) => {
      const { detail } = event;

      if (detail.billId !== billId) return;

      const { updater } = detail;
      const newAccountingAccountId = updater.accountingAccountId;

      if (newAccountingAccountId && payee && !payee.defaultChartOfAccountId) {
        setConfigurePayeeDefaultChartOfAccountState({
          payee,
          accountingAccountId: newAccountingAccountId,
        });
      }
    },
    [billId, payee]
  );

  useEventBusListener<BillLineItemUpdatedEvent>(
    BILL_LINE_ITEM_UPDATED_EVENT,
    lineItemUpdatedHandler
  );

  const refreshLineItemsQuery = useRefreshBillLineItemsQuery(billId);

  // Handler function to sync the bill line items form after a new invoice is uploaded.
  const invoiceUploadedHandler = useCallback(
    async (event: BillInvoiceUploadedEvent) => {
      if (event.detail.billId !== billId) return;

      refreshLineItemsQuery();
    },
    [billId, refreshLineItemsQuery]
  );

  useEventBusListener(BILL_INVOICE_UPLOADED_EVENT, invoiceUploadedHandler);

  return (
    <>
      {Boolean(billSyncRunEndpointError) && <BillSyncRunEndpointErrorAlert bill={bill} />}
      {!billSyncRunEndpointError && hasBillSyncErrorsToShow && (
        <div>
          {billSyncErrorsToShow.map((error) => (
            <BillSyncErrorAlert
              key={JSON.stringify(error)}
              error={error}
              bill={bill}
              accountingPlatformConnection={accountingPlatformConnection}
            />
          ))}
        </div>
      )}
      {!accountingSyncEnabled && <AccountingSyncNotEnabledAlert />}

      {configurePayeeDefaultChartOfAccountState && (
        <ConfigurePayeeDefaultChartOfAccountPrompt
          billId={billId}
          payee={configurePayeeDefaultChartOfAccountState.payee}
          accountingAccountId={configurePayeeDefaultChartOfAccountState.accountingAccountId}
          onSuccess={() => setConfigurePayeeDefaultChartOfAccountState(null)}
          onDismiss={() => setConfigurePayeeDefaultChartOfAccountState(null)}
        />
      )}

      <BillLineItemsTable billId={billId} />

      <div className="px-4 pb-2 pt-2">
        <Button
          paddingVariant="bare"
          size="sm"
          disabled={bill.isClosedForAccounting && bill.state !== BillRep.State.Draft}
          isLoading={isCreatingBillLineItem}
          onClick={() => addItem()}
        >
          <PlusCircleIcon size={16} />
          Add item
        </Button>
      </div>
    </>
  );
};

const BillLineItemsContentEmployeeView: FC<Props> = ({ billId }) => (
  <BillLineItemsTable billId={billId} />
);

const BillLineItemsContent: FC<Props> = ({ billId }) => {
  const shouldShowEmployeeView = useShouldShowEmployeeView();

  return (
    <div className="pb-2">
      {shouldShowEmployeeView ? (
        <BillLineItemsContentEmployeeView billId={billId} />
      ) : (
        <BillLineItemsContentFullView billId={billId} />
      )}
    </div>
  );
};

export default BillLineItemsContent;
