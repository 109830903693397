import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import { z } from "zod";

const repaymentOptions = [
  "total-balance",
  "current-statement-balance",
  "last-statement-balance",
  "overdue-balance",
  "custom",
] as const;

export type RepaymentOption = (typeof repaymentOptions)[number];

export const repayHighbeamCardsFormSchema = z.object({
  amount: z.number().positive(),
  repaymentOption: z
    .enum(repaymentOptions)
    .nullable()
    .refine((value) => value !== null, {
      message: "Please select a repayment option.",
    }),
  repaymentBankAccount: z.custom<BankAccountRep.Complete>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
  // NB(alex): We store this value to prevent the flicker of an incorrect value caused by the query getting refreshed upon successful payment. https://linear.app/highbeam/issue/HB-6453/fix-flickering-incorrect-math-after-capital-account-drawdown
  currentAmountAvailable: z.number().positive(),
});

export type RepayHighbeamCardsFormInputs = z.input<typeof repayHighbeamCardsFormSchema>;
export type RepayHighbeamCardsFormOutputs = z.output<typeof repayHighbeamCardsFormSchema>;

type Params = {
  defaultValues: RepayHighbeamCardsFormInputs;
};

const useRepayHighbeamCardsForm = ({ defaultValues }: Params) => {
  return useForm<RepayHighbeamCardsFormInputs, object, RepayHighbeamCardsFormOutputs>({
    resolver: zodResolver(repayHighbeamCardsFormSchema),
    defaultValues,
  });
};

export default useRepayHighbeamCardsForm;
