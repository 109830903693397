import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

const useCapitalAccountAutoPayRutterConnections = (capitalAccountGuid: string) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  const { data } = useSuspenseQuery({
    queryKey: ["capital-account-auto-pay-accounts", capitalAccountGuid],
    queryFn: () => {
      return highbeamApi.lineOfCreditBalanceRecovery.getMandatoryConnections(
        businessGuid,
        capitalAccountGuid
      );
    },
  });

  return data;
};

export default useCapitalAccountAutoPayRutterConnections;
