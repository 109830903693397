import getConnectedStoreTypeFromRutterPlatform from "modules/connected-stores/utils/getConnectedStoreTypeFromRutterPlatform";
import { isNotFalsy } from "utils/array";

import useCapitalAccountAutoPayRutterConnections from "./useCapitalAccountAutoPayRutterConnections";

const useCapitalAccountAutoPayConnectedStoreTypes = (capitalAccountGuid: string) => {
  const autoPayRutterConnections = useCapitalAccountAutoPayRutterConnections(capitalAccountGuid);

  // NB(alex): The rutter platform should always be a connected store, but we have to handle other cases because the ts types don't line up. Would be nice to fix this on the backend, but this works for now.
  const autoPayConnectedStoreTypes = autoPayRutterConnections
    .map(({ platform }) => getConnectedStoreTypeFromRutterPlatform(platform))
    .filter(isNotFalsy);

  return autoPayConnectedStoreTypes;
};

export default useCapitalAccountAutoPayConnectedStoreTypes;
