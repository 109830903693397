import { useSuspenseQuery } from "@tanstack/react-query";
import useCardPinStatusQueryOptions from "modules/cards/queries/useCardPinStatusQueryOptions";
import { isCardLocked } from "modules/cards/utils";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import Button from "ui/inputs/Button";

import ChangePinModal from "../../ChangePinModal";
import { useCardDetailsPageContext } from "../../providers/CardDetailsPageProvider";
import SetPinModal from "../../SetPinModal";

const CardDetailsSetPinButton = () => {
  const { card } = useCardDetailsPageContext();
  const { mfa } = useMfa();
  const isLocked = isCardLocked(card);

  const { data: isPinSet } = useSuspenseQuery({
    ...useCardPinStatusQueryOptions(card.id),
    select: (pinStatus) => pinStatus.data.attributes.status === "Set",
  });

  const [showChangePinModal, setShowChangePinModal] = useState(false);
  const [showSetPinModal, setShowSetPinModal] = useState(false);

  return (
    <>
      {showSetPinModal && (
        <SetPinModal cardId={card.id} closeModal={() => setShowSetPinModal(false)} />
      )}

      {showChangePinModal && (
        <ChangePinModal cardId={card.id} closeModal={() => setShowChangePinModal(false)} />
      )}

      <Button
        variant="secondary"
        onClick={() =>
          mfa().then(() => (isPinSet ? setShowChangePinModal(true) : setShowSetPinModal(true)))
        }
        disabled={isLocked}
        title={isLocked ? "Card is locked" : ""}
      >
        {isPinSet ? "Change pin" : "Set pin"}
      </Button>
    </>
  );
};

export default CardDetailsSetPinButton;
