import { CheckCircle, MinusCircle, XCircle } from "@phosphor-icons/react";
import React from "react";

type Props = {
  status: "success" | "warning" | "danger";
};

const ChatConfigIndicatorIcon: React.FC<Props> = ({ status }) => {
  switch (status) {
    case "success":
      return <CheckCircle className="fill-green-600" size={18} />;
    case "warning":
      return <MinusCircle className="fill-yellow-700" size={18} />;
    case "danger":
      return <XCircle className="fill-red-600" size={18} />;
  }
};

export default ChatConfigIndicatorIcon;
