import { ArrowRight } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import checkIsCapitalAccountSummaryWithChargeCard from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import CapitalFaqs from "modules/capital/components/CapitalFaqs";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import TeaserV2 from "ui/data-display/TeaserV2";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import { Heading2, Span } from "ui/typography";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";

import { permissionsTooltipCopy } from "../../constants";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

import ChargeCardSummaryItems from "./ChargeCardSummaryItems";
import LineOfCreditSummaryItems from "./LineOfCreditSummaryItems";
import OfferedCapitalHowItWorks from "./OfferedCapitalHowItWorks";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const OfferedCapitalView: React.FC<Props> = ({ capitalAccountSummary }) => {
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();
  const isCapitalAccountWithChargeCard =
    checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary);

  return (
    <>
      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummary} />

      <DashboardPage.Section>
        <TeaserV2 color="purple">
          <div className="mb-9">
            <Span className="mb-4 text-3xl">🎉</Span>
            <Heading2>Your {capitalAccountSummary.name.toLowerCase()} is ready!</Heading2>
          </div>

          {isCapitalAccountWithChargeCard ? (
            <ChargeCardSummaryItems capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
          ) : (
            <LineOfCreditSummaryItems capitalAccountSummaryCashAccessOnly={capitalAccountSummary} />
          )}

          <ButtonLinkWithTooltip
            to={`/capital/${capitalAccountSummary.guid}/review-line-offer/how-it-works`}
            variant="primary"
            disabled={!isAllowedToAcceptLineOfCredit}
            tooltip={
              !isAllowedToAcceptLineOfCredit &&
              permissionsTooltipCopy.notAllowedToActivateLineOfCredit
            }
            className="mt-9 w-full tablet:w-auto"
          >
            Review and accept
            <ArrowRight size={14} />
          </ButtonLinkWithTooltip>
        </TeaserV2>
      </DashboardPage.Section>

      <OfferedCapitalHowItWorks capitalAccountSummary={capitalAccountSummary} />

      <DashboardPage.Section>
        <DashboardPage.Section.Header heading="FAQs" />

        {isCapitalAccountWithChargeCard ? (
          <CapitalFaqs sections={["general", "highbeam-cards"]} />
        ) : (
          <CapitalFaqs sections={["general"]} />
        )}
      </DashboardPage.Section>
    </>
  );
};

export default OfferedCapitalView;
