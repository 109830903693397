import { Authorization } from "@highbeam/unit-node-sdk";
import TransactionsV2 from "components/TransactionsV2";
import CardAuthorizationFlexpane from "modules/card-authorizations/dialogs/CardAuthorizationFlexpane";
import { CardAvatarBarByCardId } from "modules/cards/components/CardAvatarBar";
import getCounterpartyVendor from "modules/counterparty-vendor/utils/getCounterpartyVendor";
import { FC } from "react";
import PageIndicator from "ui/navigation/PageIndicator";
import AmountCell from "ui/table/cells/AmountCell";
import CounterpartyCell from "ui/table/cells/CounterpartyCell";
import DateTimeCell from "ui/table/cells/DateTimeCell";
import { Column, TableColumnAlignment } from "ui/table/Table";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

import useCardAuthorizations, {
  AUTHORIZATIONS_PAGE_SIZE,
  useCurrentPageSearchParam,
} from "./hooks/useCardAuthorizations";

const columns: Column<Authorization>[] = [
  {
    title: "Date",
    cellRender: ({ attributes }) => <DateTimeCell date={attributes.createdAt} />,
  },
  {
    title: "Vendor",
    cellRender: (authorization) => {
      const counterpartyVendor = getCounterpartyVendor(authorization);

      if (counterpartyVendor) {
        return (
          <CounterpartyCell>
            {counterpartyVendor.name}
            <CounterpartyCell.Logo src={counterpartyVendor.logo} alt={counterpartyVendor.name} />
          </CounterpartyCell>
        );
      } else {
        return <CounterpartyCell>{authorization.attributes.merchant.name}</CounterpartyCell>;
      }
    },
  },
  {
    title: "Card",
    width: 360,
    cellRender: ({ relationships }) => (
      <CardAvatarBarByCardId cardId={relationships.card.data.id} />
    ),
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ attributes: { amount } }) => <AmountCell cents={amount} direction="negative" />,
  },
];

type Props = {
  accountType?: "deposit" | "credit";
};

/**
 * CardsAuthorizationsTable component renders a table of card authorizations.
 * - If `accountType` is provided, it filters authorizations specific to that account type.
 * - If `accountType` is not provided, it displays all cards authorizations.
 */
const CardAuthorizations: FC<Props> = ({ accountType }) => {
  const { authorizations } = useCardAuthorizations({ accountType });
  const [cardAuthorizationId, setCardAuthorizationId] = useSearchParamValue("cardAuthorization");
  const [currentPage, setCurrentPage] = useCurrentPageSearchParam();
  const page = useCardAuthorizations({ accountType }).pagination;
  const totalPages = Math.ceil(page.total / AUTHORIZATIONS_PAGE_SIZE);

  return (
    <>
      <CardAuthorizationFlexpane
        authorizationId={cardAuthorizationId}
        onClose={() => setCardAuthorizationId("")}
      />

      <TransactionsV2
        title={<TransactionsV2.Title>Pending</TransactionsV2.Title>}
        table={
          <TransactionsV2.TransactionsTable
            data={authorizations}
            rowKey={({ id }) => id}
            columns={columns}
            onRowClick={(authorization) => {
              setCardAuthorizationId(authorization.id);
            }}
          />
        }
        footer={
          <TransactionsV2.Footer
            pageIndicator={
              totalPages > 1 && (
                <PageIndicator
                  currentPage={currentPage - 1}
                  setCurrentPage={(value) => setCurrentPage(value + 1)}
                  totalPages={totalPages}
                />
              )
            }
          />
        }
      />
    </>
  );
};

export default CardAuthorizations;
