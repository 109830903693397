import { ArrowFatLinesDown, ArrowFatLinesUp } from "@phosphor-icons/react";
import getCapitalTransactionType from "modules/capital-account-transactions/utils/getCapitalTransactionType";
import React from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalTransactionRep from "reps/CapitalTransactionRep";
import colors from "styles/colors";
import TextCell from "ui/table/cells/TextCell";
import variants from "utils/ts/variants";

type Props = {
  capitalTransaction: CapitalTransactionRep.Complete;
  capitalAccountType: CapitalAccountRep.Type;
};

const CapitalAccountTransactionTransactionTypeCell: React.FC<Props> = ({
  capitalTransaction,
  capitalAccountType,
}) => {
  const transactionType = getCapitalTransactionType(capitalTransaction);

  return (
    <TextCell darken className="font-medium">
      {transactionType === "repayment" ? (
        <>
          <ArrowFatLinesUp size={14} color={colors.purple[500]} />
          {variants(capitalAccountType, {
            [CapitalAccountRep.Type.CashAccessOnly]: "Repayment",
            [CapitalAccountRep.Type.ChargeCardOnly]: "Overdue repayment",
          })}
        </>
      ) : (
        <>
          <ArrowFatLinesDown size={14} color={colors.purple[500]} />
          {variants(capitalAccountType, {
            [CapitalAccountRep.Type.CashAccessOnly]: "Drawdown",
            [CapitalAccountRep.Type.ChargeCardOnly]: "Overdue balance",
          })}
        </>
      )}
    </TextCell>
  );
};

export default CapitalAccountTransactionTransactionTypeCell;
