import { useRefreshBillLineItemsQuery } from "modules/bill-line-items/queries/useBillLineItems";
import useBillSyncRunDispatcher from "modules/bill-syncs/hooks/useBillSyncRunDispatcher";
import { useRefreshBillQuery } from "modules/bills/queries/useBill";
import { useRefreshBillAuditLogsQuery } from "modules/bills/queries/useBillAuditLogs";
import { notify } from "ui/feedback/Toast";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillLineItemApi from "../api/useBillLineItemApi";

const useDeleteBillApprovalMutation = (
  billLineItemId: string,
  billId: string,
  additionalOptions?: MutationAdditionalOptions<void, void>
) => {
  const billLineItemApi = useBillLineItemApi();

  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillAuditLogsQuery = useRefreshBillAuditLogsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        await billLineItemApi.delete(billLineItemId);
      },
      onSuccess: async () => {
        dispatchBillSync();

        await Promise.all([
          refreshBillQuery(),
          refreshBillAuditLogsQuery(),
          refreshBillLineItemsQuery(),
        ]);
      },
      onError: (error) => {
        notify("error", error.message ?? "Something went wrong! Please try again.");
      },
    },
    additionalOptions || {}
  );
};

export default useDeleteBillApprovalMutation;
