import { CheckCircle, Info, WarningCircle } from "@phosphor-icons/react";
import useInternationalWirePaymentMethodRecoilHelpers from "modules/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset/useInternationalWirePaymentMethodRecoilHelpers";
import { useRecoilValue } from "recoil";
import PayeeRep from "reps/PayeeRep";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import Banner from "ui/data-display/Banner";
import Text from "ui/typography/Text";

import styles from "./GbpValidationBanner.module.scss";
import { usePayeeValidatorQuery } from "./queries/useValidatePayee";

type GbpValidationBannerContainerProps = {
  isConfirmation: boolean;
};

const GbpValidationBannerContainer: React.FC<GbpValidationBannerContainerProps> = ({
  isConfirmation,
}) => {
  const currencyOption = useRecoilValue(quoteCurrencyState);
  const { internationalWireTransferMethod, isValidInternational, hasInputValues } =
    useInternationalWirePaymentMethodRecoilHelpers({
      payeeGuid: undefined,
    });
  if (
    !hasInputValues ||
    currencyOption.value !== "GBP" ||
    !internationalWireTransferMethod ||
    !isValidInternational
  ) {
    return null;
  } else {
    return (
      <GbpValidationBanner
        internationalWireTransferMethod={
          internationalWireTransferMethod.internationalWireTransferMethod
        }
        isConfirmation={isConfirmation}
      />
    );
  }
};

type GbpValidationBannerProps = {
  internationalWireTransferMethod: PayeeRep.InternationalWireTransferMethod;
  isConfirmation: boolean;
};

type BannerColor = "green" | "yellow" | "red";

const BANNER_COLORS: Record<string, BannerColor> = {
  Success: "green",
  Warning: "yellow",
  Error: "red",
};

const BANNER_ICONS = {
  Success: <CheckCircle />,
  Warning: <Info />,
  Error: <WarningCircle />,
};

const GbpValidationBanner: React.FC<GbpValidationBannerProps> = ({
  internationalWireTransferMethod,
  isConfirmation,
}) => {
  const {
    data: validation,
    isLoading,
    isError,
  } = usePayeeValidatorQuery({
    internationalWireTransferMethod,
  });
  if (!validation || isLoading || isError) return null;

  if (isConfirmation) {
    return validation.type === "Success" ? null : (
      <Banner
        className={styles.confirmationBanner}
        color="red"
        icon={<WarningCircle />}
        body={
          <Text size={14}>
            Account details could not be confirmed. The payment could be sent to the wrong recipient
            and we may not be able to recover the funds for you.
          </Text>
        }
      />
    );
  }

  return (
    <Banner
      className={styles.validationBanner}
      color={BANNER_COLORS[validation.type]}
      icon={BANNER_ICONS[validation.type]}
      title={validation.type === "Success" ? "Account details confirmed" : null}
      body={<Text size={14}>{validation.message}</Text>}
    />
  );
};

export default GbpValidationBannerContainer;
