import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import usePrimaryBankAccount from "modules/bank-accounts/queries/usePrimaryBankAccount";
import { useNavigate } from "react-router-dom";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./SecondaryInternationalAccountInformation.module.scss";

const SecondaryInternationalAccountInformation = () => {
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const navigate = useNavigate();

  const navigateToPrimaryAccount = () => {
    navigate(`/accounts/${primaryAccount.guid}`);
  };

  return (
    <div className={styles.container}>
      <Text className={styles.description} size={14}>
        You can only receive international payments to{" "}
        <BankAccountBar bankAccount={primaryAccount} />
      </Text>
      <Button variant="tertiary" onClick={navigateToPrimaryAccount}>
        View primary account details
      </Button>
    </div>
  );
};

export default SecondaryInternationalAccountInformation;
