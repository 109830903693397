import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useNewCreditApplication from "./useNewCreditApplication";

const useCreditApplicationDocumentsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const newCreditApplication = useNewCreditApplication();
  const creditApplicationGuid = newCreditApplication.guid;

  return useQueryOptions({
    queryKey: ["creditApplicationDocuments", { businessGuid, creditApplicationGuid }],
    queryFn: async () =>
      (await highbeamApi.creditApplication.getDocuments(businessGuid, creditApplicationGuid)) || [],
  });
};

export default useCreditApplicationDocumentsQueryOptions;
