import useBusiness from "modules/business/queries/useBusiness";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  referralSlugOrGuid: string | null;
};

const referralLinkQueryHooks = makeQueryHooks({
  name: "referralLink",
  useQueryVariables: (params: Params) => {
    return {
      referralSlugOrGuid: params.referralSlugOrGuid,
    };
  },
  useQueryFnMaker: ({ referralSlugOrGuid }) => {
    const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });

    return () => {
      if (!referralSlugOrGuid) {
        return null;
      }
      return highbeamApi.referralLink.get(referralSlugOrGuid);
    };
  },
});

const useReferral = (params: Params) => {
  return referralLinkQueryHooks.useData(params);
};

export default useReferral;

// Hooks

export const useReferralReceivedByBusiness = () => {
  const business = useBusiness();
  return useReferral({ referralSlugOrGuid: business.referralLinkGuid });
};
