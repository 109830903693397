import { Card } from "@highbeam/unit-node-sdk";
import cardsQueryHooks from "modules/cards/queries/cardsQueryHooks";
import React, { useState } from "react";

import SwitchHelper from "../SwitchHelper";

import CardDropdown from "./CardDropdown";
import CardPreview from "./CardPreview";
import NewCardDetailsHeader from "./NewCardDetailsHeader";

const NewCardDetails: React.FC = () => {
  const cards = cardsQueryHooks.useData({ status: "active" });
  const [card, setCard] = useState<Card | undefined>(cards.length === 1 ? cards[0] : undefined);

  return (
    <SwitchHelper>
      <NewCardDetailsHeader />
      <CardDropdown cards={cards} card={card} setCard={setCard} />
      {card && <CardPreview card={card} />}
    </SwitchHelper>
  );
};

export default NewCardDetails;
