import { useSuspenseQuery } from "@tanstack/react-query";

import useAccountingPlatformConnectionsQueryOptions from "./useAccountingPlatformConnectionsQueryOptions";

const useActiveAccountingPlatformConnection = () => {
  const { data } = useSuspenseQuery(useAccountingPlatformConnectionsQueryOptions());
  // NB(lev): While technically it's possible to have multiple accounting platforms connected, we only really support one at a time right now. As such, we'll just choose the first one here (that's active).
  const accountingPlatformConnection =
    data.length > 0 ? (data.find(({ isActive }) => isActive) ?? null) : null;

  return accountingPlatformConnection;
};

export default useActiveAccountingPlatformConnection;
