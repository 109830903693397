import useSendChatMessage from "modules/chat/hook/useSendChatMessage";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { notify } from "ui/feedback/Toast";
import cn from "utils/tailwind/cn";

import ChatSuggestion from "./ChatSuggestion";

const options = [
  "What was my marketing spend over the last 12 months?",
  "What was my ROAS over the last 12 months?",
  "What were my largest payouts over last 3 months?",
  "What's my largest wholesale account?",
];

type Props = {
  className?: string;
};

const ChatConversationSectionPlaceholder: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();

  const { sendChatMessage, isPending } = useSendChatMessage({
    onSuccess: (conversationId) => {
      navigate(`/chat/${conversationId}`);
    },
    onError: () => {
      notify("error", "Something went wrong, please try again!");
    },
  });

  const onChoose = useCallback(
    (option: string) => {
      if (isPending) return;
      sendChatMessage({ conversationId: undefined, content: option });
    },
    [isPending, sendChatMessage]
  );

  return (
    <div className={cn("flex flex-col items-center justify-center", className)}>
      <div className={cn("flex flex-col gap-6 px-8 py-3")}>
        {options.map((option) => (
          <ChatSuggestion key={option} onClick={() => onChoose(option)}>
            {option}
          </ChatSuggestion>
        ))}
      </div>
    </div>
  );
};

export default ChatConversationSectionPlaceholder;
