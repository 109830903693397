import { ArrowFatLinesUp, Coins, DotsThreeCircle, DotsThreeVertical } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountIcon from "modules/capital-accounts/components/CapitalAccountIcon";
import LineOfCreditDrawdownButtonLink from "modules/capital-accounts/components/LineOfCreditDrawdownButtonLink";
import LineOfCreditRepayButtonLink from "modules/capital-accounts/components/LineOfCreditRepayButtonLink";
import ChargeCardAdditionalMetadataModal from "modules/capital-accounts/dialogs/ChargeCardAdditionalMetadataModal";
import { checkIsActiveCapitalAccountWithChargeCard } from "modules/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import getCapitalAccountTitle from "modules/capital-accounts/utils/getCapitalAccountTitle";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useIsCapitalOverviewPageEnabled from "modules/capital/queries/useIsCapitalOverviewPageEnabled";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Button from "ui/inputs/Button";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import Menu from "ui/overlay/Menu";
import useModalState from "utils/dialog/useModalState";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountHeader: FC<Props> = ({ capitalAccountSummary }) => {
  const isCapitalOverviewPageEnabled = useIsCapitalOverviewPageEnabled();
  const {
    state: moreDetailsModalState,
    open: openMoreDetailsModal,
    close: closeMoreDetailsModal,
  } = useModalState<{ capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard }>();

  return (
    <>
      {moreDetailsModalState.isOpen && (
        <ChargeCardAdditionalMetadataModal
          onClose={closeMoreDetailsModal}
          capitalAccountSummaryWithChargeCard={
            moreDetailsModalState.capitalAccountSummaryWithChargeCard
          }
        />
      )}

      <DashboardPage.Header
        actions={
          (capitalAccountSummary.state === CapitalAccountRep.State.Active ||
            capitalAccountSummary.state === CapitalAccountRep.State.OfferAccepted) && (
            <div className="flex w-full gap-x-2">
              {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly && (
                <div className="flex w-full">
                  <LineOfCreditRepayButtonLink
                    capitalAccountSummary={capitalAccountSummary}
                    className="rounded-r-none border-r-0"
                  />
                  <LineOfCreditDrawdownButtonLink
                    className="rounded-l-none"
                    capitalAccountSummary={capitalAccountSummary}
                  />
                </div>
              )}

              {capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly && (
                <ButtonLinkWithTooltip
                  variant="secondary"
                  to={`/capital/${capitalAccountSummary.guid}/repay`}
                  disabled={capitalAccountSummary.state !== CapitalAccountRep.State.Active}
                  tooltip={
                    capitalAccountSummary.state !== CapitalAccountRep.State.Active &&
                    `Please connect payouts to activate your ${capitalAccountSummary.name}.`
                  }
                >
                  <ArrowFatLinesUp size={20} weight="light" />
                  Repay
                </ButtonLinkWithTooltip>
              )}

              {checkIsActiveCapitalAccountWithChargeCard(capitalAccountSummary) && (
                <Menu
                  button={
                    <Button variant="tertiary" className="h-[2.625rem] px-3">
                      <DotsThreeVertical size={16} />
                    </Button>
                  }
                >
                  <Menu.Item
                    icon={<DotsThreeCircle size={16} />}
                    onClick={() => {
                      openMoreDetailsModal({
                        capitalAccountSummaryWithChargeCard: capitalAccountSummary,
                      });
                    }}
                  >
                    More details
                  </Menu.Item>
                </Menu>
              )}
            </div>
          )
        }
      >
        {isCapitalOverviewPageEnabled ? (
          <>
            <DashboardPage.Header.BackCaret to="/capital" />
            <DashboardPage.Header.IconTile
              icon={<CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />}
            />
            <DashboardPage.Header.Title>
              {getCapitalAccountTitle(capitalAccountSummary)}
            </DashboardPage.Header.Title>
          </>
        ) : (
          <>
            <DashboardPage.Header.IconTile icon={<Coins />} />
            <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
          </>
        )}
      </DashboardPage.Header>
    </>
  );
};

export default CapitalAccountHeader;
