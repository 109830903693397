import BillRep from "reps/BillRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillApi from "../api/useBillApi";
import useRefreshBillsQueries from "../queries/useRefreshBillsQueries";

const useUpdateBillMutation = (
  billId: string,
  additionalOptions: MutationAdditionalOptions<BillRep.Complete, BillRep.Updater>
) => {
  const billApi = useBillApi();
  const refreshBillsQueries = useRefreshBillsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        return billApi.updateBillInfo(billId, variables);
      },
      onSuccess: async () => {
        await refreshBillsQueries();
      },
    },
    additionalOptions
  );
};

export default useUpdateBillMutation;
