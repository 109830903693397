import { useQueryClient } from "@tanstack/react-query";
import { KNOWLEDGE_BASE_ITEMS_QUERY_KEY } from "modules/chat/queries/useKnowledgeBaseItems";
import { KnowledgeBaseItemRep, KnowledgeBaseItemUpdateRep } from "reps/chat/KnowledgeBaseItemRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

/**
 * Note: This actually deletes the knowledge base item if the value is blank.
 */
const useUpdateKnowledgeBaseItemMutation = (
  knowledgeBaseItemId: string,
  additionalOptions: MutationAdditionalOptions<KnowledgeBaseItemRep, KnowledgeBaseItemUpdateRep>
) => {
  const queryClient = useQueryClient();
  const highbeamApi = useHighbeamApi();

  return useMutationWithDefaults(
    {
      mutationFn: async (update) => {
        if (update.value.trim().length === 0) {
          return await highbeamApi.knowledgeBaseItem.delete(knowledgeBaseItemId);
        }
        return await highbeamApi.knowledgeBaseItem.update(knowledgeBaseItemId, update);
      },
      onSuccess: async (knowledgeBaseItem) => {
        await queryClient.invalidateQueries({
          queryKey: [
            KNOWLEDGE_BASE_ITEMS_QUERY_KEY,
            { businessGuid: knowledgeBaseItem.businessGuid },
          ],
        });
      },
    },
    additionalOptions
  );
};

export default useUpdateKnowledgeBaseItemMutation;
