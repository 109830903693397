import SignOutButton from "modules/auth/components/SignOutButton";
import UserAvatarMenu from "modules/auth/components/UserAvatarMenu";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { FC, ReactNode } from "react";
import { useResetRecoilState } from "recoil";
import isSuperuseringState, { useIsSuperusering } from "state/auth/isSuperusering";
import Page, { PageHeaderProps } from "ui/data-display/Page";
import HighbeamLogo from "ui/icons/HighbeamLogo";
import Button from "ui/inputs/Button";
import cn from "utils/tailwind/cn";

export const FullPageLayoutHeaderHighbeamLogoOnly: FC<Omit<PageHeaderProps, "center">> = (
  props
) => {
  return <Page.Header center={<HighbeamLogo withText className="h-8 px-8" />} {...props} />;
};

const FullPageLayoutHeaderWithActions = () => {
  const isSuperusering = useIsSuperusering();
  const resetIsSuperusering = useResetRecoilState(isSuperuseringState);

  // TODO(alex) HB-5859: This check doesn't appear necessary by looking at ts types but actually those types are wrong, so we need to do this check or else the user avatar menu will crash.
  const businessGuid = useBusinessGuid();

  const onExitSuperuser = () => {
    resetIsSuperusering();
    window.location.reload(); // Intended to force a refresh.
  };

  return (
    <FullPageLayoutHeaderHighbeamLogoOnly
      right={
        <>
          {isSuperusering && (
            <Button variant="ghost" onClick={onExitSuperuser}>
              Exit superuser
            </Button>
          )}
          {businessGuid ? <UserAvatarMenu /> : <SignOutButton />}
        </>
      }
    />
  );
};

export type FullPageLayoutProps = {
  children: ReactNode;
  header?: ReactNode;
  backgroundImage: "highbeam-logo" | "none";
  backgroundColor: "bg-grey-50" | "bg-white";
  className?: string;
};

const FullPageLayout: FC<FullPageLayoutProps> = ({
  children,
  header = <FullPageLayoutHeaderWithActions />,
  backgroundImage,
  backgroundColor,
  className,
}) => {
  return (
    <Page
      className={cn(
        "min-h-screen",
        backgroundImage === "highbeam-logo" && "tablet:bg-highbeam-logo",
        backgroundColor,
        className
      )}
    >
      {header}
      <Page.BodyCenter>{children}</Page.BodyCenter>
    </Page>
  );
};

export default FullPageLayout;
