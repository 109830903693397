import { FC } from "react";

import OnboardingCard from "./OnboardingCard";

type Props = {
  className?: string;
};

const OnboardingErrorCard: FC<Props> = ({ className }) => {
  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header className="pb-8">
        <OnboardingCard.Heading1>Something went wrong</OnboardingCard.Heading1>
        <OnboardingCard.Subheading>
          We are sorry, there is a temporary issue with your signup. Please come back in a few
          minutes, or reach out to support.
        </OnboardingCard.Subheading>
      </OnboardingCard.Header>
    </OnboardingCard>
  );
};

export default OnboardingErrorCard;
