import React from "react";
import { ParagraphChatElement } from "reps/chat/ChatElement";
import { Paragraph } from "ui/typography";

import ResponseElement from "./ResponseElement";

type Props = {
  element: ParagraphChatElement;
};

const ResponseMessageParagraph: React.FC<Props> = ({ element }) => {
  return (
    <Paragraph>
      {element.content.map((element, i) => (
        <ResponseElement key={i} element={element} />
      ))}
    </Paragraph>
  );
};

export default ResponseMessageParagraph;
