import { Copy } from "@phosphor-icons/react";
import { ReactNode } from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import useCopyToClipboard from "utils/clipboard/useCopyToClipboard";
import cn from "utils/tailwind/cn";

type Props = {
  accented?: boolean;
  textToCopy: string;
  className?: string;
  children: ReactNode;
  tooltipContent?: ReactNode;
};

const ItemWithCopyTextTooltip: React.FC<Props> = ({
  accented = false,
  textToCopy,
  children,
  className,
  tooltipContent = "Click to copy",
}) => {
  const { copied, copyToClipboard } = useCopyToClipboard();

  return (
    <ItemWithTooltip
      tooltip={<Paragraph>{!copied ? <>{tooltipContent}</> : "Copied"}</Paragraph>}
      asChild
    >
      <VirtualButton
        className={cn(
          "inline-flex w-fit cursor-pointer items-center justify-between gap-x-2.5",
          accented && "rounded-md bg-purple-100 p-2.5 text-purple-500",
          className
        )}
        onClick={(e) => {
          e.preventDefault();
          copyToClipboard(textToCopy);
        }}
        onPointerDown={(e) => {
          // Prevents the tooltip from closing when the icon is clicked.
          e.stopPropagation();
        }}
      >
        {children}
        <Copy size={16} />
      </VirtualButton>
    </ItemWithTooltip>
  );
};

export default ItemWithCopyTextTooltip;
