import { Buildings, Gear, LockKey, Storefront, UsersFour, Calculator } from "@phosphor-icons/react";
import banksAndCardsIcon from "assets/banks-and-cards.svg";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { useIsCurrentBusinessMemberOnboarded } from "modules/business-members/queries/businessMemberQueryHooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import SectionLinkCard from "ui/navigation/SectionLinkCard";
import Tabs from "ui/navigation/Tabs";
import useIsAllowedToNavigateToBusinessDetailsSettingsPage from "utils/permissions/navigation/useIsAllowedToNavigateToBusinessDetailsSettingsPage";
import useIsAllowedToNavigateToBusinessMembersSettingsPage from "utils/permissions/navigation/useIsAllowedToNavigateToBusinessMembersSettingsPage";
import useIsAllowedToNavigateToConnectedAccountingSofwarePage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedAccountingSofwarePage";
import useIsAllowedToNavigateToConnectedStoresPage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedStoresPage";
import useIsAllowedToViewBankConnections from "utils/permissions/navigation/useIsAllowedToViewBankConnections";
import cn from "utils/tailwind/cn";

type ActiveTab = "company-and-user" | "integrations";

const titles: { [key in ActiveTab]: string } = {
  "company-and-user": "Company and user",
  integrations: "Integrations",
};

const hideSectionOnMobileClasses = "hidden tablet:block";
const linksContainerClasses = "grid grid-cols-1 gap-6 tablet:grid-cols-3";

const SettingsPage = () => {
  const isOnboarded = useIsCurrentBusinessMemberOnboarded();

  const isAllowedToNavigateToBusinessDetailsSettingsPage =
    useIsAllowedToNavigateToBusinessDetailsSettingsPage();
  const showBusinessDetailsPage = isOnboarded && isAllowedToNavigateToBusinessDetailsSettingsPage;
  const showUsersPage = useIsAllowedToNavigateToBusinessMembersSettingsPage();
  const showBanksAndCardsPage = useIsAllowedToViewBankConnections();
  const showStoresPage = useIsAllowedToNavigateToConnectedStoresPage();

  const isAllowedToNavigateToConnectedAccountingSofwarePage =
    useIsAllowedToNavigateToConnectedAccountingSofwarePage();

  const showAccountingSoftwarePage = isAllowedToNavigateToConnectedAccountingSofwarePage;

  const showIntegrationsSection =
    showStoresPage || showBanksAndCardsPage || showAccountingSoftwarePage;

  const [activeTab, setActiveTab] = useState<ActiveTab>("company-and-user");

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Settings</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      {showIntegrationsSection && (
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            { id: "company-and-user", label: titles["company-and-user"] },
            { id: "integrations", label: titles.integrations },
          ]}
          className="px-5 tablet:hidden"
        />
      )}

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.IconTile icon={<Gear />} />
          <DashboardPage.Header.Title>Settings</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section
          className={cn(activeTab !== "company-and-user" && hideSectionOnMobileClasses)}
        >
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              {titles["company-and-user"]}
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <DashboardPage.Section.Body className={linksContainerClasses}>
            {showBusinessDetailsPage && (
              <Link to={"/settings/business"}>
                <SectionLinkCard
                  icon={<Buildings />}
                  title={"Business information"}
                  description={"Edit your business name, address, and other details here."}
                />
              </Link>
            )}

            <Link to={"/settings/security"}>
              <SectionLinkCard
                icon={<LockKey />}
                title={"Security"}
                description={"Update your password and configure authentication."}
              />
            </Link>

            {showUsersPage && (
              <Link to={"/settings/users"}>
                <SectionLinkCard
                  icon={<UsersFour />}
                  title={"Users"}
                  description={"Add or remove users who have access to your Highbeam account."}
                />
              </Link>
            )}
          </DashboardPage.Section.Body>
        </DashboardPage.Section>

        {showIntegrationsSection && (
          <DashboardPage.Section
            className={cn(activeTab !== "integrations" && hideSectionOnMobileClasses)}
          >
            <DashboardPage.Section.Header>
              <DashboardPage.Section.HeaderHeading>
                {titles.integrations}
              </DashboardPage.Section.HeaderHeading>
            </DashboardPage.Section.Header>

            <DashboardPage.Section.Body className={linksContainerClasses}>
              {showStoresPage && (
                <Link to={"/settings/stores"}>
                  <SectionLinkCard
                    icon={<Storefront />}
                    title={"Stores"}
                    description={"Integrate your stores to see important information and insights."}
                  />
                </Link>
              )}

              {showBanksAndCardsPage && (
                <Link to={"/settings/banks-and-cards"}>
                  <SectionLinkCard
                    icon={<img src={banksAndCardsIcon} alt={""} />}
                    title={"Banks and cards"}
                    description={
                      "Connect your bank accounts and credit cards to use with Highbeam."
                    }
                  />
                </Link>
              )}

              {showAccountingSoftwarePage && (
                <Link to={"/settings/accounting-software"}>
                  <SectionLinkCard
                    icon={<Calculator />}
                    title={"Accounting software"}
                    description={
                      "Connect your accounting software so Highbeam can give you an optimal capital offering."
                    }
                  />
                </Link>
              )}
            </DashboardPage.Section.Body>
          </DashboardPage.Section>
        )}
      </DashboardPage>
    </>
  );
};

export default SettingsPage;
