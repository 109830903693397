import { NotePencil } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import MultiStep from "layouts/MultiStep";
import CapitalAccountBreadcrumbItem from "modules/capital-accounts/components/CapitalAccountBreadcrumbItem";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import getCardOrLineCopy from "modules/capital-accounts/utils/getCardOrLineCopy";
import { FC } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";

import ReviewLineOfferSteps from "./components/ReviewLineOfferSteps";
import ReviewLineOfferProvider from "./context/ReviewLineOfferProvider";
import AgreementView from "./views/AgreementView";
import AutoPayView from "./views/AutoPayView";
import HowItWorksView from "./views/HowItWorksView";
import OfferAcceptedView from "./views/OfferAcceptedView";
import ReviewView from "./views/ReviewView";

type ReviewLineOfferPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const ReviewLineOfferPageContent: FC<ReviewLineOfferPageContentProps> = ({
  capitalAccountSummary,
}) => {
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();
  const { pathname } = useLocation();
  const isOfferAcceptedPathname =
    pathname === `/capital/${capitalAccountSummary.guid}/review-line-offer/offer-accepted`;

  if (!isAllowedToAcceptLineOfCredit) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}`} />;
  }

  if (isOfferAcceptedPathname) {
    return <OfferAcceptedView capitalAccountSummary={capitalAccountSummary} />;
  }

  return (
    <DashboardPage>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to={`/capital/${capitalAccountSummary.guid}`} />
        <DashboardPage.Header.IconTile icon={<NotePencil />} />
        <DashboardPage.Header.Title>
          Review {getCardOrLineCopy(capitalAccountSummary)} offer
        </DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <ReviewLineOfferProvider capitalAccountSummary={capitalAccountSummary}>
          <MultiStep steps={<ReviewLineOfferSteps />}>
            <Routes>
              <Route
                index
                element={
                  <Navigate
                    to={`/capital/${capitalAccountSummary.guid}/review-line-offer/how-it-works`}
                  />
                }
              />
              <Route
                path="/how-it-works"
                element={<HowItWorksView capitalAccountSummary={capitalAccountSummary} />}
              />
              <Route
                path="/auto-pay"
                element={<AutoPayView capitalAccountSummary={capitalAccountSummary} />}
              />
              <Route
                path="/agreement"
                element={<AgreementView capitalAccountSummary={capitalAccountSummary} />}
              />
              <Route
                path="/review"
                element={<ReviewView capitalAccountSummary={capitalAccountSummary} />}
              />
            </Routes>
          </MultiStep>
        </ReviewLineOfferProvider>
      </DashboardPage.Section>
    </DashboardPage>
  );
};

const ReviewLineOfferPage = () => {
  const { capitalAccountGuid } = useParams();

  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountGuid ?? null,
  });

  if (capitalAccountSummary === null) {
    return <Navigate to="/capital" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>

          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem={false}
          />

          {capitalAccountSummary ? (
            <Breadcrumbs.CurrentItem>
              Review {getCardOrLineCopy(capitalAccountSummary)} offer
            </Breadcrumbs.CurrentItem>
          ) : (
            <Breadcrumbs.CurrentItemShimmer />
          )}
        </Breadcrumbs>
      </DashboardHeader>

      {capitalAccountSummary ? (
        <ReviewLineOfferPageContent capitalAccountSummary={capitalAccountSummary} />
      ) : (
        <DashboardPage.DotsLoader />
      )}
    </>
  );
};

export default ReviewLineOfferPage;
