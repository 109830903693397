import { CaretDown, CheckCircle } from "@phosphor-icons/react";
import isGettingStartedSetupGuideOpenState from "modules/action-items/get-started/state/gettingStartedSetupGuide";
import { useMilestoneActionItems } from "modules/action-items/hooks/useMilestoneActionItems";
import { ActionItem, Milestone } from "modules/action-items/types";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import VirtualButton from "ui/inputs/VirtualButton";
import { Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  number: number;
  actionItem: ActionItem;
  active: boolean;
  setActive: (active: number | undefined) => void;
};

const ActionItemRow: React.FC<Props> = ({ number, actionItem, active, setActive }) => {
  const navigate = useNavigate();
  const [scrollHeight, setScrollHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active) {
      const content = contentRef.current;
      if (content) {
        content.style.height = `${scrollHeight}px`;
      }
    } else {
      const content = contentRef.current;
      if (content) {
        setScrollHeight(content.scrollHeight);
        content.style.height = "0";
      }
    }
  }, [active, scrollHeight]);

  const onToggleContainer = () => {
    if (active) {
      setActive(undefined);
    } else {
      setActive(actionItem.order);
    }
  };

  const { finishItemByStep } = useMilestoneActionItems(Milestone.GetStarted);

  const onSkipAction = () => {
    finishItemByStep(actionItem.name, "Dismissed");
  };

  const isSkipped = actionItem.finishedActionItemRep?.state === "Dismissed";
  const isCompleted = actionItem.finishedActionItemRep?.state === "Complete";
  const isSkippable = !isSkipped && !isCompleted;
  const setShowGetStartedSetupGuide = useSetRecoilState(isGettingStartedSetupGuideOpenState);

  return (
    <VirtualButton
      className={cn(
        "flex cursor-pointer gap-x-3 border-t border-t-grey-100 p-4 hover:bg-grey-50",
        active && "bg-grey-50"
      )}
      onClick={onToggleContainer}
    >
      {isCompleted ? (
        <CheckCircle weight="fill" className="size-6 text-green-400" />
      ) : (
        <div
          className={cn(
            "flex size-6 items-center justify-center rounded-full bg-grey-100 text-xs text-grey-500",
            active && "bg-purple-500 text-white"
          )}
        >
          {number}
        </div>
      )}

      <div className="flex w-72 flex-col">
        <div className="flex justify-between">
          <Heading4 className="mt-0.5 font-medium">{actionItem.title}</Heading4>
          {isSkipped && <Pill color="orange-100">Skipped</Pill>}
        </div>

        <div
          ref={contentRef}
          className="transition-height h-0 overflow-hidden duration-200 ease-in-out"
        >
          <Paragraph className="text-sm text-grey-600">{actionItem.description}</Paragraph>

          {actionItem.dynamicDescription ? actionItem.dynamicDescription() : null}

          <div className="mt-3 flex gap-x-3">
            {!isCompleted && (
              <Button
                variant="tertiary"
                size="sm"
                onClick={() => {
                  navigate(actionItem.primaryActionPath, actionItem.locationState);
                  setShowGetStartedSetupGuide(false);
                }}
              >
                {actionItem.primaryActionText}
              </Button>
            )}
            {isSkippable && (
              <Button size="sm" onClick={onSkipAction}>
                Skip
              </Button>
            )}
          </div>
        </div>
      </div>

      <CaretDown className={cn("mt-1 size-4 text-grey-400", active && "rotate-180")} />
    </VirtualButton>
  );
};

export default ActionItemRow;
