import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import useChatConfigExists from "modules/chat/hook/useChatConfigExists";
import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import ChatConfigDoesNotExist from "./ChatConfigDoesNotExist";
import ChatConversationList from "./ChatConversationList";
import ChatConversationSection from "./ChatConversationSection";
import ChatConversationSectionPlaceholder from "./ChatConversationSectionPlaceholder";
import ChatInputSection from "./ChatInputSection";
import ChatSettingsSection from "./ChatSettingsSection";

const ChatPage: React.FC = () => {
  const chatConfigExists = useChatConfigExists();
  const { conversationId } = useParams();

  if (!chatConfigExists) {
    return <ChatConfigDoesNotExist />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>AI Chat</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <div className="flex flex-grow flex-col">
        <ChatSettingsSection showResetButton={Boolean(conversationId)} />
        <div className="flex h-0 flex-grow flex-row">
          <ChatConversationList className="flex-none" />
          <div className="flex flex-grow flex-col">
            <Suspense fallback={<div className="flex-grow" />}>
              {conversationId ? (
                <ChatConversationSection
                  className="flex-grow overflow-y-auto"
                  conversationId={conversationId}
                />
              ) : (
                <ChatConversationSectionPlaceholder className="flex-grow overflow-y-auto" />
              )}
            </Suspense>
            <ChatInputSection conversationId={conversationId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPage;
