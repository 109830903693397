import { CardStatus } from "@highbeam/unit-node-sdk";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { useMemo } from "react";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useHasPermission from "utils/permissions/useHasPermission";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

// NB(alex): `makeQueryHooks` is still extremely experimental and may be brittle.

export const ROOT_CARDS_QUERY_NAME = "cards-root";

export const useRefreshAllCardsQueries = () => {
  return useRefreshQuery([ROOT_CARDS_QUERY_NAME]);
};

type Params = {
  unitCoAccountId?: string;
  status: "default" | "active" | "closed" | CardStatus[];
};

const cardsQueryHooks = makeQueryHooks({
  rootName: ROOT_CARDS_QUERY_NAME,
  name: "cards",
  useQueryVariables(params: Params) {
    const businessGuid = useBusinessGuid();
    const userGuid = useUserGuid();
    const hasCardReadOwnPermission = useHasPermission("card:readOwn");
    const isSuperusering = useIsSuperusering();

    const status: CardStatus[] = useMemo(() => {
      if (Array.isArray(params.status)) {
        return params.status;
      }
      switch (params.status) {
        case "default":
          return ["Inactive", "Active", "Frozen", "SuspectedFraud"];
        case "active":
          return ["Active"];
        case "closed":
          return ["ClosedByCustomer"];
      }
    }, [params]);

    return {
      unitCoAccountId: params.unitCoAccountId,
      status,
      businessGuid,
      userGuid,
      hasCardReadOwnPermission,
      isSuperusering,
    };
  },
  useQueryFnMaker(variables) {
    const {
      businessGuid,
      status,
      userGuid,
      hasCardReadOwnPermission,
      isSuperusering,
      unitCoAccountId,
    } = variables;
    const highbeamApi = useHighbeamApi();
    const unitApi = useUnitApi();

    return async () => {
      // NB(alex): Unit token scoping bug workaround.
      // When an employee user has a token with scoped cards, but the list of scoped card ids is empty, Unit fails to restrict the list of cards.
      // But after fetching the list of cards, the scoped token _does_ work for fetching individual cards, thus throwing a 403.
      // This temporary workaround hits our cards through backend endpoint, and if the returned list of cards is empty, we return an empty array.
      // BUT we disable it for superuser because the endpoint isn't working for superuser.
      // We should fix this soon, but we want to ship employee user role, so this will work as a stop-gap for now.
      // Unit support request slack thread: https://highbeamco.slack.com/archives/C02JB5M1599/p1712866431406719
      if (!isSuperusering && hasCardReadOwnPermission) {
        const { unitResponse: cardsThroughBackend } = await highbeamApi.card.getAll({
          businessGuid: businessGuid,
          userGuid: userGuid,
          status: status,
          ...(unitCoAccountId && { unitCoAccountId: unitCoAccountId }),
        });

        if (cardsThroughBackend.length === 0) {
          return [];
        }
      }

      const { data: cards } = await unitApi.cards.list({
        include: "account",
        status: status,
        accountId: unitCoAccountId,
      });

      return cards;
    };
  },
});

export default cardsQueryHooks;
