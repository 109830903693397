import { ChartLine } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { useState } from "react";
import FeatureStatusBadge from "ui/data-display/FeatureStatusBadge";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import CashFlowDurationDropdown from "./components/CashFlowDurationDropdown";
import CashFlowSearchInput from "./components/CashFlowSearchInput";
import CashFlowSyncSheetButton from "./components/CashFlowSyncSheetButton";
import CashFlowSyncSheetModal from "./components/CashFlowSyncSheetModal";
import CashFlowTable from "./components/CashFlowTable";
import CashFlowTabs from "./components/CashFlowTabs";
import CashFlowProvider from "./providers/CashFlowProvider";

const CashFlowHeader = ({
  setIsSyncSheetModalOpen,
}: {
  setIsSyncSheetModalOpen: (open: boolean) => void;
}) => {
  return (
    <DashboardPage.Header
      actions={<CashFlowSyncSheetButton onClick={() => setIsSyncSheetModalOpen(true)} />}
    >
      <DashboardPage.Header.IconTile icon={<ChartLine />} />
      <DashboardPage.Header.Title>Cash flow</DashboardPage.Header.Title>
      <FeatureStatusBadge featureStatus="Beta" />
    </DashboardPage.Header>
  );
};

const CashFlowDurationSection = () => {
  return (
    <DashboardPage.Section>
      <CashFlowDurationDropdown />
    </DashboardPage.Section>
  );
};

const CashFlowPage = () => {
  const [isSyncSheetModalOpen, setIsSyncSheetModalOpen] = useState(false);
  return (
    <>
      {isSyncSheetModalOpen && (
        <CashFlowSyncSheetModal onClose={() => setIsSyncSheetModalOpen(false)} />
      )}

      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Cash flow</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CashFlowProvider>
          <CashFlowHeader setIsSyncSheetModalOpen={setIsSyncSheetModalOpen} />

          <CashFlowDurationSection />

          <CashFlowTabs />

          <DashboardPage.Section>
            <CashFlowSearchInput />
          </DashboardPage.Section>

          <DashboardPage.Section>
            <CashFlowTable />
          </DashboardPage.Section>
        </CashFlowProvider>
      </DashboardPage>
    </>
  );
};

export default CashFlowPage;
