import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const CONVERSATIONS_QUERY_KEY = "conversations";

const useConversationQueryOptions = (conversationId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [CONVERSATIONS_QUERY_KEY, { conversationId }],
    queryFn: () => {
      return highbeamApi.conversation.get(conversationId);
    },
  });
};

const useConversation = (conversationId: string) => {
  const { data } = useSuspenseQuery(useConversationQueryOptions(conversationId));
  if (!data) throw new RequiredButNotFoundError();
  return data;
};

export default useConversation;
