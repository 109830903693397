import useChatConfigExists from "modules/chat/hook/useChatConfigExists";
import React from "react";

import ChatConfigSettings from "./ChatConfigSettings";
import ChatConfigStatus from "./ChatConfigStatus";
import ConfigureChatConfig from "./ConfigureChatConfig";

const ChatAdmin: React.FC = () => {
  const chatConfigExists = useChatConfigExists();

  if (!chatConfigExists) return <ConfigureChatConfig />;

  return (
    <>
      <ChatConfigStatus />
      <ChatConfigSettings />
    </>
  );
};

export default ChatAdmin;
