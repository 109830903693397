import { ChatTeardropDots as ChatTeardropDotsIcon } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import useCurrentApEmailAddress from "modules/ap-email-aliases/queries/useCurrentApEmailAddress";
import { FC } from "react";
import CopyLink from "ui/data-display/CopyLink";
import Button from "ui/inputs/Button";
import { Heading4 } from "ui/typography";

import { ApSettingsSection, ApSettingsSectionBody, SecondaryText } from "../layout-primitives";

const EmailSettings: FC = () => {
  const currentApEmailAddress = useCurrentApEmailAddress();
  const chat = useChatWidget();

  return (
    <ApSettingsSection>
      <ApSettingsSectionBody>
        {currentApEmailAddress ? (
          <>
            <SecondaryText>
              Send invoices to this email address and they will show in the &ldquo;Email
              inbox&rdquo; tab for you to review. You can also share them with your payees so they
              can send invoices too.
            </SecondaryText>
            <div className="flex w-full flex-col items-start gap-4">
              <div className="w-full">
                <Heading4 className="pb-2">Email address</Heading4>
                <CopyLink link={currentApEmailAddress} tooltipContent="Copy email address" />
              </div>
              <div className="flex items-center gap-1">
                <SecondaryText>Need to change your email?</SecondaryText>
                <Button size="sm" onClick={() => chat.show()}>
                  <ChatTeardropDotsIcon size={16} />
                  Talk to support
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <SecondaryText>
              You do not currently have an email address set up to receive invoices. Please contact
              support to set one up.
            </SecondaryText>
            <Button variant="tertiary" onClick={() => chat.show()}>
              <ChatTeardropDotsIcon size={20} />
              Talk to support
            </Button>
          </>
        )}
      </ApSettingsSectionBody>
    </ApSettingsSection>
  );
};

export default EmailSettings;
