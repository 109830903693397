import DashboardPage from "layouts/DashboardPage";
import useUpdateChatConfigMutation from "modules/chat/mutations/useUpdateChatConfigMutation";
import useChatConfig from "modules/chat/queries/useChatConfig";
import React, { useCallback } from "react";
import { notify } from "ui/feedback/Toast";

import ChatSettingsDetailsItem from "./ChatSettingsDetailsItem";
import ChatSettingsIsEnabledItem from "./ChatSettingsIsEnabledItem";
import ChatSettingsSaveButton from "./ChatSettingsSaveButton";
import useChatConfigSettingsForm, {
  ChatConfigSettingsFormInputs,
} from "./useChatConfigSettingsForm";

const ChatConfigSettings: React.FC = () => {
  const chatConfig = useChatConfig()!;

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useChatConfigSettingsForm({
    defaultValues: chatConfig,
  });

  const { mutate, isPending } = useUpdateChatConfigMutation(chatConfig.id, {
    onSuccess: () => {
      notify("success", "Saved!");
    },
    onError: () => {
      notify("error", "Something went wrong, please try again!");
    },
  });

  const onSubmit = useCallback(
    (data: ChatConfigSettingsFormInputs) => {
      if (isPending) return;
      mutate(data);
    },
    [isPending, mutate]
  );

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Settings</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <form className="flex max-w-2xl flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <ChatSettingsIsEnabledItem control={control} />
        <ChatSettingsDetailsItem control={control} />
        <ChatSettingsSaveButton disabled={!isValid} isLoading={isPending} />
      </form>
    </DashboardPage.Section>
  );
};

export default ChatConfigSettings;
