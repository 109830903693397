import { Gear as GearIcon } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import useApEmailAliases from "modules/ap-email-aliases/queries/useApEmailAliases";
import AllBillsView from "modules/bills/components/AllBillsView";
import AssignedGrantedBillsView from "modules/bills/components/AssignedGrantedBillsView";
import AssignedRequestedBillsView from "modules/bills/components/AssignedRequestedBillsView";
import BillsEmailInboxView from "modules/bills/components/BillsEmailInboxView";
import CreateBillButton from "modules/bills/components/CreateBillButton";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import useCreateBillMutation from "modules/bills/mutations/useCreateBillMutation";
import { useAssignedRequestedBills } from "modules/bills/queries/assignedBillsQueryHooks";
import { useDraftBillsQuery } from "modules/bills/queries/useBills";
import { FC, useState, useMemo, useCallback } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import FeatureStatusBadge from "ui/data-display/FeatureStatusBadge";
import { notify } from "ui/feedback/Toast";
import BillPayIcon from "ui/icons/BillPayIcon";
import ButtonLink from "ui/inputs/ButtonLink";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import SegmentControls from "ui/navigation/SegmentControls";
import TabsV2 from "ui/navigation/TabsV2";
import FullPageModal from "ui/overlay/FullPageModal";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useHasPermission from "utils/permissions/useHasPermission";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

import BillDetailsPage from "../BillDetailsPage";
import BillPaymentPage from "../BillPaymentPage";
import BillPayOnboardingModal from "../BillPayOnboardingModal";
import LaunchHeroBanner from "../LaunchHeroBanner";
import LaunchHowItWorks from "../LaunchHowItWorks";

const BillsPageFullPageModal: FC = () => {
  const { billIdOrKeyword } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const shouldShowEmployeeView = useShouldShowEmployeeView();

  const onClose = useCallback(() => {
    // Do not preserve tabs specific to the bill details and payment views.
    searchParams.delete("detail-tab");
    searchParams.delete("bill-info-form-tab");
    searchParams.delete("payment-details");
    searchParams.delete("document");
    searchParams.delete("payment"); // Payment info flexpane (for bill related payments).

    searchParams.delete("bills"); // Bill IDs for payment view.
    navigate(`/bills?${searchParams}`);
  }, [searchParams, navigate]);

  const onCancelPaymentPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <FullPageModal onClose={onClose}>
      {billIdOrKeyword === "payment" && !shouldShowEmployeeView ? (
        <BillPaymentPage billIds={searchParams.getAll("bills")} onCancel={onCancelPaymentPage} />
      ) : (
        <BillDetailsPage billId={billIdOrKeyword!} />
      )}
    </FullPageModal>
  );
};

const BillsDashboardBreadcrumbs: FC = () => (
  <Breadcrumbs>
    <Breadcrumbs.CurrentItem>Bill Pay</Breadcrumbs.CurrentItem>
  </Breadcrumbs>
);

const BillsDashboardHeaderContent: FC = () => (
  <>
    <DashboardPage.Header.IconTile icon={<BillPayIcon />} />
    <DashboardPage.Header.Title>Bill Pay</DashboardPage.Header.Title>
    <FeatureStatusBadge featureStatus="Free preview" />
  </>
);

type UseApprovalsTabsOptions = {
  showNeedApprovalCount?: boolean;
};

const useApprovalsTabs = (options?: UseApprovalsTabsOptions) => {
  const { showNeedApprovalCount = true } = options ?? {};
  const assignedRequestedBills = useAssignedRequestedBills();
  const needApprovalCount = assignedRequestedBills.length;

  const tabConfig = useMemo(
    () =>
      ({
        "need-approval": {
          label: "Need approval",
          count: showNeedApprovalCount && needApprovalCount > 0 ? needApprovalCount : undefined,
        },
        approved: { label: "Approved" },
      }) satisfies TabConfig,
    [needApprovalCount, showNeedApprovalCount]
  );

  const [activeTab, setActiveTab] = useSearchParamOption(
    "approvals-tab",
    getObjectKeys(tabConfig),
    "need-approval"
  );

  return {
    tabConfig,
    activeTab,
    setActiveTab,
  } as const;
};

type ApprovalsSegmentControlsProps = ReturnType<typeof useApprovalsTabs>;

const ApprovalsSegmentControls: FC<ApprovalsSegmentControlsProps> = ({
  tabConfig,
  activeTab,
  setActiveTab,
}) => (
  <SegmentControls
    tabs={tabConfig}
    activeTab={activeTab}
    setActiveTab={setActiveTab}
    size="md"
    className="tablet:w-fit"
  />
);

const YourApprovalsView: FC = () => {
  const { tabConfig, activeTab, setActiveTab } = useApprovalsTabs({ showNeedApprovalCount: false });

  return (
    <>
      <ApprovalsSegmentControls
        tabConfig={tabConfig}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <div className="mt-8">
        {activeTab === "need-approval" && <AssignedRequestedBillsView />}
        {activeTab === "approved" && <AssignedGrantedBillsView />}
      </div>
    </>
  );
};

const ActivatedFullView: FC = () => {
  const isManualBillCreationEnabled = useFeatureFlag("BILL_PAY_MANUAL_BILL_CREATION");
  const isAllowedToNavigateToBillPaySettings = useHasPermission("accountsPayableSettings:read");

  const navigate = useNavigate();
  const { search } = useLocation();

  const { data: draftBills } = useDraftBillsQuery();
  const emailInboxCount = draftBills?.length;

  const assignedRequestedBills = useAssignedRequestedBills();
  const needApprovalCount = assignedRequestedBills.length;

  const billsPageTabs = {
    "all-bills": { label: "All bills" },
    approvals: { label: "Your approvals", count: needApprovalCount || undefined },
    "email-inbox": { label: "Email inbox", count: emailInboxCount || undefined },
  } satisfies TabConfig;

  const [activeTab, setActiveTab] = useSearchParamOption(
    "tab",
    getObjectKeys(billsPageTabs),
    "all-bills"
  );

  const { mutate: createBill, isPending } = useCreateBillMutation({
    onSuccess: (bill) => {
      navigate(`/bills/${bill.id}${search}`);
    },
  });

  const makeCreateBillVariables = () => ({
    amount: null,
    invoiceDate: null,
    invoiceDueDate: null,
    invoiceNumber: null,
    payeeGuid: null,
    memo: null,
    paymentTerms: null,
    purchaseOrderNumber: null,
  });

  return (
    <>
      <Routes>
        <Route path="/:billIdOrKeyword" element={<BillsPageFullPageModal />} />
      </Routes>

      <DashboardHeader>
        <BillsDashboardBreadcrumbs />
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header
          actions={
            <div className="flex w-full items-center gap-4">
              {isManualBillCreationEnabled && (
                <CreateBillButton
                  isLoading={isPending}
                  onClick={() => createBill(makeCreateBillVariables())}
                />
              )}
              {isAllowedToNavigateToBillPaySettings && (
                <ButtonLink variant="tertiary" paddingVariant="square" to="/bills/settings">
                  <GearIcon className="size-5" />
                </ButtonLink>
              )}
            </div>
          }
        >
          <BillsDashboardHeaderContent />
        </DashboardPage.Header>

        <TabsV2 activeTab={activeTab} setActiveTab={setActiveTab} tabs={billsPageTabs} />

        <DashboardPage.Section>
          {activeTab === "all-bills" && (
            <AllBillsView
              createBill={() => createBill(makeCreateBillVariables())}
              isCreatingBill={isPending}
            />
          )}
          {activeTab === "email-inbox" && <BillsEmailInboxView />}
          {activeTab === "approvals" && <YourApprovalsView />}
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

const LaunchFullView: FC = () => {
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <DashboardHeader>
        <BillsDashboardBreadcrumbs />
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <BillsDashboardHeaderContent />
        </DashboardPage.Header>

        <DashboardPage.Section>
          <LaunchHeroBanner onGetStarted={() => setIsOnboardingModalOpen(true)} />
        </DashboardPage.Section>

        <DashboardPage.Section>
          <LaunchHowItWorks />
        </DashboardPage.Section>
      </DashboardPage>

      {isOnboardingModalOpen && (
        <BillPayOnboardingModal
          onClose={() => setIsOnboardingModalOpen(false)}
          onComplete={() => {
            notify("success", "Welcome to Highbeam Bill Pay!");
            setIsOnboardingModalOpen(false);
            navigate("/bills?tab=email-inbox");
          }}
        />
      )}
    </>
  );
};

const FullView: FC = () => {
  const apEmailAliases = useApEmailAliases();

  return apEmailAliases.length > 0 ? <ActivatedFullView /> : <LaunchFullView />;
};

const EmployeeView: FC = () => {
  const { tabConfig, activeTab, setActiveTab } = useApprovalsTabs();

  return (
    <>
      <Routes>
        <Route path="/:billIdOrKeyword" element={<BillsPageFullPageModal />} />
      </Routes>

      <DashboardHeader>
        <BillsDashboardBreadcrumbs />
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.Title>Bill approvals</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <ApprovalsSegmentControls
          tabConfig={tabConfig}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <DashboardPage.Section>
          {activeTab === "need-approval" && <AssignedRequestedBillsView />}
          {activeTab === "approved" && <AssignedGrantedBillsView />}
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

const BillsPage: FC = () => {
  const shouldShowEmployeeView = useShouldShowEmployeeView();

  return shouldShowEmployeeView ? <EmployeeView /> : <FullView />;
};

export default BillsPage;
