import BillRep from "./BillRep";
import { Money } from "./Money";

namespace BillSummaryRep {
  export enum PaymentStatus {
    Overdue = "Overdue",
    Paid = "Paid",
    PartiallyPaid = "PartiallyPaid",
    Pending = "Pending",
    Scheduled = "Scheduled",
    Unpaid = "Unpaid",
  }

  export enum ApprovalStatus {
    Unassigned = "Unassigned",
    Partial = "Partial",
    Approved = "Approved",
  }

  export interface ApprovalSummary {
    readonly numberOfApprovals: number;
    readonly numberOfApprovalsRequested: number;
    readonly status: ApprovalStatus;
  }

  export interface Complete {
    readonly id: string;
    readonly businessGuid: string;

    readonly amount: Money | null;
    readonly duplicateOfBillId: string | null;
    readonly remainingAmount: Money | null;
    readonly state: BillRep.State;
    readonly invoiceDate: string | null; // LocalDate
    readonly invoiceDueDate: string | null; // LocalDate
    readonly invoiceNumber: string | null;
    readonly payeeGuid: string | null;
    readonly memo: string | null;
    readonly paymentTerms: string | null;
    readonly purchaseOrderNumber: string | null;
    readonly billEmailId: string | null;
    readonly sentByEmail: string | null;

    readonly paymentStatus: PaymentStatus;
    readonly approvalSummary: ApprovalSummary;
    readonly billDocumentsCount: number;
    readonly areBillDetailsEditable: boolean;
    readonly isReadyForPayment: boolean;
    readonly isClosedForAccounting: boolean;
    readonly canDeleteBill: boolean;

    readonly lineItemTotals: Money | null;
  }
}

export default BillSummaryRep;
