import React, { ReactNode } from "react";
import { Heading3 } from "ui/typography";

type Props = {
  children: ReactNode;
  name: string;
};

const ChatStatusItem: React.FC<Props> = ({ children, name }) => {
  return (
    <div className="flex flex-col gap-2">
      <Heading3>{name}</Heading3>
      {children}
    </div>
  );
};

export default ChatStatusItem;
