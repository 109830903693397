import OnboardingLayout from "layouts/OnboardingLayout";
import LoadingPage from "modules/loading/pages/LoadingPage";
import InvalidUserInvitation from "modules/user-invitations/components/InvalidUserInvitation";
import UserInvitationCard from "modules/user-invitations/components/UserInvitationCard";
import invitationBySlugQueryHooks from "modules/user-invitations/queries/invitationBySlugQueryHooks";
import { useParams } from "react-router-dom";

const InvitationPage = () => {
  const { invitationSlug } = useParams();

  const { isLoading, error, data } = invitationBySlugQueryHooks.useQuery({
    slug: invitationSlug ?? null,
  });

  if (isLoading) return <LoadingPage location={InvitationPage.name} />;

  return (
    <OnboardingLayout>
      <div className="mt-16 px-2 tablet:mt-32">
        {!error && data ? (
          <UserInvitationCard invitation={data.invitation} userExists={data.userExists} />
        ) : (
          <InvalidUserInvitation itemName="invitation" />
        )}
      </div>
    </OnboardingLayout>
  );
};

export default InvitationPage;
