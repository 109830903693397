import AccountLabel from "components/Accounts/AccountLabel";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import { useOpenBankAccountRequired } from "modules/bank-accounts/queries/bankAccountQueryHooks";
import CapitalAccountIcon from "modules/capital-accounts/components/CapitalAccountIcon";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import useCapitalDrawdownApproval from "modules/capital-drawdown-approvals/queries/useCapitalDrawdownApproval";
import { FC } from "react";
import Pill from "ui/data-display/Pill";
import { Span } from "ui/typography";
import Text from "ui/typography/Text";

type Props = {
  capitalAccountGuid: string;
  pendingDrawdownGuid: string;
};

const PendingDrawdownParties: FC<Props> = ({ capitalAccountGuid, pendingDrawdownGuid }) => {
  const pendingDrawdown = useCapitalDrawdownApproval({
    capitalAccountGuid: capitalAccountGuid,
    pendingDrawdownGuid: pendingDrawdownGuid,
    required: true,
  });
  const bankAccount = useOpenBankAccountRequired(pendingDrawdown.bankAccountGuid);
  const capitalAccountSummary = capitalAccountSummaryQueryHooks.useDataRequired({
    capitalAccountGuid,
  });

  return (
    <TransactionFlexpaneParties
      from={
        <div className="mb-0.5 flex items-center gap-x-1.5 text-grey-900">
          <CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} size={20} />
          <Text size={14} weight="medium">
            {capitalAccountSummary.name}
          </Text>
        </div>
      }
      status={
        <div className="flex items-center gap-x-2.5 rounded-full border border-grey-200 px-3 py-2">
          <Pill color="grey-200" bordered>
            Pending
          </Pill>
          <Span className="text-sm font-medium text-grey-800">Drawdown</Span>
        </div>
      }
      to={<AccountLabel bankAccount={bankAccount} />}
    />
  );
};

export default PendingDrawdownParties;
