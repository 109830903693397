import mapTransferOption from "modules/bank-accounts/utils/mapTransferOption";
import React from "react";
import BankAccountRep from "reps/BankAccountRep";
import Dropdown from "ui/inputs/Dropdown";

import styles from "./AccountDropdown.module.scss";

type Props = {
  accounts: BankAccountRep.Complete[];
  account: BankAccountRep.Complete | undefined;
  setAccount: (account: BankAccountRep.Complete) => void;
};

const AccountDropdown: React.FC<Props> = ({ accounts, account, setAccount }) => {
  return (
    <div className={styles.container}>
      <Dropdown
        label="Account"
        options={accounts.map(mapTransferOption)}
        value={account ? mapTransferOption(account) : null}
        onChange={(account) =>
          account && setAccount(accounts.find((it) => it.guid === account.guid)!)
        }
        isSearchable={false}
      />
    </div>
  );
};

export default AccountDropdown;
