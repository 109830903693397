import dayjs from "dayjs";
import React from "react";
import { MonthChatElement } from "reps/chat/ChatElement";

type Props = {
  element: MonthChatElement;
};

const ResponseMessageMonth: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value).tz("America/New_York").format("MMMM YYYY")}</span>;
};

export default ResponseMessageMonth;
