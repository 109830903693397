import {
  ArrowLineUpRight as ArrowLineUpRightIcon,
  FileText as FileTextIcon,
} from "@phosphor-icons/react";
import successGif from "assets/success.gif";
import { BankAccountBarByGuid } from "modules/bank-accounts/components/BankAccountBar";
import ExchangeRateQuote from "modules/international-wires/components/ExchangeRateQuote";
import { SwiftFeeTooltipContent } from "modules/international-wires/constants";
import PayeeNameCell from "modules/payees/components/PayeeNameCell";
import ExchangeRateInfoIcon from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/ExchangeRateInfoIcon";
import {
  PaymentMethod as SendMoneyPaymentMethod,
  getSendMoneyDeliveryMessage,
  SWIFT_FEE,
} from "pages/SendMoneyPage/utils";
import { FC, useMemo } from "react";
import PaymentRep from "reps/payments-v2/PaymentRep";
import Divider from "ui/data-display/Divider";
import CashDisplay from "ui/data-display/money/CashDisplay";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
//import Shimmer from "ui/feedback/ShimmerV2";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import useFullPageModalContext from "ui/overlay/FullPageModal/context/useFullPageModalContext";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Heading2, Span } from "ui/typography";
import { formatDate } from "utils/date";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import useReadyForPaymentBill from "../../queries/useReadyForPaymentBill";

const SuccessIconThingy: FC = () => (
  // NB(lev): This success gif implementation is copied from Send Money for now. The randomized query param is a copied-over hack to prevent the browser from caching the gif. https://github.com/highbeamco/highbeam/pull/1125
  <img
    src={`${successGif}?a=${Math.random()}`}
    alt="success"
    className="-ml-[28px] -mt-[28px] h-[70px] w-[70px] tablet-landscape:h-[90px] tablet-landscape:w-[90px]"
  />
);

const DetailsTable: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <table className={cn("w-full border-none", className)}>
    <tbody>{children}</tbody>
  </table>
);

const DetailsTableRow: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <tr className={cn("border-none", className)}>{children}</tr>
);

const DetailsTableTh: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <th className={cn("w-1/3 py-2.5 text-sm font-regular text-grey-500", className)}>{children}</th>
);

const DetailsTableTd: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <td className={cn("py-2.5 text-sm font-medium", className)}>{children}</td>
);

type Props = {
  billId: string;
  payment: PaymentRep.Complete;
};

const BillPaymentSentView: FC<Props> = ({ billId, payment }) => {
  const bill = useReadyForPaymentBill(billId, false);
  const { bankAccountGuid, sentAt, detail, paymentSenderResponse } = payment;
  const { Ach, DomesticWire, InternationalWire } = detail;
  // NB(lev): This is currently only present for ACH/domestic wires.
  const unitPaymentId = paymentSenderResponse.id;

  const isInternationalWireWithSwiftFee =
    InternationalWire &&
    InternationalWire.paymentType === "Priority" &&
    InternationalWire.receiveCurrency === "USD";

  const deliveryMessage = useMemo(() => {
    if (Ach) {
      return getSendMoneyDeliveryMessage(
        Ach.sameDay ? SendMoneyPaymentMethod.SAME_DAY_ACH : SendMoneyPaymentMethod.ACH,
        false
      );
    }
    if (DomesticWire) {
      return getSendMoneyDeliveryMessage(SendMoneyPaymentMethod.WIRE, false);
    }
    if (InternationalWire) {
      return getSendMoneyDeliveryMessage(SendMoneyPaymentMethod.INTERNATIONAL, false);
    }
  }, [Ach, DomesticWire, InternationalWire]);

  const { onClose } = useFullPageModalContext();

  return (
    <div className="size-full bg-grey-50">
      <div className="mx-auto mb-4 mt-8 flex w-full flex-col gap-6 px-4 tablet:mt-24 tablet:w-[41.5rem]">
        <div>
          <SuccessIconThingy />
          <Heading2 className="-mt-2">Payment sent</Heading2>
        </div>

        <div className="flex flex-col gap-4 rounded border border-grey-100 bg-white p-6 text-grey-900 shadow-xs">
          <div>
            {InternationalWire && isInternationalWireWithSwiftFee && (
              <DetailsTable>
                <DetailsTableRow>
                  <DetailsTableTh>Payee receives</DetailsTableTh>
                  <DetailsTableTd>
                    <CashDisplay cents={payment.amount} withCents />
                  </DetailsTableTd>
                </DetailsTableRow>
                <DetailsTableRow>
                  <DetailsTableTh>
                    <div className="flex items-center gap-1">
                      SWIFT fee{" "}
                      <IconWithTooltip color="light" tooltip={<SwiftFeeTooltipContent />} />
                    </div>
                  </DetailsTableTh>
                  <DetailsTableTd>
                    <MoneyAmount cents={SWIFT_FEE} currency="USD" trailingCurrencyCode />
                  </DetailsTableTd>
                </DetailsTableRow>
                <DetailsTableRow>
                  <DetailsTableTh>You pay</DetailsTableTh>
                  <DetailsTableTd>
                    <MoneyAmount
                      cents={payment.amount + SWIFT_FEE}
                      currency="USD"
                      trailingCurrencyCode
                    />
                  </DetailsTableTd>
                </DetailsTableRow>
              </DetailsTable>
            )}
            {InternationalWire && !isInternationalWireWithSwiftFee && (
              <DetailsTable>
                <DetailsTableRow>
                  <DetailsTableTh>You pay</DetailsTableTh>
                  <DetailsTableTd>
                    <CashDisplay cents={payment.amount} withCents />
                  </DetailsTableTd>
                </DetailsTableRow>
                <DetailsTableRow>
                  <DetailsTableTh>
                    <div className="flex items-center gap-1">
                      Rate <ExchangeRateInfoIcon color="light" />
                    </div>
                  </DetailsTableTh>
                  <DetailsTableTd>
                    <ExchangeRateQuote currency={InternationalWire.receiveCurrency} />
                  </DetailsTableTd>
                </DetailsTableRow>
                <DetailsTableRow>
                  <DetailsTableTh>Payee receives</DetailsTableTh>
                  <DetailsTableTd>
                    <MoneyAmount
                      cents={InternationalWire.receiveAmount}
                      currency={InternationalWire.receiveCurrency}
                      trailingCurrencyCode
                    />
                  </DetailsTableTd>
                </DetailsTableRow>
              </DetailsTable>
            )}
            {!InternationalWire && (
              <>
                <div className="flex items-center gap-1 text-grey-800">
                  <ArrowLineUpRightIcon className="size-4" />
                  <Span className="text-sm text-inherit">Amount</Span>
                </div>
                <CashDisplay cents={payment.amount} withCents />
              </>
            )}
          </div>

          <Divider className="my-0" />

          <DetailsTable>
            <DetailsTableRow>
              <DetailsTableTh>Bill(s) paid</DetailsTableTh>
              <DetailsTableTd>
                <Pill
                  color="grey-100"
                  iconLeft={({ sizeClassName }) => <FileTextIcon className={sizeClassName} />}
                  className="text-grey-800"
                >
                  {bill.invoiceNumber}
                </Pill>
              </DetailsTableTd>
            </DetailsTableRow>
            <DetailsTableRow>
              <DetailsTableTh>From</DetailsTableTh>
              <DetailsTableTd>
                <BankAccountBarByGuid bankAccountGuid={bankAccountGuid} />
              </DetailsTableTd>
            </DetailsTableRow>

            {Ach && (
              <DetailsTableRow>
                <DetailsTableTh>To</DetailsTableTh>
                <DetailsTableTd>{Ach.payeeName}</DetailsTableTd>
              </DetailsTableRow>
            )}
            {DomesticWire && (
              <DetailsTableRow>
                <DetailsTableTh>To</DetailsTableTh>
                <DetailsTableTd>{DomesticWire.payeeName}</DetailsTableTd>
              </DetailsTableRow>
            )}
            {InternationalWire && (
              <DetailsTableRow>
                <DetailsTableTh>To</DetailsTableTh>
                <DetailsTableTd>
                  <PayeeNameCell payeeGuid={InternationalWire.payeeGuid} shimmerClassName="w-32" />
                </DetailsTableTd>
              </DetailsTableRow>
            )}

            {Ach && (
              <>
                <DetailsTableRow>
                  <DetailsTableTh>Routing #</DetailsTableTh>
                  <DetailsTableTd>{Ach.routingNumber}</DetailsTableTd>
                </DetailsTableRow>
                <DetailsTableRow>
                  <DetailsTableTh>Account #</DetailsTableTh>
                  <DetailsTableTd>{Ach.accountNumber}</DetailsTableTd>
                </DetailsTableRow>
              </>
            )}
            {DomesticWire && (
              <>
                <DetailsTableRow>
                  <DetailsTableTh>Routing #</DetailsTableTh>
                  <DetailsTableTd>{DomesticWire.routingNumber}</DetailsTableTd>
                </DetailsTableRow>
                <DetailsTableRow>
                  <DetailsTableTh>Account #</DetailsTableTh>
                  <DetailsTableTd>{DomesticWire.accountNumber}</DetailsTableTd>
                </DetailsTableRow>
              </>
            )}

            {sentAt && (
              <DetailsTableRow>
                <DetailsTableTh>Payment date</DetailsTableTh>
                <DetailsTableTd>{formatDate(sentAt, "MM/DD/YYYY")}</DetailsTableTd>
              </DetailsTableRow>
            )}

            <DetailsTableRow>
              <DetailsTableTh>Delivery</DetailsTableTh>
              <DetailsTableTd>{deliveryMessage}</DetailsTableTd>
            </DetailsTableRow>
          </DetailsTable>
        </div>

        <div className="flex items-center gap-5">
          <Button variant="tertiary" onClick={onClose}>
            Close
          </Button>
          <ButtonLink
            variant="secondary"
            to={`/bills/${billId}${unitPaymentId ? `?payment=${unitPaymentId}` : ""}`}
          >
            View payment
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default BillPaymentSentView;
