import useExchanges from "modules/chat/queries/useExchanges";
import chatDebugModeState from "modules/chat/state/chatDebugMode";
import React, { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import cn from "utils/tailwind/cn";

import Exchange from "./Exchange";

type Props = {
  className?: string;
  conversationId: string;
};

const ChatConversationSection: React.FC<Props> = ({ className, conversationId }) => {
  const exchanges = useExchanges(conversationId);
  const chatDebugMode = useRecoilValue(chatDebugModeState);

  const conversationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const conversation = conversationRef.current!;
    conversation.scrollTop = conversation.scrollHeight;
  }, [exchanges]);

  return (
    <div className={cn("flex flex-col items-center", className)} ref={conversationRef}>
      <div
        className={cn("flex w-full flex-col px-8 py-3", chatDebugMode ? "max-w-6xl" : "max-w-3xl")}
      >
        {exchanges.map((exchange, i) => (
          <Exchange key={exchange.id} exchange={exchange} isLast={i === exchanges.length - 1} />
        ))}
      </div>
    </div>
  );
};

export default ChatConversationSection;
