import { ApprovablePaymentRequest } from "modules/payment-requests/types/paymentRequest";
import PaymentRep from "reps/payments-v2/PaymentRep";

const convertToPaymentRequest = (payment: PaymentRep.Complete): ApprovablePaymentRequest | null => {
  const { detail, ...rest } = payment;
  const { InternationalWire, UnitPayment } = detail;

  if (InternationalWire) {
    return {
      type: "international-wire",
      ...InternationalWire,
      ...rest,
      paymentReason: InternationalWire.reason,
    };
  }

  if (UnitPayment) {
    return {
      type: "unit-payment",
      ...UnitPayment,
      ...rest,
    };
  }

  return null;
};

export default convertToPaymentRequest;
