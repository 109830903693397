import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { useMemo } from "react";
import PaymentSwitcherAutoPaymentGroupRep from "reps/PaymentSwitcherAutoPaymentGroupRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const PAYMENT_SWITCHER_AUTO_PAYMENTS_QUERY_KEY = "paymentSwitcherAutoPayments";

export const usePaymentSwitcherAutoPaymentsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [PAYMENT_SWITCHER_AUTO_PAYMENTS_QUERY_KEY, businessGuid],
    queryFn: () => {
      return highbeamApi.paymentSwitcherAutoPayment.search(businessGuid);
    },
  });
};

const usePaymentSwitcherAutoPayments = (
  filter: (group: PaymentSwitcherAutoPaymentGroupRep) => boolean
) => {
  const { data } = useSuspenseQuery(usePaymentSwitcherAutoPaymentsQueryOptions());
  return useMemo(() => data.filter(filter), [data, filter]);
};

export default usePaymentSwitcherAutoPayments;
