import { captureMessage } from "@sentry/react";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BillDocumentRep from "reps/BillDocumentRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadWith404Retry } from "utils/download";
import { useEventBusDispatcher } from "utils/event-bus";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useBillDocumentsQuery } from "../queries/useBillDocuments";

import { uploadBillDocuments } from "./useUploadBillDocumentsMutation";

export const BILL_INVOICE_UPLOADED_EVENT = "billInvoiceUploaded";

export type BillInvoiceUploadedEvent = CustomEvent<{
  billId: string;
}>;

type Variables = {
  file: File;
};

const useUploadBillInvoiceDocumentMutation = (
  billId: string,
  additionalOptions: MutationAdditionalOptions<void, Variables> = {}
) => {
  const { refetch: refetchBillDocuments } = useBillDocumentsQuery({
    billId,
    type: [BillDocumentRep.DocumentType.Invoice],
  });
  const dispatchInvoiceUploadedEvent = useEventBusDispatcher<BillInvoiceUploadedEvent>();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ file }: Variables) => {
        const { associatedBillDocuments } = await uploadBillDocuments({
          highbeamApi: highbeamApi,
          businessGuid: businessGuid,
          billId: billId,
          files: [file],
          type: BillDocumentRep.DocumentType.Invoice,
        });

        // Should never happen
        if (associatedBillDocuments.length !== 1) {
          captureMessage(
            `Alert(alex): Expected "associatedBillDocuments.length" to be 1, but it was ${associatedBillDocuments.length}.`
          );
        }

        const invoiceBillDocument = associatedBillDocuments[0];
        // NB(lev): Hack alert.
        // The `signedDocumentUrl` on invoiceBillDocument is initially the URL for the non-virus-scanned asset.
        // We need to refetch the document to get the URL for the virus-scanned asset. This alone isn't so bad,
        // given that we would want to invalidate/refetch the bill documents query after the upload anyway
        // (so, here, we accomplish that indirectly by explicitly refetching the bill documents query).
        // With the refetched data, we can get the new signedDocumentUrl.

        const { data: refreshedBillDocuments } = await refetchBillDocuments();
        const refreshedInvoiceDocument = refreshedBillDocuments?.find(
          ({ id }) => id === invoiceBillDocument.id
        );

        if (!refreshedInvoiceDocument) {
          throw new Error("Failed to fetch bill document");
        }

        // We don't technically need to download this document, but awaiting this allows us to ensure
        // it's finished virus-scanning before we proceed to call the billDocumentAction.process endpoint.
        await downloadWith404Retry(refreshedInvoiceDocument.signedDocumentUrl);

        // We await this to know when bill details extraction from the invoice is complete.
        // Extraction will actually happen on the backend whether or not we call this endpoint,
        // but doing so allows us to know when it's completed (and subsequently update the bill details form
        // with extracted data).
        await highbeamApi.billDocumentAction.process(refreshedInvoiceDocument.id);
      },
      onSuccess: () => {
        dispatchInvoiceUploadedEvent(
          new CustomEvent(BILL_INVOICE_UPLOADED_EVENT, {
            detail: { billId },
          })
        );
      },
    },
    additionalOptions
  );
};

export default useUploadBillInvoiceDocumentMutation;
