import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import TextArea from "ui/inputs/TextArea";
import { Paragraph, Span } from "ui/typography";

import ChatSettingsItem from "./ChatSettingsItem";
import { ChatConfigSettingsFormInputs } from "./useChatConfigSettingsForm";

type Props = {
  control: Control<ChatConfigSettingsFormInputs>;
};

const ChatSettingsDetailsItem: React.FC<Props> = ({ control }) => {
  return (
    <ChatSettingsItem name="Details">
      <Paragraph className="text-sm">
        Provide a short description about the business. This context will be provided to the LLM.{" "}
        <Span className="font-bold">Do not</Span> include anything except a short description.
      </Paragraph>
      <Controller
        name="details"
        control={control}
        render={({ field }) => <TextArea {...field} />}
      />
    </ChatSettingsItem>
  );
};

export default ChatSettingsDetailsItem;
