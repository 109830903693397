import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const AiChatTableBody: React.FC<Props> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export default AiChatTableBody;
