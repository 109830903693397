import { useSuspenseQuery } from "@tanstack/react-query";
import chatDebugModeState from "modules/chat/state/chatDebugMode";
import { useRecoilValue } from "recoil";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const EXCHANGES_QUERY_KEY = "exchanges";

const useExchangesQueryOptions = (conversationId: string) => {
  const highbeamApi = useHighbeamApi();
  const chatDebugMode = useRecoilValue(chatDebugModeState);

  return useQueryOptions({
    queryKey: [EXCHANGES_QUERY_KEY, { conversationId, debugMode: chatDebugMode }],
    queryFn: () => {
      return highbeamApi.exchange.listByConversation(conversationId, { debugMode: chatDebugMode });
    },
    refetchInterval: (query) => {
      if (query.state.status !== "success") return false;
      const exchanges = query.state.data;
      if (exchanges?.at(-1)?.state === "Initial") return 600;
      return false;
    },
  });
};

const useExchanges = (conversationId: string) => {
  const { data } = useSuspenseQuery(useExchangesQueryOptions(conversationId));
  if (!data) throw new RequiredButNotFoundError();
  return data;
};

export default useExchanges;
