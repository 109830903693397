import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { UNIT_RECEIVED_PAYMENTS_QUERY_KEY } from "modules/unit-co-received-payments/queries/useUnitCoReceivedPaymentsQueryOptions";
import { FC, ReactNode, createContext, useContext } from "react";

type ReceivedPaymentInfoFlexpaneContextState = {
  receivedPaymentId: string;
  onClose: () => void;
};

const ReceivedPaymentInfoFlexpaneContext = createContext<ReceivedPaymentInfoFlexpaneContextState>(
  {} as ReceivedPaymentInfoFlexpaneContextState
);

type Props = {
  children: ReactNode;
  receivedPaymentId: string;
  onClose: () => void;
};

const ReceivedPaymentInfoFlexpaneProvider: FC<Props> = ({
  children,
  receivedPaymentId,
  onClose,
}) => {
  return (
    <ReceivedPaymentInfoFlexpaneContext.Provider
      value={{
        receivedPaymentId,
        onClose: onClose,
      }}
    >
      {children}
    </ReceivedPaymentInfoFlexpaneContext.Provider>
  );
};

export default ReceivedPaymentInfoFlexpaneProvider;

export const useReceivedPaymentInfoFlexpaneContext = () => {
  const { receivedPaymentId, onClose } = useContext(ReceivedPaymentInfoFlexpaneContext);
  const receivedPayment = useReceivedPayment(receivedPaymentId);

  return {
    receivedPayment,
    onClose,
  };
};

const useReceivedPayment = (receivedPaymentId: string) => {
  const unitApi = useUnitApi();
  const { data: receivedPayment } = useSuspenseQuery({
    queryKey: [UNIT_RECEIVED_PAYMENTS_QUERY_KEY, { receivedPaymentId }],
    queryFn: async () => {
      const { data } = await unitApi.receivedPayments.get(receivedPaymentId);
      return data;
    },
  });
  return receivedPayment;
};
