import { CardStatus } from "@highbeam/unit-node-sdk";
import { Archive, Lock } from "@phosphor-icons/react";
import getCardStatusCopy from "modules/cards/utils/getCardStatusCopy";
import { FC } from "react";
import Pill, { PillColor, PillSize } from "ui/data-display/Pill";
import { IconRenderFunction } from "utils/react-helpers/IconRenderFunction";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

type Props = {
  status: CardStatus;
  size?: PillSize;
  className?: string;
};

const CardStatusPill: FC<Props> = ({ status, size = "xs", className }) => {
  const icon = variants(status, {
    Active: undefined,
    Inactive: undefined,
    Stolen: undefined,
    Lost: undefined,
    Frozen: ({ sizeClassName }) => <Lock className={sizeClassName} />,
    ClosedByCustomer: ({ sizeClassName }) => <Archive className={sizeClassName} />,
    SuspectedFraud: undefined,
  } satisfies Record<CardStatus, IconRenderFunction | undefined>);

  const pillColor: PillColor = variants(status, {
    Active: "green-100",
    Inactive: "grey-100",
    Stolen: "red-200",
    Lost: "grey-100",
    Frozen: "grey-100",
    ClosedByCustomer: "yellow-500",
    SuspectedFraud: "red-200",
  } satisfies Record<CardStatus, PillColor>);

  return (
    <Pill color={pillColor} size={size} iconLeft={icon} className={cn("font-bold", className)}>
      {getCardStatusCopy(status)}
    </Pill>
  );
};

export default CardStatusPill;
