import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const BASE_CARD_LIMIT_QUERY_KEY = "unit-co-card-limit";

type QueryKeyParams = {
  cardId: string;
};

const makeQueryKey = ({ cardId }: QueryKeyParams) => [BASE_CARD_LIMIT_QUERY_KEY, { cardId }];

export const useRefreshCardLimitsQuery = (params: QueryKeyParams) => {
  return useRefreshQuery(makeQueryKey(params));
};

type Params = QueryKeyParams;

const useCardLimitsQueryOptions = ({ cardId }: Params) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: makeQueryKey({ cardId }),
    queryFn: async () => {
      return (await unitApi.cards.limits(cardId)).data;
    },
  });
};

const useCardLimits = (params: Params) => {
  return useSuspenseQuery(useCardLimitsQueryOptions(params)).data;
};

export default useCardLimits;
