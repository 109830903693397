import React from "react";
import { ExchangeResponseMessage } from "reps/chat/MessageRep";

import ResponseElement from "./ResponseElement";
import ResponseMessageContainer from "./ResponseMessageContainer";

type Props = {
  message: ExchangeResponseMessage;
};

const ResponseMessage: React.FC<Props> = ({ message }) => {
  return (
    <ResponseMessageContainer>
      {message.elements.map((element, i) => (
        <ResponseElement key={i} element={element} />
      ))}
    </ResponseMessageContainer>
  );
};

export default ResponseMessage;
