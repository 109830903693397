import { FC } from "react";
import FeatureStatusBadge, { FeatureStatus } from "ui/data-display/FeatureStatusBadge";

type Props = {
  featureStatus: FeatureStatus;
  isActive: boolean;
};

const NavFeatureStatusBadge: FC<Props> = ({ featureStatus, isActive }) => (
  <FeatureStatusBadge
    featureStatus={featureStatus}
    color={isActive ? "custom" : "purple-100"}
    className={isActive ? "bg-grey-200 text-purple-500" : ""}
  />
);

export default NavFeatureStatusBadge;
