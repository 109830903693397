import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import useCapitalAccountAutoPayBankAccount from "modules/capital-accounts/queries/useCapitalAccountAutoPayBankAccount";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import MetadataList from "ui/data-display/MetadataList";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountCardAutoPayBankAccountItem: FC<Props> = ({ capitalAccountSummary }) => {
  const capitalAccountAutoPayBankAccount =
    useCapitalAccountAutoPayBankAccount(capitalAccountSummary);

  return (
    <MetadataList.Item>
      <MetadataList.ItemLabel>Auto-pay account</MetadataList.ItemLabel>
      <MetadataList.ItemValue>
        <BankAccountBar bankAccount={capitalAccountAutoPayBankAccount} />
      </MetadataList.ItemValue>
    </MetadataList.Item>
  );
};

export default CapitalAccountCardAutoPayBankAccountItem;
