import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamAtmTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamAtmTransaction;
};

const AtmTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>ATM transaction details</Heading3>
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>ATM name</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.atmName}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>ATM surcharge</MetadataList.ItemLabel>
        <MetadataList.ItemValue className="normal-nums">
          {transaction.formattedSurcharge}
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default AtmTransactionDetailsSection;
