import { withErrorBoundary } from "@sentry/react";
import dayjs from "dayjs";
import OnboardingLayout from "layouts/OnboardingLayout";
import useUpdateBusinessMemberMutation from "modules/business-members/mutations/useUpdateBusinessMemberMutation";
import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import OnboardingPersonalInfoCard, {
  OnboardingPersonalInfoFormOutputs,
} from "modules/onboarding/components/OnboardingPersonalInfoCard";
import useGetStartedMutation from "modules/onboarding/mutations/useGetStartedMutation";
import { Navigate, useNavigate } from "react-router-dom";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { notify } from "ui/feedback/Toast";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

const OnboardingPersonalInfoPage = () => {
  const navigate = useNavigate();

  const { mutateAsync: getStarted, isPending: isGetStartedPending } = useGetStartedMutation({
    onSuccess: () => {
      navigate("/onboarding/get-started");
    },
  });

  const businessMember = useCurrentBusinessMember();
  const isSuperusering = useIsSuperusering();

  // NB(alex): This should only happen when superusering. Related: https://linear.app/highbeam/issue/HB-4436/cards-page-broken-during-superuser#comment-28d37919
  if (!isSuperusering && !businessMember) {
    notify("error", "We couldn't find your account. Please try again later.");
    throw new RequiredButNotFoundError();
  }

  const { mutateAsync: updateBusinessMember, isPending: isUpdateBusinessMemberPending } =
    useUpdateBusinessMemberMutation();

  const onSubmit = async (data: OnboardingPersonalInfoFormOutputs) => {
    if (!businessMember) {
      // NB(alex): This should only occur when superusering.
      return notify("error", "We couldn't find your account. Please try again later.");
    }

    await updateBusinessMember({
      memberGuid: businessMember.guid,
      dateOfBirth: data.dateOfBirth.format("YYYY-MM-DD"),
    });
    await getStarted();
  };

  return (
    <OnboardingLayout>
      <OnboardingPersonalInfoCard
        defaultValues={{
          firstName: businessMember?.firstName ?? "",
          lastName: businessMember?.lastName ?? "",
          dateOfBirth: businessMember?.dateOfBirth ? dayjs(businessMember.dateOfBirth) : null,
        }}
        onSubmit={onSubmit}
        isLoading={isUpdateBusinessMemberPending || isGetStartedPending}
      />
    </OnboardingLayout>
  );
};

export default withErrorBoundary(OnboardingPersonalInfoPage, {
  fallback: ({ error }) => {
    if (error instanceof RequiredButNotFoundError) {
      return <Navigate to="/" />;
    } else {
      throw error;
    }
  },
});
