/**
 * Maps a union type to corresponding values in an object, ensuring type safety.
 *
 * Usage:
 * ```ts
 * const myVariants = "a" | "b";
 * const myValue = variants(myVariants, {
 *   a: "a",
 *   b: "b",
 * });
 *
 * // ❌ Will throw a ts error because key "b" is missing!
 * const invalidValue = variants(myVariants, {
 *   a: "a",
 * });
 * ```
 *
 * NB(alex): It essentially lets you do this, but in a type-safe way:
 * ```ts
 * const myVariants = "a" | "b";
 * const myValue = {
 *   a: "a",
 *   b: "b",
 * };
 * ```
 *
 * @param value - A key from the union type.
 * @param options - An object where keys are the union members and values are the associated outputs.
 * @returns The value corresponding to the provided key.
 */
const variants = <T extends string | number, U extends Record<T, unknown>>(
  value: T,
  options: U
): U[T] => {
  return options[value];
};

export default variants;
