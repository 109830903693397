import { notify } from "ui/feedback/Toast";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillApi from "../api/useBillApi";
import useRefreshBillsQueries from "../queries/useRefreshBillsQueries";

const useDeleteBillMutation = (
  billId: string,
  additionalOptions: MutationAdditionalOptions<void, void> = {}
) => {
  const billApi = useBillApi();
  const refreshBillsQueries = useRefreshBillsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: () => {
        return billApi.delete(billId);
      },
      onError: (error) => {
        notify("error", error.message ?? "Something went wrong! Please try again.");
      },
      onSuccess: () => {
        // NB(alex): We don't want to `await` this because it crashes the bill details modal. We need to figure out a better pattern...
        refreshBillsQueries();
        notify("success", "Bill deleted");
      },
    },
    additionalOptions
  );
};

export default useDeleteBillMutation;
