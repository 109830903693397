import {
  Meta,
  RecurringPayment as UnitRecurringPayment,
  RecurringPaymentListParams,
  UnitResponse,
} from "@highbeam/unit-node-sdk";
import useBusinessUnitCoCustomerId from "modules/business/queries/useBusinessUnitCoCustomerId";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { MergeDeep, Simplify } from "type-fest";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY = "unit-co-recurring-payments";

type RecurringPayment = MergeDeep<
  UnitRecurringPayment,
  {
    attributes: {
      tags?: {
        recipientGuid?: string;
      };
    };
  }
>;

type RecurringPaymentsResponse = UnitResponse<RecurringPayment[]> & Meta;

export type RecurringPaymentParams = Simplify<
  RecurringPaymentListParams & {
    customerId?: never; // Always use the customerId from the business state
    recipientGuid?: string;
  }
>;

const useUnitCoRecurringPaymentsQueryOptions = ({
  recipientGuid,
  ...params
}: RecurringPaymentParams) => {
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId({ required: true });

  return useQueryOptions({
    queryKey: [UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY, customerId, recipientGuid, params],
    queryFn: async () => {
      const response: RecurringPaymentsResponse = await unitApi.recurringPayments.list({
        sort: "-createdAt",
        customerId: customerId,
        status: ["Active"],
        ...params,
      });

      if (recipientGuid) {
        response.data = response.data.filter(
          (recurringPayment) => recurringPayment.attributes.tags?.recipientGuid === recipientGuid
        );
      }

      const sortedRecurringPayments = response.data.sort(
        (a, b) =>
          Date.parse(a.attributes.schedule.nextScheduledAction) -
          Date.parse(b.attributes.schedule.nextScheduledAction)
      );

      return {
        recurringPayments: sortedRecurringPayments,
        pagination: response.meta.pagination,
      };
    },
  });
};

export default useUnitCoRecurringPaymentsQueryOptions;
