import { useQuery } from "@tanstack/react-query";
import { getCounterpartyIdFromRecurringPayment } from "modules/recurring-payments/utils/getCounterpartyIdFromRecurringPayment";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import useUnitCoRecurringPayment from "modules/unit-co-recurring-payments/queries/useUnitCoRecurringPayment";
import MetadataList from "ui/data-display/MetadataList";
import Flexpane from "ui/overlay/Flexpane";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";

import { useRecurringPaymentFlexpaneContext } from "../context/RecurringPaymentFlexpaneProvider";

const RecurringPaymentDetailsSection = () => {
  const { recurringPaymentId } = useRecurringPaymentFlexpaneContext();
  const recurringPayment = useUnitCoRecurringPayment(recurringPaymentId, { required: true });

  const unitApi = useUnitApi();

  const counterpartyId = getCounterpartyIdFromRecurringPayment(recurringPayment);

  const { data: counterparty } = useQuery({
    queryKey: ["counteryparty", { counterpartyId }],
    queryFn: async () => {
      const { data: counterparty } = await unitApi.counterparties.get(counterpartyId);
      return counterparty;
    },
  });

  if (!counterparty) {
    return null;
  }

  return (
    <>
      <Flexpane.SectionHeading>ACH details</Flexpane.SectionHeading>

      <MetadataList>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            <ItemWithCopyTextTooltip textToCopy={counterparty.attributes.routingNumber}>
              {counterparty.attributes.routingNumber}
            </ItemWithCopyTextTooltip>
          </MetadataList.ItemValue>
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            <ItemWithCopyTextTooltip textToCopy={counterparty.attributes.accountNumber}>
              {counterparty.attributes.accountNumber}
            </ItemWithCopyTextTooltip>
          </MetadataList.ItemValue>
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{counterparty.attributes.accountType}</MetadataList.ItemValue>
        </MetadataList.Item>
        {"addenda" in recurringPayment.attributes && recurringPayment.attributes.addenda && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{recurringPayment.attributes.addenda}</MetadataList.ItemValue>
          </MetadataList.Item>
        )}
      </MetadataList>
    </>
  );
};

export default RecurringPaymentDetailsSection;
