import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import MetadataList from "ui/data-display/MetadataList";
import { roundEpsilon } from "utils/math";
import variants from "utils/ts/variants";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const LineOfCreditDetailsSection: React.FC<Props> = ({ capitalAccountSummary }) => {
  return (
    <MetadataList>
      {capitalAccountSummary.details.netApr > 0 && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>APR</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {roundEpsilon(capitalAccountSummary.details.netApr * 100, 2)}%
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}

      <MetadataList.Item>
        <MetadataList.ItemLabel>Repayment term</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          {capitalAccountSummary.details.targetRepaymentDays} days
        </MetadataList.ItemValue>
      </MetadataList.Item>

      {(capitalAccountSummary.details.lineType === CapitalAccountDetailsRep.LineType.Advance ||
        capitalAccountSummary.details.lineType ===
          CapitalAccountDetailsRep.LineType.InRepayment) && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Line type</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {variants(capitalAccountSummary.details.lineType, {
              [CapitalAccountDetailsRep.LineType.Advance]: "Advance",
              [CapitalAccountDetailsRep.LineType.InRepayment]: "In repayment",
            })}
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}

      {capitalAccountSummary.details.securedStatus && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Collateral</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {capitalAccountSummary.details.securedStatus}
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </MetadataList>
  );
};

export default LineOfCreditDetailsSection;
