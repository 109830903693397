import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useCashFlowSyncSheetMutation = (
  additionalOptions?: MutationAdditionalOptions<void, void>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutationWithDefaults(
    {
      mutationFn: async () => highbeamApi.insightsSheet.sync(businessGuid),
      onError: () => {
        notify("error", "Something went wrong! Please try again.");
      },
      onSuccess: async () => {
        notify("info", "Sync in progress - this may take a couple minutes.");
      },
    },
    additionalOptions ?? {}
  );
};

export default useCashFlowSyncSheetMutation;
