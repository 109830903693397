import { zodResolver } from "@hookform/resolvers/zod";
import addressSchema from "modules/address-autocomplete/addressSchema";
import useBusinessAddress from "modules/business-details/queries/useBusinessAddress";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = addressSchema;

type BusinessAddressFormInputs = z.infer<typeof schema>;

const useBusinessAddressForm = () => {
  const { line1, line2, city, state, postalCode, country } = useBusinessAddress();

  return useForm<BusinessAddressFormInputs>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      autocompleteOption: {
        fullAddress: "",
        googleMapsPlaceId: "",
        streetAddress: line1,
      },
      street1: line1,
      street2: line2 ?? "",
      city,
      state,
      postalCode,
      country,
    },
  });
};

export default useBusinessAddressForm;
