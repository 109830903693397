import useBill from "modules/bills/queries/useBill";
import { useState, useEffect } from "react";

import shouldBillSyncBeDryRun from "../utils/shouldBillSyncBeDryRun";
import wasBillSyncDryRun from "../utils/wasBillSyncDryRun";

import useBillSyncQuery from "./useBillSyncQuery";
import useBillSyncRunDispatcher from "./useBillSyncRunDispatcher";
import useBillSyncViewIsEnabled from "./useBillSyncViewIsEnabled";

// When a bill details page is loaded, we want to kick off a bill sync if-and-only-if:
// - Accounting sync is enabled and the bill is not closed for accounting.
// - The bill doesn't already have a bill sync *or* the bill is not a draft and
// its last bill sync was a dry run (in this case, we want to kickoff a full sync).
const useRunInitialBillSync = (billId: string) => {
  const isEnabled = useBillSyncViewIsEnabled();

  const bill = useBill(billId, { required: true });
  const shouldDryRun = shouldBillSyncBeDryRun(bill);
  const needsRealSync = !shouldDryRun;
  const { data: billSync, isLoading: isBillSyncLoading } = useBillSyncQuery(billId);

  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  // A safety catch to protect against multiple calls
  // to dispatchBillSync(). We only want to run the initial bill sync
  // at most once.
  const [hasDispatched, setHasDispatched] = useState(false);

  useEffect(() => {
    if (hasDispatched || !isEnabled || isBillSyncLoading) return;

    if (billSync) {
      if (needsRealSync && wasBillSyncDryRun(billSync)) {
        dispatchBillSync();
        setHasDispatched(true);
      }
    } else {
      dispatchBillSync();
      setHasDispatched(true);
    }
  }, [isEnabled, isBillSyncLoading, billSync, needsRealSync, dispatchBillSync, hasDispatched]);
};

export default useRunInitialBillSync;
