import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { PAYEES_QUERY_KEY } from "./usePayees";

const usePayeeQueryOptions = (payeeGuid?: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [PAYEES_QUERY_KEY, { payeeGuid }],
    queryFn: () => {
      if (!payeeGuid) {
        return null;
      }
      return highbeamApi.payee.get(businessGuid, payeeGuid);
    },
  });
};

export const usePayeeQuery = (payeeGuid?: string) => {
  return useQuery(usePayeeQueryOptions(payeeGuid));
};

const usePayee = <TRequired extends boolean>(
  payeeGuid: TRequired extends true ? string : string | undefined,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(usePayeeQueryOptions(payeeGuid));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default usePayee;
