import { ArrowsClockwise, CalendarBlank, CheckCircle } from "@phosphor-icons/react";
import { FC } from "react";
import HighlightItem from "ui/data-display/HighlightItem";
import Pill, { PillColor } from "ui/data-display/Pill";
import { Span } from "ui/typography";
import { formatDate } from "utils/date";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import { NextCapitalAutoPayAmount } from "../queries/capitalRepaymentAmountsQueryHooks";

type AutoPayStatusTextProps = {
  className?: string;
  autoPayStatus: "on" | "off";
};

const AutoPayStatusText: FC<AutoPayStatusTextProps> = ({ className, autoPayStatus }) => {
  return (
    <div className={cn("flex h-6 items-center gap-x-1 text-xs", className)}>
      <ArrowsClockwise size={16} className="shrink-0 text-grey-600" />
      <Span className="mb-[-1px] text-grey-600">Auto-pay</Span>
      <Pill
        color={variants(autoPayStatus, {
          on: "green-100" as const,
          off: "grey-100" as const,
        } satisfies Record<string, PillColor>)}
        className="px-1 capitalize"
      >
        {autoPayStatus}
      </Pill>
    </div>
  );
};

type Props = {
  nextAutoPayAmount: NextCapitalAutoPayAmount | null;
  className?: string;
};

const NextAutoPaymentHighlightItem: FC<Props> = ({ nextAutoPayAmount, className }) => {
  return (
    <HighlightItem className={className}>
      {nextAutoPayAmount && nextAutoPayAmount.amount > 0 ? (
        <>
          <HighlightItem.Heading>
            <AutoPayStatusText autoPayStatus="on" className="text-sm" />
          </HighlightItem.Heading>
          <HighlightItem.Body>
            <HighlightItem.MoneyAmount cents={nextAutoPayAmount.amount} />
          </HighlightItem.Body>
          <HighlightItem.Footer className="flex items-center gap-x-1">
            <CalendarBlank size={16} className="shrink-0 text-grey-600" weight="light" />
            <Span className="flex items-center gap-x-1 text-xs text-grey-600">
              {formatDate(nextAutoPayAmount.date, "MMM D")}
            </Span>
          </HighlightItem.Footer>
        </>
      ) : (
        <>
          <HighlightItem.Heading className="text-green-600">
            Nothing due
            <CheckCircle size={16} />
          </HighlightItem.Heading>
          <HighlightItem.Body>
            <HighlightItem.MoneyAmount cents={0} className="text-green-600" />
          </HighlightItem.Body>
          <HighlightItem.Footer>
            <AutoPayStatusText autoPayStatus="on" />
          </HighlightItem.Footer>
        </>
      )}
    </HighlightItem>
  );
};

export default NextAutoPaymentHighlightItem;
