// NB(lev): This modal is displayed when a user toggles the accounting sync setting on,
// and essentially is an onboarding step which prompts the user to configure relevant
// accounting sync settings before actually enabling accounting sync.
// At this time, the only setting we prompt for is the books closed date, so this modal
// is currently similar to the UpdateBooksClosedDateModal. However, we intentionally
// implement this as a separate component, so that we may potentially evolve accounting
// sync onboarding/configuration in the future.

import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import useRequiredActiveAccountingPlatformConnection from "modules/accounting-platforms/queries/useRequiredActiveAccountingPlatformConnection";
import { FC, useCallback, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { notify } from "ui/feedback/Toast";
import DatePicker from "ui/inputs/DatePicker";
import Helper from "ui/inputs/Helper";
import ModalV4 from "ui/overlay/ModalV4";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import { dateOnly } from "utils/date";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";
import { z } from "zod";

import useUpdateApSettingsMutation from "../../mutations/useUpdateApSettingsMutation";
import { useApSettings } from "../../queries/apSettingsQueryHooks";
import UpdateBooksClosedDateModal, {
  UpdateBooksClosedDateSchemaParams,
  makeUpdateBooksClosedDateFormSchema,
} from "../UpdateBooksClosedDateModal";

export const makeDefaultCloseBooksDate = () =>
  // Last day of the previous month.
  dayjs().subtract(1, "month").endOf("month");

// For now, we essentially reuse the same schema as the UpdateBooksClosedDateModal.
// In the future, we may evolve this schema independently, and simply merge in the
// schema generated by makeUpdateBooksClosedDateFormSchema() (e.g. via Schema.merge()).
type ConfigureAccountingSyncSchemaParams = UpdateBooksClosedDateSchemaParams;

const makeConfigureAccountingSyncFormSchema = (params: ConfigureAccountingSyncSchemaParams) =>
  makeUpdateBooksClosedDateFormSchema(params);

type ConfigureAccountingSyncFormInputs = z.input<
  ReturnType<typeof makeConfigureAccountingSyncFormSchema>
>;

type ConfirmEnableAccountingSyncModalContentProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const ConfirmEnableAccountingSyncModalContent: FC<ConfirmEnableAccountingSyncModalContentProps> = ({
  onSuccess,
}) => {
  const { segmentTrack } = useSegment();

  const apSettings = useApSettings();
  const { id: apSettingsId, accountingPlatformCloseBooksDate } = apSettings;
  const accountingPlatformConnection = useRequiredActiveAccountingPlatformConnection();

  const accountingPlatformName = accountingPlatformConnection.platformName;
  const accountingSoftwareName = getAccountingSoftwareName(accountingPlatformName);

  const formSchema = useMemo(
    () =>
      makeConfigureAccountingSyncFormSchema({
        apSettings,
        accountingPlatformConnection: accountingPlatformConnection,
      }),
    [apSettings, accountingPlatformConnection]
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ConfigureAccountingSyncFormInputs>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      closeBooksDate: accountingPlatformCloseBooksDate
        ? dayjs(accountingPlatformCloseBooksDate)
        : makeDefaultCloseBooksDate(),
    },
  });

  const { mutate: updateApSettings, isPending: isUpdatingApSettings } = useUpdateApSettingsMutation(
    apSettingsId,
    {
      onSuccess: () => {
        notify("success", "Accounting sync turned on");
        segmentTrack(SEGMENT_EVENTS.BILL_PAY_ACCOUNTING_SYNC_ENABLED);
        onSuccess();
      },
      onError: (error) => {
        notify("error", error.message ?? "Failed to turn on accounting sync");
      },
    }
  );

  const onSubmit = useCallback(
    (data: ConfigureAccountingSyncFormInputs) => {
      updateApSettings({
        accountingSyncEnabled: true,
        closeBooksDate: data.closeBooksDate?.format("YYYY-MM-DD") ?? null,
      });
    },
    [updateApSettings]
  );

  return (
    <ModalV4.Form onSubmit={handleSubmit(onSubmit)}>
      <ModalV4.Header>Review books closed date</ModalV4.Header>
      <ModalV4.Body>
        <ModalV4.Paragraph className="text-sm">
          Any Highbeam bills with invoice dates before the books closed date will not be synced to{" "}
          {accountingSoftwareName}, and editing for the bills will be locked.
        </ModalV4.Paragraph>
        <div className="mt-4 flex flex-col gap-4 border-t border-grey-100">
          {accountingPlatformCloseBooksDate && (
            <UpdateBooksClosedDateModal.BooksClosedDateSyncedInfo
              apSettings={apSettings}
              accountingPlatformConnection={accountingPlatformConnection}
              className="py-5"
            />
          )}
          <div>
            <Controller
              name="closeBooksDate"
              control={control}
              render={({ field, fieldState }) => {
                const { value } = field;

                return (
                  <>
                    <DatePicker
                      label="Books closed date"
                      isClearable={!accountingPlatformCloseBooksDate}
                      disabled={field.disabled}
                      maxDate={dateOnly().toDate()}
                      value={value ? value.toDate() : null}
                      onChange={(selected) => {
                        field.onChange(selected ? dayjs(selected) : selected);
                      }}
                      className="leading-4" // NB(lev): DatePicker label/input is optically misaligned without this leading set.
                      variant="no-date"
                      onCalendarClose={() => {
                        field.onBlur();
                      }}
                    />
                    {fieldState.error && (
                      <Helper iconVariant="error">{fieldState.error.message}</Helper>
                    )}

                    {isValid && accountingPlatformCloseBooksDate && (
                      <Helper iconVariant="info">
                        You can set a custom books closed date for any date on or after the books
                        closed date synced from {accountingSoftwareName}.
                      </Helper>
                    )}
                  </>
                );
              }}
            />
          </div>
        </div>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton disabled={!isValid} isLoading={isUpdatingApSettings}>
          Sync bills to accounting
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton>Cancel</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = ConfirmEnableAccountingSyncModalContentProps;

const ConfirmEnableAccountingSyncModal: FC<Props> = (props) => (
  <ModalV4 onClose={props.onClose}>
    <ConfirmEnableAccountingSyncModalContent {...props} />
  </ModalV4>
);

export default ConfirmEnableAccountingSyncModal;
