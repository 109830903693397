import { ArrowRight, LockSimple } from "@phosphor-icons/react";
import useSendChatMessage from "modules/chat/hook/useSendChatMessage";
import React, { useCallback, useRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import TextArea from "ui/inputs/TextArea";
import { Paragraph } from "ui/typography";
import useMountEffect from "utils/customHooks/useMountEffect";
import { useResizeObserver } from "utils/customHooks/useResizeObserver";
import cn from "utils/tailwind/cn";

import styles from "./ChatInputSection.module.scss";
import useCreateChatMessageForm, { CreateChatMessageFormInputs } from "./useCreateChatMessageForm";

type Props = {
  className?: string;
  conversationId: string | undefined;
};

const ChatInputSection: React.FC<Props> = ({ className, conversationId }) => {
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const resize = () => {
    textAreaRef.current!.style.height = "auto";
    textAreaRef.current!.style.height = `${textAreaRef.current!.scrollHeight}px`;
  };

  useMountEffect(resize);
  useResizeObserver(textAreaRef, resize);

  useEffect(() => {
    /**
     * A hack (?) to make autofocus work when switching conversations.
     */
    textAreaRef.current!.focus();
  }, [conversationId]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useCreateChatMessageForm();

  const { sendChatMessage, isPending } = useSendChatMessage({
    onSuccess: (conversationId) => {
      reset();
      navigate(`/chat/${conversationId}`);
    },
    onError: () => {
      notify("error", "Something went wrong, please try again!");
    },
  });

  const onSubmit = useCallback(
    (data: CreateChatMessageFormInputs) => {
      if (isPending) return;
      sendChatMessage({ conversationId, content: data.content });
    },
    [conversationId, isPending, sendChatMessage]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const initialPrompt = searchParams.get("initialPrompt");
    if (initialPrompt) {
      sendChatMessage({ conversationId, content: initialPrompt });
      searchParams.delete("initialPrompt");
      setSearchParams(searchParams, { replace: true });
    }
  }, [conversationId, sendChatMessage, searchParams, setSearchParams]);

  return (
    <div className={cn("flex flex-col items-center", className)}>
      <div className="flex w-full max-w-3xl flex-col items-center gap-4 px-8 pb-5">
        <form ref={formRef} className="relative w-full" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                ref={textAreaRef}
                className={styles.textarea}
                placeholder="Ask any question about your business’s finances."
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    formRef.current!.requestSubmit();
                  }
                }}
              />
            )}
          />
          <Button
            type="submit"
            className="absolute right-3 top-3 h-8 w-8 p-0"
            variant="primary"
            disabled={!isValid}
            isLoading={isPending}
          >
            {!isPending && <ArrowRight size={14} />}
          </Button>
        </form>
        <div className="flex flex-row items-center gap-2">
          <LockSimple className="fill-grey-600" size={16} />
          <Paragraph className="text-xs text-grey-600">
            Your data is stored in Highbeam. We never allow third parties to train models on your
            data.
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default ChatInputSection;
