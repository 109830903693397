import {
  Book as BookIcon,
  WarningCircle as WarningCircleIcon,
  Gear as GearIcon,
} from "@phosphor-icons/react";
import { useApSettings } from "modules/ap-settings/queries/apSettingsQueryHooks";
import { FC } from "react";
import BillRep from "reps/BillRep";
import Banner from "ui/data-display/Banner";
import ButtonLink from "ui/inputs/ButtonLink";
import { Strong } from "ui/typography";
import { formatDate } from "utils/date";
import useHasPermission from "utils/permissions/useHasPermission";

import useBill from "../../queries/useBill";

type Props = {
  billId: string;
  className?: string;
};

const BooksClosedBillBanner: FC<Props> = ({ billId, className }) => {
  const isAllowedToNavigateToBillPaySettings = useHasPermission("accountsPayableSettings:read");

  const bill = useBill(billId, { required: true });
  const isDraftBill = bill.state === BillRep.State.Draft;
  const { closeBooksDate } = useApSettings();

  const paragraphPart1 = (
    <>
      Your books are closed up to <Strong>{formatDate(closeBooksDate)}</Strong>.
    </>
  );

  // eslint-disable-next-line functional/no-let
  let paragraphPart2 = "";
  // Only if the bill details are otherwise editable, we want to show this extra text.
  if (bill.areBillDetailsEditable) {
    paragraphPart2 = isDraftBill
      ? " Roll forward the invoice date to a future date to save as bill."
      : " This bill has an invoice date in the closed date range, so some of the details cannot be edited.";
  }

  return (
    <Banner
      responsiveMode="container-query"
      className={className}
      title="Books closed"
      color={isDraftBill ? "orange" : "purple"}
      icon={isDraftBill ? <WarningCircleIcon /> : <BookIcon />}
      paragraph={
        <>
          {paragraphPart1}
          {paragraphPart2}
        </>
      }
      button={
        isAllowedToNavigateToBillPaySettings && (
          <ButtonLink variant="tertiary" to="/bills/settings?tab=accounting-sync">
            <GearIcon className="size-4" /> Settings
          </ButtonLink>
        )
      }
    />
  );
};

export default BooksClosedBillBanner;
