import { CaretDown, CaretUp } from "@phosphor-icons/react";
import React from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
  collapsed?: [boolean, (collapsed: boolean) => void];
  label: string;
};

const DebugMessageLabel: React.FC<Props> = ({ className, collapsed, label }) => {
  return (
    <div className="flex flex-col items-end gap-2">
      <Paragraph
        className={cn("block w-28 flex-none text-right text-xs font-bold text-inherit", className)}
      >
        {label}:
      </Paragraph>
      {collapsed && (
        <VirtualButton onClick={() => collapsed[1](!collapsed[0])}>
          {collapsed[0] ? (
            <CaretDown size={12} className="text-right" />
          ) : (
            <CaretUp size={12} className="text-right" />
          )}
        </VirtualButton>
      )}
    </div>
  );
};

export default DebugMessageLabel;
