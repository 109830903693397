import DashboardPage from "layouts/DashboardPage";
import RefreshPlaidBalancesButton from "modules/plaid/components/RefreshPlaidBalancesButton";
import plaidAccountsQueryHooks from "modules/plaid/queries/plaidAccountsQueryHooks";
import PlaidCreditCardsTable from "pages/accounts/AccountsOverviewPage/components/PlaidCreditCardsTable";
import { useEffect } from "react";
import { notify } from "ui/feedback/Toast";

const notifyPlaidCreditCardsError = () => {
  notify("error", "Something went wrong while loading your connected credit cards.");
};

const PlaidCreditCardsSection = () => {
  const { data: plaidCreditCards, error: plaidCreditCardsError } =
    plaidAccountsQueryHooks.useSuspenseQuery({
      select: (accounts) => accounts.filter((account) => account.accountType === "CREDIT"),
    });

  // Show an error toast if one of the queries fails.
  useEffect(() => {
    if (plaidCreditCardsError) {
      notifyPlaidCreditCardsError();
    }
  }, [plaidCreditCardsError]);

  if (!plaidCreditCards || plaidCreditCards.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <div className="flex w-full flex-col items-start">
          <DashboardPage.Section.HeaderHeading>Connected cards</DashboardPage.Section.HeaderHeading>
          <div className="flex w-full flex-row items-center justify-between">
            <DashboardPage.Section.HeaderSubheading>
              Balances are refreshed daily.
            </DashboardPage.Section.HeaderSubheading>
            <RefreshPlaidBalancesButton />
          </div>
        </div>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <PlaidCreditCardsTable accounts={plaidCreditCards} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default PlaidCreditCardsSection;
