import makeQueryHooks from "utils/react-query/makeQueryHooks";

import useBillSyncApi from "../api/useBillSyncApi";

type Params = { billId: string };

const billSyncQueryHooks = makeQueryHooks({
  name: "bill-sync",
  useQueryVariables(params: Params) {
    return params;
  },
  useQueryFnMaker({ billId }) {
    const billSyncApi = useBillSyncApi();

    return () => billSyncApi.getByHighbeamBillId(billId);
  },
});

export default billSyncQueryHooks;
