import { useOpenBankAccountRequired } from "modules/bank-accounts/queries/bankAccountQueryHooks";
import usePrimaryBankAccount from "modules/bank-accounts/queries/usePrimaryBankAccount";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

type Params = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const useCapitalAccountRepaymentBankAccount = ({ capitalAccountSummary }: Params) => {
  const primaryBankAccount = usePrimaryBankAccount({ required: true });

  const repaymentBankAccountGuid =
    capitalAccountSummary.details.repayment.bankAccountGuid ?? primaryBankAccount.guid;

  return useOpenBankAccountRequired(repaymentBankAccountGuid);
};

export default useCapitalAccountRepaymentBankAccount;
