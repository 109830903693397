import dayjs from "dayjs";
import React from "react";
import { TimestampChatElement } from "reps/chat/ChatElement";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { DATE_TIME_CELL_DATE_FORMAT } from "ui/table/cells/DateTimeCell";
import { getUsersLocalTimezoneAbbreviation } from "utils/date";

type Props = {
  element: TimestampChatElement;
};

const ResponseMessageTimestamp: React.FC<Props> = ({ element }) => {
  return (
    <ItemWithTooltip
      tooltip={[
        dayjs(element.value).format(DATE_TIME_CELL_DATE_FORMAT),
        getUsersLocalTimezoneAbbreviation(new Date(element.value)),
      ].join(" ")}
    >
      <span>{dayjs(element.value).tz("America/New_York").format("MMM D, YYYY")}</span>
    </ItemWithTooltip>
  );
};

export default ResponseMessageTimestamp;
