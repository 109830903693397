import useChatConfig from "modules/chat/queries/useChatConfig";

const useChatIsSuperuserOnly = (): boolean => {
  const chatConfig = useChatConfig();

  if (!chatConfig) return true;
  return !chatConfig.isEnabled;
};

export default useChatIsSuperuserOnly;
