import { useRefreshBillApprovalsQuery } from "modules/bill-approvals/queries/useBillApprovals";
import useRefreshBillsQueries from "modules/bills/queries/useRefreshBillsQueries";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = {
  billApprovalId: string;
};

const useGrantBillApprovalMutation = (
  billId: string,
  additionalOptions?: MutationAdditionalOptions<void, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillApprovalsQuery = useRefreshBillApprovalsQuery(billId);
  const refreshBillsQueries = useRefreshBillsQueries();
  return useMutationWithDefaults(
    {
      mutationFn: async ({ billApprovalId }) => {
        await highbeamApi.billApprovalAction.grant(billApprovalId);
      },
      onSuccess: async () => {
        await Promise.all([refreshBillsQueries(), refreshBillApprovalsQuery()]);
      },
      onError: (error) => {
        notify("error", error.message ?? "Something went wrong! Please try again.");
      },
    },
    additionalOptions || {}
  );
};

export default useGrantBillApprovalMutation;
