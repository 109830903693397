import { useOpenBankAccountRequired } from "modules/bank-accounts/queries/bankAccountQueryHooks";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

const useCapitalAccountAutoPayBankAccount = (
  capitalAccountSummary: CapitalAccountSummaryRep.Complete
) => {
  const capitalAccountAutoPayBankAccountGuid =
    capitalAccountSummary.details.repayment.bankAccountGuid;

  if (!capitalAccountAutoPayBankAccountGuid) {
    throw new RequiredButNotFoundError();
  }

  const capitalAccountAutoPayBankAccount = useOpenBankAccountRequired(
    capitalAccountAutoPayBankAccountGuid
  );

  return capitalAccountAutoPayBankAccount;
};

export default useCapitalAccountAutoPayBankAccount;
