import PaymentSummary from "components/PaymentSummary";
import MultiStep from "layouts/MultiStep";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import useCreateLineOfCreditRepaymentMutation from "modules/line-of-credit/mutations/useCreateLineOfCreditRepaymentMutation";
import { FC, FormEvent } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

import {
  RepayLineOfCreditFormOutputs,
  repayLineOfCreditFormSchema,
} from "./useRepayLineOfCreditForm";

const REPAY_LINE_OF_CREDIT_CONFIRMATION_FORM_ID = "repay-line-of-credit-confirmation-form";

type RepayLineOfCreditConfirmationFormProps = {
  repayLineOfCreditAmountFormData: RepayLineOfCreditFormOutputs;
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const RepayLineOfCreditConfirmationForm: FC<RepayLineOfCreditConfirmationFormProps> = ({
  repayLineOfCreditAmountFormData,
  capitalAccountSummary,
}) => {
  const { state } = useLocation();
  const { amount, repaymentBankAccount, currentAmountAvailable } = repayLineOfCreditAmountFormData;
  const navigate = useNavigate();
  const availableAfterRepayment = currentAmountAvailable + amount;
  const currentAmountOwed = 0 - capitalAccountSummary.runningBalance;
  const amountOwedAfterRepayment = currentAmountOwed - amount;

  const { mutateAsync: repayLineOfCredit, isPending } = useCreateLineOfCreditRepaymentMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await repayLineOfCredit({
      lineOfCreditGuid: capitalAccountSummary.guid,
      amountInCents: amount,
      transferFromBankAccountGuid: repaymentBankAccount.guid,
    });

    navigate(`/capital/${capitalAccountSummary.guid}`);
  };

  return (
    <MultiStep.Form id={REPAY_LINE_OF_CREDIT_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Repayment amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Pay from"
              value={<BankAccountBar bankAccount={repaymentBankAccount} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available (current)"
              value={<MoneyAmount size={14} cents={currentAmountAvailable} weight="medium" />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available after repaying"
              value={<MoneyAmount size={14} cents={availableAfterRepayment} weight="medium" />}
            />

            <PaymentSummary.SectionDivider />

            <PaymentSummary.Section.SummaryItem
              label="Line used (current)"
              value={<MoneyAmount size={14} cents={currentAmountOwed} weight="medium" />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Line used after repaying"
              value={<MoneyAmount size={14} cents={amountOwedAfterRepayment} weight="medium" />}
            />
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.BackButtonLink
          to={`/capital/${capitalAccountSummary.guid}/repay`}
          disabled={isPending}
          state={state}
        />

        <MultiStep.Controls.NextButton
          form={REPAY_LINE_OF_CREDIT_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isPending}
        >
          Complete repayment
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const RepayLineOfCreditConfirmationView: FC<Props> = ({ capitalAccountSummary }) => {
  const { state } = useLocation();

  const repayLineOfCreditAmountFormData = repayLineOfCreditFormSchema.safeParse(
    state?.repayLineOfCreditAmountFormInputs
  );

  if (!repayLineOfCreditAmountFormData.success) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}/repay`} />;
  }

  return (
    <RepayLineOfCreditConfirmationForm
      capitalAccountSummary={capitalAccountSummary}
      repayLineOfCreditAmountFormData={repayLineOfCreditAmountFormData.data}
    />
  );
};

export default RepayLineOfCreditConfirmationView;
