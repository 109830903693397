import { useQueryClient } from "@tanstack/react-query";
import { EXCHANGES_QUERY_KEY } from "modules/chat/queries/useExchanges";
import chatDebugModeState from "modules/chat/state/chatDebugMode";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { useRecoilValue } from "recoil";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useCreateExchangeMutation = (
  additionalOptions: MutationAdditionalOptions<void, { conversationId: string; content: string }>
) => {
  const queryClient = useQueryClient();
  const highbeamApi = useHighbeamApi();
  const userGuid = useUserGuid();
  const chatDebugMode = useRecoilValue(chatDebugModeState);

  return useMutationWithDefaults(
    {
      mutationFn: async ({ conversationId, content }) => {
        await highbeamApi.exchange.create({
          conversationId,
          userGuid,
          content,
        });
      },
      onSuccess: async (exchange, { conversationId }) => {
        await queryClient.invalidateQueries({
          queryKey: [EXCHANGES_QUERY_KEY, { conversationId, debugMode: chatDebugMode }],
        });
      },
    },
    additionalOptions
  );
};

export default useCreateExchangeMutation;
