import React, { useMemo } from "react";
import { ParagraphChatElement, TableChatElement } from "reps/chat/ChatElement";
import { Column } from "ui/table/Table";

import AiChatTable from "./AiChatTable";
import ResponseElement from "./ResponseElement";
import ResponseMessageParagraph from "./ResponseMessageParagraph";

type Props = {
  element: TableChatElement;
};

const ResponseMessageTable: React.FC<Props> = ({ element }) => {
  const columns = useMemo(() => {
    return element.columns.map((column, i) => {
      return {
        title: column.header,
        cellRender: (row) => {
          const value = row[i];
          return <ResponseMessageParagraph element={value} />;
        },
      } satisfies Column<ParagraphChatElement[]>;
    });
  }, [element.columns]);

  const data = element.values;

  return (
    <AiChatTable>
      <AiChatTable.Head>
        <AiChatTable.HeaderRow>
          {columns.map((column, i) => (
            <AiChatTable.HeaderCell key={i}>{column.title}</AiChatTable.HeaderCell>
          ))}
        </AiChatTable.HeaderRow>
      </AiChatTable.Head>
      <AiChatTable.Body>
        {data.map((row, i) => (
          <AiChatTable.DataRow key={i}>
            {row.map((cell, j) => (
              <AiChatTable.DataCell key={j}>
                {<ResponseElement element={cell} />}
              </AiChatTable.DataCell>
            ))}
          </AiChatTable.DataRow>
        ))}
      </AiChatTable.Body>
    </AiChatTable>
  );
};

export default ResponseMessageTable;
