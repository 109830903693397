import env from "env";

import OnboardingCard from "./OnboardingCard";

const ArchivedCustomerCard = () => {
  return (
    <OnboardingCard>
      <OnboardingCard.Header className="pb-8">
        <OnboardingCard.Heading1>Account archived</OnboardingCard.Heading1>
        <OnboardingCard.Subheading>
          This account has been archived and cannot be accessed. If you think this was a mistake,
          reach out to{" "}
          <a
            href={`mailto:${env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}?subject=Archived+account`}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-purple-500"
          >
            {env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}
          </a>
          .
        </OnboardingCard.Subheading>
      </OnboardingCard.Header>
    </OnboardingCard>
  );
};

export default ArchivedCustomerCard;
