import { Check, Info } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { useChatWidget } from "components/ChatWidget";
import useConnectedStoresQueryOptions from "modules/connected-stores/queries/useConnectedStoresQueryOptions";
import { ConnectedStoreType } from "modules/connected-stores/types";
import isCapitalAccountWithChargeCard from "modules/line-of-credit/utils/isCapitalAccountWithChargeCard";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Banner from "ui/data-display/Banner";
import Button from "ui/inputs/Button";

import ActivationPendingBannerItem from "./ActivationPendingBannerItem";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  className?: string;
};

const ActivationPendingBanner: FC<Props> = ({ capitalAccountSummary, className }) => {
  const chat = useChatWidget();

  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());

  const connectedStoresByType = connectedStores.reduce<ConnectedStoreType[]>(
    (acc, connectedStore) => {
      if (!acc.includes(connectedStore.type)) {
        acc.push(connectedStore.type);
      }
      return acc;
    },
    []
  );

  const onClickHavingTrouble = () => {
    chat.message("I've already rerouted my payouts to Highbeam.");
  };

  return (
    <Banner
      color="grey"
      padding="lg"
      className={className}
      icon={<Info className="size-6 text-grey-800" />}
      title="Rerouting payouts"
      paragraph={
        <>
          {isCapitalAccountWithChargeCard(capitalAccountSummary)
            ? "In order to create your first Highbeam Card, you will need to reroute your payouts."
            : "In order to draw down from Highbeam, you will need to reroute your payouts."}
          {connectedStores.length ? ' Click "Reroute payouts" to learn how.' : ""}
        </>
      }
      button={
        <Button variant="tertiary" onClick={onClickHavingTrouble} className="text-nowrap">
          <Check size={24} weight="light" /> I already did this
        </Button>
      }
      footer={connectedStoresByType.map((connectedStoreType, index) => (
        <ActivationPendingBannerItem
          key={`${connectedStoreType}-${index}`}
          connectedStoreType={connectedStoreType}
          className="border-t border-t-grey-200 bg-white"
        />
      ))}
    />
  );
};

export default ActivationPendingBanner;
