import { zodResolver } from "@hookform/resolvers/zod";
import { Dayjs } from "dayjs";
import { spendLimitSchema } from "modules/card-spend-limit/components/SpendLimitFieldset";
import {
  BusinessMemberUsersTableDatum,
  UsersTableDatum,
} from "modules/users-table-data/queries/useUsersTableData";
import { useForm } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import { z } from "zod";

const schema = z
  .object({
    cardholder: z
      .custom<UsersTableDatum | null>()
      .refine((data) => data !== null, {
        message: "Cardholder is required",
      })
      .refine((data): data is BusinessMemberUsersTableDatum => !data.isInvited),

    cardholderDateOfBirth: z.custom<Dayjs | null>(),

    cardName: z.string().min(1, "Please enter a card name."),

    // NB(alex): Technically we should check `creditOrDebit` to see if we want to validate this field, but we can just make it non-nullable and ignore the field if `credit`, so this is fine.
    associatedBankAccount: z.custom<BankAccountRep.Complete>(),

    spendLimit: spendLimitSchema,
  })
  .superRefine((values, ctx) => {
    if (!values.cardholder.dateOfBirth && !values.cardholderDateOfBirth) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Date of birth is required.",
        path: ["cardholderDateOfBirth"],
      });
      return z.NEVER;
    }
  });

export type CreateCardModalCardDetailsFormInputs = z.input<typeof schema>;
export type CreateCardModalCardDetailsFormOutputs = z.output<typeof schema>;

type Params = {
  defaultValues: CreateCardModalCardDetailsFormInputs;
};

const useCreateCardModalCardDetailsForm = ({ defaultValues }: Params) => {
  return useForm<
    CreateCardModalCardDetailsFormInputs,
    object,
    CreateCardModalCardDetailsFormOutputs
  >({
    resolver: zodResolver(schema),
    defaultValues,
  });
};

export default useCreateCardModalCardDetailsForm;
