import { Card } from "@highbeam/unit-node-sdk";
import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";

import useHasPermission from "./useHasPermission";

const useIsAllowedToReadCardNumber = (card: Card) => {
  const businessMember = useCurrentBusinessMember();
  const isAllowedToReadOwnCardNumber = useHasPermission("card:readNumberOwn");
  const isAllowedToReadAnyCardNumber = useHasPermission(["card:readNumberAny", "card:readNumber"]);

  const cardBelongsToAuthenticatedBusinessMember =
    businessMember && card.attributes.tags.businessMemberGuid === businessMember.guid;

  return Boolean(
    isAllowedToReadAnyCardNumber ||
      (isAllowedToReadOwnCardNumber && cardBelongsToAuthenticatedBusinessMember)
  );
};

export default useIsAllowedToReadCardNumber;
