import DashboardPage from "layouts/DashboardPage";
import ActivationPendingBanner from "modules/capital-accounts/components/ActivationPendingBanner";
import InactiveConnectionWarningBanner from "modules/capital-accounts/components/InactiveConnectionWarningBanner";
import UpdatedLineOfCreditAgreementBanner from "modules/capital-accounts/components/UpdatedLineOfCreditAgreementBanner";
import useLineOfCreditAgreement from "modules/line-of-credit-agreement/queries/useLineOfCreditAgreement";
import { usePlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountBanners: FC<Props> = ({ capitalAccountSummary }) => {
  const isUnacceptedAgreementFlagEnabled = useFeatureFlag("CAPITAL_UNACCEPTED_AGREEMENT_FLOW");
  const isCapitalAccountActive = capitalAccountSummary.state === CapitalAccountRep.State.Active;
  const lineOfCreditAgreement = useLineOfCreditAgreement(capitalAccountSummary.guid);
  const isSignedLineAgreement = Boolean(lineOfCreditAgreement?.agreementSignedAt);

  const plaidConnections = usePlaidConnections();
  const hasInactivePlaidConnections = plaidConnections.some((connection) => !connection.isActive);

  return (
    <>
      {isUnacceptedAgreementFlagEnabled && !isSignedLineAgreement && (
        <DashboardPage.Section>
          <UpdatedLineOfCreditAgreementBanner capitalAccountSummary={capitalAccountSummary} />
        </DashboardPage.Section>
      )}

      {!isCapitalAccountActive && (
        <DashboardPage.Section>
          <ActivationPendingBanner capitalAccountSummary={capitalAccountSummary} />
        </DashboardPage.Section>
      )}

      {hasInactivePlaidConnections && (
        <DashboardPage.Section>
          <InactiveConnectionWarningBanner />
        </DashboardPage.Section>
      )}
    </>
  );
};

export default CapitalAccountBanners;
