import { AccountLimits, Card } from "@highbeam/unit-node-sdk";
import { useQuery } from "@tanstack/react-query";
import useUnitCoAccountLimitsQueryOptions from "modules/unit-co-account-limits/queries/useUnitCoAccountLimitsQueryOptions";
import { FC } from "react";

import DepositAccountCardLimit from "./DepositAccountCardLimit";

type Props = {
  cards: Card[];
};

type CardsByAccount = {
  [accountId: string]: Card[];
};

const getDepositAccountsApproachingCardPurchaseLimits = (accountLimits: AccountLimits[]) => {
  return accountLimits.filter(
    (accountLimit) =>
      accountLimit.type === "limits" &&
      accountLimit.attributes.card.totalsDaily.purchases /
        accountLimit.attributes.card.limits.dailyPurchase >
        0.8
  );
};

const CardApproachingLimitBanners: FC<Props> = ({ cards }) => {
  const cardsByAccount = cards.reduce((acc: CardsByAccount, card) => {
    const accountId = card.relationships.account.data.id;
    acc[accountId] = acc[accountId] || [];
    acc[accountId].push(card);
    return acc;
  }, {});

  const { data: depositAccountApproachingLimits } = useQuery({
    ...useUnitCoAccountLimitsQueryOptions({
      unitCoBankAccountIds: Object.keys(cardsByAccount),
    }),
    select: getDepositAccountsApproachingCardPurchaseLimits,
  });

  if (!depositAccountApproachingLimits || depositAccountApproachingLimits.length === 0) {
    return null;
  }

  return (
    <div className="mb-6 flex flex-col gap-y-6">
      {depositAccountApproachingLimits.map((accountLimit) => {
        // TODO update unit-node-sdk to have a type for this
        const accountId = (accountLimit as any).id as string;
        const affectedCards = cardsByAccount[accountId];
        return (
          <DepositAccountCardLimit
            key={accountId}
            accountId={accountId}
            currentPurchase={accountLimit.attributes.card.totalsDaily.purchases}
            totalLimit={accountLimit.attributes.card.limits.dailyPurchase}
            cards={affectedCards}
          />
        );
      })}
    </div>
  );
};

export default CardApproachingLimitBanners;
