import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import { z } from "zod";

export const repaymentOptions = ["full", "half", "quarter", "custom"] as const;

export type RepaymentOption = (typeof repaymentOptions)[number];

export const repayLineOfCreditFormSchema = z.object({
  amount: z.number().positive(),
  repaymentOption: z
    .enum(repaymentOptions)
    .nullable()
    .refine((value) => value !== null, {
      message: "Please select a repayment option.",
    }),
  repaymentBankAccount: z.custom<BankAccountRep.Complete>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
  // NB(alex): We store this value to prevent the flicker of an incorrect value caused by the query getting refreshed upon successful payment. https://linear.app/highbeam/issue/HB-6453/fix-flickering-incorrect-math-after-capital-account-drawdown
  currentAmountAvailable: z.number().positive(),
});

export type RepayLineOfCreditFormInputs = z.input<typeof repayLineOfCreditFormSchema>;
export type RepayLineOfCreditFormOutputs = z.output<typeof repayLineOfCreditFormSchema>;

type Params = {
  defaultValues: RepayLineOfCreditFormInputs;
};

const useRepayLineOfCreditForm = ({ defaultValues }: Params) => {
  return useForm({
    resolver: zodResolver(repayLineOfCreditFormSchema),
    defaultValues,
  });
};

export default useRepayLineOfCreditForm;

// Utils

export const getRepaymentOptionMultiplier = (
  repaymentOption: Exclude<RepaymentOption, "custom">
) => {
  switch (repaymentOption) {
    case "full":
      return 1;
    case "half":
      return 0.5;
    case "quarter":
      return 0.25;
  }
};
