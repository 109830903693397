import { TransactionListParams } from "@highbeam/unit-node-sdk";
import useBusinessUnitCoCustomerId from "modules/business/queries/useBusinessUnitCoCustomerId";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { Simplify } from "type-fest";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const UNIT_CO_TRANSACTIONS_QUERY_KEY = "unit-co-transactions";

type Params = Simplify<
  TransactionListParams & {
    customerId?: never; // Always use the customerId from the business state
  }
>;

const useUnitCoTransactionsQueryOptions = (params: Params) => {
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId({ required: true });

  return useQueryOptions({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, customerId, params],
    queryFn: async () => {
      const response = await unitApi.transactions.list({
        sort: "-createdAt",
        ...params,
        customerId: customerId,
      });

      return {
        transactions: response.data,
        pagination: response.meta.pagination,
      };
    },
  });
};

export default useUnitCoTransactionsQueryOptions;
