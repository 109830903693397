import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import useUnitCoAccountLimitQueryOptions from "modules/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import {
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { BankingInfo, PaymentMethod, PaymentMethodOption } from "pages/SendMoneyPage/utils";
import { Suspense, useEffect, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { internationalAddressState } from "state/payments/international/address";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { internationalEntityState } from "state/payments/international/entity";
import { invoiceDateState } from "state/payments/international/invoiceDate";
import { invoiceNumberState } from "state/payments/international/invoiceNumber";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { isMoreThanAllowedLocalPaymentAmountState } from "state/payments/international/isMoreThanAllowedLocalPaymentAmount";
import { purposeCodeState } from "state/payments/international/purposeCode";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { quoteUsdAmountInCentsState } from "state/payments/international/quoteUsdAmountInCents";
import requiredFieldsState from "state/payments/international/requiredFields";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";
import Button from "ui/inputs/Button";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";
import StepHeader from "ui/navigation/Steps/StepHeader";
import { Address } from "utils/address";
import { EntityIndividual } from "utils/entity";
import { getCentsFromDollars, getDollarsFromCents } from "utils/money";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import { formatAmount } from "utils/string";
import { TransferOption } from "utils/transfers";

import InternationalPayeeBankingInfo from "./InternationalPayeeBankingInfo";
import InternationalPayeeEntity from "./InternationalPayeeEntity";
import InternationalPayeeLegalAddress from "./InternationalPayeeLegalAddress";
import PayeeBankingInfo from "./PayeeBankingInfo";
import PayeeLegalAddress from "./PayeeLegalAddress";
import PayeeNotification from "./PayeeNotification";
import PaymentAmount, { PaymentAmountError, PaymentAmountFallback } from "./PaymentAmount";
import PaymentAndDescription from "./PaymentAndDescription";
import styles from "./PaymentInfo.module.scss";
import PaymentInvoice from "./PaymentInvoice";
import PaymentLimitWarningBar from "./PaymentLimitWarningBar";
import PaymentQuote from "./PaymentQuote";

type Props = {
  onPrevPress: () => void;
  onNextPress: () => void;
  setPaymentMethod: (paymentMethod: PaymentMethodOption) => void;
  paymentMethodOption: PaymentMethodOption | null;
  paymentMethodOptions: PaymentMethodOption[];
  setTransferFrom: (transferFrom: TransferOption | null) => void;
  transferFrom: TransferOption | null;
  transferFromOptions: TransferOption[];
  amountInCents: number;
  setAmountInCents: (amount: number) => void;
  bankingInfo: BankingInfo;
  setBankingInfo: (bankingInfo: BankingInfo) => void;
  addenda: string;
  setAddenda: (addenda: string) => void;
  address: Address;
  setAddress: (address: Address) => void;
  accountTypeOptions: Option[];
  isRecurringPayment: boolean;
  isUpdatingPayee: boolean;
  payeeEmail: string;
  setPayeeEmail: (email: string) => void;
  isPayeeEmailToggled: boolean;
  setIsPayeeEmailToggled: (toggled: boolean) => void;
  paymentMetadataGuid: string;
  setPaymentMetadataGuid: (paymentMetadataGuid: string) => void;
  setInvoiceName: (invoiceName: string) => void;
};

const PaymentInfo: React.FC<Props> = ({
  onPrevPress,
  onNextPress,
  paymentMethodOption,
  paymentMethodOptions,
  setPaymentMethod,
  amountInCents,
  setAmountInCents,
  transferFrom,
  transferFromOptions,
  setTransferFrom,
  bankingInfo,
  setBankingInfo,
  addenda,
  setAddenda,
  address,
  setAddress,
  accountTypeOptions,
  isRecurringPayment,
  isUpdatingPayee,
  payeeEmail,
  setPayeeEmail,
  isPayeeEmailToggled,
  setIsPayeeEmailToggled,
  paymentMetadataGuid,
  setPaymentMetadataGuid,
  setInvoiceName,
}) => {
  const [isAmountMoreThanBalanceError, setIsAmountMoreThanBalanceError] = useState(false);
  const [hasBankingInfoError, setHasBankingInfoError] = useState(false);
  const [hasLegalAddressError, setHasLegalAddressError] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [isShowingAmountMoreThanBalanceError, setIsShowingAmountMoreThanBalanceError] =
    useState(false);
  const [amountInDollars, setAmountInDollars] = useState(
    amountInCents !== 0 ? getDollarsFromCents(amountInCents).toString() : ""
  );
  const [isAmountMoreThanDailyAchLimitError, setIsAmountMoreThanDailyAchLimitError] =
    useState(false);

  const selectedBankCountry = useRecoilValue(selectedBankCountryOptionState);
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  const selectedCurrency = useRecoilValue(quoteCurrencyState);
  const fee = isLocalPayment ? 0 : (paymentMethodOption?.feeInCents ?? 0);

  const balanceAfterInitialState =
    transferFrom && !isRecurringPayment
      ? transferFrom.availableBalanceInDollars -
        getDollarsFromCents(amountInCents) -
        getDollarsFromCents(fee)
      : 0;
  const [balanceAfter, setBalanceAfter] = useState(balanceAfterInitialState);

  const isAmountLessThanMinimumAmountInitialState =
    paymentMethodOption?.minTransferAmountInCents && amountInCents
      ? paymentMethodOption?.minTransferAmountInCents > amountInCents
      : false;
  const [isAmountLessThanMinimumAmount, setIsAmountLessThanMinimumAmount] = useState(
    isAmountLessThanMinimumAmountInitialState
  );
  const [isAmountMoreThanMaxAmount, setIsAmountMoreThanMaxAmount] = useState<boolean>();

  const { data: accountLimits } = useQuery(useUnitCoAccountLimitQueryOptions(transferFrom?.value));

  // Required to maintain amounts across international wires and other payment methods
  const setUsdAmountInCents = useSetRecoilState(quoteUsdAmountInCentsState);

  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isDraftingPayment = !isAllowedToApprovePayments;

  useEffect(() => {
    if (!transferFrom) {
      setTransferFrom(transferFromOptions[0]);
    }
  }, [transferFrom, setTransferFrom, transferFromOptions]);

  useEffect(() => {
    setIsAmountMoreThanBalanceError(balanceAfter < 0);
  }, [balanceAfter, setIsAmountMoreThanBalanceError]);

  useEffect(() => {
    const getIsAmountMoreThanDailyAchLimit = (amountInCents: number) => {
      if (!accountLimits) return false;
      const dailyLimit = accountLimits.attributes.ach.limits.dailyCredit;
      return amountInCents > dailyLimit;
    };

    const getIsAmountMoreThanDailyWireLimit = (amountInCents: number) => {
      if (!accountLimits) return false;
      // TODO: remove unknown type once wire is added as type to AccountLimits in unit sdk
      const wireLimit = accountLimits.attributes.wire as unknown as any;
      const dailyLimit = wireLimit.limits.dailyCredit;
      return amountInCents > dailyLimit;
    };

    const getIsAmountMoreThanDailyOrMonthyLeftoverAchLimit = (amountInCents: number) => {
      if (!accountLimits) return false;
      const dailyLimit = accountLimits.attributes.ach.limits.dailyCredit;
      const monthlyLimit = accountLimits.attributes.ach.limits.monthlyCredit;
      const dailyLimitUsed = accountLimits.attributes.ach.totalsDaily.credits;
      const monthlyLimitUsed = accountLimits.attributes.ach.totalsMonthly.credits;
      const leftoverDailyAchLimit = dailyLimit - dailyLimitUsed;
      const leftoverMonthlyAchLimit = monthlyLimit - monthlyLimitUsed;

      // For draft payments, ensure the amountInCents is below the TOTAL daily limit
      if (isDraftingPayment) {
        return amountInCents > dailyLimit;
      }

      //If the monthly limit left is smaller than their daily limit, then use the leftoverMonthlyAchLimit, otherwise the transaction would fail in unit
      return (
        amountInCents >
        (leftoverDailyAchLimit > leftoverMonthlyAchLimit
          ? leftoverMonthlyAchLimit
          : leftoverDailyAchLimit)
      );
    };

    const getIsAmountMoreThanDailyLeftoverWireLimit = (amountInCents: number) => {
      if (!accountLimits) return false;
      // TODO: remove unknown type once wire is added as type to AccountLimits in unit sdk
      const wireLimit = accountLimits.attributes.wire as unknown as any;
      const dailyWireLimit = wireLimit.limits.dailyTransfer;
      const leftoverDailyWireLimit = dailyWireLimit - wireLimit.totalsDaily.transfers;

      return amountInCents > leftoverDailyWireLimit;
    };

    if (
      paymentMethodOption?.value === PaymentMethod.ACH ||
      paymentMethodOption?.value === PaymentMethod.SAME_DAY_ACH
    ) {
      setIsAmountMoreThanDailyAchLimitError(getIsAmountMoreThanDailyAchLimit(amountInCents));
      setIsAmountMoreThanMaxAmount(
        isRecurringPayment
          ? getIsAmountMoreThanDailyAchLimit(amountInCents)
          : getIsAmountMoreThanDailyOrMonthyLeftoverAchLimit(amountInCents)
      );
    } else if (paymentMethodOption?.value === PaymentMethod.WIRE) {
      setIsAmountMoreThanDailyAchLimitError(false);
      setIsAmountMoreThanMaxAmount(
        isRecurringPayment
          ? getIsAmountMoreThanDailyWireLimit(amountInCents)
          : getIsAmountMoreThanDailyLeftoverWireLimit(amountInCents)
      );
    } else {
      setIsAmountMoreThanDailyAchLimitError(false);
    }
  }, [accountLimits, amountInCents, paymentMethodOption, isRecurringPayment, isDraftingPayment]);

  useEffect(() => {
    setBalanceAfter(
      transferFrom && !isRecurringPayment
        ? transferFrom.availableBalanceInDollars -
            getDollarsFromCents(amountInCents) -
            getDollarsFromCents(fee)
        : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountInCents, selectedCurrency, transferFrom, isRecurringPayment]);

  useEffect(() => {
    setIsAmountLessThanMinimumAmount(
      paymentMethodOption?.minTransferAmountInCents && amountInCents
        ? paymentMethodOption?.minTransferAmountInCents > amountInCents
        : false
    );
  }, [amountInCents, selectedCurrency, paymentMethodOption]);

  useEffect(() => {
    const amountInCents = amountInDollars ? getCentsFromDollars(amountInDollars) : 0;
    setAmountInCents(amountInCents);
    setUsdAmountInCents(amountInCents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountInDollars]);

  const getAmountErrorMessage = () => {
    if (isAmountMoreThanBalanceError) {
      const fee = isLocalPayment ? 0 : (paymentMethodOption?.feeInCents ?? 0);
      const balance = formatAmount(transferFrom ? transferFrom.availableBalanceInDollars : 0);
      return paymentMethodOption && fee > 0
        ? `The amount you entered (+ $${getDollarsFromCents(
            paymentMethodOption.feeInCents
          )} fee) is greater than your account balance of ${balance}.`
        : `The amount you entered is greater than your account balance of ${balance}.`;
    } else if (isAmountMoreThanDailyAchLimitError) {
      return `The amount you entered is greater than your daily ACH limit.`;
    } else if (isAmountMoreThanMaxAmount) {
      return `${paymentMethodOption?.label} payments are limited to ${formatAmount(
        getDollarsFromCents(paymentMethodOption?.paymentLimitInCents!)
      )}.`;
    }

    return "The amount you entered in invalid.";
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextPress();
  };

  const hasAmountError = Boolean(
    isRecurringPayment
      ? isAmountMoreThanDailyAchLimitError || isAmountMoreThanMaxAmount
      : isAmountMoreThanDailyAchLimitError ||
          isAmountMoreThanMaxAmount ||
          isAmountMoreThanBalanceError
  );

  const hasPaymentError = (() => {
    if (isRecurringPayment) {
      return hasBankingInfoError || hasLegalAddressError || isAmountMoreThanDailyAchLimitError;
    }

    if (!isDraftingPayment && isAmountMoreThanBalanceError) {
      return true;
    }

    return hasBankingInfoError || hasLegalAddressError;
  })();

  const isMinAddendaLengthMet =
    !paymentMethodOption?.minAddendaLength ||
    addenda.length >= paymentMethodOption?.minAddendaLength;

  const entity = useRecoilValue(internationalEntityState);
  const isValidEntity = Boolean(
    entity.entityType === EntityIndividual
      ? entity.firstName && entity.lastName
      : entity.companyName
  );

  const internationalAddress = useRecoilValue(internationalAddressState);
  const isValidInternationalAddress = Boolean(
    internationalAddress.country && internationalAddress.addressLine1 && internationalAddress.city
  );

  const internationalBankingInfo = useRecoilValue(internationalBankingInfoState);
  const requiredFields = useRecoilValue(requiredFieldsState);
  const isValidInternationalBanking = (): boolean => {
    const applicableFields = Object.values(internationalBankingInfo).filter((field) =>
      requiredFields?.has(field.inputName)
    );
    return applicableFields.every((field) => field.isValid);
  };

  const isInternational = paymentMethodOption?.value === PaymentMethod.INTERNATIONAL;
  const isValidAch =
    (paymentMethodOption?.value === PaymentMethod.ACH ||
      paymentMethodOption?.value === PaymentMethod.SAME_DAY_ACH) &&
    bankingInfo.accountNumber &&
    bankingInfo.routingNumber &&
    bankingInfo.accountType &&
    !hasPaymentError;

  const isMoreThanAllowedLocalPaymentAmountLoadable = useRecoilValueLoadable(
    isMoreThanAllowedLocalPaymentAmountState
  );
  const isValidLocalAmount =
    !isLocalPayment ||
    (isMoreThanAllowedLocalPaymentAmountLoadable.state === "hasValue" &&
      isMoreThanAllowedLocalPaymentAmountLoadable.contents === false);

  const purposeCode = useRecoilValue(purposeCodeState);
  const invoiceNumber = useRecoilValue(invoiceNumberState);
  const invoiceDate = useRecoilValue(invoiceDateState);

  const isValidInternationalMisc =
    (!requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.PURPOSE_CODE) || purposeCode !== null) &&
    (!requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.INVOICE_NUMBER) ||
      (invoiceNumber &&
        INTERNATIONAL_WIRE_INPUT_DETAILS[
          INTERNATIONAL_WIRE_INPUTS.INVOICE_NUMBER
        ].defaultValidation.test(invoiceNumber))) &&
    (!requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.INVOICE_DATE) ||
      (invoiceDate &&
        INTERNATIONAL_WIRE_INPUT_DETAILS[
          INTERNATIONAL_WIRE_INPUTS.INVOICE_DATE
        ].defaultValidation.test(invoiceDate.format("YYYY-MM-DD"))));

  const isValidInternational =
    isInternational &&
    isValidEntity &&
    isValidInternationalAddress &&
    isValidInternationalBanking() &&
    isValidLocalAmount &&
    isValidInternationalMisc;

  const isValidWire =
    paymentMethodOption?.value === PaymentMethod.WIRE &&
    address.addressLine1 &&
    address.city &&
    address.state &&
    address.zipCode &&
    bankingInfo.accountNumber &&
    bankingInfo.routingNumber &&
    bankingInfo.accountType &&
    !hasPaymentError;
  const isBaseValid =
    amountInCents &&
    isMinAddendaLengthMet &&
    !isAmountLessThanMinimumAmount &&
    !isAmountMoreThanMaxAmount &&
    (!isAmountMoreThanBalanceError || isDraftingPayment);

  const isNextStepAllowed =
    isBaseValid &&
    (isValidAch || isValidInternational || isValidWire) &&
    (!isPayeeEmailToggled || !hasEmailError);

  return (
    <>
      <StepHeader
        disableBackButton={false}
        disableForwardButton={!isNextStepAllowed || isUpdatingPayee}
        handleBackButtonClick={onPrevPress}
        handleForwardButtonClick={onNextPress}
        stepNumber={2}
        title="Payment info"
      />
      <form id="payment-info" onSubmit={handleSubmit}>
        {isInternational ? (
          <>
            <ErrorBoundary fallback={() => <PaymentAmountError />}>
              <Suspense fallback={<PaymentAmountFallback />}>
                <PaymentAmount
                  setAmount={setAmountInDollars}
                  hasError={hasAmountError}
                  getErrorMessage={getAmountErrorMessage}
                />
              </Suspense>
            </ErrorBoundary>

            <PaymentQuote />

            {transferFromOptions.length > 0 && (
              <Dropdown
                value={transferFrom}
                onChange={(from) => setTransferFrom(from as TransferOption)}
                id="send-from"
                label="Send from"
                options={transferFromOptions}
                isSearchable={false}
                className={styles.selectDropdownContainer}
              />
            )}
          </>
        ) : (
          <div>
            <CurrencyInput
              value={amountInDollars}
              onChange={setAmountInDollars}
              onFocus={() => setIsShowingAmountMoreThanBalanceError(false)}
              onBlur={() => setIsShowingAmountMoreThanBalanceError(true)}
              label="Amount"
              prefixValue="$"
              hasError={hasAmountError}
              errorMessage={isAmountMoreThanBalanceError ? getAmountErrorMessage() : null}
              autoFocus
            />

            {!isAmountMoreThanBalanceError && (
              <PaymentLimitWarningBar
                paymentMethodOption={paymentMethodOption}
                amountInCents={amountInCents}
                transferFrom={transferFrom}
                isRecurringPayment={isRecurringPayment}
              />
            )}

            {transferFromOptions.length > 0 && (
              <Dropdown
                value={transferFrom}
                onChange={(from) => setTransferFrom(from as TransferOption)}
                id="send-from"
                label="Send from"
                options={transferFromOptions}
                isSearchable={false}
                className={styles.selectDropdownContainer}
              />
            )}

            {Boolean(amountInCents) &&
              transferFrom &&
              !isAmountMoreThanBalanceError &&
              !isRecurringPayment && (
                <Helper>Balance after transaction: {formatAmount(balanceAfter)}</Helper>
              )}
          </div>
        )}

        <PaymentAndDescription
          options={paymentMethodOptions}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethodOption}
          addenda={addenda}
          setAddenda={setAddenda}
          minDescriptionLength={paymentMethodOption!.minAddendaLength}
          maxDescriptionLength={paymentMethodOption!.maxAddendaLength}
          isAmountLessThanMinimumAmount={
            isShowingAmountMoreThanBalanceError && isAmountLessThanMinimumAmount
          }
        />

        {(paymentMethodOption?.value === PaymentMethod.ACH ||
          paymentMethodOption?.value === PaymentMethod.SAME_DAY_ACH ||
          paymentMethodOption?.value === PaymentMethod.WIRE) && (
          <PayeeBankingInfo
            bankingInfo={bankingInfo}
            setBankingInfo={setBankingInfo}
            paymentMethod={paymentMethodOption}
            accountTypeOptions={accountTypeOptions}
            setHasBankingInfoError={setHasBankingInfoError}
          />
        )}
        {paymentMethodOption?.value === PaymentMethod.WIRE && (
          <PayeeLegalAddress
            address={address}
            setAddress={setAddress}
            setHasLegalAddressError={setHasLegalAddressError}
          />
        )}
        {paymentMethodOption?.value === PaymentMethod.INTERNATIONAL && (
          <>
            <InternationalPayeeEntity />
            <InternationalPayeeBankingInfo />
            {selectedBankCountry && <InternationalPayeeLegalAddress />}
          </>
        )}
        <PayeeNotification
          payeeEmail={payeeEmail}
          setPayeeEmail={setPayeeEmail}
          isPayeeEmailToggled={isPayeeEmailToggled}
          setIsPayeeEmailToggled={setIsPayeeEmailToggled}
          setHasEmailError={setHasEmailError}
        />
        {!isRecurringPayment && (
          <PaymentInvoice
            paymentMethod={paymentMethodOption?.value}
            paymentMetadataGuid={paymentMetadataGuid}
            setPaymentMetadataGuid={setPaymentMetadataGuid}
            setInvoiceName={setInvoiceName}
          />
        )}
      </form>

      <div className={styles.buttons}>
        <Button
          type="submit"
          form="payment-info"
          variant="primary"
          isLoading={isUpdatingPayee}
          disabled={!isNextStepAllowed}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default PaymentInfo;
