import useCard from "modules/cards/queries/useCard";
import useCardLimits from "modules/cards/queries/useCardLimits";
import { PropsWithChildren, createContext, useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useParams } from "react-router-dom";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

type CardDetailsPageContextState = {
  cardId: string;
};

export const CardDetailsPageContext = createContext<CardDetailsPageContextState>(
  {} as CardDetailsPageContextState
);

const CardDetailsPageProvider = ({ children }: PropsWithChildren) => {
  const { cardId } = useParams();

  if (!cardId) {
    return <Navigate to="/cards" />;
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        if (error instanceof RequiredButNotFoundError) {
          return <Navigate to="/cards" />;
        }
      }}
    >
      <CardDetailsPageContext.Provider value={{ cardId }}>
        {children}
      </CardDetailsPageContext.Provider>
    </ErrorBoundary>
  );
};

export default CardDetailsPageProvider;

export const useCardDetailsPageContext = () => {
  const { cardId } = useContext(CardDetailsPageContext);
  const card = useCard({ cardId, required: true });
  const cardLimits = useCardLimits({ cardId });

  return {
    card,
    cardLimits,
  };
};
