import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusiness from "modules/business/queries/useBusiness";
import getBusinessSafeDisplayName from "modules/business/utils/getBusinessSafeDisplayName";
import React from "react";
import UserAvatar from "ui/data-display/UserAvatar";
import VirtualButton from "ui/inputs/VirtualButton";
import { Span } from "ui/typography";

type Props = {
  onOpenUserMenu: () => void;
};

const UserAvatarMenuButton: React.FC<Props> = ({ onOpenUserMenu: handleOpenUserMenu }) => {
  const business = useBusiness();
  const businessMember = useCurrentBusinessMember();

  const businessDisplayName = getBusinessSafeDisplayName(business);
  const businessMemberDisplayName = businessMember?.displayName;
  const businessMemberInitials = businessMember?.initials ?? "";

  return (
    <VirtualButton onClick={handleOpenUserMenu} className="flex items-center gap-x-4">
      <div className="hidden text-end tablet:block">
        {businessMemberDisplayName && (
          <Span className="block text-xs font-medium">{businessMemberDisplayName}</Span>
        )}
        <Span className="block text-xs font-regular text-grey-600">{businessDisplayName}</Span>
      </div>
      <UserAvatar color="purple-light" size={32} initials={businessMemberInitials} />
    </VirtualButton>
  );
};

export default UserAvatarMenuButton;
