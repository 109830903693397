import PlaidConnectionsList from "modules/plaid/components/PlaidConnectionsList";
import { usePlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { FC } from "react";
import ButtonLink from "ui/inputs/ButtonLink";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import { Heading4 } from "ui/typography";

import OnboardingCard from "./OnboardingCard";

type Props = {
  className?: string;
};

const OnboardingConnectPlaidCard: FC<Props> = ({ className }) => {
  const plaidConnections = usePlaidConnections();
  const hasPlaidConnections = plaidConnections.length > 0;

  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header>
        <OnboardingCard.Heading1>
          Want to see how much Highbeam can save you?
        </OnboardingCard.Heading1>

        <div className="mt-4">
          <Heading4 className="text-sm font-medium text-grey-600">
            Connect your accounts and cards to get:
          </Heading4>
          <ul className="mt-2 list-inside list-disc text-sm text-grey-600">
            <li className="ml-0.5">An estimated ($) value Highbeam can save your business</li>
            <li className="ml-0.5">
              A complimentary cash flow analysis sheet with your 13 week forecasts
            </li>
          </ul>
        </div>
      </OnboardingCard.Header>

      <OnboardingCard.Body className="pb-4">
        <PlaidConnectionsList />
      </OnboardingCard.Body>

      <OnboardingCard.Footer className="mt-8 flex justify-end gap-x-4 border-t border-t-grey-200 px-8 py-5">
        <ButtonLink variant="ghost" to="/onboarding/bank-application">
          Skip for now
        </ButtonLink>

        <ButtonLinkWithTooltip
          variant="primary"
          to="/onboarding/book-a-time"
          disabled={!hasPlaidConnections}
          tooltip={!hasPlaidConnections && "Please connect your cards and accounts to continue."}
        >
          Save and continue
        </ButtonLinkWithTooltip>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingConnectPlaidCard;
