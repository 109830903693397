import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import BillRep from "reps/BillRep";

type BillApi = {
  get: (billId: string) => Promise<BillRep.Complete | null>;
  search: (businessGuid: string) => Promise<BillRep.Complete[]>;
  create: (creator: BillRep.Creator) => Promise<BillRep.Complete>;
  updateBillInfo: (billId: string, updater: BillRep.Updater) => Promise<BillRep.Complete>;
  delete: (billId: string) => Promise<void>;
};

const builder: ApiBuilder<BillApi> = (api) => ({
  get: async (billId) => {
    const url = `/accounts-payable/bills/${billId}`;
    return await api.get<BillRep.Complete>(url);
  },

  search: async (businessGuid) => {
    const qp = new URLSearchParams({ businessGuid: businessGuid });
    const url = `/accounts-payable/bills?${qp}`;
    return (await api.get<BillRep.Complete[]>(url))!;
  },

  create: async (creator) => {
    const url = "/accounts-payable/bills";
    return (await api.post<BillRep.Complete>(url, creator))!;
  },

  updateBillInfo: async (billId, updater) => {
    const url = `/accounts-payable/bills/${billId}/info`;
    return (await api.patch<BillRep.Complete>(url, updater))!;
  },

  delete: async (billId) => {
    const url = `/accounts-payable/bills/${billId}`;
    await api.delete(url);
  },
});

const useBillApi = () => useBackendV2Api(builder);

export default useBillApi;
