import { CheckCircle } from "@phosphor-icons/react";
import capitalRepaymentAmountsQueryHooks from "modules/capital-accounts/queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Shimmer from "ui/feedback/ShimmerV2";
import { formatDate } from "utils/date";
import variants from "utils/ts/variants";

const NothingDueItem = () => {
  return (
    <MetadataList.Item>
      <MetadataList.ItemLabel className="text-green-600">Nothing due</MetadataList.ItemLabel>
      <MetadataList.ItemValue>
        <CheckCircle size={24} className="text-green-600" />
      </MetadataList.ItemValue>
    </MetadataList.Item>
  );
};

type AmountDueByCapitalAccountGuidProps = {
  capitalAccountGuid: string;
};

const AmountDueByCapitalAccountGuid: FC<AmountDueByCapitalAccountGuidProps> = ({
  capitalAccountGuid,
}) => {
  const { data, isLoading } = capitalRepaymentAmountsQueryHooks.useQuery({
    capitalAccountGuid,
    select: ({ amounts }) => {
      const overdueAmountComplete = amounts.find((amount) => amount.type === "Overdue");
      if (overdueAmountComplete) return overdueAmountComplete;

      const lastStatementAmountComplete = amounts.find((amount) => amount.type === "LastStatement");
      if (lastStatementAmountComplete) return lastStatementAmountComplete;

      const currentStatementAmountComplete = amounts.find(
        (amount) => amount.type === "CurrentStatement" && amount.amount > 0 // Do not show over-payment
      );
      if (currentStatementAmountComplete) return currentStatementAmountComplete;

      return null;
    },
  });

  if (isLoading) {
    return <Shimmer />;
  }

  if (!data) {
    return <NothingDueItem />;
  }

  return (
    <MetadataList.Item>
      <MetadataList.ItemLabel>
        {variants(data.type, {
          Overdue: "Overdue balance",
          LastStatement: `Due ${formatDate(data.nextRepaymentDate, "MMM D")}`,
          CurrentStatement: `Due ${formatDate(data.nextRepaymentDate, "MMM D")}`,
        })}
      </MetadataList.ItemLabel>
      <MetadataList.ItemValue>
        <MoneyAmount cents={data.amount} />
      </MetadataList.ItemValue>
    </MetadataList.Item>
  );
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const CapitalAccountCardChargeCardAmountDueItem: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  if (
    capitalAccountSummaryWithChargeCard.state === CapitalAccountRep.State.Offered ||
    capitalAccountSummaryWithChargeCard.state === CapitalAccountRep.State.OfferAccepted
  ) {
    return <NothingDueItem />;
  } else {
    return (
      <AmountDueByCapitalAccountGuid
        capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
      />
    );
  }
};

export default CapitalAccountCardChargeCardAmountDueItem;
