import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

const plaidConnectionsQueryHooks = makeQueryHooks({
  name: "plaidConnections",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker: ({ businessGuid }) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.plaid.getPlaidConnectionsByBusiness(businessGuid);
    };
  },
});

export default plaidConnectionsQueryHooks;

// Hooks

export const usePlaidConnections = () => {
  return plaidConnectionsQueryHooks.useData({});
};

export const useHasPlaidConnections = () => {
  return usePlaidConnections().length > 0;
};

export const useRefreshPlaidConnectionsQueries = () => {
  return plaidConnectionsQueryHooks.useRefreshQueries({});
};
