import { ArrowsLeftRight } from "@phosphor-icons/react";
import successGif from "assets/success.gif";
import PaymentDetailsCard from "components/PaymentDetailsCard";
import { useOpenBankAccountsByUnitCoDepositAccountId } from "modules/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";
import { PaymentMethod } from "pages/SendMoneyPage/utils";
import { useNavigate } from "react-router-dom";
import Button from "ui/inputs/Button";
import { startOfBankingDay } from "utils/date";
import { getTransferDeliveryMessage } from "utils/transfers";

import { Transfer } from "../TransferInfo/utils";

import styles from "./TransferScheduled.module.scss";

type Props = {
  transferInfo: Transfer;
  resetTransfer: () => void;
};

const TransferScheduled: React.FC<Props> = ({ transferInfo, resetTransfer }) => {
  const navigate = useNavigate();
  const openBankAccountsByUnitCoDepositAccountId = useOpenBankAccountsByUnitCoDepositAccountId();
  const now = startOfBankingDay();

  return (
    <>
      <img
        className={styles["success-gif"]}
        src={`${successGif}?a=${Math.random()}`}
        alt="success"
      />
      <div className={styles.heading}>
        <h1 className={styles.text}>Transfer successful</h1>
      </div>
      <PaymentDetailsCard
        paymentMethod={PaymentMethod.TRANSFER}
        iconBesideAmount={<ArrowsLeftRight size={16} />}
        amountInCents={transferInfo.amountInCents}
        from={transferInfo.from?.label!}
        to={transferInfo.to?.label!}
        deliveryMessage={getTransferDeliveryMessage(
          transferInfo,
          openBankAccountsByUnitCoDepositAccountId
        )}
        scheduledDate={now}
      />
      <div className={styles.buttons}>
        <Button className={styles.button} variant="tertiary" onClick={resetTransfer}>
          Make another transfer
        </Button>
        <Button className={styles.button} variant="primary" onClick={() => navigate("/")} autoFocus>
          Back to dashboard
        </Button>
      </div>
    </>
  );
};

export default TransferScheduled;
