import { useState, useCallback } from "react";

import useWaitForBillSync from "./useWaitForBillSync";

type ContingentOnBillSyncParams = {
  onSuccess: () => void;
  onError?: () => void;
};

// Invoke an onSuccess callback that is contingent on a bill sync completing successfully.
// If the bill sync fails, the onSuccess callback is not invoked, and the onError callback
// (if provided) is invoked instead.
const useContingentOnBillSync = (billId: string) => {
  const [isWaitingForBillSync, setIsWaitingForBillSync] = useState(false);
  const waitForBillSync = useWaitForBillSync(billId);

  const contingentOnBillSync = useCallback(
    async (params: ContingentOnBillSyncParams) => {
      setIsWaitingForBillSync(true);
      try {
        await waitForBillSync();
        params.onSuccess();
      } catch {
        params.onError?.();
      } finally {
        setIsWaitingForBillSync(false);
      }
    },
    [waitForBillSync]
  );

  return { contingentOnBillSync, isWaitingForBillSync };
};

export default useContingentOnBillSync;
