import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import RutterConnectionRep from "reps/RutterConnectionRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import { isRutterAccountingPlatform } from "utils/rutter/typeguards";

const useAccountingPlatformConnectionsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["accountingPlatformConnections", { businessGuid }],
    queryFn: async () => {
      const rutterConnections = await highbeamApi.rutterConnection.getByBusiness(businessGuid);
      return rutterConnections.filter(({ platformName }) =>
        isRutterAccountingPlatform(platformName)
      ) as RutterConnectionRep.RutterAccountingPlatformComplete[];
    },
  });
};

export default useAccountingPlatformConnectionsQueryOptions;
