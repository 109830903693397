import HighbeamAccountIcon from "modules/bank-accounts/components/HighbeamAccountIcon";
import colors from "styles/colors";
import AccountBar from "ui/data-display/AccountBar";
import Text from "ui/typography/Text";
import {
  HighbeamTransaction,
  isBookTransaction,
  isInternationalWireTransaction,
} from "utils/types/transactionsTypes";

import styles from "./Counterparty.module.scss";

type Props = {
  transaction: HighbeamTransaction;
  hideCounterpartyIcon?: boolean;
};

const getCounterpartyName = (transaction: HighbeamTransaction) =>
  isInternationalWireTransaction(transaction)
    ? (transaction.payeeName ?? "Payee name unavailable")
    : transaction.counterpartyFormattedName || transaction.counterpartyName;

const Counterparty: React.FC<Props> = ({ transaction, hideCounterpartyIcon = false }) => {
  const counterpartyName = getCounterpartyName(transaction);
  const image = transaction.counterpartyIcon && (
    <img src={transaction.counterpartyIcon} alt="" className={styles.icon} />
  );

  if (isBookTransaction(transaction)) {
    // TODO(alex): We _should_ be able to use `BankAccountBarByGuid` but `HighbeamTransaction` strips the counterparty info 🙄. Will fix later.
    return (
      <AccountBar
        icon={<HighbeamAccountIcon highbeamTypeName="DepositAccount" />} // This is wrong for `HighYield` and has been wrong.
        accountName={transaction.counterpartyName}
      />
    );
  }

  const icon = !hideCounterpartyIcon && image;

  return (
    <div className={styles.container}>
      <Text className={styles.text} size={14} weight="medium" color={colors.grey[800]}>
        {counterpartyName}
      </Text>
      {icon}
    </div>
  );
};

export default Counterparty;
