import { ArrowsDownUp } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import AccountLimitWarnings from "modules/account-limits/components/AccountLimitWarnings";
import BankAccountsDropdown from "modules/bank-accounts/components/BankAccountsDropdown";
import ExchangeRateQuote from "modules/international-wires/components/ExchangeRateQuote";
import {
  ExchangeRateTooltipContent,
  SwiftFeeTooltipContent,
} from "modules/international-wires/constants";
import useInternationalWireQuoteQueryOptions from "modules/international-wires/queries/useInternationalWireQuoteQueryOptions";
import usePayee from "modules/payees/queries/usePayee";
import {
  filterSupportedInternationalWireCountriesOptions,
  getCurrencyOption,
} from "pages/SendMoneyPage/internationalWires";
import { SWIFT_FEE } from "pages/SendMoneyPage/utils";
import { FC, Suspense, useState } from "react";
import { Controller } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";
import UserAvatar from "ui/data-display/UserAvatar";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import CurrencyInputV3 from "ui/inputs/CurrencyInputV3";
import DatePicker from "ui/inputs/DatePicker";
import DropdownV2 from "ui/inputs/DropdownV2";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import MoneyInputs from "ui/inputs/MoneyInputs";
import Switch from "ui/inputs/Switch";
import TextInputV2 from "ui/inputs/TextInputV2";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { TextButton } from "ui/typography";
import Text from "ui/typography/Text";
import { roundEpsilon } from "utils/math";
import { getDollarsFromCents, getCentsFromDollars } from "utils/money";
import getCurrencyDecimalPlaces from "utils/money/getCurrencyDecimalPlaces";
import getInitials from "utils/string/getInitials";

import { BillPaymentMethod } from "../../queries/useBillPaymentMethods";
import useReadyForPaymentBill from "../../queries/useReadyForPaymentBill";
import getBillFixedSide from "../../utils/getBillFixedSide";
import BillPaymentMethodNotFoundHelper from "../BillPaymentMethodNotFoundHelper";
import BillPaymentMethodsDropdown from "../BillPaymentMethodsDropdown";
import MissingAccountingCategoryModal from "../MissingAccountingCategoryModal";

import styles from "./BillPaymentPayloadFormBody.module.scss";
import useBillPaymentPayloadForm, {
  SEND_AMOUNT_LIMITS_EXCEEDED_HIDDEN_ERROR_MESSAGE,
  FROM_BANK_ACCOUNT_MISSING_CATEGORY_HIDDEN_ERROR_MESSAGE,
} from "./useBillPaymentPayloadForm";

type BillPaymentPayloadFormBodyProps = {
  form: ReturnType<typeof useBillPaymentPayloadForm>;
  billId: string;
  bankAccounts: BankAccountRep.Complete[];
  billPaymentMethods: BillPaymentMethod[];
};

const BillPaymentPayloadFormBody: FC<BillPaymentPayloadFormBodyProps> = ({
  form,
  billId,
  bankAccounts,
  billPaymentMethods,
}) => {
  const { control, watch } = form;

  const billPaymentMethod = watch("billPaymentMethod");
  const billPaymentMethodType = billPaymentMethod?.billPaymentMethodType;
  const sendAmount = watch("sendAmount");
  const fromBankAccount = watch("fromBankAccount");
  const sendConfirmationEmailToPayee = watch("sendConfirmationEmailToPayee");

  const selectedBillPaymentMethodCurrency = watch("billPaymentMethod.payeePaymentMethod.currency");

  // TODO(alex): HB-5295 Figure out how to share queries across components. This should use the same quote query as `BillPaymentFullPageModal`.
  const { data: quote, isLoading: isQuoteLoading } = useQuery({
    ...useInternationalWireQuoteQueryOptions(selectedBillPaymentMethodCurrency ?? ""),
    enabled:
      Boolean(selectedBillPaymentMethodCurrency) && selectedBillPaymentMethodCurrency !== "USD",
  });

  const bill = useReadyForPaymentBill(billId);
  const payee = usePayee(bill.payeeGuid, { required: true });

  const fixedSide = getBillFixedSide(bill);

  // NB(alex): This is a little sketchy, but it should be safe as long as currencies only have one set of purpose codes. This seems unlikely to change for a long time.
  const purposeCodes = filterSupportedInternationalWireCountriesOptions(bill.amount.currency)[0]
    ?.purposeCodes;

  const [isMissingAccountingCategoryModalOpen, setIsMissingAccountingCategoryModalOpen] =
    useState(false);

  return (
    <div>
      <div className={styles.headerContainer}>
        <UserAvatar size={32} color="purple-light" initials={getInitials(payee.name)} />

        <div className={styles.headerTitle}>{payee.name}</div>
        <div className={styles.headerDot}>•</div>
        <div className={styles.headerBillNumber}>Bill #{bill.invoiceNumber}</div>
      </div>

      <div className={styles.summaryContainer}>
        <div className={styles.amount}>
          <MoneyAmount
            showCurrencySymbol
            showTrailingCurrencyCode
            amount={bill.remainingAmount.amount}
            currencyCode={bill.remainingAmount.currency}
            currencyCodeTextWeight="medium"
          />
        </div>
        <div className={styles.dash}>–</div>
        <div className={styles.date}>
          {/* TODO(alex): Will extract to a different component in follow-up PR */}
          <div className={styles.calendarIcon}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11 2V4" stroke="#52525B" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 2V4" stroke="#52525B" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2.5 6H13.5" stroke="#52525B" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M13 3H3C2.72386 3 2.5 3.22386 2.5 3.5V13.5C2.5 13.7761 2.72386 14 3 14H13C13.2761 14 13.5 13.7761 13.5 13.5V3.5C13.5 3.22386 13.2761 3 13 3Z"
                stroke="#52525B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect x="8" y="8.5" width="8" height="8" fill="white" />
              <circle cx="13" cy="13.5" r="3.5" stroke="#52525B" />
              <path
                d="M13 11.5V13.5H15"
                stroke="#52525B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          Due {dayjs(bill.invoiceDueDate).format("MMM D, YYYY")}
        </div>
      </div>

      <FieldsetV2>
        <Controller
          control={control}
          name="sendAmount"
          render={({ field: { onChange, ...field }, fieldState }) => {
            return (
              <div>
                {billPaymentMethodType === "international-wire-local-currency" ? (
                  <MoneyInputs
                    amountInput={
                      <MoneyInputs.AmountInput
                        label={fixedSide === "Send" ? "You send exactly" : "You send"}
                        {...field}
                        currency="USD"
                        onChange={(value) => {
                          onChange(value);

                          if (selectedBillPaymentMethodCurrency && quote) {
                            const valueAsNumber = Number(value ?? 0);
                            const updatedReceiveMoneyAmount = roundEpsilon(
                              valueAsNumber * quote.rate,
                              getCurrencyDecimalPlaces(selectedBillPaymentMethodCurrency)
                            ).toString();

                            // NB(alex): I though I'd just be able to update `receiveMoney.amount` but apparently this doesn't work.
                            form.setValue("receiveAmount", updatedReceiveMoneyAmount);
                          }
                        }}
                        endAdornment={
                          fixedSide === "Receive" && isQuoteLoading && <AnimatedSpinner />
                        }
                      />
                    }
                    currencyDropdown={
                      <MoneyInputs.CurrencyDropdown
                        value={getCurrencyOption("USD") ?? null}
                        disabled
                      />
                    }
                  />
                ) : (
                  <CurrencyInputV3
                    label="You send"
                    startAdornment="$"
                    decimalScale={2}
                    onChange={onChange}
                    endAdornment={
                      billPaymentMethodType === "international-wire-usd-swift" && (
                        <Text size={14}>🇺🇸 USD</Text>
                      )
                    }
                    showErrorOutline={Boolean(fieldState.error)}
                    allowNegativeValue={false}
                    {...field}
                  />
                )}

                {fieldState.error &&
                  fieldState.error.message !== SEND_AMOUNT_LIMITS_EXCEEDED_HIDDEN_ERROR_MESSAGE && (
                    <Helper iconVariant="error">{fieldState.error.message}</Helper>
                  )}
              </div>
            );
          }}
        />

        {billPaymentMethodType && sendAmount && (
          <Suspense fallback={null}>
            <AccountLimitWarnings
              paymentMethod={billPaymentMethodType}
              amountInCents={getCentsFromDollars(sendAmount)}
              unitCoDepositAccountId={fromBankAccount?.unitCoDepositAccountId}
              className={styles.accountLimitWarnings}
            />
          </Suspense>
        )}

        {selectedBillPaymentMethodCurrency &&
          billPaymentMethodType === "international-wire-local-currency" && (
            <div className={styles.rateContainer}>
              <div className={styles.rateLabel}>
                <ArrowsDownUp size={16} />
                <Text
                  size={14}
                  weight="regular"
                  color={colors.grey[600]}
                  className={styles.rateLabelText}
                >
                  Exchange rate
                  <IconWithTooltip color="light" tooltip={<ExchangeRateTooltipContent />} />
                </Text>
              </div>

              <ExchangeRateQuote
                currency={selectedBillPaymentMethodCurrency}
                color={colors.grey[700]}
                weight="regular"
                size={14}
              />
            </div>
          )}

        {selectedBillPaymentMethodCurrency &&
          billPaymentMethodType === "international-wire-local-currency" && (
            <Controller
              control={control}
              name="receiveAmount"
              render={({ field: { onChange, ...field }, fieldState }) => (
                <div>
                  <MoneyInputs
                    amountInput={
                      <MoneyInputs.AmountInput
                        label={
                          fixedSide === "Receive" ? "Payee receives exactly" : "Payee receives"
                        }
                        {...field}
                        onChange={(value) => {
                          onChange(value);

                          if (quote) {
                            const valueAsNumber = Number(value ?? 0);
                            const updatedSendAmount = roundEpsilon(
                              valueAsNumber * quote.inverse,
                              getCurrencyDecimalPlaces("USD")
                            ).toString();

                            form.setValue("sendAmount", updatedSendAmount);
                          }
                        }}
                        currency={selectedBillPaymentMethodCurrency}
                        endAdornment={fixedSide === "Send" && isQuoteLoading && <AnimatedSpinner />}
                      />
                    }
                    currencyDropdown={
                      <MoneyInputs.CurrencyDropdown
                        value={getCurrencyOption(selectedBillPaymentMethodCurrency) ?? null}
                        disabled
                      />
                    }
                  />
                  {fieldState.error && (
                    <Helper iconVariant="error">{fieldState.error.message}</Helper>
                  )}
                </div>
              )}
            />
          )}

        {billPaymentMethodType === "international-wire-usd-swift" && (
          <div className={styles.rateContainer}>
            <div className={styles.rateLabel}>
              <Text size={14} weight="regular" color={colors.grey[600]}>
                SWIFT fee
              </Text>
            </div>

            <div className={styles.swiftFeeAmountContainer}>
              <MoneyAmount
                amount={getDollarsFromCents(SWIFT_FEE).toString()}
                showCurrencySymbol
                currencyCode="USD"
                weight="regular"
                color={colors.grey[600]}
              />
              <IconWithTooltip color="light" tooltip={<SwiftFeeTooltipContent />} />
            </div>
          </div>
        )}

        <FieldsetV2.Row columns={2}>
          <Controller
            control={control}
            name="fromBankAccount"
            render={({ field, fieldState }) => (
              <div>
                <BankAccountsDropdown
                  label="Pay from"
                  options={bankAccounts}
                  showErrorOutline={Boolean(fieldState.error)}
                  {...field}
                />
                {fieldState.error && (
                  <Helper iconVariant="error">
                    {fieldState.error.message ===
                    FROM_BANK_ACCOUNT_MISSING_CATEGORY_HIDDEN_ERROR_MESSAGE ? (
                      <>
                        Bank account is missing an accounting category.{" "}
                        <TextButton onClick={() => setIsMissingAccountingCategoryModalOpen(true)}>
                          Set up
                        </TextButton>
                        .
                      </>
                    ) : (
                      fieldState.error.message
                    )}
                  </Helper>
                )}
              </div>
            )}
          />

          <Controller
            control={control}
            name="paymentDate"
            render={({ field: { ref: _ref, value, onChange, ...field }, fieldState }) => (
              <div>
                <DatePicker
                  variant="no-date"
                  label="Payment date"
                  isClearable
                  value={value ? value.toDate() : null}
                  onChange={(selected) => {
                    onChange(selected ? dayjs(selected) : selected);
                  }}
                  disabled
                  {...field}
                />
                {fieldState.error && (
                  <Helper iconVariant="error">{fieldState.error.message}</Helper>
                )}
              </div>
            )}
          />
        </FieldsetV2.Row>

        <FieldsetV2.Row
          columns={purposeCodes ? 3 : 2}
          className={purposeCodes ? styles.purposeCodeRow : ""}
        >
          <Controller
            control={control}
            name="billPaymentMethod"
            render={({ field, fieldState }) => {
              const { billPaymentMethodType, payeePaymentMethod } = field.value || {};

              return (
                <div>
                  <BillPaymentMethodsDropdown options={billPaymentMethods} {...field} />

                  {billPaymentMethodType && payeePaymentMethod === null && (
                    <BillPaymentMethodNotFoundHelper
                      billId={billId}
                      billPaymentMethodType={billPaymentMethodType}
                      payeeName={payee.name}
                    />
                  )}

                  {fieldState.error && (
                    <Helper iconVariant="error">{fieldState.error.message}</Helper>
                  )}
                </div>
              );
            }}
          />

          {purposeCodes && (
            <Controller
              control={control}
              name="purposeCode"
              render={({ field, fieldState: { error } }) => {
                return (
                  <div>
                    <DropdownV2
                      label="Purpose code"
                      options={purposeCodes}
                      isClearable
                      renderOption={(optionProps) => (
                        <DropdownV2.Option
                          {...optionProps}
                          description={optionProps.data.description}
                        />
                      )}
                      {...field}
                    />
                    {error && <Helper iconVariant="error">{error.message}</Helper>}
                  </div>
                );
              }}
            />
          )}

          <Controller
            control={control}
            name="paymentDescription"
            render={({ field, fieldState }) => (
              <div>
                <TextInputV2 label="Payment description for payee (optional)" {...field} />
                {fieldState.error && (
                  <Helper iconVariant="error">{fieldState.error.message}</Helper>
                )}
              </div>
            )}
          />
        </FieldsetV2.Row>

        <Controller
          control={control}
          name="sendConfirmationEmailToPayee"
          defaultValue={false}
          render={({ field, fieldState }) => (
            <div className={styles.switchFieldContainer}>
              <div className={styles.switchWithLabelContainer}>
                <Switch id={field.name} {...field} />

                <label htmlFor={field.name}>Send payee a confirmation email</label>
              </div>
              {fieldState.error && <Helper iconVariant="error">{fieldState.error.message}</Helper>}
            </div>
          )}
        />

        {sendConfirmationEmailToPayee && (
          <Controller
            control={control}
            name="payeeEmail"
            defaultValue={payee.emailAddress}
            render={({ field, fieldState: { error } }) => {
              return (
                <div>
                  <TextInputV2
                    type="email"
                    label="Email address"
                    {...field}
                    onFocus={(e) => e.target.select()}
                    value={field.value ?? ""}
                  />
                  {error && <Helper iconVariant="error">{error.message}</Helper>}
                </div>
              );
            }}
          />
        )}
      </FieldsetV2>

      {isMissingAccountingCategoryModalOpen && (
        <MissingAccountingCategoryModal
          bankAccount={fromBankAccount!}
          onClose={() => {
            setIsMissingAccountingCategoryModalOpen(false);
            form.clearErrors("fromBankAccount");
          }}
        />
      )}
    </div>
  );
};

export default BillPaymentPayloadFormBody;
