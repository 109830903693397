import { DEMO_BUSINESS_GUID } from "modules/demo-account/constants";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { startOfBankingDay } from "utils/date";
import useQueryOptions from "utils/react-query/useQueryOptions";

import generateFakeData from "../utils/generateFakeData";
import getHistoryWithTodayForSingleAccount from "../utils/getHistoryWithTodayForSingleAccount";

import bankAccountsQueryHooks from "./bankAccountsQueryHooks";

const BANK_ACCOUNT_NUM_DAYS_HISTORY = 30;

const useBankAccountBalanceHistoryQueryOptions = (accountId: string) => {
  const unitApi = useUnitApi();
  const bankAccounts = bankAccountsQueryHooks.useData({ status: "all" });
  const currentBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.unitCoDepositAccountId === accountId
  );
  const currentBankAccountBalance = currentBankAccount?.availableBalance;
  const businessGuid = currentBankAccount?.businessGuid;

  return useQueryOptions({
    queryKey: ["bankAccountBalanceHistory", { accountId, businessGuid }],
    queryFn: async () => {
      const accountsEndOfDay = await unitApi.accountsEndOfDay.list({
        since: startOfBankingDay()
          .subtract(BANK_ACCOUNT_NUM_DAYS_HISTORY, "days")
          .format("YYYY-MM-DD"),
        accountId: accountId,
      });
      const historyWithToday =
        accountsEndOfDay?.data || currentBankAccountBalance
          ? getHistoryWithTodayForSingleAccount(
              accountsEndOfDay?.data || [],
              currentBankAccountBalance || 0
            )
          : [];

      if (businessGuid === DEMO_BUSINESS_GUID) {
        return generateFakeData(historyWithToday);
      }

      return historyWithToday;
    },
  });
};

export default useBankAccountBalanceHistoryQueryOptions;
