import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoPaymentsQueryOptions from "modules/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";

type Params = {
  billIds: string[];
};

const useBillRelatedPaymentsQueryOptions = ({ billIds }: Params) => {
  return useUnitCoPaymentsQueryOptions({
    tags: {
      billIds: billIds.join(","),
    },
  });
};

const useBillRelatedUnitCoPayments = (params: Params) => {
  const { data } = useSuspenseQuery(useBillRelatedPaymentsQueryOptions(params));
  return data;
};

export default useBillRelatedUnitCoPayments;
