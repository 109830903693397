import { CheckCircle, Plus } from "@phosphor-icons/react";
import useCreateBankAccountMutation from "modules/bank-accounts/mutations/useCreateBankAccountMutation";
import { useState } from "react";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import TextInput from "ui/inputs/TextInput";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import { isNotNull } from "utils/array";

import styles from "./CreateAccountModal.module.scss";

type Props = {
  onClose: () => void;
  name?: string;
};

const CreationModal: React.FC<
  Props & { handleSuccess: (account: BankAccountRep.Complete) => void }
> = ({ name, onClose, handleSuccess }) => {
  const [accountName, setAccountName] = useState(name ?? "");
  const { mutateAsync: createBankAccount, isPending } = useCreateBankAccountMutation();

  return (
    <Modal
      title="Create new account"
      icon={<Plus size={24} />}
      onClose={onClose}
      buttonText="Create account"
      showCancel
      onClick={async () => {
        const response = await createBankAccount(accountName);
        handleSuccess(response);
      }}
      isLoading={isPending}
      isPrimaryButtonDisabled={isPending}
      isCancelButtonDisabled={isPending}
    >
      <div className={styles["creation-container"]}>
        <Text size={16} weight={"bold"} className={styles["creation-container__title"]}>
          Fill in the account details
        </Text>
        <TextInput label={"Account Name"} onChange={setAccountName} value={accountName} />
      </div>
    </Modal>
  );
};

const SuccessModal: React.FC<
  Props & { newAccount: BankAccountRep.Complete; setNewAccount: () => void }
> = (props) => {
  const rowInfo = [
    { label: "Account Name", value: props.newAccount.name },
    { label: "Account #", value: `•• ${props.newAccount.accountNumber.slice(-4)}` },
  ].filter(isNotNull);

  const rows = rowInfo.map((row) => (
    <div className={styles["success-container__row"]} key={row.value}>
      <Text size={14} className={styles["success-container__row__label"]}>
        {row.label}
      </Text>
      <Text size={14} className={styles["success-container__row__value"]}>
        {row.value}
      </Text>
    </div>
  ));

  const onClose = () => {
    props.setNewAccount();
    props.onClose();
  };

  return (
    <Modal
      title={"Account created!"}
      icon={<CheckCircle size={24} weight={"fill"} color={colors.green[400]} />}
      onClose={onClose}
      buttonText={"Close"}
      onClick={onClose}
    >
      <div className={styles["success-container"]}>
        <Text size={16} weight={"bold"} className={styles["success-container__title"]}>
          Here’s your account details
        </Text>
        {rows}
      </div>
    </Modal>
  );
};

const CreateAccountModal: React.FC<Props> = ({ ...props }) => {
  const [newAccount, setNewAccount] = useState<BankAccountRep.Complete | null>(null);

  if (newAccount) {
    return (
      <SuccessModal
        {...props}
        newAccount={newAccount}
        setNewAccount={() => {
          setNewAccount(null);
        }}
      />
    );
  } else {
    return (
      <CreationModal
        {...props}
        handleSuccess={(account) => {
          setNewAccount(account);
        }}
      />
    );
  }
};

export default CreateAccountModal;
