import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import PayeeRep from "reps/PayeeRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useRefreshPayeesQueries } from "../queries/usePayees";

const useCreatePayeeMutation = (
  additionalOptions: MutationAdditionalOptions<PayeeRep.Complete, PayeeRep.Creation> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshPayeesQueries = useRefreshPayeesQueries();

  return useMutationWithDefaults(
    {
      mutationFn: (body) => {
        return highbeamApi.payee.create(businessGuid, body);
      },
      onSuccess: () => {
        refreshPayeesQueries();
      },
    },
    additionalOptions
  );
};

export default useCreatePayeeMutation;
