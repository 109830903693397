import {
  AchReceivedPayment,
  CheckDeposit,
  CheckDepositStatus,
  Payment,
  PaymentStatus,
} from "@highbeam/unit-node-sdk";
import { captureMessage } from "@sentry/react";
import getBankAccountDisplayName from "modules/bank-accounts/utils/getBankAccountDisplayName";
import BankAccountRep from "reps/BankAccountRep";

import { formatBankingDate } from "./date";
import { formatAmount } from "./string";
import { Counterparty } from "./types/counterparty";
import { BaseHighbeamPayment, HighbeamPayment, HighbeamPaymentStatus } from "./types/paymentsTypes";
import { HighbeamFailedPaymentTransaction } from "./types/transactionsTypes";

export function getPaymentCounterparty(
  payment: Payment | CheckDeposit | AchReceivedPayment,
  accountsById: Map<string, BankAccountRep.Complete>
): Counterparty {
  switch (payment.type) {
    case "achPayment":
      return {
        name: payment.attributes.counterparty.name,
        routingNumber: payment.attributes.counterparty.routingNumber,
        accountNumber: payment.attributes.counterparty.accountNumber,
        accountType: payment.attributes.counterparty.accountType,
      };
    case "achReceivedPayment":
      return {
        name: payment.attributes.companyName,
        routingNumber: payment.attributes.counterpartyRoutingNumber,
        accountNumber: payment.attributes.counterpartyAccountNumber,
      };

    // For a Book Payment, the counterparty is the user's receiving account.
    case "bookPayment":
      const accountId = payment.relationships.counterpartyAccount.data.id;
      return {
        name: accountsById.get(accountId)?.name ?? "Book Payment",
        routingNumber: accountsById.get(accountId)?.routingNumber,
        accountNumber: accountsById.get(accountId)?.accountNumber,
        accountType: accountsById.get(accountId)?.type,
      };

    case "wirePayment":
      return {
        name: payment.attributes.counterparty.name,
        routingNumber: payment.attributes.counterparty.routingNumber,
        accountNumber: payment.attributes.counterparty.accountNumber,
      };
    case "checkDeposit":
      return {
        name: payment.attributes.counterparty?.name ?? "Check Deposit",
        routingNumber: payment.attributes.counterparty?.routingNumber,
        accountNumber: payment.attributes.counterparty?.accountNumber,
        // Users can add a custom name to the check deposit
        formattedName: (payment.attributes?.tags as any)?.userProvidedCounterpartyName,
      };
  }
}

// We merge statuses together to make it easier for users to understand
export function getPaymentStatus(
  status: PaymentStatus | CheckDepositStatus
): HighbeamPaymentStatus {
  switch (status) {
    case "Pending":
    case "PendingReview":
      return "Pending";
    case "Returned":
      return "Returned";
    case "Rejected":
      return "Rejected";
    case "Clearing":
      return "Clearing";
    case "Sent":
      return "Sent";
    case "Canceled":
      return "Canceled";
    // Checkdeposit payment should never be in this state since we submit everything all at once. Don't want to show a weird state to users.
    case "AwaitingImages":
    case "AwaitingFrontImage":
    case "AwaitingBackImage":
    case "AwaitingCustomerConfirmation":
      return "Unknown";
  }
}

export function convertUnitPaymentToHighbeamPayment(
  payment: Payment | CheckDeposit,
  accountsById: Map<string, BankAccountRep.Complete>
): HighbeamPayment {
  const accountId = payment.relationships.account.data.id;
  const account = accountsById.get(accountId);

  const baseHighbeamPayment: BaseHighbeamPayment = {
    id: payment.id,
    type: payment.type,
    status: getPaymentStatus(payment.attributes.status),
    createdAt: payment.attributes.createdAt,
    formattedCreatedAt: formatBankingDate(payment.attributes.createdAt),
    depositAccountId: accountId,
    depositAccountName: account ? getBankAccountDisplayName(account) : "",
    counterparty: getPaymentCounterparty(payment, accountsById),
    direction: payment.type === "checkDeposit" ? "Debit" : payment.attributes.direction,
    shortMethodName: "Payment",
    longMethodName: "Payment",
    amountInCents: payment.attributes.amount,
    formattedAmount: formatAmount(payment.attributes.amount),
    description: payment.attributes.description,
    paymentGuid: payment.attributes.tags?.paymentGuid ?? undefined,
  };

  return enrichHighbeamPayment(baseHighbeamPayment, payment);
}

export function convertHighbeamFailedPaymentToHighbeamTransaction(
  payment: HighbeamPayment
): HighbeamFailedPaymentTransaction {
  const failedPaymentTransaction: HighbeamFailedPaymentTransaction = {
    id: payment.id,
    type: "failedPaymentTransaction",
    createdAt: payment.createdAt,
    formattedCreatedAt: payment.formattedCreatedAt,
    accountId: payment.depositAccountId,
    accountName: payment.depositAccountName,
    counterpartyName: payment.counterparty?.name || "",
    direction: payment.direction === "Debit" ? "Credit" : "Debit",
    shortMethodName: payment.shortMethodName,
    longMethodName: payment.longMethodName,
    amountInCents: payment.amountInCents,
    summary: getFailedPaymentStatusReasonDescription(payment),
    reason: payment.statusReason || "",
    category: "Payment",
    paymentStatus: payment.status,
    paymentEstimatedDate: payment.estimatedDate,
    formattedPaymentEstimatedDate: payment.formattedEstimatedDate,
  };

  return failedPaymentTransaction;
}

export function enrichHighbeamPayment(
  base: BaseHighbeamPayment,
  payment: Payment | CheckDeposit
): HighbeamPayment {
  switch (payment.type) {
    case "achPayment":
      return {
        ...base,
        type: "achPayment",
        statusReason: payment.attributes.reason,
        estimatedDate: payment.attributes.settlementDate,
        formattedCreatedAt: formatBankingDate(payment.attributes.createdAt),
        formattedEstimatedDate: payment.attributes.settlementDate
          ? formatBankingDate(payment.attributes.settlementDate)
          : "-",
        shortMethodName: "ACH",
        longMethodName: "ACH transaction",
      };
    case "bookPayment":
      return {
        ...base,
        type: "bookPayment",
        statusReason: payment.attributes.reason,
        shortMethodName: "Book",
        longMethodName: "Book transaction",
      };
    case "wirePayment":
      return {
        ...base,
        type: "wirePayment",
        statusReason: payment.attributes.reason,
        shortMethodName: "Wire",
        longMethodName: "Wire transaction",
      };
    case "checkDeposit":
      return {
        ...base,
        type: "checkDeposit",
        counterparty: {
          ...base.counterparty,
          name: base.counterparty?.formattedName || base.counterparty.name || "Check deposit",
        },
        statusReason: payment.attributes.reason,
        estimatedDate: payment.attributes.settlementDate,
        shortMethodName: "Check",
        longMethodName: "Check deposit",
        formattedEstimatedDate: payment.attributes.settlementDate
          ? formatBankingDate(payment.attributes.settlementDate)
          : "-",
      };
    default:
      captureMessage(`Unhandled payment type: ${payment}`);
      return {
        ...base,
        type: "unknown",
        shortMethodName: "Payment",
        longMethodName: "Payment",
      };
  }
}

export function getFailedPaymentStatusReasonDescription(payment: HighbeamPayment): string {
  if (payment.type === "checkDeposit") {
    switch (payment.statusReason) {
      case "Altered / Fictitious":
        return "Check was deemed fictitious or altered.";
      case "Suspected Counterfeit":
        return "Check was suspected to be counterfeit.";
      case "Missing Endorsement":
        return "Check is missing endorsement. Please sign back of the check.";
      case "Cannot read check":
        return "Check couldn't be read. Please retake photo of check.";
      case "Date issue":
        return "Check has date issue.";
      case "Check has already been submitted":
        return "Check has already been submitted. It cannot be submitted again.";
      case "Missing Signature":
        return "Check is missing signature.";
      case "Beneficiary Name Mismatch":
        return "Make sure name of check matches the one on account.";
      case "Check processing failed":
        return "Check processing failed. Please try again";
      case "No check uploaded":
        return "No check uploaded. Please try again.";
      case "Cannot read Account data on bottom of check":
        return "Account data isn't clear on bottom of check. Please retake photo of check.";
      case "Unable to read account information due to interference from signature or other markings.":
        return "Unable to read account information due to interference from signature or other markings.";
      case "Incomplete check":
        return "Check is incomplete.";
      case "Amount mismatch":
        return "Amount is mismatched.";
      case "Blank check":
        return "Check is blank.";
      case "Other":
        return "Please contact support for more information.";
      default:
        return payment.statusReason || "Please contact support for more information.";
    }
  } else {
    return unitErrorToHighbeamError(payment.statusReason);
  }
}

export function unitErrorToHighbeamError(unitError?: string): string {
  switch (unitError) {
    case "InsufficientFunds":
      return "The account doesn't have sufficient balance.";
    case "DailyACHCreditLimitExceeded":
      return "The daily ACH credit limit has been exceeded.";
    case "DailyACHDebitLimitExceeded":
      return "The daily ACH debit limit has been exceeded.";
    case "MonthlyACHCreditLimitExceeded":
      return "The monthly ACH credit limit has been exceeded.";
    case "MonthlyACHDebitLimitExceeded":
      return "The monthly ACH debit limit has been exceeded.";
    case "Invalid Routing Number":
      return "The counterparty routing number is not valid.";
    case "Wire Rejected":
      return "The counterparty rejected the wire payment.";
    case "ClientRequest":
      return "The client has requested the wire payment to be rejected.";
    default:
      return "Please contact support for more information.";
  }
}
