import useDeletePlaidBankAccountMutation from "modules/plaid/mutations/useDeletePlaidBankAccountMutation";
import { notify } from "ui/feedback/Toast";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

type Props = {
  onClose: () => void;
  plaidAccountId: string;
};

const DeletePlaidAccountModal: React.FC<Props> = ({ onClose, plaidAccountId }) => {
  const deletePlaidBankAccountMutation = useDeletePlaidBankAccountMutation({
    onSuccess: () => {
      onClose();
      notify("info", "Plaid bank account deleted.");
    },
  });
  return (
    <ModalV4 size={"standard"} onClose={onClose}>
      <ModalV4.Header>[SUPERUSER] Delete Plaid bank account</ModalV4.Header>
      <ModalV4.Body>
        <Text size={14}>
          Are you sure you want to delete this Plaid bank account? This cannot be undone.
        </Text>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton
          variant="danger"
          onClick={() => deletePlaidBankAccountMutation.mutate({ plaidAccountId })}
          isLoading={deletePlaidBankAccountMutation.isPending}
        >
          Hard delete
        </ModalV4.SubmitButton>
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default DeletePlaidAccountModal;
