import useCreateConversationMutation from "modules/chat/mutations/useCreateConversationMutation";
import useCreateExchangeMutation from "modules/chat/mutations/useCreateExchangeMutation";
import { useState } from "react";

type Options = {
  conversationId: string | undefined;
  content: string;
};

const useSendChatMessage = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (conversationId: string) => void;
  onError?: (e: any) => void;
}): {
  sendChatMessage: (options: Options) => Promise<void>;
  isPending: boolean;
} => {
  const [isPending, setIsPending] = useState(false);

  const { mutateAsync: createConversation } = useCreateConversationMutation({});
  const { mutateAsync: createExchange } = useCreateExchangeMutation({});

  return {
    sendChatMessage: async (options: Options) => {
      setIsPending(true);
      try {
        const conversationId = options.conversationId
          ? options.conversationId
          : (await createConversation()).id;
        await createExchange({ conversationId, content: options.content });
        onSuccess?.(conversationId);
      } catch (e) {
        onError?.(e);
      } finally {
        setIsPending(false);
      }
    },
    isPending,
  };
};

export default useSendChatMessage;
