import { captureMessage } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import { useCallback } from "react";
import UnitCoTokenScope from "reps/UnitCoTokenScope";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

type Params = {
  scopes: UnitCoTokenScope[];
};

/**
 * When this function is called, it returns a promise that is resolved when the token is fetched.
 * If the token is already fetched, it resolves immediately. It resolves and returns the token.
 * If MFA is cancelled by the user, it rejects the promise.
 */

const useGetUnitCoSensitiveTokenWithMfa = ({ scopes }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const { mfa } = useMfa();

  const {
    data: cachedData,
    refetch,
    isStale,
  } = useQuery({
    queryKey: ["unitCoSensitiveToken", { businessGuid, scopes }],
    queryFn: async () => {
      await mfa();
      const { token } = await highbeamApi.unitCoCustomerToken.create(businessGuid, scopes);
      return token;
    },
    retry: false,
    enabled: false,
    staleTime: 24 * 60 * 60 * 1000,
  });

  const getUnitCoSensitiveTokenWithMfa = useCallback(async () => {
    if (!isStale && cachedData) {
      return cachedData;
    }

    const { data } = await refetch({ throwOnError: true });

    if (!data) {
      captureMessage(
        "Alert(alex): Token not found, should not be possible, experimenting with a new pattern."
      );
      throw new Error("Token not found");
    }

    return data;
  }, [cachedData, isStale, refetch]);

  return getUnitCoSensitiveTokenWithMfa;
};

export default useGetUnitCoSensitiveTokenWithMfa;
