import { ArrowsClockwise } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import AccountPaymentsView from "modules/payment-switcher/components/AccountPaymentsView";
import SwitchHelper from "modules/payment-switcher/components/SwitchHelper/SwitchHelper";
import React from "react";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import TabsV2 from "ui/navigation/TabsV2";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

const tabs = {
  "bank-payments": { label: "Bank payments" },
  "card-payments": { label: "Card payments" },
} satisfies TabConfig;

const PaymentSwitcherPage: React.FC = () => {
  const [tab, setTab] = useSearchParamOption("tab", getObjectKeys(tabs), "bank-payments");

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Switch auto-payments</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.BackCaret to="/accounts" />
          <DashboardPage.Header.IconTile icon={<ArrowsClockwise />} />
          <DashboardPage.Header.Title>Switch auto-payments</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <TabsV2 activeTab={tab} setActiveTab={setTab} tabs={tabs} />

        <DashboardPage.Section>
          <AccountPaymentsViewForTab tab={tab} />
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default PaymentSwitcherPage;

const AccountPaymentsViewForTab: React.FC<{ tab: keyof typeof tabs }> = ({ tab }) => {
  switch (tab) {
    case "bank-payments":
      return (
        <AccountPaymentsView
          filter={(account) => account.accountTypeDirection === "Positive"}
          switchHelper={<SwitchHelper.HowToSwitch />}
        />
      );
    case "card-payments":
      return (
        <AccountPaymentsView
          filter={(account) => account.accountTypeDirection === "Negative"}
          switchHelper={<SwitchHelper.NewCardDetails />}
        />
      );
  }
};
