import { useIsMutating } from "@tanstack/react-query";
import useBill from "modules/bills/queries/useBill";

import { makeMutationKey } from "../mutations/useBillSyncSyncMutation";
import shouldBillSyncBeDryRun from "../utils/shouldBillSyncBeDryRun";

const useBillSyncIsRunning = (billId: string) => {
  const bill = useBill(billId, { required: true });
  const dryRun = shouldBillSyncBeDryRun(bill);
  const mutationKey = makeMutationKey(billId, dryRun);

  // useIsMutating returns the number of mutations that are running, *not* a boolean.
  const count = useIsMutating({ mutationKey });
  return count > 0;
};

export default useBillSyncIsRunning;
