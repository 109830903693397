import AccountingAccountRep from "reps/AccountingAccountRep";

const makeAccountingAccountDisplayName = (accountingAccount: AccountingAccountRep.Complete) => {
  const { name, nominalCode } = accountingAccount;

  if (!nominalCode) {
    return name;
  }

  const escapedNominalCode = nominalCode.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const prefixPattern = new RegExp(`^${escapedNominalCode}\\s*-?\\s*`, "i");

  return name.replace(prefixPattern, "").trim();
};

export default makeAccountingAccountDisplayName;
