import { useSuspenseQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { useLineOfCreditInterestFeeQueryOptions } from "modules/line-of-credit/queries/useLineOfCreditInterestFee";
import calculateAccruedInterestFromDailyInterestFees from "modules/line-of-credit/utils/calculateAccruedInterestFromDailyInterestFees";
import { FC, useState } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import HighlightItem from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Span } from "ui/typography";
import Text from "ui/typography/Text";
import { roundEpsilon } from "utils/math";

dayjs.extend(weekday);

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  className?: string;
};

const CapitalAccountInterestDueHighlightItem: FC<Props> = ({
  capitalAccountSummary,
  className,
}) => {
  const [now] = useState(dayjs());

  const isTodayBeforeTuesdayThisWeek = now.day() < 2;
  const accruedInterestSince = isTodayBeforeTuesdayThisWeek ? now.weekday(-5) : now.weekday(2);
  const accruedInterestDueDate = isTodayBeforeTuesdayThisWeek
    ? now.weekday(2)
    : now.add(1, "week").weekday(2);

  const { data: accruedInterestAmountInCents } = useSuspenseQuery({
    ...useLineOfCreditInterestFeeQueryOptions({
      lineOfCreditGuid: capitalAccountSummary.guid,
      since: accruedInterestSince,
      untilInclusive: now.endOf("day"),
    }),
    select: (dailyInterestFees) => calculateAccruedInterestFromDailyInterestFees(dailyInterestFees),
  });

  return (
    <HighlightItem className={className}>
      <HighlightItem.Heading>
        <>
          Interest due {accruedInterestDueDate.format("MMM D")}
          <IconWithTooltip
            color="primary"
            tooltip={
              <Text size={14}>
                Interest accrues daily, and the total amount will be paid automatically from your
                primary account every Tuesday.
              </Text>
            }
          />
        </>
      </HighlightItem.Heading>

      <HighlightItem.Body>
        <MoneyAmount weight="medium" cents={accruedInterestAmountInCents} />
      </HighlightItem.Body>

      <HighlightItem.Footer>
        {capitalAccountSummary.details.netApr > 0 && (
          <Span className="text-xs">
            {roundEpsilon(capitalAccountSummary.details.netApr * 100, 2)}% APR
          </Span>
        )}
      </HighlightItem.Footer>
    </HighlightItem>
  );
};

export default CapitalAccountInterestDueHighlightItem;
