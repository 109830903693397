import { useChatWidget } from "components/ChatWidget";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";
import { notify } from "ui/feedback/Toast";
import { copyToClipboard } from "utils/clipboard/useCopyToClipboard";

type CommandFactory<TCommandName extends string, TPayload extends object | null = null> = {
  commandName: TCommandName;
  payload: TPayload;
};

export type ChatWidgetCommand = CommandFactory<"chat-widget", { message: string }>;
export type CopyTextCommand = CommandFactory<"copy-text", { text: string; showText?: boolean }>;
export type NavigateCommand = CommandFactory<"navigate", { to: string; openInNewTab?: boolean }>;
export type SuperuserCommand = CommandFactory<"superuser", { businessGuid: string | null }>;

export type Command = ChatWidgetCommand | CopyTextCommand | NavigateCommand | SuperuserCommand;

const useExecuteCommand = () => {
  const chat = useChatWidget();
  const navigate = useNavigate();
  const setIsSuperusering = useSetRecoilState(isSuperuseringState);
  const setBusinessGuid = useSetRecoilState(businessGuidState);

  return useCallback(
    // Returning `boolean` ensures the switch statement handles every command case.
    ({ commandName, payload }: Command): boolean => {
      switch (commandName) {
        case "chat-widget":
          chat.message(payload.message);
          return true;

        case "copy-text":
          copyToClipboard(payload.text);
          const text = payload.showText ? `Copied "${payload.text}"` : "Copied to clipboard";
          notify("success", text);
          return true;

        case "navigate":
          if (payload.openInNewTab) {
            window.open(payload.to, "_blank");
          } else {
            navigate(payload.to);
          }
          return true;

        case "superuser":
          setIsSuperusering(Boolean(payload.businessGuid));
          setBusinessGuid(payload.businessGuid ?? "");
          window.location.reload();
          return true;
      }
    },
    [chat, navigate, setIsSuperusering, setBusinessGuid]
  );
};

export default useExecuteCommand;
