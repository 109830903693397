import { Card } from "@highbeam/unit-node-sdk";
import { Archive } from "@phosphor-icons/react";
import CardAvatarBar from "modules/cards/components/CardAvatarBar";
import { FC } from "react";
import Divider from "ui/data-display/Divider";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

import useArchiveCardMutation from "../mutations/useArchiveCardMutation";

type Props = {
  card: Card;
  onClose: () => void;
};

const ArchiveCardModal: FC<Props> = ({ card, onClose }) => {
  const { mutate: archiveCard, isPending } = useArchiveCardMutation();

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Header icon={<Archive size={24} />}>Close this card?</ModalV4.Header>

      <ModalV4.Body>
        <CardAvatarBar card={card} />

        <Divider />

        <Text size={14}>Once closed, you won’t be able to use this card again.</Text>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton
          variant="danger"
          isLoading={isPending}
          onClick={() => archiveCard({ cardId: card.id })}
        >
          Close card
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton variant="ghost" onClick={onClose}>
          Cancel
        </ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default ArchiveCardModal;
