import { useQueryClient } from "@tanstack/react-query";
import { CHAT_CONFIGS_QUERY_KEY } from "modules/chat/queries/useChatConfig";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { ChatConfigRep } from "reps/chat/ChatConfigRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useConfigureChatMutation = (
  additionalOptions: MutationAdditionalOptions<ChatConfigRep, void>
) => {
  const queryClient = useQueryClient();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        return await highbeamApi.chatConfig.create({
          businessGuid,
          isEnabled: false,
          details: "",
        });
      },
      onSuccess: async (chatConfig) => {
        await queryClient.setQueryData([CHAT_CONFIGS_QUERY_KEY, { businessGuid }], chatConfig);
      },
    },
    additionalOptions
  );
};

export default useConfigureChatMutation;
