import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { notify } from "ui/feedback/Toast";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY } from "../queries/useUnitCoRecurringPaymentsQueryOptions";

type Variables = {
  recurringPaymentId: string;
};

const useCancelRecurringPaymentMutation = () => {
  const unitApi = useUnitApi();
  const refreshRecurringPayments = useRefreshQuery([UNIT_CO_RECURRING_PAYMENTS_QUERY_KEY]);

  return useMutation({
    mutationFn: ({ recurringPaymentId }: Variables) => {
      return unitApi.recurringPayments.disable(recurringPaymentId);
    },
    onError: (error) => {
      captureException(error);
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async () => {
      await refreshRecurringPayments();
      notify("success", "Recurring payment canceled");
    },
  });
};

export default useCancelRecurringPaymentMutation;
