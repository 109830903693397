import chargeCardProgramQueryHooks from "modules/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { FC } from "react";

import getChargeCardRepaymentTermsCopy from "../utils/getChargeCardRepaymentTermsCopy";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardOfferRepaymentPeriodContent: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardProgram = chargeCardProgramQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    state: capitalAccountSummaryWithChargeCard.state,
  });
  return <>{getChargeCardRepaymentTermsCopy(chargeCardProgram.maxRepaymentDays)}</>;
};

export default ChargeCardOfferRepaymentPeriodContent;
