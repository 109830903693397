import { ErrorBoundary } from "@sentry/react";
import ErrorPage from "modules/error/pages/ErrorPage";
import React, { Suspense } from "react";
import DotsPageLoader from "ui/feedback/DotsLoaderPage";

import DashboardSidebar from "./DashboardSidebar";

// Used for getting the content's containing element to control the scroll position.
export const DASHBOARD_PAGE_CONTENT_ID = "dashboard-page-content";

const DashboardLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex h-full w-full">
      <DashboardSidebar />

      <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
        <Suspense fallback={<DotsPageLoader variant="page" />}>
          <div
            className="flex w-full flex-col overflow-y-auto @container"
            id={DASHBOARD_PAGE_CONTENT_ID}
          >
            {children}
          </div>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default DashboardLayout;
