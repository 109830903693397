import env from "env";

import AccountingAccountApi from "./AccountingAccountApi";
import ActionItemApi from "./ActionItemApi";
import ApEmailAliasApi from "./ApEmailAliasApi";
import ApSettingsApi from "./ApSettingsApi";
import ArchiveBusinessApi from "./ArchiveBusinessApi";
import AssignedBillSummaryApi from "./AssignedBillSummaryApi";
import AuthorizedUserApi from "./AuthorizedUserApi";
import BankAccountApi from "./BankAccountApi";
import BankApplicationApi from "./BankApplicationApi";
import BillActionApi from "./BillActionApi";
import BillApprovalActionApi from "./BillApprovalActionApi";
import BillApprovalApi from "./BillApprovalApi";
import BillDocumentActionApi from "./BillDocumentActionApi";
import BillDocumentApi from "./BillDocumentApi";
import BillEmailApi from "./BillEmailApi";
import BillPayeeApi from "./BillPayeeApi";
import BillPaymentApi from "./BillPaymentApi";
import BusinessAddressApi from "./BusinessAddressApi";
import BusinessApi from "./BusinessApi";
import BusinessDetailsApi from "./BusinessDetailsApi";
import BusinessMemberApi from "./BusinessMemberApi";
import BusinessMemberSummaryApi from "./BusinessMemberSummaryApi";
import BusinessOwnerApi from "./BusinessOwnerApi";
import CapitalAccountApi from "./CapitalAccountApi";
import CapitalDrawdownApprovalApi from "./CapitalDrawdownApprovalApi";
import CapitalRepaymentAmountApi from "./CapitalRepaymentAmountApi";
import CapitalRepaymentApi from "./CapitalRepaymentApi";
import CapitalTransactionLimitsApi from "./CapitalTransactionLimitsApi";
import CapitalTransactionsApi from "./CapitalTransactionsApi";
import CardApi from "./CardApi";
import ChargeCardAccountApi from "./ChargeCardAccountApi";
import ChargeCardOfferApi from "./ChargeCardOfferApi";
import ChargeCardRepaymentApi from "./ChargeCardRepaymentApi";
import ChatConfigApi from "./chat/ChatConfigApi";
import ConversationApi from "./chat/ConversationApi";
import ExchangeApi from "./chat/ExchangeApi";
import KnowledgeBaseItemApi from "./chat/KnowledgeBaseItemApi";
import CounterpartyAliasManagementApi from "./CounterpartyAliasManagementApi";
import CounterpartyReportApi from "./CounterpartyReportApi";
import CreditApplicationApi from "./CreditApplicationApi";
import CreditComparisonApi from "./CreditComparisonApi";
import DailyPlReportApi from "./DailyPlReportApi";
import DailyShopifySummaryApi from "./DailyShopifySummaryApi";
import DocumentsApi from "./DocumentsApi";
import DocumentV2ActionApi from "./DocumentV2ActionApi";
import DocumentV2Api from "./DocumentV2Api";
import GeneralPaymentMetadataApi from "./GeneralPaymentMetadataApi";
import HighbeamBaseApi from "./HighbeamBaseApi";
import CategorizationRuleApi from "./Insights/CategorizationRuleApi";
import CategorizedResourceApi from "./Insights/CategorizedResourceApi";
import SubcategoryApi from "./Insights/SubcategoryApi";
import InsightsCategoryApi from "./InsightsCategoryApi";
import InsightsSheetApi from "./InsightsSheetApi";
import InsightsSyncApi from "./InsightsSyncApi";
import InstitutionApi from "./InstitutionApi";
import IntercomApi from "./IntercomApi";
import InternationalBankAccountApi from "./InternationalBankAccountApi";
import IsolatedDataViewActionApi from "./isolatedDataView/IsolatedDataViewActionApi";
import LeadApi from "./LeadApi";
import LineOfCreditAgreementApi from "./LineOfCreditAgreementApi";
import LineOfCreditApi from "./LineOfCreditApi";
import LineOfCreditBalanceRecoveryApi from "./LineOfCreditBalanceRecoveryApi";
import LineOfCreditInterestFeeApi from "./LineOfCreditInterestFeeApi";
import LineOfCreditTransactionsApi from "./LineOfCreditTransactionsApi";
import PayeeApi from "./PayeeApi";
import PayeeValidatorApi from "./PayeeValidatorApi";
import PaymentActionApi from "./PaymentActionApi";
import PaymentApi from "./PaymentApi";
import PaymentApiV2 from "./PaymentApiV2";
import InternationalPaymentDetailsApi from "./PaymentDetailsApi";
import PaymentSwitcherAutoPaymentApi from "./PaymentSwitcherAutoPaymentApi";
import PlaidApi from "./PlaidApi";
import ReferralLinkApi from "./ReferralLinkApi";
import RutterBalanceApi from "./RutterBalanceApi";
import RutterConnectionApi from "./RutterConnectionApi";
import RutterPayoutApi from "./RutterPayoutApi";
import ShopifyBalanceApi from "./ShopifyBalanceApi";
import ShopifyConnectionApi from "./ShopifyConnectionApi";
import ShopifyPayoutApi from "./ShopifyPayoutApi";
import TransactionApi from "./TransactionApi";
import TransactionCategorizationMatcherApi from "./TransactionCategorizationMatcherApi";
import UnitCoCustomerTokenApi from "./UnitCoCustomerTokenApi";
import UserApi from "./UserApi";
import UserExistenceApi from "./UserExistenceApi";
import UserInvitationActionApi from "./UserInvitationActionApi";
import UserInvitationApi from "./UserInvitationApi";
import UserNotificationSettingsApi from "./UserNotificationSettingsApi";
import UserRoleApi from "./UserRoleApi";
import UserRoleMembershipApi from "./UserRoleMembershipApi";

/**
 * Enables interaction with the Highbeam backend. In order to avoid an excessively large file, the API
 * interaction is broken down into delegate APIs that all use a common shared base.
 */
export default class HighbeamApi {
  readonly accountingAccount: AccountingAccountApi;
  readonly actionItem: ActionItemApi;
  readonly apEmailAlias: ApEmailAliasApi;
  readonly apSettings: ApSettingsApi;
  readonly archiveBusiness: ArchiveBusinessApi;
  readonly assignedBillSummary: AssignedBillSummaryApi;
  readonly authorizedUser: AuthorizedUserApi;
  readonly bankAccount: BankAccountApi;
  readonly bankApplication: BankApplicationApi;
  readonly billAction: BillActionApi;
  readonly billApproval: BillApprovalApi;
  readonly billApprovalAction: BillApprovalActionApi;
  readonly billDocument: BillDocumentApi;
  readonly billDocumentAction: BillDocumentActionApi;
  readonly billEmail: BillEmailApi;
  readonly billPayment: BillPaymentApi;
  readonly billPayee: BillPayeeApi;
  readonly business: BusinessApi;
  readonly businessAddress: BusinessAddressApi;
  readonly businessDetails: BusinessDetailsApi;
  readonly businessMember: BusinessMemberApi;
  readonly businessMemberSummary: BusinessMemberSummaryApi;
  readonly businessOwner: BusinessOwnerApi;
  readonly capitalAccount: CapitalAccountApi;
  readonly capitalDrawdownApproval: CapitalDrawdownApprovalApi;
  readonly capitalRepayment: CapitalRepaymentApi;
  readonly capitalRepaymentAmount: CapitalRepaymentAmountApi;
  readonly capitalTransactionLimits: CapitalTransactionLimitsApi;
  readonly capitalTransactions: CapitalTransactionsApi;
  readonly card: CardApi;
  readonly categorizationRule: CategorizationRuleApi;
  readonly categorizedResource: CategorizedResourceApi;
  readonly chargeCardAccount: ChargeCardAccountApi;
  readonly chargeCardOffer: ChargeCardOfferApi;
  readonly chargeCardRepayment: ChargeCardRepaymentApi;
  readonly chatConfig: ChatConfigApi;
  readonly conversation: ConversationApi;
  readonly counterpartyAliasManagement: CounterpartyAliasManagementApi;
  readonly counterpartyReport: CounterpartyReportApi;
  readonly creditApplication: CreditApplicationApi;
  readonly creditComparison: CreditComparisonApi;
  readonly dailyPlReport: DailyPlReportApi;
  readonly dailyShopifySummary: DailyShopifySummaryApi;
  readonly documentV2: DocumentV2Api;
  readonly documentV2Action: DocumentV2ActionApi;
  readonly documents: DocumentsApi;
  readonly exchange: ExchangeApi;
  readonly generalPaymentMetadata: GeneralPaymentMetadataApi;
  readonly insightsCategory: InsightsCategoryApi;
  readonly insightsSheet: InsightsSheetApi;
  readonly insightsSync: InsightsSyncApi;
  readonly institution: InstitutionApi;
  readonly intercom: IntercomApi;
  readonly internationalBankAccount: InternationalBankAccountApi;
  readonly internationalPaymentDetails: InternationalPaymentDetailsApi;
  readonly isolatedDataViewAction: IsolatedDataViewActionApi;
  readonly knowledgeBaseItem: KnowledgeBaseItemApi;
  readonly lead: LeadApi;
  readonly lineOfCredit: LineOfCreditApi;
  readonly lineOfCreditBalanceRecovery: LineOfCreditBalanceRecoveryApi;
  readonly lineOfCreditAgreement: LineOfCreditAgreementApi;
  readonly lineOfCreditInterestFee: LineOfCreditInterestFeeApi;
  readonly lineOfCreditTransactions: LineOfCreditTransactionsApi;
  readonly payee: PayeeApi;
  readonly payeeValidator: PayeeValidatorApi;
  readonly payment: PaymentApi;
  readonly paymentAction: PaymentActionApi;
  readonly paymentSwitcherAutoPayment: PaymentSwitcherAutoPaymentApi;
  readonly paymentV2: PaymentApiV2;
  readonly plaid: PlaidApi;
  readonly referralLink: ReferralLinkApi;
  readonly rutterBalance: RutterBalanceApi;
  readonly rutterConnection: RutterConnectionApi;
  readonly rutterPayout: RutterPayoutApi;
  readonly shopifyBalance: ShopifyBalanceApi;
  readonly shopifyConnection: ShopifyConnectionApi;
  readonly shopifyPayouts: ShopifyPayoutApi;
  readonly subcategories: SubcategoryApi;
  readonly transaction: TransactionApi;
  readonly transactionCategorizationMatcher: TransactionCategorizationMatcherApi;
  readonly unitCoCustomerToken: UnitCoCustomerTokenApi;
  readonly user: UserApi;
  readonly userExistence: UserExistenceApi;
  readonly userInvitationAction: UserInvitationActionApi;
  readonly userInvitation: UserInvitationApi;
  readonly userNotificationSettings: UserNotificationSettingsApi;
  readonly userRole: UserRoleApi;
  readonly userRoleMembership: UserRoleMembershipApi;

  constructor(getJwt: () => Promise<string | undefined>) {
    const v1Api = new HighbeamBaseApi(env.HIGHBEAM_API_ORIGIN_V1, getJwt);
    const v2Api = new HighbeamBaseApi(env.HIGHBEAM_API_ORIGIN_V2, getJwt);

    // TODO: Generate the following from backend code
    this.accountingAccount = new AccountingAccountApi(v2Api);
    this.actionItem = new ActionItemApi(v1Api);
    this.apEmailAlias = new ApEmailAliasApi(v2Api);
    this.apSettings = new ApSettingsApi(v2Api);
    this.archiveBusiness = new ArchiveBusinessApi(v2Api);
    this.assignedBillSummary = new AssignedBillSummaryApi(v2Api);
    this.authorizedUser = new AuthorizedUserApi(v1Api);
    this.bankAccount = new BankAccountApi(v1Api);
    this.bankApplication = new BankApplicationApi(v1Api);
    this.billAction = new BillActionApi(v2Api);
    this.billApproval = new BillApprovalApi(v2Api);
    this.billApprovalAction = new BillApprovalActionApi(v2Api);
    this.billDocument = new BillDocumentApi(v2Api);
    this.billDocumentAction = new BillDocumentActionApi(v2Api);
    this.billEmail = new BillEmailApi(v2Api);
    this.billPayment = new BillPaymentApi(v2Api);
    this.billPayee = new BillPayeeApi(v2Api);
    this.business = new BusinessApi(v1Api);
    this.businessAddress = new BusinessAddressApi(v1Api);
    this.businessDetails = new BusinessDetailsApi(v1Api);
    this.businessMember = new BusinessMemberApi(v1Api);
    this.businessMemberSummary = new BusinessMemberSummaryApi(v1Api);
    this.businessOwner = new BusinessOwnerApi(v1Api);
    this.capitalAccount = new CapitalAccountApi(v1Api);
    this.capitalDrawdownApproval = new CapitalDrawdownApprovalApi(v1Api);
    this.capitalRepayment = new CapitalRepaymentApi(v1Api);
    this.capitalRepaymentAmount = new CapitalRepaymentAmountApi(v1Api);
    this.capitalTransactionLimits = new CapitalTransactionLimitsApi(v1Api);
    this.capitalTransactions = new CapitalTransactionsApi(v1Api);
    this.card = new CardApi(v1Api);
    this.categorizationRule = new CategorizationRuleApi(v1Api);
    this.categorizedResource = new CategorizedResourceApi(v1Api);
    this.chargeCardAccount = new ChargeCardAccountApi(v1Api);
    this.chargeCardOffer = new ChargeCardOfferApi(v1Api);
    this.chargeCardRepayment = new ChargeCardRepaymentApi(v1Api);
    this.chatConfig = new ChatConfigApi(v2Api);
    this.conversation = new ConversationApi(v2Api);
    this.counterpartyAliasManagement = new CounterpartyAliasManagementApi(v2Api);
    this.counterpartyReport = new CounterpartyReportApi(v2Api);
    this.creditApplication = new CreditApplicationApi(v1Api);
    this.creditComparison = new CreditComparisonApi(v1Api);
    this.dailyPlReport = new DailyPlReportApi(v2Api);
    this.dailyShopifySummary = new DailyShopifySummaryApi(v1Api);
    this.documentV2 = new DocumentV2Api(v2Api);
    this.documentV2Action = new DocumentV2ActionApi(v2Api);
    this.documents = new DocumentsApi(v1Api);
    this.exchange = new ExchangeApi(v2Api);
    this.generalPaymentMetadata = new GeneralPaymentMetadataApi(v1Api);
    this.insightsCategory = new InsightsCategoryApi(v2Api);
    this.insightsSheet = new InsightsSheetApi(v2Api);
    this.insightsSync = new InsightsSyncApi(v2Api);
    this.institution = new InstitutionApi(v1Api);
    this.intercom = new IntercomApi(v1Api);
    this.internationalBankAccount = new InternationalBankAccountApi(v1Api);
    this.internationalPaymentDetails = new InternationalPaymentDetailsApi(v1Api);
    this.isolatedDataViewAction = new IsolatedDataViewActionApi(v2Api);
    this.knowledgeBaseItem = new KnowledgeBaseItemApi(v2Api);
    this.lead = new LeadApi(v1Api);
    this.lineOfCredit = new LineOfCreditApi(v1Api);
    this.lineOfCreditBalanceRecovery = new LineOfCreditBalanceRecoveryApi(v1Api);
    this.lineOfCreditAgreement = new LineOfCreditAgreementApi(v1Api);
    this.lineOfCreditInterestFee = new LineOfCreditInterestFeeApi(v1Api);
    this.lineOfCreditTransactions = new LineOfCreditTransactionsApi(v1Api);
    this.payee = new PayeeApi(v1Api);
    this.payeeValidator = new PayeeValidatorApi(v1Api);
    this.payment = new PaymentApi(v1Api);
    this.paymentAction = new PaymentActionApi(v1Api);
    this.paymentSwitcherAutoPayment = new PaymentSwitcherAutoPaymentApi(v2Api);
    this.paymentV2 = new PaymentApiV2(v1Api);
    this.plaid = new PlaidApi(v1Api);
    this.referralLink = new ReferralLinkApi(v1Api);
    this.rutterBalance = new RutterBalanceApi(v1Api);
    this.rutterConnection = new RutterConnectionApi(v1Api);
    this.rutterPayout = new RutterPayoutApi(v1Api);
    this.shopifyBalance = new ShopifyBalanceApi(v1Api);
    this.shopifyConnection = new ShopifyConnectionApi(v1Api);
    this.shopifyPayouts = new ShopifyPayoutApi(v1Api);
    this.subcategories = new SubcategoryApi(v1Api);
    this.transaction = new TransactionApi(v1Api);
    this.transactionCategorizationMatcher = new TransactionCategorizationMatcherApi(v2Api);
    this.unitCoCustomerToken = new UnitCoCustomerTokenApi(v1Api);
    this.user = new UserApi(v1Api);
    this.userExistence = new UserExistenceApi(v1Api);
    this.userInvitationAction = new UserInvitationActionApi(v1Api);
    this.userInvitation = new UserInvitationApi(v1Api);
    this.userNotificationSettings = new UserNotificationSettingsApi(v1Api);
    this.userRole = new UserRoleApi(v1Api);
    this.userRoleMembership = new UserRoleMembershipApi(v1Api);
  }
}
