import { Card } from "@highbeam/unit-node-sdk";
import EditCardModal from "modules/cards/dialogs/EditCardModal";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import { Paragraph } from "ui/typography";
import useIsAllowedToPerformCardActions from "utils/permissions/useIsAllowedToPerformCardActions";

type Props = {
  card: Card;
};

const SetSpendLimitButton = ({ card }: Props) => {
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const isAllowedToPerformCardActions = useIsAllowedToPerformCardActions();
  const { mfa } = useMfa();

  return (
    <>
      {showEditCardModal && (
        <EditCardModal cardId={card.id} onClose={() => setShowEditCardModal(false)} />
      )}

      <ButtonWithTooltip
        variant="tertiary"
        size="xs"
        onClick={() => mfa().then(() => setShowEditCardModal(true))}
        disabled={!isAllowedToPerformCardActions}
        tooltip={
          !isAllowedToPerformCardActions && (
            <Paragraph>You don’t have permission to edit the card budget.</Paragraph>
          )
        }
      >
        Set spend limit
      </ButtonWithTooltip>
    </>
  );
};

export default SetSpendLimitButton;
