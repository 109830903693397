import emptyImg from "assets/empty-state.svg";
import assignedBillsQueryHooks from "modules/bills/queries/assignedBillsQueryHooks";
import { FC, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AssignedBillSummaryRep from "reps/AssignedBillSummaryRep";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import EmptyState from "ui/table/EmptyState";
import Table, { Column } from "ui/table/Table";

import {
  assignedBillRequestedColumn,
  assignedBillInvoiceNumberColumn,
  assignedBillPayeeColumn,
  assignedBillDueColumn,
  assignedBillAmountColumn,
} from "../AssignedRequestedBillsView";

const columns: Column<AssignedBillSummaryRep.Complete>[] = [
  assignedBillRequestedColumn,
  assignedBillInvoiceNumberColumn,
  assignedBillPayeeColumn,
  assignedBillDueColumn,
  assignedBillAmountColumn,
];

const AssignedGrantedBillsView: FC = () => {
  const { data: assignedGrantedBills, isPending } = assignedBillsQueryHooks.useQuery({
    approvalStatus: "Granted",
  });
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <div>
      <Suspense fallback={<Table.Loading columns={columns} isLoadingRows={2} />}>
        {!isPending && assignedGrantedBills && !assignedGrantedBills.length ? (
          <EmptyState image={emptyImg} primaryText="Bills you've approved will show here." />
        ) : (
          <Table
            isLoading={isPending}
            rowKey={(assignedBill) => assignedBill.billId}
            onRowClick={({ billId }) =>
              navigate(`/bills/${billId}${search}`, { state: DISABLE_SCROLL_TO_TOP_STATE })
            }
            data={assignedGrantedBills}
            columns={columns}
          />
        )}
      </Suspense>
    </div>
  );
};

export default AssignedGrantedBillsView;
