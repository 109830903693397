import { Bank } from "@phosphor-icons/react";
import { FC } from "react";
import BankAccountRep from "reps/BankAccountRep";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Span } from "ui/typography";
import { pluralize } from "utils/string";
import cn from "utils/tailwind/cn";

type Props = {
  openBankAccounts: BankAccountRep.Complete[];
  plaidBankAccounts: PlaidBankAccountRep.Complete[];
  className?: string;
};

const BackupAutoPayAccountsHelper: FC<Props> = ({
  openBankAccounts,
  plaidBankAccounts,
  className,
}) => {
  return (
    <div className={cn("flex items-center gap-x-2", className)}>
      <Bank size={14} />
      <Span className="text-sm font-medium text-grey-800">
        {openBankAccounts.length + plaidBankAccounts.length} backup auto-pay accounts.
      </Span>
      <IconWithTooltip
        tooltip={
          <>
            Your {pluralize(openBankAccounts.length, "Highbeam account")}{" "}
            {plaidBankAccounts.length > 0 && (
              <>and {pluralize(plaidBankAccounts.length, "connected account")}</>
            )}{" "}
            are used as backup auto-pay accounts.
          </>
        }
      />
    </div>
  );
};

export default BackupAutoPayAccountsHelper;
