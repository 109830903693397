import cardsQueryHooks from "modules/cards/queries/cardsQueryHooks";
import { useNavigate } from "react-router-dom";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = {
  cardId: string;
};

const useArchiveCardMutation = (additionalOptions?: MutationAdditionalOptions<void, Variables>) => {
  const highbeamApi = useHighbeamApi();
  const refreshListCardsQueries = cardsQueryHooks.useRefreshQueries();
  const navigate = useNavigate();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ cardId }: Variables) => {
        await highbeamApi.card.close(cardId);
      },
      onSuccess: async () => {
        await refreshListCardsQueries();
        navigate("/cards");
        notify("success", "Card archived");
      },
      onError: () => {
        notify("warning", "There was an issue closing the card. Please try again.");
      },
    },
    additionalOptions ?? {}
  );
};

export default useArchiveCardMutation;
