import DashboardPage from "layouts/DashboardPage";
import { getCardName } from "modules/cards/utils";
import { checkIsDebitCard } from "modules/cards/utils/typeguards";
import useIsAllowedToPerformCardActions from "utils/permissions/useIsAllowedToPerformCardActions";
import useIsAllowedToSetCardPin from "utils/permissions/useIsAllowedToSetCardPin";

import CardDetailsHeaderMenu from "../components/CardDetailsHeaderMenu";
import CardDetailsLockCardSwitch from "../components/CardDetailsLockCardSwitch";
import CardDetailsSetPinButton from "../components/CardDetailsSetPinButton";
import { useCardDetailsPageContext } from "../providers/CardDetailsPageProvider";

import styles from "./CardDetailsHeader.module.scss";

const CardDetailsHeader = () => {
  const { card } = useCardDetailsPageContext();
  const cardName = getCardName(card);
  const isCardArchived = card.attributes.status === "ClosedByCustomer";

  const isAllowedToPerformCardActions = useIsAllowedToPerformCardActions();
  const isAllowedToSetCardPin = useIsAllowedToSetCardPin(card);

  return (
    <DashboardPage.Header
      actions={
        !isCardArchived && (
          <div className={styles.actionsContainer}>
            {isAllowedToPerformCardActions && (
              <div className={styles.switch}>
                <span className={styles.switchLabel}>Lock card</span>
                <CardDetailsLockCardSwitch />
              </div>
            )}

            {isAllowedToSetCardPin && checkIsDebitCard(card) && <CardDetailsSetPinButton />}

            {isAllowedToPerformCardActions && <CardDetailsHeaderMenu />}
          </div>
        )
      }
    >
      <DashboardPage.Header.BackCaret to="/cards" />
      <DashboardPage.Header.Title>{cardName}</DashboardPage.Header.Title>
    </DashboardPage.Header>
  );
};

export default CardDetailsHeader;
