import { Dayjs } from "dayjs";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const COUNTERPARTY_REPORT_QUERY_KEY = "counterparty-report";

type Params = {
  monthRangeStart: Dayjs;
  monthRangeEndInclusive: Dayjs;
};

const useCounterpartyReportQueryOptions = ({ monthRangeStart, monthRangeEndInclusive }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [
      COUNTERPARTY_REPORT_QUERY_KEY,
      businessGuid,
      monthRangeStart.format("YYYY-MM"),
      monthRangeEndInclusive.format("YYYY-MM"),
    ],
    queryFn: async () => {
      return highbeamApi.counterpartyReport.search(
        businessGuid,
        monthRangeStart,
        monthRangeEndInclusive
      );
    },
  });
};

export default useCounterpartyReportQueryOptions;
