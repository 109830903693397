import { CheckCircle, LinkBreak } from "@phosphor-icons/react";
import { FC } from "react";
import Pill from "ui/data-display/Pill";

type Props = {
  isActive: boolean;
};

const PlaidConnectionHealthIndicator: FC<Props> = ({ isActive }) => {
  if (isActive) {
    return (
      <Pill
        color="green-100"
        size="md"
        className="font-regular"
        iconLeft={({ sizeClassName }) => <CheckCircle className={sizeClassName} weight="light" />}
      >
        Connected
      </Pill>
    );
  } else {
    return (
      <Pill
        color="yellow-200"
        size="md"
        className="font-regular"
        iconLeft={({ sizeClassName }) => <LinkBreak className={sizeClassName} weight="light" />}
      >
        Disconnected
      </Pill>
    );
  }
};

export default PlaidConnectionHealthIndicator;
