import { useCallback } from "react";
import { useEventBusDispatcher } from "utils/event-bus";

export const BILL_SYNC_RUN_REQUESTED_EVENT = "billSyncRunRequested";

export type BillSyncRunRequestedEvent = CustomEvent<{
  billId: string;
}>;

const makeBillSyncRunRequestedEvent = (billId: string): BillSyncRunRequestedEvent =>
  new CustomEvent(BILL_SYNC_RUN_REQUESTED_EVENT, {
    detail: { billId },
  });

const useBillSyncRunDispatcher = (billId: string) => {
  const dispatch = useEventBusDispatcher<BillSyncRunRequestedEvent>();

  return useCallback(() => dispatch(makeBillSyncRunRequestedEvent(billId)), [billId, dispatch]);
};

export default useBillSyncRunDispatcher;
