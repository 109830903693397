import React, { ReactNode } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";

type Props = {
  label: string;
  value: ReactNode;
  tooltip?: ReactNode;
};

const ExchangeProfileDatum: React.FC<Props> = ({ label, value, tooltip }) => {
  return (
    <ItemWithTooltip tooltip={tooltip}>
      <Paragraph className="text-xs">
        <span className="font-bold">{label}</span>
        <br />
        {value}
      </Paragraph>
    </ItemWithTooltip>
  );
};

export default ExchangeProfileDatum;
