import { ArrowFatLinesUp } from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  className?: string;
};

const LineOfCreditRepayButtonLink: FC<Props> = ({ className, capitalAccountSummary }) => {
  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();
  const lineUsed = 0 - capitalAccountSummary.runningBalance;

  return (
    <ButtonLinkWithTooltip
      to={`/capital/${capitalAccountSummary.guid}/repay`}
      fullWidth
      className={className}
      variant="secondary"
      disabled={
        !isAllowedToRepayCapital ||
        // TODO(alex): Use `isAbleToEarlyPay` once the feature flags are cleaned up. It's confusing to do this now because there are conflicting feature flags between charge cards and separating repayment flows.
        capitalAccountSummary.state !== CapitalAccountRep.State.Active ||
        lineUsed <= 0
      }
      tooltip={!isAllowedToRepayCapital && "You don’t have permission to repay."}
    >
      <ArrowFatLinesUp size={20} weight="light" />
      Repay
    </ButtonLinkWithTooltip>
  );
};

export default LineOfCreditRepayButtonLink;
