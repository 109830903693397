import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import GeneralPaymentMetadataRep from "reps/GeneralPaymentMetadataRep";
import { Simplify } from "type-fest";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { PAYMENT_METADATA_QUERY_KEY } from "../queries/usePaymentMetadataQueryOptions";

type UpdateVariables = GeneralPaymentMetadataRep.Update & {
  paymentMetadataGuid: string;
};

type CreateVariables = GeneralPaymentMetadataRep.Creation & {
  unitCoDepositAccountId?: string;
  transactionId?: string;
};

type Variables = Simplify<UpdateVariables | CreateVariables>;

const useUpdateOrCreatePaymentMetadataMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  // NB(alex): It'd be nice if we could specify the resulting `data.guid` to refresh, but this is fine for now.
  const refreshPaymentMetadataQueries = useRefreshQuery([PAYMENT_METADATA_QUERY_KEY]);

  return useMutation({
    mutationFn: (variables: Variables) => {
      if ("paymentMetadataGuid" in variables) {
        const { paymentMetadataGuid, ...updateBody } = variables;
        return highbeamApi.generalPaymentMetadata.update(
          businessGuid,
          paymentMetadataGuid,
          updateBody
        );
      } else {
        const { unitCoDepositAccountId, transactionId, ...createBody } = variables;
        return highbeamApi.generalPaymentMetadata.create(
          businessGuid,
          createBody,
          unitCoDepositAccountId,
          transactionId
        );
      }
    },
    onSuccess: async () => {
      await refreshPaymentMetadataQueries();
    },
    onError: () => {
      notify("error", "Something went wrong saving your notes! Please try again.");
    },
  });
};

export default useUpdateOrCreatePaymentMetadataMutation;
