import { Note } from "@phosphor-icons/react";
import { CardTransactionsTableTransaction } from "modules/card-transactions-table/queries/useCardTransactionsTableData";
import { FC } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import NotFoundCell from "ui/table/cells/NotFoundCell";
import { Paragraph } from "ui/typography";

import useCardDetailsPageCardTransactionsTablePaymentMetadata from "../../hooks/useCardDetailsPageCardTransactionsTablePaymentMetadata";

type Props = {
  transaction: CardTransactionsTableTransaction;
};

const CardTransactionsTableNotesCell: FC<Props> = ({ transaction }) => {
  const paymentMetadataItem = useCardDetailsPageCardTransactionsTablePaymentMetadata(
    transaction.attributes.tags?.generalPaymentMetadataGuid ?? undefined
  );

  if (!paymentMetadataItem || !paymentMetadataItem.notes) {
    return <NotFoundCell />;
  }

  return (
    <div>
      <ItemWithTooltip asChild tooltip={<Paragraph>{paymentMetadataItem.notes}</Paragraph>}>
        <Note size={24} />
      </ItemWithTooltip>
    </div>
  );
};

export default CardTransactionsTableNotesCell;
