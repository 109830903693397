import { DownloadSimple } from "@phosphor-icons/react";
import { useInternationalWirePaymentMetadata } from "modules/international-wire-payment-metadata/queries/useInternationalWirePaymentMetadata";
import InternationalWiresMetadataListItems from "modules/international-wires/components/InternationalWiresMetadataListItems";
import { usePayeeQuery } from "modules/payees/queries/usePayee";
import { FC } from "react";
import colors from "styles/colors";
import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import Shimmer from "ui/feedback/Shimmer";
import Button from "ui/inputs/Button";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Heading3 } from "ui/typography";
import Text from "ui/typography/Text";

import useDownloadMt103 from "./hooks/useDownloadMt103";

type Props = {
  addenda?: string;
  internationalPaymentMetadataGuid: string;
};

const InternationalWireTransactionDetailsSection: FC<Props> = ({
  addenda,
  internationalPaymentMetadataGuid,
}) => {
  const internationalPaymentMetadata = useInternationalWirePaymentMetadata({
    paymentMetadataGuid: internationalPaymentMetadataGuid,
  });
  const { data: payee } = usePayeeQuery(internationalPaymentMetadata?.payeeGuid);
  const { mutate: downloadMt103, isPending } = useDownloadMt103(internationalPaymentMetadataGuid);

  return (
    <Section>
      <Heading3>International transfer details</Heading3>
      <MetadataList>
        {addenda && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{addenda}</MetadataList.ItemValue>
          </MetadataList.Item>
        )}
        {internationalPaymentMetadata?.paymentType === "Priority" && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>
              MT103 Form
              <IconWithTooltip
                color="light"
                tooltip="This document is a proof of payment generated for the SWIFT network when the payment is sent. You can share it with the recipient for them to locate the payment at their bank."
              />
            </MetadataList.ItemLabel>
            <MetadataList.ItemValue className="flex flex-col items-end">
              {internationalPaymentMetadata?.paymentStatus === "completed" && (
                <Button
                  variant="tertiary"
                  size="xs"
                  isLoading={isPending}
                  onClick={() => {
                    downloadMt103();
                  }}
                >
                  <DownloadSimple size={14} />
                  <Text>Download</Text>
                </Button>
              )}
              {internationalPaymentMetadata?.paymentStatus !== "completed" && (
                <Text size={14} weight="medium" color={colors.grey[600]}>
                  Available after payment sends
                </Text>
              )}
            </MetadataList.ItemValue>
          </MetadataList.Item>
        )}
        {/* Either IBAN or Account number is available */}

        {payee ? (
          <InternationalWiresMetadataListItems
            // NB(alex): `description` is intentionally excluded to be rendered above the m103 form item
            bicSwift={payee.internationalWireTransferMethod?.swift}
            iban={payee.internationalWireTransferMethod?.iban}
            accountNumber={payee.internationalWireTransferMethod?.accountNumber}
            bankCode={payee.internationalWireTransferMethod?.bankCode}
            branchCode={payee.internationalWireTransferMethod?.branchCode}
            bsbCode={payee.internationalWireTransferMethod?.bsbCode}
            clabe={payee.internationalWireTransferMethod?.clabe}
            cnaps={payee.internationalWireTransferMethod?.cnaps}
            ifsc={payee.internationalWireTransferMethod?.ifsc}
            sortCode={payee.internationalWireTransferMethod?.sortCode}
            payeeType={payee.internationalWireTransferMethod?.entity.entityType}
            address={payee.internationalWireTransferMethod?.address}
          />
        ) : (
          <Shimmer />
        )}
      </MetadataList>
    </Section>
  );
};

export default InternationalWireTransactionDetailsSection;
