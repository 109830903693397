import { CalendarCheck, Coin, CurrencyCircleDollar } from "@phosphor-icons/react";
import { ElementRef, FC, forwardRef, ReactNode } from "react";
import CardRep from "reps/CardRep";
import RadioCard, { RadioCardProps } from "ui/inputs/RadioCard";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";

import CardAvatar from "../CardAvatar";

const classes = {
  descriptionContainer: "flex flex-col gap-y-2 py-1",
  descriptionText: "text-sm text-grey-600",
  descriptionHighlightsContainer:
    "flex flex-col gap-x-8 gap-y-1 tablet:flex-row tablet:items-center flex-wrap",
  descriptionHighlightItem: "flex gap-x-1 text-grey-600",
  descriptionHighlightItemText: "text-xs font-medium leading-6 text-inherit",
  descriptionIcon: "shrink-0 mt-1", // Vertically aligns icon relative to first line of text.
};

type DescriptionHighlightItemProps = {
  checked: boolean;
  children: ReactNode;
};

const DescriptionHighlightItem: FC<DescriptionHighlightItemProps> = ({ children, checked }) => {
  return (
    <div className={cn("flex gap-x-1 text-grey-600", checked && "text-purple-500")}>{children}</div>
  );
};

type CardTypeRadioCardCreditDescriptionProps = {
  checked: boolean;
  chargeCardMaxCashback: number;
  chargeCardMinCashback: number;
  maxRepaymentDays: number;
};

const CardTypeRadioCardCreditDescription: FC<CardTypeRadioCardCreditDescriptionProps> = ({
  checked,
  chargeCardMaxCashback,
  chargeCardMinCashback,
  maxRepaymentDays,
}) => {
  return (
    <div className={classes.descriptionContainer}>
      <Span className={classes.descriptionText}>Spend via a capital account.</Span>
      <div className={classes.descriptionHighlightsContainer}>
        {Boolean(chargeCardMaxCashback) && (
          <DescriptionHighlightItem checked={checked}>
            <CurrencyCircleDollar size={16} className={classes.descriptionIcon} />
            <Span className={classes.descriptionHighlightItemText}>
              {chargeCardMaxCashback}% cash back on ads
            </Span>
          </DescriptionHighlightItem>
        )}
        {Boolean(chargeCardMinCashback) && (
          <DescriptionHighlightItem checked={checked}>
            <CurrencyCircleDollar size={16} className={classes.descriptionIcon} />
            <Span className={classes.descriptionHighlightItemText}>
              {chargeCardMinCashback}% cash back on everything else
            </Span>
          </DescriptionHighlightItem>
        )}
        <DescriptionHighlightItem checked={checked}>
          <CalendarCheck size={16} className={classes.descriptionIcon} />
          <Span className={classes.descriptionHighlightItemText}>
            Up to {maxRepaymentDays} day repayment
          </Span>
        </DescriptionHighlightItem>
      </div>
    </div>
  );
};

type CardTypeRadioCardDebitDescriptionProps = {
  checked: boolean;
};

const CardTypeRadioCardDebitDescription: FC<CardTypeRadioCardDebitDescriptionProps> = ({
  checked,
}) => {
  return (
    <div className={classes.descriptionContainer}>
      <Span className={classes.descriptionText}>Spend via a bank account.</Span>
      <div className={classes.descriptionHighlightsContainer}>
        <DescriptionHighlightItem checked={checked}>
          <CurrencyCircleDollar size={16} className={classes.descriptionIcon} />
          <Span className={classes.descriptionHighlightItemText}>2% cash back on ads</Span>
        </DescriptionHighlightItem>

        <DescriptionHighlightItem checked={checked}>
          <Coin size={16} className={classes.descriptionIcon} />
          <Span className={classes.descriptionHighlightItemText}>
            1% cash back on everything else
          </Span>
        </DescriptionHighlightItem>
      </div>
    </div>
  );
};

type Props = Omit<RadioCardProps<string>, "icon"> & {
  cardType: CardRep.CardType;
};

const CardTypeRadioCard = forwardRef<ElementRef<typeof RadioCard>, Props>(
  ({ checked, description, cardType, ...radioCardProps }, ref) => {
    return (
      <RadioCard
        ref={ref}
        className="items-start"
        checked={checked}
        icon={
          <div className="h-20 px-2 py-1">
            <CardAvatar cardType={cardType} />
          </div>
        }
        description={description}
        tintBackgroundWhenChecked
        {...radioCardProps}
      />
    );
  }
);

export default Object.assign(CardTypeRadioCard, {
  CreditDescription: CardTypeRadioCardCreditDescription,
  DebitDescription: CardTypeRadioCardDebitDescription,
});
