import { useRefreshBillLineItemsQuery } from "modules/bill-line-items/queries/useBillLineItems";
import useBillSyncRunDispatcher from "modules/bill-syncs/hooks/useBillSyncRunDispatcher";
import { useRefreshBillQuery } from "modules/bills/queries/useBill";
import { useRefreshBillAuditLogsQuery } from "modules/bills/queries/useBillAuditLogs";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BillLineItemRep from "reps/BillLineItemRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillLineItemApi from "../api/useBillLineItemApi";

type Variables = Omit<BillLineItemRep.Creator, "billId" | "businessGuid">;

const useCreateBillLineItemMutation = (
  billId: string,
  additionalOptions?: MutationAdditionalOptions<BillLineItemRep.Complete, Variables>
) => {
  const billLineItemApi = useBillLineItemApi();
  const businessGuid = useBusinessGuid();

  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillAuditLogsQuery = useRefreshBillAuditLogsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        return billLineItemApi.create({
          billId,
          businessGuid,
          ...variables,
        });
      },

      onSuccess: async () => {
        dispatchBillSync();

        await Promise.all([
          refreshBillQuery(),
          refreshBillAuditLogsQuery(),
          refreshBillLineItemsQuery(),
        ]);
      },
    },
    additionalOptions || {}
  );
};

export default useCreateBillLineItemMutation;
