import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const AiChatTableDataCell: React.FC<Props> = ({ children }) => {
  return <td className="px-2 py-4">{children}</td>;
};

export default AiChatTableDataCell;
