import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useIntercomMetadataQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const userGuid = useUserGuid();
  const businessGuid = useBusinessGuid({ loginRequired: false }) ?? undefined;

  return useQueryOptions({
    queryKey: ["intercomMetadata", { userGuid }],
    queryFn: async () => {
      return (await highbeamApi.intercom.getMetadata(userGuid, businessGuid))!;
    },
  });
};

export default useIntercomMetadataQueryOptions;
