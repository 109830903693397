import { AchReceivedPayment } from "@highbeam/unit-node-sdk";
import ReceivedPaymentInfoFlexpane from "dialogs/ReceivedPaymentInfoFlexpane";
import { BankAccountBarByUnitCoDepositAccountId } from "modules/bank-accounts/components/BankAccountBar";
import useFailedDebitPayments from "modules/failed-debits/queries/useFailedDebitPayments";
import { FC } from "react";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import AmountCell from "ui/table/cells/AmountCell";
import CounterpartyCell from "ui/table/cells/CounterpartyCell";
import NotFoundCell from "ui/table/cells/NotFoundCell";
import TextCell from "ui/table/cells/TextCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { formatBankingDate } from "utils/date";
import { getReceivedAchPaymentCounterparty } from "utils/receivedPayments";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

import styles from "./FailedDebitsTable.module.scss";

const ReceivedPaymentsTableCounterpartyCell = ({
  receivedPayment,
}: {
  receivedPayment: AchReceivedPayment;
}) => {
  const counterparty = getReceivedAchPaymentCounterparty(receivedPayment);
  return (
    <div className={styles.toFromStatus}>
      <CounterpartyCell>{counterparty.formattedName ?? counterparty.name}</CounterpartyCell>
      <Pill className={styles.pill} bordered color="pink-100">
        Failed
      </Pill>
    </div>
  );
};

const columns: Column<AchReceivedPayment>[] = [
  {
    title: "Date",
    key: "formattedCreatedAt",
    cellRender: (receivedPayment) => {
      if ("createdAt" in receivedPayment.attributes && receivedPayment.attributes.createdAt) {
        return <TextCell>{formatBankingDate(receivedPayment.attributes.createdAt)}</TextCell>;
      } else {
        return <NotFoundCell />;
      }
    },
    width: 120,
  },
  {
    title: "From",
    cellRender: (receivedPayment) => {
      return <ReceivedPaymentsTableCounterpartyCell receivedPayment={receivedPayment} />;
    },
  },
  {
    title: "Account",
    key: "accountName",
    cellRender: (receivedPayment) => {
      return (
        <BankAccountBarByUnitCoDepositAccountId
          unitCoDepositAccountId={receivedPayment.relationships.account.data.id}
          shortMethodName={"ACH"}
        />
      );
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: (payment) => {
      return (
        <AmountCell
          cents={payment.attributes.amount}
          direction={payment.attributes.direction === "Debit" ? "negative" : "positive"}
        />
      );
    },
  },
  {
    title: "Action",
    align: TableColumnAlignment.RIGHT,
    width: 120,
    cellRender: () => {
      return (
        <div className={styles.retryButtonContainer}>
          <Button variant="tertiary" size="sm">
            Retry
          </Button>
        </div>
      );
    },
  },
];

const FailedDebitsTable: FC = () => {
  const failedDebits = useFailedDebitPayments();
  const [selectedReceivedPayment, setReceivedSelectedPayment] = useSearchParamValue("debitId");

  return (
    <>
      <ReceivedPaymentInfoFlexpane
        receivedPaymentId={
          selectedReceivedPayment && selectedReceivedPayment !== ""
            ? selectedReceivedPayment
            : undefined
        }
        onClose={() => setReceivedSelectedPayment("")}
      />
      <Table
        columns={columns}
        data={failedDebits}
        rowKey={(payment) => payment.id}
        cellClassName={styles.cell}
        onRowClick={(receivedPayment) => {
          setReceivedSelectedPayment(receivedPayment.id);
        }}
      />
    </>
  );
};

export default FailedDebitsTable;
