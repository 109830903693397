import chatDebugModeState from "modules/chat/state/chatDebugMode";
import ChatPre from "pages/accounts/ChatPage/ChatPre";
import React from "react";
import { useRecoilValue } from "recoil";
import { ExchangeExceptionMessage } from "reps/chat/MessageRep";

import DebugMessage from "./DebugMessage";
import ResponseMessageContainer from "./ResponseMessage/ResponseMessageContainer";
import ResponseMessageParagraph from "./ResponseMessage/ResponseMessageParagraph";

type Props = {
  message: ExchangeExceptionMessage | null;
};

const ExceptionMessage: React.FC<Props> = ({ message }) => {
  const chatDebugMode = useRecoilValue(chatDebugModeState);

  if (!chatDebugMode) {
    return (
      <ResponseMessageContainer>
        <ResponseMessageParagraph
          element={{
            type: "Paragraph",
            content: [
              { type: "Text", bold: false, content: "Something went wrong, please try again!" },
            ],
          }}
        />
      </ResponseMessageContainer>
    );
  }

  return (
    <DebugMessage className="text-red-600">
      <DebugMessage.Label label="Exception" />
      <DebugMessage.Content>
        <ChatPre content={message!!.stackTrace} />
      </DebugMessage.Content>
    </DebugMessage>
  );
};

export default ExceptionMessage;
