import { ApprovablePaymentRequest } from "modules/payment-requests/types/paymentRequest";

type MethodName = {
  short: string;
  long: string;
};

// TODO(alex): use `satisfies` once typescript is fixed https://highbeamco.slack.com/archives/C02M3GGQPHC/p1697581242996279
export const METHOD_NAMES: Record<string, MethodName> = {
  INTERNATIONAL_WIRE: {
    short: "Intl. wire",
    long: "International wire",
  },
  ACH: {
    short: "ACH",
    long: "ACH",
  },
  DOMESTIC_WIRE: {
    short: "Wire",
    long: "Wire",
  },
};

const getPaymentApprovalMethodName = (paymentRequest: ApprovablePaymentRequest): MethodName => {
  switch (paymentRequest.type) {
    case "international-wire":
      return METHOD_NAMES.INTERNATIONAL_WIRE;
    case "unit-payment":
      return paymentRequest.unitPayload.type === "achPayment"
        ? METHOD_NAMES.ACH
        : METHOD_NAMES.DOMESTIC_WIRE;
    default:
      throw new Error(`Unknown payment type: ${paymentRequest}`);
  }
};

export default getPaymentApprovalMethodName;
