import { FC } from "react";
import { IconRenderFunction, IconSizeRenderProps } from "utils/react-helpers/IconRenderFunction";
import { SingleRenderedElement } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type PillColor =
  | "blue-light"
  | "green-100"
  | "green-500"
  | "grey-100"
  | "grey-200"
  | "orange-100"
  | "orange-200"
  | "pink-100"
  | "pink-200"
  | "purple-100"
  | "purple-200"
  | "purple-500"
  | "red-200"
  | "yellow-100"
  | "yellow-200"
  | "yellow-500"
  | "white"
  | "custom";

const getPillColorClasses = (color: PillColor): string => {
  return variants(color, {
    "blue-light": "border-blue bg-blue-light text-blue",
    "green-100": "border-green-600 bg-green-100 text-green-600",
    "green-500": "border-green-50 bg-green-500 text-green-50",
    "grey-100": "border-grey-500 bg-grey-100 text-grey-500",
    "grey-200": "border-grey-700 bg-grey-200 text-grey-700",
    "orange-100": "border-orange-500 bg-orange-100 text-orange-500",
    "orange-200": "border-orange-700 bg-orange-200 text-orange-700",
    "pink-100": "border-pink-500 bg-pink-100 text-pink-500",
    "pink-200": "border-pink-700 bg-pink-200 text-pink-700",
    "purple-100": "border-purple-500 bg-purple-100 text-purple-500",
    "purple-200": "border-purple-700 bg-purple-200 text-purple-700",
    "purple-500": "border-purple-50 bg-purple-500 text-purple-50",
    "red-200": "border-red-700 bg-red-200 text-red-700",
    "yellow-100": "border-yellow-700 bg-yellow-100 text-yellow-700",
    "yellow-200": "border-yellow-800 bg-yellow-200 text-yellow-800",
    "yellow-500": "border-yellow-900 bg-yellow-500 text-yellow-900",
    white: "border-black bg-white text-black",
    custom: "",
  });
};

export type PillSize = "2xs" | "xs" | "md";

type Props = {
  // Any singular rendered element is allowed. This ensures people use `iconLeft`/`iconRight` props instead of passing the icon in as `children`.
  children: SingleRenderedElement;
  iconLeft?: IconRenderFunction;
  iconRight?: IconRenderFunction;
  color: PillColor;
  className?: string;
  size?: PillSize;
  bordered?: boolean;
  onClick?: () => void;
};

const Pill: FC<Props> = ({
  children,
  color,
  className,
  size = "xs",
  bordered,
  onClick,
  iconLeft,
  iconRight,
}) => {
  const iconRenderProps: IconSizeRenderProps = variants(size, {
    "2xs": { size: 12, sizeClassName: "size-3" },
    xs: { size: 16, sizeClassName: "size-4" },
    md: { size: 20, sizeClassName: "size-5" },
  });

  return (
    <span
      className={cn(
        "inline-flex h-min w-min items-center justify-center whitespace-nowrap rounded-full font-medium",
        "gap-x-1",
        variants(size, {
          "2xs": "px-2 py-1 text-2xs",
          xs: "px-2 py-1 text-xs",
          md: "px-2.5 py-1.5 text-sm",
        }),
        bordered ? "border" : "border-0",
        onClick && "cursor-pointer",
        getPillColorClasses(color),
        className
      )}
      onClick={onClick}
    >
      {/* We always want to render the `span`s because we always want the added gap between the children and spans regardless of if an icon is passed in. */}
      <span className="inline-flex">{iconLeft?.(iconRenderProps)}</span>
      <span>{children}</span>
      <span className="inline-flex">{iconRight?.(iconRenderProps)}</span>
    </span>
  );
};

export default Pill;
