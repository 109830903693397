import AccountPayments from "modules/payment-switcher/components/AccountPayments";
import usePaymentSwitcherAutoPayments from "modules/payment-switcher/queries/usePaymentSwitcherAutoPayments";
import React, { ReactNode } from "react";
import PaymentSwitcherAutoPaymentGroupRep from "reps/PaymentSwitcherAutoPaymentGroupRep";

type Props = {
  filter: (group: PaymentSwitcherAutoPaymentGroupRep) => boolean;
  switchHelper: ReactNode;
};

const AccountPaymentsView: React.FC<Props> = ({ filter, switchHelper }) => {
  const payments = usePaymentSwitcherAutoPayments(filter);

  return (
    <div className="flex flex-col items-start gap-x-12 gap-y-10 pt-2 extra-large-desktop:flex-row-reverse">
      {switchHelper}
      <div className="flex flex-shrink flex-grow flex-col gap-y-10">
        {payments.map((group) => (
          <AccountPayments key={group.transactionSource} group={group} />
        ))}
      </div>
    </div>
  );
};

export default AccountPaymentsView;
