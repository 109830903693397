import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import RutterConnectionRep from "reps/RutterConnectionRep";
import ShopifyConnectionRep from "reps/ShopifyConnectionRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import { getRutterPlatformDisplayName } from "utils/rutter";
import { isRutterCommercePlatformOrPaymentProcessor } from "utils/rutter/typeguards";

import { ConnectedStore, ConnectedStoreType } from "../types";

// NB(alex): We should consider cleaning this up and replacing `useRutterConnectionsQueryOptions` and `useShopifyConnectionsQueryOptions` with this hook.

export const CONNECTED_STORES_QUERY_KEY = "connected-stores";

const getConnectedStoreType = (
  platform: RutterConnectionRep.RutterCommercePlatform | RutterConnectionRep.RutterPaymentProcessor
): ConnectedStoreType => {
  switch (platform) {
    case "AMAZON":
      return "Amazon";
    case "SHOPIFY":
      return "Shopify";
    case "STRIPE":
      return "Stripe";
    case "PAYPAL":
      return "PayPal";
  }
};

const useConnectedStoresQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CONNECTED_STORES_QUERY_KEY, { businessGuid }],
    queryFn: async () => {
      const [shopifyConnections, rutterConnections] = await Promise.all([
        highbeamApi.shopifyConnection.getByBusiness(businessGuid),
        highbeamApi.rutterConnection.getByBusiness(businessGuid),
      ]);

      const mapShopifyConnections = (
        shopifyConnections: ShopifyConnectionRep.Complete[]
      ): ConnectedStore[] => {
        if (shopifyConnections && shopifyConnections.length > 0) {
          return shopifyConnections
            .map((shopifyConnection) => ({
              id: shopifyConnection.guid,
              type: "Shopify" as const,
              name: shopifyConnection.shopName,
              hasSynced: shopifyConnection.hasSyncedBalances && shopifyConnection.hasSyncedPayouts,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        }

        return [];
      };

      const mapRutterConnections = (
        rutterConnections: RutterConnectionRep.Complete[]
      ): ConnectedStore[] => {
        if (rutterConnections && rutterConnections.length > 0) {
          return rutterConnections
            .flatMap((rutterConnection) => {
              if (!isRutterCommercePlatformOrPaymentProcessor(rutterConnection.platformName))
                return [];

              const type = getConnectedStoreType(rutterConnection.platformName);
              return [
                {
                  id: rutterConnection.guid,
                  type,
                  name:
                    rutterConnection.shopName ||
                    getRutterPlatformDisplayName(rutterConnection.platformName),
                  hasSynced:
                    rutterConnection.hasSyncedBalances && rutterConnection.hasSyncedPayouts,
                },
              ];
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        }

        return [];
      };

      return [
        ...mapShopifyConnections(shopifyConnections),
        ...mapRutterConnections(rutterConnections),
      ];
    },
  });
};

export default useConnectedStoresQueryOptions;
