import { DEFAULT_ACTIONS_BY_MILESTONE } from "modules/action-items/config";
import { ActionItem, Milestone } from "modules/action-items/types";
import { atomFamily, selectorFamily } from "recoil";
import ActionItemRep from "reps/ActionItemRep";
import businessGuidState from "state/auth/businessGuid";
import highbeamApiState from "state/auth/highbeamApi";
import userGuidState from "state/auth/userGuid";

const localFinishedActionItemsState = atomFamily<ActionItem[], Milestone>({
  key: "actionItems/localFinishedActionItems",
  default: [],
});

export const OWNER_GUID_BY_MILESTONE = {
  [Milestone.Signup]: userGuidState,
  [Milestone.GetStarted]: businessGuidState,
  [Milestone.FundYourAccount]: businessGuidState,
};

const actionItemsState = selectorFamily<ActionItem[], Milestone>({
  key: "actionItems/finishedActionItems",

  get:
    (milestoneName) =>
    async ({ get }) => {
      const localActionItems = get(localFinishedActionItemsState(milestoneName));

      if (localActionItems.length > 0) {
        return localActionItems;
      }

      const highbeamApi = get(highbeamApiState("authenticated"));
      const ownerGuid = get(OWNER_GUID_BY_MILESTONE[milestoneName]);
      const defaultActionItems = DEFAULT_ACTIONS_BY_MILESTONE[milestoneName];

      const finishedActionItems = await highbeamApi.actionItem.getByMilestoneName(
        ownerGuid,
        milestoneName.toString()
      );

      return updateDefaultActionItem(defaultActionItems, finishedActionItems);
    },

  set:
    (milestoneName) =>
    ({ set }, newActionItems) =>
      set(localFinishedActionItemsState(milestoneName), newActionItems),
});

export function updateDefaultActionItem(
  defaultActionItems: ActionItem[],
  finishedActionItems: ActionItemRep.Complete[]
): ActionItem[] {
  return defaultActionItems.map((actionItem) => {
    const found = finishedActionItems.find(
      (finishedActionItem) => finishedActionItem.name === actionItem.name
    );

    return {
      ...actionItem,
      finishedActionItemRep: found,
    };
  });
}

export default actionItemsState;
