import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useBankApplicationQueryOptions from "../queries/useBankApplicationQueryOptions";

const useCreateBankApplicationMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshBankApplication = useRefreshQuery(useBankApplicationQueryOptions().queryKey);

  return useMutation({
    mutationFn: () => {
      return highbeamApi.bankApplication.createBankApplication(businessGuid);
    },
    onSuccess: async () => {
      await refreshBankApplication();
    },
  });
};

export default useCreateBankApplicationMutation;
