import classNames from "classnames";
import { FC, HTMLAttributes, AnchorHTMLAttributes, ElementType } from "react";
import { Link } from "react-router-dom";

import styles from "./Text.module.scss";
import { TextSize, TextWeight } from "./TextTypes";

export type TextProps = {
  size?: TextSize;
  lineHeight?: 32 | 24 | 20; // NB(alex): WIP / feel free to add more values as they come up!
  weight?: TextWeight;
  align?: "left" | "center" | "right";
  color?: string;
  numeric?: boolean; // TODO (jhudson): Audit.
  underline?: boolean;
  lineThrough?: boolean;
  italic?: boolean;
  as?: ElementType;
} & HTMLAttributes<HTMLParagraphElement>;

/**
 * @deprecated Please use components found in `ui/typography/index.tsx` instead.
 */
const Text: FC<TextProps> = ({
  className,
  size,
  lineHeight,
  weight,
  align,
  color,
  underline,
  lineThrough,
  italic,
  numeric = false,
  children,
  as: Component = "div",
  style,
  ...props
}) => (
  <Component
    className={classNames(
      styles.text,
      size && styles[`size--${size}`],
      lineHeight && styles[`lineHeight--${lineHeight}`],
      weight && styles[`weight--${weight}`],
      align && styles[`align--${align}`],
      underline && styles.underline,
      lineThrough && styles.lineThrough,
      italic && styles.italic,
      className
    )}
    style={{
      fontFeatureSettings: numeric ? "'tnum' on, 'lnum' on" : "normal",
      color: color,
      ...style,
    }}
    {...props}
  >
    {children}
  </Component>
);

type InternalTextLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string; // Require the href prop (otherwise it's optional in AnchorHTMLAttributes)
};

const InternalTextLink: FC<InternalTextLinkProps> = ({ children, className, href, ...props }) => (
  <Link className={classNames(styles.textLink, className)} to={href} {...props}>
    {children}
  </Link>
);

export default Object.assign(Text, {
  InternalLink: InternalTextLink,
});
