import useRemoveBusinessMemberMutation from "modules/business-members/mutations/useRemoveBusinessMemberMutation";
import { FC } from "react";
import Divider from "ui/data-display/Divider";
import UserAvatarBar from "ui/data-display/UserAvatarBar";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";

import { BusinessMemberUsersTableDatum } from "../queries/useUsersTableData";

type RemoveBusinessMemberModalProps = {
  businessMember: BusinessMemberUsersTableDatum;
};

const RemoveBusinessMemberModalContent: FC<RemoveBusinessMemberModalProps> = ({
  businessMember,
}) => {
  const { closeModalWithAnimation } = useModalContext();

  const { mutate: removeBusinessMember, isPending } = useRemoveBusinessMemberMutation({
    onSuccess: () => {
      closeModalWithAnimation();
    },
  });

  return (
    <>
      <ModalV4.Header>Remove user?</ModalV4.Header>

      <ModalV4.Body>
        <UserAvatarBar
          fullName={businessMember.displayName}
          initials={businessMember.initials}
          userRoleName={businessMember.userRoleName}
        />
        <Divider />
        Are you sure you want to remove {businessMember.displayName}?
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton
          variant="danger"
          onClick={() => {
            removeBusinessMember({
              businessGuid: businessMember.businessGuid,
              memberGuid: businessMember.guid,
            });
          }}
          isLoading={isPending}
        >
          Remove user
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton disabled={isPending} variant="ghost">
          Cancel
        </ModalV4.CloseButton>
      </ModalV4.Footer>
    </>
  );
};

type Props = {
  businessMember: BusinessMemberUsersTableDatum;
  onClose: () => void;
};

const RemoveBusinessMemberModal: FC<Props> = ({ businessMember, onClose }) => {
  return (
    <ModalV4 onClose={onClose}>
      <RemoveBusinessMemberModalContent businessMember={businessMember} />
    </ModalV4>
  );
};

export default RemoveBusinessMemberModal;
