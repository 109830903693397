import classNames from "classnames";
import { FC, ReactElement, ReactNode, cloneElement, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { useIsTablet } from "utils/device/useMediaQuery";

import styles from "./MenuItem.module.scss";

type MenuItemProps = {
  text: string;
  icon: ReactElement;
  children?: ReactNode;
  disabled?: boolean;
  externalLink?: boolean;
  onClick?: () => void;
  path?: string;
  badge?: ReactNode | ((renderProps: { isActive: boolean }) => ReactNode);
};

const MenuItem: FC<MenuItemProps> = ({
  text,
  icon,
  children,
  disabled = false,
  externalLink = false,
  onClick: onClose,
  path = "",
  badge,
}) => {
  const pathName = useLocation().pathname;
  const isActive = Boolean(path && pathName.startsWith(path));
  const [isHovering, setIsHovering] = useState(false);
  const isTablet = useIsTablet();

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getColor = (): string => {
    if (isActive) return colors.purple[500];
    if (isHovering) return colors.grey[900];
    return colors.grey[600];
  };

  const color = getColor();

  // handling for customicons color change
  const customIcon: ReactElement = cloneElement(icon, {
    color,
    size: 20,
  });

  return (
    <>
      <Link
        className={classNames({
          [styles.link]: true,
          [styles.linkDisabled]: disabled,
        })}
        to={path}
        target={externalLink ? "_blank" : undefined}
        rel={externalLink ? "noopener noreferrer" : undefined}
        onClick={onClose}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div
          className={classNames({
            [styles.textWrapper]: true,
            [styles.active]: isActive,
          })}
        >
          {customIcon}

          <Text size={14} className={styles.text} as="span">
            {text}
          </Text>

          {badge && (
            <span className="ml-auto">
              {typeof badge === "function" ? badge({ isActive }) : badge}
            </span>
          )}
        </div>
      </Link>
      {(isTablet || isActive) && children && <div className={styles.content}>{children}</div>}
    </>
  );
};

export default MenuItem;
