import getCapitalAccountTitle from "modules/capital-accounts/utils/getCapitalAccountTitle";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete | undefined;
  isCurrentItem: boolean;
};

const CapitalAccountBreadcrumbItem: FC<Props> = ({ capitalAccountSummary, isCurrentItem }) => {
  if (isCurrentItem) {
    return (
      <>
        {capitalAccountSummary ? (
          <Breadcrumbs.CurrentItem>
            {getCapitalAccountTitle(capitalAccountSummary)}
          </Breadcrumbs.CurrentItem>
        ) : (
          <Breadcrumbs.CurrentItemShimmer />
        )}
      </>
    );
  } else {
    return (
      <>
        {capitalAccountSummary ? (
          <Breadcrumbs.Item to={`/capital/${capitalAccountSummary.guid}`}>
            {getCapitalAccountTitle(capitalAccountSummary)}
          </Breadcrumbs.Item>
        ) : (
          <Breadcrumbs.ItemShimmer />
        )}
      </>
    );
  }
};

export default CapitalAccountBreadcrumbItem;
