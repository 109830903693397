import React from "react";
import Button from "ui/inputs/Button";

type Props = {
  disabled: boolean;
  isLoading: boolean;
};

const ChatSettingsSaveButton: React.FC<Props> = ({ disabled, isLoading }) => {
  return (
    <Button
      type="submit"
      variant="primary"
      disabled={disabled}
      isLoading={isLoading}
      className="self-start"
    >
      Save
    </Button>
  );
};

export default ChatSettingsSaveButton;
