import dayjs from "dayjs";
import React, { useMemo } from "react";
import { formatValue } from "react-currency-input-field";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { LineChartChatElement } from "reps/chat/ChatElement";
import colors from "styles/colors";

import styles from "./ResponseMessageLineChart.module.scss";

const CHART_COLORS = [
  colors.purple[500],
  colors.green[400],
  colors.orange[500],
  colors.red[500],
  colors.yellow[500],
  colors.pink[500],
];

type Props = {
  element: LineChartChatElement;
};

const ResponseMessageLineChart: React.FC<Props> = ({ element }) => {
  const seriesNames = element.series.map((series) => series.name);

  const data = useMemo(() => {
    return element.xAxis.map((x, i) => ({
      label: dayjs(x).valueOf(),
      series: Object.fromEntries(
        element.series.map((series) => [series.name, series.values[i] ?? 0])
      ),
    }));
  }, [element.series, element.xAxis]);

  const xAxisFormatter = createXAxisFormatter();
  const yAxisFormatter = createYAxisFormatter(element.yAxisType);

  return (
    <ResponsiveContainer height={256}>
      <LineChart data={data} className={styles.lineChart}>
        <CartesianGrid stroke={colors.grey[200]} strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          domain={[data.at(0)!!.label, data.at(-1)!!.label]}
          scale="time"
          type="number"
          tick={{ fontSize: 12, fill: colors.grey[900] }}
          tickFormatter={xAxisFormatter}
          tickMargin={8}
          stroke={colors.grey[500]}
        />
        <YAxis
          tick={{ fontSize: 12, fill: colors.grey[900] }}
          tickFormatter={yAxisFormatter}
          tickMargin={4}
          stroke={colors.grey[500]}
        />
        <Legend wrapperStyle={{ fontSize: 12 }} />
        {seriesNames.map((label, i) => (
          <Line
            key={label}
            dataKey={(obj) => obj.series[label]}
            name={label}
            stroke={CHART_COLORS[i % CHART_COLORS.length]}
            type="monotone"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ResponseMessageLineChart;

const createXAxisFormatter = (): ((value: any) => string) => {
  return (value) => dayjs(value).format("MMM D, YYYY");
};

const createYAxisFormatter = (yAxisType: "Money" | "Number"): ((value: any) => string) => {
  return (value) => {
    switch (yAxisType) {
      case "Money":
        return `$${formatValue({ value: value.toString() })}`;
      default:
        return value.toString();
    }
  };
};
