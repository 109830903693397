import { Info } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import useShopifyPayoutsQueryOptions from "modules/connected-stores/queries/useShopifyPayoutsQueryOptions";
import ShopifyPayoutRep from "reps/ShopifyPayoutRep";
import Banner from "ui/data-display/Banner";
import MoneyCell from "ui/table/cells/MoneyCell";
import Table, { Column } from "ui/table/Table";
import Text from "ui/typography/Text";

const PayoutsTable = () => {
  const { data: payouts, isPending } = useQuery(useShopifyPayoutsQueryOptions());

  const columns = deriveColumns();
  const data = transformData(payouts);

  return (
    <>
      <h2 style={{ fontSize: "18px" }}>Payouts</h2>
      {data?.length === 0 && (
        <Banner
          icon={<Info size={24} />}
          color="grey"
          title={
            <Text size={14}>
              No Shopify payouts; if you recently signed up please try again in a few minutes.
            </Text>
          }
        />
      )}
      <Table
        isLoading={isPending}
        rowKey={(row) => row.connectionGuid}
        data={data}
        columns={columns}
      />
    </>
  );
};

export default PayoutsTable;

const deriveColumns = (): Column<ShopifyPayoutRep.Complete>[] => {
  return [
    {
      title: "Date",
      cellRender: (datum) => <Text size={14}>{datum.date}</Text>,
    },
    {
      title: "Amount",
      cellRender: (datum) => <MoneyCell amount={datum.amount.toString()} currencyCode="USD" />,
    },
    {
      title: "Status",
      cellRender: (datum) => <Text size={14}>{datum.status}</Text>,
    },
  ];
};

const transformData = (
  payouts: ShopifyPayoutRep.Complete[] | undefined
): ShopifyPayoutRep.Complete[] | undefined =>
  payouts?.toSorted((a, b) => dayjs(a.date).diff(dayjs(b.date))).slice(0, 10);
