import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BillCommentRep from "reps/BillCommentRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillCommentApi from "../api/useBillCommentApi";
import useRefreshBillCommentsQuery from "../queries/useRefreshBillCommentsQuery";

const useCreateBillCommentMutation = (
  billId: string,
  additionalOptions: MutationAdditionalOptions<
    BillCommentRep.Complete,
    Omit<BillCommentRep.Creator, "businessGuid" | "billId" | "businessMemberGuid">
  >
) => {
  const billCommentApi = useBillCommentApi();
  const refreshBillCommentsQuery = useRefreshBillCommentsQuery(billId);
  const businessGuid = useBusinessGuid();
  const isSuperusering = useIsSuperusering();
  // NB(lev): `businessMemberGuid` is a required field for the mutation, but we
  // won't have a reference to the current business member if the user is
  // superusering. The caller of this mutation is responsible for ensuring
  // that bill comment creation is not allowed when the user is superusering.
  const currentBusinessMember = useCurrentBusinessMember();

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        if (isSuperusering) {
          throw new Error("Cannot create bill comments while superusering.");
        }

        return billCommentApi.create({
          businessGuid,
          billId,
          businessMemberGuid: currentBusinessMember!.guid,
          ...variables,
        });
      },
      onSuccess: async () => {
        await refreshBillCommentsQuery();
      },
    },
    additionalOptions
  );
};

export default useCreateBillCommentMutation;
