import BillLineItemRep from "reps/BillLineItemRep";

export const BILL_LINE_ITEMS_OPEN_REQUESTED_EVENT = "billLineItemsOpenRequested";

export type BillLineItemsOpenRequestedEvent = CustomEvent<{
  billId: string;
}>;

export const makeBillLineItemsOpenRequestedEvent = (
  billId: string
): BillLineItemsOpenRequestedEvent =>
  new CustomEvent(BILL_LINE_ITEMS_OPEN_REQUESTED_EVENT, {
    detail: { billId },
  });

export const BILL_LINE_ITEM_UPDATED_EVENT = "billLineItemUpdated";

export type BillLineItemUpdatedEvent = CustomEvent<{
  billLineItemId: string;
  billId: string;
  originalBillLineItem: BillLineItemRep.Complete;
  billLineItem: BillLineItemRep.Complete;
  updater: BillLineItemRep.Updater;
}>;

export const makeBillLineItemUpdatedEvent = (
  billLineItemId: string,
  billId: string,
  originalBillLineItem: BillLineItemRep.Complete,
  billLineItem: BillLineItemRep.Complete,
  updater: BillLineItemRep.Updater
): BillLineItemUpdatedEvent =>
  new CustomEvent(BILL_LINE_ITEM_UPDATED_EVENT, {
    detail: { billLineItemId, billId, originalBillLineItem, billLineItem, updater },
  });
