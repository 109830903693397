import { ConnectedStoreType } from "modules/connected-stores/types";
import { atom } from "recoil";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

export const DEFAULT_ACTIVE_TAB = "Shopify";

type PayoutInstructionsModalOpenState = { activeTab: ConnectedStoreType };

type PayoutInstructionsModalState = DialogState<PayoutInstructionsModalOpenState>;

const payoutInstructionsModalState = atom<PayoutInstructionsModalState>({
  key: "payoutInstructionsModal",
  default: { isOpen: false },
});

export default payoutInstructionsModalState;

export const usePayoutInstructionsModal = () => {
  return useModal<PayoutInstructionsModalOpenState>(payoutInstructionsModalState);
};
