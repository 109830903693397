import { FC } from "react";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";
import { Span, Strong } from "ui/typography";

import MergedCounterpartiesCounterpartyRow from "./MergedCounterpartiesCounterpartyRow";

export type MergedCounterpartiesModalParams = {
  counterparty: string;
  originalCounterparties: string[];
};

type MergedCounterpartiesModalContentProps = MergedCounterpartiesModalParams;

const MergedCounterpartiesModalContent: FC<MergedCounterpartiesModalContentProps> = ({
  counterparty,
  originalCounterparties,
}) => {
  const { closeModalWithAnimation } = useModalContext();

  return (
    <>
      <ModalV4.Header>Merged vendors</ModalV4.Header>

      <ModalV4.Body>
        <div className="mb-6">
          <Span className="text-sm text-grey-600">
            <Strong className="font-medium">{counterparty}</Strong> is made up of{" "}
            {originalCounterparties.length} vendors:
          </Span>
        </div>

        {originalCounterparties.map((originalCounterparty, index) => (
          <MergedCounterpartiesCounterpartyRow
            key={originalCounterparty}
            originalCounterparty={originalCounterparty}
            isLastRow={index === originalCounterparties.length - 1}
            counterparty={counterparty}
            onClose={closeModalWithAnimation}
          />
        ))}
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.CloseButton>Cancel</ModalV4.CloseButton>
      </ModalV4.Footer>
    </>
  );
};

type Props = {
  params: MergedCounterpartiesModalParams;
  onClose: () => void;
};

const MergedCounterpartiesModal: FC<Props> = ({ params, onClose }) => {
  return (
    <ModalV4 onClose={onClose} dropdownOverflowHack>
      <MergedCounterpartiesModalContent {...params} />
    </ModalV4>
  );
};

export default MergedCounterpartiesModal;
