import React from "react";

import styles from "./ChatPre.module.scss";

type Props = {
  content: string;
};

const ChatPre: React.FC<Props> = ({ content }) => {
  return <pre className={styles.pre}>{content}</pre>;
};

export default ChatPre;
