import { useMutation } from "@tanstack/react-query";
import useRefreshCapitalAccountTransactions from "modules/capital-account-transactions/queries/useRefreshCapitalAccountTransactions";
import { useRefreshAllCapitalAccountsQueries } from "modules/capital-accounts/queries/capitalAccountsQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import BankAccountRep from "reps/BankAccountRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY } from "../queries/useLineOfCreditInterestFee";

type Data = {
  lineOfCreditGuid: string;
  amountInCents: number;
  transferTo: BankAccountRep.Complete;
  idempotencyKey: string;
};

const useCreateDrawdownMutation = () => {
  const { mfa } = useMfa();
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  const refreshCapitalAccountsQueries = useRefreshAllCapitalAccountsQueries();
  const refreshCapitalAccountTransactions = useRefreshCapitalAccountTransactions();

  const refreshLineOfCreditInterestFeeQuery = useRefreshQuery([
    LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY,
  ]);

  return useMutation({
    mutationFn: async (data: Data) => {
      await mfa();
      return highbeamApi.lineOfCreditTransactions.createDrawdown(
        businessGuid,
        data.lineOfCreditGuid,
        data.amountInCents,
        data.idempotencyKey,
        data.transferTo.guid
      );
    },
    onError: () => {
      notify("warning", "There was an issue completing the transfer. Please try again.");
    },
    onSuccess: async () => {
      // Exemplar: `await` multiple queries to ensure the queries refreshed before we navigate.
      await Promise.all([
        refreshCapitalAccountsQueries(),
        refreshLineOfCreditInterestFeeQuery(),
        refreshCapitalAccountTransactions(),
      ]);
      notify("success", "Drawdown completed");
    },
  });
};

export default useCreateDrawdownMutation;
