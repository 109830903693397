import { Card } from "@highbeam/unit-node-sdk";
import { getCardName } from "modules/cards/utils";
import getCardTypeByCard from "modules/cards/utils/getCardTypeByCard";
import { FC } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import { Span } from "ui/typography";
import { maskAccountNumber } from "utils/account";
import cn from "utils/tailwind/cn";

import { useCardQuery } from "../../queries/useCard";
import { getCardTypeLabelByUnitCoCard } from "../../utils/get-card-type-label";
import CardAvatar from "../CardAvatar";
import CardStatusPill from "../CardStatusPill";

type CardAvatarBarLoadingProps = {
  showIcon?: boolean;
  showTypeLabel?: boolean;
  className?: string;
};

export const CardAvatarBarLoading: FC<CardAvatarBarLoadingProps> = ({
  showIcon = true,
  showTypeLabel = true,
  className,
}) => {
  return (
    <div className={cn("flex items-center gap-2", className)}>
      {showIcon && <CardAvatar.Loading size="sm" />}

      <div className="flex h-9 flex-col justify-center">
        <Shimmer className="mb-2 w-36" />
        {showTypeLabel && <Shimmer className="w-24" />}
      </div>
    </div>
  );
};

export type CardAvatarBarShowStatus = "always" | "never" | "if-not-active";

type Props = {
  card: Card;
  showIcon?: boolean;
  showTypeLabel?: boolean;
  showLast4Digits?: boolean;
  showStatus?: CardAvatarBarShowStatus;
  isLoading?: boolean;
  className?: string;

  // NB(alex): Would be nice to add an option for concatenating the card name / setting a max length, but we don't have designs for it yet.
};

const CardAvatarBar: FC<Props> = ({
  card,
  showLast4Digits = true,
  showIcon = true,
  showTypeLabel = true,
  showStatus = "if-not-active",
  isLoading,
  className,
}) => {
  const last4digits = card?.attributes.last4Digits;
  const cardLabel = getCardTypeLabelByUnitCoCard({ card, capitalize: true });

  if (isLoading) {
    return (
      <CardAvatarBarLoading
        showIcon={showIcon}
        showTypeLabel={showTypeLabel}
        className={className}
      />
    );
  }

  return (
    <div className={cn("flex items-center gap-2", className)}>
      {showIcon && (
        <div className="flex items-center">
          <CardAvatar cardType={getCardTypeByCard(card)} size="sm" />
        </div>
      )}
      <div className="h-full">
        <Span className="flex items-center gap-x-2 text-sm font-medium text-grey-800">
          {getCardName(card)}
          {showLast4Digits && last4digits && ` ${maskAccountNumber(last4digits)}`}
          {(showStatus === "always" ||
            (showStatus === "if-not-active" && card.attributes.status !== "Active")) && (
            <CardStatusPill status={card.attributes.status} size="2xs" />
          )}
        </Span>
        {showTypeLabel && (
          <Span className="block text-xs font-medium text-grey-400">{cardLabel}</Span>
        )}
      </div>
    </div>
  );
};

export default Object.assign(CardAvatarBar, {
  Loading: CardAvatarBarLoading,
});

//
// Wrappers
//

type CardAvatarBarByCardIdProps = Omit<Props, "card"> & {
  cardId: string;
};

export const CardAvatarBarByCardId = ({ cardId, ...props }: CardAvatarBarByCardIdProps) => {
  const { data: card } = useCardQuery({ cardId });

  if (!card) {
    return <CardAvatarBarLoading {...props} />;
  }

  return <CardAvatarBar card={card} {...props} />;
};
