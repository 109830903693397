import HighbeamCard from "components/HighbeamCard";
import { BankAccountBarByUnitCoDepositAccountId } from "modules/bank-accounts/components/BankAccountBar";
import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import { CapitalAccountBarByUnitCoCreditAccountId } from "modules/capital-accounts/components/CapitalAccountBar";
import ApplePayInfoText from "modules/cards/components/ApplePayInfoText";
import useCard from "modules/cards/queries/useCard";
import useCardLimits from "modules/cards/queries/useCardLimits";
import { getCardBillingAddress, getCardholderName } from "modules/cards/utils";
import { checkIsCreditCard, checkIsVirtualCard, DebitCard } from "modules/cards/utils/typeguards";
import { FC } from "react";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ModalV4 from "ui/overlay/ModalV4";
import { Heading3, Paragraph, Span } from "ui/typography";

type CreateCardModalCardCreatedSuccessfullyHeaderProps = {
  cardId: string;
};

export const CreateCardModalCardCreatedSuccessfullyHeader: FC<
  CreateCardModalCardCreatedSuccessfullyHeaderProps
> = ({ cardId }) => {
  const card = useCard({ cardId, required: true });
  const currentBusinessMember = useCurrentBusinessMember();
  const isOwnCard = card.attributes.tags.businessMemberGuid === currentBusinessMember?.guid;

  return (
    <div className="flex flex-col gap-2">
      <Heading3>Here are the card details</Heading3>
      <Paragraph className="text-sm font-regular text-grey-600">
        {isOwnCard ? (
          <>
            You can now access and use this virtual card from the <strong>Cards</strong> page.
          </>
        ) : (
          <>
            {getCardholderName(card)} can now access and use this virtual card from the{" "}
            <strong>Cards</strong> page in their Highbeam account.
          </>
        )}
      </Paragraph>
    </div>
  );
};

const DebitCardBankAccountLabel: FC<{ debitCard: DebitCard }> = ({ debitCard }) => {
  return (
    <BankAccountBarByUnitCoDepositAccountId
      unitCoDepositAccountId={debitCard.relationships.account.data.id}
    />
  );
};

type Props = {
  cardId: string;
};

const CreateCardModalCardCreatedSuccessfully: FC<Props> = ({ cardId }) => {
  const card = useCard({ cardId, required: true });
  const billingAddress = getCardBillingAddress(card);
  const cardLimits = useCardLimits({ cardId: cardId });

  return (
    <>
      <ModalV4.Body>
        <div className="flex items-center justify-center">
          <HighbeamCard
            card={card}
            clickable={false}
            className="leading-tight" // Fixes a spacing issue caused by the Modal's line height setting.
            showDetailsButton={checkIsVirtualCard(card)}
          />
        </div>

        <MetadataList className="mt-6">
          {billingAddress && (
            <MetadataList.Item>
              <MetadataList.ItemLabel className="items-start">
                Billing address
              </MetadataList.ItemLabel>
              <MetadataList.ItemValue className="ml-auto flex-col items-end gap-y-0">
                <Span>{billingAddress.street}</Span>
                {billingAddress.street2 && <Span>{billingAddress.street2}</Span>}
                <Span>
                  {billingAddress.city}, {billingAddress.state} {billingAddress.postalCode}
                </Span>
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}

          <MetadataList.Item>
            <MetadataList.ItemLabel>Associated account</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              {checkIsCreditCard(card) ? (
                <CapitalAccountBarByUnitCoCreditAccountId
                  unitCoCreditAccountId={card.relationships.account.data.id}
                />
              ) : (
                <DebitCardBankAccountLabel debitCard={card} />
              )}
            </MetadataList.ItemValue>
          </MetadataList.Item>

          {cardLimits.attributes.limits?.monthlyPurchase && (
            <MetadataList.Item>
              <MetadataList.ItemLabel>Monthly spend limit</MetadataList.ItemLabel>
              <MetadataList.ItemValue>
                <MoneyAmount
                  cents={cardLimits.attributes.limits.monthlyPurchase}
                  size={14}
                  weight="medium"
                />
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}
          {cardLimits.attributes.limits?.dailyPurchase && (
            <MetadataList.Item>
              <MetadataList.ItemLabel>Daily spend limit</MetadataList.ItemLabel>
              <MetadataList.ItemValue>
                <MoneyAmount
                  cents={cardLimits.attributes.limits.dailyPurchase}
                  size={14}
                  weight="medium"
                />
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}
        </MetadataList>

        <ApplePayInfoText className="my-2" />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.CloseButton />
      </ModalV4.Footer>
    </>
  );
};

export default CreateCardModalCardCreatedSuccessfully;
