import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

import drawdownPreApprovedLimitQueryHooks from "./drawdownPreApprovedLimitQueryHooks";

type Params = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  amountInCents: number;
};

type Result =
  | {
      type: "instant-drawdown";
    }
  | {
      type: "large-drawdown";
      drawdownRateLimit: number;
      drawdownPreApprovedLimit: number;
      recentTransactionAmount: number;
    }
  | {
      type: "requires-approval";
    };

const useCapitalDrawdownType = ({ capitalAccountSummary, amountInCents }: Params): Result => {
  const isDrawdownApprovalRequired = capitalAccountSummary.controls.drawdownRequiresApproval;

  const drawdownPreApprovedLimit = drawdownPreApprovedLimitQueryHooks.useData({
    businessGuid: capitalAccountSummary.businessGuid,
    capitalAccountGuid: capitalAccountSummary.guid,
  });

  if (isDrawdownApprovalRequired) {
    return {
      type: "requires-approval",
    };
  }

  const isLargeAmount =
    drawdownPreApprovedLimit !== null && amountInCents > drawdownPreApprovedLimit;
  const drawdownRateLimit = capitalAccountSummary.controls.drawdownRateLimit;

  if (isLargeAmount && drawdownRateLimit) {
    return {
      type: "large-drawdown",
      drawdownRateLimit: drawdownRateLimit,
      drawdownPreApprovedLimit: drawdownPreApprovedLimit,
      recentTransactionAmount: drawdownRateLimit - drawdownPreApprovedLimit,
    };
  }

  return {
    type: "instant-drawdown",
  };
};

export default useCapitalDrawdownType;
