import { usePaymentApprovalFlexpaneContext } from "dialogs/PaymentApprovalFlexpane/context/PaymentApprovalFlexpaneProvider";
import usePaymentApproval from "modules/payment-approvals/queries/usePaymentApproval";
import PaymentInvoice from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PaymentInvoice";
import Flexpane from "ui/overlay/Flexpane";

const PaymentApprovalInvoice = () => {
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });
  const paymentMetadataGuid = paymentApproval.generalPaymentMetadataGuid;

  if (!paymentMetadataGuid) {
    return null;
  }

  return (
    <Flexpane.Section>
      <PaymentInvoice paymentMetadataGuid={paymentMetadataGuid} showDelete={false} />
    </Flexpane.Section>
  );
};

export default PaymentApprovalInvoice;
