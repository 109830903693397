import HighbeamBaseApi from "api/HighbeamBaseApi";
import { ExchangeRep, ExchangeCreatorRep } from "reps/chat/ExchangeRep";

export default class ConversationApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async listByConversation(
    conversationId: string,
    { debugMode }: { debugMode: boolean }
  ): Promise<ExchangeRep[]> {
    const queryParams = new URLSearchParams({
      conversationId: conversationId,
      debugMode: debugMode.toString(),
    });
    const url = `/chat/exchanges?${queryParams}`;
    return (await this.api.get<ExchangeRep[]>(url))!;
  }

  async create(creator: ExchangeCreatorRep): Promise<ExchangeRep> {
    const url = `/chat/exchanges`;
    return (await this.api.post<ExchangeRep>(url, creator))!;
  }
}
