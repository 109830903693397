import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const CHARGE_CARD_REPAYMENT_QUERY_KEY = "charge-card-repayments";

// NB(alex): we would like to pass `search` to the transactions query but the backend does not support it yet, so we filter transactions here on the frontend
export const useChargeCardRepaymentsQueryOptions = (chargeCardAccountGuid?: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CHARGE_CARD_REPAYMENT_QUERY_KEY, { chargeCardAccountGuid }],
    queryFn: () => {
      if (!chargeCardAccountGuid) {
        return [];
      }
      return highbeamApi.chargeCardRepayment.get(businessGuid, chargeCardAccountGuid);
    },
  });
};

export const useChargeCardRepaymentsQuery = (chargeCardAccountGuid?: string) => {
  return useQuery(useChargeCardRepaymentsQueryOptions(chargeCardAccountGuid));
};

const useChargeCardRepayments = (chargeCardAccountGuid?: string) => {
  const { data } = useSuspenseQuery(useChargeCardRepaymentsQueryOptions(chargeCardAccountGuid));
  return data;
};

export default useChargeCardRepayments;
