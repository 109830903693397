import useCapitalAccountAutoPayConnectedStoreTypes from "modules/capital-accounts/queries/useCapitalAccountAutoPayConnectedStoreTypes";
import getRemittanceRate from "modules/capital-accounts/utils/getRemittanceRate";
import ConnectedStoresAvatarGroup from "modules/connected-stores/components/ConnectedStoresAvatarGroup";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import MetadataList from "ui/data-display/MetadataList";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountCardAutoPayConnectedStoresItem: FC<Props> = ({ capitalAccountSummary }) => {
  // NB(alex): The rutter platform should always be a connected store, but we have to handle other cases because the ts types don't line up. Would be nice to fix this on the backend, but this works for now.
  const autoPayConnectedStoreTypes = useCapitalAccountAutoPayConnectedStoreTypes(
    capitalAccountSummary.guid
  );

  const remittanceRate = getRemittanceRate(capitalAccountSummary) ?? 0;
  const remittancePercent = remittanceRate * 100;

  if (autoPayConnectedStoreTypes.length === 0) {
    return null;
  }

  return (
    <MetadataList.Item>
      <MetadataList.ItemLabel>Auto-paying from</MetadataList.ItemLabel>
      <MetadataList.ItemValue className="text-nowrap">
        <ConnectedStoresAvatarGroup
          connectedStoreTypes={autoPayConnectedStoreTypes}
          isGrey={remittancePercent === 0}
          size="sm"
        />
      </MetadataList.ItemValue>
    </MetadataList.Item>
  );
};

export default CapitalAccountCardAutoPayConnectedStoresItem;
