import { ComponentProps, FC } from "react";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";

import TextCell from "./TextCell";

type Props = ComponentProps<typeof MoneyAmount> & {
  darken?: boolean;
};

const MoneyCell: FC<Props> = ({ darken, ...moneyAmountProps }) => {
  return (
    <TextCell darken={darken}>
      <MoneyAmount
        weight="medium"
        showCurrencySymbol
        showTrailingCurrencyCode={false}
        {...moneyAmountProps}
      />
    </TextCell>
  );
};

export default MoneyCell;
