import DashboardPage from "layouts/DashboardPage";
import RefreshPlaidBalancesButton from "modules/plaid/components/RefreshPlaidBalancesButton";
import plaidAccountsQueryHooks from "modules/plaid/queries/plaidAccountsQueryHooks";
import { useEffect } from "react";
import { notify } from "ui/feedback/Toast";

import PlaidBankAccountsTable from "../../components/PlaidBankAccountsTable";

const notifyPlaidBankAccountsError = () => {
  notify("error", "Something went wrong while loading your connected accounts.");
};

const PlaidBankAccountsSection = () => {
  const { data: plaidBankAccounts, error: plaidBankAccountsError } =
    plaidAccountsQueryHooks.useSuspenseQuery({
      select: (accounts) => accounts.filter((account) => account.accountType === "DEPOSITORY"),
    });

  // Show an error toast if one of the queries fails.
  useEffect(() => {
    if (plaidBankAccountsError) {
      notifyPlaidBankAccountsError();
    }
  }, [plaidBankAccountsError]);

  if (!plaidBankAccounts || plaidBankAccounts.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <div className="flex w-full flex-col items-start">
          <DashboardPage.Section.HeaderHeading>
            Connected bank accounts
          </DashboardPage.Section.HeaderHeading>
          <div className="flex w-full flex-row items-center justify-between">
            <DashboardPage.Section.HeaderSubheading>
              Balances are refreshed daily.
            </DashboardPage.Section.HeaderSubheading>
            <RefreshPlaidBalancesButton />
          </div>
        </div>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <PlaidBankAccountsTable accounts={plaidBankAccounts} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default PlaidBankAccountsSection;
