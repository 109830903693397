import { ChargeCardProgram } from "modules/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import { FC } from "react";
import IconWithTooltip, { InfoIconColor } from "ui/overlay/IconWithTooltip";
import { Paragraph } from "ui/typography";

type Props = {
  chargeCardProgram: ChargeCardProgram;
  color?: InfoIconColor;
};

const ChargeCardCashBackInfoIconWithTooltip: FC<Props> = ({
  chargeCardProgram,
  color = "dark",
}) => {
  return (
    <IconWithTooltip
      color={color}
      tooltip={
        <Paragraph>
          {chargeCardProgram.maxCashback}% on all qualifying ad spend, and{" "}
          {chargeCardProgram.minCashback}% on all other spend. Cash back is paid directly to your
          Highbeam bank account.
        </Paragraph>
      }
    />
  );
};

export default ChargeCardCashBackInfoIconWithTooltip;
