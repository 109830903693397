import { useMutationState, useQueryClient } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import plaidAccountsQueryHooks from "../queries/plaidAccountsQueryHooks";

export const REFRESH_BALANCES_MUTATION_KEY = "refresh-plaid-balances";

type Params = {
  forceFetch: boolean;
};

const useRefreshPlaidBalancesMutation = (
  additionalOptions?: MutationAdditionalOptions<PlaidBankAccountRep.Complete[], Params>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const queryClient = useQueryClient();
  const plaidAccountsQueryKey = plaidAccountsQueryHooks.useQueryKey({});

  return useMutationWithDefaults(
    {
      mutationKey: [REFRESH_BALANCES_MUTATION_KEY, { businessGuid }],
      mutationFn: async (params: Params) => {
        return highbeamApi.plaid.refreshPlaidBalances(businessGuid, params.forceFetch);
      },
      onSuccess: (data) => {
        // This returns the same data as the Plaid accounts query, so we can safely set it here too.
        queryClient.setQueryData(plaidAccountsQueryKey, data);
      },
      onError: () => {
        notify("error", "Failed to refresh Plaid balances.");
      },
    },
    additionalOptions || {}
  );
};

export default useRefreshPlaidBalancesMutation;

// Hooks

export const usePlaidBalancesRefreshing = () => {
  const businessGuid = useBusinessGuid();

  const results = useMutationState({
    filters: {
      mutationKey: [REFRESH_BALANCES_MUTATION_KEY, { businessGuid }],
    },
  });
  return results.some((result) => result.status === "pending");
};
