import React, { ReactNode } from "react";

import AiChatTableRow from "./AiChatTableRow";

type Props = {
  children: ReactNode;
};

const AiChatTableHeaderRow: React.FC<Props> = ({ children }) => {
  return <AiChatTableRow>{children}</AiChatTableRow>;
};

export default AiChatTableHeaderRow;
