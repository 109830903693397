import merge from "lodash-es/merge";
import { useMemo } from "react";
import { PartialDeep } from "type-fest";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import { TabConfig, TabConfigTab } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

export const BILL_DETAILS_PAYEE_PAYMENT_METHOD_TAB_KEY = "payment-details";

export const billDetailsPayeePaymentMethodTabs = {
  ach: { label: "ACH" },
  "domestic-wire": { label: "Wire" },
  "international-wire": { label: "International wire" },
} satisfies TabConfig;

export type PayeePaymentMethodTabKey = keyof typeof billDetailsPayeePaymentMethodTabs;

const useBillDetailsPayeePaymentMethodTabs = (
  defaultValue: PayeePaymentMethodTabKey,
  configOverride?: PartialDeep<Record<PayeePaymentMethodTabKey, TabConfigTab>>
) => {
  const tabs = useMemo(
    () => merge({}, billDetailsPayeePaymentMethodTabs, configOverride),
    [configOverride]
  ) as typeof billDetailsPayeePaymentMethodTabs;

  const [activeTab, setActiveTab] = useSearchParamOption(
    "payment-details",
    getObjectKeys(tabs),
    defaultValue
  );

  return {
    tabs,
    activeTab: activeTab,
    setActiveTab: setActiveTab,
  };
};

export default useBillDetailsPayeePaymentMethodTabs;
