import { useSuspenseQuery } from "@tanstack/react-query";
import OnboardingLayout from "layouts/OnboardingLayout";
import useConnectedStoresQueryOptions from "modules/connected-stores/queries/useConnectedStoresQueryOptions";
import OnboardingConnectStoresCard from "modules/onboarding/components/OnboardingConnectStoresCard";
import HighbeamInsightsPage from "pages/HighbeamInsightsPage";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

/**
 * Highbeam supports an "Insights App" for Shopify, that only includes insights features.
 * See {@link useInsightsApp} for more.
 */
const InsightsAppRouter: React.FC = () => {
  const { data: connectedStores = [] } = useSuspenseQuery(useConnectedStoresQueryOptions());
  const numberOfConnections = connectedStores.length;
  const hasConnections = numberOfConnections > 0;

  return (
    <Routes>
      {hasConnections && <Route path="/insights" element={<HighbeamInsightsPage />} />}
      <Route
        path="/onboarding/connect-stores"
        element={
          <OnboardingLayout>
            <OnboardingConnectStoresCard />
          </OnboardingLayout>
        }
      />
      <Route
        path="*"
        element={
          <Navigate replace to={hasConnections ? "/insights" : "/onboarding/connect-stores"} />
        }
      />
    </Routes>
  );
};

export default InsightsAppRouter;
