import { ArrowsInSimple, Pencil, Storefront } from "@phosphor-icons/react";
import { CashFlowTableDatum } from "pages/CashFlowPage/data/useCashFlowTableData";
import { FC } from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import CounterpartyCell from "ui/table/cells/CounterpartyCell";
import { Span } from "ui/typography";

type Props = {
  datum: CashFlowTableDatum;
  onClickCounterpartyName: () => void;
  onClickMergedCounterparties: () => void;
};

const CashFlowCounterpartyCell: FC<Props> = ({
  datum,
  onClickCounterpartyName,
  onClickMergedCounterparties,
}) => {
  const numberOfMergedCounterparties = datum.originalCounterparties.length;

  return (
    <CounterpartyCell>
      <VirtualButton
        className="group inline-flex items-center gap-x-2"
        onClick={onClickCounterpartyName}
      >
        <Storefront size={24} />
        {datum.counterparty}

        <Pencil size={16} className="invisible group-hover:visible" />
      </VirtualButton>

      {numberOfMergedCounterparties > 1 && (
        <div
          className="mt-1 flex cursor-pointer items-center gap-x-1"
          onClick={onClickMergedCounterparties}
        >
          <ArrowsInSimple className="text-grey-600" size={16} />

          <Span className="text-xs text-grey-600 underline">
            {numberOfMergedCounterparties} merged
          </Span>
        </div>
      )}
    </CounterpartyCell>
  );
};

export default CashFlowCounterpartyCell;
