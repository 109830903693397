import { useSuspenseQueries } from "@tanstack/react-query";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import plaidAccountsQueryHooks from "modules/plaid/queries/plaidAccountsQueryHooks";

const useCapitalBackupAutoPayAccounts = () => {
  const [{ data: openBankAccounts }, { data: plaidAccounts }] = useSuspenseQueries({
    queries: [
      bankAccountsQueryHooks.useQueryOptions({ status: "open" }),
      plaidAccountsQueryHooks.useQueryOptions({}),
    ],
  });

  const plaidBankAccounts = plaidAccounts.filter((account) => account.accountType === "DEPOSITORY");

  return {
    openBankAccounts,
    plaidBankAccounts,
  };
};

export default useCapitalBackupAutoPayAccounts;
