import { createContext, useContext } from "react";

type BarChartContextValue = {
  height: number;
};

const BarChartContext = createContext<BarChartContextValue>({} as BarChartContextValue);

export const BarChartProvider = BarChartContext.Provider;

export const useBarChartContext = () => {
  const context = useContext(BarChartContext);
  if (!context) {
    throw new Error("useBarChartContext must be used within a BarChart");
  }
  return context;
};
