import { FC, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type MenuSubItemProps = {
  text: ReactNode;
  path: string;
  onClose?: () => void;
  badge?: ReactNode | ((renderProps: { isActive: boolean }) => ReactNode);
};

const MenuSubItem: FC<MenuSubItemProps> = ({ text, path, onClose, badge }) => {
  const pathName = useLocation().pathname;
  const isActive = pathName === path;

  return (
    <Link to={path} onClick={onClose}>
      <div className="ml-8 flex cursor-pointer items-center gap-2 px-4 py-2 font-medium leading-5 text-grey-600 hover:text-purple-900">
        <Text
          size={14}
          as="div"
          className={cn({
            "text-purple-500 hover:text-purple-500": isActive,
          })}
        >
          {text}
        </Text>
        {badge && (
          <span className="ml-auto">
            {typeof badge === "function" ? badge({ isActive }) : badge}
          </span>
        )}
      </div>
    </Link>
  );
};

export default MenuSubItem;
