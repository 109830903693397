import { ReactElement, ReactNode, useState } from "react";
import useSearchParamOption from "utils/search-params/useSearchParamOption";

export type TabConfigBadge = number | "dot" | "warning" | ReactNode;

export type TabConfigTab = {
  icon?: ReactElement<{ size: number }>;
  label: ReactNode;
  // DEPRECATED: use badge={number} instead.
  // TODO(lev): remove count in favor of badge={number}.
  count?: number;

  badge?: TabConfigBadge;
};

export type TabConfig<T extends string = string> = Record<T, TabConfigTab>;

const useTabState = <TTabs extends TabConfig>(
  tabs: TTabs,
  initialTab: keyof TTabs = Object.keys(tabs)[0]
) => {
  return useState<keyof TTabs>(initialTab);
};

export default useTabState;

export const useSearchParamTabState = <TTabs extends TabConfig>(
  key: string,
  tabs: TTabs,
  initialTab: keyof TTabs = Object.keys(tabs)[0]
) => {
  return useSearchParamOption(key, Object.keys(tabs), initialTab.toString());
};
