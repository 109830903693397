export type ConversationSearch = Readonly<{
  businessGuid: string;
  userGuid: string | undefined;
}>;

export const conversationSearchQuery = (search: ConversationSearch) => {
  const queryParams = new URLSearchParams();
  queryParams.set("businessGuid", search.businessGuid);
  if (search.userGuid) queryParams.set("userGuid", search.userGuid);
  return queryParams;
};
