import { ButtonHTMLAttributes, ForwardRefRenderFunction, forwardRef, useRef } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

// Exemplar: usage of `forwardRef`

export type ButtonVariant =
  | "default"
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "ghost"
  | "plain";

export type ButtonPaddingVariant = "regular" | "square" | "bare";

export type ButtonSize = "md" | "sm" | "xs";

const getButtonVariantClasses = (
  variant: ButtonVariant,
  paddingVariant?: ButtonPaddingVariant
): string => {
  return variants(variant, {
    // Only apply certain hover and active styles to the default variant if the button has padding.
    default: `bg-transparent text-purple-500 ${
      paddingVariant !== "bare"
        ? "data-[disabled=false]:hover:bg-grey-50 data-[disabled=false]:hover:shadow-none data-[disabled=false]:active:shadow-inset"
        : ""
    }`,
    primary:
      "border border-transparent bg-purple-500 text-grey-50 data-[disabled=false]:hover:bg-purple-600 data-[disabled=false]:active:shadow-inset-purple-dark",
    secondary:
      "border border-purple-100 bg-purple-50 text-purple-500 shadow-xs data-[disabled=false]:hover:bg-purple-100 data-[disabled=false]:hover:shadow-sm data-[disabled=false]:active:shadow-inset-purple",
    tertiary:
      "border border-grey-200 bg-white text-grey-800 shadow-xs data-[disabled=false]:hover:bg-grey-100 data-[disabled=false]:hover:shadow-none data-[disabled=false]:active:shadow-inset",
    danger:
      "border border-red-100 bg-red-50 text-red-500 shadow-xs data-[disabled=false]:hover:bg-red-100 data-[disabled=false]:active:shadow-inset-red",
    ghost: "text-grey-800",
    plain: "text-inherit",
  });
};

const getButtonSizeClasses = (size: ButtonSize): string => {
  return variants(size, {
    md: "gap-2 text-sm",
    sm: "gap-1 text-xs",
    xs: "gap-1 text-xs",
  });
};

const getButtonPaddingVariantClasses = (
  paddingVariant: ButtonPaddingVariant,
  size: ButtonSize
): string => {
  return variants(paddingVariant, {
    regular: variants(size, {
      md: "px-4 py-2.5",
      sm: "px-3 py-2",
      xs: "px-2 py-1",
    }),
    square: variants(size, {
      md: "p-2.5",
      sm: "p-2",
      xs: "p-1",
    }),
    bare: "p-0",
  });
};

const baseButtonClasses =
  "inline-flex h-fit items-center justify-center whitespace-nowrap rounded-md font-medium data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50";

type GetButtonClassesParams = {
  variant: ButtonVariant;
  paddingVariant: ButtonPaddingVariant;
  size: ButtonSize;
};

export const getButtonClasses = ({ variant, paddingVariant, size }: GetButtonClassesParams) => {
  return cn(
    baseButtonClasses,
    getButtonVariantClasses(variant, paddingVariant),
    getButtonSizeClasses(size),
    getButtonPaddingVariantClasses(paddingVariant, size)
  );
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant;
  paddingVariant?: ButtonPaddingVariant;
  size?: ButtonSize;
  isLoading?: boolean;
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    variant = "default",
    paddingVariant = "regular",
    className,
    size = "md",
    isLoading,
    disabled,
    children,
    ...props
  },
  ref
) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dataDisabled = Boolean(disabled || isLoading);

  return (
    <button
      type="button"
      ref={ref ? ref : buttonRef}
      className={cn(
        getButtonClasses({
          variant,
          paddingVariant,
          size,
        }),
        className
      )}
      disabled={dataDisabled}
      data-disabled={dataDisabled}
      {...props}
    >
      {isLoading && <AnimatedSpinner />}
      {children}
    </button>
  );
};

// This ensures the component name is correct when inferred by storybook.
Button.displayName = "Button";

export default forwardRef(Button);
