import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import PayeeValidationRep from "reps/PayeeValidationRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const PAYEES_VALIDATION_QUERY_KEY = "payees";

const usePayeeValidatorQueryOptions = (validate: PayeeValidationRep.Validate) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const queryKey = PAYEES_VALIDATION_QUERY_KEY + JSON.stringify(validate);
  return useQueryOptions({
    queryKey: [queryKey, { businessGuid }],
    queryFn: () => {
      if (!businessGuid) {
        return null;
      }
      return highbeamApi.payeeValidator.validate(businessGuid, validate);
    },
  });
};

export const usePayeeValidatorQuery = (validate: PayeeValidationRep.Validate) => {
  return useQuery(usePayeeValidatorQueryOptions(validate));
};

const usePayeeValidator = <TRequired extends boolean>(
  validate: PayeeValidationRep.Validate,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(usePayeeValidatorQueryOptions(validate));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default usePayeeValidator;
