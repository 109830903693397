import { PauseCircle } from "@phosphor-icons/react";
import useCapitalAccountAutoPayConnectedStoreTypes from "modules/capital-accounts/queries/useCapitalAccountAutoPayConnectedStoreTypes";
import getRemittanceRate from "modules/capital-accounts/utils/getRemittanceRate";
import ConnectedStoresAvatarGroup from "modules/connected-stores/components/ConnectedStoresAvatarGroup";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import HighlightItem from "ui/data-display/HighlightItem";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Span } from "ui/typography";
import Text from "ui/typography/Text";

// NB(alex): This component is almost never used but is here because we still have to support `PayoutPercentage` types. We can probably delete it soon.

type Props = {
  className?: string;
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountAutoPayConnectedStoresHighlightItem: FC<Props> = ({
  className,
  capitalAccountSummary,
}) => {
  const remittanceRate = getRemittanceRate(capitalAccountSummary) ?? 0;
  const remittancePercent = remittanceRate * 100;

  const autoPayConnectedStoreTypes = useCapitalAccountAutoPayConnectedStoreTypes(
    capitalAccountSummary.guid
  );

  const onClickSetUpAutoPay = () => {
    Intercom("showNewMessage", "Hi, I would like to set up auto-pay for my line of credit.");
  };

  return (
    <HighlightItem className={className}>
      <HighlightItem.Heading>
        <>
          Auto-pay
          {remittancePercent === 0 ? (
            <Pill color="grey-100">Off</Pill>
          ) : (
            <>
              {" from "}
              <IconWithTooltip
                color="primary"
                tooltip={
                  <Text size={14}>
                    {remittancePercent}% of every payout will be used to automatically pay back the
                    outstanding balance on your line of credit.
                  </Text>
                }
              />
            </>
          )}
        </>
      </HighlightItem.Heading>

      <HighlightItem.Body>
        {autoPayConnectedStoreTypes.length >= 1 ? (
          <ConnectedStoresAvatarGroup
            connectedStoreTypes={autoPayConnectedStoreTypes}
            isGrey={remittancePercent === 0}
            size="sm"
          />
        ) : (
          <Button variant="tertiary" size="xs" onClick={onClickSetUpAutoPay}>
            Set up auto-pay
          </Button>
        )}
      </HighlightItem.Body>

      <HighlightItem.Footer>
        <Span className="flex items-center gap-x-1 text-xs text-grey-600">
          {remittancePercent === 0 ? (
            <>
              <PauseCircle /> Paused
            </>
          ) : (
            <>{remittancePercent}% of every payout</>
          )}
        </Span>
      </HighlightItem.Footer>
    </HighlightItem>
  );
};

export default CapitalAccountAutoPayConnectedStoresHighlightItem;
