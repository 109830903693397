import InsightsSyncTaskRep from "reps/InsightsSyncTaskRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class InsightsSyncApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async sync(businessGuid: string): Promise<void> {
    const url = `/insights/tasks/insights-sync/create`;
    const body: InsightsSyncTaskRep.Complete = {
      businessGuids: { type: "Explicit", guids: [businessGuid] },
      force: false,
    };
    return (await this.api.post(url, body))!;
  }
}
