// NB(lev): Experimental (but optimistic)!
// This module provides facilities for creating API client modules for the Highbeam API
// which are independent of the HighbeamApi class and don't require the useHighbeamApi hook.
// This is so we can move towards a more modular API client architecture and code split
// different API modules across the app.

import { useQueryClient } from "@tanstack/react-query";
import HighbeamBaseApi from "api/HighbeamBaseApi";
import env from "env";
import useJwt from "modules/jwt/queries/useJwt";
import { useMemo, useCallback } from "react";
import Jwt from "reps/Jwt";

enum Backend {
  V1 = "V1",
  V2 = "V2",
}

const getRequestOrigin = (backend: Backend) => {
  switch (backend) {
    case Backend.V1:
      return env.HIGHBEAM_API_ORIGIN_V1;
    case Backend.V2:
      return env.HIGHBEAM_API_ORIGIN_V2;
  }
};

const useRequestOrigin = (backend: Backend) => useMemo(() => getRequestOrigin(backend), [backend]);

const useGetJwt = () => {
  // Call useJwt() to guarantee the query cache is always primed when using this hook.
  useJwt();

  const queryClient = useQueryClient();

  return useCallback(() => {
    const jwt = queryClient.getQueryData<Jwt>(["jwt"]);

    // HighbeamBaseApi expects a promise that resolves to a string with the raw JWT token.
    return Promise.resolve(jwt?.rawToken);
  }, [queryClient]);
};

export type ApiBuilder<TApi> = (api: HighbeamBaseApi) => TApi;

const useBackendApi = <TApi>(backend: Backend, builder: ApiBuilder<TApi>) => {
  const requestOrigin = useRequestOrigin(backend);
  const getJwt = useGetJwt();
  const api = useMemo(() => new HighbeamBaseApi(requestOrigin, getJwt), [requestOrigin, getJwt]);

  return useMemo(() => builder(api), [api, builder]);
};

export const useBackendV1Api = <TApi>(builder: ApiBuilder<TApi>) =>
  useBackendApi(Backend.V1, builder);

export const useBackendV2Api = <TApi>(builder: ApiBuilder<TApi>) =>
  useBackendApi(Backend.V2, builder);
