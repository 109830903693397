import { useQuery } from "@tanstack/react-query";
import useInternationalWireQuoteQueryOptions from "modules/international-wires/queries/useInternationalWireQuoteQueryOptions";
import { FC } from "react";
import ShimmerV2 from "ui/feedback/ShimmerV2";
import Text, { TextProps } from "ui/typography/Text";

type Props = TextProps & {
  currency: string; // TODO(alex): string union
};

const ExchangeRateQuote: FC<Props> = ({ currency, ...textProps }) => {
  // TODO(alex): HB-5295 Figure out how to share queries across components.
  const { data: quote, isPending } = useQuery(useInternationalWireQuoteQueryOptions(currency));

  return (
    <Text numeric {...textProps}>
      {isPending ? (
        <ShimmerV2 className="w-32" />
      ) : (
        <>
          {quote ? (
            <>
              1 USD = {quote.rate} {currency}
            </>
          ) : (
            "Rate not found"
          )}
        </>
      )}
    </Text>
  );
};

export default ExchangeRateQuote;
