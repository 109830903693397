import questionFlowerImg from "assets/question-flower.svg";
import { useChatWidget } from "components/ChatWidget";
import { FC, ReactNode } from "react";
import AccentCard from "ui/data-display/AccentCard";
import Button from "ui/inputs/Button";
import { Heading4, Paragraph } from "ui/typography";

type Props = {
  title?: ReactNode;
  description?: ReactNode;
};

const TransactionFlexpaneDispute: FC<Props> = ({
  title = "Don’t recognize this transaction?",
  description = "If you don’t recognize this transaction or believe it is fraudulent, report it to us and we’ll be able to lodge a dispute for you.",
}) => {
  const chat = useChatWidget();

  return (
    <>
      <AccentCard className="flex flex-col gap-y-5">
        <img src={questionFlowerImg} alt="?" className="size-10" />

        <div className="flex flex-col gap-y-1">
          <Heading4 className="text-sm text-grey-900">{title}</Heading4>
          <Paragraph className="text-sm text-grey-600">{description}</Paragraph>
        </div>

        <Button variant="tertiary" onClick={() => chat.show()} className="w-fit">
          Contact support
        </Button>
      </AccentCard>
    </>
  );
};

export default TransactionFlexpaneDispute;
