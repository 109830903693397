import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import usePaymentApprovalsQueryOptions from "modules/payment-approvals/queries/usePaymentApprovalsQueryOptions";
import { useSetRecoilState } from "recoil";
import PaymentDetailCreatorRep from "reps/payments-v2/PaymentDetailCreatorRep";
import paymentIdempotencyKeyState from "state/payments/paymentIdempotencyKey";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { v4 as uuidv4 } from "uuid";

import currentStepState, { Step } from "../state/currentStepState";
import isTransferErrorState from "../state/isTransferErrorState";

type Variables = {
  data: PaymentDetailCreatorRep.Creator;
  send: boolean;
};

const useCreatePaymentMutation = () => {
  const highbeamApi = useHighbeamApi();
  const setCurrentStepState = useSetRecoilState(currentStepState);
  const { mfa } = useMfa();
  const refreshPaymentApprovalsQuery = useRefreshQuery(
    usePaymentApprovalsQueryOptions("Open").queryKey
  );
  const setPaymentIdempotencyKey = useSetRecoilState(paymentIdempotencyKeyState);
  const setIsTransferError = useSetRecoilState(isTransferErrorState);
  const handlePaymentFailure = () => {
    setPaymentIdempotencyKey(uuidv4());
    setIsTransferError(true);
    notify("warning", "There was an issue sending the payment. Please contact support.");
  };

  const { segmentTrack } = useSegment();

  return useMutation({
    mutationFn: async ({ data, send }: Variables) => {
      await mfa();
      return highbeamApi.paymentV2.create({
        send: send,
        detail: data,
      });
    },
    onError: () => {
      handlePaymentFailure();
    },
    onSuccess: (payment, { send }) => {
      if (payment.status === "Failed" || payment.status === "Rejected") {
        handlePaymentFailure();
      } else {
        setCurrentStepState(Step.TRANSFER_SCHEDULED);
      }
      if (!send) {
        segmentTrack(SEGMENT_EVENTS.PAYMENT_APPROVAL_CREATED);
      }
      refreshPaymentApprovalsQuery();
    },
  });
};

export default useCreatePaymentMutation;
