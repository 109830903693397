import chargeCardProgramQueryHooks, {
  ChargeCardProgram,
} from "modules/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import StatementCycleExplanation from "modules/charge-cards/components/StatementCycleExplanation";
import getChargeCardRepaymentTermsCopy from "modules/charge-cards/utils/getChargeCardRepaymentTermsCopy";
import { FC, ReactNode } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import MetadataList from "ui/data-display/MetadataList";
import Shimmer from "ui/feedback/ShimmerV2";
import IconWithTooltip from "ui/overlay/IconWithTooltip";

import ChargeCardCashBackInfoIconWithTooltip from "./ChargeCardCashBackInfoIconWithTooltip";

type Props = {
  chargeCardProgram: ChargeCardProgram;
  className?: string;
};

const ChargeCardCapitalAccountMetadataList: FC<Props> = ({ chargeCardProgram, className }) => {
  return (
    <MetadataList className={className}>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Repayment terms</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          {getChargeCardRepaymentTermsCopy(chargeCardProgram.maxRepaymentDays)}
          <IconWithTooltip
            tooltip={<StatementCycleExplanation creditTerms={chargeCardProgram.creditTerms} />}
          />
        </MetadataList.ItemValue>
      </MetadataList.Item>

      {chargeCardProgram.maxCashback > 0 && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Cash back</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            Up to {chargeCardProgram.maxCashback}%
            <ChargeCardCashBackInfoIconWithTooltip
              chargeCardProgram={chargeCardProgram}
              color="primary"
            />
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </MetadataList>
  );
};

export default ChargeCardCapitalAccountMetadataList;

const ChargeCardCapitalAccountMetadataListLoading: FC<{ className?: string }> = ({ className }) => {
  return (
    <MetadataList className={className}>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Repayment terms</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <Shimmer />
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  );
};

// Wrappers

type ChargeCardCapitalAccountMetadataListByCapitalAccountGuidProps = {
  capitalAccountGuid: string;
  capitalAccountState: CapitalAccountRep.State;
  notFoundFallback?: ReactNode;
};

export const ChargeCardCapitalAccountMetadataListByCapitalAccountGuid: FC<
  ChargeCardCapitalAccountMetadataListByCapitalAccountGuidProps
> = ({ capitalAccountGuid, capitalAccountState, notFoundFallback = null }) => {
  const { data: chargeCardProgram, isLoading } = chargeCardProgramQueryHooks.useQuery({
    capitalAccountGuid,
    state: capitalAccountState,
  });

  if (isLoading) {
    return <ChargeCardCapitalAccountMetadataListLoading />;
  }

  if (!chargeCardProgram) {
    return <>{notFoundFallback}</>;
  }

  return <ChargeCardCapitalAccountMetadataList chargeCardProgram={chargeCardProgram} />;
};
