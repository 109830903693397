import { useSuspenseQuery } from "@tanstack/react-query";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";

import useUserQueryOptions from "./useUserQueryOptions";

const useUser = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const userGuid = useUserGuid(params);
  const userQueryOptions = useUserQueryOptions(userGuid ?? "");

  const { data } = useSuspenseQuery({
    queryKey: userQueryOptions.queryKey,
    queryFn: userGuid ? userQueryOptions.queryFn : () => null, // Just return `null` if there isn't a `userGuid`.
  });

  return data as DataRequired<typeof data, TRequired>;
};

export default useUser;
