import { Coins } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import RequestAdditionalCapitalButton from "modules/capital-accounts/components/RequestAdditionalCapitalButton";
import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useIsCapitalOverviewPageEnabled from "modules/capital/queries/useIsCapitalOverviewPageEnabled";
import { Navigate } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

// TODO(alex): We will move this view within the `CapitalOverviewPage` when or before rolling out `CAPITAL_OVERVIEW_PAGE`.
import UpsellCapitalView from "../CapitalAccountPage/views/UpsellCapitalView";

import CapitalAccountsList from "./CapitalAccountsList";

const CapitalOverviewContent = () => {
  const isCapitalOverviewPageEnabled = useIsCapitalOverviewPageEnabled();
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  if (nonTerminatedCapitalAccounts.length === 0) {
    return <UpsellCapitalView />;
  }

  // NB(alex): We can delete this redirect logic once we fully roll out the `CAPITAL_OVERVIEW_PAGE` flag.
  if (!isCapitalOverviewPageEnabled && nonTerminatedCapitalAccounts.length === 1) {
    return <Navigate to={`/capital/${nonTerminatedCapitalAccounts[0].guid}`} />;
  }

  return (
    <>
      <DashboardPage.Header actions={<RequestAdditionalCapitalButton />}>
        <DashboardPage.Header.IconTile icon={<Coins />} />
        <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <CapitalAccountsList />
      </DashboardPage.Section>
    </>
  );
};

const CapitalOverviewPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Capital</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CapitalOverviewContent />
      </DashboardPage>
    </>
  );
};

export default CapitalOverviewPage;
