import { FC, PropsWithChildren, ReactNode } from "react";
import IconWithTooltip from "ui/overlay/IconWithTooltip";

type Props = PropsWithChildren<{
  tooltip: ReactNode;
}>;

const WarningCellDecorator: FC<Props> = ({ children, tooltip }) => (
  <span className="inline-flex items-center gap-1">
    {children}
    <IconWithTooltip icon="warning" color="warning" tooltip={tooltip} />
  </span>
);

export default WarningCellDecorator;
