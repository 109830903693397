import { Card } from "@highbeam/unit-node-sdk";
import HighbeamCard from "components/HighbeamCard";
import DashboardPage from "layouts/DashboardPage";
import { EmptyCardPlaceholder } from "modules/cards/components/CardPlaceholder";
import CardTabs, { useCardTabsSearchParamState } from "modules/cards/components/CardTabs";
import cardsQueryHooks from "modules/cards/queries/cardsQueryHooks";
import {
  checkIsPhysicalCreditCard,
  checkIsPhysicalDebitCard,
  checkIsVirtualChargeCard,
  checkIsVirtualDebitCard,
} from "modules/cards/utils/typeguards";
import { Link } from "react-router-dom";
import Divider from "ui/data-display/Divider";
import Shimmer from "ui/feedback/ShimmerV2";
import { Heading3, Span } from "ui/typography";
import { pluralize } from "utils/string";

import { cardFilterByTab, cardsSectionClasses, CardsSectionSuspenseFallback } from "../CardsPage";

const ArchivedCardsLists = () => {
  const [activeTab, setActiveTab] = useCardTabsSearchParamState();

  const { data: archivedCards = [], isLoading } = cardsQueryHooks.useQuery({
    status: "closed",
    select: (data) => data.filter((card) => cardFilterByTab(activeTab, card)),
  });

  return (
    <section className="p-5 pb-20 tablet:p-0">
      <section>
        <CardTabs activeTab={activeTab} setActiveTab={setActiveTab} className="mb-8 mt-3 w-max" />
      </section>
      <VirtualCardsSection cards={archivedCards} isLoading={isLoading} />
      <Divider />
      <PhysicalCardsSection cards={archivedCards} isLoading={isLoading} />
    </section>
  );
};

type CardsSectionProps = {
  cards: Card[];
  isLoading: boolean;
};

const VirtualCardsSection = ({ cards, isLoading }: CardsSectionProps) => {
  const virtualCards = cards.filter((card) => {
    if (checkIsVirtualChargeCard(card)) return true;
    if (checkIsVirtualDebitCard(card)) return true;

    return false;
  });

  const showEmptyVirtualCardPlaceholder = virtualCards.length === 0;
  return (
    <section>
      <div className="mb-6 flex items-center gap-x-5">
        <Heading3>Virtual cards</Heading3>
        {isLoading ? (
          <Shimmer className="h-3 w-32" />
        ) : (
          <Span className="text-sm">{pluralize(virtualCards.length, "card")}</Span>
        )}
      </div>

      {isLoading && <CardsSectionSuspenseFallback />}

      {!isLoading && (
        <DashboardPage.Section.Body
          className={cardsSectionClasses}
          suspenseFallback={<CardsSectionSuspenseFallback />}
        >
          {virtualCards.map((card) => (
            <Link key={card.id} to={`/cards/${card.id}`}>
              <HighbeamCard card={card} clickable />
            </Link>
          ))}
          {showEmptyVirtualCardPlaceholder && <EmptyCardPlaceholder heading="No archived cards" />}
        </DashboardPage.Section.Body>
      )}
    </section>
  );
};

const PhysicalCardsSection = ({ cards, isLoading }: CardsSectionProps) => {
  const physicalCards = cards.filter((card) => {
    if (checkIsPhysicalDebitCard(card)) return true;
    if (checkIsPhysicalCreditCard(card)) return true;

    return false;
  });

  const showEmptyPhysicalCardPlaceholder = physicalCards.length === 0;

  return (
    <section>
      <div className="mb-6 flex items-center gap-x-5">
        <Heading3>Physical cards</Heading3>
        {isLoading ? (
          <Shimmer className="h-3 w-32" />
        ) : (
          <Span className="text-sm">{pluralize(physicalCards.length, "card")}</Span>
        )}
      </div>

      {isLoading && <CardsSectionSuspenseFallback />}

      {!isLoading && (
        <DashboardPage.Section.Body
          className={cardsSectionClasses}
          suspenseFallback={<CardsSectionSuspenseFallback />}
        >
          {physicalCards.map((card) => (
            <Link key={card.id} to={`/cards/${card.id}`}>
              <HighbeamCard card={card} clickable />
            </Link>
          ))}

          {showEmptyPhysicalCardPlaceholder && <EmptyCardPlaceholder heading="No archived cards" />}
        </DashboardPage.Section.Body>
      )}
    </section>
  );
};

export default ArchivedCardsLists;
