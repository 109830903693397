import { useQueryClient } from "@tanstack/react-query";
import { KNOWLEDGE_BASE_ITEMS_QUERY_KEY } from "modules/chat/queries/useKnowledgeBaseItems";
import { KnowledgeBaseItemRep, KnowledgeBaseItemCreatorRep } from "reps/chat/KnowledgeBaseItemRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useCreateKnowledgeBaseItemMutation = (
  additionalOptions: MutationAdditionalOptions<KnowledgeBaseItemRep, KnowledgeBaseItemCreatorRep>
) => {
  const queryClient = useQueryClient();
  const highbeamApi = useHighbeamApi();

  return useMutationWithDefaults(
    {
      mutationFn: async (creator) => {
        return await highbeamApi.knowledgeBaseItem.create(creator);
      },
      onSuccess: async (knowledgeBaseItem) => {
        await queryClient.invalidateQueries({
          queryKey: [
            KNOWLEDGE_BASE_ITEMS_QUERY_KEY,
            { businessGuid: knowledgeBaseItem.businessGuid },
          ],
        });
      },
    },
    additionalOptions
  );
};

export default useCreateKnowledgeBaseItemMutation;
