/**
 * 🚧 Experimental
 *
 * Inspired by:
 * - https://v2.chakra-ui.com/docs/components/box
 * - https://mui.com/material-ui/react-box/
 */

import { CSSProperties, forwardRef, ForwardRefRenderFunction, HTMLAttributes } from "react";
import cn from "utils/tailwind/cn";

export type BoxElementProps = HTMLAttributes<HTMLElement>;

// NB(alex): It would be nice to make this a generic `extends keyof JSX.IntrinsicElements`, but I haven't been able to get it to work yet.
type BoxProps = BoxElementProps & {
  as?: "div" | "article" | "aside" | "nav" | "main" | "section" | "header" | "footer";
  baseClassName?: string;
  baseStyle?: CSSProperties;
};

/**
 * A generic structural element. Useful for creating layout components with extendable `className` or `style` props.
 */
const Box: ForwardRefRenderFunction<
  HTMLDivElement, // NB(alex): I want to pass in `HTMLElement` but this causes a typescript error. Lmk / feel free to fix if there's a better way to do this!
  BoxProps
> = ({ as: Element = "div", baseClassName, baseStyle, className, style, ...elementProps }, ref) => {
  return (
    <Element
      className={cn(baseClassName, className)}
      style={{ ...baseStyle, ...style }}
      {...elementProps}
      ref={ref}
    />
  );
};

export default forwardRef(Box);
