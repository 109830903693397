import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const AiChatTableHead: React.FC<Props> = ({ children }) => {
  return <thead>{children}</thead>;
};

export default AiChatTableHead;
