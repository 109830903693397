import { useRefreshBillApprovalsQuery } from "modules/bill-approvals/queries/useBillApprovals";
import { useRefreshBillQuery } from "modules/bills/queries/useBill";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useDeleteBillApprovalMutation = (
  billApprovalId: string,
  billId: string,
  additionalOptions?: MutationAdditionalOptions<void, void>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillApprovalsQuery = useRefreshBillApprovalsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        await highbeamApi.billApproval.delete(billApprovalId);
      },
      onSuccess: async () => {
        // Invalidate the relevant bill summary (as well as the bill approvals)
        // since the bill summary will now have a new requested approval count.
        await Promise.all([refreshBillQuery(), refreshBillApprovalsQuery()]);
      },
      onError: (error) => {
        notify("error", error.message ?? "Something went wrong! Please try again.");
      },
    },
    additionalOptions || {}
  );
};

export default useDeleteBillApprovalMutation;
