import useBusiness from "modules/business/queries/useBusiness";
import getBusinessSafeDisplayName from "modules/business/utils/getBusinessSafeDisplayName";
import React from "react";
import { Control, Controller } from "react-hook-form";
import Switch from "ui/inputs/Switch";
import { Paragraph, Span } from "ui/typography";
import cn from "utils/tailwind/cn";

import ChatSettingsItem from "./ChatSettingsItem";
import { ChatConfigSettingsFormInputs } from "./useChatConfigSettingsForm";

type Props = {
  control: Control<ChatConfigSettingsFormInputs>;
};

const ChatSettingsIsEnabledItem: React.FC<Props> = ({ control }) => {
  const business = useBusiness();

  const businessDisplayName = getBusinessSafeDisplayName(business);

  return (
    <ChatSettingsItem name="Enabled">
      <Controller
        name="isEnabled"
        control={control}
        render={({ field }) => (
          <>
            <Paragraph className="text-sm">
              AI Chat is currently{" "}
              <Span className={cn("font-bold", field.value ? "text-green-600" : "text-yellow-700")}>
                {field.value ? "Enabled" : "Disabled"}
              </Span>{" "}
              for <Span className="font-bold">{businessDisplayName}</Span>.{" "}
              {field.value
                ? "Users from the business will be able to use AI Chat."
                : "Only Highbeam superusers can use AI Chat."}
            </Paragraph>
            <Switch {...field} />
          </>
        )}
      />
    </ChatSettingsItem>
  );
};

export default ChatSettingsIsEnabledItem;
