import { FC } from "react";
import ButtonLink, { ButtonLinkProps } from "ui/inputs/ButtonLink";

type Props = ButtonLinkProps & {
  isSuperuserOnly?: boolean;
};

const SkipButtonLink: FC<Props> = ({ children = "Skip", isSuperuserOnly, ...buttonV2Props }) => {
  return (
    <ButtonLink variant="ghost" {...buttonV2Props}>
      {isSuperuserOnly && "[SUPERUSER] "}
      {children}
    </ButtonLink>
  );
};

export default SkipButtonLink;
