import { useQuery } from "@tanstack/react-query";
import useInternationalWireQuoteQueryOptions from "modules/international-wires/queries/useInternationalWireQuoteQueryOptions";
import useUpdatePayeeMutation from "modules/payees/mutations/useUpdatePayeeMutation";
import useApprovePaymentMutation from "modules/payment-approvals/mutations/useApprovePaymentMutation";
import usePaymentApproval from "modules/payment-approvals/queries/usePaymentApproval";
import { FC } from "react";
import { ConversionSide } from "reps/payments-v2/ConversionSide";
import Button, { ButtonProps } from "ui/inputs/Button";
import { startOfBankingDay } from "utils/date";

import { usePaymentApprovalFlexpaneContext } from "../../context/PaymentApprovalFlexpaneProvider";

const BaseApprovePaymentButton: FC<ButtonProps> = (props) => {
  return (
    <Button variant="primary" {...props}>
      Approve and send
    </Button>
  );
};

type ApproveUsdToUsdButtonProps = {
  amountBeforeSwiftFeeInCents: number;
};

const ApproveUsdToUsdButton: FC<ApproveUsdToUsdButtonProps> = ({ amountBeforeSwiftFeeInCents }) => {
  const { mutateAsync: approvePayment, isPending } = useApprovePaymentMutation();

  const { paymentApprovalGuid, close: closePaymentApprovalFlexpane } =
    usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });

  const { mutate: updatePayee } = useUpdatePayeeMutation();

  return (
    <BaseApprovePaymentButton
      isLoading={isPending}
      onClick={async () => {
        // NB(alex): for international USD to USD payments, we do not have to add the SWIFT fee. This is subject to change in the future.
        await approvePayment({
          paymentGuid: paymentApproval.guid,
          InternationalWire: {
            sendAmount: amountBeforeSwiftFeeInCents,
          },
        });

        closePaymentApprovalFlexpane();

        updatePayee({
          payeeGuid: paymentApproval.payeeGuid,
          lastTransferAmount: amountBeforeSwiftFeeInCents,
          lastTransferAt: startOfBankingDay().toISOString(),
        });
      }}
    />
  );
};

type ApproveInternationalWireButtonProps = {
  fixedSide: ConversionSide;
  sendAmount: number;
  receiveAmount: number;
  receiveCurrency: string; // TODO(alex): string union
};

const ApproveInternationalWireButton: FC<ApproveInternationalWireButtonProps> = ({
  fixedSide,
  receiveAmount,
  receiveCurrency,
  sendAmount,
}) => {
  const { data: quote } = useQuery(useInternationalWireQuoteQueryOptions(receiveCurrency));

  const { mutateAsync: approvePayment, isPending } = useApprovePaymentMutation();
  const { mutate: updatePayee } = useUpdatePayeeMutation();

  const { paymentApprovalGuid, close: closePaymentApprovalFlexpane } =
    usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });

  return (
    <BaseApprovePaymentButton
      isLoading={isPending}
      disabled={!quote}
      onClick={async () => {
        // NB(alex): button is disabled if quote not loaded
        if (!quote) return;

        if (fixedSide === "Send") {
          await approvePayment({
            paymentGuid: paymentApproval.guid,
            InternationalWire: {
              receiveAmount: quote.rate * sendAmount,
            },
          });
        } else {
          await approvePayment({
            paymentGuid: paymentApproval.guid,
            InternationalWire: {
              sendAmount: quote.inverse * receiveAmount,
            },
          });
        }

        closePaymentApprovalFlexpane();

        updatePayee({
          payeeGuid: paymentApproval.payeeGuid,
          lastTransferAmount: fixedSide === "Send" ? sendAmount : quote.inverse * receiveAmount,
          lastTransferAt: startOfBankingDay().toISOString(),
        });
      }}
    />
  );
};

const ApproveUnitPaymentButton = () => {
  const { mutateAsync: approvePayment, isPending } = useApprovePaymentMutation();
  const { mutate: updatePayee } = useUpdatePayeeMutation();

  const {
    paymentApprovalGuid,
    isApproveDisabled,
    close: closePaymentApprovalFlexpane,
  } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });

  return (
    <BaseApprovePaymentButton
      isLoading={isPending}
      disabled={isApproveDisabled}
      onClick={async () => {
        await approvePayment({
          paymentGuid: paymentApproval.guid,
          UnitPayment: {},
        });

        closePaymentApprovalFlexpane();

        updatePayee({
          payeeGuid: paymentApproval.payeeGuid,
          lastTransferAmount: paymentApproval.amount,
          lastTransferAt: startOfBankingDay().toISOString(),
        });
      }}
    />
  );
};

const ApprovePaymentButton: FC = () => {
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });

  switch (paymentApproval.type) {
    case "international-wire":
      if (paymentApproval.receiveCurrency === "USD") {
        return <ApproveUsdToUsdButton amountBeforeSwiftFeeInCents={paymentApproval.amount} />;
      } else {
        return (
          <ApproveInternationalWireButton
            sendAmount={paymentApproval.amount}
            {...paymentApproval}
          />
        );
      }
    case "unit-payment":
      return <ApproveUnitPaymentButton />;
  }
};

export default ApprovePaymentButton;
