import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { ROOT_CHARGE_CARD_ACCOUNT_QUERY_KEY } from "./chargeCardAccountQueryHooks";

type Params = {
  unitCoCreditAccountId: string;
};

const chargeCardAccountByUnitCoCreditAccountIdQueryHooks = makeQueryHooks({
  rootName: ROOT_CHARGE_CARD_ACCOUNT_QUERY_KEY,
  name: "chargeCardAccountByUnitCoCreditAccountId",
  useQueryVariables: ({ unitCoCreditAccountId }: Params) => {
    const businessGuid = useBusinessGuid();
    return { businessGuid, unitCoCreditAccountId };
  },
  useQueryFnMaker: ({ unitCoCreditAccountId }) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.chargeCardAccount.getByUnitCoCreditAccountId(unitCoCreditAccountId);
    };
  },
});

export default chargeCardAccountByUnitCoCreditAccountIdQueryHooks;
