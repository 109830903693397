import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import { BankAccountBarByUnitCoDepositAccountId } from "modules/bank-accounts/components/BankAccountBar";
import Flexpane from "ui/overlay/Flexpane";

import { useReceivedPaymentInfoFlexpaneContext } from "../../context/ReceivedPaymentInfoFlexpaneProvider";

import CounterpartyDetail from "./CounterpartyDetail";
import PaymentDetailBubble from "./ReceivedPaymentDetailBubble";

const ReceivedPaymentInfoFlexpaneToFromSection = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();
  const positive = receivedPayment.attributes.direction === "Credit";

  const bankAccountDetail = (
    <BankAccountBarByUnitCoDepositAccountId
      unitCoDepositAccountId={receivedPayment.relationships.account.data.id}
    />
  );

  return (
    <Flexpane.Section>
      <TransactionFlexpaneParties
        from={positive ? <CounterpartyDetail /> : bankAccountDetail}
        status={<PaymentDetailBubble />}
        to={positive ? bankAccountDetail : <CounterpartyDetail />}
      />
    </Flexpane.Section>
  );
};

export default ReceivedPaymentInfoFlexpaneToFromSection;
