import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useNewCreditApplication from "./useNewCreditApplication";

const useCreditApplicationQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const newCreditApplication = useNewCreditApplication();
  const creditApplicationGuid = newCreditApplication.guid;

  return useQueryOptions({
    queryKey: ["creditApplication", { businessGuid, creditApplicationGuid }],
    queryFn: async () =>
      await highbeamApi.creditApplication.get(businessGuid, creditApplicationGuid),
  });
};

export default useCreditApplicationQueryOptions;
