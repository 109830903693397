import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

/**
 * How long a Plaid link token is valid after creation.
 *
 * The real time is 4 hours for new connections, and 30 minutes for updates,
 * but to keep things simple we just use an amount under the lower bound.
 *
 * https://plaid.com/docs/api/link/#link-token-create-response-expiration
 */
const PLAID_LINK_TOKEN_EXPIRATION_MINS = 25;

/**
 * The redirect URL used in OAuth flows.
 *
 * This value must be allowlisted for all hosts at https://dashboard.plaid.com/developers/api
 * and must be bound in a route to handle the Plaid oauth link reinitialization.
 *
 * See https://plaid.com/docs/link/oauth/
 */
export const PLAID_OAUTH_REDIRECT_URL = "/plaid-oauth";

type Params = {
  existingConnectionGuid?: string;
};

const plaidLinkTokenQueryHooks = makeQueryHooks({
  name: "plaidLinkToken",
  useQueryVariables: (params: Params) => {
    const userGuid = useUserGuid();
    const businessGuid = useBusinessGuid();
    return { userGuid, businessGuid, existingConnectionGuid: params.existingConnectionGuid };
  },
  useQueryFnMaker: ({ businessGuid, userGuid, existingConnectionGuid }) => {
    const highbeamApi = useHighbeamApi();
    return async () => {
      const { linkToken } = await highbeamApi.plaid.createLinkToken({
        businessGuid,
        userGuid,
        connectionGuid: existingConnectionGuid,
        redirectUrl: window.location.origin + PLAID_OAUTH_REDIRECT_URL,
      });
      return linkToken;
    };
  },
});

export default plaidLinkTokenQueryHooks;

// Hooks

export const usePlaidLinkToken = (params: Params) => {
  return plaidLinkTokenQueryHooks.useData({
    ...params,
    staleTime: PLAID_LINK_TOKEN_EXPIRATION_MINS * 60 * 1000,
  });
};
