import { useQueryClient } from "@tanstack/react-query";
import { CONVERSATIONS_QUERY_KEY } from "modules/chat/queries/useConversation";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { ConversationRep } from "reps/chat/ConversationRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useCreateConversationMutation = (
  additionalOptions: MutationAdditionalOptions<ConversationRep, void>
) => {
  const queryClient = useQueryClient();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        return await highbeamApi.conversation.create({
          businessGuid,
          userGuid,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [CONVERSATIONS_QUERY_KEY, { businessGuid, userGuid }],
        });
      },
    },
    additionalOptions
  );
};

export default useCreateConversationMutation;
