import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { useNavigate } from "react-router-dom";
import PayeeRep from "reps/PayeeRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useIsAllowedToDeletePayees from "utils/permissions/useIsAllowedToDeletePayees";

const useDeletePayeeMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const isAllowedToDeletePayee = useIsAllowedToDeletePayees();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payee: PayeeRep.Complete) => {
      if (!isAllowedToDeletePayee) {
        throw new Error("Not allowed to delete payee");
      }
      return highbeamApi.payee.delete(businessGuid, payee.guid);
    },
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: () => {
      notify("success", "Payee removed");
      navigate("/payments/payees");
    },
  });
};

export default useDeletePayeeMutation;
