import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import AccountingCategorySelect from "modules/accounting-accounts/components/AccountingCategorySelect";
import useAccountingAccounts from "modules/accounting-accounts/queries/useAccountingAccounts";
import {
  BANK_ACCOUNT_TYPE,
  CREDIT_CARD_ACCOUNT_TYPE,
} from "modules/accounting-accounts/utils/config";
import { useApSettings } from "modules/ap-settings/queries/apSettingsQueryHooks";
import { FC, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import AccountingAccountRep from "reps/AccountingAccountRep";
import BillSummaryRep from "reps/BillSummaryRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { notify } from "ui/feedback/Toast";
import DatePicker from "ui/inputs/DatePicker";
import Helper from "ui/inputs/Helper";
import MoneyInputs from "ui/inputs/MoneyInputs";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import { parseMoneyFloat } from "utils/money";
import { z } from "zod";

import useMarkBillAsPaidMutation from "../../mutations/useMarkBillAsPaidMutation";
import useBill from "../../queries/useBill";

const makeMarkBillAsPaidFormSchema = (
  bill: BillSummaryRep.Complete,
  accountingSyncEnabled: boolean
) =>
  z.object({
    amountAmount: z
      .string()
      .refine((value) => {
        const parsedValue = parseMoneyFloat(value);
        if (isNaN(parsedValue)) {
          return false;
        }
        return parsedValue >= 0;
      }, "Please enter an amount greater than or equal to 0.")
      .refine((value) => {
        if (!bill.remainingAmount) {
          return true;
        }
        const parsedValue = parseMoneyFloat(value);
        if (isNaN(parsedValue)) {
          return false;
        }
        return parsedValue <= parseMoneyFloat(bill.remainingAmount.amount);
      }, "Please enter an amount less than or equal to the bill's amount."),
    recordedPaymentDate: z.custom<Dayjs>(),
    accountingAccountId: z
      .string()
      .nullable()
      .refine(
        (value) => (accountingSyncEnabled ? value !== null : true),
        "Please select an accounting category."
      ),
    memo: z.string(),
  });

type MarkBillAsPaidFormInputs = z.infer<ReturnType<typeof makeMarkBillAsPaidFormSchema>>;

type MarkBillAsPaidModalContentProps = {
  billId: string;
  onClose: () => void;
  onSuccess: () => void;
};

const MarkBillAsPaidModalContent: FC<MarkBillAsPaidModalContentProps> = ({ billId, onSuccess }) => {
  const isSuperusering = useIsSuperusering();

  const navigate = useNavigate();
  const location = useLocation();

  const bill = useBill(billId, { required: true });
  const inferredCurrency = bill.amount?.currency ?? "USD";
  const { accountingSyncEnabled } = useApSettings();
  const accountingAccounts = useAccountingAccounts();
  const hasAccountingAccounts = accountingAccounts.length > 0;

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<MarkBillAsPaidFormInputs>({
    resolver: zodResolver(makeMarkBillAsPaidFormSchema(bill, accountingSyncEnabled)),
    mode: "onChange",
    defaultValues: {
      amountAmount: bill.remainingAmount?.amount ?? "",
      recordedPaymentDate: dayjs(),
      accountingAccountId: null,
      memo: "",
    },
  });

  const { mutate: markBillAsPaid, isPending: isMarkingBillAsPaid } = useMarkBillAsPaidMutation(
    billId,
    {
      onSuccess: () => {
        notify("success", "Bill marked as paid", {
          action: {
            text: "View bill",
            onClick: () => {
              navigate(`/bills/${billId}${location.search}`);
            },
          },
        });
        onSuccess();
      },
      onError: (error) => {
        notify("error", error.message ?? "Failed to mark bill as paid");
      },
    }
  );

  const onSubmit = useCallback(
    (data: MarkBillAsPaidFormInputs) => {
      markBillAsPaid({
        amount: {
          amount: data.amountAmount,
          currency: inferredCurrency,
        },
        method: {
          accountingAccountId: data.accountingAccountId,
          recordedPaymentDate: data.recordedPaymentDate.format("YYYY-MM-DD"),
        },
        memo: data.memo,
      });
    },
    [markBillAsPaid, inferredCurrency]
  );

  const accountingAccountIdFilter = useCallback(
    (accountingAccount: AccountingAccountRep.Complete) => {
      const { accountType } = accountingAccount;
      return accountType === BANK_ACCOUNT_TYPE || accountType === CREDIT_CARD_ACCOUNT_TYPE;
    },
    []
  );

  return (
    <ModalV4.Form onSubmit={handleSubmit(onSubmit)}>
      <ModalV4.Header>Mark bill as paid</ModalV4.Header>
      <ModalV4.Body className="flex flex-col gap-4">
        <div>
          <Controller
            name="amountAmount"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <MoneyInputs.AmountInput
                  isStandalone
                  label="Payment amount"
                  currency={inferredCurrency}
                  showErrorOutline={Boolean(fieldState.error)}
                  {...field}
                />
                {fieldState.error && (
                  <Helper iconVariant="error">{fieldState.error.message}</Helper>
                )}
              </>
            )}
          />
        </div>
        <div>
          <Controller
            name="recordedPaymentDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Payment date"
                disabled={field.disabled}
                value={field.value ? field.value.toDate() : null}
                onChange={(selected) => {
                  field.onChange(selected ? dayjs(selected) : selected);
                }}
                className="leading-4" // NB(lev): DatePicker label/input is optically misaligned without this leading set.
                variant="no-date"
                onCalendarClose={() => {
                  field.onBlur();
                }}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="accountingAccountId"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <AccountingCategorySelect
                  filter={accountingAccountIdFilter}
                  labelText={
                    accountingSyncEnabled ? "Accounting category" : "Accounting category (optional)"
                  }
                  noAccountsDisplayVariant="contact-support"
                  disabled={field.disabled}
                  value={field.value}
                  onValueChange={field.onChange}
                  clearable={!accountingSyncEnabled}
                  showErrorOutline={Boolean(fieldState.error)}
                />
                {fieldState.error && (
                  <Helper iconVariant="error">{fieldState.error.message}</Helper>
                )}
                {(!accountingSyncEnabled || !hasAccountingAccounts) && (
                  <ModalV4.Paragraph className="mt-2 text-sm">
                    Please contact support to connect your accounting software to Highbeam Bill Pay.
                  </ModalV4.Paragraph>
                )}
              </>
            )}
          />
        </div>
        <div>
          <Controller
            name="memo"
            control={control}
            render={({ field }) => <TextInputV2 label="Notes (optional)" {...field} />}
          />
        </div>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton
          disabled={isSuperusering || !isValid}
          isLoading={isMarkingBillAsPaid}
          tooltip={isSuperusering ? "Cannot mark bills as paid as a superuser." : null}
        >
          Mark as paid
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton>Close</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = MarkBillAsPaidModalContentProps;

const MarkBillAsPaidModal: FC<Props> = (props) => (
  <ModalV4 onClose={props.onClose}>
    <MarkBillAsPaidModalContent {...props} />
  </ModalV4>
);

export default MarkBillAsPaidModal;
