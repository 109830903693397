import env from "env";
import useBusiness from "modules/business/queries/useBusiness";
import { useRefreshAllBusinessesQueries } from "modules/businesses/queries/businessesQueryHooks";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import Text from "ui/typography/Text";

import styles from "./BusinessSwitcherTopRow.module.scss";

const BUSINESS_SWITCHER_TOP_ROW_ID = "business-switcher-top-row";

const BusinessSwitcherTopRow = () => {
  const business = useBusiness();
  const refreshBusinessesQuery = useRefreshAllBusinessesQueries();
  const superblocksUrl = `${env.SUPERBLOCKS_BUSINESS_LOOKUP_APP_LINK}?businessGuid=${business.guid}`;
  const unitAdminUrl = `${env.UNIT_APP_LINK}/customers/${business.unitCoCustomerId}`;

  return (
    <div className={styles.container} id={BUSINESS_SWITCHER_TOP_ROW_ID}>
      <div className={styles.currentBusinessInfoContainer}>
        <Text size={16} weight="medium">
          {business.internalName}
        </Text>
        <ItemWithCopyTextTooltip textToCopy={business.guid}>
          <Text size={16}>{"guid: " + business.guid}</Text>
        </ItemWithCopyTextTooltip>
      </div>
      <ButtonLink variant="tertiary" to={superblocksUrl} target="_blank">
        Superblocks
      </ButtonLink>
      <ButtonLink variant="tertiary" to={unitAdminUrl} target="_blank">
        Unit
      </ButtonLink>
      <Button variant="tertiary" onClick={() => refreshBusinessesQuery()}>
        Refresh
      </Button>
    </div>
  );
};
export default BusinessSwitcherTopRow;
