import { Authorization, AuthorizationStatus } from "@highbeam/unit-node-sdk";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import TransactionFlexpaneStatus from "components/common/transaction-flexpane/TransactionFlexpaneStatus";
import { CardAvatarBarByCardId } from "modules/cards/components/CardAvatarBar";
import { FC } from "react";
import { PillColor } from "ui/data-display/Pill";
import { Span } from "ui/typography";
import variants from "utils/ts/variants";

type Props = {
  authorization: Authorization;
};

const CardAuthorizationFlexpaneParties: FC<Props> = ({ authorization }) => {
  return (
    <TransactionFlexpaneParties
      from={
        <CardAvatarBarByCardId
          cardId={authorization.relationships.card.data.id}
          showTypeLabel={false}
          showIcon={false}
        />
      }
      status={
        <TransactionFlexpaneStatus
          pillColor={variants(authorization.attributes.status, {
            Authorized: "grey-100",
            Completed: "green-100",
            Canceled: "grey-100",
            Declined: "red-200",
          } satisfies { [key in AuthorizationStatus]: PillColor })}
          status={variants(authorization.attributes.status, {
            Authorized: "Pending",
            Completed: "Completed",
            Canceled: "Canceled",
            Declined: "Declined",
          } satisfies { [key in AuthorizationStatus]: string })}
          methodName="Card transaction"
        />
      }
      to={<Span className="text-sm font-medium">{authorization.attributes.merchant.name}</Span>}
    />
  );
};

export default CardAuthorizationFlexpaneParties;
