import React, { ReactNode } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  children: ReactNode;
  className?: string;
};

const AiChatTableRow: React.FC<Props> = ({ children, className }) => {
  return <tr className={cn("border-0 border-solid border-grey-100", className)}>{children}</tr>;
};

export default AiChatTableRow;
