import chargeCardProgramQueryHooks from "modules/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import ChargeCardCashBackInfoIconWithTooltip from "modules/capital-accounts/components/ChargeCardCashBackInfoIconWithTooltip";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardOfferRepaymentPeriodContent from "modules/charge-cards/components/ChargeCardOfferRepaymentPeriodContent";
import StatementCycleExplanation from "modules/charge-cards/components/StatementCycleExplanation";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import IconWithTooltip from "ui/overlay/IconWithTooltip";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardSummaryItems = ({ capitalAccountSummaryWithChargeCard }: Props) => {
  const chargeCardProgram = chargeCardProgramQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    state: capitalAccountSummaryWithChargeCard.state,
  });

  return (
    <SummaryArray className="w-full bg-white">
      <SummaryArray.Item>
        <SummaryArray.Heading>Limit</SummaryArray.Heading>
        <CashDisplay cents={capitalAccountSummaryWithChargeCard.details.limit} />
      </SummaryArray.Item>

      {chargeCardProgram.maxCashback > 0 && (
        <SummaryArray.Item>
          <SummaryArray.Heading>
            Cash back
            <ChargeCardCashBackInfoIconWithTooltip chargeCardProgram={chargeCardProgram} />
          </SummaryArray.Heading>
          <SummaryArray.Value>Up to {chargeCardProgram.maxCashback}%</SummaryArray.Value>
        </SummaryArray.Item>
      )}

      <SummaryArray.Item>
        <SummaryArray.Heading>
          Repayment period
          <IconWithTooltip
            color="dark"
            tooltip={<StatementCycleExplanation creditTerms={chargeCardProgram.creditTerms} />}
          />
        </SummaryArray.Heading>
        <SummaryArray.Value>
          <ChargeCardOfferRepaymentPeriodContent
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />
        </SummaryArray.Value>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default ChargeCardSummaryItems;
