import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { ASSIGNED_BILLS_QUERY_KEY } from "./assignedBillsQueryHooks";
import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

type Params = {
  billId: string;
};

const assignedBillQueryHooks = makeQueryHooks({
  rootName: BILLS_QUERY_KEY,
  name: ASSIGNED_BILLS_QUERY_KEY,
  useQueryVariables(params: Params) {
    return params;
  },
  useQueryFnMaker({ billId }) {
    const highbeamApi = useHighbeamApi();
    const currentBusinessMember = useCurrentBusinessMember();
    const businessMemberGuid = currentBusinessMember?.guid ?? null;
    return async () => {
      // When superusering, we don't have a business member guid, so we can't call this backend endpoint.
      if (!businessMemberGuid) {
        return null;
      }
      return await highbeamApi.assignedBillSummary.get(billId, businessMemberGuid);
    };
  },
});

export default assignedBillQueryHooks;

//
// Hooks
//

export const useAssignedBillRequired = (billId: string) =>
  assignedBillQueryHooks.useDataRequired({ billId });
