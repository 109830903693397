import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { ActionItemSteps, GetStartedSetupGuideStep } from "modules/action-items/types";
import PlaidCreditCardsSection from "pages/accounts/AccountsOverviewPage/sections/PlaidCreditCardsSection";
import {
  DEFAULT_ACTIVE_TAB,
  usePayoutInstructionsModal,
} from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import AccountsOverviewBalanceSection from "./sections/AccountsOverviewBalanceSection";
import AccountsOverviewPageHeader from "./sections/AccountsOverviewPageHeader";
import BankAccountsSection from "./sections/BankAccountsSection";
import CapitalAccountsSection from "./sections/CapitalAccountsSection";
import PlaidBankAccountsSection from "./sections/PlaidBankAccountsSection";
import StoreAccountsSection from "./sections/StoreAccountsSection";

const AccountsOverviewPageContent = () => {
  return (
    <>
      <AccountsOverviewPageHeader />

      <AccountsOverviewBalanceSection />

      <BankAccountsSection />

      <CapitalAccountsSection />

      <PlaidBankAccountsSection />

      <PlaidCreditCardsSection />

      <StoreAccountsSection />
    </>
  );
};

type LocationState = {
  fromStep: ActionItemSteps;
};

const AccountsOverviewPage = () => {
  const locationState = useLocation().state as LocationState | undefined;
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  useEffect(() => {
    if (locationState?.fromStep === GetStartedSetupGuideStep.ReceiveStorePayouts) {
      openPayoutInstructionsModal({ activeTab: DEFAULT_ACTIVE_TAB });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Accounts</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <AccountsOverviewPageContent />
      </DashboardPage>
    </>
  );
};

export default AccountsOverviewPage;
