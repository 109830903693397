import { ArrowsClockwise as ArrowsClockwiseIcon, Check as CheckIcon } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { FC } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { Span } from "ui/typography";
import { timeAgo } from "utils/date";
import cn from "utils/tailwind/cn";

import useBillSyncIsRunning from "../../hooks/useBillSyncIsRunning";
import useBillSyncQuery from "../../hooks/useBillSyncQuery";
import useBillSyncRunEndpointError from "../../hooks/useBillSyncRunEndpointError";

const makeLastSyncedAtContent = (lastSyncedAt: string | null) => {
  if (!lastSyncedAt) {
    return <>Synced</>;
  }

  // NB(lev): The billSync.lastSyncedAt field is sometimes slightly ahead immediately after a new sync.
  // This is a workaround to avoid showing "Synced a few seconds from now" when the sync just happened.
  if (dayjs().isBefore(dayjs(lastSyncedAt))) {
    return <>Synced a few seconds ago</>;
  }

  return <>Synced {timeAgo(lastSyncedAt)}</>;
};

type Props = {
  billId: string;
  className?: string;
};

const BillSyncStatus: FC<Props> = ({ billId, className }) => {
  const isSyncRunning = useBillSyncIsRunning(billId);
  const { data: billSync } = useBillSyncQuery(billId);
  const billSyncRunEndpointError = useBillSyncRunEndpointError(billId);
  const errors = billSync?.errors ?? [];
  const hasErrors = Boolean(billSyncRunEndpointError) || errors.length > 0;

  return (
    <Span
      className={cn(
        "flex items-center gap-1 text-xs font-regular text-grey-600 tablet:text-sm",
        className
      )}
    >
      {billSync && !isSyncRunning && (
        <>
          {hasErrors ? (
            <>
              <ArrowsClockwiseIcon className="size-4 text-grey-500" />
              Not synced
            </>
          ) : (
            <>
              <CheckIcon className="size-4 text-green-500" />
              {makeLastSyncedAtContent(billSync.lastSyncedAt)}
            </>
          )}
        </>
      )}

      {isSyncRunning && (
        <>
          <AnimatedSpinner icon={ArrowsClockwiseIcon} />
          Syncing
        </>
      )}
    </Span>
  );
};

export default BillSyncStatus;
