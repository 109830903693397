import TransactionsV2 from "components/TransactionsV2";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import chargeCardAccountQueryHooks from "modules/charge-cards/queries/chargeCardAccountQueryHooks";
import { useChargeCardRepaymentsQuery } from "modules/charge-cards/queries/useChargeCardRepayments";
import { FC, useState } from "react";
import ChargeCardRepaymentRep from "reps/ChargeCardRepaymentRep";
import Shimmer from "ui/feedback/Shimmer";
import PageIndicator from "ui/navigation/PageIndicator";
import AmountCell from "ui/table/cells/AmountCell";
import DateTimeCell from "ui/table/cells/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import ChargeCardRepaymentType from "./ChargeCardRepaymentType";
import useDownloadChargeCardTransactionsCsvMutation from "./hooks/useDownloadChargeCardTransactionsCsvMutation";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const columns: Column<ChargeCardRepaymentRep.Complete>[] = [
  {
    title: "Date",
    cellRender: ({ createdAt }) => <DateTimeCell date={createdAt} />,
  },
  {
    title: "Transaction type",
    cellRender: () => <ChargeCardRepaymentType />,
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ amount }) => (
      <AmountCell cents={amount} direction={amount >= 0 ? "positive" : "negative"} />
    ),
  },
];

const MAX_REPAYMENTS_PER_PAGE = 10;

const ChargeCardRepayments: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const { data: chargeCardAccount } = chargeCardAccountQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
  });

  // NB(alex): We only paginate on the frontend for now.
  const { data: repayments = [], isLoading } = useChargeCardRepaymentsQuery(
    chargeCardAccount?.guid
  );

  const { mutate: downloadCsv, isPending: isExportLoading } =
    useDownloadChargeCardTransactionsCsvMutation();

  const numRepayments = repayments.length;
  const totalPages = Math.ceil(numRepayments / MAX_REPAYMENTS_PER_PAGE);

  const [currentPage, setCurrentPage] = useState(0);

  const paginatedRepayments = repayments.slice(
    currentPage * MAX_REPAYMENTS_PER_PAGE,
    (currentPage + 1) * MAX_REPAYMENTS_PER_PAGE
  );

  const count = paginatedRepayments.length;
  const totalCount = repayments.length;

  return (
    <TransactionsV2
      title={<TransactionsV2.Title>Repayments</TransactionsV2.Title>}
      filters={
        <TransactionsV2.Filters
          paginationInfo={
            isLoading ? (
              <Shimmer />
            ) : (
              <TransactionsV2.PaginationInfo count={count} totalCount={totalCount} />
            )
          }
          exportButton={
            chargeCardAccount ? (
              <TransactionsV2.ExportButton
                isLoading={isExportLoading}
                onClick={() => {
                  downloadCsv(chargeCardAccount.guid);
                }}
              />
            ) : null
          }
        />
      }
      table={
        isLoading ? (
          <Table.Loading columns={columns.map(({ title }) => ({ title }))} />
        ) : (
          <TransactionsV2.TransactionsTable
            data={paginatedRepayments}
            rowKey={({ unitCoTransactionId }) => unitCoTransactionId}
            columns={columns}
          />
        )
      }
      footer={
        <TransactionsV2.Footer
          pageIndicator={
            totalPages > 1 && (
              <PageIndicator
                className="mt-6"
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            )
          }
        />
      }
    />
  );
};

export default ChargeCardRepayments;
