import { PlusCircle } from "@phosphor-icons/react";
import React, { useState } from "react";
import Button from "ui/inputs/Button";

import NewChatKnowledgeBaseItem from "./NewChatKnowledgeBaseItem";

type Props = {
  businessGuid: string;
};

const ChatKnowledgeBaseCreate: React.FC<Props> = ({ businessGuid }) => {
  const [isCreating, setIsCreating] = useState(false);

  return (
    <>
      {!isCreating ? (
        <Button
          className="self-start text-grey-600"
          onClick={() => setIsCreating(true)}
          variant="tertiary"
        >
          <PlusCircle size={16} />
          Create
        </Button>
      ) : (
        <NewChatKnowledgeBaseItem
          businessGuid={businessGuid}
          handleDone={() => setIsCreating(false)}
          handleCancel={() => setIsCreating(false)}
        />
      )}
    </>
  );
};

export default ChatKnowledgeBaseCreate;
