import useHydrateIsolatedDataViewMutation from "modules/isolated-data-view/mutations/useHydrateIsolatedDataViewMutation";
import React, { useCallback } from "react";
import { ChatConfigRep } from "reps/chat/ChatConfigRep";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";

type Props = {
  chatConfig: ChatConfigRep;
};

const ChatSettingsIsolatedDataViewItemHydrateNowButton: React.FC<Props> = () => {
  const { mutate, isPending } = useHydrateIsolatedDataViewMutation({
    onSuccess: () => {
      notify("success", "Success!");
    },
    onError: () => {
      notify("error", "Something went wrong, please try again!");
    },
  });

  const onClick = useCallback(() => {
    if (isPending) return;
    mutate();
  }, [isPending, mutate]);

  return (
    <Button variant="secondary" isLoading={isPending} className="self-start" onClick={onClick}>
      Hydrate now
    </Button>
  );
};

export default ChatSettingsIsolatedDataViewItemHydrateNowButton;
