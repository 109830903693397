import { ComponentProps, FC } from "react";
import Card from "ui/data-display/Card";
import { Heading1, Heading4 } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const OnboardingCard: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <Card className={cn("mb-32 w-full max-w-2xl", className)}>{children}</Card>;
};

const OnboardingCardHeader: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("px-8 pt-8", className)}>{children}</div>;
};

const OnboardingHeading1: FC<ComponentProps<typeof Heading1>> = ({ className, ...props }) => {
  return <Heading1 className={cn("text-xl font-bold", className)} {...props} />;
};

const OnboardingSubheading: FC<ComponentProps<typeof Heading4>> = ({ className, ...props }) => {
  return (
    <Heading4 className={cn("mt-1 text-sm font-regular text-grey-500", className)} {...props} />
  );
};

const OnboardingCardBody: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("px-8 py-8", className)}>{children}</div>;
};

const OnboardingCardFooter: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div className={cn("flex justify-end gap-x-4 border-t border-t-grey-200 px-8 py-5", className)}>
      {children}
    </div>
  );
};

export default Object.assign(OnboardingCard, {
  Header: OnboardingCardHeader,
  Heading1: OnboardingHeading1,
  Subheading: OnboardingSubheading,
  Body: OnboardingCardBody,
  Footer: OnboardingCardFooter,
});
