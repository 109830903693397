import { DefaultError, useQueryClient } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import AccountingAccountRep from "reps/AccountingAccountRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { makeQueryKey as makeIndividualAccountingAccountQueryKey } from "../queries/useAccountingAccount";
import { makeQueryKey as makeAccountingAccountsQueryKey } from "../queries/useAccountingAccounts";
import useRefreshAccountingAccountsQueries from "../queries/useRefreshAccountingAccountsQueries";

type Variables = {
  bankAccountGuid: string | null;
  accountingAccountId: string;
};

const useSetBankAccountForChartOfAccountMutation = (
  additionalOptions?: MutationAdditionalOptions<void, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshAccountingAccountsQueries = useRefreshAccountingAccountsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ bankAccountGuid, accountingAccountId }) => {
        await highbeamApi.accountingAccount.setAccount(accountingAccountId, {
          bankAccountGuid,
        });
      },
      onSuccess: async () => {
        await refreshAccountingAccountsQueries();
      },
    },
    additionalOptions || {}
  );
};

//
// Optimistic updates
//

type MutationContext = {
  originalAccountingAccount?: AccountingAccountRep.Complete;
};

export const useOptimisticSetBankAccountForChartOfAccountMutation = (
  additionalOptions?: MutationAdditionalOptions<void, Variables, DefaultError, MutationContext>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshAccountingAccountsQueries = useRefreshAccountingAccountsQueries();
  const queryClient = useQueryClient();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ bankAccountGuid, accountingAccountId }) => {
        await highbeamApi.accountingAccount.setAccount(accountingAccountId, {
          bankAccountGuid,
        });
      },
      onMutate: async (variables) => {
        const { bankAccountGuid, accountingAccountId } = variables;
        const accountingAccountsQueryKey = makeAccountingAccountsQueryKey(businessGuid);
        const individualAccountingAccountQueryKey =
          makeIndividualAccountingAccountQueryKey(accountingAccountId);

        await queryClient.cancelQueries({ queryKey: accountingAccountsQueryKey });
        await queryClient.cancelQueries({ queryKey: individualAccountingAccountQueryKey });

        const accountingAccounts = queryClient.getQueryData<AccountingAccountRep.Complete[]>(
          accountingAccountsQueryKey
        );
        const originalAccountingAccount = accountingAccounts?.find(
          (accountingAccount) => accountingAccount.id === accountingAccountId
        );

        queryClient.setQueryData<AccountingAccountRep.Complete[]>(
          accountingAccountsQueryKey,
          (old) =>
            old?.map((accountingAccount) =>
              accountingAccount.id === accountingAccountId
                ? { ...accountingAccount, bankAccountGuid }
                : accountingAccount
            )
        );
        queryClient.setQueryData<AccountingAccountRep.Complete>(
          individualAccountingAccountQueryKey,
          (old) => {
            if (old) return { ...old, bankAccountGuid };
            return old;
          }
        );

        return { originalAccountingAccount };
      },
      onSuccess: async () => {
        await refreshAccountingAccountsQueries();
      },
      onError: (error, variables, context) => {
        const { accountingAccountId } = variables;
        const accountingAccountsQueryKey = makeAccountingAccountsQueryKey(businessGuid);
        const individualAccountingAccountQueryKey =
          makeIndividualAccountingAccountQueryKey(accountingAccountId);

        if (context) {
          const { originalAccountingAccount } = context;
          if (originalAccountingAccount) {
            queryClient.setQueryData<AccountingAccountRep.Complete[]>(
              accountingAccountsQueryKey,
              (old) =>
                old?.map((accountingAccount) =>
                  accountingAccount.id === accountingAccountId
                    ? originalAccountingAccount
                    : accountingAccount
                )
            );
            queryClient.setQueryData<AccountingAccountRep.Complete>(
              individualAccountingAccountQueryKey,
              originalAccountingAccount
            );
          }
        }
      },
      onSettled: (data, error, variables) => {
        const { accountingAccountId } = variables;
        const accountingAccountsQueryKey = makeAccountingAccountsQueryKey(businessGuid);
        const individualAccountingAccountQueryKey =
          makeIndividualAccountingAccountQueryKey(accountingAccountId);

        queryClient.invalidateQueries({ queryKey: accountingAccountsQueryKey });
        queryClient.invalidateQueries({ queryKey: individualAccountingAccountQueryKey });
      },
    },
    additionalOptions || {}
  );
};

export default useSetBankAccountForChartOfAccountMutation;
