import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import { FC, useId } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "ui/inputs/Button";
import DateInputFieldset from "ui/inputs/DateInputFieldset";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import { Heading4 } from "ui/typography";
import { z } from "zod";

import OnboardingCard from "./OnboardingCard";

const schema = z.object({
  firstName: z.string().min(1, { message: "Please enter your first name." }),
  lastName: z.string().min(1, { message: "Please enter your last name." }),

  // NB(alex): It would actually be cleanest to have 3 separate text input fields and then `.transform` the day/month/year text inputs into a Dayjs.
  dateOfBirth: z
    .custom<Dayjs | null>()
    .refine((val): val is Dayjs => val !== null, "Please enter your date of birth.")
    .refine((val) => {
      const age = dayjs().diff(val, "year");
      return age >= 13;
    }, "Please enter a valid age"),
});

export type OnboardingPersonalInfoFormInputs = z.input<typeof schema>;
export type OnboardingPersonalInfoFormOutputs = z.output<typeof schema>;

type Params = {
  defaultValues: OnboardingPersonalInfoFormInputs;
};

const useOnboardingPersonalInfoForm = ({ defaultValues }: Params) => {
  return useForm<OnboardingPersonalInfoFormInputs, object, OnboardingPersonalInfoFormOutputs>({
    defaultValues,
    resolver: zodResolver(schema),
  });
};

type Props = {
  onSubmit: (data: OnboardingPersonalInfoFormOutputs) => void;
  defaultValues: OnboardingPersonalInfoFormInputs;
  isLoading: boolean;
  className?: string;
};

const OnboardingPersonalInfoCard: FC<Props> = ({
  isLoading,
  defaultValues,
  onSubmit: onSubmitProp,
  className,
}) => {
  const { handleSubmit, control } = useOnboardingPersonalInfoForm({
    defaultValues,
  });

  const onSubmit = handleSubmit((data) => {
    onSubmitProp(data);
  });

  const formId = useId();

  return (
    <OnboardingCard className={className}>
      <form onSubmit={onSubmit} id={formId}>
        <OnboardingCard.Header>
          <OnboardingCard.Heading1>Verify your personal information</OnboardingCard.Heading1>
          <OnboardingCard.Subheading>
            We’ll use this information for your Highbeam Cards and debit cards.
          </OnboardingCard.Subheading>
        </OnboardingCard.Header>

        <OnboardingCard.Body className="flex flex-col gap-y-6">
          <FieldsetV2>
            <FieldsetV2.Row columns={2}>
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState: { error }, formState: { defaultValues } }) => (
                  <div>
                    <TextInputV2
                      label="First name"
                      showErrorOutline={Boolean(error)}
                      // NB(alex): Disabled these fields for now, until we migrate `useUser` to `useBusinessMember` so we can safely update user names.
                      disabled={Boolean(defaultValues?.firstName)}
                      {...field}
                    />
                    {error && <Helper iconVariant="error">{error.message}</Helper>}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState: { error }, formState: { defaultValues } }) => (
                  <div>
                    <TextInputV2
                      label="Last name"
                      showErrorOutline={Boolean(error)}
                      // NB(alex): Disabled these fields for now, until we migrate `useUser` to `useBusinessMember` so we can safely update user names.
                      disabled={Boolean(defaultValues?.lastName)}
                      {...field}
                    />
                    {error && <Helper iconVariant="error">{error.message}</Helper>}
                  </div>
                )}
              />
            </FieldsetV2.Row>
          </FieldsetV2>

          <div className="flex flex-col gap-y-2">
            <Heading4 className="text-sm font-medium text-grey-900">Date of birth</Heading4>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({
                field: {
                  ref: _ref, // NB(alex): Still figuring out the best way to handle passing a ref down to a custom fieldset.
                  ...field
                },
                fieldState: { error },
              }) => <DateInputFieldset fieldsetErrorMessage={error?.message} {...field} />}
            />
          </div>
        </OnboardingCard.Body>

        <OnboardingCard.Footer>
          <Button type="submit" form={formId} variant="primary" isLoading={isLoading}>
            Complete signup
          </Button>
        </OnboardingCard.Footer>
      </form>
    </OnboardingCard>
  );
};

export default OnboardingPersonalInfoCard;
