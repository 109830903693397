import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

// TODO(alex): we should make this a string union to prevent errors
type Currency = string;

const useInternationalWireQuoteQueryOptions = (currency: Currency) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["internationalWireQuote", { businessGuid, currency }],
    queryFn: () => highbeamApi.payment.getInternationalWireQuote({ businessGuid, currency }),
    refetchInterval: 15 * 1000, // Make sure quote is relatively fresh
  });
};

export default useInternationalWireQuoteQueryOptions;
