import { captureException } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const CHAT_CONFIGS_QUERY_KEY = "chat-configs";

const useChatConfigQueryOptions = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [CHAT_CONFIGS_QUERY_KEY, { businessGuid }],
    queryFn: async () => {
      try {
        return await highbeamApi.chatConfig.getByBusiness(businessGuid);
      } catch (e) {
        captureException(e);
        return null; // Disable AI Chat if something goes wrong with this request.
      }
    },
  });
};

const useChatConfig = () => {
  return useSuspenseQuery(useChatConfigQueryOptions()).data;
};

export default useChatConfig;
