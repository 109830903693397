import { Info } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import useInternationalAccountQuery from "modules/bank-accounts/queries/useInternationalAccountQuery";
import { useBusinessQuery } from "modules/business/queries/useBusiness";
import React from "react";
import Banner from "ui/data-display/Banner";
import MetadataList from "ui/data-display/MetadataList";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import Text from "ui/typography/Text";

import SecondaryInternationalAccountInformation from "./SecondaryInternationalAccountInformation";

type InternationalAccountInformationProps = {
  isAllowedToViewAccountNumbers: boolean;
  isPrimary: boolean;
};

const InternationalAccountInformation: React.FC<InternationalAccountInformationProps> = ({
  isAllowedToViewAccountNumbers,
  isPrimary,
}) => {
  const { data: account, isPending: isAccountPending } = useQuery(useInternationalAccountQuery());
  const { data: business, isPending: isBusinessPending } = useBusinessQuery();

  if (!isAllowedToViewAccountNumbers) return <ContactAdmin />;
  if (!isPrimary) return <SecondaryInternationalAccountInformation />;
  if (isAccountPending || isBusinessPending || !business) {
    return <InternationalAccountInformationLoading />;
  }
  if (!account) return <ContactSupport />;

  const accountHolderName = business.name;
  const accountNumber = account.iban;

  const swiftCode = account.swiftCode;

  return (
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account holder name</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <ItemWithCopyTextTooltip textToCopy={accountHolderName || ""}>
            {accountHolderName}
          </ItemWithCopyTextTooltip>
        </MetadataList.ItemValue>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <ItemWithCopyTextTooltip textToCopy={accountNumber || ""}>
            {accountNumber}
          </ItemWithCopyTextTooltip>
        </MetadataList.ItemValue>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel>SWIFT code</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <ItemWithCopyTextTooltip textToCopy={swiftCode || ""}>
            {swiftCode}
          </ItemWithCopyTextTooltip>
        </MetadataList.ItemValue>
      </MetadataList.Item>
      <PartnerBankDetails swiftCode={swiftCode} />
    </MetadataList>
  );
};

const ContactSupport = () => {
  return (
    <Banner
      color="blue"
      icon={<Info />}
      paragraph="International account information is not available for this account. Please contact support for more information."
    />
  );
};

const ContactAdmin = () => {
  return (
    <Banner
      color="blue"
      icon={<Info />}
      paragraph="Please contact your account admin to view this information."
    />
  );
};

const InternationalAccountInformationLoading = () => {
  return (
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account holder name</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <AnimatedSpinner />
        </MetadataList.ItemValue>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <AnimatedSpinner />
        </MetadataList.ItemValue>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel>SWIFT code</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <AnimatedSpinner />
        </MetadataList.ItemValue>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel>Partner bank</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <Text>
            <AnimatedSpinner />
          </Text>
          <Text>
            <AnimatedSpinner />
          </Text>
          <Text>
            <AnimatedSpinner />
          </Text>
          <Text>
            <AnimatedSpinner />
          </Text>
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  );
};

type PartnerBankDetailsProps = {
  swiftCode: string | null;
};

const PartnerBankDetails: React.FC<PartnerBankDetailsProps> = ({ swiftCode }) => {
  if (swiftCode === "CMFGUS33") {
    return (
      <MetadataList.Item>
        <MetadataList.ItemLabel>Partner bank</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <div>
            <Text>Community Federal Savings Bank </Text>
            <Text>810 Seventh Avenue</Text>
            <Text>New York, NY 10019</Text>
          </div>
        </MetadataList.ItemValue>
      </MetadataList.Item>
    );
  }
  return (
    <MetadataList.Item>
      <MetadataList.ItemLabel>Partner bank</MetadataList.ItemLabel>
      <MetadataList.ItemValue>
        <div>
          <Text>The Currency Cloud Limited</Text>
          <Text>12 Steward Street</Text>
          <Text>London, E1 6FQ</Text>
          <Text>United Kingdom</Text>
        </div>
      </MetadataList.ItemValue>
    </MetadataList.Item>
  );
};

export default InternationalAccountInformation;
