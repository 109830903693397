import { zodResolver } from "@hookform/resolvers/zod";
import useBusinessDetails from "modules/business-details/queries/useBusinessDetails";
import { useForm, useFormContext } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  name: z.string().nonempty(),
  dba: z.string().min(3, "Too short.").max(40, "Too long.").optional().or(z.literal("")),
  phoneNumber: z
    .string()
    .regex(/(\+\d{1,3}[.\-\s]|\+1)?[\d.\-\s()]+/, "Please enter a valid phone number."),
});

type BusinessDetailsFormInputs = z.infer<typeof schema>;

const useBusinessDetailsForm = () => {
  const { name, dba, phoneNumber } = useBusinessDetails();

  return useForm<BusinessDetailsFormInputs>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      name,
      dba: dba ?? "",
      phoneNumber,
    },
  });
};

export default useBusinessDetailsForm;

export const useBusinessDetailsFormContext = () => {
  return useFormContext<BusinessDetailsFormInputs>();
};
