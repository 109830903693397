import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoReceivedPaymentsQueryOptions from "modules/unit-co-received-payments/queries/useUnitCoReceivedPaymentsQueryOptions";

const useFailedDebitPayments = () => {
  const { data: failedDebitPayments } = useSuspenseQuery(
    useUnitCoReceivedPaymentsQueryOptions({ status: ["MarkedForReturn"] })
  );

  return failedDebitPayments;
};

export default useFailedDebitPayments;
