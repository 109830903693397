import DashboardPage from "layouts/DashboardPage";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  heading: string;
};

const ChatKnowledgeBaseSection: React.FC<Props> = ({ children, heading }) => {
  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>{heading}</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      {children}
    </DashboardPage.Section>
  );
};

export default ChatKnowledgeBaseSection;
