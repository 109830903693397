import React from "react";
import { NumberChatElement } from "reps/chat/ChatElement";

type Props = {
  element: NumberChatElement;
};

const ResponseMessageNumber: React.FC<Props> = ({ element }) => {
  return <span>{element.value.toFixed(element.decimalPlaces)}</span>;
};

export default ResponseMessageNumber;
