import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountBreadcrumbItem from "modules/capital-accounts/components/CapitalAccountBreadcrumbItem";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import checkIsCapitalAccountSummaryWithChargeCard from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import isGuidNotFoundError from "modules/error/utils/isGuidNotFoundError";
import { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Navigate, useParams } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import ChargeCardView from "./views/ChargeCardView";
import LineOfCreditView from "./views/LineOfCreditView";
import OfferedCapitalView from "./views/OfferedCapitalView";
import UpsellCapitalView from "./views/UpsellCapitalView";

type CapitalAccountPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountPageContent: FC<CapitalAccountPageContentProps> = ({
  capitalAccountSummary,
}) => {
  switch (capitalAccountSummary.state) {
    case CapitalAccountRep.State.Active:
    case CapitalAccountRep.State.OfferAccepted:
      return checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
        <ChargeCardView capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
      ) : (
        <LineOfCreditView capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary} />
      );
    case CapitalAccountRep.State.Offered:
      return <OfferedCapitalView capitalAccountSummary={capitalAccountSummary} />;
    case CapitalAccountRep.State.Terminated:
      return <UpsellCapitalView />;
  }
};

const CapitalAccountPage = () => {
  const { capitalAccountGuid } = useParams();

  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountGuid ?? null,
  });

  if (capitalAccountSummary === null) {
    return <Navigate to="/capital" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>

          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem
          />
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        {capitalAccountSummary ? (
          <CapitalAccountPageContent capitalAccountSummary={capitalAccountSummary} />
        ) : (
          <DashboardPage.DotsLoader />
        )}
      </DashboardPage>
    </>
  );
};

export default withErrorBoundary(CapitalAccountPage, {
  fallbackRender: ({ error }) => {
    if (isGuidNotFoundError(error)) {
      return <Navigate to="/capital" />;
    }
  },
});
