import React from "react";
import { NumberedListChatElement } from "reps/chat/ChatElement";

import ResponseMessageParagraph from "./ResponseMessageParagraph";

type Props = {
  element: NumberedListChatElement;
};

const ResponseMessageNumberedList: React.FC<Props> = ({ element }) => {
  return (
    <ol className="list-decimal pl-7">
      {element.items.map((item, i) => (
        <li key={i}>
          <ResponseMessageParagraph element={item} />
        </li>
      ))}
    </ol>
  );
};

export default ResponseMessageNumberedList;
