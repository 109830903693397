import PaymentSummary from "components/PaymentSummary";
import MultiStep from "layouts/MultiStep";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import CapitalAccountBar from "modules/capital-accounts/components/CapitalAccountBar";
import { CapitalAccountSummaryWithChargeCard } from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useRepayChargeCardMutation from "modules/charge-cards/mutations/useRepayChargeCardMutation";
import { FC, FormEvent } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import { notify } from "ui/feedback/Toast";

import {
  RepayHighbeamCardsFormOutputs,
  repayHighbeamCardsFormSchema,
} from "./useRepayHighbeamCardsForm";

const REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID = "repay-highbeam-cards-confirmation-form";

type RepayHighbeamCardsConfirmationFormProps = {
  repayHighbeamCardsAmountFormData: RepayHighbeamCardsFormOutputs;
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsConfirmationForm: FC<RepayHighbeamCardsConfirmationFormProps> = ({
  repayHighbeamCardsAmountFormData,
  capitalAccountSummaryWithChargeCard,
}) => {
  const { state } = useLocation();
  const { amount, repaymentBankAccount, currentAmountAvailable } = repayHighbeamCardsAmountFormData;
  const navigate = useNavigate();
  const availableAfterRepayment = currentAmountAvailable + amount;

  const { mutate: repayChargeCard, isPending } = useRepayChargeCardMutation({
    onSuccess: () => {
      notify("success", "Repayment successful");
      navigate(`/capital/${capitalAccountSummaryWithChargeCard.guid}`);
    },
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    repayChargeCard({
      capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
      amountInCents: amount,
      bankAccountGuid: repaymentBankAccount.guid,
    });
  };

  return (
    <MultiStep.Form id={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Repayment amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Repaying"
              value={<CapitalAccountBar capitalAccount={capitalAccountSummaryWithChargeCard} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Pay from"
              value={<BankAccountBar bankAccount={repaymentBankAccount} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Available (current)"
              value={
                <MoneyAmountFraction
                  numeratorInCents={currentAmountAvailable}
                  denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
                />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Available after repaying"
              value={
                <MoneyAmountFraction
                  numeratorInCents={availableAfterRepayment}
                  denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
                />
              }
            />
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.BackButtonLink
          to={`/capital/${capitalAccountSummaryWithChargeCard.guid}/repay`}
          disabled={isPending}
          state={state}
        />

        <MultiStep.Controls.NextButton
          form={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isPending}
        >
          Complete repayment
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsConfirmationView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const { state } = useLocation();

  const repayHighbeamCardsAmountFormData = repayHighbeamCardsFormSchema.safeParse(
    state?.repayHighbeamCardsAmountFormInputs
  );

  if (!repayHighbeamCardsAmountFormData.success) {
    return <Navigate to={`/capital/${capitalAccountSummaryWithChargeCard.guid}/repay`} />;
  }

  return (
    <RepayHighbeamCardsConfirmationForm
      capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
      repayHighbeamCardsAmountFormData={repayHighbeamCardsAmountFormData.data}
    />
  );
};

export default RepayHighbeamCardsConfirmationView;
