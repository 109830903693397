import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

const useIsCapitalOverviewPageEnabled = () => {
  const isCapitalOverviewPageFlagEnabled = useFeatureFlag("CAPITAL_OVERVIEW_PAGE");
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  return isCapitalOverviewPageFlagEnabled || nonTerminatedCapitalAccounts.length > 1;
};

export default useIsCapitalOverviewPageEnabled;
