import React from "react";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

import ChatConfigIndicatorIcon from "./ChatConfigIndicatorIcon";

type Props = {
  children: string;
  status: "success" | "warning" | "danger";
};

const ChatConfigIndicator: React.FC<Props> = ({ children, status }) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <ChatConfigIndicatorIcon status={status} />
      <Paragraph className={cn("text-sm font-medium", color(status))}>{children}</Paragraph>
    </div>
  );
};

export default ChatConfigIndicator;

const color = (status: "success" | "warning" | "danger"): string => {
  switch (status) {
    case "success":
      return cn("text-green-600");
    case "warning":
      return cn("text-yellow-700");
    case "danger":
      return cn("text-red-600");
  }
};
