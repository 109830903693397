import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ModalV3 from "ui/overlay/ModalV3";
import { Paragraph } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./CardLimitModal.module.scss";
import cardLimitsModalState, { useCardLimitsModal } from "./state/cardLimitModalState";

type Props = {
  account: BankAccountRep.Complete;
  currentPurchase: number;
};

const CardLimitModal: React.FC<Props> = ({ account, currentPurchase }) => {
  const { close: onClose } = useCardLimitsModal();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onClose();
      }}
    >
      <ModalV3.Header onClose={onClose}>Daily debit limit</ModalV3.Header>
      <ModalV3.Body>
        <div className="mb-4 flex flex-col gap-4 text-grey-600">
          <Paragraph className="text-sm">
            You cannot debit more than <MoneyAmount cents={currentPurchase} withCents={false} /> per
            day from one Highbeam bank account. This limit resets every day at 12:00 AM EST. To
            avoid hitting the debit limit, we recommend that you create a separate bank account and
            card for your largest auto-pay vendor to reduce the daily spend on{" "}
            <BankAccountBar
              size={16}
              className="mx-1 gap-x-1 align-bottom font-bold"
              disableTextMarginBottom
              bankAccount={account}
            />
            .
          </Paragraph>
          <>
            <Text size={14} weight="bold">
              How to create a new account and card
            </Text>
            <div className={styles.step}>
              <div className={styles.stepNumber}>1</div>
              <Text size={12}>
                Go to Accounts &gt;{" "}
                <Link className={styles.link} to="accounts/manage-accounts">
                  Manage accounts
                </Link>{" "}
                &gt; “Create new account”
              </Text>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>2</div>
              <Text size={12}>
                Fund your new account from{" "}
                <BankAccountBar
                  size={16}
                  className="mx-1 gap-x-1 align-bottom font-bold"
                  disableTextMarginBottom
                  bankAccount={account}
                />
              </Text>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>3</div>
              <Text size={12}>
                Go to{" "}
                <Link className={styles.link} to="/cards">
                  Cards
                </Link>{" "}
                &gt; “Create virtual card” &gt; “Debit card” to create a new debit card
              </Text>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>4</div>
              <Text size={12}>Select your new bank account as the “Associated account”</Text>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>5</div>
              <Text size={12}>
                Update one of your large auto-pay vendors (e.g. Meta ads, Google ads) with the new
                card credentials{" "}
              </Text>
            </div>
          </>
        </div>
      </ModalV3.Body>
      <ModalV3.Footer onClose={onClose}>
        <ModalV3.Footer.SubmitButton>Done</ModalV3.Footer.SubmitButton>
      </ModalV3.Footer>
    </form>
  );
};

const CardLimitModalWrapper = () => {
  const { isOpen, close: onClose } = useCardLimitsModal();
  const state = useRecoilValue(cardLimitsModalState);

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose}>
      {state.isOpen && (
        <CardLimitModal account={state.account} currentPurchase={state.currentPurchase} />
      )}
    </ModalV3>
  );
};

export default CardLimitModalWrapper;
