import { Chat } from "@phosphor-icons/react";
import dayjs from "dayjs";
import chatDebugModeState from "modules/chat/state/chatDebugMode";
import React from "react";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ConversationRep } from "reps/chat/ConversationRep";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  label: string;
  conversations: ConversationRep[];
};

const ChatConversationListGroup: React.FC<Props> = ({ label, conversations }) => {
  const chatDebugMode = useRecoilValue(chatDebugModeState);

  if (!chatDebugMode) {
    // Temporarily disabling the chat sidebar for non-superusers.
    return null;
  }

  if (conversations.length === 0) return null;

  return (
    <div className="flex flex-col gap-2">
      <Paragraph className="grey-600 px-4 text-xs font-medium">{label}</Paragraph>
      <div className="flex flex-col gap-1">
        {conversations
          .toSorted((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
          .map((conversation) => (
            <NavLink
              key={conversation.id}
              to={`/chat/${conversation.id}`}
              className={({ isActive }) =>
                cn(
                  "flex flex-row items-center gap-3 rounded-lg px-4 py-2",
                  isActive ? "bg-grey-50 font-medium text-grey-900" : "text-grey-500"
                )
              }
            >
              <Chat size={16} className="flex-shrink-0" />
              <Paragraph className="flex-grow truncate text-nowrap text-sm text-inherit">
                {dayjs(conversation.createdAt).format("MMM D [at] h:mm A")}
              </Paragraph>
            </NavLink>
          ))}
      </div>
    </div>
  );
};

export default ChatConversationListGroup;
