import { Icon, IconProps } from "@phosphor-icons/react";
import { ComponentPropsWithoutRef, FC, ForwardedRef, ReactNode, useState } from "react";
import colors from "styles/colors";
import Radio from "ui/inputs/Radio";
import { Heading4 } from "ui/typography";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";
import forwardRefPreserveGenerics from "utils/react-helpers/forwardRefPreserveGenerics";
import cn from "utils/tailwind/cn";

type RadioCardIconProps = IconProps & {
  Icon: Icon;
};

const RadioCardIcon: FC<RadioCardIconProps> = ({ Icon, ...iconProps }) => {
  return (
    <div className="flex size-16 items-center justify-center rounded bg-grey-100">
      <Icon size={32} color={colors.grey[700]} weight="thin" {...iconProps} />
    </div>
  );
};

export type RadioCardProps<TValue extends string> = ComponentPropsWithoutRef<"label"> & {
  icon: ReactNode;
  value: TValue;
  label: ReactNode;
  description?: ReactNode | ((renderProps: { checked: boolean }) => ReactNode);
  disabled?: boolean;
  onChange: (value: TValue) => void;
  checked: boolean;
  tintBackgroundWhenChecked?: boolean;
  inputRef?: React.Ref<HTMLInputElement>; // Do not use `forwardRef` or else we cannot use a generic directly. https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
};

const RadioCard = <TValue extends string>(
  {
    icon,
    value,
    onChange,
    label,
    checked,
    description,
    inputRef,
    disabled,
    tintBackgroundWhenChecked,
    onBlur,
    onFocus,
    className,
    ...labelProps
  }: RadioCardProps<TValue>,
  ref: ForwardedRef<HTMLLabelElement>
) => {
  const [isFocused, setIsFocused] = useState(false);

  // Selects the focused radio card when the space key is pressed
  useKeyboardEvent(
    (e) => e.code === "Space" || e.code === "Enter", // NB(alex): I actually want this to submit the form if a user presses `Enter` but I can't get it to work :/
    () => !disabled && isFocused && onChange(value),
    [disabled, isFocused, value, onChange]
  );

  return (
    <label
      ref={ref}
      htmlFor={value}
      className={cn(
        "flex items-center rounded-md bg-white py-4 pl-4 pr-6",
        "border-2 border-transparent outline-none",
        checked && "border-purple-500",
        checked && tintBackgroundWhenChecked && "bg-purple-50",
        disabled && "cursor-not-allowed opacity-40",
        !disabled && "hover:bg-grey-50",
        !disabled && !checked && "focus:border-focus",
        className
      )}
      tabIndex={disabled ? -1 : 0}
      onFocus={(e) => {
        setIsFocused(true);
        onFocus?.(e);
      }}
      onBlur={(e) => {
        setIsFocused(false);
        onBlur?.(e);
      }}
      {...labelProps}
    >
      {icon}

      <div className="mx-4 flex-1">
        <Heading4 className="text-sm font-medium text-grey-900">{label}</Heading4>
        <div className="text-xs font-regular text-grey-500">
          {typeof description === "function" ? description({ checked }) : description}
        </div>
      </div>

      <div className="flex justify-end self-center">
        <Radio
          ref={inputRef}
          tabIndex={-1} // Prevents focusing the radio button, since we want the containing label to show focus instead.
          id={value}
          checked={checked}
          disabled={disabled}
          value={value}
          onChange={() => onChange(value)}
        />
      </div>
    </label>
  );
};

export default Object.assign(forwardRefPreserveGenerics(RadioCard), {
  Icon: RadioCardIcon,
});
