import {
  Authorization,
  OriginatedAchTransaction,
  Payment,
  Transaction,
  WireTransaction,
} from "@highbeam/unit-node-sdk";
import HighbeamApi from "api/HighbeamApi";
import getBankAccountDisplayName from "modules/bank-accounts/utils/getBankAccountDisplayName";
import BankAccountRep from "reps/BankAccountRep";
import PaymentDetailsRep from "reps/PaymentDetailsRep";
import {
  BaseHighbeamTransaction,
  HighbeamInternationalWireTransaction,
  HighbeamTransaction,
  HighbeamWireImadOmad,
  TransactionTags,
  TransactionType,
  isCardTransaction,
  isPurchaseTransaction,
} from "utils/types/transactionsTypes";
import { getTransactionCounterpartyMetadata } from "utils/vendors";

import { formatBankingDate } from "./date";
import { getDollarsFromCents } from "./money";
import { getPaymentStatus } from "./payments";
import { formatAmount } from "./string";

export function convertToHighbeamTransaction(
  unitTransaction: Transaction,
  accountsById: Map<string, BankAccountRep.Complete>,
  metadata?: PaymentDetailsRep.Complete,
  payment?: Payment
): HighbeamTransaction {
  const accountId = unitTransaction.relationships.account.data.id;
  const account = accountsById.get(accountId);
  const transaction = enrichUnitTransactionWithTags(unitTransaction);
  const accountName = getTransactionAccountName(transaction, account);

  const { icon: counterpartyIcon, counterpartyFormattedName } =
    getTransactionCounterpartyMetadata(transaction);
  const baseHighbeamTransaction: BaseHighbeamTransaction = {
    id: transaction.id,
    accountId,
    type: getTransactionType(transaction),
    createdAt: transaction.attributes.createdAt,
    formattedCreatedAt: formatBankingDate(transaction.attributes.createdAt),
    accountName: accountName,
    counterpartyName: getTransactionCounterparty(transaction, accountsById),
    counterpartyIcon,
    counterpartyFormattedName,
    direction: transaction.attributes.direction,
    shortMethodName: "Transaction",
    longMethodName: "Transaction",
    summary: transaction.attributes.summary,
    category: "", // TODO: Hook up to actual category
    amountInCents: transaction.attributes.amount,
    balanceInCents: transaction.attributes.balance,
    paymentStatus: payment ? getPaymentStatus(payment.attributes.status) : undefined,
    generalPaymentMetadataGuid:
      transaction.attributes.tags?.generalPaymentMetadataGuid ?? undefined,
    payeeGuid: transaction.attributes.tags?.recipientGuid ?? undefined,
  };

  if (hasHighbeamTransactionType(transaction)) {
    return enrichHighbeamTransaction(baseHighbeamTransaction);
  }

  return enrichUnitTransaction(baseHighbeamTransaction, transaction, metadata);
}

function getTransactionType(unitTransaction: Transaction): TransactionType {
  const tags = unitTransaction.attributes.tags as TransactionTags;
  return tags?.transactionType ?? unitTransaction.type;
}

function hasHighbeamTransactionType(unitTransaction: Transaction): boolean {
  const tags = unitTransaction.attributes.tags as TransactionTags;
  return Boolean(tags?.transactionType);
}

function enrichUnitTransactionWithTags(transaction: Transaction): Transaction {
  const tags = transaction.attributes.tags as TransactionTags;

  return {
    ...transaction,
    type: tags?.transactionType ?? transaction.type,
    attributes: {
      ...transaction.attributes,
      counterpartyName: tags?.counterpartyName ?? transaction.attributes.counterpartyName,
      companyName: tags?.transactionCompanyName ?? transaction.attributes.companyName,
      description: tags?.transactionDescription ?? transaction.attributes.description,
      merchantName: tags?.transactionMerchantName ?? transaction.attributes.merchantName,
      summary: tags?.transactionSummary ?? transaction.attributes.summary,
    },
  } as Transaction;
}

function enrichHighbeamTransaction(base: BaseHighbeamTransaction): HighbeamTransaction {
  switch (base.type) {
    case "ChargeCardCreditAccountRepayment":
      return {
        ...base,
        counterpartyName: "Highbeam Card repayment",
        summary: "Highbeam Card repayment",
      };
    default:
      return base;
  }
}

function enrichUnitTransaction(
  base: BaseHighbeamTransaction,
  transaction: Transaction,
  metadata?: PaymentDetailsRep.Complete
): HighbeamTransaction {
  switch (transaction.type) {
    case "adjustmentTransaction":
      return {
        ...base,
        type: "adjustmentTransaction",
        shortMethodName: "Adjustment",
        longMethodName: "Adjustment transaction",
        counterpartyName: "Highbeam",
        description: transaction.attributes.description,
      };
    case "atmTransaction":
      return {
        ...base,
        cardId: transaction.relationships.card.data.id,
        type: "atmTransaction",
        shortMethodName: "ATM",
        longMethodName: "ATM transaction",
        atmName: transaction.attributes.atmName,
        atmLocation: transaction.attributes.atmLocation,
        surchargeInCents: transaction.attributes.surcharge,
        formattedSurcharge: formatAmount(getDollarsFromCents(transaction.attributes.surcharge)),
      };
    case "bookTransaction":
      return {
        ...base,
        type: "bookTransaction",
        shortMethodName: "Book",
        longMethodName: "Book transfer",
      };
    case "dishonoredAchTransaction":
      return {
        ...base,
        type: "dishonoredAchTransaction",
        shortMethodName: "ACH",
        longMethodName: "Dishonored ACH",
        description: transaction.attributes.description,
        counterpartyRoutingNumber: transaction.attributes.counterpartyRoutingNumber,
        traceNumber: String(transaction.attributes.traceNumber),
        reason: transaction.attributes.reason,
      };
    case "originatedAchTransaction":
      if (metadata?.paymentStatus)
        return formatHighbeamInternationalWireTransaction(base, transaction, metadata);
      return {
        ...base,
        type: "originatedAchTransaction",
        shortMethodName: "ACH",
        longMethodName: "ACH transaction",
        description: transaction.attributes.description,
        addenda: transaction.attributes.addenda || "",
        counterpartyRoutingNumber: transaction.attributes.counterparty.routingNumber,
        counterpartyAccountNumber: transaction.attributes.counterparty.accountNumber,
        counterpartyAccountType: transaction.attributes.counterparty.accountType,
        paymentGuid: transaction.attributes.tags?.paymentGuid ?? undefined,
        traceNumber: transaction.attributes.traceNumber,
      };
    case "receivedAchTransaction":
      return {
        ...base,
        type: "receivedAchTransaction",
        shortMethodName: "ACH",
        longMethodName: "ACH transaction",
        description: transaction.attributes.description,
        addenda: transaction.attributes.addenda || "",
        counterpartyRoutingNumber: transaction.attributes.counterpartyRoutingNumber,
        traceNumber: String(transaction.attributes.traceNumber),
      };
    case "returnedAchTransaction":
      return {
        ...base,
        type: "returnedAchTransaction",
        shortMethodName: "ACH",
        longMethodName: "Returned ACH",
        counterpartyRoutingNumber: transaction.attributes.counterpartyRoutingNumber,
        reason: transaction.attributes.reason,
      };
    case "returnedReceivedAchTransaction":
      return {
        ...base,
        type: "returnedReceivedAchTransaction",
        shortMethodName: "ACH",
        longMethodName: "Returned ACH",
        reason: transaction.attributes.reason,
      };
    case "cardTransaction":
      return {
        ...base,
        type: "cardTransaction",
        shortMethodName: "Card",
        longMethodName: "Card transaction",
        cardId: getCardId(transaction),
        merchantCategory: transaction.attributes.merchant.category,
        merchantLocation: transaction.attributes.merchant.location,
      };
    case "cardReversalTransaction":
      return {
        ...base,
        type: "cardReversalTransaction",
        shortMethodName: "Card",
        longMethodName: "Card transaction",
      };
    case "purchaseTransaction":
      return {
        ...base,
        type: "purchaseTransaction",
        shortMethodName: "Card",
        longMethodName: "Card transaction",
        cardId: getCardId(transaction),
        merchantCategory: transaction.attributes.merchant.category,
        merchantLocation: transaction.attributes.merchant.location,
      };
    case "wireTransaction":
      if (metadata?.paymentStatus)
        return formatHighbeamInternationalWireTransaction(base, transaction, metadata);
      return {
        ...base,
        type: "wireTransaction",
        shortMethodName: "Wire",
        longMethodName: "Wire transfer",
        description: transaction.attributes.description,
        counterpartyRoutingNumber: transaction.attributes.counterparty.routingNumber,
        counterpartyAccountNumber: transaction.attributes.counterparty.accountNumber,
        counterpartyAccountType: transaction.attributes.counterparty.accountType,
        imadOmad: transaction.attributes.imadOmad
          ? (transaction.attributes.imadOmad as HighbeamWireImadOmad)
          : undefined,
      };
    case "checkDepositTransaction":
      return {
        ...base,
        counterpartyName:
          transaction.attributes.tags?.userProvidedCounterpartyName ||
          base.counterpartyFormattedName ||
          base.counterpartyName ||
          "Check deposit",
        type: "checkDepositTransaction",
        shortMethodName: "Check",
        longMethodName: "Check deposit",
        checkDepositId: transaction.relationships.checkDeposit.data.id,
      };
    default:
      return base;
  }
}

function formatHighbeamInternationalWireTransaction(
  base: BaseHighbeamTransaction,
  transaction: WireTransaction | OriginatedAchTransaction,
  metadata: PaymentDetailsRep.Complete
): HighbeamInternationalWireTransaction {
  return {
    ...base,
    type: "internationalWireTransaction",
    shortMethodName: "Intl. wire",
    longMethodName: "International wire",
    description: transaction.attributes.description,
    // NB(alex): Wires over 50k use `WireTransaction` under the hood, which does not have an `addenda` field.
    addenda:
      transaction.type === "originatedAchTransaction" ? transaction.attributes.addenda : undefined,
    receivedAmountInCents: metadata.paymentAmount,
    receivedCurrency: metadata.paymentCurrency,
    internationalPaymentType: metadata.paymentType,
    internationalWireStatus: metadata.paymentStatus,
    internationalWireFeeInCents: metadata.paymentFee,
    payeeGuid: metadata.payeeGuid,
    payeeName: metadata.payeeName,
    paymentMetadataGuid: metadata.guid,
  };
}

export function getCardId(transaction: Transaction | Authorization) {
  switch (transaction.type) {
    case "atmTransaction":
    case "purchaseTransaction":
    case "authorization":
    case "cardTransaction":
      return transaction.relationships.card.data.id;
  }
}

export function getCardIdFromHighbeamTransaction(transaction: HighbeamTransaction) {
  if (isCardTransaction(transaction) || isPurchaseTransaction(transaction)) {
    return transaction.cardId;
  }
  return undefined;
}

export function getTransactionCounterparty(
  transaction: Transaction,
  accountsById: Map<string, BankAccountRep.Complete>
): string {
  const highbeamType = getTransactionHighbeamType(transaction);

  switch (highbeamType) {
    case "cashback":
      return "Highbeam cash back";
  }

  // https://docs.unit.co/resources#transaction-originatedach and resources below
  switch (transaction.type) {
    case "originatedAchTransaction":
      return transaction.attributes.counterparty.name;
    case "receivedAchTransaction":
      return transaction.attributes.companyName;
    case "returnedAchTransaction":
      return transaction.attributes.counterpartyName;
    case "returnedReceivedAchTransaction":
      return transaction.attributes.companyName;
    case "dishonoredAchTransaction":
      return transaction.attributes.companyName;
    case "bookTransaction":
      const counterpartyAccount = accountsById.get(
        transaction.relationships.counterpartyAccount.data.id
      );
      return (
        (counterpartyAccount && getBankAccountDisplayName(counterpartyAccount)) ||
        transaction.attributes.counterparty.name
      );
    case "purchaseTransaction":
      return transaction.attributes.merchant.name || "";
    case "atmTransaction":
      return transaction.attributes.atmName;
    case "cardTransaction":
      return transaction.attributes.merchant?.name || "";
    case "wireTransaction":
      return transaction.attributes.counterparty.name;
    case "releaseTransaction":
      return transaction.attributes.counterparty.name;
    default:
      return transaction.attributes.summary;
  }
}

export function getTransactionHighbeamType(transaction: Transaction) {
  if (transaction.attributes.tags) {
    return (transaction.attributes.tags as TransactionTags).transactionType || null;
  }
  return null;
}

export async function getPaymentMetadataByGuid(
  businessGuid: string,
  transactions: Transaction[],
  highbeamApi: HighbeamApi
) {
  const paymentMetadataGuids = transactions
    .map((transaction) => transaction.attributes.tags?.paymentMetadataGuid)
    .filter(Boolean) as string[];
  const paymentMetadata =
    paymentMetadataGuids.length > 0
      ? await highbeamApi.internationalPaymentDetails.batchGet(businessGuid, paymentMetadataGuids)
      : [];
  return (
    paymentMetadata?.reduce(
      (map: Record<string, PaymentDetailsRep.Complete>, details: PaymentDetailsRep.Complete) => ({
        ...map,
        [details.guid]: details,
      }),
      {}
    ) || {}
  );
}

export const getTransactionAccountName = (
  transaction: Transaction,
  account?: BankAccountRep.Complete
) => {
  if (account) return getBankAccountDisplayName(account);

  if (transaction.type === "cardTransaction" || transaction.type === "purchaseTransaction") {
    return `${transaction.attributes.tags?.cardName} ${transaction.attributes.cardLast4Digits}`;
  }

  return "";
};
