import dayjs from "dayjs";
import useKnowledgeBaseItems from "modules/chat/queries/useKnowledgeBaseItems";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import React from "react";
import { NIL as zeroGuid } from "uuid";

import ChatKnowledgeBaseSection from "./ChatKnowledgeBaseSection";
import ChatKnowledgeBaseTable from "./ChatKnowledgeBaseTable";

const ChatKnowledgeBase: React.FC = () => {
  const businessGuid = useBusinessGuid();

  const businessKnowledgeBaseItems = useKnowledgeBaseItems(businessGuid).toSorted((a, b) =>
    dayjs(b.createdAt).diff(dayjs(a.createdAt))
  );
  const globalKnowledgeBaseItems = useKnowledgeBaseItems(zeroGuid).toSorted((a, b) =>
    dayjs(b.createdAt).diff(dayjs(a.createdAt))
  );

  return (
    <div className="flex flex-col gap-4">
      <ChatKnowledgeBaseSection heading="Business knowledge base">
        <ChatKnowledgeBaseTable businessGuid={businessGuid} items={businessKnowledgeBaseItems} />
      </ChatKnowledgeBaseSection>
      <ChatKnowledgeBaseSection heading="Global knowledge base">
        <ChatKnowledgeBaseTable businessGuid={zeroGuid} items={globalKnowledgeBaseItems} />
      </ChatKnowledgeBaseSection>
    </div>
  );
};

export default ChatKnowledgeBase;
