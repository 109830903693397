// NB(alex): `makeQueryHooks` is still experimental.

import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const ROOT_CAPITAL_ACCOUNTS_QUERY_KEY = "capitalAccounts-root";

export const useRefreshAllCapitalAccountsQueries = () => {
  return useRefreshQuery([ROOT_CAPITAL_ACCOUNTS_QUERY_KEY]);
};

const capitalAccountsQueryHooks = makeQueryHooks({
  rootName: ROOT_CAPITAL_ACCOUNTS_QUERY_KEY,
  name: "capitalAccounts",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker: ({ businessGuid }) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.capitalAccount.getAll(businessGuid);
    };
  },
});

export default capitalAccountsQueryHooks;
