import CapitalTransactionLimitsRep from "reps/CapitalTransactionLimitsRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CapitalTransactionLimitsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(
    businessGuid: string,
    capitalAccountGuid: string
  ): Promise<CapitalTransactionLimitsRep.Complete> {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const url = `/capital-transaction-limits?${qp}`;
    return (await this.api.get<CapitalTransactionLimitsRep.Complete>(url))!;
  }
}
