import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const BASE_INTERNATIONAL_PAYMENT_METADATA_QUERY_KEY = "international-wire-payment-metadata";

type Params = {
  paymentMetadataGuid?: string;
};

export const useInternationalWirePaymentMetadataQueryOptions = ({
  paymentMetadataGuid,
}: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [
      BASE_INTERNATIONAL_PAYMENT_METADATA_QUERY_KEY,
      { businessGuid, paymentMetadataGuid },
    ],
    queryFn: () => {
      if (!paymentMetadataGuid) {
        return null;
      }

      return highbeamApi.internationalPaymentDetails.get(businessGuid, paymentMetadataGuid);
    },
  });
};

export const useInternationalWirePaymentMetadataQuery = (params: Params) => {
  return useSuspenseQuery(useInternationalWirePaymentMetadataQueryOptions(params));
};

export const useInternationalWirePaymentMetadata = (params: Params) => {
  return useInternationalWirePaymentMetadataQuery(params).data;
};
