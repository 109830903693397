import { FC } from "react";
import ButtonLink from "ui/inputs/ButtonLink";

import OnboardingCard from "./OnboardingCard";

type Props = {
  bankApplicationUrl: string;
  className?: string;
};

const OnboardingAwaitingDocumentsCard: FC<Props> = ({ bankApplicationUrl, className }) => {
  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header>
        <OnboardingCard.Heading1 className="mb-2 font-bold">
          Additional documents required
        </OnboardingCard.Heading1>
        <OnboardingCard.Subheading>
          Please upload the additional documentation to complete your application.
        </OnboardingCard.Subheading>
      </OnboardingCard.Header>

      <OnboardingCard.Footer className="mt-8 flex justify-end gap-x-4 border-t border-t-grey-200 px-8 py-5">
        <ButtonLink variant="primary" to={bankApplicationUrl}>
          Upload documents
        </ButtonLink>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingAwaitingDocumentsCard;
