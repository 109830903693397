import ReferralLinkRep from "../reps/ReferralLinkRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ReferralLinkApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(referral: ReferralLinkRep.Creator): Promise<ReferralLinkRep> {
    const url = `/referral-links`;
    return (await this.api.post<ReferralLinkRep>(url, referral))!;
  }

  async get(referralSlugOrGuid: string): Promise<ReferralLinkRep | null> {
    const url = `/referral-links/${referralSlugOrGuid}`;
    return await this.api.get<ReferralLinkRep>(url);
  }

  // NB(alex): This gets the referral links that a business can **send**, not referral links that a business _received_.
  async getByBusiness(businessGuid: string): Promise<ReferralLinkRep[] | null> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/referral-links?${queryParams.toString()}`;
    return await this.api.get<ReferralLinkRep[]>(url);
  }

  async delete(referralSlug: string): Promise<ReferralLinkRep | null> {
    const url = `/referral-links/${referralSlug}`;
    return await this.api.delete<ReferralLinkRep>(url);
  }
}
