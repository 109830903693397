import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoTransactionsQueryOptions from "modules/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { startOfYear } from "utils/date";

import bankAccountsQueryHooks from "./bankAccountsQueryHooks";

// If a bankAccountGuid is passed in, it fetches the interest for that bank account.
// Otherwise, it returns interest across all accounts.
const useBankAccountInterestYieldYtd = (bankAccountGuid?: string) => {
  const shouldIncludeAdjustments = useFeatureFlag("INCLUDE_ADJUSTMENT_TRANSACTIONS_AS_INTEREST");

  const bankAccount = bankAccountsQueryHooks.useData({
    status: "all",
    select: (bankAccounts) => {
      return bankAccounts.find((bankAccount) => bankAccount.guid === bankAccountGuid) ?? null;
    },
  });

  const { data } = useSuspenseQuery({
    ...useUnitCoTransactionsQueryOptions({
      accountId: bankAccount?.unitCoDepositAccountId,
      since: startOfYear().format(),
      type: shouldIncludeAdjustments ? ["Interest", "Adjustment"] : ["Interest"],
    }),
  });

  return data.transactions.map((t) => t.attributes.amount).reduce((a, b) => a + b, 0);
};

export default useBankAccountInterestYieldYtd;
