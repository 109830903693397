import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const createChatMessageFormSchema = z.object({
  content: z.string().nonempty(),
});

export type CreateChatMessageFormInputs = z.infer<typeof createChatMessageFormSchema>;

const useCreateChatMessageForm = () =>
  useForm<CreateChatMessageFormInputs>({
    resolver: zodResolver(createChatMessageFormSchema),
    defaultValues: {
      content: "",
    } satisfies CreateChatMessageFormInputs,
  });

export default useCreateChatMessageForm;
