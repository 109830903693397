import { Card } from "@highbeam/unit-node-sdk";
import React, { useMemo } from "react";
import Text from "ui/typography/Text";
import { copyToClipboard } from "utils/clipboard/useCopyToClipboard";

import HighbeamCardField from "./HighbeamCardField";

type Props = {
  card: Card;
  textColor?: "white" | "black";
  isAvailable?: boolean;
};

const HighbeamCardExpiry: React.FC<Props> = ({ card, textColor, isAvailable }) => {
  const placeholder = "••/••";

  const expirationDate = useMemo(() => {
    const [year, month] = card.attributes.expirationDate.split("-");
    return `${month}/${year.substring(2, 4)}`;
  }, [card.attributes.expirationDate]);

  const onClick = () => copyToClipboard(expirationDate);

  return (
    <HighbeamCardField label="Exp" numeric copy={{ onClick }} textColor={textColor}>
      <Text size={14} color={textColor}>
        {isAvailable ? expirationDate : placeholder}
      </Text>
    </HighbeamCardField>
  );
};

export default HighbeamCardExpiry;
