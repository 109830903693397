import React, { ReactNode } from "react";

import AiChatTableBody from "./AiChatTableBody";
import AiChatTableDataCell from "./AiChatTableDataCell";
import AiChatTableDataRow from "./AiChatTableDataRow";
import AiChatTableHead from "./AiChatTableHead";
import AiChatTableHeaderCell from "./AiChatTableHeaderCell";
import AiChatTableHeaderRow from "./AiChatTableHeaderRow";

type Props = {
  children: ReactNode;
};

const AiChatTable: React.FC<Props> = ({ children }) => {
  return (
    <div className="rounded-lg border border-grey-200 px-6 py-2">
      <table className="w-full table-auto border-collapse">{children}</table>
    </div>
  );
};

export default Object.assign(AiChatTable, {
  Body: AiChatTableBody,
  DataCell: AiChatTableDataCell,
  DataRow: AiChatTableDataRow,
  Head: AiChatTableHead,
  HeaderCell: AiChatTableHeaderCell,
  HeaderRow: AiChatTableHeaderRow,
});
