import { FC } from "react";

import OnboardingCard from "./OnboardingCard";

type Props = {
  className?: string;
};

const OnboardingBankApplicationPendingCard: FC<Props> = ({ className }) => {
  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header className="pb-8">
        <OnboardingCard.Heading1 className="mb-2 font-bold">Almost done!</OnboardingCard.Heading1>
        <OnboardingCard.Subheading>
          We are processing your information and we will contact you once your Highbeam account is
          ready to use.
        </OnboardingCard.Subheading>
      </OnboardingCard.Header>
    </OnboardingCard>
  );
};

export default OnboardingBankApplicationPendingCard;
