import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import BillCommentRep from "reps/BillCommentRep";

type BillCommentApi = {
  search: (billId: string, businessGuid: string) => Promise<BillCommentRep.Complete[]>;
  create: (body: BillCommentRep.Creator) => Promise<BillCommentRep.Complete>;
};

const builder: ApiBuilder<BillCommentApi> = (api) => ({
  search: async (billId, businessGuid) => {
    const queryParams = new URLSearchParams({ billId, businessGuid });
    const url = `/accounts-payable/bill-comments?${queryParams}`;
    return (await api.get<BillCommentRep.Complete[]>(url))!;
  },

  create: async (body) => {
    const url = "/accounts-payable/bill-comments";
    return (await api.post<BillCommentRep.Complete>(url, body))!;
  },
});

const useBillCommentApi = () => useBackendV2Api(builder);

export default useBillCommentApi;
