import { ArrowsOutSimple, Storefront } from "@phosphor-icons/react";
import useUnmergeCounterpartyMutation from "modules/counterparty-report/mutations/useUnmergeCounterpartyMutation";
import { FC } from "react";
import Button from "ui/inputs/Button";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  originalCounterparty: string;
  isLastRow: boolean;
  counterparty: string;
  onClose: () => void;
};

const MergedCounterpartiesCounterpartyRow: FC<Props> = ({
  originalCounterparty,
  isLastRow,
  counterparty,
  onClose,
}) => {
  const isOriginalCounterparty = counterparty === originalCounterparty;

  const { mutateAsync: unmergeCounterparty, isPending } = useUnmergeCounterpartyMutation({
    originalCounterparty: originalCounterparty,
  });

  const onClick = async () => {
    await unmergeCounterparty();
    onClose();
  };

  return (
    <div
      className={cn("flex justify-between border-t border-t-grey-100 py-6", isLastRow && "pb-0")}
    >
      <div className="flex items-center gap-x-2">
        <Storefront size={24} />

        <Span className="text-md text-grey-800">{originalCounterparty}</Span>
      </div>

      {!isOriginalCounterparty && (
        <Button variant="tertiary" size="sm" onClick={onClick} isLoading={isPending}>
          <ArrowsOutSimple size={16} />
          Unmerge
        </Button>
      )}
    </div>
  );
};

export default MergedCounterpartiesCounterpartyRow;
