import dayjs from "dayjs";
import React from "react";
import { DateChatElement } from "reps/chat/ChatElement";

type Props = {
  element: DateChatElement;
};

const ResponseMessageDate: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value).tz("America/New_York").format("MMM D, YYYY")}</span>;
};

export default ResponseMessageDate;
