import {
  DotsThreeVertical as DotsThreeVerticalIcon,
  CheckSquareOffset as CheckSquareOffsetIcon,
  Trash as TrashIcon,
} from "@phosphor-icons/react";
import DeleteBillModal from "modules/bills/components/DeleteBillModal";
import MarkBillAsPaidModal from "modules/bills/components/MarkBillAsPaidModal";
import useBill from "modules/bills/queries/useBill";
import { FC, useState } from "react";
import BillRep from "reps/BillRep";
import Button from "ui/inputs/Button";
import Menu from "ui/overlay/Menu";
import { useEventBusDispatcher } from "utils/event-bus";

export const BILL_MARKED_AS_PAID_EVENT = "billMarkedAsPaid";

export type BillMarkedAsPaidEvent = CustomEvent<{
  billId: string;
}>;

export const makeBillMarkedAsPaidEvent = (billId: string): BillMarkedAsPaidEvent =>
  new CustomEvent(BILL_MARKED_AS_PAID_EVENT, {
    detail: { billId },
  });

export const BILL_DELETED_EVENT = "billDeleted";

export type BillDeletedEvent = CustomEvent<{
  billId: string;
}>;

export const makeBillDeletedEvent = (billId: string): BillDeletedEvent =>
  new CustomEvent(BILL_DELETED_EVENT, {
    detail: { billId },
  });

type Props = {
  billId: string;
};

const BillDetailsActions: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });

  const { state: billState, approvalSummary } = bill;
  const isBillDraft = billState === BillRep.State.Draft;
  const isBillPaid = billState === BillRep.State.Paid;
  const approvalStatus = approvalSummary.status;
  const isBillPendingApproval = approvalStatus === "Partial";

  const [isDeleteBillOpen, setIsDeleteBillOpen] = useState(false);
  const [isMarkBillAsPaidOpen, setIsMarkBillAsPaidOpen] = useState(false);

  const dispatchBillMarkedAsPaidEvent = useEventBusDispatcher<BillMarkedAsPaidEvent>();
  const dispatchBillDeletedEvent = useEventBusDispatcher<BillDeletedEvent>();

  return (
    <>
      <Menu
        button={
          <Button variant="tertiary" paddingVariant="square">
            <DotsThreeVerticalIcon size={20} />
          </Button>
        }
      >
        {!isBillDraft && !isBillPaid && !isBillPendingApproval && (
          <Menu.Item icon={<CheckSquareOffsetIcon />} onClick={() => setIsMarkBillAsPaidOpen(true)}>
            Mark bill as paid
          </Menu.Item>
        )}
        <Menu.Item icon={<TrashIcon />} variant="danger" onClick={() => setIsDeleteBillOpen(true)}>
          Delete bill
        </Menu.Item>
      </Menu>

      {isMarkBillAsPaidOpen && (
        <MarkBillAsPaidModal
          billId={billId}
          onClose={() => setIsMarkBillAsPaidOpen(false)}
          onSuccess={() => {
            setIsMarkBillAsPaidOpen(false);
            dispatchBillMarkedAsPaidEvent(makeBillMarkedAsPaidEvent(billId));
          }}
        />
      )}

      {isDeleteBillOpen && (
        <DeleteBillModal
          billId={billId}
          onClose={() => setIsDeleteBillOpen(false)}
          onDeleteSuccessful={() => {
            setIsDeleteBillOpen(false);
            dispatchBillDeletedEvent(makeBillDeletedEvent(billId));
          }}
        />
      )}
    </>
  );
};

export default BillDetailsActions;
