namespace Regex {
  const hostnamePortion = new RegExp("[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]");

  const hostname = new RegExp(`${hostnamePortion.source}?(\\.${hostnamePortion.source}?)*`);

  export const emailAddress = new RegExp(`[A-Za-z0-9.!#$%&'*+/=?^_\`{|}~-]+@${hostname.source}`);

  export const phoneNumber = new RegExp("(\\+\\d{1,3}[.\\-\\s]|\\+1)?([\\d.\\-\\s()]+)");
}

export default Regex;
