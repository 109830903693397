import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import { SwiftFeeTooltipContent } from "modules/international-wires/constants";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Flexpane from "ui/overlay/Flexpane";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { HighbeamInternationalWireTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamInternationalWireTransaction;
};

const UsdAmount: React.FC<Props> = ({ transaction }) => {
  const amountBeforeFee = transaction.amountInCents - transaction.internationalWireFeeInCents;

  return (
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Transferred to payee</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <MoneyAmount
            weight="bold"
            size={18}
            cents={amountBeforeFee}
            withCents
            currency={transaction.receivedCurrency}
            trailingCurrencyCode
          />
        </MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item hideBorder>
        <MetadataList.ItemLabel>
          SWIFT fee <IconWithTooltip color="light" tooltip={<SwiftFeeTooltipContent />} />
        </MetadataList.ItemLabel>
        <MetadataList.ItemValue className="text-right text-sm font-medium">
          <MoneyAmount cents={transaction.internationalWireFeeInCents} trailingCurrencyCode />
        </MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel className="font-bold">Total</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <TransactionFlexpaneAmount
            cents={transaction.amountInCents}
            direction={transaction.direction === "Credit" ? "positive" : "negative"}
            currency={"USD"}
            trailingCurrencyCode
          />
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  );
};

const LocalAmount: React.FC<Props> = ({ transaction }) => {
  const fee = transaction.internationalWireFeeInCents ?? 0;
  const transferedAmountInCents = transaction.receivedAmountInCents - fee;
  const totalAmountInCents = transaction.amountInCents;
  const buyRate = (transferedAmountInCents / totalAmountInCents).toFixed(5);
  return (
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>You sent</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <MoneyAmount
            weight="medium"
            cents={totalAmountInCents}
            direction={transaction.direction === "Credit" ? "positive" : "negative"}
            currency={"USD"}
            trailingCurrencyCode
          />
        </MetadataList.ItemValue>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel>Exchange rate</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          1 USD = {buyRate} {transaction.receivedCurrency}
        </MetadataList.ItemValue>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel>Transferred to payee</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <TransactionFlexpaneAmount
            cents={transferedAmountInCents}
            direction="none"
            currency={transaction.receivedCurrency}
            trailingCurrencyCode
          />
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  );
};

const InternationalTransactionInfoFlexpaneAmountSection: React.FC<Props> = ({ transaction }) => {
  const isLocalCurrency = transaction.receivedCurrency !== "USD";
  return (
    <Flexpane.Section>
      {isLocalCurrency ? (
        <LocalAmount transaction={transaction} />
      ) : (
        <UsdAmount transaction={transaction} />
      )}
    </Flexpane.Section>
  );
};

export default InternationalTransactionInfoFlexpaneAmountSection;
