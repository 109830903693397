import { useSuspenseQuery } from "@tanstack/react-query";
import {
  PayeeAchPaymentMethod,
  PayeeDomesticWirePaymentMethod,
  PayeeInternationalWirePaymentMethod,
  PayeePaymentMethod,
} from "modules/payee-payment-methods/queries/usePayeePaymentMethods";
import { useRefreshPayeesQueries } from "modules/payees/queries/usePayees";
import { useCallback } from "react";
import PayeeRep from "reps/PayeeRep";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import getBillPaymentMethodByPayeeAndBillPaymentMethodType from "../utils/getBillPaymentMethodByPayeeAndBillPaymentMethodType";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

export const billPaymentMethodTypes = [
  "ach" as const,
  "domestic-wire" as const,
  "international-wire-local-currency" as const,
  "international-wire-usd-swift" as const,
];

export type BillPaymentMethodType = (typeof billPaymentMethodTypes)[number];

// Validates type extends type. This is basically the same thing as extending an `interface`.
type BillPaymentMethodFactory<
  T extends {
    billPaymentMethodType: BillPaymentMethodType;
    payeePaymentMethod: PayeePaymentMethod | null;
  },
> = T;

export type BillAchPaymentMethod = BillPaymentMethodFactory<{
  billPaymentMethodType: "ach";
  payeePaymentMethod: PayeeAchPaymentMethod | null;
}>;

export type BillDomesticWirePaymentMethod = BillPaymentMethodFactory<{
  billPaymentMethodType: "domestic-wire";
  payeePaymentMethod: PayeeDomesticWirePaymentMethod | null;
}>;

export type BillInternationalWireLocalCurrency = BillPaymentMethodFactory<{
  billPaymentMethodType: "international-wire-local-currency";
  payeePaymentMethod: PayeeInternationalWirePaymentMethod | null;
}>;

export type BillInternationalWireUsdSwift = BillPaymentMethodFactory<{
  billPaymentMethodType: "international-wire-usd-swift";
  payeePaymentMethod: PayeeInternationalWirePaymentMethod | null;
}>;

export type BillPaymentMethod =
  | BillAchPaymentMethod
  | BillDomesticWirePaymentMethod
  | BillInternationalWireLocalCurrency
  | BillInternationalWireUsdSwift;

type Params = {
  currencyType: "usd" | "international";
  payee: PayeeRep.Complete;
};

export const useBillPaymentMethodsQueryOptions = ({ currencyType, payee }: Params) => {
  return useQueryOptions({
    queryKey: [BILLS_QUERY_KEY, { currencyType, payee }, "payment-methods"],
    queryFn: async () => {
      return billPaymentMethodTypes
        .filter((type) => {
          if (currencyType !== "usd") {
            return type === "international-wire-local-currency";
          }
          return true;
        })
        .map((type) => {
          return getBillPaymentMethodByPayeeAndBillPaymentMethodType(payee, type);
        });
    },
  });
};

// NB(alex): This seems a bit unintuitive to me, we should probably re-think the query keys at some point.
export const useRefreshBillPaymentMethodsQueries = () => {
  const refreshPayeesQueries = useRefreshPayeesQueries();
  const refreshBillsQueries = useRefreshQuery([BILLS_QUERY_KEY]);
  return useCallback(
    () => Promise.all([refreshPayeesQueries(), refreshBillsQueries()]),
    [refreshPayeesQueries, refreshBillsQueries]
  );
};

const useBillPaymentMethods = (params: Params) => {
  const { data } = useSuspenseQuery(useBillPaymentMethodsQueryOptions(params));
  return data;
};

export default useBillPaymentMethods;
