import TransactionFlexpaneCounterparty from "components/common/transaction-flexpane/TransactionFlexpaneCounterparty";
import { getBankAccountsByUnitCoDepositAccountId } from "modules/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";
import { getPaymentCounterparty } from "utils/payments";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

const CounterpartyDetail = () => {
  const { payment } = usePaymentInfoFlexpaneContext();
  const bankAccountsByUnitCoDepositAccountId = bankAccountsQueryHooks.useData({
    status: "all",
    select: getBankAccountsByUnitCoDepositAccountId,
  });
  const counterparty = getPaymentCounterparty(payment, bankAccountsByUnitCoDepositAccountId);
  const positive = payment.attributes.direction === "Debit";

  return (
    <>
      <TransactionFlexpaneCounterparty>
        {counterparty.formattedName ?? counterparty.name}
      </TransactionFlexpaneCounterparty>

      {positive && (
        <Text size={14} color={colors.grey[500]}>
          {formatBankingDate(payment.attributes.createdAt)}
        </Text>
      )}
    </>
  );
};
export default CounterpartyDetail;
