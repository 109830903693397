import { useSuspenseQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import useCreditApplicationQueryOptions from "modules/credit-application/queries/useCreditApplicationQueryOptions";
import useUpdateCreditApplication from "pages/capital/CreditApplicationPage/hooks/useUpdateCreditApplication";
import { FC, useState } from "react";
import { Control, FormProvider, useController } from "react-hook-form";
import CreditApplicationRep from "reps/CreditApplicationRep";
import DropdownV2 from "ui/inputs/DropdownV2";
import Helper from "ui/inputs/Helper";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import ModalV4 from "ui/overlay/ModalV4";

import ApplicationRequirementsSummary from "./ApplicationRequirementsSummary";
import useApplicationRequirementsForm, {
  ApplicationRequirementsFormInputs,
} from "./useApplicationRequirementsForm";

// Calculates the number of months from January 1900 to the current date
const FIRST_SALE_MONTHS_OPTIONS_COUNT = dayjs().diff(dayjs("1900-01-01"), "month");
const CLOSED_BOOKS_MONTHS_OPTIONS_COUNT = 4;
const RECOMMENDED_CLOSED_BOOKS_DAYS = 45;
const MONTHS_BEFORE_THRESHOLD = 3;

type ApplicationRequirementsFormInputProps = {
  control: Control<ApplicationRequirementsFormInputs>;
};

/**
 * Generates an array of Dayjs objects representing dates from the current date,
 * going back a specified number of months.
 */
const generateDateOptions = (months: number) => {
  const now = dayjs();

  return Array.from({ length: months }, (_, i) => now.subtract(i + 1, "month"));
};

/**
 * Generates an array of strings representing dates for closedBooks field from the current date,
 * going back a specified number of months.
 */
type ClosedBooksOption = { value: Dayjs; label: string };

const generateBooksClosedDateOptions = (months: number): ClosedBooksOption[] => {
  const now = dayjs();

  return Array.from({ length: months }, (_, i) => {
    if (i === months - 1) {
      return {
        label: `Before ${now.subtract(i, "month").format("MMMM YYYY")}`,
        value: dayjs("invalid-date-string"),
      };
    }
    return {
      label: now.subtract(i + 1, "month").format("MMMM YYYY"),
      value: now.subtract(i + 1, "month"),
    };
  });
};

const isBooksClosedWarning = (date: Dayjs) => {
  if (!date) return;
  const now = dayjs();
  const latestRecommendedDate = now.subtract(RECOMMENDED_CLOSED_BOOKS_DAYS, "day");
  return date.isBefore(latestRecommendedDate, "month");
};

const FirstSaleDropdown: FC<ApplicationRequirementsFormInputProps> = ({ control }) => {
  const { field, fieldState } = useController({
    name: "firstSale",
    control: control,
  });

  const firstSaleMonthsOptions = generateDateOptions(FIRST_SALE_MONTHS_OPTIONS_COUNT);

  return (
    <>
      <ModalV4.Paragraph className="flex items-center gap-x-2 font-bold">
        When did your brand make its first sale?
        <IconWithTooltip
          tooltip={"We use the date of sale to define how long your brand has been doing business."}
        />
      </ModalV4.Paragraph>

      <div>
        <DropdownV2
          label="Select"
          isClearable
          options={firstSaleMonthsOptions}
          value={field.value}
          getOptionLabel={(option) => option.format("MMMM YYYY")}
          onChange={(val) => field.onChange(val)}
        />
        {fieldState.error && <Helper iconVariant="error">{fieldState.error.message}</Helper>}
      </div>
    </>
  );
};

const ClosedBooksDropdown: FC<ApplicationRequirementsFormInputProps> = ({ control }) => {
  const { field, fieldState } = useController({
    name: "closedBooks",
    control: control,
  });

  const closedBooksMonthsOptions = generateBooksClosedDateOptions(
    CLOSED_BOOKS_MONTHS_OPTIONS_COUNT
  );

  return (
    <>
      <ModalV4.Paragraph className="flex items-center gap-x-2 font-bold">
        When did you last close your books?
        <IconWithTooltip
          tooltip={
            "In order to qualify for Highbeam Capital, we require for you to submit recent financials (P&L and balance sheet)."
          }
        />
      </ModalV4.Paragraph>

      <div>
        <DropdownV2
          label="Select"
          value={
            closedBooksMonthsOptions.find(
              (option) => option.value.format("MMMM YYYY") === field.value?.format("MMMM YYYY")
            ) || null
          }
          options={closedBooksMonthsOptions}
          getOptionLabel={(option) => option.label}
          onChange={(val) => {
            field.onChange(val?.value ?? null);
          }}
        />
        {isBooksClosedWarning(field.value) && !fieldState.error && (
          <Helper iconVariant="warning">
            We recommend providing financials as of{" "}
            {dayjs().subtract(RECOMMENDED_CLOSED_BOOKS_DAYS, "day").format("MMMM YYYY")}. You may
            still qualify capital with older financials, but with a smaller limit
          </Helper>
        )}
        {fieldState.error && <Helper iconVariant="error">{fieldState.error.message}</Helper>}
      </div>
    </>
  );
};

type ApplicationRequirementsModalStep = "questions" | "summary";

type Props = {
  onSuccess: () => void;
};

const ApplicationRequirementsForm: FC<Props> = ({ onSuccess }) => {
  const [step, setStep] = useState<ApplicationRequirementsModalStep>("questions");

  const form = useApplicationRequirementsForm();
  const { data: creditApplicationData } = useSuspenseQuery(useCreditApplicationQueryOptions());
  const { mutateAsync: updateCreditApplication, isPending: isUpdateCreditApplicationLoading } =
    useUpdateCreditApplication();

  const onInvalid = async () => {
    const dirtyFields = form.formState.dirtyFields;
    const shouldSubmitInvalidForm = "firstSale" in dirtyFields && "closedBooks" in dirtyFields;
    const firstSale = form.getValues("firstSale");
    const closedBooks = form.getValues("closedBooks");
    const firstSaleAt = dayjs(firstSale).format("YYYY-MM");
    const booksClosedAtValid = dayjs(closedBooks).isValid();
    const booksClosedAt = booksClosedAtValid
      ? {
          type: CreditApplicationRep.BooksClosedAtType.Exactly,
          yearMonth: dayjs(closedBooks).format("YYYY-MM"),
        }
      : {
          type: CreditApplicationRep.BooksClosedAtType.Before,
          yearMonth: dayjs(dayjs().subtract(MONTHS_BEFORE_THRESHOLD, "month")).format("YYYY-MM"),
        };

    if (shouldSubmitInvalidForm) {
      await updateCreditApplication({
        userProvidedDetails: {
          ...creditApplicationData?.userProvidedDetails,
          firstSaleAt,
          booksClosedAt,
        },
      });
    }
  };

  const onValid = async (data: ApplicationRequirementsFormInputs) => {
    if (step === "questions") {
      setStep("summary");
    } else {
      const { firstSale, closedBooks } = data;
      const firstSaleAt = dayjs(firstSale).format("YYYY-MM");
      const booksClosedAt = dayjs(closedBooks).format("YYYY-MM");
      await updateCreditApplication({
        userProvidedDetails: {
          ...creditApplicationData?.userProvidedDetails,
          firstSaleAt,
          booksClosedAt: {
            type: CreditApplicationRep.BooksClosedAtType.Exactly,
            yearMonth: booksClosedAt,
          },
        },
      });
      onSuccess();
    }
  };

  const onSubmit = form.handleSubmit(onValid, onInvalid);

  const firstSaleMonthValue = form.watch("firstSale");
  const isFirstSaleMonthValueValid = firstSaleMonthValue;

  return (
    <FormProvider {...form}>
      <ModalV4.Form onSubmit={onSubmit}>
        <ModalV4.Header>Questions</ModalV4.Header>
        <ModalV4.Body className="flex flex-col gap-y-4">
          {step === "questions" ? (
            <>
              <FirstSaleDropdown control={form.control} />
              {isFirstSaleMonthValueValid && <ClosedBooksDropdown control={form.control} />}
            </>
          ) : (
            <ApplicationRequirementsSummary />
          )}
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.SubmitButton isLoading={isUpdateCreditApplicationLoading}>
            {step === "questions" ? "Continue" : "My brand meets the requirements"}
          </ModalV4.SubmitButton>
          <ModalV4.CloseButton>{step === "questions" ? "Close" : "Cancel"}</ModalV4.CloseButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </FormProvider>
  );
};

export default ApplicationRequirementsForm;
