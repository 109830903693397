import { useMutation } from "@tanstack/react-query";
import useNewCreditApplication from "modules/credit-application/queries/useNewCreditApplication";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import CreditApplicationRep from "reps/CreditApplicationRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

const useUpdateCreditApplication = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const newCreditApplication = useNewCreditApplication();

  return useMutation({
    mutationFn: (body: CreditApplicationRep.Updater) => {
      return highbeamApi.creditApplication.update(businessGuid, newCreditApplication.guid, body);
    },
    onError: () => {
      notify("info", "Failed to update the credit application. Please try again.");
    },
  });
};

export default useUpdateCreditApplication;
