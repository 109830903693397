import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import BillSyncRep from "reps/BillSyncRep";

type BillSyncApi = {
  sync: (billId: string, dryRun: boolean) => Promise<BillSyncRep.Complete>;
  getByHighbeamBillId: (billId: string) => Promise<BillSyncRep.Complete>;
};

const builder: ApiBuilder<BillSyncApi> = (api) => ({
  sync: async (billId, dryRun) => {
    const url = `/accounting/bill-syncs/sync/${billId}?dryRun=${dryRun}`;
    return (await api.post<BillSyncRep.Complete>(url))!;
  },

  getByHighbeamBillId: async (billId) => {
    const url = `/accounting/bill-syncs?highbeamBillId=${billId}`;
    return (await api.get<BillSyncRep.Complete>(url))!;
  },
});

const useBillSyncApi = () => useBackendV2Api(builder);

export default useBillSyncApi;
