import { Info, FileText } from "@phosphor-icons/react";
import classNames from "classnames";
import { CHAT_WIDGET_SHOWN_EVENT } from "components/ChatWidget";
import BillLineItemsDesktop from "modules/bill-line-items/components/BillLineItemsDesktop";
import useBillSyncRunManager from "modules/bill-syncs/hooks/useBillSyncRunManager";
import useRunInitialBillSync from "modules/bill-syncs/hooks/useRunInitialBillSync";
import {
  BILL_DELETED_EVENT,
  BILL_MARKED_AS_PAID_EVENT,
  BillMarkedAsPaidEvent,
  BillDeletedEvent,
} from "modules/bills/components/BillDetailsActions";
import BillDetailsDocumentsView from "modules/bills/components/BillDetailsDocumentsView";
import BillDetailsInfoEmployeeView from "modules/bills/components/BillDetailsInfoEmployeeView";
import BillDetailsInfoView from "modules/bills/components/BillDetailsInfoView";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SegmentControls from "ui/navigation/SegmentControls";
import useFullPageModalContext from "ui/overlay/FullPageModal/context/useFullPageModalContext";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { useIsMobile } from "utils/device/useMediaQuery";
import { useEventBusListener } from "utils/event-bus";
import useTabState from "utils/tabs/useTabState";

import styles from "./BillDetailsPage.module.scss";
import BillDetailsPageHeader from "./BillDetailsPageHeader";

type Props = {
  billId: string;
};

const BillDetailsPage: FC<Props> = ({ billId }) => {
  const shouldShowEmployeeView = useShouldShowEmployeeView();
  const billPayLineItemsEnabled = useFeatureFlag("BILL_PAY_LINE_ITEMS_UI");
  const { onClose } = useFullPageModalContext();

  useBillSyncRunManager(billId);
  useRunInitialBillSync(billId);

  const isMobile = useIsMobile();

  const tabs = {
    "bill-info": {
      icon: <Info />,
      label: "Bill info",
    },
    documents: {
      icon: <FileText />,
      label: "Documents",
    },
  };

  const [activeTab, setActiveTab] = useTabState(tabs);

  const navigate = useNavigate();

  const onCreatePayment = () => {
    navigate(`/bills/payment?bills=${billId}`);
  };

  const chatWidgetShownHandler = useCallback(() => {
    // Close the bill details modal when the chat widget is shown.
    onClose();
  }, [onClose]);

  useEventBusListener(CHAT_WIDGET_SHOWN_EVENT, chatWidgetShownHandler);

  const billMarkedAsPaidHandler = useCallback(
    (event: BillMarkedAsPaidEvent) => {
      if (event.detail.billId === billId) {
        onClose();
      }
    },
    [billId, onClose]
  );

  useEventBusListener(BILL_MARKED_AS_PAID_EVENT, billMarkedAsPaidHandler);

  const billDeletedHandler = useCallback(
    (event: BillDeletedEvent) => {
      if (event.detail.billId === billId) {
        onClose();
      }
    },
    [billId, onClose]
  );

  useEventBusListener(BILL_DELETED_EVENT, billDeletedHandler);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <BillDetailsPageHeader billId={billId} />

        <div className={styles.billInfoAndDocumentsSegmentControlContainer}>
          <SegmentControls
            size="sm"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
          />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <div
          className={classNames(
            styles.sidebarContainer,
            activeTab === "bill-info" && styles.active
          )}
        >
          {shouldShowEmployeeView ? (
            <BillDetailsInfoEmployeeView billId={billId} />
          ) : (
            <BillDetailsInfoView
              billId={billId}
              onCreatePayment={onCreatePayment}
              onDraftBillSavedAsOpenSuccess={onClose}
            />
          )}
        </div>

        <div
          className={classNames(
            styles.contentContainer,
            activeTab === "documents" && styles.active
          )}
        >
          <BillDetailsDocumentsView billId={billId} />

          {billPayLineItemsEnabled && !isMobile && <BillLineItemsDesktop billId={billId} />}
        </div>
      </div>
    </div>
  );
};

export default BillDetailsPage;
