import { Info } from "@phosphor-icons/react";
import { captureMessage } from "@sentry/react";
import PaymentSummary from "components/PaymentSummary";
import MultiStep from "layouts/MultiStep";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import CapitalAccountBar from "modules/capital-accounts/components/CapitalAccountBar";
import useCreateDrawdownApprovalRequestMutation from "modules/capital-drawdown-approvals/mutations/useCreateDrawdownApprovalRequestMutation";
import useCapitalDrawdownType from "modules/capital-drawdown-approvals/queries/useCapitalDrawdownType";
import useCreateDrawdownMutation from "modules/line-of-credit/mutations/useCreateDrawdownMutation";
import { FC, FormEvent } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Banner from "ui/data-display/Banner";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import { notify } from "ui/feedback/Toast";
import { formatNumberCompact } from "utils/numbers";

import { useDrawdownContext } from "../context/DrawdownProvider";
import {
  DrawdownAmountFormOutputs,
  drawdownAmountFormSchema,
} from "../forms/useDrawdownAmountForm";
import {
  DrawdownInvoiceFormOutputs,
  drawdownInvoiceFormSchema,
} from "../forms/useDrawdownInvoiceForm";

const DRAW_DOWN_CONFIRMATION_FORM_ID = "draw-down-confirmation-form";

type DrawdownConfirmationFormProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  drawdownAmountFormData: DrawdownAmountFormOutputs;
  drawdownInvoiceFormData?: DrawdownInvoiceFormOutputs;
};

const DrawdownConfirmationForm: FC<DrawdownConfirmationFormProps> = ({
  capitalAccountSummary,
  drawdownAmountFormData,
  drawdownInvoiceFormData,
}) => {
  const { prevPathname, idempotencyKey } = useDrawdownContext();
  const amount = drawdownAmountFormData.amount;
  const drawdownFromCapitalAccount = drawdownAmountFormData.drawdownFromCapitalAccount;
  const drawdownToBankAccount = drawdownAmountFormData.drawdownToBankAccount;

  // NB(alex): We need to be sure to use the form's amount values instead of the `capitalAccountSummary` to prevent this incorrect value flickering issue: https://linear.app/highbeam/issue/HB-6453/fix-flickering-incorrect-math-after-capital-account-drawdown
  const currentAmountAvailable = drawdownAmountFormData.drawdownFromCapitalAccount.available;
  const currentRunningBalance = drawdownAmountFormData.drawdownFromCapitalAccount.runningBalance;
  const availableAfterDrawdown = currentAmountAvailable - amount;
  const currentAmountOwed = 0 - currentRunningBalance;
  const amountOwedAfterDrawdown = currentAmountOwed + amount;

  const drawdownType = useCapitalDrawdownType({
    capitalAccountSummary,
    amountInCents: amount,
  });

  const { state } = useLocation();
  const navigate = useNavigate();

  const { mutateAsync: createDrawdown, isPending: isCreateDrawdownPending } =
    useCreateDrawdownMutation();

  const {
    mutateAsync: createDrawdownApprovalRequest,
    isPending: isDrawdownApprovalRequestPending,
  } = useCreateDrawdownApprovalRequestMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (drawdownType.type === "instant-drawdown") {
      await createDrawdown({
        lineOfCreditGuid: capitalAccountSummary.guid,
        amountInCents: amount,
        transferTo: drawdownToBankAccount,
        idempotencyKey: idempotencyKey,
      });
    } else {
      if (!drawdownInvoiceFormData) {
        // NB(alex): This should never happen, but is not type-safe without it.
        notify(
          "error",
          "There was an issue completing your request. Please try again or contact support!"
        );
        captureMessage("drawdownInvoiceFormData missing");
        return;
      }

      if (drawdownType.type === "requires-approval") {
        await createDrawdownApprovalRequest({
          capitalAccountGuid: capitalAccountSummary.guid,
          amount,
          bankAccountGuid: drawdownToBankAccount.guid,
          note: drawdownInvoiceFormData.reasonForDrawdown,
          idempotencyKey: idempotencyKey,
          invoice: drawdownInvoiceFormData.invoice,
        });
      } else if (drawdownType.type === "large-drawdown") {
        await createDrawdownApprovalRequest({
          capitalAccountGuid: capitalAccountSummary.guid,
          amount,
          bankAccountGuid: drawdownToBankAccount.guid,
          note: "",
          idempotencyKey: idempotencyKey,
        });
      }
    }

    navigate(`/capital/${capitalAccountSummary.guid}`);
  };

  const isPending = isCreateDrawdownPending || isDrawdownApprovalRequestPending;

  return (
    <MultiStep.Form id={DRAW_DOWN_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Drawdown amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="From"
              value={<CapitalAccountBar capitalAccount={drawdownFromCapitalAccount} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="To"
              value={<BankAccountBar bankAccount={drawdownToBankAccount} />}
            />
          </PaymentSummary.Section>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Available after drawdown"
              value={<MoneyAmount size={14} cents={availableAfterDrawdown} weight="medium" />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Outstanding (current)"
              value={<MoneyAmount size={14} cents={currentAmountOwed} weight="medium" />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Outstanding after drawdown"
              value={<MoneyAmount size={14} cents={amountOwedAfterDrawdown} weight="medium" />}
            />
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      {drawdownType.type === "requires-approval" && (
        <MultiStep.Section>
          <Banner
            color="blue"
            icon={<Info />}
            paragraph={
              <>
                Please allow for up to <strong>3 business days</strong> for this drawdown to be
                reviewed.
              </>
            }
          />
        </MultiStep.Section>
      )}

      {drawdownType.type === "large-drawdown" && (
        <MultiStep.Section>
          <Banner
            color="purple"
            icon={<Info className="size-6 text-purple-500" />}
            paragraph={
              drawdownType.recentTransactionAmount === 0 ? (
                <>
                  Large drawdowns over ${formatNumberCompact(drawdownType.drawdownRateLimit / 100)}{" "}
                  can take up to 1-2 business days to process.
                </>
              ) : (
                <>
                  Consecutive drawdowns over $
                  {formatNumberCompact(drawdownType.drawdownRateLimit / 100)} in a 48-hour period
                  can take up to 1-2 business days to process.
                </>
              )
            }
          />
        </MultiStep.Section>
      )}

      <MultiStep.Controls>
        <MultiStep.Controls.BackButtonLink
          to={prevPathname || `/capital/${capitalAccountSummary.guid}`}
          disabled={isPending}
          state={state}
        />

        <MultiStep.Controls.NextButton
          form={DRAW_DOWN_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isPending}
        >
          {drawdownType.type === "requires-approval"
            ? "Submit for approval"
            : drawdownType.type === "large-drawdown"
              ? "Submit"
              : "Confirm drawdown"}
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const DrawdownConfirmationView: FC<Props> = ({ capitalAccountSummary }) => {
  const { state } = useLocation();
  const isDrawdownApprovalRequired = capitalAccountSummary.controls.drawdownRequiresApproval;

  const drawdownAmountFormData = drawdownAmountFormSchema.safeParse(
    state?.drawdownAmountFormInputs
  );

  if (!drawdownAmountFormData.success) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}/draw-down`} />;
  }

  if (isDrawdownApprovalRequired) {
    const drawdownInvoiceFormData = drawdownInvoiceFormSchema.safeParse(
      state?.drawdownInvoiceFormInputs
    );

    if (!drawdownInvoiceFormData.success) {
      return <Navigate to={`/capital/${capitalAccountSummary.guid}/draw-down/invoice`} />;
    }

    return (
      <DrawdownConfirmationForm
        capitalAccountSummary={capitalAccountSummary}
        drawdownAmountFormData={drawdownAmountFormData.data}
        drawdownInvoiceFormData={drawdownInvoiceFormData.data}
      />
    );
  }

  return (
    <DrawdownConfirmationForm
      capitalAccountSummary={capitalAccountSummary}
      drawdownAmountFormData={drawdownAmountFormData.data}
    />
  );
};

export default DrawdownConfirmationView;
