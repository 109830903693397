import useBusiness from "modules/business/queries/useBusiness";
import useReferral from "modules/user-referrals/queries/referralLinkQueryHooks";

import { useLeadUserReportedRevenueInDollars } from "../queries/leadQueryHooks";

const useIsQualifiedForOnboardingConnectPlaid = () => {
  const userReportedRevenueInDollars = useLeadUserReportedRevenueInDollars();
  const business = useBusiness();
  const referral = useReferral({ referralSlugOrGuid: business?.referralLinkGuid ?? null });

  return (userReportedRevenueInDollars && userReportedRevenueInDollars >= 10_000_000) || referral;
};

export default useIsQualifiedForOnboardingConnectPlaid;
