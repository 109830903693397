import businessSummariesByMemberUserQueryHooks, {
  filterActiveAndPendingBusinessSummaries,
} from "modules/businesses/queries/businessSummariesByMemberUserQueryHooks";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useUserInvitationsByEmailAddress from "modules/user-invitations/queries/useUserInvitationsByEmailAddress";
import { useSearchParams } from "react-router-dom";

import BusinessDisambiguatorBusinesses from "./BusinessDisambiguatorBusinesses";
import BusinessDisambiguatorLayout from "./BusinessDisambiguatorLayout";

/**
 * This component wraps most of the app, adding support for multi-business.
 * It uses some approaches that are atypical in React,
 * such as returning null to avoid flickers and using window.location.href.
 * Avoid copying these patterns.
 */
const BusinessDisambiguator = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") ?? "/";

  const userGuid = useUserGuid();
  const businessSummaries = businessSummariesByMemberUserQueryHooks.useData({
    userGuid,
    select: filterActiveAndPendingBusinessSummaries,
  });

  const userInvitations = useUserInvitationsByEmailAddress();

  return (
    <BusinessDisambiguatorLayout>
      <BusinessDisambiguatorBusinesses
        businessSummaries={businessSummaries}
        userInvitations={userInvitations}
        redirectTo={redirectTo}
      />
    </BusinessDisambiguatorLayout>
  );
};

export default BusinessDisambiguator;
