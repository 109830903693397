import dayjs, { Dayjs } from "dayjs";
import useConversations from "modules/chat/queries/useConversations";
import React, { useMemo } from "react";
import { ConversationRep } from "reps/chat/ConversationRep";
import cn from "utils/tailwind/cn";

import ChatConversationListGroup from "./ChatConversationListGroup";
import ChatConversationNewChatButton from "./ChatConversationNewChatButton";

const labels = ["Today", "Yesterday", "This week", "This month", "Older"] as const;
type Label = (typeof labels)[number];

type Props = {
  className?: string;
};

const ChatConversationList: React.FC<Props> = ({ className }) => {
  const conversations = useConversations();

  const conversationsByLabel = useMemo(() => groupConversations(conversations), [conversations]);

  return (
    <div
      className={cn(
        "flex w-64 flex-col gap-4 overflow-y-auto border-r border-grey-200 px-2 py-4",
        className
      )}
    >
      <ChatConversationNewChatButton />
      {labels.map((label) => (
        <ChatConversationListGroup
          key={label}
          label={label}
          conversations={conversationsByLabel[label]}
        />
      ))}
    </div>
  );
};

export default ChatConversationList;

const groupConversations = (conversations: ConversationRep[]) =>
  conversations.reduce(
    (acc, conversation) => {
      const label = deriveLabel(dayjs(conversation.createdAt));
      return { ...acc, [label]: [...acc[label], conversation] };
    },
    { Today: [], Yesterday: [], "This week": [], "This month": [], Older: [] } satisfies Record<
      Label,
      ConversationRep[]
    >
  );

const deriveLabel = (createdAt: Dayjs): Label => {
  const now = dayjs();
  if (now.diff(createdAt, "day") < 1) return "Today";
  if (now.diff(createdAt, "day") < 2) return "Yesterday";
  if (now.diff(createdAt, "week") < 1) return "This week";
  if (now.diff(createdAt, "month") < 1) return "This month";
  return "Older";
};
