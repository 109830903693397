import { ArrowsClockwise } from "@phosphor-icons/react";
import useRefreshPlaidBalancesMutation from "modules/plaid/mutations/useRefreshPlaidBalancesMutation";
import Button from "ui/inputs/Button";

const RefreshPlaidBalancesButton = () => {
  const { mutate: refreshBalances, isPending } = useRefreshPlaidBalancesMutation();

  return (
    <Button
      onClick={() => refreshBalances({ forceFetch: true })}
      disabled={isPending}
      variant="ghost"
    >
      {isPending ? (
        "Refreshing..."
      ) : (
        <>
          <ArrowsClockwise />
          Refresh balances
        </>
      )}
    </Button>
  );
};

export default RefreshPlaidBalancesButton;
