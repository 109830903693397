import { useState, useCallback, useEffect } from "react";
import sleep from "utils/async/sleep";

export const copyToClipboard = (str: string) => {
  navigator.clipboard.writeText(str);
};

const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  const copyToClipboardCallback = useCallback(
    async (textToCopy: string) => {
      copyToClipboard(textToCopy);

      if (copied) {
        // Minor UI nicety that quickly re-shows the uncopied state for feedback if one clicks multiple times before the state resets.
        setCopied(false);
        await sleep(100);
      }
      setCopied(true);
    },
    [copied, setCopied]
  );

  return {
    copied: copied,
    copyToClipboard: copyToClipboardCallback,
  };
};

export default useCopyToClipboard;
