import { useSuspenseQuery } from "@tanstack/react-query";
import useBatchGetPaymentMetadataQueryOptions from "modules/transactions/queries/useBatchGetPaymentMetadataQueryOptions";
import { useMemo } from "react";
import { isNotNull } from "utils/array";

import useCardDetailsPageCardTransactionsTableData from "./useCardDetailsPageCardTransactionsTableData";

const useCardDetailsPageCardTransactionsTablePaymentMetadata = (paymentMetadataGuid?: string) => {
  const { data } = useCardDetailsPageCardTransactionsTableData();

  const paymentMetadataGuids = useMemo(() => {
    return data
      .map(({ attributes: { tags } }) =>
        tags && "generalPaymentMetadataGuid" in tags ? tags.generalPaymentMetadataGuid : null
      )
      .filter(isNotNull);
  }, [data]);

  const { data: paymentMetadataItem } = useSuspenseQuery({
    ...useBatchGetPaymentMetadataQueryOptions({ paymentMetadataGuids: paymentMetadataGuids }),
    select: (data) => {
      return data.find(({ guid }) => guid === paymentMetadataGuid) ?? null;
    },
  });

  return paymentMetadataItem;
};

export default useCardDetailsPageCardTransactionsTablePaymentMetadata;
