import { ActionItem, Milestone } from "modules/action-items/types";
import { atom, selector } from "recoil";
import actionItemsState from "state/actionItems/actionItems";

const getAllActionItemsIncomplete = (actionItems: ActionItem[]) => {
  return actionItems.every((item) => !item.finishedActionItemRep);
};

const showGetStartedSetupGuideState = atom<boolean | null>({
  key: "showGetStartedSetupGuide",
  default: null,
});

const isGettingStartedSetupGuideOpenState = selector<boolean | null>({
  key: "isGettingStartedSetupGuideOpen",
  get: ({ get }) => {
    const showGetStartedSetupGuide = get(showGetStartedSetupGuideState);

    // If the user has set the state, return it.
    if (showGetStartedSetupGuide !== null) {
      return showGetStartedSetupGuide;
    }

    const actionItems = get(actionItemsState(Milestone.GetStarted));
    const allActionItemsIncomplete = getAllActionItemsIncomplete(actionItems);

    return allActionItemsIncomplete;
  },
  set: ({ set }, show) => set(showGetStartedSetupGuideState, show),
});

export default isGettingStartedSetupGuideOpenState;
