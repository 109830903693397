import { FC } from "react";
import MetadataList from "ui/data-display/MetadataList";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import ModalV4 from "ui/overlay/ModalV4";

import ChargeCardOverdueBalanceInterestExplanation from "../components/ChargeCardOverdueBalanceInterestExplanation";
import { CapitalAccountSummaryWithChargeCard } from "../utils/isCapitalAccountSummaryWithChargeCard";

type Props = {
  onClose: () => void;
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardAdditionalMetadataModal: FC<Props> = ({
  onClose,
  capitalAccountSummaryWithChargeCard,
}) => {
  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Header>{capitalAccountSummaryWithChargeCard.name} details</ModalV4.Header>
      <ModalV4.Body>
        <MetadataList>
          <MetadataList.Item>
            <MetadataList.ItemLabel>Overdue balance</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              {capitalAccountSummaryWithChargeCard.details.netApr * 100}% APR
              <IconWithTooltip
                icon="info"
                tooltip={
                  <ChargeCardOverdueBalanceInterestExplanation
                    capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
                  />
                }
              />
            </MetadataList.ItemValue>
          </MetadataList.Item>
        </MetadataList>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.CloseButton variant="ghost" />
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default ChargeCardAdditionalMetadataModal;
