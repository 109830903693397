import { ArrowFatLinesUp } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import MultiStep from "layouts/MultiStep";
import CapitalAccountBreadcrumbItem from "modules/capital-accounts/components/CapitalAccountBreadcrumbItem";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import checkIsCapitalAccountSummaryWithChargeCard from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { FC } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";

import CapitalRepaySteps from "./components/CapitalRepaySteps";
import RepayHighbeamCardsAmountView from "./highbeam-cards/RepayHighbeamCardsAmountView";
import RepayHighbeamCardsConfirmationView from "./highbeam-cards/RepayHighbeamCardsConfirmationView";
import RepayLineOfCreditAmountView from "./line-of-credit/RepayLineOfCreditAmountView";
import RepayLineOfCreditConfirmationView from "./line-of-credit/RepayLineOfCreditConfirmationView";

const CapitalRepayContent: FC<{
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
}> = ({ capitalAccountSummary }) => {
  const isCapitalAccountSummaryWithChargeCard =
    checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary);

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to={`/capital/${capitalAccountSummary.guid}`} />
        <DashboardPage.Header.IconTile icon={<ArrowFatLinesUp size={20} weight="light" />} />
        <DashboardPage.Header.Title>Repay {capitalAccountSummary.name}</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <MultiStep steps={<CapitalRepaySteps capitalAccountSummary={capitalAccountSummary} />}>
          {isCapitalAccountSummaryWithChargeCard ? (
            <Routes>
              <Route
                index
                element={
                  <RepayHighbeamCardsAmountView
                    capitalAccountSummaryWithChargeCard={capitalAccountSummary}
                  />
                }
              />
              <Route
                path="/confirm"
                element={
                  <RepayHighbeamCardsConfirmationView
                    capitalAccountSummaryWithChargeCard={capitalAccountSummary}
                  />
                }
              />
            </Routes>
          ) : (
            <Routes>
              <Route
                index
                element={
                  <RepayLineOfCreditAmountView capitalAccountSummary={capitalAccountSummary} />
                }
              />
              <Route
                path="/confirm"
                element={
                  <RepayLineOfCreditConfirmationView
                    capitalAccountSummary={capitalAccountSummary}
                  />
                }
              />
            </Routes>
          )}
        </MultiStep>
      </DashboardPage.Section>
    </>
  );
};

const CapitalRepayPage = () => {
  const { capitalAccountGuid } = useParams();
  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountGuid ?? null,
  });

  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();

  if (capitalAccountSummary === null) {
    return <Navigate to="/capital" />;
  }

  if (capitalAccountSummary && !isAllowedToRepayCapital) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}`} />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem={false}
          />
          <Breadcrumbs.CurrentItem>Repay</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        {capitalAccountSummary ? (
          <CapitalRepayContent capitalAccountSummary={capitalAccountSummary} />
        ) : (
          <DashboardPage.DotsLoader />
        )}
      </DashboardPage>
    </>
  );
};

export default CapitalRepayPage;
