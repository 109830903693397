import { CheckCircle } from "@phosphor-icons/react";
import React from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { Paragraph } from "ui/typography";

type Props = {
  messages: string[][];
};

const ExchangeLoading: React.FC<Props> = ({ messages }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-grey-200 px-5 py-4">
      {messages.map((messageGroup, i) =>
        messageGroup.map((message, j) => (
          <div key={`${i}-${j}`} className="flex flex-row items-center gap-4">
            {i === messages.length - 1 ? (
              <AnimatedSpinner className="flex-none fill-grey-400" size={20} />
            ) : (
              <CheckCircle className="flex-none fill-grey-400" size={20} />
            )}
            <Paragraph className="text-sm">{message} ...</Paragraph>
          </div>
        ))
      )}
    </div>
  );
};

export default ExchangeLoading;
