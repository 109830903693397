import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import { Heading3, Heading4 } from "ui/typography";

import UploadDocumentsSection from "../../components/UploadDocumentsSection";

const ConnectTabUploadDocumentsStep = () => (
  <div className="flex flex-col gap-y-4">
    <Heading3>2. Upload documents</Heading3>
    <UploadDocumentsSection
      subheading={<Heading4 className="font-medium">AP & AR aging reports</Heading4>}
      type={CreditApplicationDocumentRep.DocumentType.ApArAgingReport}
    />
    <UploadDocumentsSection
      subheading={
        <Heading4 className="font-medium">
          (Optional) Any additional documents that might be useful (e.g. term sheets)
        </Heading4>
      }
      type={CreditApplicationDocumentRep.DocumentType.Unknown}
    />
  </div>
);

export default ConnectTabUploadDocumentsStep;
