import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoCheckDepostsQueryOptions, {
  INCOMING_CHECK_DEPOSITS_PARAMS,
} from "modules/unit-co-check-deposits/queries/useUnitCoCheckDepostsQueryOptions";
import useUnitCoPaymentsQueryOptions, {
  INCOMING_PAYMENT_PARAMS,
} from "modules/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";
import useUnitCoReceivedPaymentsQueryOptions from "modules/unit-co-received-payments/queries/useUnitCoReceivedPaymentsQueryOptions";

import { IncomingPayment } from "../types";
import getIncomingPaymentEstDate from "../utils/getIncomingPaymentEstDate";

type Params = {
  accountId?: string;
};

const useIncomingPayments = ({ accountId }: Params = {}) => {
  const { data: unitCheckDeposits } = useSuspenseQuery(
    useUnitCoCheckDepostsQueryOptions({
      accountId,
      ...INCOMING_CHECK_DEPOSITS_PARAMS,
    })
  );

  // Only needed for Plaid transfers
  const { data: unitPayments } = useSuspenseQuery(
    useUnitCoPaymentsQueryOptions({
      accountId,
      ...INCOMING_PAYMENT_PARAMS,
    })
  );

  const { data: receivedPayments } = useSuspenseQuery(
    useUnitCoReceivedPaymentsQueryOptions({
      accountId: accountId,
      status: ["Advanced", "Pending", "PendingReview"],
    })
  );

  const incomingPayments: IncomingPayment[] = [
    ...unitCheckDeposits,
    ...unitPayments,
    ...receivedPayments,
  ];

  const sortedIncomingPayments = incomingPayments.sort((a, b) => {
    const dateA = getIncomingPaymentEstDate(a) ?? a.attributes.createdAt;
    const dateB = getIncomingPaymentEstDate(b) ?? b.attributes.createdAt;

    if (dateA > dateB) {
      return -1;
    } else if (dateA < dateB) {
      return 1;
    }
    return 0;
  });

  return sortedIncomingPayments;
};

export default useIncomingPayments;
