import PayeeValidationRep from "reps/PayeeValidationRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PayeeValidatorApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async validate(
    businessGuid: string,
    validate: PayeeValidationRep.Validate
  ): Promise<PayeeValidationRep.Complete> {
    const url = `/businesses/${businessGuid}/payees/validate`;
    return (await this.api.post<PayeeValidationRep.Complete>(url, validate))!;
  }
}
