import dayjs from "dayjs";
import { capitalize } from "lodash-es";
import { CardBudgetType } from "modules/cards/types";
import { getUsersLocalTimezoneAbbreviation } from "utils/date";
import { pluralize } from "utils/string";

const formatTimeLeft = (milliseconds: number): string => {
  const hours = milliseconds / (1000 * 60 * 60);

  if (hours >= 24) {
    const days = Math.floor(hours / 24);
    return pluralize(days, "day");
  } else if (hours >= 1) {
    const roundedHours = Math.floor(hours);
    return pluralize(roundedHours, "hour");
  } else {
    const minutes = Math.floor(milliseconds / (1000 * 60));
    return pluralize(minutes, "minute");
  }
};

const getTargetDate = (period: CardBudgetType): dayjs.Dayjs => {
  // Thread bank uses Central Time. We will have to update these values / make them dynamic if we ever support other banks with a different time zone.
  // https://highbeamco.slack.com/archives/C02JB5M1599/p1691093921988339
  // https://highbeamco.slack.com/archives/C04D9AQ02KW/p1729033718440809
  switch (period) {
    case "daily":
      return dayjs().tz("America/Chicago").endOf("day").add(1, "second");
    case "monthly":
      return dayjs().tz("America/Chicago").endOf("month").add(1, "second");
  }
};

const getLocalTimeWhenReset = (period: CardBudgetType, localDate: dayjs.Dayjs): string => {
  const timezoneAbbreviation = getUsersLocalTimezoneAbbreviation(localDate.toDate());

  switch (period) {
    case "daily":
      return localDate.format(`hh:mm A [${timezoneAbbreviation}]`);
    case "monthly":
      return localDate.format("MM/DD/YY");
  }
};

const getCopyForCardLimitTimeLeftUntilReset = (period: CardBudgetType): string => {
  const now = dayjs();
  const targetDate = getTargetDate(period);
  const timeRemaining = formatTimeLeft(targetDate.diff(now));
  const localDate = targetDate.local();
  const localTimeWhenReset = getLocalTimeWhenReset(period, localDate);

  return `${capitalize(period)} budget resets in ${timeRemaining} ${period === "daily" ? "at" : "on"} ${localTimeWhenReset}.`;
};

export default getCopyForCardLimitTimeLeftUntilReset;
