import { zodResolver } from "@hookform/resolvers/zod";
import { useSuspenseQuery } from "@tanstack/react-query";
import useUserRolesQueryOptions from "modules/user-roles/queries/useUserRolesQueryOptions";
import { useForm, useFormContext } from "react-hook-form";
import UserRoleRep from "reps/UserRoleRep";
import { z } from "zod";

const schema = z.object({
  userRole: z.custom<UserRoleRep.Complete>((value) => Boolean(value), "Please select a role."),
});

export type EditUserRoleFormInputs = z.input<typeof schema>;
export type EditUserRoleFormOutputs = z.output<typeof schema>;

type Params = {
  defaultValues: {
    userRoleGuid: string;
  };
};

const useEditUserRoleForm = ({ defaultValues: { userRoleGuid } }: Params) => {
  const { data: userRoles } = useSuspenseQuery(useUserRolesQueryOptions());

  return useForm<EditUserRoleFormInputs>({
    mode: "onBlur", // Validate input on blur because button is disabled until form fields are valid.
    resolver: zodResolver(schema),
    defaultValues: {
      userRole: userRoles.find(({ guid }) => guid === userRoleGuid),
    },
  });
};

export default useEditUserRoleForm;

export const useEditUserRoleFormContext = () => {
  return useFormContext<EditUserRoleFormInputs>();
};
