import { CalendarBlank, CalendarPlus } from "@phosphor-icons/react";
import env from "env";
import { FC } from "react";
import IconTile from "ui/icons/IconTile";
import ButtonLink from "ui/inputs/ButtonLink";
import BackButtonLink from "ui/navigation/BackButton";
import { Paragraph } from "ui/typography";

import useIsQualifiedForOnboardingConnectPlaid from "../hooks/useIsQualifiedForOnboardingConnectPlaid";

import OnboardingCard from "./OnboardingCard";

type Props = {
  className?: string;
};

const OnboardingBookATimeCard: FC<Props> = ({ className }) => {
  const isQualifiedForOnboardingConnectPlaid = useIsQualifiedForOnboardingConnectPlaid();

  return (
    <OnboardingCard className={className}>
      <div className="px-8 pb-4 pt-12 text-center">
        <IconTile className="mx-auto" icon={<CalendarPlus />} />
        <OnboardingCard.Heading1 className="mt-3">
          Book a time to see your results
        </OnboardingCard.Heading1>

        <Paragraph className="mt-2 text-md">
          We’re working on estimating how much Highbeam can save you.
          <br />
          Book a time below to review the results with our team!
        </Paragraph>
        <Paragraph className="mt-6 text-md">
          If you’re not ready to book now, we’ll email you in 2 - 3 business days when the sheet is
          ready.
        </Paragraph>

        <ButtonLink to={env.CALENDLY_DEMO_LINK} variant="default" className="mt-5">
          <CalendarBlank size={24} />
          Book a time
        </ButtonLink>
      </div>

      <OnboardingCard.Footer className="mt-8">
        {isQualifiedForOnboardingConnectPlaid && (
          <BackButtonLink to="/onboarding/connect-accounts-and-cards" className="mr-auto" />
        )}

        <ButtonLink to="/onboarding/bank-application" variant="primary">
          Next – Business verification
        </ButtonLink>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingBookATimeCard;
