import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import MultiStep from "layouts/MultiStep";
import useAccountingPlatformConnectionsQueryOptions from "modules/accounting-platforms/queries/useAccountingPlatformConnectionsQueryOptions";
import useCreditApplicationDocumentsQueryOptions from "modules/credit-application/queries/useCreditApplicationDocumentsQueryOptions";
import useCreditApplicationQueryOptions from "modules/credit-application/queries/useCreditApplicationQueryOptions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import CreditApplicationRep from "reps/CreditApplicationRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Tabs from "ui/navigation/Tabs";

import CreditApplicationBackButton from "../../components/CreditApplicationBackButton";
import useGetNextPathname from "../../hooks/useGetNextPathname";
import useUpdateCreditApplication from "../../hooks/useUpdateCreditApplication";

import ConnectTab from "./ConnectTab";
import UploadDocumentsTab from "./UploadDocumentsTab";

export const getCountByDocumentType = (
  documents: CreditApplicationDocumentRep.Complete[],
  type: CreditApplicationDocumentRep.DocumentType
) => documents.filter((doc) => doc.type === type).length;

const UPLOAD_FINANCIAL_DOCUMENTS_FORM_ID = "upload-financial-documents-form";

const financialDocumentsViewTabs = [
  { id: "connect", label: "Connect" },
  { id: "upload", label: "Upload" },
];

const UploadFinancialDocumentsView = () => {
  const { data } = useQuery(useCreditApplicationDocumentsQueryOptions());
  const [activeTab, setActiveTab] = useState("connect");
  const [skipFinancialsReason, setSkipFinancialsReason] =
    useState<CreditApplicationRep.SkipFinancialsRequirementReasonRep>();
  const [skipFinancialsExplanation, setSkipFinancialsExplanation] = useState<string>();
  const [noDocumentsToUpload, setNoDocumentsToUpload] = useState(false);

  const navigate = useNavigate();
  const nextPathname = useGetNextPathname();
  const isSuperusering = useIsSuperusering();

  const { data: creditApplicationData } = useSuspenseQuery(useCreditApplicationQueryOptions());
  const { mutateAsync: updateCreditApplication, isPending: isUpdateCreditApplicationLoading } =
    useUpdateCreditApplication();
  const { data: accountingPlatformConnections } = useQuery(
    useAccountingPlatformConnectionsQueryOptions()
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (noDocumentsToUpload && skipFinancialsReason) {
      await updateCreditApplication({
        userProvidedDetails: {
          ...creditApplicationData?.userProvidedDetails,
          skipFinancialsRequirementReason: {
            reason: skipFinancialsReason,
            explanation: skipFinancialsExplanation,
          },
        },
      });
    }
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  const balanceSheetCount = getCountByDocumentType(
    data || [],
    CreditApplicationDocumentRep.DocumentType.BalanceSheet
  );
  const plStatementCount = getCountByDocumentType(
    data || [],
    CreditApplicationDocumentRep.DocumentType.ProfitAndLossStatement
  );

  const isSkipFinancialsReasonAndExplanationProvided =
    skipFinancialsReason === "Other"
      ? skipFinancialsExplanation?.length
      : Boolean(skipFinancialsReason);

  const accountingSoftwareConnected = accountingPlatformConnections?.length;

  const isSubmitEnabled =
    activeTab === "connect"
      ? accountingSoftwareConnected
      : (balanceSheetCount > 0 && plStatementCount > 0) ||
        (noDocumentsToUpload && isSkipFinancialsReasonAndExplanationProvided);

  return (
    <MultiStep.Form id={UPLOAD_FINANCIAL_DOCUMENTS_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section.Header>
        <MultiStep.Section.Header.Heading>Share your financials</MultiStep.Section.Header.Heading>
        <MultiStep.Section.Header.Subheading>
          Share your business financials so we can get visibility into your cash flow and
          statements.
        </MultiStep.Section.Header.Subheading>
      </MultiStep.Section.Header>

      <Tabs
        variant="rounded"
        tabs={financialDocumentsViewTabs}
        noBorder
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <MultiStep.Section.Spacer />

      {activeTab === "connect" ? (
        <ConnectTab
          onClickNoMatchingPlatforms={() => {
            setActiveTab("upload");
          }}
        />
      ) : (
        <UploadDocumentsTab
          skipFinancialsReason={skipFinancialsReason}
          skipFinancialsExplanation={skipFinancialsExplanation}
          noDocumentsToUpload={noDocumentsToUpload}
          onNoDocumentsToUpload={() => setNoDocumentsToUpload(!noDocumentsToUpload)}
          onSetSkipFinancialsReason={(reason) => setSkipFinancialsReason(reason)}
          onSetSkipFinancialsExplanation={(explanation) =>
            setSkipFinancialsExplanation(explanation)
          }
        />
      )}

      <MultiStep.Controls>
        <CreditApplicationBackButton />

        <MultiStep.Controls.NextButton
          form={UPLOAD_FINANCIAL_DOCUMENTS_FORM_ID}
          disabled={!isSubmitEnabled}
          isLoading={isUpdateCreditApplicationLoading}
        >
          Save and continue
        </MultiStep.Controls.NextButton>

        {isSuperusering && nextPathname && (
          <MultiStep.Controls.SkipButtonLink to={nextPathname} isSuperuserOnly={isSuperusering} />
        )}
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default UploadFinancialDocumentsView;
