import { FC } from "react";
import AccentCard from "ui/data-display/AccentCard";
import { Heading4, Paragraph } from "ui/typography";

type Props = {
  image: string;
  primaryText: string;
  secondaryText?: string;
};

const EmptyState: FC<Props> = ({ image, primaryText, secondaryText }) => {
  return (
    <AccentCard className="flex flex-col items-center p-10 text-center">
      <img src={image} alt={primaryText} height={96} className="mb-8" />

      <Heading4 className="mb-2">{primaryText}</Heading4>

      {secondaryText && (
        <Paragraph className="mb-4 w-full max-w-60 text-sm text-grey-600">
          {secondaryText}
        </Paragraph>
      )}
    </AccentCard>
  );
};

export default EmptyState;
