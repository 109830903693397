import { ArrowRight } from "@phosphor-icons/react";
import capitalAccountSummaryQueryHooks from "modules/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import getCapitalAccountAvailablePercentage from "modules/capital-accounts/utils/getCapitalAccountAvailablePercentage";
import getCapitalAccountRepaymentOptionType from "modules/capital-accounts/utils/getCapitalAccountRepaymentOptionType";
import checkIsCapitalAccountSummaryWithChargeCard from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { FC, ReactNode } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import Divider from "ui/data-display/Divider";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import OfferDetails from "ui/data-display/OfferDetails";
import Pill from "ui/data-display/Pill";
import BarChart from "ui/data-visualization/BarChart";
import Button from "ui/inputs/Button";
import { Heading2 } from "ui/typography";
import cn from "utils/tailwind/cn";

import CapitalAccountIcon from "../CapitalAccountIcon";
import CapitalAccountRepaymentPeriodContent from "../CapitalAccountRepaymentPeriodContent";
import ChargeCardCapitalAccountBarChart from "../ChargeCardCapitalAccountBarChart";
import RequestAdditionalCapitalButton from "../RequestAdditionalCapitalButton";

import CapitalAccountCardAutoPayBankAccountItem from "./CapitalAccountCardAutoPayBankAccountItem";
import CapitalAccountCardAutoPayConnectedStoresItem from "./CapitalAccountCardAutoPayConnectedStoresItem";
import CapitalAccountCardChargeCardAmountDueItem from "./CapitalAccountCardChargeCardAmountDueItem";
import CapitalAccountCardChargeCardOfferDetailsContent from "./CapitalAccountCardChargeCardOfferDetailsContent";
import CapitalAccountCardTodaysInterestItem from "./CapitalAccountCardTodaysInterestItem";

type CapitalAccountCardContainerProps = {
  children: ReactNode;
  className?: string;
};

const CapitalAccountCardContainer: FC<CapitalAccountCardContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        "min-h-[27.5rem] min-w-80 flex-grow-0 rounded-lg px-8 py-9 shadow-xs",
        className
      )}
    >
      {children}
    </div>
  );
};

export const EmptyCapitalAccountCard = () => {
  return (
    <CapitalAccountCardContainer className="flex items-center justify-center bg-grey-50">
      <RequestAdditionalCapitalButton />
    </CapitalAccountCardContainer>
  );
};

type CapitalAccountCardProps = {
  capitalAccountGuid: string;
};

const CapitalAccountCard: FC<CapitalAccountCardProps> = ({ capitalAccountGuid }) => {
  const capitalAccountSummary = capitalAccountSummaryQueryHooks.useDataRequired({
    capitalAccountGuid,
  });

  const availablePercentage = getCapitalAccountAvailablePercentage(capitalAccountSummary);

  return (
    <CapitalAccountCardContainer className="flex flex-col border border-grey-200">
      <div className="mb-9">
        <div className="mb-3 flex items-center justify-between">
          <CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} size={32} />
          {capitalAccountSummary.state === CapitalAccountRep.State.Offered && (
            <Pill color="purple-100">
              {/* TODO(alex): Make this dynamic once the backend passes a dynamic expiration date */}
              {/* <CalendarBlank size={16} /> {14} days to accept */}
              New
            </Pill>
          )}
        </div>
        <Heading2 className="text-grey-800">{capitalAccountSummary.name}</Heading2>
      </div>

      <div className="flex-1">
        <div className="mb-8 flex flex-col gap-y-4">
          <MoneyAmountFraction
            variant="prominent"
            numeratorInCents={capitalAccountSummary.available}
            denominatorInCents={capitalAccountSummary.details.limit}
          />

          {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly ? (
            <BarChart height={10} backgroundVariant="grey-striped">
              <BarChart.Bar
                color={
                  capitalAccountSummary.state === CapitalAccountRep.State.Offered
                    ? "green-disabled"
                    : "green"
                }
                widthPercentage={availablePercentage}
              />
            </BarChart>
          ) : (
            <ChargeCardCapitalAccountBarChart
              capitalAccountSummaryWithChargeCard={capitalAccountSummary}
              height={10}
            />
          )}
        </div>

        {capitalAccountSummary.state === CapitalAccountRep.State.Offered ? (
          <OfferDetails>
            {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
              <CapitalAccountCardChargeCardOfferDetailsContent
                capitalAccountSummaryWithChargeCard={capitalAccountSummary}
              />
            ) : (
              <>
                {capitalAccountSummary.details.netApr > 0 && (
                  <>
                    <OfferDetails.Item
                      label="APR"
                      value={`${capitalAccountSummary.details.netApr * 100}%`}
                    />

                    <Divider orientation="vertical" />

                    <OfferDetails.Item
                      label="Repayment period"
                      value={
                        <CapitalAccountRepaymentPeriodContent
                          capitalAccountSummary={capitalAccountSummary}
                        />
                      }
                    />
                  </>
                )}
              </>
            )}
          </OfferDetails>
        ) : (
          <MetadataList>
            {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly && (
              <CapitalAccountCardTodaysInterestItem
                capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary}
              />
            )}

            {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) && (
              <CapitalAccountCardChargeCardAmountDueItem
                capitalAccountSummaryWithChargeCard={capitalAccountSummary}
              />
            )}

            {getCapitalAccountRepaymentOptionType(capitalAccountSummary) === "PayoutPercentage" && (
              <CapitalAccountCardAutoPayConnectedStoresItem
                capitalAccountSummary={capitalAccountSummary}
              />
            )}

            {getCapitalAccountRepaymentOptionType(capitalAccountSummary) !== "None" && (
              <CapitalAccountCardAutoPayBankAccountItem
                capitalAccountSummary={capitalAccountSummary}
              />
            )}
          </MetadataList>
        )}
      </div>

      {capitalAccountSummary.state === CapitalAccountRep.State.Offered && (
        <div>
          <Button variant="primary" className="w-full">
            See offer details <ArrowRight size={24} weight="thin" />
          </Button>
        </div>
      )}
    </CapitalAccountCardContainer>
  );
};

export default CapitalAccountCard;
