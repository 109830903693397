import useSetPaymentSwitcherAutoPayment from "modules/payment-switcher/mutation/useSetPaymentSwitcherAutoPayment";
import React from "react";
import PaymentSwitcherAutoPaymentRep from "reps/PaymentSwitcherAutoPaymentRep";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import { Column } from "ui/table/Table";

import styles from "./SwitchedCell.module.scss";

type Props = {
  payment: PaymentSwitcherAutoPaymentRep;
};

const SwitchedCell: React.FC<Props> = ({ payment }) => {
  const { mutate: setPaymentSwitcherAutoPayment } = useSetPaymentSwitcherAutoPayment();

  return (
    <CheckboxLabel
      label={null}
      checked={payment.switched}
      textWeight="regular"
      labelClassName={styles.checkboxLabel}
      onChange={() => setPaymentSwitcherAutoPayment({ payment })}
    />
  );
};

export default Object.assign(SwitchedCell, {
  column: {
    title: "Switched",
    cellRender: (payment) => <SwitchedCell payment={payment} />,
    headerClassName: "w-[73px] text-right whitespace-nowrap",
  } satisfies Column<PaymentSwitcherAutoPaymentRep>,
});
