import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BillRep from "reps/BillRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const DAILY_PL_REPORT_QUERY_KEY = "dailyPlReport";

type Params = {
  state?: BillRep.State[];
};

const useDailyPlReportQueryOptions = (params: Params = {}) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [DAILY_PL_REPORT_QUERY_KEY, businessGuid, params],
    queryFn: async () => {
      const now = dayjs();
      return await highbeamApi.dailyPlReport.search(businessGuid, now.subtract(7, "d"), now);
    },
  });
};

export const useRefreshDailyPlReportQueries = () => {
  return useRefreshQuery([DAILY_PL_REPORT_QUERY_KEY]);
};

export const useDailyPlReportQuery = (params: Params = {}) => {
  return useQuery(useDailyPlReportQueryOptions(params));
};

const useDailyPlReport = (params: Params = {}) => {
  const { data } = useSuspenseQuery(useDailyPlReportQueryOptions(params));
  return data;
};

export default useDailyPlReport;
