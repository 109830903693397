import HighbeamBaseApi from "api/HighbeamBaseApi";
import {
  KnowledgeBaseItemRep,
  KnowledgeBaseItemCreatorRep,
  KnowledgeBaseItemUpdateRep,
} from "reps/chat/KnowledgeBaseItemRep";

export default class KnowledgeBaseItemApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async listByBusiness(businessGuid: string): Promise<KnowledgeBaseItemRep[]> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/knowledge-base-items?${queryParams}`;
    return (await this.api.get<KnowledgeBaseItemRep[]>(url))!;
  }

  async create(creator: KnowledgeBaseItemCreatorRep): Promise<KnowledgeBaseItemRep> {
    const url = "/chat/knowledge-base-items";
    return (await this.api.post<KnowledgeBaseItemRep>(url, creator))!;
  }

  async update(
    knowledgeBaseItemId: string,
    update: KnowledgeBaseItemUpdateRep
  ): Promise<KnowledgeBaseItemRep> {
    const url = `/chat/knowledge-base-items/${knowledgeBaseItemId}`;
    return (await this.api.patch<KnowledgeBaseItemRep>(url, update))!;
  }

  async delete(knowledgeBaseItemId: string): Promise<KnowledgeBaseItemRep> {
    const url = `/chat/knowledge-base-items/${knowledgeBaseItemId}`;
    return (await this.api.delete<KnowledgeBaseItemRep>(url))!;
  }
}
