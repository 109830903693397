import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BillRep from "reps/BillRep";
import { notify } from "ui/feedback/Toast";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillApi from "../api/useBillApi";
import useRefreshBillsQueries from "../queries/useRefreshBillsQueries";

type Variables = Omit<BillRep.Creator, "businessGuid">;

const useCreateBillMutation = (
  additionalOptions?: MutationAdditionalOptions<BillRep.Complete, Variables>
) => {
  const billApi = useBillApi();
  const businessGuid = useBusinessGuid();
  const refreshBillsQueries = useRefreshBillsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: (variables: Variables) => {
        return billApi.create({
          ...variables,
          businessGuid,
        });
      },
      onError: () => {
        // NB(alex): There was a pubsub error that resulted in the bill being created but throwing a 500 error. We should fix this and handle these kinds of exceptions appropriately.
        notify("error", "Something went wrong! Please try again.");
      },
      onSuccess: async () => {
        await refreshBillsQueries();
      },
    },
    additionalOptions || {}
  );
};

export default useCreateBillMutation;
