import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

// Token expires every 24 hours, we reset the token every 23 hours
const UNIT_CO_CUSTOMER_TOKEN_EXPIRATION = 23 * 60 * 60 * 1000;

const useUnitCoCustomerTokenQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["unitCoCustomerToken", { businessGuid }],
    queryFn: async () => {
      return (await highbeamApi.unitCoCustomerToken.create(businessGuid)).token;
    },
    staleTime: UNIT_CO_CUSTOMER_TOKEN_EXPIRATION / 2, // NB(alex): Dividing by 2 to see if this helps with the token refresh issue. https://linear.app/highbeam/issue/HB-4626/invalid-or-expired-bearer-token-401-error
    refetchOnWindowFocus: true,
  });
};

export default useUnitCoCustomerTokenQueryOptions;
