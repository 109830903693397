import { useChatWidget } from "components/ChatWidget";
import usePrimaryBankAccount from "modules/bank-accounts/queries/usePrimaryBankAccount";
import { useRecoilValue } from "recoil";
import MetadataList from "ui/data-display/MetadataList";
import InfoIcon from "ui/icons/InfoIcon";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";
import { Paragraph } from "ui/typography";
import { getAccountType } from "utils/account";

import manualTransferFallbackModalState, {
  useCloseManualTransferFallbackModal,
} from "./state/manualTransferFallbackModalState";

const ManualTransferFallbackModalContent = () => {
  const { closeModalWithAnimation } = useModalContext();
  const chat = useChatWidget();
  const primaryBankAccount = usePrimaryBankAccount({ required: true });

  return (
    <>
      <ModalV4.Header icon={<InfoIcon variant="info" />}>Transferring large amounts</ModalV4.Header>

      <ModalV4.Body className="flex flex-col gap-y-4">
        <Paragraph className="text-sm text-grey-600">
          To fund your Highbeam account, you can ACH or wire funds from your other bank into your
          Highbeam account with no limits:
        </Paragraph>

        <MetadataList>
          <MetadataList.Item>
            <MetadataList.ItemLabel>Account name</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{primaryBankAccount.name}</MetadataList.ItemValue>
          </MetadataList.Item>

          <MetadataList.Item>
            <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              <ItemWithCopyTextTooltip textToCopy={primaryBankAccount.accountNumber}>
                {primaryBankAccount.accountNumber}
              </ItemWithCopyTextTooltip>
            </MetadataList.ItemValue>
          </MetadataList.Item>

          <MetadataList.Item>
            <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              <ItemWithCopyTextTooltip textToCopy={primaryBankAccount.routingNumber}>
                {primaryBankAccount.routingNumber}
              </ItemWithCopyTextTooltip>
            </MetadataList.ItemValue>
          </MetadataList.Item>

          <MetadataList.Item>
            <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{getAccountType(primaryBankAccount)}</MetadataList.ItemValue>
          </MetadataList.Item>
        </MetadataList>

        <div className="text-sm">
          <span className="text-grey-600">For further assistance, please contact </span>
          <button
            onClick={() => {
              closeModalWithAnimation();
              chat.show();
            }}
            className="font-medium text-purple-500"
          >
            Highbeam support
          </button>
          .
        </div>
      </ModalV4.Body>
    </>
  );
};

const ManualTransferFallbackModal = () => {
  const state = useRecoilValue(manualTransferFallbackModalState);
  const closeModal = useCloseManualTransferFallbackModal();

  if (!state.isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={closeModal}>
      <ManualTransferFallbackModalContent />
    </ModalV4>
  );
};

export default ManualTransferFallbackModal;
