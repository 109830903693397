import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const CONNECTED_STORE_BALANCES_QUERY_KEY = "connected-stores-balances";

const useRutterBalancesQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CONNECTED_STORE_BALANCES_QUERY_KEY, "rutter", businessGuid],
    queryFn: () => {
      return highbeamApi.rutterBalance.getCurrentBalance(businessGuid);
    },
  });
};

export default useRutterBalancesQueryOptions;
