import BillApprovalStatusPill from "modules/bills/components/BillApprovalStatusPill";
import BillDetailsActions from "modules/bills/components/BillDetailsActions";
import BillPaymentStatusPill from "modules/bills/components/BillPaymentStatusPill";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import useBill from "modules/bills/queries/useBill";
import { billDetailsAutosaveStatusState } from "modules/bills/state/billDetailsAutosaveState";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import AutosaveIndicator from "ui/feedback/AutosaveIndicator";
import FullPageModal from "ui/overlay/FullPageModal";
import { Heading2 } from "ui/typography";

const getTitleTextContent = (bill: BillSummaryRep.Complete) => {
  const { state, invoiceNumber } = bill;

  if (state === BillRep.State.Draft) {
    return "Review bill";
  }

  return invoiceNumber ? `Bill • #${invoiceNumber}` : "Bill details";
};

type Props = {
  billId: string;
};

const BillDetailsPageHeader: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const autosaveStatus = useRecoilValue(billDetailsAutosaveStatusState);
  const isBillDraft = bill.state === BillRep.State.Draft;
  const shouldShowEmployeeView = useShouldShowEmployeeView();

  return (
    <FullPageModal.Header
      className="border-b-0 tablet:border-b"
      actions={
        // NB(lev): we don't show actions for draft bills for now because there aren't any actions
        // available for them to show in the menu. However, this may change in the future!
        shouldShowEmployeeView || isBillDraft ? null : <BillDetailsActions billId={billId} />
      }
    >
      <div className="flex flex-col gap-y-2 tablet:flex-row tablet:items-center tablet:gap-x-4">
        <Heading2>{getTitleTextContent(bill)}</Heading2>

        <div className="flex items-center gap-x-2 tablet:gap-x-4">
          {!isBillDraft && (
            <>
              <BillApprovalStatusPill bill={bill} />
              <BillPaymentStatusPill bill={bill} />
            </>
          )}

          <AutosaveIndicator saveStatus={autosaveStatus} />
        </div>
      </div>
    </FullPageModal.Header>
  );
};

export default BillDetailsPageHeader;
