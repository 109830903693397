import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusiness from "modules/business/queries/useBusiness";
import getBusinessSafeDisplayName from "modules/business/utils/getBusinessSafeDisplayName";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useUserRoleMembershipDisplayName from "modules/user-roles/queries/useUserRoleMembershipDisplayName";
import { FC } from "react";
import Pill from "ui/data-display/Pill";
import UserAvatar from "ui/data-display/UserAvatar";
import { Span } from "ui/typography";

type Props = {
  onClickAvatar: () => void;
};

const UserAvatarMenuDropdownHeader: FC<Props> = ({ onClickAvatar }) => {
  const business = useBusiness();
  const businessMember = useCurrentBusinessMember();
  const userRoleMembershipDisplayName = useUserRoleMembershipDisplayName(
    business.guid,
    useUserGuid()
  );

  const businessDisplayName = getBusinessSafeDisplayName(business);
  const businessMemberDisplayName = businessMember?.displayName;
  const businessMemberInitials = businessMember?.initials ?? "";

  return (
    <div className="flex items-center justify-between gap-x-4 px-4 py-3">
      <div className="flex flex-col justify-center gap-y-0.5">
        <div className="flex items-center gap-x-3">
          <Span className="block text-sm font-bold">{businessMemberDisplayName}</Span>

          <Pill size="2xs" color="grey-100" className="min-w-14 text-left">
            {userRoleMembershipDisplayName ?? (
              // Ensures height of the pill is correct while the data is loading.
              <>&nbsp;</>
            )}
          </Pill>
        </div>
        <Span className="block text-xs font-regular text-grey-600">{businessDisplayName}</Span>
      </div>

      <button onClick={onClickAvatar}>
        <UserAvatar color="purple-light" size={32} initials={businessMemberInitials} />
      </button>
    </div>
  );
};

export default UserAvatarMenuDropdownHeader;
