import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const drawdownInvoiceFormSchema = z.object({
  invoice: z.union([z.instanceof(File), z.null()]).refine((value) => value !== null, {
    message: "Please upload an invoice.",
  }),
  reasonForDrawdown: z.string().min(1, "Please provide a reason for this drawdown."),
});

export type DrawdownInvoiceFormInputs = z.input<typeof drawdownInvoiceFormSchema>;
export type DrawdownInvoiceFormOutputs = z.output<typeof drawdownInvoiceFormSchema>;

type Params = {
  defaultValues: DrawdownInvoiceFormInputs;
};

const useDrawdownInvoiceForm = ({ defaultValues }: Params) => {
  return useForm<DrawdownInvoiceFormInputs, object, DrawdownInvoiceFormOutputs>({
    resolver: zodResolver(drawdownInvoiceFormSchema),
    defaultValues: defaultValues,
  });
};

export default useDrawdownInvoiceForm;
