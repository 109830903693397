import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  counterpartyName: z.string().nonempty("Please enter a name."),
});

export type UpdateCounterpartyAliasFormInputs = z.infer<typeof schema>;

type Params = {
  defaultValues: UpdateCounterpartyAliasFormInputs;
};

const useUpdateCounterpartyAliasForm = ({ defaultValues }: Params) => {
  return useForm<UpdateCounterpartyAliasFormInputs>({
    defaultValues,
    resolver: zodResolver(schema),
  });
};

export default useUpdateCounterpartyAliasForm;
