import BankingInfo from "components/BankingInfo";
import useBusiness from "modules/business/queries/useBusiness";
import BankAccountRep from "reps/BankAccountRep";
import ExternalLink from "ui/navigation/ExternalLink";
import Text from "ui/typography/Text";
import { getPartnerBankNameForAccount } from "utils/account";

import styles from "../PayoutInstructionModal.module.scss";

type Props = {
  accountToDisplay?: BankAccountRep.Complete;
};

const StripeInstructions: React.FC<Props> = ({ accountToDisplay }) => {
  const business = useBusiness();

  return (
    <>
      <div className={styles.step}>
        <div className={styles.stepNumber}>1</div>
        <Text size={14}>Log into your Stripe dashboard.</Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>2</div>
        <Text size={14}>
          From the Stripe dashboard menu, click on the <strong>Settings</strong> icon and then under
          your Business settings, click on{" "}
          <ExternalLink href="https://dashboard.stripe.com/account/payouts">
            External Payout Accounts and Scheduling
          </ExternalLink>
          .
        </Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>3</div>
        <Text size={14}>
          Click <strong>+ Add Bank Account</strong>.
        </Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>4</div>
        <Text size={14}>Enter your Highbeam bank account information.</Text>
      </div>

      {accountToDisplay && (
        <BankingInfo>
          <BankingInfo.Key>Account holder name</BankingInfo.Key>
          <BankingInfo.Value textToCopy={business.name || undefined}>
            {business.name}
          </BankingInfo.Value>

          <BankingInfo.Key>Routing number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.routingNumber}>
            {accountToDisplay.routingNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Account number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.accountNumber}>
            {accountToDisplay.accountNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Bank name</BankingInfo.Key>
          <BankingInfo.Value>
            {getPartnerBankNameForAccount(accountToDisplay)} (Highbeam’s partner bank)
          </BankingInfo.Value>
        </BankingInfo>
      )}

      <div className={styles.step}>
        <div className={styles.stepNumber}>5</div>
        <Text size={14}>
          Click <strong>Add Bank Account</strong> to save.
        </Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>6</div>
        <Text size={14}>Wait a few days until the first payout arrives in Highbeam.</Text>
      </div>
    </>
  );
};

export default StripeInstructions;
