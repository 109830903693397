import { ArrowFatLinesUp } from "@phosphor-icons/react";
import colors from "styles/colors";
import TextCell from "ui/table/cells/TextCell";

const ChargeCardRepaymentType = () => (
  <TextCell darken className="font-medium">
    <ArrowFatLinesUp size={14} color={colors.purple[500]} />
    Repayment
  </TextCell>
);

export default ChargeCardRepaymentType;
