import { ArrowDown, Warning } from "@phosphor-icons/react";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import isMfaInProgressState from "modules/mfa/isMfaInProgressState";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import colors from "styles/colors";
import Banner from "ui/data-display/Banner";
import Divider from "ui/data-display/Divider";
import UserAvatarBar from "ui/data-display/UserAvatarBar";
import ModalV3 from "ui/overlay/ModalV3";

import styles from "./ChangeOrganizationOwnerModal.module.scss";
import useChangeOwnerMutation from "./hooks/useChangeOwnerMutation";
import changeOrganizationOwnerModalState, {
  useChangeOrganizationOwnerModal,
  useChangeOrganizationOwnerModalContext,
  ChangeOrganizationOwnerModalContext,
} from "./state/changeOrganizationOwnerModalState";

const CHANGE_ORGANIZATION_OWNER_FORM = "change-organization-owner";

const ChangeOrganizationOwnerModalForm = () => {
  const { close: closeChangeOrganizationOwnerModal } = useChangeOrganizationOwnerModal();
  const { currentOwner, nextOwner } = useChangeOrganizationOwnerModalContext();

  const currentUserGuid = useUserGuid();
  const isCurrentUser = currentUserGuid === currentOwner.userGuid;

  const { mutate: changeOwner, isPending } = useChangeOwnerMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    changeOwner({
      userGuid: nextOwner.userGuid,
    });
  };

  return (
    <form id={CHANGE_ORGANIZATION_OWNER_FORM} onSubmit={onSubmit}>
      <ModalV3.Header onClose={closeChangeOrganizationOwnerModal}>
        Transfer account ownership
      </ModalV3.Header>

      <ModalV3.Body>
        <UserAvatarBar
          initials={currentOwner.initials}
          fullName={currentOwner.fullName + (isCurrentUser ? " (You)" : "")}
          userRoleName={currentOwner.userRoleName}
        />

        <div className={styles.arrowContainer}>
          <ArrowDown size={24} color={colors.grey[400]} />
        </div>

        <UserAvatarBar
          initials={nextOwner.initials}
          fullName={nextOwner.fullName}
          userRoleName={nextOwner.userRoleName}
        />

        <Divider />

        <Banner
          icon={<Warning />}
          title={`Transfer account ownership to ${nextOwner.fullName}?`}
          paragraph={
            <>
              There can only be one account owner. If you transfer account ownership to{" "}
              <strong>{nextOwner.fullName}</strong> your role will be changed to{" "}
              <strong>Admin</strong>.
            </>
          }
          color="orange"
        />
      </ModalV3.Body>

      <ModalV3.Footer onClose={closeChangeOrganizationOwnerModal}>
        <ModalV3.Footer.SubmitButton
          variant="danger"
          form={CHANGE_ORGANIZATION_OWNER_FORM}
          isLoading={isPending}
        >
          Transfer ownership
        </ModalV3.Footer.SubmitButton>
      </ModalV3.Footer>
    </form>
  );
};

const ChangeOrganizationOwnerModal = () => {
  const { isOpen, close } = useChangeOrganizationOwnerModal();
  const isMfaInProgress = useRecoilValue(isMfaInProgressState);
  const state = useRecoilValue(changeOrganizationOwnerModalState);

  return (
    <ModalV3
      isOpen={isOpen}
      onClose={() => {
        // NB(alex): couldn't figure out a cleaner way to fix an issue where clicking the MFA modal causes this underlying modal to close.
        if (!isMfaInProgress) {
          close();
        }
      }}
    >
      <Suspense fallback={null}>
        {state.isOpen && (
          <ChangeOrganizationOwnerModalContext.Provider
            value={{ currentOwner: state.currentOwner, nextOwner: state.nextOwner }}
          >
            <ChangeOrganizationOwnerModalForm />
          </ChangeOrganizationOwnerModalContext.Provider>
        )}
      </Suspense>
    </ModalV3>
  );
};

export default ChangeOrganizationOwnerModal;
