import TransactionsV2 from "components/TransactionsV2";
import Empty from "components/TransactionsV2/Empty";
import dayjs, { Dayjs } from "dayjs";
import useDownloadCapitalAccountTransactionsCsvMutation from "modules/capital-account-transactions/mutations/useDownloadCapitalAccountTransactionsCsvMutation";
import { useCapitalAccountTransactionsQuery } from "modules/capital-account-transactions/queries/useCapitalAccountTransactions";
import getCapitalTransactionType from "modules/capital-account-transactions/utils/getCapitalTransactionType";
import { Dispatch, FC, SetStateAction, Suspense, useEffect, useState } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalTransactionRep from "reps/CapitalTransactionRep";
import Shimmer from "ui/feedback/Shimmer";
import PageIndicator from "ui/navigation/PageIndicator";
import AmountCell from "ui/table/cells/AmountCell";
import DateTimeCell, { DATE_TIME_CELL_DATE_FORMAT } from "ui/table/cells/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { useFuseSearch } from "utils/fuse";

import CapitalAccountTransactionTransactionTypeCell from "../CapitalAccountTransactionTransactionTypeCell";

const columns = (capitalAccountType: CapitalAccountRep.Type) =>
  [
    {
      title: "Date",
      cellRender: ({ date }) => {
        return <DateTimeCell date={date} />;
      },
    },
    {
      title: "Transaction type",
      cellRender: (capitalTransaction) => {
        return (
          <CapitalAccountTransactionTransactionTypeCell
            capitalTransaction={capitalTransaction}
            capitalAccountType={capitalAccountType}
          />
        );
      },
    },
    {
      title: "Amount",
      align: TableColumnAlignment.RIGHT,
      cellRender: ({ amount }) => (
        <AmountCell cents={amount} direction={amount < 0 ? "negative" : "positive"} />
      ),
    },
  ] satisfies Column<CapitalTransactionRep.Complete>[];

const MAX_TRANSACTIONS_PER_PAGE = 10;

// NB(alex): Experimental pattern, please ask before copying!
type Control<T> = [T, Dispatch<SetStateAction<T>>];

type CapitalAccountTransactionsTableProps = {
  capitalAccountGuid: string;
  capitalAccountType: CapitalAccountRep.Type;
  sinceControl: Control<Dayjs>;
  untilControl: Control<Dayjs>;
  searchControl: Control<string>;
};

const CapitalAccountTransactionsTable: FC<CapitalAccountTransactionsTableProps> = ({
  capitalAccountGuid,
  capitalAccountType,
  sinceControl: [since, setSince],
  untilControl: [until, setUntil],
  searchControl: [search, setSearch],
}) => {
  // NB(alex): We only paginate on the frontend for now.
  const { data: transactions = [], isPending: isLoadingTransactions } =
    useCapitalAccountTransactionsQuery({
      capitalAccountGuid,
      since,
      until,
    });

  const { mutate: downloadCapitalAccountTransactions, isPending: isDownloadingTransactions } =
    useDownloadCapitalAccountTransactionsCsvMutation();

  const filteredTransactions = useFuseSearch(transactions, search, {
    shouldSort: true,
    threshold: 0.3,
    keys: [
      {
        name: "date",
        getFn: (transaction) => dayjs(transaction.date).format(DATE_TIME_CELL_DATE_FORMAT),
      },
      "amount",
      "summary",
      {
        name: "transactionType",
        getFn: (transaction) => getCapitalTransactionType(transaction),
      },
    ],
  });

  const numTransactions = filteredTransactions.length;
  const totalPages = Math.ceil(numTransactions / MAX_TRANSACTIONS_PER_PAGE);

  const [currentPage, setCurrentPage] = useState(0);

  const paginatedTransactions = filteredTransactions.slice(
    currentPage * MAX_TRANSACTIONS_PER_PAGE,
    (currentPage + 1) * MAX_TRANSACTIONS_PER_PAGE
  );

  const count = paginatedTransactions.length;
  const totalCount = transactions.length;

  // Reset pagination when searching.
  const [prevSearch, setPrevSearch] = useState(search);
  useEffect(() => {
    if (search !== prevSearch) {
      setCurrentPage(0);
      setPrevSearch(search);
    }
  }, [search, prevSearch]);

  return (
    <TransactionsV2
      filters={
        <TransactionsV2.Filters
          searchBar={<TransactionsV2.SearchBar value={search} onChange={setSearch} />}
          dateRangePickers={
            <TransactionsV2.DateRangePickers
              fromPicker={
                <TransactionsV2.FromDatePicker
                  fromDate={since.toDate()}
                  toDate={until.toDate()}
                  setFromDate={(date) => setSince(dayjs(date))}
                />
              }
              toPicker={
                <TransactionsV2.ToDatePicker
                  fromDate={since.toDate()}
                  toDate={until.toDate()}
                  setToDate={(date) => setUntil(dayjs(date))}
                />
              }
            />
          }
          paginationInfo={
            <Suspense fallback={<Shimmer />}>
              <TransactionsV2.PaginationInfo count={count} totalCount={totalCount} />
            </Suspense>
          }
          exportButton={
            <TransactionsV2.ExportButton
              isLoading={isDownloadingTransactions}
              onClick={() =>
                downloadCapitalAccountTransactions({
                  capitalAccountGuid: capitalAccountGuid,
                  sinceInclusive: since,
                  untilInclusive: until,
                })
              }
            />
          }
        />
      }
      table={
        !isLoadingTransactions && paginatedTransactions.length === 0 ? (
          <Empty />
        ) : (
          <Table
            data={paginatedTransactions}
            columns={columns(capitalAccountType)}
            rowKey={({ unitCoId }) => unitCoId}
            isLoading={isLoadingTransactions}
          />
        )
      }
      footer={
        <TransactionsV2.Footer
          pageIndicator={
            totalPages > 1 && (
              <PageIndicator
                className="mt-6"
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            )
          }
        />
      }
    />
  );
};

export default CapitalAccountTransactionsTable;
