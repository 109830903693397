import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import { getIsNewCreditApplication } from "../utils/credit-application-type-guards";

import { CREATE_CREDIT_APPLICATIONS_QUERY_KEY } from "./useCreditApplicationsQueryOptions";
import useMostRecentCreditApplication from "./useMostRecentCreditApplication";

const useNewCreditApplication = () => {
  const mostRecentCreditApplication = useMostRecentCreditApplication();
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const shouldCreateNewCreditApplication =
    !mostRecentCreditApplication || !getIsNewCreditApplication(mostRecentCreditApplication);

  const { data: newCreditApplication } = useSuspenseQuery({
    queryKey: [
      CREATE_CREDIT_APPLICATIONS_QUERY_KEY,
      businessGuid,
      shouldCreateNewCreditApplication,
    ],
    queryFn: async () => {
      if (shouldCreateNewCreditApplication) {
        const createdCreditApplication = await highbeamApi.creditApplication.create(businessGuid);
        if (!createdCreditApplication) {
          throw new Error("Failed to create new credit application");
        }
        return createdCreditApplication;
      }

      return mostRecentCreditApplication;
    },
  });

  return newCreditApplication;
};

export default useNewCreditApplication;
