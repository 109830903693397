import MergedCounterpartiesModal, {
  MergedCounterpartiesModalParams,
} from "dialogs/insights/MergedCounterpartiesModal";
import UpdateCounterpartyAliasModal, {
  UpdateCounterpartyAliasModalParams,
} from "dialogs/insights/UpdateCounterpartyAliasModal";
import useCashFlowTableData from "pages/CashFlowPage/data/useCashFlowTableData";
import { useCashFlowContext } from "pages/CashFlowPage/providers/CashFlowProvider";
import Table from "ui/table/Table";
import useModalState from "utils/dialog/useModalState";

import CashFlowCategoryCell from "../CashFlowCategoryCell";
import CashFlowCounterpartyCell from "../CashFlowCounterpartyCell";

const CashFlowTable = () => {
  const { activeTab } = useCashFlowContext();
  const data = useCashFlowTableData();

  const {
    state: updateCounterpartyAliasModalState,
    open: openUpdateCounterpartyAliasModal,
    close: closeUpdateCounterpartyAliasModal,
  } = useModalState<UpdateCounterpartyAliasModalParams>();

  const {
    state: mergedCounterpartiesModalState,
    open: openMergedCounterpartiesModal,
    close: closeMergedCounterpartiesModal,
  } = useModalState<MergedCounterpartiesModalParams>();

  return (
    <>
      {updateCounterpartyAliasModalState.isOpen && (
        <UpdateCounterpartyAliasModal
          onClose={() => closeUpdateCounterpartyAliasModal()}
          params={updateCounterpartyAliasModalState}
        />
      )}

      {mergedCounterpartiesModalState.isOpen && (
        <MergedCounterpartiesModal
          onClose={() => closeMergedCounterpartiesModal()}
          params={mergedCounterpartiesModalState}
        />
      )}

      <Table
        className="mb-96" // HACK(alex): The menu gets cut off by the containing table. This value needs to be slightly taller than the menu's max-height. I think this is a limitation of our Headless UI menu.
        data={data}
        rowKey={(datum) => datum.key}
        columns={[
          {
            key: "counterparty",
            title: activeTab === "money-in" ? "Source" : "Vendor",
            cellRender: (datum) => (
              <CashFlowCounterpartyCell
                datum={datum}
                onClickCounterpartyName={() => {
                  openUpdateCounterpartyAliasModal({
                    counterparty: datum.counterparty,
                    direction:
                      datum.category.groupDirectionValue === "In" ||
                      (datum.category.groupDirectionValue === "Bidirectional" &&
                        Number(datum.amount.amount) > 0)
                        ? "money-in"
                        : "money-out",
                    originalCounterparties: datum.originalCounterparties,
                  });
                }}
                onClickMergedCounterparties={() => {
                  openMergedCounterpartiesModal({
                    counterparty: datum.counterparty,
                    originalCounterparties: datum.originalCounterparties,
                  });
                }}
              />
            ),
          },
          {
            key: "category",
            title: "Category",
            cellRender: (datum) => <CashFlowCategoryCell datum={datum} />,
          },
        ]}
      />
    </>
  );
};

export default CashFlowTable;
