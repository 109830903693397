import PaymentMethodBankingInstitution from "modules/payee-payment-methods/components/PaymentMethodBankingInstitution";
import { forwardRef, ForwardRefRenderFunction } from "react";
import DropdownV2, { DropdownProps } from "ui/inputs/DropdownV2";
import Text from "ui/typography/Text";

import { BillPaymentMethod, BillPaymentMethodType } from "../../queries/useBillPaymentMethods";

const getPaymentMethodName = (billPaymentMethodType: BillPaymentMethodType): string => {
  switch (billPaymentMethodType) {
    case "ach":
      return "ACH";
    case "domestic-wire":
      return "Wire";
    case "international-wire-local-currency":
    case "international-wire-usd-swift":
      return "International wire";
  }
};

const getPaymentMethodDescription = (billPaymentMethodType: BillPaymentMethodType): string => {
  switch (billPaymentMethodType) {
    case "ach":
      return "Free • 1 business day or less";
    case "domestic-wire":
      return "Free • 1 business day";
    case "international-wire-local-currency":
      return "Free · 3 - 5 business days";
    case "international-wire-usd-swift":
      return "SWIFT fee • 3 - 5 business days";
  }
};

const SeparatorDot = () => <span className="mx-1">{"·"}</span>;

type Props = DropdownProps<BillPaymentMethod>;

const BillPaymentMethodsDropdown: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref
) => {
  return (
    <DropdownV2
      ref={ref}
      label="Pay to"
      getOptionLabel={(option) =>
        option?.billPaymentMethodType ? getPaymentMethodName(option.billPaymentMethodType) : ""
      }
      renderOption={(optionProps) => {
        const billPaymentMethod = optionProps.data;
        const payeePaymentMethod = billPaymentMethod.payeePaymentMethod;

        return (
          <DropdownV2.Option
            {...optionProps}
            description={getPaymentMethodDescription(optionProps.data.billPaymentMethodType)}
          >
            <Text weight="medium" className="column-gap-1 flex items-center">
              {payeePaymentMethod && (
                <>
                  <PaymentMethodBankingInstitution payeePaymentMethod={payeePaymentMethod} />
                  <SeparatorDot />
                </>
              )}
              {optionProps.children}

              {billPaymentMethod.billPaymentMethodType === "international-wire-local-currency" &&
                !payeePaymentMethod &&
                " · Local currency"}
            </Text>
          </DropdownV2.Option>
        );
      }}
      components={{
        SingleValue: (singleValueProps) => {
          const billPaymentMethod = singleValueProps.data;
          const payeePaymentMethod = billPaymentMethod.payeePaymentMethod;

          return (
            <DropdownV2.SingleValue {...singleValueProps}>
              <Text weight="medium" className="column-gap-1 flex items-center">
                {payeePaymentMethod && (
                  <>
                    <PaymentMethodBankingInstitution payeePaymentMethod={payeePaymentMethod} />
                    <SeparatorDot />
                  </>
                )}
                {singleValueProps.children}

                {billPaymentMethod.billPaymentMethodType === "international-wire-local-currency" &&
                  !payeePaymentMethod &&
                  " · Local currency"}
              </Text>
            </DropdownV2.SingleValue>
          );
        },
      }}
      {...props}
    />
  );
};

export default forwardRef(BillPaymentMethodsDropdown);
