import React, { ReactNode } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
  children: ReactNode;
  collapsed?: boolean;
};

const DebugMessageContent: React.FC<Props> = ({ className, children, collapsed = false }) => {
  return (
    <div className={cn("flex-grow overflow-x-auto", { "max-h-36": collapsed }, className)}>
      {children}
    </div>
  );
};

export default DebugMessageContent;
