import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useActiveAccountingPlatformConnection from "./useActiveAccountingPlatformConnection";

const useRequiredActiveAccountingPlatformConnection = () => {
  const accountingPlatformConnection = useActiveAccountingPlatformConnection();
  if (!accountingPlatformConnection) {
    throw new RequiredButNotFoundError("No active accounting platform connection found");
  }
  return accountingPlatformConnection;
};

export default useRequiredActiveAccountingPlatformConnection;
