import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const AiChatTableHeaderCell: React.FC<Props> = ({ children }) => {
  return <th className="px-0 py-3 font-medium text-grey-500">{children}</th>;
};

export default AiChatTableHeaderCell;
