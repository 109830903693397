import { Receipt, Eye, DownloadSimple, TrashSimple, File } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { PAYMENT_METADATA_QUERY_KEY } from "modules/payment-metadata/queries/usePaymentMetadataQueryOptions";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import GeneralPaymentMetadataRep from "reps/GeneralPaymentMetadataRep";
import colors from "styles/colors";
import InvoiceUploadedCard from "ui/data-display/InvoiceUploadedCard";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { notify } from "ui/feedback/Toast";
import FileIcon from "ui/icons/FileIcon";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadBlob, downloadWith404Retry } from "utils/download";
import { fileTypeExtensions } from "utils/dropzone/file-types";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { getFileExtension } from "utils/url";
import { v4 as uuidv4 } from "uuid";

import styles from "./InvoiceUpload.module.scss";
import PreviewInvoiceModal from "./PreviewInvoiceModal";

type Props = {
  paymentMetadataGuid: string;
  attachmentType: "receipt" | "invoice";
  unitCoDepositAccountId?: string;
  transactionId?: string;
  setPaymentMetadataGuid?: (paymentMetadataGuid: string) => void;
  setInvoiceName?: (invoiceName: string) => void;
  refreshTransactions?: () => void;
  showDelete?: boolean;
};

const InvoiceUpload: React.FC<Props> = ({
  paymentMetadataGuid,
  attachmentType,
  unitCoDepositAccountId,
  transactionId,
  setPaymentMetadataGuid,
  setInvoiceName,
  refreshTransactions,
  showDelete = true,
}) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshPaymentMetadataQueries = useRefreshQuery([PAYMENT_METADATA_QUERY_KEY]);

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [invoiceFiles, setInvoiceFiles] = useState<File[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>();
  const [paymentMetadata, setPaymentMetadata] = useState<GeneralPaymentMetadataRep.Complete>();

  const [previewInvoiceModalOpen, setPreviewInvoiceModalOpen] = useState<boolean>(false);

  const onDropAccepted = (acceptedFiles: File[]) => {
    setInvoiceFiles([...invoiceFiles, ...acceptedFiles]);
  };

  const onDropRejected = () => {
    notify("error", "Your file must be a PDF, JPG, or PNG under 20MB.");
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      ...fileTypeExtensions.image,
      ...fileTypeExtensions.pdf,
    },
    maxSize: 20971520, // 20mb => 20971520 bytes
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDropRejected,
  });

  const getMetadata = async (paymentMetadataGuid: string) => {
    const paymentMetadata = await highbeamApi.generalPaymentMetadata.get(
      businessGuid,
      paymentMetadataGuid
    );
    if (paymentMetadata) {
      setPaymentMetadata(paymentMetadata);
      setInvoiceName?.(paymentMetadata?.invoiceName ?? "");
    }
    setSelectedFile(paymentMetadata?.invoiceName ?? undefined);
  };

  const processUpload = async (img: ArrayBuffer, fileName: string) => {
    if (!setPaymentMetadataGuid) return;
    setIsUploading(true);
    const invoiceGuid = uuidv4();
    try {
      const uploadUrl = await highbeamApi.transaction.setInvoice(businessGuid, invoiceGuid);
      await fetch(uploadUrl.url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: img,
      });

      const res =
        paymentMetadataGuid === ""
          ? await highbeamApi.generalPaymentMetadata.create(
              businessGuid,
              {
                invoiceGuid: invoiceGuid,
                invoiceName: fileName,
              },
              unitCoDepositAccountId,
              transactionId
            )
          : await highbeamApi.generalPaymentMetadata.update(businessGuid, paymentMetadataGuid, {
              invoiceGuid: invoiceGuid,
              invoiceName: fileName,
            });
      if (res) {
        if (paymentMetadataGuid === "") {
          refreshTransactions?.();
        }
        setPaymentMetadata(res);
        setPaymentMetadataGuid(res.guid);
        setInvoiceName?.(fileName);
        setSelectedFile(fileName);
      } else {
        throw new Error(`Payment metadata not found for guid: ${paymentMetadataGuid}`);
      }
    } catch (e) {
      notify("error", `Something went wrong uploading your ${attachmentType}! Please try again.`);
      captureException(e);
    } finally {
      setIsUploading(false);
      refreshPaymentMetadataQueries();
    }
  };

  useEffect(() => {
    if (invoiceFiles.length > 0) {
      const reader = new FileReader();
      const file = invoiceFiles[invoiceFiles.length - 1];
      reader.readAsArrayBuffer(file);
      reader.onloadend = function () {
        if (reader.result instanceof ArrayBuffer) {
          processUpload(reader.result, file.name);
        }
      };
    } else if (paymentMetadataGuid !== "") {
      getMetadata(paymentMetadataGuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceFiles]);

  // NB(alex): Didn't audit this, just copied code over.
  const invoiceGuid = paymentMetadata?.invoiceGuid!;

  const queryClient = useQueryClient();

  const getInvoiceDownloadUrl = async () => {
    return queryClient.ensureQueryData({
      queryKey: ["invoiceDownloadUrl", { businessGuid, invoiceGuid }],
      queryFn: async () => {
        const invoice = await highbeamApi.transaction.getInvoice(businessGuid, invoiceGuid);
        return invoice.url;
      },
    });
  };

  const download = async () => {
    setIsDownloading(true);
    try {
      const invoiceDownloadUrl = await getInvoiceDownloadUrl();
      const blob = await downloadWith404Retry(invoiceDownloadUrl);
      downloadBlob(paymentMetadata?.invoiceName!, window.URL.createObjectURL(blob));
    } catch (e) {
      notify(
        "error",
        "Something went wrong downloading your " + attachmentType + "! Please try again."
      );
      captureException(e);
    } finally {
      setIsDownloading(false);
    }
  };

  const remove = async () => {
    const res = await highbeamApi.generalPaymentMetadata.deleteInvoice(
      businessGuid,
      paymentMetadata?.guid!
    );
    setPaymentMetadata(res!);
    setSelectedFile(undefined);
    refreshPaymentMetadataQueries();
  };

  const fileExtension = useMemo(() => getFileExtension(selectedFile || ""), [selectedFile]);

  const showUploadView = !selectedFile || isUploading;

  return (
    <>
      {showUploadView ? (
        <div
          {...getRootProps({
            className: classNames(styles.container, isDragActive && styles.dragActive),
          })}
        >
          <input {...getInputProps()} />
          <div className={styles.iconContainer}>
            <Receipt size={16} />
          </div>

          <Text size={14} color={colors.grey[600]} className={styles.descriptionText}>
            {isUploading
              ? "Uploading " + invoiceFiles[invoiceFiles.length - 1].name
              : "Drag & drop or browse for your " + attachmentType + " to upload."}
            {isUploading && <AnimatedSpinner />}
          </Text>
          <Button onClick={open} variant="tertiary" className={styles.browseButton} size="sm">
            Browse
          </Button>
        </div>
      ) : (
        <InvoiceUploadedCard>
          <div className={styles.containerUploaded}>
            <FileIcon fileExtension={fileExtension} />
            <Text size={14} color={colors.grey[900]} className={styles.descriptionText}>
              {selectedFile}
            </Text>
            {fileExtension !== "pdf" && (
              <button
                type="button"
                title="Preview"
                onClick={() => setPreviewInvoiceModalOpen(true)}
                className={styles.iconButton}
              >
                <Eye size={16} />
              </button>
            )}
            <button type="button" title="Download" onClick={download} className={styles.iconButton}>
              {isDownloading ? <AnimatedSpinner /> : <DownloadSimple size={14} />}
            </button>
            {showDelete && (
              <button type="button" title="Delete" onClick={remove} className={styles.iconButton}>
                <TrashSimple size={16} />
              </button>
            )}
          </div>
        </InvoiceUploadedCard>
      )}
      {previewInvoiceModalOpen && (
        <PreviewInvoiceModal
          onClose={() => setPreviewInvoiceModalOpen(false)}
          getInvoiceDownloadUrl={getInvoiceDownloadUrl}
          attachmentType={attachmentType}
        />
      )}
    </>
  );
};

export default InvoiceUpload;
