import DashboardPage from "layouts/DashboardPage";
import useChatConfig from "modules/chat/queries/useChatConfig";
import React from "react";

import ChatStatusIsolatedDataViewItem from "./ChatStatusIsolatedDataViewItem";

const ChatConfigStatus: React.FC = () => {
  const chatConfig = useChatConfig()!;

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Status</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <div className="flex max-w-2xl flex-col gap-4">
        <ChatStatusIsolatedDataViewItem chatConfig={chatConfig} />
      </div>
    </DashboardPage.Section>
  );
};

export default ChatConfigStatus;
