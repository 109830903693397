import { DefaultError } from "@tanstack/react-query";
import { useRefreshBillLineItemsQuery } from "modules/bill-line-items/queries/useBillLineItems";
import useBillSyncRunDispatcher from "modules/bill-syncs/hooks/useBillSyncRunDispatcher";
import { useRefreshBillQuery } from "modules/bills/queries/useBill";
import { useRefreshBillAuditLogsQuery } from "modules/bills/queries/useBillAuditLogs";
import BillLineItemRep from "reps/BillLineItemRep";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBillLineItemApi from "../api/useBillLineItemApi";

type MutationContext = {
  originalBillLineItem: BillLineItemRep.Complete;
};

const useUpdateBillLineItemMutation = (
  billLineItemId: string,
  billId: string,
  additionalOptions?: MutationAdditionalOptions<
    BillLineItemRep.Complete,
    BillLineItemRep.Updater,
    DefaultError,
    MutationContext
  >
) => {
  const billLineItemApi = useBillLineItemApi();

  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillAuditLogsQuery = useRefreshBillAuditLogsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        return billLineItemApi.update(billLineItemId, variables);
      },

      onSuccess: async () => {
        dispatchBillSync();

        await Promise.all([
          refreshBillQuery(),
          refreshBillAuditLogsQuery(),
          refreshBillLineItemsQuery(),
        ]);
      },
    },
    additionalOptions || {}
  );
};

export default useUpdateBillLineItemMutation;
