import HighbeamBaseApi from "api/HighbeamBaseApi";
import { ChatConfigRep, ChatConfigCreatorRep, ChatConfigUpdateRep } from "reps/chat/ChatConfigRep";

export default class ChatConfigApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusiness(businessGuid: string): Promise<ChatConfigRep | null> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/chat-configs?${queryParams}`;
    return await this.api.get<ChatConfigRep>(url);
  }

  async create(creator: ChatConfigCreatorRep): Promise<ChatConfigRep> {
    const url = "/chat/chat-configs";
    return (await this.api.post<ChatConfigRep>(url, creator))!;
  }

  async update(chatConfigId: string, update: ChatConfigUpdateRep): Promise<ChatConfigRep> {
    const url = `/chat/chat-configs/${chatConfigId}`;
    return (await this.api.patch<ChatConfigRep>(url, update))!;
  }
}
