import { DepositAccountLimits } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery, useQueryClient } from "@tanstack/react-query";
import useUnitCoAccountLimitQueryOptions, {
  makeQueryKey,
  makeQueryFn,
} from "modules/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { useCallback } from "react";

import { AccountLimitInfo } from "../types";

const makeAccountLimitInfo = (unitDepositAccountLimits: DepositAccountLimits): AccountLimitInfo => {
  const { attributes } = unitDepositAccountLimits;
  const { ach, wire } = attributes;

  const achLimits = ach.limits;
  const achTotalsDaily = ach.totalsDaily;
  const achTotalsMonthly = ach.totalsMonthly;

  const achDailyCreditLimit = achLimits.dailyCredit;
  const achDailyCreditUsed = achTotalsDaily.credits;
  const achDailyCreditRemaining = achDailyCreditLimit - achDailyCreditUsed;
  const achMonthlyCreditLimit = achLimits.monthlyCredit;
  const achMonthlyCreditUsed = achTotalsMonthly.credits;
  const achMonthlyCreditRemaining = achMonthlyCreditLimit - achMonthlyCreditUsed;

  const wireLimits = wire.limits;
  const wireTotalsDaily = wire.totalsDaily;
  const wireTotalsMonthly = wire.totalsMonthly;

  const wireDailyTransferLimit = wireLimits.dailyTransfer;
  const wireDailyTransferUsed = wireTotalsDaily.transfers;
  const wireDailyTransferRemaining = wireDailyTransferLimit - wireDailyTransferUsed;
  const wireMonthlyTransferLimit = wireLimits.monthlyTransfer;
  const wireMonthlyTransferUsed = wireTotalsMonthly.transfers;
  const wireMonthlyTransferRemaining = wireMonthlyTransferLimit - wireMonthlyTransferUsed;

  return {
    achDailyCreditLimit,
    achDailyCreditUsed,
    achDailyCreditRemaining,
    achMonthlyCreditLimit,
    achMonthlyCreditUsed,
    achMonthlyCreditRemaining,
    wireDailyTransferLimit,
    wireDailyTransferUsed,
    wireDailyTransferRemaining,
    wireMonthlyTransferLimit,
    wireMonthlyTransferUsed,
    wireMonthlyTransferRemaining,
  };
};

const useAccountLimitInfo = (unitCoDepositAccountId?: string): AccountLimitInfo => {
  const { data } = useSuspenseQuery(useUnitCoAccountLimitQueryOptions(unitCoDepositAccountId));

  return makeAccountLimitInfo(data);
};

// This hook returns a "retriever" function that can be used to fetch account limit info
// based on data which may (or may not) already be in the query cache. Calling the retriever
// function will trigger a query to fetch the relevant data if it's not already in the cache,
// or otherwise use the cached data.
// An intended use case for this is to allow retriever functions to be passed down from a component
// into a form schema, so that the schema may access query data when it needs to in order to implement
// custom validation logic.
export const useAccountLimitInfoRetriever = () => {
  const queryClient = useQueryClient();
  const unitApi = useUnitApi();

  return useCallback(
    async (unitCoDepositAccountId?: string) => {
      const data = await queryClient.ensureQueryData({
        queryKey: makeQueryKey(unitCoDepositAccountId),
        queryFn: makeQueryFn(unitApi, unitCoDepositAccountId),
      });

      return makeAccountLimitInfo(data);
    },
    [queryClient, unitApi]
  );
};

export default useAccountLimitInfo;
