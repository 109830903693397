import { Money } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import PaymentApprovalFlexpane from "dialogs/PaymentApprovalFlexpane";
import RecurringPaymentInfoFlexpane from "dialogs/RecurringPaymentInfoFlexpane";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import isMfaInProgressState from "modules/mfa/isMfaInProgressState";
import usePaymentApprovalsQueryOptions, {
  PaymentApprovalStatus,
} from "modules/payment-approvals/queries/usePaymentApprovalsQueryOptions";
import RecurringPayments from "pages/payments/PaymentsOverviewPage/RecurringPayments";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import Tabs from "ui/navigation/Tabs";
import Text from "ui/typography/Text";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";
import useIsAllowedToViewSendMoney from "utils/permissions/useIsAllowedToViewSendMoney";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import DraftedPaymentsTable from "./DraftedPaymentsTable";
import styles from "./PaymentsOverview.module.scss";

const CreatePaymentButton = () => {
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToSendMoney = isAllowedToCreatePaymentDrafts || isAllowedToApprovePayments;

  return (
    <ButtonLinkWithTooltip
      to="/send-money"
      variant="secondary"
      disabled={!isAllowedToSendMoney}
      tooltip={
        !isAllowedToSendMoney && <Text size={14}>You don’t have permission to send money.</Text>
      }
    >
      {isAllowedToCreatePaymentDrafts && !isAllowedToApprovePayments
        ? "Draft a payment"
        : "Create payment"}
    </ButtonLinkWithTooltip>
  );
};

const DraftedPaymentsSection = () => {
  const { data: paymentApprovals } = useQuery(usePaymentApprovalsQueryOptions("Open"));
  const [activeTab, setActiveTab] = useState<PaymentApprovalStatus>("Open");

  const count = paymentApprovals?.length;

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Drafted payments</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <Tabs
          className={styles.draftPaymentsTabs}
          variant="rounded"
          tabs={[
            { id: "Open", label: "Awaiting approval", count: count || undefined },
            { id: "Rejected", label: "Rejected" },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <DraftedPaymentsTable paymentApprovalStatus={activeTab} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

const PaymentsOverviewPage = () => {
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToViewSendMoney = useIsAllowedToViewSendMoney();
  const { recurringPaymentId, paymentApprovalGuid } = useParams();
  const navigate = useNavigate();

  const isMfaInProgress = useRecoilValue(isMfaInProgressState);

  return (
    <>
      {isAllowedToApprovePayments && (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => {
            // NB(alex): Necessary for resetting the error boundary after navigation takes place.
            resetErrorBoundary();

            if (error instanceof RequiredButNotFoundError) {
              return <Navigate to="/payments" state={DISABLE_SCROLL_TO_TOP_STATE} />;
            }

            // Trigger a different error boundary if it isn't a RequiredButNotFoundError
            throw error;
          }}
        >
          <PaymentApprovalFlexpane
            paymentApprovalGuid={paymentApprovalGuid}
            onClose={() => {
              // NB(alex): Without the `isMfaInProgress`, clicking "Continue" closes the flexpage, thus causing the approval to fail.
              !isMfaInProgress && navigate("/payments", { state: DISABLE_SCROLL_TO_TOP_STATE });
            }}
          />

          <RecurringPaymentInfoFlexpane
            recurringPaymentId={recurringPaymentId}
            onClose={() => navigate("/payments", { state: DISABLE_SCROLL_TO_TOP_STATE })}
          />
        </ErrorBoundary>
      )}

      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Payments</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header actions={<CreatePaymentButton />}>
          <DashboardPage.Header.IconTile
            icon={<Money />} // TODO(alex): make this <MoneyInOut /> to match designs
          />
          <DashboardPage.Header.Title>Payments</DashboardPage.Header.Title>
        </DashboardPage.Header>

        {isAllowedToViewSendMoney && <DraftedPaymentsSection />}

        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              Scheduled payments
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <DashboardPage.Section.Body>
            <RecurringPayments />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default PaymentsOverviewPage;
