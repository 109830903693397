import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import getBankAccountDropdownOptions, {
  ALL_BANK_ACCOUNTS_OPTION,
} from "modules/bank-accounts/utils/getBankAccountDropdownOptions";
import getBankAccountOptionsByUnitId from "modules/bank-accounts/utils/getBankAccountOptionsByUnitId";
import { useMemo, useState } from "react";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import styles from "./AccountsStatements.module.scss";
import AccountStatements from "./AccountStatements";
import AccountStatementsCount from "./AccountStatementsCount";
import AccountTaxForms from "./AccountTaxForms/AccountTaxForms";
import AccountTaxFormsCount from "./AccountTaxForms/AccountTaxFormsCount";
import {
  DocumentsDropdownOption,
  useActiveDocumentTypeSearchParam,
} from "./utils/useActiveDocumentTypeSearchParam";

const ALL_DOCUMENT_TYPES: Option[] = [
  { label: "Statements", value: "statements" },
  { label: "Tax forms", value: "tax" },
];

const DOCUMENT_TYPES_BY_VALUE = ALL_DOCUMENT_TYPES.reduce(
  (map: Record<string, Option>, option: Option) => ({
    ...map,
    [option.value]: option,
  }),
  {}
);

const AccountsStatementsPage = () => {
  const [activeDocumentType, setActiveDocumentType] = useActiveDocumentTypeSearchParam();
  const accountOptions = bankAccountsQueryHooks.useData({
    status: "all",
    select: (bankAccounts) => getBankAccountDropdownOptions(bankAccounts), // DEPRECATED
  });

  const accountOptionsById = useMemo(() => {
    return getBankAccountOptionsByUnitId(accountOptions);
  }, [accountOptions]);

  const [selectedUnitCoAccountId, setSelectedUnitCoAccountId] = useState(accountOptions[0].value);

  // NB(alex): We have to do this hack because of how `ALL_BANK_ACCOUNTS_OPTION` works... turns out it's tightly coupled with our transactions table + `HighbeamTransaction` + Dropdown v1. We should be able to remove this hack once we delete `HighbeamTransaction`.
  const accountId =
    selectedUnitCoAccountId === ALL_BANK_ACCOUNTS_OPTION.value
      ? undefined
      : selectedUnitCoAccountId;

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Documents</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.BackCaret to="/accounts" />
          <DashboardPage.Header.Title>Documents</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          <div className={styles.content}>
            <div className={styles.infoBar}>
              <div className={styles.actions}>
                <Dropdown
                  label="Document type"
                  onChange={(e) => {
                    setActiveDocumentType(e.value as DocumentsDropdownOption);
                  }}
                  value={DOCUMENT_TYPES_BY_VALUE[activeDocumentType]}
                  options={ALL_DOCUMENT_TYPES}
                  className={styles.dropdown}
                  hideCursor
                />
                {accountOptions.length > 1 && (
                  <Dropdown
                    label="Show statements for"
                    onChange={(e) => {
                      setSelectedUnitCoAccountId(e.value);
                    }}
                    value={accountOptionsById[selectedUnitCoAccountId]}
                    options={accountOptions}
                    className={styles.dropdown}
                    hideCursor
                  />
                )}
              </div>
              {activeDocumentType === "statements" && (
                <AccountStatementsCount accountId={accountId} />
              )}
              {activeDocumentType === "tax" && <AccountTaxFormsCount accountId={accountId} />}
            </div>

            {activeDocumentType === "statements" && <AccountStatements accountId={accountId} />}
            {activeDocumentType === "tax" && <AccountTaxForms accountId={accountId} />}
          </div>
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default AccountsStatementsPage;
