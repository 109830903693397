import { ArrowFatLinesDown } from "@phosphor-icons/react";
import isAbleToDrawDown from "modules/capital-accounts/utils/isAbleToDrawDown";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import useIsAllowedToDrawDownCapital from "utils/permissions/useIsAllowedToDrawDownCapital";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  className?: string;
};

const LineOfCreditDrawdownButtonLink: FC<Props> = ({ className, capitalAccountSummary }) => {
  const isAllowedToDrawDownCapital = useIsAllowedToDrawDownCapital();

  return (
    <ButtonLinkWithTooltip
      to={`/capital/${capitalAccountSummary.guid}/draw-down`}
      fullWidth
      className={className}
      variant="secondary"
      disabled={!isAllowedToDrawDownCapital || !isAbleToDrawDown(capitalAccountSummary)}
      tooltip={!isAllowedToDrawDownCapital && "You don’t have permission to draw down."}
    >
      <ArrowFatLinesDown size={20} weight="light" />
      Draw down
    </ButtonLinkWithTooltip>
  );
};

export default LineOfCreditDrawdownButtonLink;
