import { Clock } from "@phosphor-icons/react";
import dayjs from "dayjs";
import chargeCardProgramQueryHooks, {
  ChargeCardProgram,
} from "modules/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import { checkIsCashCreditTerm } from "modules/charge-cards/utils/check-credit-terms";
import { FC, ReactNode } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalRepaymentAmountRep from "reps/CapitalRepaymentAmountRep";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import BarChart from "ui/data-visualization/BarChart";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Span, Strong } from "ui/typography";

import capitalAccountSummaryQueryHooks from "../queries/capitalAccountSummaryQueryHooks";
import capitalRepaymentAmountsQueryHooks from "../queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "../utils/isCapitalAccountSummaryWithChargeCard";

import { ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuid } from "./ChargeCardRepaymentPeriodDateRange";

const classes = {
  legendItem: "p-4 border-b border-grey-100 last-of-type:border-b-0",
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
  chargeCardProgram: ChargeCardProgram;
  overdueAmountComplete?: CapitalRepaymentAmountRep.AmountComplete;
  lastStatementAmountComplete?: CapitalRepaymentAmountRep.AmountComplete;
  currentStatementAmountComplete?: CapitalRepaymentAmountRep.AmountComplete;
};

const ChargeCardCapitalAccountBarChartLegend: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
  chargeCardProgram,
  overdueAmountComplete,
  lastStatementAmountComplete,
  currentStatementAmountComplete,
}) => {
  const isCashCreditTerm = checkIsCashCreditTerm(chargeCardProgram.creditTerms);

  // TODO(alex): HB-6272 Need to wait for new data to be returned by `CapitalRepaymentAmountApi`.
  const overdueBalanceDueBy = null;

  return (
    <div className="mt-6 rounded-lg border border-grey-200">
      {overdueAmountComplete && (
        <div className={classes.legendItem}>
          <div className="mb-3 flex justify-between">
            <div className="flex items-center gap-x-4">
              <BarChart
                height={12}
                backgroundVariant="transparent"
                className="w-3"
                roundedCornerVariant="rounded-none"
              >
                <BarChart.Bar color="red-striped" widthPercentage={100} />
              </BarChart>
              <Span className="flex items-center gap-x-2 text-sm font-medium text-red-800">
                Overdue
                {overdueBalanceDueBy && (
                  <IconWithTooltip
                    tooltip={
                      <>
                        Overdue balances are due by{" "}
                        <Strong className="font-medium">{overdueBalanceDueBy}</Strong>. Missing a
                        daily repayment can result in associated cards being frozen.
                      </>
                    }
                  />
                )}
                <Pill color="grey-100">
                  {capitalAccountSummaryWithChargeCard.details.apr * 100}% APR
                </Pill>
              </Span>
            </div>

            <MoneyAmount cents={overdueAmountComplete.amount} className="text-sm font-medium" />
          </div>

          <div className="flex justify-between pl-7">
            <Span className="text-sm text-red-800">Next overdue payment</Span>
            <MoneyAmount
              cents={overdueAmountComplete.nextRepaymentAmount}
              className="text-sm font-medium"
            />
          </div>
        </div>
      )}

      {lastStatementAmountComplete && (
        <div className={classes.legendItem}>
          <div className="flex justify-between">
            <div className="flex items-center gap-x-4">
              <BarChart
                height={12}
                backgroundVariant="transparent"
                className="w-3"
                roundedCornerVariant="rounded-none"
              >
                <BarChart.Bar color="orange-striped" widthPercentage={100} />
              </BarChart>
              <Span className="flex items-center gap-x-2 text-sm font-medium text-grey-800">
                Last statement
                {lastStatementAmountComplete.nextRepaymentDate && (
                  <Pill
                    color="yellow-100"
                    iconLeft={({ sizeClassName }) => <Clock className={sizeClassName} />}
                  >
                    Due {dayjs(lastStatementAmountComplete.nextRepaymentDate).format("MMM D")}
                  </Pill>
                )}
              </Span>
            </div>

            <MoneyAmount
              cents={lastStatementAmountComplete.amount}
              className="text-sm font-medium"
            />
          </div>

          {!isCashCreditTerm && (
            <div className="mt-1 flex justify-between pl-7 pr-4">
              <Span className="text-sm text-grey-600">
                <ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuid
                  capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
                  statementPeriod="previous"
                />
              </Span>
            </div>
          )}
        </div>
      )}

      {currentStatementAmountComplete && (
        <div className={classes.legendItem}>
          <div className="flex justify-between">
            <div className="flex items-center gap-x-4">
              <BarChart
                height={12}
                backgroundVariant="transparent"
                className="w-3"
                roundedCornerVariant="rounded-none"
              >
                <BarChart.Bar color="grey-striped" widthPercentage={100} />
              </BarChart>
              <Span className="flex items-center gap-x-2 text-sm font-medium text-grey-800">
                Current statement
                {currentStatementAmountComplete.nextRepaymentDate && (
                  <Pill
                    color="grey-100"
                    iconLeft={({ sizeClassName }) => <Clock className={sizeClassName} />}
                  >
                    Due {dayjs(currentStatementAmountComplete.nextRepaymentDate).format("MMM D")}
                  </Pill>
                )}
              </Span>
            </div>

            <MoneyAmount
              cents={currentStatementAmountComplete.amount}
              className="text-sm font-medium"
            />
          </div>

          {!isCashCreditTerm && (
            <div className="mt-1 flex justify-between pl-7 pr-4">
              <Span className="text-sm text-grey-600">
                <ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuid
                  capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
                  statementPeriod="current"
                />
              </Span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChargeCardCapitalAccountBarChartLegend;

// Wrappers

type ChargeCardCapitalAccountBarChartLegendByCapitalAccountGuidProps = {
  capitalAccountGuid: string;
  notFoundFallback?: ReactNode;
};

export const ChargeCardCapitalAccountBarChartLegendByCapitalAccountGuid: FC<
  ChargeCardCapitalAccountBarChartLegendByCapitalAccountGuidProps
> = ({ capitalAccountGuid, notFoundFallback = null }) => {
  const capitalAccountSummary = capitalAccountSummaryQueryHooks.useDataRequired({
    capitalAccountGuid,
  });
  const chargeCardProgram = chargeCardProgramQueryHooks.useDataRequired({
    capitalAccountGuid,
    state: capitalAccountSummary.state,
  });

  const capitalRepaymentAmounts = capitalRepaymentAmountsQueryHooks.useData({
    capitalAccountGuid: capitalAccountGuid,
    enabled: capitalAccountSummary?.state === CapitalAccountRep.State.Active,
    select: (data) => data.amounts,
  });

  const currentStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "CurrentStatement"
  );
  const lastStatementAmountComplete = capitalRepaymentAmounts.find(
    (amount) => amount.type === "LastStatement"
  );
  const overdueAmountComplete = capitalRepaymentAmounts.find((amount) => amount.type === "Overdue");

  const hasRepaymentAmount =
    overdueAmountComplete || lastStatementAmountComplete || currentStatementAmountComplete;

  if (capitalAccountSummary.type !== CapitalAccountRep.Type.ChargeCardOnly || !hasRepaymentAmount) {
    return <>{notFoundFallback}</>;
  }

  return (
    <ChargeCardCapitalAccountBarChartLegend
      capitalAccountSummaryWithChargeCard={capitalAccountSummary}
      chargeCardProgram={chargeCardProgram}
      overdueAmountComplete={overdueAmountComplete}
      lastStatementAmountComplete={lastStatementAmountComplete}
      currentStatementAmountComplete={currentStatementAmountComplete}
    />
  );
};
