import { CheckCircle } from "@phosphor-icons/react";
import classNames from "classnames";
import { useFinancialInstitutionQuery } from "modules/financial-institutions/queries/useFinancialInstitution";
import { BankingInfo, PaymentMethod, PaymentMethodOption } from "pages/SendMoneyPage/utils";
import { useEffect, useState } from "react";
import colors from "styles/colors";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import TextInput, { Filter } from "ui/inputs/TextInput";
import { isDigitsOnly } from "utils/string";

import styles from "./PayeeBankingInfo.module.scss";

const useRoutingNumberValidationState = (routingNumber: string, paymentMethod: PaymentMethod) => {
  const [isRoutingNumberValid, setIsRoutingNumberValid] = useState(false);
  const [isShowingRoutingNumberError, setIsShowingRoutingNumberError] = useState(false);
  const [routingNumberErrorMessage, setRoutingNumberErrorMessage] = useState<string>();

  const [institution, setInstitution] = useState<string | null>(null);

  const { refetch: getInstitution, isLoading: isRoutingNumberValidationLoading } =
    useFinancialInstitutionQuery({ routingNumber });

  useEffect(() => {
    const validateRoutingNumber = async () => {
      if (!routingNumber) {
        return;
      }

      const isValid = routingNumber.length === 9 && isDigitsOnly(routingNumber);

      if (isValid) {
        const { data: financialInstitution } = await getInstitution();

        if (!financialInstitution) {
          setIsRoutingNumberValid(false);
          setRoutingNumberErrorMessage("No institution is associated with this routing number.");
          return;
        }

        setInstitution(financialInstitution.name);

        switch (paymentMethod) {
          case PaymentMethod.ACH:
          case PaymentMethod.SAME_DAY_ACH:
            setIsRoutingNumberValid(financialInstitution.isAchSupported);
            setRoutingNumberErrorMessage(
              `This ${financialInstitution.name} routing number does not support ACH transfers.`
            );
            return;
          case PaymentMethod.WIRE:
            setIsRoutingNumberValid(financialInstitution.isWireSupported);
            setRoutingNumberErrorMessage(
              `This ${financialInstitution.name} routing number does not support wires.`
            );
            return;
        }
      } else {
        setIsRoutingNumberValid(false);
        setRoutingNumberErrorMessage("Routing number must be 9 digits.");
      }
    };

    validateRoutingNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routingNumber, paymentMethod]);

  return {
    institution,
    isRoutingNumberValid,
    isRoutingNumberValidationLoading,
    isShowingRoutingNumberError,
    setIsShowingRoutingNumberError,
    routingNumberErrorMessage,
  };
};

const useAccountNumberValidationState = (accountNumber: string) => {
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);
  const [isShowingAccountNumberError, setIsShowingAccountNumberError] = useState(false);

  useEffect(() => {
    const validateAccountNumber = () => {
      if (!accountNumber) {
        return;
      }

      const isValid = accountNumber.length <= 17 && isDigitsOnly(accountNumber);
      setIsAccountNumberValid(isValid);
    };

    validateAccountNumber();
  }, [accountNumber]);

  return {
    isAccountNumberValid,
    isShowingAccountNumberError,
    setIsShowingAccountNumberError,
  };
};

type Props = {
  bankingInfo: BankingInfo;
  setBankingInfo: (bankingInfo: BankingInfo) => void;
  paymentMethod: PaymentMethodOption | null;
  accountTypeOptions: Option[];
  setHasBankingInfoError?: (hasBankingInfoError: boolean) => void;
  className?: string;
};

const PayeeBankingInfo: React.FC<Props> = ({
  bankingInfo,
  setBankingInfo,
  paymentMethod,
  accountTypeOptions,
  setHasBankingInfoError = undefined,
  className,
}) => {
  const {
    institution,
    isRoutingNumberValid,
    isRoutingNumberValidationLoading,
    isShowingRoutingNumberError,
    setIsShowingRoutingNumberError,
    routingNumberErrorMessage,
  } = useRoutingNumberValidationState(
    bankingInfo.routingNumber,
    paymentMethod?.value! as PaymentMethod
  );

  const { isAccountNumberValid, isShowingAccountNumberError, setIsShowingAccountNumberError } =
    useAccountNumberValidationState(bankingInfo.accountNumber);

  useEffect(() => {
    setHasBankingInfoError?.(
      !isAccountNumberValid ||
        !isRoutingNumberValid ||
        !bankingInfo.routingNumber ||
        !bankingInfo.accountNumber
    );
  }, [isAccountNumberValid, isRoutingNumberValid, bankingInfo, setHasBankingInfoError]);

  const getRoutingIcon = () => {
    if (isRoutingNumberValidationLoading) {
      return <AnimatedSpinner size={20} />;
    }

    if (!institution || !isRoutingNumberValid || !bankingInfo.routingNumber) {
      return;
    }

    return (
      <CheckCircle
        weight="fill"
        color={colors.green[400]}
        size={20}
        className={styles.checkCircle}
      />
    );
  };

  return (
    <fieldset>
      <div className={classNames(styles.container, className)}>
        <legend className={styles.heading}>Payee’s banking info</legend>
        <div className={styles.formData}>
          <TextInput
            type="text"
            value={bankingInfo.routingNumber}
            onChange={(routingNumber) =>
              setBankingInfo({ ...bankingInfo, routingNumber: routingNumber.trim() })
            }
            onFocus={() => {
              setIsShowingRoutingNumberError(false);
            }}
            onBlur={() => {
              setIsShowingRoutingNumberError(true);
            }}
            label="Routing number"
            icon={getRoutingIcon()}
            hasError={
              !isRoutingNumberValidationLoading &&
              !isRoutingNumberValid &&
              isShowingRoutingNumberError
            }
            errorMessage={routingNumberErrorMessage}
            maxLength={9}
            suffixValue={
              !isRoutingNumberValid || !institution || !bankingInfo.routingNumber ? "" : institution
            }
            inputFilter={Filter.DIGITS}
          />
          <TextInput
            type="text"
            value={bankingInfo.accountNumber}
            onChange={(accountNumber) =>
              setBankingInfo({ ...bankingInfo, accountNumber: accountNumber.trim() })
            }
            onFocus={() => {
              setIsShowingAccountNumberError(false);
            }}
            onBlur={() => {
              setIsShowingAccountNumberError(true);
            }}
            label="Account number"
            hasError={isShowingAccountNumberError && !isAccountNumberValid}
            errorMessage="Account number must not be more than 17 digits."
            maxLength={17}
            inputFilter={Filter.DIGITS}
          />
          <Dropdown
            onChange={(accountType) =>
              setBankingInfo({ ...bankingInfo, accountType: accountType as Option })
            }
            value={bankingInfo.accountType}
            id="account-type"
            label="Account type"
            options={accountTypeOptions}
            isSearchable={false}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default PayeeBankingInfo;
