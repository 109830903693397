type BalanceHistoryDatum = {
  date: string;
  amount: number;
};

// Only for demo purposes, remove after asking in #proj-demo
const generateFakeDemoData = (historyWithToday: BalanceHistoryDatum[]): BalanceHistoryDatum[] => {
  if (historyWithToday.length === 0) return [];

  const finalBalance = historyWithToday[historyWithToday.length - 1].amount;
  const increment = 1500000; // Increment per day
  const variance = 5000000; // Variance range for randomness

  const fakeData = historyWithToday.map((datum, index) => {
    const baseAmount = finalBalance + increment * (index - (historyWithToday.length - 1));
    const randomVariance = Math.floor(Math.random() * (2 * variance + 1)) - variance; // Random value between -variance and +variance
    return {
      date: datum.date,
      amount: baseAmount + randomVariance,
    };
  });

  // Ensure the last data point has the correct value
  fakeData[fakeData.length - 1].amount = finalBalance;

  return fakeData;
};

export default generateFakeDemoData;
