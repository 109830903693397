import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import BillLineItemRep from "reps/BillLineItemRep";

type BillLineItemApi = {
  getByBillId: (billId: string) => Promise<BillLineItemRep.Complete[]>;
  create: (body: BillLineItemRep.Creator) => Promise<BillLineItemRep.Complete>;
  update: (
    billLineItemId: string,
    body: BillLineItemRep.Updater
  ) => Promise<BillLineItemRep.Complete>;
  delete: (billLineItemId: string) => Promise<BillLineItemRep.Complete>;
};

const builder: ApiBuilder<BillLineItemApi> = (api) => ({
  getByBillId: async (billId) => {
    const url = `/accounts-payable/bills/${billId}/bill-line-items`;
    return (await api.get<BillLineItemRep.Complete[]>(url))!;
  },

  create: async (body) => {
    const url = `/accounts-payable/bill-line-items`;
    return (await api.post<BillLineItemRep.Complete>(url, body))!;
  },

  update: async (billLineItemId, body) => {
    const url = `/accounts-payable/bill-line-items/${billLineItemId}`;
    return (await api.patch<BillLineItemRep.Complete>(url, body))!;
  },

  delete: async (billLineItemId) => {
    const url = `/accounts-payable/bill-line-items/${billLineItemId}`;
    return (await api.delete<BillLineItemRep.Complete>(url))!;
  },
});

const useBillLineItemApi = () => useBackendV2Api(builder);

export default useBillLineItemApi;
