import { LOGIN_HINT_EMAIL_PARAM } from "modules/auth/constants";
import LoadingPage from "modules/loading/pages/LoadingPage";
import { useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

/**
 * Redirects the browser to Auth0 to handle sign in. Auth0 requires that there's a dedicated path
 * that will always redirect to Auth0. For more information, see
 * https://auth0.com/docs/universal-login/configure-default-login-routes.
 */
const SignInPage = () => {
  const auth0 = useRecoilValue(auth0ClientState);
  const [loginHintEmail] = useSearchParamValue(LOGIN_HINT_EMAIL_PARAM);

  void auth0.loginWithRedirect({
    authorizationParams: {
      ...(loginHintEmail && { login_hint: loginHintEmail }), // eslint-disable-line camelcase
    },
  });

  return <LoadingPage location={SignInPage.name} />;
};

export default SignInPage;
