import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import AccountingSyncSettings from "modules/ap-settings/components/AccountingSyncSettings";
import EmailSettings from "modules/ap-settings/components/EmailSettings";
import { FC } from "react";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import TabsV2 from "ui/navigation/TabsV2";
import { TabConfig, useSearchParamTabState } from "utils/tabs/useTabState";

const tabs: TabConfig = {
  "accounting-sync": { label: "Accounting sync" },
  "email-settings": { label: "Email settings" },
};

const BillsSettingsPage: FC = () => {
  const [activeTab, setActiveTab] = useSearchParamTabState("tab", tabs, "accounting-sync");

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/bills">Bill Pay</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Bill pay settings</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.BackCaret to="/bills" />
          <DashboardPage.Header.Title>Bill pay settings</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <TabsV2 activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

        <DashboardPage.Section>
          {activeTab === "email-settings" && <EmailSettings />}
          {activeTab === "accounting-sync" && <AccountingSyncSettings />}
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default BillsSettingsPage;
