import { useChatWidget } from "components/ChatWidget";
import chatDebugModeState from "modules/chat/state/chatDebugMode";
import LoadingPage from "modules/loading/pages/LoadingPage";
import { useRecoilValue, useResetRecoilState } from "recoil";
import auth0ClientState from "state/auth/auth0Client";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";
import offerInputsState from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import sessionGuidState from "state/compareCreditOffers/sessionGuid";
import useMountEffect from "utils/customHooks/useMountEffect";
import { segmentClear } from "utils/customHooks/useSegment";

/**
 * Redirects the browser to Auth0 to handle sign out. The presence of a dedicated sign out path can
 * come in handy when debugging.
 */
const SignOutPage = () => {
  const auth0 = useRecoilValue(auth0ClientState);
  const chat = useChatWidget();

  const resetIsSuperusering = useResetRecoilState(isSuperuseringState);
  const resetBusinessGuid = useResetRecoilState(businessGuidState);
  const resetChatDebugMode = useResetRecoilState(chatDebugModeState);

  // NB(alex): Reset persisted state from whatstheapr
  const resetOfferInputs = useResetRecoilState(offerInputsState);
  const resetSessionGuid = useResetRecoilState(sessionGuidState);

  chat.shutDown();
  segmentClear();

  useMountEffect(() => {
    resetOfferInputs();
    resetSessionGuid();
    resetIsSuperusering();
    resetBusinessGuid();
    resetChatDebugMode();
  });

  void auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });

  return <LoadingPage location={SignOutPage.name} />;
};

export default SignOutPage;
