/* eslint-disable functional/no-let */

import { ArrowLeft } from "@phosphor-icons/react";
import { useRefreshBillLineItemsQuery } from "modules/bill-line-items/queries/useBillLineItems";
import useBillSyncRunDispatcher from "modules/bill-syncs/hooks/useBillSyncRunDispatcher";
import AchPaymentMethodFieldset from "modules/payee-payment-methods/components/AchPaymentMethodFieldset";
import DomesticWirePaymentMethodFieldset from "modules/payee-payment-methods/components/DomesticWirePaymentMethodFieldset";
import InternationalWirePaymentMethodRecoilFieldset from "modules/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset";
import useInternationalWirePaymentMethodRecoilHelpers from "modules/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset/useInternationalWirePaymentMethodRecoilHelpers";
import useAchPaymentMethodForm, {
  AchPaymentMethodFormInputs,
} from "modules/payee-payment-methods/forms/useAchPaymentMethodForm";
import useDomesticWirePaymentMethodForm, {
  DomesticWirePaymentMethodFormInputs,
} from "modules/payee-payment-methods/forms/useDomesticWirePaymentMethodForm";
import PayeeDetailsFieldset from "modules/payees/components/PayeeDetailsFieldset";
import usePayeeDetailsForm, {
  PayeeDetailsFormInputs,
} from "modules/payees/forms/usePayeeDetailsForm";
import useUpdatePayeeForBillPayMutation from "modules/payees/mutations/useUpdatePayeeForBillPayMutation";
import { FC, FormEvent, useMemo } from "react";
import PayeeRep from "reps/PayeeRep";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import Divider from "ui/data-display/Divider";
import UserAvatar from "ui/data-display/UserAvatar";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import SegmentControls from "ui/navigation/SegmentControls";
import Text from "ui/typography/Text";
import { INITIAL_US_ADDRESS, states, US_COUNTRY_OPTION } from "utils/address";
import { ApiError } from "utils/ajax";
import getInitials from "utils/string/getInitials";

import { useRefreshBillPaymentMethodsQueries } from "../../queries/useBillPaymentMethods";
import useBillDetailsPayeePaymentMethodTabs, {
  PayeePaymentMethodTabKey,
} from "../../search-params/useBillDetailsPayeePaymentMethodTabs";

const getDefaultPaymentMethodTab = (payee: PayeeRep.Complete): PayeePaymentMethodTabKey => {
  if (payee.achTransferMethod) {
    return "ach";
  }
  if (payee.wireTransferMethod) {
    return "domestic-wire";
  }
  if (payee.internationalWireTransferMethod) {
    return "international-wire";
  }
  return "ach";
};

type Props = {
  onBack: () => void;
  payee: PayeeRep.Complete;
  billId: string;
};

const BillUpdatePayeeForm: FC<Props> = ({ onBack, payee, billId }) => {
  const dispatchBillSync = useBillSyncRunDispatcher(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillPaymentMethodsQueries = useRefreshBillPaymentMethodsQueries();

  const payeeDetailsForm = usePayeeDetailsForm({
    defaultValues: {
      payeeName: payee.name,
      payeeEmail: payee.emailAddress ?? "",
      payeePhoneNumber: payee.phone ?? "",
      payeeDefaultChartOfAccountId: payee.defaultChartOfAccountId,
    },
  });

  const achPaymentMethodForm = useAchPaymentMethodForm({
    defaultValues: payee.achTransferMethod
      ? {
          accountNumber: payee.achTransferMethod.accountNumber,
          routingNumber: payee.achTransferMethod.routingNumber,
        }
      : {},
  });

  const domesticWirePaymentMethodForm = useDomesticWirePaymentMethodForm({
    defaultValues: payee.wireTransferMethod
      ? {
          accountNumber: payee.wireTransferMethod.accountNumber,
          routingNumber: payee.wireTransferMethod.routingNumber,
          // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
          address: payee.address
            ? {
                addressLine1: {
                  value: payee.address?.addressLine1,
                  label: payee.address?.addressLine1,
                },
                addressLine2: payee.address.addressLine2 ?? "",
                city: payee.address.city,
                state: { label: states[payee.address.state], value: payee.address.state },
                zipCode: payee.address.zipCode,
                country: US_COUNTRY_OPTION,
              }
            : INITIAL_US_ADDRESS,
        }
      : {},
  });

  const {
    internationalWireTransferMethod,
    isValidInternational,
    hasInputValues: hasInternationalInputValues,
  } = useInternationalWirePaymentMethodRecoilHelpers({
    payeeGuid: payee.guid,
  });

  const defaultPaymentMethodTab = useMemo(() => getDefaultPaymentMethodTab(payee), [payee]);
  const { tabs, activeTab, setActiveTab } = useBillDetailsPayeePaymentMethodTabs(
    defaultPaymentMethodTab,
    {
      ach: {
        badge:
          !achPaymentMethodForm.isBlank() && !achPaymentMethodForm.formState.isValid && "warning",
      },
      "domestic-wire": {
        badge:
          !domesticWirePaymentMethodForm.isBlank() &&
          !domesticWirePaymentMethodForm.formState.isValid &&
          "warning",
      },
      "international-wire": {
        badge: hasInternationalInputValues && !isValidInternational && "warning",
      },
    }
  );

  const { mutate: updatePayeeForBillPay, isPending } = useUpdatePayeeForBillPayMutation({
    onSuccess: async () => {
      dispatchBillSync();

      // NB(alex): refreshBillPaymentMethodsQueries() also updates bill payment methods. This seems a bit unintuitive to me, so we should probably re-think the query key in `useBillPaymentMethodsQueryOptions`.
      await Promise.all([refreshBillPaymentMethodsQueries(), refreshBillLineItemsQuery()]);

      onBack();
      notify("success", "Payee updated");
    },
    onError: (error) => {
      notify(
        "error",
        error instanceof ApiError
          ? error.message
          : "There was an error saving payee details. Please try again."
      );
    },
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let valid = true;
    let payeeDetailsFormData: PayeeDetailsFormInputs | null = null;

    try {
      payeeDetailsFormData = await new Promise<PayeeDetailsFormInputs>((resolve, reject) => {
        payeeDetailsForm.handleSubmit(resolve, reject)(e);
      });
    } catch (error) {
      valid = false;
    }

    const transferMethodBody = await (async () => {
      const body = {};
      let achPaymentMethodFormData: AchPaymentMethodFormInputs | null = null;
      let domesticWirePaymentMethodFormData: DomesticWirePaymentMethodFormInputs | null = null;

      if (!achPaymentMethodForm.isBlank()) {
        try {
          achPaymentMethodFormData = await new Promise<AchPaymentMethodFormInputs>(
            (resolve, reject) => {
              achPaymentMethodForm.handleSubmit(resolve, reject)(e);
            }
          );
          Object.assign(body, {
            achTransferMethod: {
              routingNumber: achPaymentMethodFormData.routingNumber,
              accountNumber: achPaymentMethodFormData.accountNumber,
            } satisfies PayeeRep.AchTransferMethod,
          });
        } catch (error) {
          valid = false;
        }
      }

      if (!domesticWirePaymentMethodForm.isBlank()) {
        try {
          domesticWirePaymentMethodFormData =
            await new Promise<DomesticWirePaymentMethodFormInputs>((resolve, reject) => {
              domesticWirePaymentMethodForm.handleSubmit(
                // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
                (data) => resolve(data as DomesticWirePaymentMethodFormInputs),
                reject
              )(e);
            });

          Object.assign(body, {
            wireTransferMethod: {
              routingNumber: domesticWirePaymentMethodFormData.routingNumber,
              accountNumber: domesticWirePaymentMethodFormData.accountNumber,
            } satisfies PayeeRep.WireTransferMethod,
            // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
            address: {
              addressLine1: domesticWirePaymentMethodFormData.address.addressLine1?.value ?? "",
              addressLine2: domesticWirePaymentMethodFormData.address.addressLine2 || undefined,
              city: domesticWirePaymentMethodFormData.address.city,
              state: domesticWirePaymentMethodFormData.address.state?.value ?? "",
              zipCode: domesticWirePaymentMethodFormData.address.zipCode,
              country: domesticWirePaymentMethodFormData.address.country?.value ?? "",
            } satisfies PayeeRep.Address,
          });
        } catch (error) {
          valid = false;
        }
      }

      return body as Pick<
        PayeeRep.Creation,
        "achTransferMethod" | "wireTransferMethod" | "address"
      >;
    })();

    // HACK(alex): We're hacking this form to work with recoil state for international wire details, so we have to trigger form validation manually.
    if (
      activeTab === "international-wire" &&
      hasInternationalInputValues &&
      !isValidInternational
    ) {
      valid = false;
    }

    if (!valid || !payeeDetailsFormData) {
      return;
    }

    updatePayeeForBillPay({
      payeeGuid: payee.guid,
      name: payeeDetailsFormData.payeeName,
      emailAddress: payeeDetailsFormData.payeeEmail || undefined,
      phone: payeeDetailsFormData.payeePhoneNumber || undefined,
      defaultChartOfAccountId: payeeDetailsFormData.payeeDefaultChartOfAccountId || undefined,
      ...transferMethodBody,
      ...(hasInternationalInputValues && isValidInternational && internationalWireTransferMethod),
    });
  };

  return (
    <form className="h-full" onSubmit={onSubmit}>
      <DetailsSidebarBody
        header={
          <DetailsSidebarBody.Header>
            <Button variant="ghost" paddingVariant="square" onClick={onBack}>
              <ArrowLeft size={24} weight="light" />
            </Button>

            <UserAvatar initials={getInitials(payee.name)} size={44} color="purple-light" />

            <Text weight="medium" size={16} as="h3">
              {payee.name}
            </Text>
          </DetailsSidebarBody.Header>
        }
        main={
          <DetailsSidebarBody.Main>
            <DetailsSidebarBody.Section>
              <PayeeDetailsFieldset form={payeeDetailsForm} />
            </DetailsSidebarBody.Section>

            <Divider className="my-0" />

            <DetailsSidebarBody.Section>
              <Text size={14} weight="bold">
                Payment details
              </Text>

              <SegmentControls
                size="md"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={tabs}
                className="my-6"
              />

              {activeTab === "ach" && <AchPaymentMethodFieldset form={achPaymentMethodForm} />}
              {activeTab === "domestic-wire" && (
                <DomesticWirePaymentMethodFieldset form={domesticWirePaymentMethodForm} />
              )}
              {activeTab === "international-wire" && (
                <InternationalWirePaymentMethodRecoilFieldset />
              )}
            </DetailsSidebarBody.Section>
          </DetailsSidebarBody.Main>
        }
        footer={
          <DetailsSidebarBody.Footer className="flex flex-col gap-y-4">
            <Button type="submit" variant="primary" isLoading={isPending}>
              Save
            </Button>
            <Button variant="ghost" onClick={onBack}>
              Cancel
            </Button>
          </DetailsSidebarBody.Footer>
        }
      />
    </form>
  );
};

export default BillUpdatePayeeForm;
