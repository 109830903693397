import React from "react";
import { MoneyChatElement } from "reps/chat/ChatElement";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";

type Props = {
  element: MoneyChatElement;
};

const ResponseMessageMoney: React.FC<Props> = ({ element }) => {
  return <MoneyAmount amount={element.amount.toString()} currencyCode="USD" showCurrencySymbol />;
};

export default ResponseMessageMoney;
