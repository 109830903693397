import useBill from "modules/bills/queries/useBill";
import { useCallback, useState, useEffect } from "react";
import BillSyncRep from "reps/BillSyncRep";
import { useEventBusDispatcher, useEventBusListener } from "utils/event-bus";

import useBillSyncSyncMutation from "../mutations/useBillSyncSyncMutation";
import shouldBillSyncBeDryRun from "../utils/shouldBillSyncBeDryRun";

import {
  BILL_SYNC_RUN_REQUESTED_EVENT,
  BillSyncRunRequestedEvent,
} from "./useBillSyncRunDispatcher";
import useBillSyncViewIsEnabled from "./useBillSyncViewIsEnabled";

export const BILL_SYNC_RUN_SETTLED_EVENT = "billSyncRunSettled";

export type BillSyncRunSettledEvent = CustomEvent<{
  billId: string;
  data: BillSyncRep.Complete | null;
  error: Error | null;
}>;

const makeBillSyncRunSettledEvent = (
  billId: string,
  data: BillSyncRep.Complete | null,
  error: Error | null
): BillSyncRunSettledEvent =>
  new CustomEvent(BILL_SYNC_RUN_SETTLED_EVENT, {
    detail: { billId, data, error },
  });

// Represents a "job" to run a bill sync on a queue.
// Currently, we don't need to store any additional information about the job,
// so we just use a sentinel value.
const billSyncJob = "bill-sync-job";

const useBillSyncRunManager = (billId: string) => {
  const isEnabled = useBillSyncViewIsEnabled();

  const bill = useBill(billId, { required: true });
  const shouldDryRun = shouldBillSyncBeDryRun(bill);

  const dispatchRunSettled = useEventBusDispatcher<BillSyncRunSettledEvent>();

  const { mutate: runBillSync, isPending: isBillSyncRunning } = useBillSyncSyncMutation(
    billId,
    shouldDryRun,
    {
      onSettled: (data, error) => {
        dispatchRunSettled(makeBillSyncRunSettledEvent(billId, data || null, error));
      },
    }
  );

  // Used to serialize bill sync runs.
  const [jobQueue, setJobQueue] = useState<(typeof billSyncJob)[]>([]);

  // Job queue processor.
  useEffect(() => {
    if (isBillSyncRunning || !jobQueue.length) return;

    const [_, ...remainingJobs] = jobQueue;
    runBillSync();
    setJobQueue(remainingJobs);
  }, [jobQueue, runBillSync, isBillSyncRunning]);

  const billSyncRunRequestedHandler = useCallback(
    (event: BillSyncRunRequestedEvent) => {
      // Run bill syncs only if sync is enabled and the bill is not closed for accounting.
      if (isEnabled && !bill.isClosedForAccounting && event.detail.billId === billId) {
        setJobQueue((prev) => [...prev, billSyncJob]);
      }
    },
    [isEnabled, bill, billId, setJobQueue]
  );

  useEventBusListener<BillSyncRunRequestedEvent>(
    BILL_SYNC_RUN_REQUESTED_EVENT,
    billSyncRunRequestedHandler
  );
};

export default useBillSyncRunManager;
