import { useMutation } from "@tanstack/react-query";
import { useRefreshAllBankAccountQueries } from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

type Variables = {
  accountGuid: string;
};

const useSetPrimaryAccountNameMutation = () => {
  const highbeamApi = useHighbeamApi();

  const refreshBankAccounts = useRefreshAllBankAccountQueries();

  return useMutation({
    mutationFn: async ({ accountGuid }: Variables) =>
      await highbeamApi.bankAccount.update(accountGuid, { isPrimary: true }),
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async () => {
      refreshBankAccounts();
    },
  });
};

export default useSetPrimaryAccountNameMutation;
