import { ApiBuilder, useBackendV2Api } from "modules/highbeam-api/base";
import BillAuditLogRep from "reps/BillAuditLogRep";

type AuditTrailApi = {
  getBillAuditLogs: (billId: string, businessGuid: string) => Promise<BillAuditLogRep.Complete[]>;
};

const builder: ApiBuilder<AuditTrailApi> = (api) => ({
  getBillAuditLogs: async (billId, businessGuid) => {
    const queryParams = new URLSearchParams({ billId, businessGuid });
    const url = `/audit-trail/bill-audit-logs?${queryParams}`;
    return (await api.get<BillAuditLogRep.Complete[]>(url))!;
  },
});

const useAuditTrailApi = () => useBackendV2Api(builder);

export default useAuditTrailApi;
