import {
  AirplaneTakeoff,
  ChartLineUp,
  CurrencyDollarSimple,
  PlusMinus,
  Scales,
} from "@phosphor-icons/react";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Heading3, Paragraph } from "ui/typography";

const classes = {
  icon: "size-10 p-2 border border-grey-200 rounded-full bg-white",
  itemContainer: "flex gap-x-4 items-center",
  text: "flex gap-x-2",
  infoIcon: "mb-[-3px] ml-1", // NB(alex): Kind of a hack but it seems to work pretty well.
};

export const MINIMUM_REQUIREMENTS_CONTENT = {
  ecommRevenue: <>Ecommerce revenue {">$500K"}</>,
  currentRatio: (
    <>
      Current ratio must be greater than 1.
      <IconWithTooltip
        className={classes.infoIcon}
        tooltip="Current assets / current liabilities"
      />
    </>
  ),
  yearOverYearSalesTrend: (
    <>
      Year-over-year sales trend must not be declining more than 15%.
      <IconWithTooltip
        className={classes.infoIcon}
        tooltip="Trailing 12-month sales / last year’s sales over the same period"
      />
    </>
  ),
  cashRunway: (
    <>
      Cash runway must be greater than 6 months.
      <IconWithTooltip className={classes.infoIcon} tooltip="Cash on hand / monthly cash burn" />
    </>
  ),
  shareholderEquity: (
    <>
      Shareholder’s equity greater than 0.
      <IconWithTooltip className={classes.infoIcon} tooltip="Assets - liabilities" />
    </>
  ),
};

const ApplicationRequirementsSummary = () => {
  return (
    <>
      <div className="flex flex-col gap-y-2">
        <Heading3>Application requirements</Heading3>
        <Paragraph>
          In order to qualify for Highbeam Capital you must meet these financial requirements.
        </Paragraph>
      </div>
      <div className="mt-3 flex flex-col gap-y-4 rounded-lg bg-grey-50 p-6">
        <div className={classes.itemContainer}>
          <CurrencyDollarSimple size={24} className={classes.icon} />
          <Paragraph>{MINIMUM_REQUIREMENTS_CONTENT.ecommRevenue}</Paragraph>
        </div>
        <div className={classes.itemContainer}>
          <PlusMinus size={24} className={classes.icon} />
          <Paragraph>{MINIMUM_REQUIREMENTS_CONTENT.currentRatio}</Paragraph>
        </div>
        <div className={classes.itemContainer}>
          <ChartLineUp size={24} className={classes.icon} />
          <Paragraph>{MINIMUM_REQUIREMENTS_CONTENT.yearOverYearSalesTrend}</Paragraph>
        </div>
        <div className={classes.itemContainer}>
          <AirplaneTakeoff size={24} className={classes.icon} />
          <Paragraph>{MINIMUM_REQUIREMENTS_CONTENT.cashRunway}</Paragraph>
        </div>
        <div className={classes.itemContainer}>
          <Scales size={24} className={classes.icon} />
          <Paragraph>{MINIMUM_REQUIREMENTS_CONTENT.shareholderEquity}</Paragraph>
        </div>
      </div>
    </>
  );
};

export default ApplicationRequirementsSummary;
