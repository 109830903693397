import { atom } from "recoil";
import { booleanLocalStorageEffect } from "utils/recoil";

const chatDebugModeState = atom<boolean>({
  key: "chat/debugMode",
  default: false,
  effects: [booleanLocalStorageEffect("chatDebugMode")],
});

export default chatDebugModeState;
