import { useBillDocumentDataQuery } from "modules/bill-documents/queries/useBillDocumentData";
import { FC, PropsWithChildren } from "react";
import BillDocumentRep from "reps/BillDocumentRep";
import Card from "ui/data-display/Card";
import PdfDisplay from "ui/data-display/PdfDisplay";

import styles from "./BillDocumentDisplay.module.scss";

type ContainerProps = PropsWithChildren & {
  width: number;
};

const Container: FC<ContainerProps> = ({ width, children }) => {
  return (
    <Card shadow="none" className={styles.container} style={{ width }}>
      {children}
    </Card>
  );
};

type Props = {
  billDocument: BillDocumentRep.Complete;
  // NB(alex): We have to explicitly set the width because `react-pdf` defaults to the PDF file's width, which is not what we want here.
  // NB(lev): We set `width - 2` for the image and PDF to account for a 1px border on each side.
  width: number;
};

const BillDocumentDisplay: FC<Props> = ({ billDocument, width }) => {
  const { data } = useBillDocumentDataQuery({ billDocument });

  if (data) {
    if (data.fileType === "pdf") {
      return (
        <Container width={width}>
          <PdfDisplay file={data.src} width={width - 2} gap={24} />
        </Container>
      );
    } else {
      return (
        <Container width={width}>
          <img src={data.src} alt={data.fileType ?? ""} width={width - 2} className={styles.img} />
        </Container>
      );
    }
  }

  return <PdfDisplay.Loading />;
};

export default BillDocumentDisplay;
