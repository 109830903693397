import useChatConfig from "modules/chat/queries/useChatConfig";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useHasPermission from "utils/permissions/useHasPermission";

const useIsAllowedToNavigateToChatRoutes = (): boolean => {
  const isSuperusering = useIsSuperusering();
  const chatConfig = useChatConfig();
  const hasChatPermission = useHasPermission("chat");

  if (isSuperusering) return true;
  if (!chatConfig) return false;
  if (!chatConfig.isEnabled) return false;
  return hasChatPermission;
};

export default useIsAllowedToNavigateToChatRoutes;
