import { useSuspenseQuery } from "@tanstack/react-query";
import OnboardingLayout from "layouts/OnboardingLayout";
import useCreateBankApplicationMutation from "modules/bank-application/mutations/useCreateBankApplicationMutation";
import useBankApplicationQueryOptions from "modules/bank-application/queries/useBankApplicationQueryOptions";
import useBusiness from "modules/business/queries/useBusiness";
import LoadingPage from "modules/loading/pages/LoadingPage";
import OnboardingApplicationEvaluationCard from "modules/onboarding/components/OnboardingApplicationEvaluationCard";
import OnboardingAwaitingDocumentsCard from "modules/onboarding/components/OnboardingAwaitingDocumentsCard";
import OnboardingBankApplicationPendingCard from "modules/onboarding/components/OnboardingBankApplicationPendingCard";
import OnboardingStartBankApplicationCard from "modules/onboarding/components/OnboardingStartBankApplicationCard";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import BankApplicationRep from "reps/BankApplicationRep";
import useMountEffect from "utils/customHooks/useMountEffect";

const OnboardingBankApplicationPage: FC = () => {
  const { data: bankApplication } = useSuspenseQuery(useBankApplicationQueryOptions());
  const business = useBusiness();

  const { mutate: createBankApplication } = useCreateBankApplicationMutation();

  useMountEffect(() => {
    if (!bankApplication) {
      createBankApplication();
    }
  });

  // Show the loading page while the bank application is being created.
  if (!bankApplication) {
    return <LoadingPage location="NoBankApplication" />;
  }

  switch (bankApplication.status) {
    case BankApplicationRep.Status.FormCreated:
      return (
        <OnboardingLayout progressBarPercentage={30}>
          <OnboardingStartBankApplicationCard bankApplication={bankApplication} />
        </OnboardingLayout>
      );

    case BankApplicationRep.Status.AwaitingDocuments:
      return (
        <OnboardingLayout>
          <OnboardingAwaitingDocumentsCard bankApplicationUrl={bankApplication.url!} />
        </OnboardingLayout>
      );

    case BankApplicationRep.Status.Pending:
    case BankApplicationRep.Status.PendingReview:
      return (
        <OnboardingLayout>
          <OnboardingBankApplicationPendingCard />
        </OnboardingLayout>
      );

    case BankApplicationRep.Status.Denied:
      // NB(alex): We have `Denied` here because we may still want to dispute with Unit. For actual rejection we use `BusinessRep.Status === Rejected`.
      return (
        <OnboardingLayout>
          <OnboardingApplicationEvaluationCard />
        </OnboardingLayout>
      );

    case BankApplicationRep.Status.Approved:
      if (business.status === "Active") {
        return <Navigate replace to="/onboarding/get-started" />;
      } else {
        return (
          <OnboardingLayout>
            <OnboardingApplicationEvaluationCard />
          </OnboardingLayout>
        );
      }
  }
};

export default OnboardingBankApplicationPage;
