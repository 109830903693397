import { useSuspenseQuery } from "@tanstack/react-query";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import useBillLineItemApi from "../api/useBillLineItemApi";

const makeQueryKey = (billId: string) => ["billLineItems", { billId }];

const useBillLineItemsQueryOptions = (billId: string) => {
  const billLineItemApi = useBillLineItemApi();

  return useQueryOptions({
    queryKey: makeQueryKey(billId),
    queryFn: () => billLineItemApi.getByBillId(billId),
  });
};

const useBillLineItems = (billId: string) => {
  const { data } = useSuspenseQuery(useBillLineItemsQueryOptions(billId));
  return data;
};

export const useRefreshBillLineItemsQuery = (
  billId: string,
  options: UseRefreshQueryOptions = {}
) => useRefreshQuery(makeQueryKey(billId), options);

export default useBillLineItems;
