import useApEmailAliases from "modules/ap-email-aliases/queries/useApEmailAliases";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import assignedBillsQueryHooks from "modules/bills/queries/assignedBillsQueryHooks";
import { useDraftBillsQuery, useAllBillsQuery } from "modules/bills/queries/useBills";
import { FC } from "react";
import CountBadge from "ui/data-display/CountBadge";
import BillPayIcon from "ui/icons/BillPayIcon";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToNavigateToBillPayRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToBillPayRoutes";
import useHasPermission from "utils/permissions/useHasPermission";

import MenuItem from "../MenuItem";
import NavFeatureStatusBadge from "../NavFeatureStatusBadge";
import { getCountBadgeColor } from "../utils";

type Props = {
  onClose: () => void;
};

const BillPayMenuRenderer: FC<Props> = ({ onClose }) => {
  const billPayEnabled = useFeatureFlag("BILL_PAY_UI");
  const isAllowedToNavigateToBillPayRoutes = useIsAllowedToNavigateToBillPayRoutes();
  const hasAccountsPayableReadPermission = useHasPermission("accountsPayableBill:read");

  const { data: draftBills, isLoading: isLoadingDraftBills } = useDraftBillsQuery(
    hasAccountsPayableReadPermission
  );
  const billsEmailInboxCount = draftBills?.length;

  const { data: allBills } = useAllBillsQuery(hasAccountsPayableReadPermission);
  const allBillsCount = allBills?.length;

  const apEmailAliases = useApEmailAliases();
  const shouldShowBillsEmployeeView = useShouldShowEmployeeView();
  const { data: assignedRequestedBills } = assignedBillsQueryHooks.useQuery({
    approvalStatus: "Requested",
  });
  const assignedRequestedBillsCount = assignedRequestedBills?.length;

  if (
    billPayEnabled &&
    isAllowedToNavigateToBillPayRoutes &&
    (!shouldShowBillsEmployeeView || apEmailAliases.length > 0)
  ) {
    return (
      <MenuItem
        text={shouldShowBillsEmployeeView ? "Bill approvals" : "Bill Pay"}
        icon={<BillPayIcon />}
        path="/bills"
        onClick={onClose}
        badge={({ isActive }) => {
          const totalCount =
            (shouldShowBillsEmployeeView ? 0 : (billsEmailInboxCount ?? 0)) +
            (assignedRequestedBillsCount ?? 0);

          if (!shouldShowBillsEmployeeView && totalCount) {
            return <CountBadge count={totalCount} backgroundColor={getCountBadgeColor(isActive)} />;
          }

          if (shouldShowBillsEmployeeView && assignedRequestedBillsCount) {
            return (
              <CountBadge
                count={assignedRequestedBillsCount}
                backgroundColor={getCountBadgeColor(isActive)}
              />
            );
          }

          if (!shouldShowBillsEmployeeView && !isLoadingDraftBills && allBillsCount === 0) {
            return <NavFeatureStatusBadge featureStatus="New" isActive={isActive} />;
          }
        }}
      />
    );
  }

  return null;
};

export default BillPayMenuRenderer;
