import { Export, MagnifyingGlass } from "@phosphor-icons/react";
import classNames from "classnames";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import { getBankAccountDropdownOption } from "modules/bank-accounts/utils/getBankAccountDropdownOptions";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import DatePicker from "ui/inputs/DatePicker";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import TextInput from "ui/inputs/TextInput";
import { Pagination } from "ui/navigation/PageIndicator";
import Tabs, { Tab } from "ui/navigation/Tabs";
import Menu from "ui/overlay/Menu";
import Text from "ui/typography/Text";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { AccountOption } from "utils/customHooks/useHighbeamTransactions";
import { startOfBankingDay } from "utils/date";
import { downloadString } from "utils/download";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

import styles from "./TransactionsFilters.module.scss";

type TransactionsExportFileExtension = "csv" | "qbo" | "qfx";

const getFileTypeForExtension = (extension: TransactionsExportFileExtension) => {
  switch (extension) {
    case "csv":
      return "csv";
    case "qbo":
      return "qbo";
    case "qfx":
      return "qbo";
  }
};

export type TransactionsFiltersProps = {
  transactions: HighbeamTransaction[];
  searchQuery: string;
  setSearchQuery: (val: string) => void;
  fromDate?: Date | null;
  toDate?: Date | null;
  setFromDate?: (date: Date | null) => void;
  setToDate?: (date: Date | null) => void;
  pagination?: Pagination;
  isLoading?: boolean;
  hasExport?: boolean;
  tabs: Tab[];
  activeTab: string;
  setActiveTab: (tabId: string) => void;
  accountOptions?: AccountOption[];
  selectedAccount?: Option | null;
  setSelectedAccount?: Dispatch<SetStateAction<Option | null>>;
  unitCoDepositAccountId?: string;
  searchDisabledOnNonCompletedTabs?: boolean;
};

const TransactionsFilters: React.FC<TransactionsFiltersProps> = ({
  transactions,
  searchQuery,
  setSearchQuery,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  pagination,
  tabs,
  activeTab,
  setActiveTab,
  hasExport,
  isLoading,
  accountOptions,
  setSelectedAccount,
  selectedAccount = null,
  unitCoDepositAccountId,
  searchDisabledOnNonCompletedTabs,
}) => {
  const businessGuid = useBusinessGuid();

  // NB(alex): There are cleaner ways to do this, but this is ok for now.
  const accountsByDepositId = bankAccountsQueryHooks.useData({
    status: "open",
    select: (bankAccounts) => {
      // NB(alex): Hack to let us delete `openBankAccountsOptionsByUnitIdState`. If we need this in more places, we can move to `modules/bank-accounts`
      const openBankAccountsAsDropdownOptions = bankAccounts.map(getBankAccountDropdownOption);
      return openBankAccountsAsDropdownOptions.reduce(
        (map: Record<string, Option>, option: Option) => ({
          ...map,
          [option.value]: option,
        }),
        {}
      );
    },
  });

  const highbeamApi = useHighbeamApi();
  const [isExportLoading, setIsExportLoading] = useState(false);

  useEffect(() => {
    if (fromDate && toDate && toDate < fromDate) {
      setToDate?.(fromDate);
    }
  }, [fromDate, setToDate, toDate]);

  const handleExportClick = async (extension: TransactionsExportFileExtension) => {
    if (isExportLoading) {
      return;
    }

    setIsExportLoading(true);
    const content = await highbeamApi.transaction.list(
      businessGuid,
      getFileTypeForExtension(extension),
      unitCoDepositAccountId,
      searchQuery,
      fromDate ? startOfBankingDay(fromDate, { keepLocalTime: true }).toDate() : undefined,
      toDate ? startOfBankingDay(toDate, { keepLocalTime: true }).toDate() : undefined,
      "Deposit"
    );

    const accountName =
      unitCoDepositAccountId && accountsByDepositId[unitCoDepositAccountId]
        ? `${accountsByDepositId[unitCoDepositAccountId].description}`
        : "Highbeam all account";
    const dateRange =
      fromDate && toDate ? `${fromDate.toLocaleDateString()} - ${toDate.toLocaleDateString()}` : "";

    downloadString(`${accountName} transactions export ${dateRange}.${extension}`, content);
    setIsExportLoading(false);
  };
  const showTabs = tabs && tabs.length > 1;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.filter}>
          {accountOptions && (
            <Dropdown
              id="account-filter"
              onChange={setSelectedAccount}
              value={selectedAccount}
              label="Show transactions from"
              options={accountOptions.map((option) => ({
                ...option,
                description:
                  option.value !== "all"
                    ? option.balance !== undefined
                      ? `Balance: ${formatAmount(getDollarsFromCents(Number(option.balance)))}`
                      : "Balance unavailable"
                    : null,
              }))}
              isSearchable={false}
            />
          )}
          <TextInput
            id="search-filter"
            className={classNames({
              [styles.search]: true,
              [styles["search--expanded"]]: !Boolean(accountOptions),
            })}
            placeholder="Search for transaction"
            value={searchQuery}
            onChange={setSearchQuery}
            icon={<MagnifyingGlass size={12} />}
            disabled={activeTab !== "completed" && searchDisabledOnNonCompletedTabs}
          />
          <div className={styles.dates}>
            <div className={styles.date}>
              <DatePicker
                value={fromDate}
                onChange={setFromDate}
                label="From"
                variant="start-date"
                startDate={fromDate}
                endDate={toDate}
              />
            </div>
            <div className={styles.date}>
              <DatePicker
                value={toDate}
                onChange={setToDate}
                label="To"
                variant="end-date"
                startDate={fromDate}
                endDate={toDate}
                minDate={fromDate}
              />
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          {showTabs && (
            <Tabs
              className={styles.tabs}
              tabsWrapperAdditionalClassName={styles.wrapper}
              variant="rounded"
              tabs={tabs}
              noBorder
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
          <div className={styles.summary}>
            <Text size={14}>
              {!isLoading &&
              transactions.length > 0 &&
              pagination?.totalCount &&
              pagination.totalCount > 0
                ? pagination
                  ? `Showing ${pagination.offset + 1}-${
                      pagination.offset + transactions.length
                    } of ${pagination.totalCount} transactions`
                  : `Showing ${transactions.length} of ${transactions.length} transactions`
                : ""}
            </Text>
            {Boolean(hasExport && pagination?.totalCount) && (
              <Menu
                button={
                  <Button variant="tertiary" size="xs" disabled={isExportLoading}>
                    {isExportLoading ? <AnimatedSpinner /> : <Export />}
                    {isExportLoading ? "Exporting..." : "Export"}
                  </Button>
                }
              >
                <Menu.Item onClick={() => handleExportClick("csv")}>Export as CSV</Menu.Item>
                <Menu.Item onClick={() => handleExportClick("qbo")}>Export as QBO</Menu.Item>
                <Menu.Item onClick={() => handleExportClick("qfx")}>Export as QFX</Menu.Item>
              </Menu>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsFilters;
