import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { ErrorBoundary } from "@sentry/react";
import ErrorPage from "modules/error/pages/ErrorPage";
import { FC, Fragment, ReactNode, Suspense, useEffect, useRef } from "react";
import DotsPageLoader from "ui/feedback/DotsLoaderPage";
import { modalTransitionClasses } from "ui/overlay/ModalV4";
import cn from "utils/tailwind/cn";
import { useTransition } from "utils/transitions";

import { FullPageModalContextProvider } from "./context/useFullPageModalContext";
import FullPageModalHeader from "./FullPageModalHeader";

type ChildrenRenderProps = {
  closeModal: () => void;
};

type Props = {
  onClose: () => void;
  /**
   * Pass in a function as `children` if you want access to the animated `closeModal` function.
   */
  children: ReactNode | ((renderProps: ChildrenRenderProps) => ReactNode);
  className?: string;
};

/**
 * 🚧 WIP: Heavily inspired by `ModalV4`.
 */
const FullPageModal: FC<Props> = ({ onClose: onCloseProp, children, className }) => {
  const {
    show,
    setShow,
    handleClose: onClose,
  } = useTransition(true, {
    initiateClose: () => setShow(false),
    onClose: onCloseProp,
  });

  const modalRef = useRef<HTMLElement | null>(null);

  // Prevent closing the modal if an element is currently focused.
  useEffect(() => {
    if (!modalRef.current) return;

    const modalElement = modalRef.current;
    const focusableElements = modalElement.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        // eslint-disable-next-line functional/no-let
        for (let i = 0; i < focusableElements.length; i++) {
          // Check if the current input contains the activeElement
          if (focusableElements[i].contains(document.activeElement)) {
            e.preventDefault();

            // This should always be safe to call. Prevents ts error: "Property 'blur' does not exist on type 'Element'."
            document.activeElement &&
              "blur" in document.activeElement &&
              typeof document.activeElement.blur === "function" &&
              (document.activeElement as HTMLElement).blur();
          }
        }
      }
    };

    modalElement.addEventListener("keydown", handleKeyDown);

    return () => {
      modalElement.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <FullPageModalContextProvider onClose={onClose}>
      <Transition show={show} as={Fragment}>
        <Dialog onClose={onClose} ref={modalRef}>
          <TransitionChild
            as="div"
            className="pointer-events-none fixed inset-0 z-fullPageModal flex flex-col items-center justify-center"
            enter={modalTransitionClasses.enter}
            enterFrom={modalTransitionClasses.enterFrom}
            leave={modalTransitionClasses.leave}
            leaveTo={modalTransitionClasses.leaveTo}
          >
            <div
              className={cn(
                "pointer-events-auto absolute inset-0 overflow-y-auto bg-white",
                className
              )}
            >
              <ErrorBoundary
                fallback={({ error }) => (
                  <ErrorPage error={error} header={<FullPageModalHeader />} />
                )}
              >
                <Suspense fallback={<DotsPageLoader variant="page" />}>
                  {typeof children === "function" ? children({ closeModal: onClose }) : children}
                </Suspense>
              </ErrorBoundary>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>
    </FullPageModalContextProvider>
  );
};

export default Object.assign(FullPageModal, {
  Header: FullPageModalHeader,
});
