import { FC, ReactNode } from "react";
import Box, { BoxElementProps } from "ui/data-display/Box";

const DetailsSidebarBodyHeader = (props: BoxElementProps) => {
  return <Box baseClassName="my-10 flex items-center gap-x-4 px-8" {...props} />;
};

const DetailsSidebarBodyMain = (props: BoxElementProps) => {
  return <Box baseClassName="flex flex-grow flex-col gap-y-10" {...props} />;
};

const DetailsSidebarBodySection = (props: BoxElementProps) => {
  return <Box baseClassName="px-8" {...props} />;
};

const DetailsSidebarBodyFooter = (props: BoxElementProps) => {
  return (
    <Box as="footer" baseClassName="px-8 pb-4 pt-6 shadow-[0_-4px_6px_-1px_#0000000d]" {...props} />
  );
};

type DetailsSidebarBodyProps = Omit<BoxElementProps, "children"> & {
  header?: ReactNode;
  main?: ReactNode;
  footer?: ReactNode;
};

const DetailsSidebarBody: FC<DetailsSidebarBodyProps> = ({ header, main, footer, ...boxProps }) => {
  return (
    <Box baseClassName="flex flex-col h-full bg-white" {...boxProps}>
      <div className="flex-grow overflow-y-auto pb-32">
        {header}
        {main}
      </div>
      {footer}
    </Box>
  );
};

export default Object.assign(DetailsSidebarBody, {
  Header: DetailsSidebarBodyHeader,
  Main: DetailsSidebarBodyMain,
  Section: DetailsSidebarBodySection,
  Footer: DetailsSidebarBodyFooter,
});
