import { captureMessage } from "@sentry/react";
import classNames from "classnames";
import {
  CHECKING_INTEREST_TIERS,
  HIGH_YIELD_INTEREST_TIERS,
  InterestTier,
} from "modules/bank-accounts/constants/interestTiers";
import { FC } from "react";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { roundEpsilon } from "utils/math";

import styles from "./ApyTooltipContent.module.scss";

type Props = {
  account: BankAccountRep.Complete;
};

const getInterestTiers = (account: BankAccountRep.Complete): readonly InterestTier[] => {
  switch (account.highbeamType.name) {
    case "DepositAccount":
      return CHECKING_INTEREST_TIERS;
    case "HighYield":
      return HIGH_YIELD_INTEREST_TIERS;
    default:
      captureMessage(`Unknown account type: ${account.highbeamType.name}`);
      return [];
  }
};

const getAccountTypeCopy = (account: BankAccountRep.Complete): string => {
  if (account.highbeamType.name === "HighYield") {
    return "high yield";
  }

  return "checking";
};

const ApyTooltipContent: FC<Props> = ({ account }) => {
  const interestTiers = getInterestTiers(account);
  const accountType = getAccountTypeCopy(account);

  return (
    <div className={styles.currentApyTooltipContainer}>
      <div className={classNames(styles.row, styles.headers)}>
        <Text size={14}>Total checking balance</Text>
        <Text size={14}>APY</Text>
      </div>
      {interestTiers.map((tier) => {
        const isSelected = account.depositProduct.interestBps === tier.interestBps;
        return (
          <div className={styles.row} key={tier.range}>
            <Text size={14} color={isSelected ? colors.purple[500] : undefined} weight="medium">
              {tier.range} {isSelected && " (Current)"}
            </Text>
            <Text size={14} color={isSelected ? colors.purple[500] : undefined} weight="medium">
              {roundEpsilon(tier.interestBps / 100, 2)}%
            </Text>
          </div>
        );
      })}
      <div className={styles.disclaimerContainer}>
        <Text size={12}>
          APY is calculated on the combined balance of all {accountType} accounts. APY is updated at
          the end of every business day.
        </Text>
      </div>
    </div>
  );
};

export default ApyTooltipContent;
