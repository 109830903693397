import { ErrorBoundary } from "@sentry/react";
import StartNewBankingApplicationConfirmationModal from "modules/bank-application/dialogs/StartNewBankingApplicationConfirmationModal";
import { FC, useState } from "react";
import cn from "utils/tailwind/cn";

import UserAvatarMenuButton from "./UserAvatarMenuButton";
import UserAvatarMenuDropdown from "./UserAvatarMenuDropdown";

type Props = {
  className?: string;
  dropdownClassName?: string;
};

const UserAvatarMenu: FC<Props> = ({ className, dropdownClassName }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isBankingApplicationModalOpen, setIsBankingApplicationModalOpen] = useState(false);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  return (
    <ErrorBoundary fallback={<></>}>
      <div className={cn("tablet:relative", className)}>
        {isBankingApplicationModalOpen && (
          <StartNewBankingApplicationConfirmationModal
            onClose={() => setIsBankingApplicationModalOpen(false)}
          />
        )}

        <UserAvatarMenuButton onOpenUserMenu={handleOpenUserMenu} />
        {isUserMenuOpen && (
          <UserAvatarMenuDropdown
            onCloseUserMenu={handleCloseUserMenu}
            onClickAddNewBusiness={() => setIsBankingApplicationModalOpen(true)}
            className={dropdownClassName}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default UserAvatarMenu;
