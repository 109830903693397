import { DefaultError, useQueryClient } from "@tanstack/react-query";
import ApSettingsRep from "reps/ApSettingsRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import apSettingsQueryHooks from "../queries/apSettingsQueryHooks";

const useUpdateApSettingsMutation = (
  apSettingsId: string,
  additionalOptions?: MutationAdditionalOptions<ApSettingsRep.Complete, ApSettingsRep.Updater>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshApSettingsQuery = apSettingsQueryHooks.useRefreshQuery({});

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => highbeamApi.apSettings.update(apSettingsId, variables),
      onSuccess: async () => {
        await refreshApSettingsQuery();
      },
    },
    additionalOptions || {}
  );
};

export default useUpdateApSettingsMutation;

//
// Optimistic updates
//

type MutationContext = {
  originalApSettings?: ApSettingsRep.Complete;
};

export const useOptimisticUpdateApSettingsMutation = (
  apSettingsId: string,
  additionalOptions?: MutationAdditionalOptions<
    ApSettingsRep.Complete,
    ApSettingsRep.Updater,
    DefaultError,
    MutationContext
  >
) => {
  const highbeamApi = useHighbeamApi();
  const refreshApSettingsQuery = apSettingsQueryHooks.useRefreshQuery({});
  const queryClient = useQueryClient();
  const apSettingsQueryKey = apSettingsQueryHooks.useQueryKey({});

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => highbeamApi.apSettings.update(apSettingsId, variables),
      onMutate: async (variables) => {
        await queryClient.cancelQueries({ queryKey: apSettingsQueryKey });

        const originalApSettings =
          queryClient.getQueryData<ApSettingsRep.Complete>(apSettingsQueryKey);

        queryClient.setQueryData<typeof variables>(apSettingsQueryKey, (old) => ({
          ...old,
          ...variables,
        }));

        return { originalApSettings };
      },
      onSuccess: async () => {
        await refreshApSettingsQuery();
      },
      onError: (error, variables, context) => {
        if (context) {
          const { originalApSettings } = context;
          if (originalApSettings) {
            queryClient.setQueryData(apSettingsQueryKey, originalApSettings);
          }
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: apSettingsQueryKey });
      },
    },
    additionalOptions || {}
  );
};
