import {
  CardReversalTransaction,
  CardTransaction,
  PurchaseTransaction,
} from "@highbeam/unit-node-sdk";
import { captureMessage } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { UNIT_CO_TRANSACTIONS_QUERY_KEY } from "modules/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import { FC, ReactNode } from "react";
import NotFoundCell from "ui/table/cells/NotFoundCell";

type RenderProps = {
  relatedTransaction: CardTransaction | PurchaseTransaction;
};

type Props = {
  cardReversalTransaction: CardReversalTransaction;
  children: ({ relatedTransaction }: RenderProps) => ReactNode;
};

/**
 * Wraps a component with a renderer which returns a card reversal transaction's related transaction.
 */
const CardReversalTransactionRelatedTransactionComponent: FC<Props> = ({
  cardReversalTransaction,
  children,
}) => {
  // NB(alex): I'm pretty sure `transactionId` should always be defined, but not sure... just following the typescript types.
  const transactionId = cardReversalTransaction.relationships.relatedTransaction?.data.id;
  const accountId = cardReversalTransaction.relationships.account.data.id;

  const unitApi = useUnitApi();

  const { data: relatedTransaction } = useSuspenseQuery({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, accountId, transactionId],
    queryFn: async () => {
      if (!transactionId) {
        return null;
      }

      const { data } = await unitApi.transactions.get(accountId, transactionId);
      return data;
    },
  });

  // NB(alex): I'm like 85% sure card reversals are one of these 2 types, but if not this should be updated to handle all cases.
  if (
    relatedTransaction?.type === "cardTransaction" ||
    relatedTransaction?.type === "purchaseTransaction"
  ) {
    return <>{children({ relatedTransaction })}</>;
  }

  // NB(alex): I don't think either of these should ever happen, but they may. If they do, we should add code to handle them appropriately.
  if (relatedTransaction === null) {
    captureMessage(
      `Alert(alex): Could not find related transaction for card reversal transaction: "${transactionId}".`
    );
  } else {
    captureMessage(
      `Alert(alex): Related transaction for card reversal transaction: "${cardReversalTransaction.id}" is of type: "${relatedTransaction.type}".`
    );
  }

  return <NotFoundCell />;
};

export default CardReversalTransactionRelatedTransactionComponent;
