import env from "env";
import useUser from "modules/user/queries/useUser";
import { FC, useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import UserInvitationRep from "reps/UserInvitationRep";
import auth0ClientState from "state/auth/auth0Client";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import TextLink from "ui/navigation/TextLink";
import { Heading1, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

import UserInvitationAlreadyAcceptedCard from "./UserInvitationAlreadyAcceptedCard";

type Props = {
  invitation: UserInvitationRep.Complete;
  userExists: boolean;
  className?: string;
};

const UserInvitationCard: FC<Props> = ({ invitation, userExists, className }) => {
  const auth0 = useRecoilValue(auth0ClientState);
  const [isPending, setIsPending] = useState(false);
  const user = useUser({ loginRequired: false });

  const isInvitedUserEmailSameAsCurrentUserEmail =
    user && user.emailAddress === invitation.emailAddress;

  const onAcceptInvite = useCallback(async () => {
    // Redirects so we don't set `setIsPending(false)`.
    setIsPending(true);

    void auth0.loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}/onboarding`,
      },
      authorizationParams: {
        /* eslint-disable camelcase */
        screen_hint: userExists ? "login" : "signup",
        login_hint: invitation.emailAddress,
        redirectMethod: "replace",
        userInvitationSlug: invitation.slug,
        // NB(alex): `max_age: 0` forces the user to sign back in. We only need the user to sign back in if the invite was sent to a different email.
        ...(isInvitedUserEmailSameAsCurrentUserEmail ? {} : { max_age: 0 }),
        /* eslint-enable camelcase */
      },
    });
  }, [
    auth0,
    invitation.emailAddress,
    invitation.slug,
    userExists,
    isInvitedUserEmailSameAsCurrentUserEmail,
  ]);

  if (invitation.accepted) {
    return <UserInvitationAlreadyAcceptedCard loginHintEmail={invitation.emailAddress} />;
  }

  return (
    <Card
      shadow="xs"
      className={cn("flex w-full max-w-2xl flex-col items-center gap-x-8", className)}
    >
      <div className="flex flex-col items-center gap-y-6 rounded-lg bg-white px-8 py-8">
        <div className="flex flex-col gap-y-2">
          <Heading1 className="text-center">
            {invitation.senderName} invited you to{" "}
            {invitation.businessDisplayName ? (
              <>access the Highbeam account for {invitation.businessDisplayName}</>
            ) : (
              <TextLink external to={env.HIGHBEAM_MARKETING_SITE_URL}>
                Highbeam
              </TextLink>
            )}
            .
          </Heading1>
          <Paragraph className="mb-1 mt-1 text-center text-md text-grey-500">
            Once you accept the invite, you will be able to access their Highbeam account.
          </Paragraph>
        </div>
        <Button
          variant="primary"
          className="mb-2 w-full tablet:w-auto"
          onClick={onAcceptInvite}
          isLoading={isPending}
        >
          Accept invite
        </Button>
      </div>
    </Card>
  );
};

export default UserInvitationCard;
