import { FC, PropsWithChildren } from "react";

const DetailsSidebarPanelHeader: FC<PropsWithChildren> = ({ children }) => (
  <header className="bg-white">{children}</header>
);

const DetailsSidebarPanelBody: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex-grow overflow-y-auto">{children}</div>
);

const DetailsSidebarPanel: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex h-full flex-col bg-grey-50 @container">{children}</div>;
};

export default Object.assign(DetailsSidebarPanel, {
  Header: DetailsSidebarPanelHeader,
  Body: DetailsSidebarPanelBody,
});
