import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { getCentsFromDollars } from "utils/money";
import { z } from "zod";

export const drawdownAmountFormSchema = z
  .object({
    amount: z.string().transform((data, ctx) => {
      const amountInCents = getCentsFromDollars(data);
      if (amountInCents <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid amount.",
        });
      }
      return amountInCents;
    }),
    drawdownFromCapitalAccount: z.custom<CapitalAccountSummaryRep.Complete>(
      (value) => Boolean(value),
      "Please select a capital account."
    ),
    drawdownToBankAccount: z.custom<BankAccountRep.Complete>(
      (value) => Boolean(value),
      "Please select a bank account."
    ),
  })
  .superRefine((data, ctx) => {
    if (data.drawdownFromCapitalAccount.available < data.amount) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Your drawdown amount is larger than your remaining line of credit.",
        path: ["amount"],
      });
    }
  });

export type DrawdownAmountFormInputs = z.input<typeof drawdownAmountFormSchema>;
export type DrawdownAmountFormOutputs = z.output<typeof drawdownAmountFormSchema>;

type Params = {
  defaultValues: DrawdownAmountFormInputs;
};

const useDrawdownAmountForm = ({ defaultValues }: Params) => {
  return useForm({
    resolver: zodResolver(drawdownAmountFormSchema),
    defaultValues,
  });
};

export default useDrawdownAmountForm;
