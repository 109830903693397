import BankAccountsDropdown from "modules/bank-accounts/components/BankAccountsDropdown";
import useEditCapitalAccountAutoPayAccountMutation from "modules/capital-accounts/mutations/useEditCapitalAccountAutoPayAccountMutation";
import { FC, useState } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Helper from "ui/inputs/Helper";
import ModalV4 from "ui/overlay/ModalV4";

import BackupAutoPayAccountsHelper from "../components/BackupAutoPayAccountsHelper";
import NextAutoPaymentHighlightItem from "../components/NextAutoPaymentHighlightItem";
import useCapitalBackupAutoPayAccounts from "../hooks/useCapitalBackupAutoPayAccounts";
import { useNextCapitalAutoPayAmount } from "../queries/capitalRepaymentAmountsQueryHooks";

type EditCapitalAccountAutoPayAccountModalContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const EditCapitalAccountAutoPayAccountModalContent: FC<
  EditCapitalAccountAutoPayAccountModalContentProps
> = ({ capitalAccountSummary }) => {
  const nextAutoPayAmount = useNextCapitalAutoPayAmount({
    capitalAccountGuid: capitalAccountSummary.guid,
    enabled: capitalAccountSummary.state === CapitalAccountRep.State.Active,
  });

  const { openBankAccounts, plaidBankAccounts } = useCapitalBackupAutoPayAccounts();

  const [selectedAccount, setSelectedAccount] = useState(
    openBankAccounts.find((option) => {
      return option.guid === capitalAccountSummary.details.repayment.bankAccountGuid;
    }) ?? openBankAccounts[0]
  );
  const { mutateAsync: updateRepaymentAccount, isPending } =
    useEditCapitalAccountAutoPayAccountMutation(capitalAccountSummary.guid);

  const chargeCardBalance =
    0 - (capitalAccountSummary.cardBalance + capitalAccountSummary.cardPending);

  return (
    <ModalV4.Form
      onSubmit={async (_e, { closeModalWithAnimation }) => {
        await updateRepaymentAccount({
          repaymentBankAccountGuid: selectedAccount.guid,
        });
        closeModalWithAnimation();
      }}
    >
      <ModalV4.Header>Edit auto-pay</ModalV4.Header>

      <ModalV4.Body className="flex flex-col gap-y-6">
        <NextAutoPaymentHighlightItem nextAutoPayAmount={nextAutoPayAmount} />

        <div>
          <BankAccountsDropdown
            label="Auto-pay account"
            value={selectedAccount}
            onChange={(option) => {
              if (option) {
                setSelectedAccount(option);
              }
            }}
            options={openBankAccounts}
          />

          {selectedAccount.availableBalance < chargeCardBalance ? (
            <Helper iconVariant="warning">
              Account balance of <MoneyAmount cents={selectedAccount.availableBalance} withCents />{" "}
              is less than the current card balance.
            </Helper>
          ) : (
            <BackupAutoPayAccountsHelper
              openBankAccounts={openBankAccounts}
              plaidBankAccounts={plaidBankAccounts}
              className="mt-4"
            />
          )}
        </div>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton
          disabled={
            selectedAccount.guid === capitalAccountSummary.details.repayment.bankAccountGuid
          }
          isLoading={isPending}
        >
          Save changes
        </ModalV4.SubmitButton>

        <ModalV4.CloseButton variant="ghost" />
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  onClose: () => void;
};

const EditCapitalAccountAutoPayAccountModal: FC<Props> = ({ capitalAccountSummary, onClose }) => {
  return (
    <ModalV4 onClose={onClose} dropdownOverflowHack>
      <EditCapitalAccountAutoPayAccountModalContent capitalAccountSummary={capitalAccountSummary} />
    </ModalV4>
  );
};

export default EditCapitalAccountAutoPayAccountModal;
