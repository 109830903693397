import { captureException } from "@sentry/react";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import { atom } from "recoil";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

const inviteUserModalState = atom<DialogState>({
  key: "settings/users/inviteUserModalState",
  default: {
    isOpen: false,
  },
});

export default inviteUserModalState;

// DEPRECATED

export const useInviteUserModal = () => {
  const { mfa } = useMfa();
  const { segmentTrack } = useSegment();
  const businessGuid = useBusinessGuid();

  const controls = useModal(inviteUserModalState);

  const open = async () => {
    try {
      await mfa({ allowWhileSuperusering: true });
      segmentTrack(SEGMENT_EVENTS.USER_INVITE_STARTED, { businessGuid });
      controls.open({});
    } catch (error) {
      if (!(error instanceof MfaCanceledError)) {
        captureException(error);
      }
    }
  };

  return {
    ...controls,
    open,
  };
};
