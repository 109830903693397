import dayjs from "dayjs";
import React from "react";
import { YearChatElement } from "reps/chat/ChatElement";

type Props = {
  element: YearChatElement;
};

const ResponseMessageYear: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value).tz("America/New_York").format("YYYY")}</span>;
};

export default ResponseMessageYear;
