import { DownloadSimple, Eyeglasses } from "@phosphor-icons/react";
import MultiStep from "layouts/MultiStep";
import {
  CHARGE_CARD_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL,
  LINE_OF_CREDIT_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL,
} from "modules/capital/constants";
import useLineOfCreditAgreement from "modules/line-of-credit-agreement/queries/useLineOfCreditAgreement";
import useDownloadLineOfCreditAgreementMutation from "modules/line-of-credit/mutations/useDownloadLineOfCreditAgreementMutation";
import useUpdateLineOfCreditUserActionsMetadataMutation from "modules/line-of-credit/mutations/useUpdateLineOfCreditUserActionsMetadataMutation";
import { permissionsTooltipCopy } from "pages/capital/CapitalAccountPage/constants";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Divider from "ui/data-display/Divider";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import { Heading4, Span } from "ui/typography";
import useIsAllowedToDownloadCreditOfferAgreement from "utils/permissions/useIsAllowedToDownloadCreditOfferAgreement";

import ReviewLineOfferBackButton from "../../components/ReviewLineOfferBackButton";
import {
  useReviewLineOfferAgreementFormContext,
  useReviewLineOfferContext,
} from "../../context/ReviewLineOfferProvider";

import AgreementPlainLanguageSummary from "./AgreementPlainLanguageSummary";

const AGREEMENT_FORM_ID = "agreement-form";

type AgreementViewContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AgreementViewContent: FC<AgreementViewContentProps> = ({ capitalAccountSummary }) => {
  const { control } = useReviewLineOfferAgreementFormContext();
  const lineOfCreditAgreement = useLineOfCreditAgreement(capitalAccountSummary.guid);
  const isAllowedToDownloadCreditOfferAgreement = useIsAllowedToDownloadCreditOfferAgreement();
  const { agreementDownloaded, setAgreementDownloaded } = useReviewLineOfferContext();
  const { mutateAsync: downloadLineOfCreditAgreementMutation, isPending } =
    useDownloadLineOfCreditAgreementMutation({
      onSuccess: () => setAgreementDownloaded(true),
    });
  const { mutateAsync: updateLineOfCreditUserActionsMetadataMutation } =
    useUpdateLineOfCreditUserActionsMetadataMutation();
  const isSuperusering = useIsSuperusering();

  const onDownloadAgreement = async () => {
    await downloadLineOfCreditAgreementMutation({
      lineOfCreditGuid: capitalAccountSummary.guid,
      unsignedAgreementGuid: lineOfCreditAgreement?.unsignedAgreementGuid,
      signedAgreementGuid: lineOfCreditAgreement?.signedAgreementGuid,
    });

    if (!lineOfCreditAgreement?.unsignedAgreementOpenedAt && !isSuperusering) {
      await updateLineOfCreditUserActionsMetadataMutation({
        capitalAccountGuid: capitalAccountSummary.guid,
      });
    }
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="overflow-hidden rounded-lg border border-grey-200">
        <div className="flex items-center gap-x-2 bg-grey-50 px-6 py-4">
          <Eyeglasses className="size-6 text-grey-700" />
          <Heading4 className="text-sm font-medium text-grey-600">Plain language summary</Heading4>
        </div>
        <Divider className="my-0" />
        <AgreementPlainLanguageSummary capitalAccountSummary={capitalAccountSummary} />
      </div>

      <div className="flex items-center gap-x-4">
        <ButtonWithTooltip
          variant={agreementDownloaded ? "ghost" : "primary"}
          onClick={onDownloadAgreement}
          isLoading={isPending}
          disabled={!isAllowedToDownloadCreditOfferAgreement}
          tooltip={
            !isAllowedToDownloadCreditOfferAgreement &&
            permissionsTooltipCopy.notAllowedToDownloadCreditAgreement
          }
        >
          <DownloadSimple size={24} />
          Download full agreement
        </ButtonWithTooltip>
        <Span className="text-sm text-grey-500">Required</Span>
      </div>

      <Divider className="my-0" />

      {capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly && (
        <Controller
          name="chargeCardAgreementConfirmed"
          control={control}
          render={({ field }) => (
            <CheckboxLabel
              label={
                <>
                  I agree to the{" "}
                  <a
                    href={"https://www.highbeam.co/legal/card-terms-of-use"}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block font-medium underline"
                  >
                    Highbeam Card Terms of Use
                  </a>
                  {" and "}
                  <a
                    href={"https://www.highbeam.co/legal/card-authorized-user-terms-of-use"}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block font-medium underline"
                  >
                    Highbeam Authorized User
                  </a>
                  {" terms."}
                </>
              }
              checked={field.value}
              textWeight="regular"
              onChange={field.onChange}
              disabled={!agreementDownloaded}
            />
          )}
        />
      )}

      <Controller
        name="readAgreementConfirmed"
        control={control}
        render={({ field }) => (
          <CheckboxLabel
            label={
              capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly
                ? CHARGE_CARD_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL
                : LINE_OF_CREDIT_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL
            }
            checked={field.value}
            textWeight="regular"
            onChange={field.onChange}
            disabled={!agreementDownloaded}
          />
        )}
      />
    </div>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AgreementView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();
  const { formState } = useReviewLineOfferAgreementFormContext();
  const { nextPathname } = useReviewLineOfferContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={AGREEMENT_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            Review capital agreement
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        <AgreementViewContent capitalAccountSummary={capitalAccountSummary} />
      </MultiStep.Section>

      <MultiStep.Controls>
        <ReviewLineOfferBackButton />
        <MultiStep.Controls.NextButton
          form={AGREEMENT_FORM_ID}
          tooltip={
            !formState.isValid &&
            "Please download the agreement, read the terms, and confirm that you agree to the terms."
          }
          disabled={!formState.isValid}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default AgreementView;
