import { FC } from "react";
import { roundEpsilon } from "utils/math";

import { CapitalAccountSummaryWithChargeCard } from "../utils/isCapitalAccountSummaryWithChargeCard";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardOverdueBalanceInterestExplanation: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const targetRepaymentDays = capitalAccountSummaryWithChargeCard.details.targetRepaymentDays;

  const { netApr, apr } = capitalAccountSummaryWithChargeCard.details;

  const diffApr = roundEpsilon(netApr - apr, 2);
  const aprPercentage = roundEpsilon(apr * 100, 2);
  const diffAprPercentage = roundEpsilon(diffApr * 100, 2);

  const aprCopy =
    diffApr === 0 ? (
      <>{aprPercentage}% APR</>
    ) : (
      <>
        {aprPercentage}% APR + {diffAprPercentage}% default APR
      </>
    );

  return (
    <>
      After the due date, any overdue balance will accrue interest daily at {aprCopy} and Highbeam
      will auto-debit from your auto-pay accounts over the following{" "}
      {targetRepaymentDays === 1 ? "days" : `${targetRepaymentDays} days`} until the balance is
      repaid in full.
    </>
  );
};

export default ChargeCardOverdueBalanceInterestExplanation;
