import { FC, ReactNode } from "react";
import Card from "ui/data-display/Card";
import cn from "utils/tailwind/cn";

type Props = {
  children: ReactNode;
  className?: string;
};

const PlaidConnectionCard: FC<Props> = ({ children, className }) => {
  return (
    <Card
      className={cn("flex min-h-80 w-full flex-col border border-grey-200", className)}
      shadow="xs"
    >
      {children}
    </Card>
  );
};

export default PlaidConnectionCard;
