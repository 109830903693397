import { CardSpendLimit } from "modules/card-spend-limit/queries/cardSpendLimitQueryHooks";
import { FC } from "react";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import BarChart from "ui/data-visualization/BarChart";
import Text from "ui/typography/Text";

type Props = {
  spendLimit: CardSpendLimit;
};

const CardSpendLimitBarChart: FC<Props> = ({ spendLimit }) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <BarChart height={8} className="w-full">
        <BarChart.Bar
          widthPercentage={100 * (spendLimit.spentInCents / spendLimit.limitInCents)}
          color="black"
        />
      </BarChart>
      <div>
        <Text as="span" size={14}>
          Spent
        </Text>

        <MoneyAmountFraction
          className="ml-2 inline-flex"
          numeratorInCents={spendLimit.spentInCents}
          denominatorInCents={spendLimit.limitInCents}
        />
      </div>
    </div>
  );
};

export default CardSpendLimitBarChart;
