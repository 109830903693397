import dollarSignPurpleIcon from "assets/dollar-sign-purple.svg";
import emptyBalanceGraph from "assets/empty-balance-graph.svg";
import logoWithoutLabel from "assets/highbeam-logo-without-label.svg";
import classNames from "classnames";
import { Milestone } from "modules/action-items/types";
import usePrimaryBankAccount from "modules/bank-accounts/queries/usePrimaryBankAccount";
import {
  DEFAULT_ACTIVE_TAB,
  usePayoutInstructionsModal,
} from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import actionItemsState from "state/actionItems/actionItems";
import colors from "styles/colors";
import Card from "ui/data-display/Card";
import CashDisplay from "ui/data-display/money/CashDisplay";
import Button from "ui/inputs/Button";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./HomeBalance.module.scss";

// NB(alex): We can probably do a better job of consolidating both components.
export const sharedCardClasses = "w-full border border-grey-200 p-5 extra-large-desktop:max-w-xl";

const FundYourAccount = () => {
  const navigate = useNavigate();

  const actionItems = useRecoilValue(actionItemsState(Milestone.FundYourAccount));
  const transferMoneyActionItem = actionItems[0];
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  const primaryBankAccount = usePrimaryBankAccount({ required: true });

  return (
    <>
      <div className={classNames(styles.container, styles.containerEmpty)}>
        <div className={styles["balance-container"]}>
          <Heading2>
            <img className={styles.logo} src={logoWithoutLabel} alt="Highbeam logo" />
            Highbeam balance
          </Heading2>
          <div className={styles["balance-container__balance"]}>
            <CashDisplay color={colors.black} cents={0} />
            <Text size={12} className={styles.text}>
              as of {"today"}
            </Text>
          </div>
        </div>

        <div className={styles.emptyBalanceContainer}>
          <Card shadow="none" className={sharedCardClasses}>
            <img src={dollarSignPurpleIcon} alt="dollar sign icon" />
            <div className={styles.fundAccountDetails}>
              <Text size={14} weight="bold">
                {transferMoneyActionItem.title}
              </Text>
              <Text size={14}>{transferMoneyActionItem.description}</Text>
            </div>
            <div className={styles.fundAccountButtons}>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(transferMoneyActionItem.primaryActionPath, {
                    state: { toAccountGuid: primaryBankAccount.guid },
                  });
                }}
                size="sm"
              >
                {transferMoneyActionItem.primaryActionText}
              </Button>
              <Button
                variant="secondary"
                onClick={() => openPayoutInstructionsModal({ activeTab: DEFAULT_ACTIVE_TAB })}
                size="sm"
              >
                Connect your payouts
              </Button>
            </div>
          </Card>
          <div className={styles.emptyChartContainer}>
            <img src={emptyBalanceGraph} alt="empty balance graph" className={styles.emptyChart} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FundYourAccount;
