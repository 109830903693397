import { RepaymentOption } from "./useRepayHighbeamCardsForm";

type Params = {
  currentStatementAmount: number;
  lastStatementAmount: number;
  overdueAmount: number;
};

const getRepaymentOptions = ({
  currentStatementAmount,
  lastStatementAmount,
  overdueAmount,
}: Params): RepaymentOption[] => {
  const totalAmount = currentStatementAmount + lastStatementAmount + overdueAmount;

  const options: RepaymentOption[] = [];

  if (overdueAmount > 0) {
    options.push("overdue-balance");
  }

  if (lastStatementAmount > 0) {
    options.push("last-statement-balance");
  }

  // Only show `current-statement-balance` if there aren't any other values, otherwise we show `total-balance` instead.
  if (options.length === 0 && currentStatementAmount > 0) {
    options.push("current-statement-balance");
  } else if (totalAmount > 0 && (options.length > 1 || currentStatementAmount > 0)) {
    options.push("total-balance");
  }

  options.push("custom");

  return options;
};

export default getRepaymentOptions;
