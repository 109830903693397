import { ComponentProps, FC } from "react";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import cn from "utils/tailwind/cn";

type Props = ComponentProps<typeof MoneyAmount>;

const AmountCell: FC<Props> = ({ className, ...props }) => {
  return (
    <MoneyAmount
      className={cn("block", className)} // NB(alex): This ensures the element gets positioned to the right. It may not be necessary once we use a new table component.
      weight="medium"
      {...props}
    />
  );
};

export default AmountCell;
