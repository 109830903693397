import { X } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import Divider from "ui/data-display/Divider";
import Button from "ui/inputs/Button";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import useFullPageModalContext from "../context/useFullPageModalContext";

const CloseButton: FC = () => {
  const { onClose } = useFullPageModalContext();

  return (
    <Button paddingVariant="square" variant="ghost" onClick={onClose}>
      <X size={24} />
    </Button>
  );
};

type Props = PropsWithChildrenAndClassName & {
  actions?: ReactNode;
};

const FullPageModalHeader: FC<Props> = ({ children, className, actions }) => (
  <header
    className={cn(
      "flex w-full items-start justify-between gap-x-6 border-b border-grey-200 px-8 py-4",
      className
    )}
  >
    <div className="flex flex-grow items-start">
      <CloseButton />
      {children && <Divider orientation="vertical" className="ml-2 mr-4 mt-2 h-6" />}
      <div className="pt-1">{children}</div>
    </div>

    {actions && <div>{actions}</div>}
  </header>
);

export default FullPageModalHeader;
