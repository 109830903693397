import chargeCardProgramQueryHooks from "modules/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import checkIsCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "modules/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import StatementCycleExplanation from "modules/charge-cards/components/StatementCycleExplanation";
import { checkIsCashCreditTerm } from "modules/charge-cards/utils/check-credit-terms";
import { FC } from "react";
import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type ChargeCardCapitalAccountPlainLanguageSummaryProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardCapitalAccountPlainLanguageSummary: FC<
  ChargeCardCapitalAccountPlainLanguageSummaryProps
> = ({ capitalAccountSummaryWithChargeCard }) => {
  const chargeCardProgram = chargeCardProgramQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    state: capitalAccountSummaryWithChargeCard.state,
  });

  const isCashCreditTerm = checkIsCashCreditTerm(chargeCardProgram.creditTerms);

  return (
    <ul className="flex list-disc flex-col gap-y-4 pl-4 text-sm text-grey-500">
      <li>
        Your card limit will start at{" "}
        <MoneyAmount cents={capitalAccountSummaryWithChargeCard.details.limit} withCents={false} />.
      </li>
      <li>
        Statement period: <StatementCycleExplanation creditTerms={chargeCardProgram.creditTerms} />
      </li>
      <li>
        Cards that are unpaid at the end of the statement period will become overdue and be paid
        back over {capitalAccountSummaryWithChargeCard.details.targetRepaymentDays} additional days
        at {capitalAccountSummaryWithChargeCard.details.apr * 100}% APR. Missing an overdue payment
        can result in your card being frozen.
      </li>
      {/*
          TODO(alex): API does not have `drawPeriodInDays` or `drawPeriodEndsAt` yet.
          <li>
            This card will be active until {{Today() + drawPeriodInDays}}. At the end of the term, your card will be re-underwritten to reflect updated performance.
          </li>
        */}
      <li>
        Within 45 days of activation, Highbeam must act as your primary operating account. i.e.
        sales payouts, payroll, inventory, marketing and card payments must flow through Highbeam.
      </li>
      {!isCashCreditTerm && (
        <li>
          No new debt can be taken on without Highbeam approval. Taking on new debt without approval
          can cause the card to be frozen.
        </li>
      )}
      <li>
        Highbeam will request financials at least once per quarter, and they need to be submitted
        within two weeks of receiving the request.
      </li>
      {capitalAccountSummaryWithChargeCard.details.securedStatus ===
        CapitalAccountDetailsRep.SecuredStatus.Secured && (
        <li>All assets are used as collateral against this line.</li>
      )}
      {isCashCreditTerm && <li>Must keep all external banks and cards connections live.</li>}
      {isCashCreditTerm && (
        <li>Missing a Cash Card payment will result in your card being frozen.</li>
      )}
    </ul>
  );
};

type CashAccessPlainLanguageSummaryProps = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const CashAccessPlainLanguageSummary: FC<CashAccessPlainLanguageSummaryProps> = ({
  capitalAccountSummaryWithCashAccessOnly,
}) => {
  return (
    <ul className="flex list-disc flex-col gap-y-4 pl-4 text-sm text-grey-500">
      <li>
        Your line limit will start at{" "}
        <MoneyAmount
          cents={capitalAccountSummaryWithCashAccessOnly.details.limit}
          withCents={false}
        />{" "}
        with a {capitalAccountSummaryWithCashAccessOnly.details.apr * 100}% APR.
      </li>
      {/* TODO(alex): API does not have `drawPeriodInDays` or `drawPeriodEndsAt` yet.
          <li>
            This line will be active until {{Today() + drawPeriodInDays}}. At the end of the term, your line will be re-underwritten to reflect updated financial performance.
          </li>
        */}
      <li>
        Most draws are funded immediately. However, large draws can take 2-5 business days to
        process.
      </li>
      {/* TODO(alex): API does not have `drawPeriodInDays` or `drawPeriodEndsAt` yet.
          <li>
            Each draw must be paid back automatically within {capitalAccountSummaryWithCashAccessOnly.details.targetRepaymentDays} of the draw, of which you are able to redraw until {{Today() + drawPeriodInDays}}.
          </li>
        */}
      <li>
        Within 45 days of activation, Highbeam must act as your primary operating account. i.e.
        sales payouts, payroll, inventory, marketing and card payments must flow through Highbeam.
      </li>
      <li>
        No new debt can be taken on without Highbeam approval. Taking on new debt without approval
        can cause the line to be paused.
      </li>
      <li>
        Highbeam will request financials at least once per quarter, and they need to be submitted
        within two weeks of receiving the request.
      </li>
      {capitalAccountSummaryWithCashAccessOnly.details.securedStatus ===
        CapitalAccountDetailsRep.SecuredStatus.Secured && (
        <li>All assets are used as collateral against this line.</li>
      )}
    </ul>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AgreementPlainLanguageSummary: FC<Props> = ({ capitalAccountSummary }) => (
  <div className="flex flex-col p-6">
    {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
      <ChargeCardCapitalAccountPlainLanguageSummary
        capitalAccountSummaryWithChargeCard={capitalAccountSummary}
      />
    ) : (
      <CashAccessPlainLanguageSummary
        capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary}
      />
    )}
  </div>
);

export default AgreementPlainLanguageSummary;
