import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadString } from "utils/download";

const useDownloadMt103 = (paymentMetadataGuid: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutation({
    mutationFn: async () => {
      const receipt = await highbeamApi.internationalPaymentDetails.getPaymentReceipt(
        businessGuid,
        paymentMetadataGuid
      );

      if (!receipt) {
        notify("info", "The MT103 form is not ready yet. Please try again later.");
      }

      const fileName = `MT103-payment.txt`;
      downloadString(fileName, receipt);
    },
    onError: () => {
      notify("info", "Something went wrong while downloading the MT103 form. Please try again.");
    },
  });
};

export default useDownloadMt103;
