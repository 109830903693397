import CapitalAccountRepaymentPeriodContent from "modules/capital-accounts/components/CapitalAccountRepaymentPeriodContent";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import Text from "ui/typography/Text";
import { roundEpsilon } from "utils/math";

type Props = {
  capitalAccountSummaryCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const LineOfCreditSummaryItems = ({ capitalAccountSummaryCashAccessOnly }: Props) => {
  return (
    <SummaryArray className="w-full bg-white">
      <SummaryArray.Item>
        <SummaryArray.Heading>
          Starting limit
          <IconWithTooltip
            color="dark"
            tooltip="Limits can grow over time based on your business’s financial performance. Limits are reviewed quarterly and adjusted as needed."
          />
        </SummaryArray.Heading>
        <CashDisplay cents={capitalAccountSummaryCashAccessOnly.details.limit} />
      </SummaryArray.Item>

      {capitalAccountSummaryCashAccessOnly.details.netApr > 0 && (
        <SummaryArray.Item>
          <SummaryArray.Heading>
            <Text size={14}>APR</Text>
            <IconWithTooltip
              color="dark"
              tooltip="Interest is charged daily based on the outstanding balance"
            />
          </SummaryArray.Heading>
          <SummaryArray.Value>
            {roundEpsilon(capitalAccountSummaryCashAccessOnly.details.netApr * 100, 2)}%
          </SummaryArray.Value>
        </SummaryArray.Item>
      )}

      <SummaryArray.Item>
        <SummaryArray.Heading>Repayment period</SummaryArray.Heading>
        <SummaryArray.Value>
          <CapitalAccountRepaymentPeriodContent
            capitalAccountSummary={capitalAccountSummaryCashAccessOnly}
          />
        </SummaryArray.Value>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default LineOfCreditSummaryItems;
