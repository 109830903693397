import { useQuery } from "@tanstack/react-query";
import ConnectStores from "components/ConnectStores";
import useConnectedStoresQueryOptions from "modules/connected-stores/queries/useConnectedStoresQueryOptions";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import SkipButtonLink from "ui/navigation/SkipButtonLink";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import { CREDIT_COMPARISON_SIGNUP_SOURCE } from "../constants";
import { useSignupSource } from "../queries/leadQueryHooks";

import OnboardingCard from "./OnboardingCard";

type Props = {
  className?: string;
};

const OnboardingConnectStoresCard: FC<Props> = ({ className }) => {
  const { segmentTrack } = useSegment();

  const requireStoreConnectionInOnboarding = useFeatureFlag(
    "REQUIRE_STORE_CONNECTION_IN_ONBOARDING"
  );

  const signupSource = useSignupSource();
  const isSignupSourceCreditComparison = signupSource === CREDIT_COMPARISON_SIGNUP_SOURCE;
  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());

  const hasConnectedShopifyOrAmazon =
    connectedStores.filter(
      (integration) => integration.type === "Shopify" || integration.type === "Amazon"
    ).length > 0;

  const to = isSignupSourceCreditComparison ? CREDIT_COMPARISON_PATH : "/onboarding/get-started";

  const [searchParams] = useSearchParams();
  const allowSkip = Boolean(searchParams.get("allowSkip")) || !requireStoreConnectionInOnboarding;

  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header>
        <OnboardingCard.Heading1>Connect your stores</OnboardingCard.Heading1>
        <OnboardingCard.Subheading>
          Highbeam uses data from your stores to provide tailored banking services and real-time
          insights to manage cash flow.
        </OnboardingCard.Subheading>
      </OnboardingCard.Header>

      <OnboardingCard.Body>
        <ConnectStores redirectPathKey="onboarding" />
      </OnboardingCard.Body>

      <OnboardingCard.Footer>
        {allowSkip && (
          <SkipButtonLink
            to={to}
            disabled={hasConnectedShopifyOrAmazon}
            type="submit"
            onClick={() => {
              segmentTrack(SEGMENT_EVENTS.ONBOARDING_STORES_SKIPPED);
            }}
          >
            Skip this step
          </SkipButtonLink>
        )}

        <ButtonLinkWithTooltip
          to={to}
          variant="primary"
          disabled={!hasConnectedShopifyOrAmazon}
          type="submit"
          tooltip={
            !hasConnectedShopifyOrAmazon &&
            !isSignupSourceCreditComparison &&
            "Please connect at least 1 store to continue."
          }
        >
          {isSignupSourceCreditComparison ? "Compare offers" : "Next"}
        </ButtonLinkWithTooltip>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingConnectStoresCard;
