import React from "react";
import { Paragraph } from "ui/typography";

import ExchangeProfileDatum from "./ExchangeProfileDatum";

type Props = {
  label: string;
  value: string;
  description?: string;
};

const ExchangeProfileTimeDatum: React.FC<Props> = ({ label, value, description }) => {
  const tooltip = description && <Paragraph className="text-xs">{description}</Paragraph>;
  return <ExchangeProfileDatum label={label} value={value} tooltip={tooltip} />;
};

export default ExchangeProfileTimeDatum;
