import useBusiness from "modules/business/queries/useBusiness";
import getBusinessSafeDisplayName from "modules/business/utils/getBusinessSafeDisplayName";
import useConfigureChatMutation from "modules/chat/mutations/useConfigureChatMutation";
import React, { useCallback } from "react";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import { Paragraph, Span } from "ui/typography";

const ConfigureChatConfig: React.FC = () => {
  const business = useBusiness();

  const businessDisplayName = getBusinessSafeDisplayName(business);

  const { mutate, isPending } = useConfigureChatMutation({
    onSuccess: () => {
      notify("success", "Success!");
    },
    onError: () => {
      notify("error", "Something went wrong, please try again!");
    },
  });

  const onClick = useCallback(() => {
    if (isPending) return;
    mutate();
  }, [isPending, mutate]);

  return (
    <div className="flex flex-col gap-4">
      <Paragraph className="text-md">
        AI Chat is not configured for <Span className="font-bold">{businessDisplayName}</Span>.
      </Paragraph>
      <Button variant="primary" className="self-start" isLoading={isPending} onClick={onClick}>
        Configure
      </Button>
    </div>
  );
};

export default ConfigureChatConfig;
