import BillPaymentPayloadForm from "modules/bills/components/BillPaymentPayloadForm";
import BillPaymentSentView from "modules/bills/components/BillPaymentSentView";
import useRefreshBillsQueries from "modules/bills/queries/useRefreshBillsQueries";
import { FC, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import PaymentRep from "reps/payments-v2/PaymentRep";
import Pill from "ui/data-display/Pill";
import { notify } from "ui/feedback/Toast";
import FullPageModal from "ui/overlay/FullPageModal";
import { Heading2 } from "ui/typography";
import IncorrectDataReceivedError from "utils/react-query/IncorrectDataReceivedError";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

type BillPaymentPageContentProps = {
  billIds: string[];
  onCancel: () => void;
};

const BillPaymentPageContent: FC<BillPaymentPageContentProps> = ({ billIds, onCancel }) => {
  const billId = billIds[0];
  const refreshBillsQueries = useRefreshBillsQueries({
    type: "all",
  });

  const [sentPayment, setSentPayment] = useState<PaymentRep.Complete | null>(null);

  // NB(lev): Wait for there to be sent payment set before invalidating the bills query. This is important because we want to unmount the BillPaymentPayloadForm
  // first, because otherwise it may see a view of its bill that is no longer ready for payment (i.e. since it's now been paid), which will lead it to raise
  // an error.
  useEffect(() => {
    if (sentPayment) {
      refreshBillsQueries();
    }
  }, [sentPayment, refreshBillsQueries]);

  return (
    <div className="flex h-full flex-col">
      <FullPageModal.Header>
        <div className="flex flex-col gap-y-2 tablet:flex-row tablet:items-center tablet:gap-x-4">
          <Heading2>Pay bill</Heading2>
          <div className="flex items-center gap-x-2 tablet:gap-x-4">
            <Pill color="grey-100">1 bill</Pill>
          </div>
        </div>
      </FullPageModal.Header>

      {sentPayment ? (
        <BillPaymentSentView billId={billId} payment={sentPayment} />
      ) : (
        <BillPaymentPayloadForm
          billId={billId}
          onPaymentSent={setSentPayment}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

type ErrorFallbackProps = {
  billIds: string[];
  error: unknown;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ billIds, error }) => {
  const navigate = useNavigate();
  const isRequiredButNotFoundError = error instanceof RequiredButNotFoundError;
  const isIncorrectDataReceivedError = error instanceof IncorrectDataReceivedError;

  useEffect(() => {
    if (isRequiredButNotFoundError) {
      notify("error", "Bill not found.");
      navigate("/bills");
    } else if (isIncorrectDataReceivedError) {
      navigate(`/bills/${billIds[0]}`);
    }
  }, [error, billIds, navigate, isRequiredButNotFoundError, isIncorrectDataReceivedError]);

  if (isRequiredButNotFoundError || isIncorrectDataReceivedError) {
    return null;
  }

  throw error;
};

type Props = BillPaymentPageContentProps;

const BillPaymentPage: FC<Props> = ({ billIds, onCancel }) => {
  if (billIds.length !== 1) {
    // NB(alex): Temporary. Will remove this constraint once we support paying multiple bills.
    throw new Error(`billIds.length should be exactly 1, but it was ${billIds.length}.`);
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <ErrorFallback billIds={billIds} error={error} />}
    >
      <BillPaymentPageContent billIds={billIds} onCancel={onCancel} />
    </ErrorBoundary>
  );
};

export default BillPaymentPage;
