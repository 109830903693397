import useConnectedStoresQueryOptions from "modules/connected-stores/queries/useConnectedStoresQueryOptions";
import { ConnectedStoreType } from "modules/connected-stores/types";
import ConnectRutter from "pages/settings/SettingsPage/Integrations/ConnectRutter";
import ConnectShopify from "pages/settings/SettingsPage/Integrations/ConnectShopify";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { getRutterPlatformByConnectedStoreType } from "utils/rutter";

type Props = {
  platform: ConnectedStoreType;
  redirectPathKey: string;
  children: (props: { openModal: () => void }) => React.ReactNode;
};

const ConnectStoreButtonContainer: React.FC<Props> = ({ platform, children, redirectPathKey }) => {
  const refreshConnectedStoresQuery = useRefreshQuery(useConnectedStoresQueryOptions().queryKey);

  switch (platform) {
    case "Shopify":
      return (
        <ConnectShopify redirectPathKey={redirectPathKey}>
          {({ openModal }) => children({ openModal })}
        </ConnectShopify>
      );
    case "Stripe":
    case "PayPal":
    case "Amazon":
      return (
        <ConnectRutter
          platform={getRutterPlatformByConnectedStoreType(platform)}
          onConnect={async (_, callbacks) => {
            await refreshConnectedStoresQuery();
            callbacks.closeModal();
          }}
        >
          {({ openModal }) => children({ openModal })}
        </ConnectRutter>
      );
  }
};

export default ConnectStoreButtonContainer;
