import React from "react";

import Pill, { PillColor } from "../Pill";

export type FeatureStatus = "New" | "Beta" | "Free preview";

type Props = {
  featureStatus: FeatureStatus;
  color?: PillColor;
  className?: string;
};

const FeatureStatusBadge: React.FC<Props> = ({
  featureStatus,
  color = "purple-100",
  className,
}) => (
  <Pill size="2xs" color={color} className={className}>
    {featureStatus}
  </Pill>
);

export default FeatureStatusBadge;
