import { FC, Suspense } from "react";
import Card from "ui/data-display/Card";
import SectionLoading from "ui/feedback/SectionLoading";
import { Paragraph } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const ApSettingsSection: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Suspense fallback={<SectionLoading />}>
    <div
      className={cn(
        "grid grid-cols-1 gap-x-14 gap-y-6 border-t border-grey-100 py-6 first-of-type:border-t-0 first-of-type:pt-0 @4xl:grid-cols-2",
        className
      )}
    >
      {children}
    </div>
  </Suspense>
);

const ApSettingsSectionHeader: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div className={cn("col-span-full flex flex-col gap-1", className)}>{children}</div>
);

const ApSettingsSectionBody: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div className={cn("flex flex-col gap-6", className)}>{children}</div>
);

const ApSettingsCard: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Card
    className={cn("flex items-center justify-between gap-5 border border-grey-200 p-6", className)}
    shadow="xs"
  >
    {children}
  </Card>
);

const ApSettingsCardBody: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div className={cn("flex flex-col gap-2", className)}>{children}</div>
);

const MainText: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Paragraph className={cn("text-sm font-medium text-grey-800", className)}>{children}</Paragraph>
);

const SecondaryText: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <Paragraph className={cn("text-sm text-grey-600", className)}>{children}</Paragraph>
);

export {
  ApSettingsSection,
  ApSettingsSectionHeader,
  ApSettingsSectionBody,
  ApSettingsCard,
  ApSettingsCardBody,
  MainText,
  SecondaryText,
};
