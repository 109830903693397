import { PlusCircle } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import emptyExternalBankAccounts from "assets/empty-external-bank-accounts.svg";
import { useMilestoneActionItems } from "modules/action-items/hooks/useMilestoneActionItems";
import { GetStartedSetupGuideStep, Milestone } from "modules/action-items/types";
import PlaidConnectionCard from "modules/plaid/components/connection-cards/PlaidConnectionCard";
import PlaidConnectionCardContents from "modules/plaid/components/connection-cards/PlaidConnectionCardContents";
import PlaidNewConnectionCardContents from "modules/plaid/components/connection-cards/PlaidNewConnectionCardContents";
import PlaidPendingConnectionCardContents, {
  PlaidPendingConnection,
} from "modules/plaid/components/connection-cards/PlaidPendingConnectionCardContents";
import compareConnections from "modules/plaid/components/PlaidConnectionsList/compareConnections";
import PlaidLinkModal from "modules/plaid/components/PlaidLinkModal";
import plaidAccountsQueryHooks from "modules/plaid/queries/plaidAccountsQueryHooks";
import {
  usePlaidConnections,
  useRefreshPlaidConnectionsQueries,
} from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { FC, useCallback, useMemo, useState } from "react";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useSetRecoilState } from "recoil";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import duplicatePlaidConnectionGuidState from "state/plaid/duplicateConnectionGuid";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import EmptyCardsList from "ui/data-display/EmptyCardsList";
import { notify } from "ui/feedback/Toast";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import { HighbeamApiError } from "utils/ajax";
import { HighbeamPlaidLinkOnSuccess } from "utils/customHooks/useHighbeamPlaidLink";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";

type NoPlaidConnectionsDisplayProps = {
  openPlaidLinkModal: () => void;
};

const NoPlaidConnectionsDisplay: FC<NoPlaidConnectionsDisplayProps> = ({ openPlaidLinkModal }) => {
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  return (
    <>
      <EmptyCardsList>
        <img src={emptyExternalBankAccounts} alt="No connected bank accounts" />
        <ButtonWithTooltip
          disabled={!isAllowedToConnectBankAccounts}
          variant="secondary"
          onClick={openPlaidLinkModal}
          tooltip={
            !isAllowedToConnectBankAccounts && "You don’t have permission to connect bank accounts."
          }
        >
          <PlusCircle />
          Connect an account
        </ButtonWithTooltip>
      </EmptyCardsList>
    </>
  );
};

type PlaidLinkModalState = null | {
  existingConnection?: PlaidConnectionRep.Complete;
};

const showConnectionSuccessToast = (
  metadata: PlaidLinkOnSuccessMetadata,
  existingConnectionBeingUpdated?: PlaidConnectionRep.Complete
) => {
  const name = metadata.institution?.name ?? "external";
  if (existingConnectionBeingUpdated) {
    notify("success", `Your ${name} account is connected 🎉.`);
  } else {
    notify("success", `Your ${name} account has been updated 🎉.`);
  }
};

const PlaidConnectionsList: FC = () => {
  const plaidConnections = usePlaidConnections();
  const [plaidLinkModalState, setPlaidLinkModalState] = useState<PlaidLinkModalState>(null);
  const [pendingConnection, setPendingConnection] = useState<PlaidPendingConnection | null>(null);
  const connectionsIncludingPending = useMemo(() => {
    if (!pendingConnection) {
      return plaidConnections.sort(compareConnections);
    }
    // If a pending connection exists which is modifying an existing one, exclude that existing connection
    // in favor of the pending version.
    const unmodifiedConnections = plaidConnections.filter(
      (connection) => connection.guid !== pendingConnection.modifyingExistingConnection?.guid
    );
    return [...unmodifiedConnections, pendingConnection].sort(compareConnections);
  }, [plaidConnections, pendingConnection]);

  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQueries();
  const refreshPlaidAccountsQuery = plaidAccountsQueryHooks.useRefreshQueries();

  const setIsPlaidExistingAccountModalOpen = useSetRecoilState(
    isPlaidExistingAccountModalOpenState
  );
  const setDuplicatePlaidConnectionGuid = useSetRecoilState(duplicatePlaidConnectionGuidState);
  const { finishItemByStep } = useMilestoneActionItems(Milestone.GetStarted);
  const { segmentTrack } = useSegment();
  const onLinkSuccess: HighbeamPlaidLinkOnSuccess = useCallback(
    (
      metadata: PlaidLinkOnSuccessMetadata,
      finalizationPromise: Promise<void>,
      existingConnectionBeingUpdated?: PlaidConnectionRep.Complete
    ) => {
      setPendingConnection({
        linkSuccessMetadata: metadata,
        modifyingExistingConnection: existingConnectionBeingUpdated,
      });
      finalizationPromise
        .then(() => {
          refreshPlaidConnectionsQuery();
          refreshPlaidAccountsQuery();
          finishItemByStep(GetStartedSetupGuideStep.ConnectBank, "Complete");
          showConnectionSuccessToast(metadata, existingConnectionBeingUpdated);
        })
        .catch((e: Error) => {
          // TODO move PlaidConnectionExistsModal from global router into this component,
          //   and use state to launch it here. -ayoon
          if (e instanceof HighbeamApiError && e.error === "PlaidConnectionAlreadyExists") {
            setDuplicatePlaidConnectionGuid(e.properties.connectionGuid);
            setIsPlaidExistingAccountModalOpen(true);
            segmentTrack(SEGMENT_EVENTS.PLAID_LINK_DUPLICATE, metadata);
            return;
          } else {
            captureException(e);
          }

          notify("error", "We were unable to connect the account.");
          segmentTrack(SEGMENT_EVENTS.PLAID_LINK_FAILED, metadata);
        })
        .finally(() => setPendingConnection(null));
    },
    [
      finishItemByStep,
      refreshPlaidAccountsQuery,
      refreshPlaidConnectionsQuery,
      segmentTrack,
      setDuplicatePlaidConnectionGuid,
      setIsPlaidExistingAccountModalOpen,
    ]
  );

  return (
    <>
      {plaidLinkModalState && (
        <PlaidLinkModal
          onClose={() => setPlaidLinkModalState(null)}
          existingConnection={plaidLinkModalState.existingConnection}
          onLinkSuccess={onLinkSuccess}
        />
      )}
      <div className="@container">
        {connectionsIncludingPending.length === 0 ? (
          <NoPlaidConnectionsDisplay openPlaidLinkModal={() => setPlaidLinkModalState({})} />
        ) : (
          <div className="grid gap-6 @xl:max-w-screen-mobile-phone @xl:grid-cols-2 @4xl:max-w-screen-large-desktop @4xl:grid-cols-3">
            {connectionsIncludingPending.map((connection) => {
              if ("guid" in connection) {
                return (
                  <PlaidConnectionCard key={connection.guid}>
                    <PlaidConnectionCardContents
                      connection={connection}
                      openPlaidLinkModal={() =>
                        setPlaidLinkModalState({ existingConnection: connection })
                      }
                    />
                  </PlaidConnectionCard>
                );
              } else {
                return (
                  <PlaidConnectionCard key={"PENDING"}>
                    <PlaidPendingConnectionCardContents pendingConnection={connection} />
                  </PlaidConnectionCard>
                );
              }
            })}

            <PlaidConnectionCard className="bg-grey-50">
              <PlaidNewConnectionCardContents
                openPlaidLinkModal={() => setPlaidLinkModalState({})}
              />
            </PlaidConnectionCard>
          </div>
        )}
      </div>
    </>
  );
};

export default PlaidConnectionsList;
