import { useQuery } from "@tanstack/react-query";
import env from "env";
import useIntercomMetadataQueryOptions from "modules/intercom-metadata/queries/useIntercomMetadataQueryOptions";
import { useEffect } from "react";

const IntercomChat = () => {
  const { data: metadata } = useQuery({
    ...useIntercomMetadataQueryOptions(),
    throwOnError: true,
  });

  useEffect(() => {
    if (!metadata) return;

    const intercomSettings = {
      /* eslint-disable camelcase */
      api_base: env.INTERCOM_API_ORIGIN,
      app_id: env.INTERCOM_APP_ID,
      user_hash: env.INTERCOM_HASH_ENABLED ? metadata?.hash : undefined,
      ...metadata.metadata,
    };
    /* eslint-enable camelcase */

    Intercom("boot", intercomSettings);

    return () => {
      Intercom("shutdown");
    };
  }, [metadata]);

  return null;
};

export default IntercomChat;
