import useCancelRecurringPaymentMutation from "modules/unit-co-recurring-payments/mutations/useCancelRecurringPaymentMutation";
import useUnitCoRecurringPayment from "modules/unit-co-recurring-payments/queries/useUnitCoRecurringPayment";
import isRecurring from "modules/unit-co-recurring-payments/utils/isRecurring";
import colors from "styles/colors";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import { formatDate } from "utils/date";

import { useRecurringPaymentFlexpaneContext } from "../../context/RecurringPaymentFlexpaneProvider";
import RecurringPaymentInfoFlexpaneAmountSection from "../../RecurringPaymentInfoFlexpaneAmountSection/RecurringPaymentInfoFlexpaneAmountSection";
import RecurringPaymentInfoFlexpaneToFromSection from "../../RecurringPaymentInfoFlexpaneToFromSection";

import styles from "./CancelRecurringPaymentModal.module.scss";

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

const CancelRecurringPaymentModal: React.FC<Props> = ({ onClose, onSuccess }) => {
  const { recurringPaymentId } = useRecurringPaymentFlexpaneContext();
  const recurringPayment = useUnitCoRecurringPayment(recurringPaymentId, { required: true });

  const cancelTitle = `Cancel ${
    isRecurring(recurringPayment) ? "recurring payments" : "scheduled payment"
  }`;

  const { mutateAsync: cancelRecurringPayment, isPending } = useCancelRecurringPaymentMutation();

  const scheduledDate = formatDate(
    recurringPayment.attributes.schedule.nextScheduledAction
  ).replace(",", "");

  return (
    <Modal
      title={`${cancelTitle}?`}
      buttonText={cancelTitle}
      buttonVariant="danger"
      isLoading={isPending}
      showCancel
      onClick={async () => {
        await cancelRecurringPayment({ recurringPaymentId: recurringPayment.id });
        onSuccess();
      }}
      onClose={onClose}
    >
      <div>
        <RecurringPaymentInfoFlexpaneToFromSection />

        <div className={styles.amount}>
          <RecurringPaymentInfoFlexpaneAmountSection />
        </div>

        <Text size={16} color={colors.grey[600]}>
          {isRecurring(recurringPayment) ? (
            <>
              This will cancel all future instances of this payment. Any payments that have already
              been sent or initiated can not be reversed.
            </>
          ) : (
            <>Canceling this payment will prevent it from being initiated on {scheduledDate}.</>
          )}
        </Text>
      </div>
    </Modal>
  );
};

export default CancelRecurringPaymentModal;
