import { ErrorBoundary } from "@sentry/react";
import Transactions from "components/Transactions";
import RecurringPaymentInfoFlexpane from "dialogs/RecurringPaymentInfoFlexpane";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import usePayee from "modules/payees/queries/usePayee";
import usePayeeRecurringPayments from "modules/payees/queries/usePayeeRecurringPayments";
import RecurringPaymentsTable from "pages/payments/PaymentsOverviewPage/RecurringPayments/RecurringPaymentsTable";
import { FC, Suspense, useEffect } from "react";
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Navigate, useParams } from "react-router-dom";
import Shimmer from "ui/feedback/Shimmer";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

import PayeeDetailsHeader from "./PayeeDetailsHeader";
import PayeeDetailsInfo from "./PayeeDetailsInfo";
import { usePayeeTransactions } from "./transaction-utils";

const PayeeDetailsBreadcrumbItem = () => {
  const { payeeGuid } = useParams();
  const payee = usePayee(payeeGuid!, { required: true });
  return <Breadcrumbs.CurrentItem>{payee.name}</Breadcrumbs.CurrentItem>;
};

const ErrorFallback: FC<FallbackProps & { onRequiredButNotFoundError: () => void }> = ({
  error,
  resetErrorBoundary,
  onRequiredButNotFoundError,
}) => {
  useEffect(() => {
    resetErrorBoundary();
    if (error instanceof RequiredButNotFoundError) {
      onRequiredButNotFoundError();
    }
  }, [error, resetErrorBoundary, onRequiredButNotFoundError]);
  return null;
};

const PayeeDetailsContent = () => {
  const { payeeGuid } = useParams();
  const [recurringPaymentId, setRecurringPaymentId] = useSearchParamValue("recurringPaymentId");
  const payeeTransactions = usePayeeTransactions(payeeGuid!);
  const payeeRecurringPayments = usePayeeRecurringPayments({ payeeGuid: payeeGuid! });

  return (
    <>
      <ReactErrorBoundary
        fallbackRender={(props) => (
          <ErrorFallback {...props} onRequiredButNotFoundError={() => setRecurringPaymentId("")} />
        )}
      >
        <RecurringPaymentInfoFlexpane
          recurringPaymentId={recurringPaymentId}
          onClose={() => setRecurringPaymentId("")}
        />
      </ReactErrorBoundary>

      <Suspense fallback={<Shimmer total={2} />}>
        <PayeeDetailsHeader />
      </Suspense>

      <DashboardPage.Section>
        <Suspense
          fallback={<Shimmer total={2} />} // TODO(alex): `DashboardPage.Section` should have a default fallback
        >
          <PayeeDetailsInfo />
        </Suspense>
      </DashboardPage.Section>

      {payeeRecurringPayments.length > 0 && (
        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              Scheduled payments
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <RecurringPaymentsTable
            isLoading={false}
            recurringPayments={payeeRecurringPayments}
            onRowClick={(recurringPayment) => {
              setRecurringPaymentId(recurringPayment.id);
            }}
          />
        </DashboardPage.Section>
      )}

      <DashboardPage.Section>
        <DashboardPage.Section.Header>
          <DashboardPage.Section.HeaderHeading>Transactions</DashboardPage.Section.HeaderHeading>
        </DashboardPage.Section.Header>

        <DashboardPage.Section.Body>
          <Transactions
            {...payeeTransactions}
            hideBalanceColumn
            // NB(alex): These unnecessary props are currently required but will be deleted soon.
            activeTab="completed"
            setActiveTab={() => {}}
            tabs={[{ id: "completed", label: "Completed" }]}
          />
        </DashboardPage.Section.Body>
      </DashboardPage.Section>
    </>
  );
};

const PayeeDetailsPage = () => {
  return (
    <ErrorBoundary fallback={<Navigate to="/payments/payees" />}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/payments">Payments</Breadcrumbs.Item>
          <Breadcrumbs.Item to="/payments/payees">Payees</Breadcrumbs.Item>
          <Suspense fallback={<Breadcrumbs.CurrentItemShimmer />}>
            <PayeeDetailsBreadcrumbItem />
          </Suspense>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <PayeeDetailsContent />
      </DashboardPage>
    </ErrorBoundary>
  );
};

export default PayeeDetailsPage;
