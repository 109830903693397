import {
  Plus as PlusIcon,
  Pencil as PencilIcon,
  ChatTeardropText as ChatTeardropTextIcon,
  Check as CheckIcon,
  ArrowLineUpRight as ArrowLineUpRightIcon,
  ListDashes as ListDashesIcon,
} from "@phosphor-icons/react";
import businessMembersQueryHooks from "modules/business-members/queries/businessMembersQueryHooks";
import getBusinessMemberByBusinessMemberGuid from "modules/business-members/utils/getBusinessMemberByBusinessMemberGuid";
import { FC, ReactNode, Suspense } from "react";
import AuditLogUser from "reps/AuditLogUser";
import BillAuditLogRep from "reps/BillAuditLogRep";
import LogList from "ui/data-display/LogList";
import { formatEventTime } from "utils/date";
import { toTitleCase } from "utils/string";

import useBillAuditLogs from "../../queries/useBillAuditLogs";

import styles from "./BillAuditTrail.module.scss";

type BillAuditLogIconProps = {
  billAuditLog: BillAuditLogRep.Complete;
};

const BillAuditLogIcon: FC<BillAuditLogIconProps> = ({ billAuditLog }) => {
  const eventType = billAuditLog.contents.type;

  if (eventType === "BillCreated") return <PlusIcon />;
  if (eventType === "BillEdited") return <PencilIcon />;
  if (eventType === "BillSaved") return <PlusIcon />;
  if (eventType === "ApprovalRequested") return <ChatTeardropTextIcon />;
  if (eventType === "ApprovalGranted") return <CheckIcon />;
  if (eventType === "BillPaid") return <ArrowLineUpRightIcon />;
  if (
    eventType === "LineItemAdded" ||
    eventType === "LineItemEdited" ||
    eventType === "LineItemRemoved"
  ) {
    return <ListDashesIcon />;
  }

  // Unsupported event type.
  return null;
};

type BusinessMemberAuditLogUserTextContentProps = {
  businessMemberGuid: string;
};

const BusinessMemberAuditLogUserTextContent: FC<BusinessMemberAuditLogUserTextContentProps> = ({
  businessMemberGuid,
}) => {
  const businessMembers = businessMembersQueryHooks.useData({});
  const businessMember = getBusinessMemberByBusinessMemberGuid(businessMembers, businessMemberGuid);

  return businessMember ? <>{businessMember.displayName}</> : <>Deactivated member</>;
};

type AuditLogUserTextContentProps = {
  auditLogUser: AuditLogUser;
};

const AuditLogUserTextContent: FC<AuditLogUserTextContentProps> = ({ auditLogUser }) => {
  const { type } = auditLogUser;

  if (type === "BusinessMemberAuditLogUser") {
    return (
      <BusinessMemberAuditLogUserTextContent businessMemberGuid={auditLogUser.businessMemberGuid} />
    );
  }

  if (type === "SupportAuditLogUser") return <>Highbeam support</>;
  if (type === "ServiceAuditLogUser") return <>Highbeam</>;

  throw new Error(`Unsupported audit log user type: ${type}`);
};

type ChangeValueProps = {
  value: string;
};

const ChangeValue: FC<ChangeValueProps> = ({ value }) => (
  <LogList.ItemTextBold>&ldquo;{value}&rdquo;</LogList.ItemTextBold>
);

type ChangeDescriptionProps = {
  field: string;
  fieldLabel?: string;
  oldValue: string | null;
  newValue: string | null;
  performer: ReactNode;
};

const ChangeDescription: FC<ChangeDescriptionProps> = ({
  field,
  fieldLabel,
  oldValue,
  newValue,
  performer,
}) => {
  const startContent = `${fieldLabel ? `${fieldLabel} ` : ""}${fieldLabel ? field : toTitleCase(field)}`;
  const endContent = <>by {performer}</>;

  if (oldValue && newValue) {
    return (
      <>
        {startContent} changed from <ChangeValue value={oldValue} /> to{" "}
        <ChangeValue value={newValue} /> {endContent}
      </>
    );
  }

  if (newValue) {
    return (
      <>
        {startContent} set to <ChangeValue value={newValue} /> {endContent}
      </>
    );
  }

  return (
    <>
      {startContent} {oldValue && <ChangeValue value={oldValue} />} removed {endContent}
    </>
  );
};

type BillAuditLogContentProps = {
  billAuditLog: BillAuditLogRep.Complete;
};

const BillAuditLogTextContent: FC<BillAuditLogContentProps> = ({ billAuditLog }) => {
  const { contents, performedBy } = billAuditLog;
  const eventType = contents.type;

  const performer = (
    <LogList.ItemTextBold>
      <AuditLogUserTextContent auditLogUser={performedBy} />
    </LogList.ItemTextBold>
  );

  if (eventType === "BillCreated") {
    return <>Bill created by {performer}</>;
  }

  if (eventType === "BillEdited") {
    return (
      <ChangeDescription
        field={contents.field}
        oldValue={contents.oldValue}
        newValue={contents.newValue}
        performer={performer}
      />
    );
  }

  if (eventType === "BillSaved") {
    return <>Bill saved by {performer}</>;
  }

  if (eventType === "ApprovalRequested") {
    return (
      <>
        Approval requested from{" "}
        <LogList.ItemTextBold>
          <AuditLogUserTextContent auditLogUser={contents.approver} />
        </LogList.ItemTextBold>{" "}
        by {performer}
      </>
    );
  }

  if (eventType === "ApprovalGranted") {
    return <>Bill approved by {performer}</>;
  }

  if (eventType === "BillPaid") {
    return (
      <>
        Payment of {contents.amount} sent by {performer}
      </>
    );
  }

  if (eventType === "LineItemAdded") {
    return <>Line item added by {performer}</>;
  }

  if (eventType === "LineItemEdited") {
    return (
      <ChangeDescription
        field={contents.field}
        fieldLabel="Line item"
        oldValue={contents.oldValue}
        newValue={contents.newValue}
        performer={performer}
      />
    );
  }

  if (eventType === "LineItemRemoved") {
    return <>Line item removed by {performer}</>;
  }

  // Unsupported event type.
  return <>Bill event</>;
};

type BillAuditLogListProps = {
  billId: string;
};

const BillAuditLogList: FC<BillAuditLogListProps> = ({ billId }) => {
  const billAuditLogs = useBillAuditLogs(billId);

  return (
    <LogList className={styles.billAuditLogListContainer}>
      {billAuditLogs.map((billAuditLog) => (
        <LogList.Item key={billAuditLog.id} icon={<BillAuditLogIcon billAuditLog={billAuditLog} />}>
          <LogList.ItemText>
            <BillAuditLogTextContent billAuditLog={billAuditLog} />
          </LogList.ItemText>
          <LogList.ItemSubtext>{formatEventTime(billAuditLog.eventTime)}</LogList.ItemSubtext>
        </LogList.Item>
      ))}
    </LogList>
  );
};

type Props = {
  billId: string;
};

const BillAuditTrail: FC<Props> = ({ billId }) => {
  return (
    <div className={styles.container}>
      <Suspense fallback={<LogList.Loading className={styles.billAuditLogListContainer} />}>
        <BillAuditLogList billId={billId} />
      </Suspense>
    </div>
  );
};

export default BillAuditTrail;
