import { Card } from "@highbeam/unit-node-sdk";
import { Archive, Cards, Plus, PlusCircle } from "@phosphor-icons/react";
import HighbeamCard from "components/HighbeamCard";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import CardApproachingLimitBanners from "modules/cards/components/CardApproachingLimitBanners";
import CardPlaceholder, { EmptyCardPlaceholder } from "modules/cards/components/CardPlaceholder";
import CardTabs, { useCardTabsSearchParamState } from "modules/cards/components/CardTabs";
import InactivePhysicalCard from "modules/cards/components/InactivePhysicalCard";
import CreateCardModal from "modules/cards/dialogs/CreateCardModal";
import useCreateCardModalState from "modules/cards/hooks/useCreateCardModalState";
import cardsQueryHooks from "modules/cards/queries/cardsQueryHooks";
import {
  checkIsCreditCard,
  checkIsDebitCard,
  checkIsPhysicalCard,
  checkIsVirtualCard,
} from "modules/cards/utils/typeguards";
import { useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Divider from "ui/data-display/Divider";
import CardShimmer from "ui/feedback/CardShimmer";
import Shimmer from "ui/feedback/ShimmerV2";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { Heading3, Paragraph, Span } from "ui/typography";
import useHasPermission from "utils/permissions/useHasPermission";
import useIsAllowedToCreateCards from "utils/permissions/useIsAllowedToCreateCards";
import { pluralize } from "utils/string";

type LocationState = {
  openVirtualCard: boolean;
};

const NotAllowedToCreateCardsTooltipContent = () => {
  return <Paragraph>You don’t have permission to create cards.</Paragraph>;
};

const ViewArchivedCards = () => {
  return <Paragraph>View archived cards</Paragraph>;
};

export const cardsSectionClasses = "flex gap-5 flex-wrap";

export const CardsSectionSuspenseFallback = () => (
  <div className={cardsSectionClasses}>
    <CardShimmer />
    <CardShimmer />
  </div>
);

export const cardFilterByTab = (activeTab: string, card: Card) => {
  if (activeTab === "Highbeam") return checkIsCreditCard(card);
  if (activeTab === "Debit") return checkIsDebitCard(card);
  return card;
};

const CardsPageContent = () => {
  const [activeTab, setActiveTab] = useCardTabsSearchParamState();
  const isFilteringCards = activeTab !== "All";

  const { createCardModalState, openCreateCardModal, closeCreateCardModal } =
    useCreateCardModalState();

  const { data: allCards = [], isLoading } = cardsQueryHooks.useQuery({ status: "default" });
  const cards = allCards.filter((card) => cardFilterByTab(activeTab, card));
  const virtualCards = cards.filter(checkIsVirtualCard);
  const physicalCards = cards.filter(checkIsPhysicalCard);

  const isAllowedToCreateCards = useIsAllowedToCreateCards();
  const canReadAnyOrCreateCards = useHasPermission(["card:readAny", "card:create"]);
  const hasChargeCards = allCards.some((card) => checkIsCreditCard(card));

  // NB(alex): It might be cleaner to open this with a query param but we don't currently have a great pattern for serializing json data for modal state in query params.
  const locationState = useLocation().state as LocationState | undefined;
  const navigate = useNavigate();
  useEffect(() => {
    if (!createCardModalState.isOpen && locationState?.openVirtualCard) {
      openCreateCardModal();
      navigate("", { state: { openVirtualCard: false } });
    }
  }, [locationState, openCreateCardModal, createCardModalState.isOpen, navigate]);

  return (
    <>
      {createCardModalState.isOpen && (
        <CreateCardModal
          defaultValues={createCardModalState.defaultValues}
          onClose={closeCreateCardModal}
        />
      )}

      <DashboardPage.Header
        actions={
          <>
            <ButtonWithTooltip
              onClick={() => {
                openCreateCardModal();
              }}
              variant="secondary"
              disabled={!isAllowedToCreateCards}
              tooltip={!isAllowedToCreateCards && <NotAllowedToCreateCardsTooltipContent />}
            >
              <PlusCircle size={20} weight="light" />
              Create card
            </ButtonWithTooltip>

            <ButtonLinkWithTooltip
              to="/cards/archived"
              tooltip={<ViewArchivedCards />}
              paddingVariant="square"
              variant="tertiary"
            >
              <Archive size={20} />
            </ButtonLinkWithTooltip>
          </>
        }
      >
        <DashboardPage.Header.IconTile icon={<Cards />} />
        <DashboardPage.Header.Title>Cards</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section className="p-5 pb-20 tablet:p-0">
        {hasChargeCards && (
          <CardTabs className="mb-8 mt-3 w-max" activeTab={activeTab} setActiveTab={setActiveTab} />
        )}

        <CardApproachingLimitBanners cards={cards} />

        <div className="mb-6 flex items-center gap-x-5">
          <Heading3>Virtual cards</Heading3>
          {isLoading ? (
            <Shimmer className="h-3 w-32" />
          ) : (
            <Span className="text-sm">{pluralize(virtualCards.length, "card")}</Span>
          )}
        </div>

        {isLoading && <CardsSectionSuspenseFallback />}

        {!isLoading && (
          <DashboardPage.Section.Body
            className={cardsSectionClasses}
            suspenseFallback={<CardsSectionSuspenseFallback />}
          >
            {virtualCards.map((card) => (
              <Link key={card.id} to={`/cards/${card.id}`}>
                <HighbeamCard card={card} clickable />
              </Link>
            ))}

            {!canReadAnyOrCreateCards && virtualCards.length === 0 && (
              <EmptyCardPlaceholder
                heading="No virtual cards found"
                subheading="To create a card please contact an admin."
              />
            )}

            {virtualCards.length === 0 && (
              <CardPlaceholder
                ctaIcon={Plus}
                primaryText={isFilteringCards ? "No cards found" : "Create a virtual card"}
                secondaryText={
                  isFilteringCards ? undefined : "Create virtual cards for all your online needs."
                }
                ctaText="Create virtual card"
                ctaOnClick={() => {
                  openCreateCardModal({
                    defaultValues: {
                      cardVirtualOrPhysical: "virtual",
                    },
                  });
                }}
                onPromptClick={(prompt) => {
                  if (!isAllowedToCreateCards) {
                    return;
                  }
                  openCreateCardModal({
                    defaultValues: {
                      cardName: prompt,
                    },
                  });
                }}
                prompts={["Marketing card", "Software card", "Vendor card", "Meal card"]}
                disablePrimaryAction={!isAllowedToCreateCards}
                disabledPrimaryText={<NotAllowedToCreateCardsTooltipContent />}
              />
            )}
          </DashboardPage.Section.Body>
        )}

        <Divider />

        <div className="mb-6 flex items-center gap-x-5">
          <Heading3>Physical cards</Heading3>
          {isLoading ? (
            <Shimmer className="h-3 w-32" />
          ) : (
            <Span className="text-sm">{pluralize(physicalCards.length, "card")}</Span>
          )}
        </div>

        {isLoading && <CardsSectionSuspenseFallback />}

        {!isLoading && (
          <DashboardPage.Section.Body
            className={cardsSectionClasses}
            suspenseFallback={<CardsSectionSuspenseFallback />}
          >
            {physicalCards.map((card) =>
              card.attributes.status === "Inactive" ? (
                <InactivePhysicalCard key={card.id} card={card} />
              ) : (
                <Link key={card.id} to={`/cards/${card.id}`}>
                  <HighbeamCard card={card} clickable />
                </Link>
              )
            )}

            {!canReadAnyOrCreateCards && physicalCards.length === 0 && (
              <EmptyCardPlaceholder
                heading="No physical cards found"
                subheading="To create a card please contact an admin."
              />
            )}

            {physicalCards.length === 0 && (
              <CardPlaceholder
                ctaIcon={Plus}
                primaryText={isFilteringCards ? "No cards found" : "Create a physical card"}
                secondaryText={isFilteringCards ? undefined : "We will ship you a physical card."}
                ctaText="Create physical card"
                ctaOnClick={() => {
                  openCreateCardModal({
                    defaultValues: {
                      cardVirtualOrPhysical: "physical",
                    },
                  });
                }}
                disablePrimaryAction={!isAllowedToCreateCards}
                disabledPrimaryText={<NotAllowedToCreateCardsTooltipContent />}
              />
            )}
          </DashboardPage.Section.Body>
        )}
      </DashboardPage.Section>
    </>
  );
};

const CardsPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Cards</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CardsPageContent />
      </DashboardPage>
    </>
  );
};

export default CardsPage;
