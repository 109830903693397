import { useChatWidget } from "components/ChatWidget";
import OnboardingLayout from "layouts/OnboardingLayout";
import { useIsCurrentBusinessMemberOnboarded } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusiness from "modules/business/queries/useBusiness";
import ArchivedCustomerCard from "modules/onboarding/components/ArchivedCustomerCard";
import OnboardingBankApplicationRejectedCard from "modules/onboarding/components/OnboardingBankApplicationRejectedCard";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useSegment from "utils/customHooks/useSegment";

import useInsightsApp from "../../modules/insights-app/queries/useInsightsApp";

import AuthenticatedRouterErrorBoundary from "./AuthenticatedRouterErrorBoundary";
import InsightsAppRouter from "./InsightsAppRouter";
import NonOnboardedRouter from "./NonOnboardedRouter";
import OnboardedRouter from "./OnboardedRouter";

const AuthenticatedRouter = () => {
  const location = useLocation();
  const { segmentPage } = useSegment();
  const chat = useChatWidget();

  const business = useBusiness();

  const isBusinessRejected = business.status === "Rejected";
  const isBusinessArchived = business.status === "Archived";

  useEffect(() => {
    chat.refresh();
    segmentPage();
  }, [location, segmentPage, chat]);

  if (isBusinessRejected) {
    return (
      <AuthenticatedRouterErrorBoundary>
        <OnboardingLayout>
          <OnboardingBankApplicationRejectedCard />
        </OnboardingLayout>
      </AuthenticatedRouterErrorBoundary>
    );
  }

  if (isBusinessArchived) {
    return (
      <AuthenticatedRouterErrorBoundary>
        <OnboardingLayout>
          <ArchivedCustomerCard />
        </OnboardingLayout>
      </AuthenticatedRouterErrorBoundary>
    );
  }

  return (
    <AuthenticatedRouterErrorBoundary>
      <SubRouter />
    </AuthenticatedRouterErrorBoundary>
  );
};

export default AuthenticatedRouter;

const SubRouter: React.FC = () => {
  const isInsightsApp = useInsightsApp();
  const isOnboarded = useIsCurrentBusinessMemberOnboarded();

  if (isInsightsApp) return <InsightsAppRouter />;
  if (!isOnboarded) return <NonOnboardedRouter />;
  return <OnboardedRouter />;
};
