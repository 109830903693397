import { BANK_ACCOUNT_TYPE } from "modules/accounting-accounts/utils/config";
import { FC, ComponentPropsWithoutRef, useCallback, useState } from "react";
import AccountingAccountRep from "reps/AccountingAccountRep";
import BankAccountRep from "reps/BankAccountRep";

import useAccountingAccounts from "../../queries/useAccountingAccounts";
import makeAccountingAccountDisplayName from "../../utils/makeAccountingAccountDisplayName";
import AccountingAccountBar from "../AccountingAccountBar";
import AccountingCategorySelect, { ItemRenderProps } from "../AccountingCategorySelect";

type Props = Omit<
  ComponentPropsWithoutRef<typeof AccountingCategorySelect>,
  "filter" | "renderItem" | "clearable" | "searchValue" | "onSearchValueChange"
> & {
  bankAccount: BankAccountRep.Complete;
};

const BankAccountChartOfAccountSelect: FC<Props> = ({ bankAccount, ...props }) => {
  const filter = useCallback(
    (accountingAccount: AccountingAccountRep.Complete) =>
      accountingAccount.accountType === BANK_ACCOUNT_TYPE,
    []
  );

  const renderItem = useCallback(
    ({ accountingAccount }: ItemRenderProps) => {
      const { id, name, bankAccountGuid, status, nominalCode } = accountingAccount;
      const isActive = status === "active";
      const isAssociatedWithDifferentBankAccount =
        bankAccountGuid !== null && bankAccountGuid !== bankAccount.guid && isActive;

      const searchableText = nominalCode
        ? `${makeAccountingAccountDisplayName(accountingAccount)} (${nominalCode})`
        : name;

      return (
        <AccountingCategorySelect.Item
          key={id}
          value={id}
          searchableText={searchableText}
          disabled={isAssociatedWithDifferentBankAccount || !isActive}
        >
          <AccountingAccountBar accountingAccount={accountingAccount}>
            {nominalCode && <AccountingAccountBar.NominalCode nominalCode={nominalCode} />}
            <span>
              {name}
              {isAssociatedWithDifferentBankAccount && " (associated with another account)"}
            </span>
          </AccountingAccountBar>
        </AccountingCategorySelect.Item>
      );
    },
    [bankAccount]
  );

  const accountingAccounts = useAccountingAccounts();

  const [searchValue, setSearchValue] = useState(() => {
    // As a little nicety, we-prefill the search value if there is an accounting account that
    // looks like it matches the bank account name or the last 4 digits of the bank account number.
    // Note that is done only once on the initial render (which is ok for our purposes
    // because the bank account and accounting accounts don't change over the lifetime of this
    // component).

    const { name, accountNumber } = bankAccount;
    if (accountingAccounts.find((accountingAccount) => accountingAccount.name.includes(name))) {
      return name;
    }

    const accountNumberEndingDigits = accountNumber.slice(-4);
    if (
      accountingAccounts.find((accountingAccount) =>
        accountingAccount.name.includes(accountNumberEndingDigits)
      )
    ) {
      return accountNumberEndingDigits;
    }

    return "";
  });

  return (
    <AccountingCategorySelect
      filter={filter}
      renderItem={renderItem}
      clearable
      searchValue={searchValue}
      onSearchValueChange={setSearchValue}
      {...props}
    />
  );
};

export default BankAccountChartOfAccountSelect;
