import BankAccountsDropdown from "modules/bank-accounts/components/BankAccountsDropdown";
import { usePaymentEnabledBankAccounts } from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import { useController } from "react-hook-form";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Helper from "ui/inputs/Helper";

import { useDrawdownAmountFormContext } from "../context/DrawdownProvider";

const DrawdownToBankAccountDropdown = () => {
  const transferOptions = usePaymentEnabledBankAccounts();
  const { control } = useDrawdownAmountFormContext();

  const { field } = useController({
    control: control,
    name: "drawdownToBankAccount",
  });

  return (
    <>
      <BankAccountsDropdown
        disabled={transferOptions.length === 1}
        id="to"
        label="To"
        isSearchable={false}
        options={transferOptions}
        {...field}
      />

      {field.value && (
        <Helper>
          Account balance: <MoneyAmount weight="medium" cents={field.value.availableBalance} />
        </Helper>
      )}
    </>
  );
};

export default DrawdownToBankAccountDropdown;
