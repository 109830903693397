import {
  CalendarBlank as CalendarBlankIcon,
  Check as CheckIcon,
  XCircle as XCircleIcon,
  ArrowRight as ArrowRightIcon,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import BankAccountChartOfAccountSelect from "modules/accounting-accounts/components/BankAccountChartOfAccountSelect";
import { useOptimisticSetBankAccountForChartOfAccountMutation } from "modules/accounting-accounts/mutations/useSetBankAccountOnAccountingAccountMutation";
import useAccountingAccounts from "modules/accounting-accounts/queries/useAccountingAccounts";
import useActiveAccountingPlatformConnection from "modules/accounting-platforms/queries/useActiveAccountingPlatformConnection";
import useRequiredActiveAccountingPlatformConnection from "modules/accounting-platforms/queries/useRequiredActiveAccountingPlatformConnection";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import { usePaymentEnabledBankAccounts } from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import { useAllBills } from "modules/bills/queries/useBills";
import { FC, useState } from "react";
import BankAccountRep from "reps/BankAccountRep";
import EmptyState from "ui/data-display/EmptyState";
import Pill from "ui/data-display/Pill";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import SwitchWithLabel from "ui/inputs/SwitchWithLabel";
import { Heading3 } from "ui/typography";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import { formatDate } from "utils/date";
import useHasPermission from "utils/permissions/useHasPermission";
import { getRutterIcon } from "utils/rutter";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";
import cn from "utils/tailwind/cn";

import { useOptimisticUpdateApSettingsMutation } from "../../mutations/useUpdateApSettingsMutation";
import { useApSettings } from "../../queries/apSettingsQueryHooks";
import ConfirmEnableAccountingSyncModal, {
  makeDefaultCloseBooksDate,
} from "../ConfirmEnableAccountingSyncModal";
import {
  ApSettingsSection,
  ApSettingsSectionHeader,
  ApSettingsSectionBody,
  ApSettingsCard,
  ApSettingsCardBody,
  MainText,
  SecondaryText,
} from "../layout-primitives";
import UpdateBooksClosedDateModal from "../UpdateBooksClosedDateModal";

const useCanUpdateApSettings = () => useHasPermission("accountsPayableSettings:write");

const useCanConnectAccountingPlatform = () => useHasPermission("storeConnection:create");

const AccountingConnectionModule: FC = () => {
  const accountingPlatformConnection = useActiveAccountingPlatformConnection();
  const canConnectAccountingPlatform = useCanConnectAccountingPlatform();

  if (accountingPlatformConnection) {
    const { platformName } = accountingPlatformConnection;
    const accountingSoftwareName = getAccountingSoftwareName(platformName);

    return (
      <ApSettingsCard>
        <ApSettingsCardBody>
          <div className="flex items-center gap-3">
            <img src={getRutterIcon(platformName)} alt={`${accountingSoftwareName} logo`} />
            <MainText>{accountingSoftwareName}</MainText>
            <Pill
              size="2xs"
              color="green-100"
              iconLeft={({ sizeClassName }) => <CheckIcon className={sizeClassName} />}
            >
              Connected
            </Pill>
          </div>
        </ApSettingsCardBody>
      </ApSettingsCard>
    );
  }

  return (
    <ApSettingsCard>
      <ApSettingsCardBody>
        <div className="flex items-center gap-2">
          <XCircleIcon className="size-4 text-grey-600" />

          <MainText>No accounting software connected</MainText>
        </div>
      </ApSettingsCardBody>
      <div>
        <ButtonLinkWithTooltip
          to="/settings/accounting-software"
          variant="tertiary"
          disabled={!canConnectAccountingPlatform}
          tooltip={
            !canConnectAccountingPlatform &&
            "You do not have permission to connect accounting software."
          }
        >
          Connect
        </ButtonLinkWithTooltip>
      </div>
    </ApSettingsCard>
  );
};

const AccountingSyncEnabledModule: FC = () => {
  const { segmentTrack } = useSegment();

  const {
    id: apSettingsId,
    accountingSyncEnabled,
    accountingPlatformCloseBooksDate,
  } = useApSettings();
  const accountingPlatformConnection = useActiveAccountingPlatformConnection();
  const canUpdateApSettings = useCanUpdateApSettings();

  const [isConfirmEnableAccountingSyncModalOpen, setIsConfirmEnableAccountingSyncModalOpen] =
    useState(false);

  const { mutate: updateApSettings } = useOptimisticUpdateApSettingsMutation(apSettingsId, {
    onSuccess: (data) => {
      const enabled = data.accountingSyncEnabled;
      notify("success", `Accounting sync turned ${enabled ? "on" : "off"}`);
      segmentTrack(
        enabled
          ? SEGMENT_EVENTS.BILL_PAY_ACCOUNTING_SYNC_ENABLED
          : SEGMENT_EVENTS.BILL_PAY_ACCOUNTING_SYNC_DISABLED
      );
    },
  });

  // Query to see if there are non-draft bills. If there are, we will show the `ConfirmEnableAccountingSyncModal` modal
  // if the user switches the accounting sync toggle on (ahead of actually enabling accounting sync).
  // If there are no non-draft bills, we will enable accounting sync immediately.
  const allBills = useAllBills();
  const allBillsCount = allBills.length;

  return (
    <>
      <SwitchWithLabel
        label="Sync bills to accounting"
        value={accountingSyncEnabled}
        onChange={(value) => {
          if (value) {
            if (allBillsCount > 0) {
              setIsConfirmEnableAccountingSyncModalOpen(true);
            } else {
              updateApSettings({
                accountingSyncEnabled: true,
                closeBooksDate:
                  accountingPlatformCloseBooksDate ||
                  makeDefaultCloseBooksDate().format("YYYY-MM-DD"),
              });
            }
          } else {
            updateApSettings({ accountingSyncEnabled: false });
          }
        }}
        disabled={!(canUpdateApSettings && accountingPlatformConnection)}
        className="pt-3"
      />

      {isConfirmEnableAccountingSyncModalOpen && (
        <ConfirmEnableAccountingSyncModal
          onClose={() => setIsConfirmEnableAccountingSyncModalOpen(false)}
          onSuccess={() => setIsConfirmEnableAccountingSyncModalOpen(false)}
        />
      )}
    </>
  );
};

const ConsolidateLineItemsModule: FC = () => {
  const { id: apSettingsId, consolidateLineItems } = useApSettings();
  const canUpdateApSettings = useCanUpdateApSettings();

  const { mutate: updateApSettings } = useOptimisticUpdateApSettingsMutation(apSettingsId, {
    onSuccess: (data) => {
      notify(
        "success",
        `Combined line items for each bill turned ${data.consolidateLineItems ? "on" : "off"}`
      );
    },
  });

  return (
    <div className="flex flex-col gap-3">
      <SwitchWithLabel
        label="Combine line items for each bill"
        value={consolidateLineItems}
        onChange={(value) => updateApSettings({ consolidateLineItems: value })}
        disabled={!canUpdateApSettings}
        className="pt-3"
      />
      <SecondaryText>
        Turn this on to assign a single accounting category to each bill instead of assigning one to
        every line item.
      </SecondaryText>
    </div>
  );
};

const BooksClosedDateModule: FC = () => {
  const { closeBooksDate, accountingPlatformCloseBooksDate } = useApSettings();
  const [isUpdateBooksClosedDateModalOpen, setIsUpdateBooksClosedDateModalOpen] = useState(false);
  const canUpdateApSettings = useCanUpdateApSettings();
  const accountingPlatformConnection = useRequiredActiveAccountingPlatformConnection();
  const accountingPlatformName = accountingPlatformConnection.platformName;
  const accountingSoftwareName = getAccountingSoftwareName(accountingPlatformName);

  const isClosedBooksDateSameAsAccountingPlatform =
    closeBooksDate &&
    accountingPlatformCloseBooksDate &&
    dayjs(closeBooksDate).isSame(accountingPlatformCloseBooksDate, "day");

  return (
    <>
      <ApSettingsCard>
        <ApSettingsCardBody>
          <Heading3 className="flex items-center gap-2.5 text-sm font-regular text-grey-600">
            <span>Books closed date</span>
            {isClosedBooksDateSameAsAccountingPlatform && (
              <>
                <span>•</span>
                <img
                  src={getRutterIcon(accountingPlatformName)}
                  alt={`${accountingSoftwareName} logo`}
                />
                <strong className="font-medium">Synced from {accountingSoftwareName}</strong>
              </>
            )}
          </Heading3>

          <MainText className="flex items-center gap-2">
            <CalendarBlankIcon className="size-4 text-grey-600" />
            <span>{closeBooksDate ? formatDate(closeBooksDate) : <>No date selected</>}</span>
          </MainText>

          <SecondaryText>
            You cannot edit any bookkeeping related details on bills with invoice dates on or before
            the books closed date. You can still make payments on the bills.
          </SecondaryText>
        </ApSettingsCardBody>
        <div>
          <Button
            disabled={!canUpdateApSettings}
            onClick={() => setIsUpdateBooksClosedDateModalOpen(true)}
          >
            {closeBooksDate ? <>Edit</> : <>Select date</>}
          </Button>
        </div>
      </ApSettingsCard>

      {isUpdateBooksClosedDateModalOpen && (
        <UpdateBooksClosedDateModal
          onClose={() => setIsUpdateBooksClosedDateModalOpen(false)}
          onSuccess={() => setIsUpdateBooksClosedDateModalOpen(false)}
        />
      )}
    </>
  );
};

const BankAccountCategorizationNoBankAccounts: FC = () => (
  <EmptyState
    variant="inset-card"
    className="w-full"
    body={
      <EmptyState.PrimaryText>
        You don&apos;t have any eligible bank accounts
      </EmptyState.PrimaryText>
    }
    footer={<EmptyState.LinkCTA to="/accounts/manage-accounts">Manage accounts</EmptyState.LinkCTA>}
  />
);

type BankAccountCategorizationFieldProps = {
  bankAccount: BankAccountRep.Complete;
};

const BankAccountCategorizationField: FC<BankAccountCategorizationFieldProps> = ({
  bankAccount,
}) => {
  const bankAccountGuid = bankAccount.guid;
  const accountingAccounts = useAccountingAccounts();

  const { mutate: setBankAccountForChartOfAccount } =
    useOptimisticSetBankAccountForChartOfAccountMutation({
      onSuccess: () => {
        notify("success", "Accounting category updated");
      },
      onError: (error) => {
        notify("error", error.message ?? "Failed to update accounting category");
      },
    });

  const currentAccountingAccountId =
    accountingAccounts.find(
      (accountingAccount) => accountingAccount.bankAccountGuid === bankAccountGuid
    )?.id ?? null;

  return (
    <BankAccountChartOfAccountSelect
      value={currentAccountingAccountId}
      onValueChange={(value) => {
        if (value) {
          setBankAccountForChartOfAccount({
            bankAccountGuid,
            accountingAccountId: value,
          });
        } else if (currentAccountingAccountId) {
          // Clear the current accounting account.
          setBankAccountForChartOfAccount({
            bankAccountGuid: null,
            accountingAccountId: currentAccountingAccountId,
          });
        }
      }}
      bankAccount={bankAccount}
      variant="minimal"
      noAccountsDisplayVariant="not-available"
      placeholder="Select accounting category"
    />
  );
};

const CATEGORIZATION_TABLE_TR_CLASSES = cn(
  "border-b border-grey-100 first-of-type:border-t-0 last-of-type:border-b-0"
);

const CATEGORIZATION_TABLE_TD_CLASSES = cn("px-10 py-5 first-of-type:pl-0 last-of-type:pr-0");

// NB(lev): This module currently assumes that the user has sufficient permissions for
// writing to accounting accounts, which is generally true in practice for users with
// access to the Bill Pay settings page. In reality, updating the accounting category
// bank account's will fail for users without the `accounting:write` permission.
// This is done this way for now for consistency sake with other places within Bill Pay
// where we currently expose a <BankAccountChartOfAccountSelect /> (e.g. in the bill
// payment flow).
const BankAccountsCategorizationModule: FC = () => {
  const bankAccounts = usePaymentEnabledBankAccounts();

  if (!bankAccounts.length) {
    return <BankAccountCategorizationNoBankAccounts />;
  }

  return (
    <table className="w-full">
      <tbody>
        {bankAccounts.map((bankAccount) => (
          <tr key={bankAccount.guid} className={CATEGORIZATION_TABLE_TR_CLASSES}>
            <td className={CATEGORIZATION_TABLE_TD_CLASSES}>
              <BankAccountBar bankAccount={bankAccount} className="whitespace-nowrap" />
            </td>
            <td aria-hidden="true" className={cn(CATEGORIZATION_TABLE_TD_CLASSES, "w-5 px-0")}>
              <ArrowRightIcon className="size-5 text-grey-500" />
            </td>
            <td className={cn(CATEGORIZATION_TABLE_TD_CLASSES, "min-w-80")}>
              <BankAccountCategorizationField bankAccount={bankAccount} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const AccountingSyncSettings: FC = () => {
  const accountingPlatformConnection = useActiveAccountingPlatformConnection();
  const { accountingSyncEnabled } = useApSettings();
  const showEnabledOnlyModules = accountingPlatformConnection && accountingSyncEnabled;

  return (
    <>
      <ApSettingsSection>
        <ApSettingsSectionBody>
          <AccountingConnectionModule />
          <AccountingSyncEnabledModule />
          {showEnabledOnlyModules && (
            <>
              <ConsolidateLineItemsModule />
              <BooksClosedDateModule />
            </>
          )}
        </ApSettingsSectionBody>
      </ApSettingsSection>

      {showEnabledOnlyModules && (
        <ApSettingsSection>
          <ApSettingsSectionHeader>
            <Heading3>Categorize bank accounts</Heading3>
            <SecondaryText>
              In order to sync your bill payments to your accounting software, please assign an
              accounting category to each account.
            </SecondaryText>
          </ApSettingsSectionHeader>
          <ApSettingsSectionBody>
            <BankAccountsCategorizationModule />
          </ApSettingsSectionBody>
        </ApSettingsSection>
      )}
    </>
  );
};

export default AccountingSyncSettings;
