import { useQuery } from "@tanstack/react-query";
import ApySummary from "components/Accounts/InterestTier/ApySummary";
import DashboardPage from "layouts/DashboardPage";
import useBankAccountBalanceHistoryQueryOptions from "modules/bank-accounts/queries/useBankAccountBalanceHistoryQueryOptions";
import useBankAccountInterestYieldYtd from "modules/bank-accounts/queries/useBankAccountInterestYieldYtd";
import { Suspense, useEffect, useState } from "react";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SectionHeader from "ui/data-display/SectionHeader";
import DetailedAreaChart, { DetailedAreaChartDatum } from "ui/data-visualization/DetailedAreaChart";
import Tabs, { Tab } from "ui/navigation/Tabs";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";
import useIsAllowedToViewAccountNumbers from "utils/permissions/useIsAllowedToViewAccountNumbers";

import styles from "./AccountInformation.module.scss";
import DomesticAccountInformation from "./DomesticAccountInformation";
import InternationalAccountInformation from "./InternationalAccountInformation";

type Props = {
  account: BankAccountRep.Complete;
};

const TABS: Tab[] = [
  {
    id: "domestic",
    label: "Domestic",
  },
  {
    id: "international",
    label: "International",
  },
];

const AccountInformation: React.FC<Props> = ({ account }) => {
  const [activeTick, setActiveTick] = useState<DetailedAreaChartDatum | null>(null);
  const [isAnimationActive, setIsAnimationActive] = useState(true);
  const isMobile = useIsMobile();
  const isAllowedToViewAccountNumbers = useIsAllowedToViewAccountNumbers();
  const [selectedTab, setSelectedTab] = useState<string>(TABS[0].id);

  const { data: bankAccountBalanceHistory } = useQuery(
    useBankAccountBalanceHistoryQueryOptions(account.unitCoDepositAccountId)
  );

  const interest = useBankAccountInterestYieldYtd(account.guid);

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setIsAnimationActive(false);
    }, 1500);
    return () => clearTimeout(animationTimer);
  }, []);

  const earningInterest = interest > 0 || account.depositProduct.interestBps > 0;
  const shouldShowInterestSection = earningInterest;

  const balanceChartHeight = shouldShowInterestSection || isMobile ? 180 : 350;

  return (
    <DashboardPage.Section>
      <div className={styles.container}>
        <Suspense fallback={null}>
          {bankAccountBalanceHistory && (
            <div className={styles.column}>
              <SectionHeader>
                <Heading2>Account balance</Heading2>
              </SectionHeader>
              <div>
                <CashDisplay
                  color={activeTick ? colors.purple[500] : colors.black}
                  cents={
                    activeTick
                      ? activeTick.amount
                      : bankAccountBalanceHistory[bankAccountBalanceHistory.length - 1]?.amount || 0
                  }
                />
                <Text size={12}>as of {activeTick ? activeTick.date : "today"}</Text>
              </div>
              <DetailedAreaChart
                data={bankAccountBalanceHistory}
                ticks={bankAccountBalanceHistory
                  .map((datum) => datum.date)
                  .filter((_, i) => (bankAccountBalanceHistory.length - i - 1) % 10 === 0)}
                isAnimationActive={isAnimationActive}
                setActiveTick={setActiveTick}
                chartHeight={balanceChartHeight}
              />
              {shouldShowInterestSection && <ApySummary account={account} />}
            </div>
          )}
        </Suspense>
        <div className={styles.column}>
          <SectionHeader>
            <Heading2>Account information</Heading2>
          </SectionHeader>

          <Tabs
            tabs={TABS}
            activeTab={selectedTab}
            setActiveTab={setSelectedTab}
            variant="rounded"
          />
          {selectedTab === "domestic" && (
            <DomesticAccountInformation
              account={account}
              isAllowedToViewAccountNumbers={isAllowedToViewAccountNumbers}
            />
          )}
          {selectedTab === "international" && (
            <InternationalAccountInformation
              isPrimary={account.isPrimary}
              isAllowedToViewAccountNumbers={isAllowedToViewAccountNumbers}
            />
          )}
        </div>
      </div>
    </DashboardPage.Section>
  );
};

export default AccountInformation;
