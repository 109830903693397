import React from "react";
import { ChatElement } from "reps/chat/ChatElement";

import ResponseMessageBulletedList from "./ResponseMessageBulletedList";
import ResponseMessageDate from "./ResponseMessageDate";
import ResponseMessageLineChart from "./ResponseMessageLineChart";
import ResponseMessageMoney from "./ResponseMessageMoney";
import ResponseMessageMonth from "./ResponseMessageMonth";
import ResponseMessageNumber from "./ResponseMessageNumber";
import ResponseMessageNumberedList from "./ResponseMessageNumberedList";
import ResponseMessageParagraph from "./ResponseMessageParagraph";
import ResponseMessageTable from "./ResponseMessageTable";
import ResponseMessageText from "./ResponseMessageText";
import ResponseMessageTimestamp from "./ResponseMessageTimestamp";
import ResponseMessageYear from "./ResponseMessageYear";

type Props = {
  element: ChatElement;
};

const ResponseElement: React.FC<Props> = ({ element }) => {
  switch (element.type) {
    case "BulletedList":
      return <ResponseMessageBulletedList element={element} />;
    case "Date":
      return <ResponseMessageDate element={element} />;
    case "LineChart":
      return <ResponseMessageLineChart element={element} />;
    case "Money":
      return <ResponseMessageMoney element={element} />;
    case "Month":
      return <ResponseMessageMonth element={element} />;
    case "Number":
      return <ResponseMessageNumber element={element} />;
    case "NumberedList":
      return <ResponseMessageNumberedList element={element} />;
    case "Paragraph":
      return <ResponseMessageParagraph element={element} />;
    case "Table":
      return <ResponseMessageTable element={element} />;
    case "Text":
      return <ResponseMessageText element={element} />;
    case "Timestamp":
      return <ResponseMessageTimestamp element={element} />;
    case "Year":
      return <ResponseMessageYear element={element} />;
    default:
      return null;
  }
};

export default ResponseElement;
