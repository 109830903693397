import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Paragraph, Span } from "ui/typography";

export type Recipient = {
  name: string;
  email?: string;
  isEmailToggled: boolean;
};

type Props = {
  to: Recipient;
  infoText: string;
};

const PayeeInfo: React.FC<Props> = ({ to, infoText }) => (
  <div>
    <Span className="text-sm font-medium">{to.name}</Span>

    {to.isEmailToggled && to.email && (
      <div className="flex items-center gap-x-1.5">
        <Span className="text-grey-600">{to.email}</Span>
        <IconWithTooltip tooltip={<Paragraph>{infoText}</Paragraph>} color="light" />
      </div>
    )}
  </div>
);

export default PayeeInfo;
