import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { isNotNull } from "utils/array";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import convertToPaymentRequest from "../utils/convertToPaymentRequest";

export type PaymentApprovalStatus = "Open" | "Rejected";

export const LIST_PAYMENT_APPROVALS_QUERY_KEY = "listPaymentApprovals";

const usePaymentApprovalsQueryOptions = (status: PaymentApprovalStatus) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [LIST_PAYMENT_APPROVALS_QUERY_KEY, { businessGuid, status }],
    queryFn: async () => {
      return (await highbeamApi.paymentV2.list(businessGuid, status))
        .map(convertToPaymentRequest)
        .filter(isNotNull);
    },
  });
};

export default usePaymentApprovalsQueryOptions;
