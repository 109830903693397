import confettiGif from "assets/onboarding-confetti.gif";
import { FC, useEffect, useState } from "react";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";
import { Heading1, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

import useGetStartedMutation from "../mutations/useGetStartedMutation";

type Props = {
  className?: string;
};

const OnboardingGetStartedContent: FC<Props> = ({ className }) => {
  const [showConfetti, setShowConfetti] = useState(true);
  const { mutate: getStarted, isPending } = useGetStartedMutation({
    onSuccess: () => {
      window.location.href = "/"; // Intended to force a refresh.
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 1800);
    return () => clearTimeout(timer);
  });

  return (
    <div
      className={cn(
        "flex h-full min-h-[40rem] w-full flex-1 flex-col items-center bg-purple-50",
        className
      )}
    >
      {showConfetti ? (
        <img src={confettiGif} alt="confetti" className="size-[40rem]" />
      ) : (
        <div className="mt-32 flex w-full animate-slide-up-and-fade-in flex-col items-center p-8">
          <InfoIcon variant="success" size={70} />
          <div className="mb-8 mt-2 flex flex-col gap-y-4">
            <Heading1 className="text-center text-2xl font-bold">
              Your banking just got a lot more exciting!
            </Heading1>
            <Paragraph className="text-center text-md">We’re glad to have you on board.</Paragraph>
          </div>
          <Button variant="primary" isLoading={isPending} onClick={() => getStarted()}>
            Take me to Highbeam
          </Button>
        </div>
      )}
    </div>
  );
};

export default OnboardingGetStartedContent;
