import { ArrowClockwise, ArrowsClockwise } from "@phosphor-icons/react";
import useInsightsSyncMutation from "pages/CashFlowPage/hooks/useInsightsSyncMutation";
import { useRef, useState } from "react";
import RadioCard from "ui/inputs/RadioCard";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import ModalV4, { ModalRef } from "ui/overlay/ModalV4";
import variants from "utils/ts/variants";

import useCashFlowSyncSheetMutation from "../../hooks/useCashFlowSyncSheetMutation";

type Props = {
  onClose: () => void;
};

const CashFlowSyncSheetModal = ({ onClose }: Props) => {
  const modalRef = useRef<ModalRef>(null);
  const { mutate: startCashFlowSheetSync, isPending: isStartCashFlowSheetSyncPending } =
    useCashFlowSyncSheetMutation();
  const { mutate: startInsightsSync, isPending: isStartInsightsSyncPending } =
    useInsightsSyncMutation();

  const basicSyncOption = "basic" as const;
  const options = [
    {
      value: basicSyncOption,
      label: "Basic sync",
      description: "Syncs vendor names and categories to sheet",
    },
    {
      value: "full" as const,
      label: "Full sync",
      description: "Syncs all data, will take longer",
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const isLoading = isStartCashFlowSheetSyncPending || isStartInsightsSyncPending;
  const onSubmit = () => {
    if (selectedOption.value === basicSyncOption) {
      startCashFlowSheetSync();
    } else {
      startInsightsSync();
    }
    modalRef.current?.closeModalWithAnimation();
  };

  return (
    <ModalV4 onClose={onClose} modalRef={modalRef}>
      <ModalV4.Header>Sync to Google sheet</ModalV4.Header>

      <ModalV4.Body>
        <RadioCardSelect
          options={options}
          value={selectedOption}
          onChange={(option) => {
            setSelectedOption(option);
          }}
        >
          {({ option, isSelected, onSelect }) => (
            <RadioCard
              icon={variants(option.value, {
                basic: <RadioCard.Icon Icon={ArrowClockwise} />,
                full: <RadioCard.Icon Icon={ArrowsClockwise} />,
              })}
              key={option.value}
              checked={isSelected}
              value={option.value}
              label={option.label}
              description={option.description}
              onChange={() => {
                onSelect(option);
              }}
            />
          )}
        </RadioCardSelect>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isLoading} onClick={onSubmit}>
          Sync
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton />
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default CashFlowSyncSheetModal;
