import AccountHeader from "modules/payment-switcher/components/AccountPayments/AccountHeader";
import React from "react";
import PaymentSwitcherAutoPaymentGroupRep from "reps/PaymentSwitcherAutoPaymentGroupRep";

import styles from "./AccountPayments.module.scss";
import AccountTable from "./AccountTable";

type Props = {
  group: PaymentSwitcherAutoPaymentGroupRep;
};

const AccountPayments: React.FC<Props> = ({ group }) => {
  return (
    <div className={styles.container}>
      <AccountHeader group={group} />
      <AccountTable group={group} />
    </div>
  );
};

export default AccountPayments;
