import inferBillLineItemCurrencyFromBill from "modules/bill-line-items/utils/inferBillLineItemCurrency";
import useBill from "modules/bills/queries/useBill";
import { FC } from "react";
import { Money } from "reps/Money";
import { Strong } from "ui/typography";
import { formatMoney } from "utils/money";

type Props = {
  billId: string;
};

const BillLineItemsTotal: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const { lineItemTotals } = bill;
  const value: Money = lineItemTotals || {
    amount: "0",
    currency: inferBillLineItemCurrencyFromBill(bill),
  };
  const formattedValue = formatMoney(value, {
    showCurrencySymbol: true,
    showTrailingCurrencyCode: true,
    dropZeroCents: false,
  });

  return <Strong className="text-md">{formattedValue}</Strong>;
};

export default BillLineItemsTotal;
