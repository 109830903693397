import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { CAPITAL_ACCOUNT_TRANSACTIONS_QUERY_KEY } from "./useRefreshCapitalAccountTransactions";

export type CapitalAccountTransactionsParams = {
  capitalAccountGuid: string;
  since: Dayjs;
  until: Dayjs;
};

export const useCapitalAccountTransactionsQueryOptions = ({
  capitalAccountGuid,
  since,
  until,
}: CapitalAccountTransactionsParams) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [
      CAPITAL_ACCOUNT_TRANSACTIONS_QUERY_KEY,
      {
        capitalAccountGuid,
        since: since.format("YYYY-MM-DD"),
        until: until.format("YYYY-MM-DD"),
      },
    ],
    queryFn: () => {
      return highbeamApi.capitalTransactions.get(businessGuid, capitalAccountGuid, since, until);
    },
  });
};

export const useCapitalAccountTransactionsQuery = (params: CapitalAccountTransactionsParams) => {
  return useQuery(useCapitalAccountTransactionsQueryOptions(params));
};

const useCapitalAccountTransactions = (params: CapitalAccountTransactionsParams) => {
  return useSuspenseQuery(useCapitalAccountTransactionsQueryOptions(params)).data;
};

export default useCapitalAccountTransactions;
