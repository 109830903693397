import HighbeamBaseApi from "api/HighbeamBaseApi";
import { ConversationRep, ConversationCreatorRep } from "reps/chat/ConversationRep";
import { ConversationSearch, conversationSearchQuery } from "reps/chat/ConversationSearch";

export default class ConversationApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(conversationId: string): Promise<ConversationRep> {
    const url = `/chat/conversations/${conversationId}`;
    return (await this.api.get<ConversationRep>(url))!;
  }

  async search(search: ConversationSearch): Promise<ConversationRep[]> {
    const url = `/chat/conversations?${conversationSearchQuery(search)}`;
    return (await this.api.get<ConversationRep[]>(url))!;
  }

  async create(creator: ConversationCreatorRep): Promise<ConversationRep> {
    const url = "/chat/conversations";
    return (await this.api.post<ConversationRep>(url, creator))!;
  }
}
