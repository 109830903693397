import useUser from "modules/user/queries/useUser";
import React from "react";
import { ExchangeUserMessage } from "reps/chat/MessageRep";
import UserAvatar from "ui/data-display/UserAvatar";
import { Paragraph } from "ui/typography";

type Props = {
  message: ExchangeUserMessage;
};

const UserMessage: React.FC<Props> = ({ message }) => {
  const user = useUser();

  return (
    <div className="flex flex-row gap-4">
      <UserAvatar
        className="flex-none"
        color="purple-light"
        initials={user?.initials ?? ""}
        size={32}
      />
      <Paragraph className="py-1.5 text-sm text-grey-600">{message.content}</Paragraph>
    </div>
  );
};

export default UserMessage;
