import useBusiness from "modules/business/queries/useBusiness";
import getBusinessSafeDisplayName from "modules/business/utils/getBusinessSafeDisplayName";
import useCanEnterChatDebugMode from "modules/chat/hook/useCanEnterChatDebugMode";
import useIsAllowedToNavigateToChatAdminRoutes from "modules/chat/hook/useIsAllowedToNavigateToChatAdminRoutes";
import useChatConfig from "modules/chat/queries/useChatConfig";
import chatDebugModeState from "modules/chat/state/chatDebugMode";
import React from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import SwitchWithLabel from "ui/inputs/SwitchWithLabel";
import { Paragraph, Span } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  showResetButton: boolean;
};

const ChatSettingsSection: React.FC<Props> = ({ showResetButton }) => {
  const business = useBusiness();
  const chatConfig = useChatConfig()!;

  const businessDisplayName = getBusinessSafeDisplayName(business);

  const canEnterChatDebugMode = useCanEnterChatDebugMode();
  const isAllowedToNavigateToChatAdminRoutes = useIsAllowedToNavigateToChatAdminRoutes();

  const [chatDebugMode, setChatDebugMode] = useRecoilState(chatDebugModeState);

  if (!canEnterChatDebugMode && !showResetButton) return null;

  return (
    <>
      {isAllowedToNavigateToChatAdminRoutes && (
        <div className="flex flex-none flex-col items-center border-b border-grey-200">
          <div className="flex w-full flex-row justify-center gap-8 px-8 py-2">
            <Paragraph className="text-sm">
              [SUPERUSER] AI Chat is currently{" "}
              <Span
                className={cn(
                  "font-bold",
                  chatConfig.isEnabled ? "text-green-600" : "text-yellow-700"
                )}
              >
                {chatConfig.isEnabled ? "Enabled" : "Disabled"}
              </Span>{" "}
              for <Span className="font-bold">{businessDisplayName}</Span>.{" "}
              {chatConfig.isEnabled
                ? "Users from the business will be able to use AI Chat."
                : "Only Highbeam superusers can use AI Chat."}{" "}
              <NavLink to="/chat/admin">Click here</NavLink> to configure AI Chat for this business.
            </Paragraph>
            <SwitchWithLabel
              className="shrink-0 text-grey-600"
              label="Debug mode"
              onChange={setChatDebugMode}
              value={chatDebugMode}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatSettingsSection;
