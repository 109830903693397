import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import PayeeRep from "reps/PayeeRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const PAYEES_QUERY_KEY = "payees";

export const useRefreshPayeesQueries = () => {
  return useRefreshQuery([PAYEES_QUERY_KEY]);
};

type Params = {
  status: PayeeRep.Status;
};

export const usePayeesQueryOptions = ({ status }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [PAYEES_QUERY_KEY, { businessGuid, status }],
    queryFn: () => highbeamApi.payee.getByBusiness(businessGuid, status),
  });
};

export const useActivePayeesQueryOptions = () => {
  return usePayeesQueryOptions({ status: "Active" });
};

export const useActivePayeesQuery = () => {
  return useQuery(useActivePayeesQueryOptions());
};

export const useActivePayees = () => {
  return useSuspenseQuery(useActivePayeesQueryOptions()).data;
};
