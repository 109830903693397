import { captureMessage } from "@sentry/react";
import { useCheckDepositFlexpaneContext } from "dialogs/CheckDepositFlexpane/context/CheckDepositFlexpaneProvider";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import bankAccountByUnitCoDepositAccountIdQueryHooks from "modules/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";

const CheckDepositFlexpaneBankAccountParty = () => {
  const { checkDeposit } = useCheckDepositFlexpaneContext();
  const bankAccount = bankAccountByUnitCoDepositAccountIdQueryHooks.useData({
    unitCoDepositAccountId: checkDeposit.relationships.account.data.id,
  });

  if (!bankAccount) {
    // TODO(alex): Required but not found error?
    captureMessage(`Bank account not found for check deposit ${checkDeposit.id}`);
    return null;
  }

  return <BankAccountBar bankAccount={bankAccount} />;
};

export default CheckDepositFlexpaneBankAccountParty;
