import OnboardingLayout from "layouts/OnboardingLayout";
import { useHasFilledOutBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import OnboardingConnectStoresCard from "modules/onboarding/components/OnboardingConnectStoresCard";
import { useReferralReceivedByBusiness } from "modules/user-referrals/queries/referralLinkQueryHooks";
import { Navigate } from "react-router-dom";

const OnboardingConnectStoresPage = () => {
  const hasFilledOutBankApplication = useHasFilledOutBankApplication();
  const referralReceivedByBusiness = useReferralReceivedByBusiness();

  // Let `OnboardingNavigate` determine where the user should go next if they haven't filled out their bank application yet.
  // We do not need to force a redirect if they received a referral.
  // We mostly do the banking application check for the Shopify app review process.
  if (!hasFilledOutBankApplication && !referralReceivedByBusiness) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <OnboardingLayout progressBarPercentage={90}>
      <OnboardingConnectStoresCard />
    </OnboardingLayout>
  );
};

export default OnboardingConnectStoresPage;
