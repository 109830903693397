import React, { ReactNode } from "react";
import cn from "utils/tailwind/cn";

import DebugMessageContent from "./DebugMessageContent";
import DebugMessageLabel from "./DebugMessageLabel";

type Props = {
  className?: string;
  children: ReactNode;
};

const DebugMessage: React.FC<Props> = ({ className, children }) => {
  return <div className={cn("flex flex-row gap-4", className)}>{children}</div>;
};

export default Object.assign(DebugMessage, {
  Content: DebugMessageContent,
  Label: DebugMessageLabel,
});
