import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import AssignedBillSummaryRep from "reps/AssignedBillSummaryRep";
import BillApprovalRep from "reps/BillApprovalRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

export const ASSIGNED_BILLS_QUERY_KEY = "assigned-bills";

type Params = {
  approvalStatus: BillApprovalRep.Status;
};

const assignedBillsQueryHooks = makeQueryHooks({
  rootName: BILLS_QUERY_KEY,
  name: ASSIGNED_BILLS_QUERY_KEY,
  useQueryVariables(params: Params) {
    return params;
  },
  useQueryFnMaker({ approvalStatus }) {
    const highbeamApi = useHighbeamApi();
    const businessGuid = useBusinessGuid();
    const currentBusinessMember = useCurrentBusinessMember();
    const businessMemberGuid = currentBusinessMember?.guid ?? null;
    return async () => {
      // When superusering, we don't have a business member guid, so we can't call this backend endpoint.
      if (!businessMemberGuid) {
        return [] as AssignedBillSummaryRep.Complete[];
      }
      return await highbeamApi.assignedBillSummary.searchByAssigned(
        businessGuid,
        businessMemberGuid,
        approvalStatus
      );
    };
  },
});

export default assignedBillsQueryHooks;

//
// Hooks
//

export const useAssignedRequestedBills = () =>
  assignedBillsQueryHooks.useData({ approvalStatus: "Requested" });

export const useAssignedGrantedBills = () =>
  assignedBillsQueryHooks.useData({ approvalStatus: "Granted" });
