import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { useCallback } from "react";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useBillSummaryApi from "../api/useBillSummaryApi";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

const filterBillsByState = (bills: BillSummaryRep.Complete[], states: BillRep.State[]) => {
  return bills.filter(({ state }) => states.includes(state));
};

const ALL_BILLS_STATES = [BillRep.State.Open, BillRep.State.Paid, BillRep.State.Closed];

export const useBillsQueryOptions = () => {
  const billSummaryApi = useBillSummaryApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [BILLS_QUERY_KEY, businessGuid],
    queryFn: () => {
      return billSummaryApi.search(businessGuid);
    },
  });
};

export const useBillsQuery = () => {
  return useQuery(useBillsQueryOptions());
};

export const useAllBillsQuery = (enabled = true) => {
  const select = useCallback(
    (data: BillSummaryRep.Complete[]) => filterBillsByState(data, ALL_BILLS_STATES),
    []
  );

  return useQuery({
    ...useBillsQueryOptions(),
    enabled,
    select,
  });
};

export const useAllBills = () => {
  const select = useCallback(
    (data: BillSummaryRep.Complete[]) => filterBillsByState(data, ALL_BILLS_STATES),
    []
  );

  const { data } = useSuspenseQuery({
    ...useBillsQueryOptions(),
    select,
  });
  return data;
};

export const useProcessingBills = () => {
  const select = useCallback(
    (data: BillSummaryRep.Complete[]) => filterBillsByState(data, [BillRep.State.Processing]),
    []
  );

  const { data } = useSuspenseQuery({
    ...useBillsQueryOptions(),
    select,
  });
  return data;
};

export const useDraftBillsQuery = (enabled = true) => {
  const select = useCallback(
    (data: BillSummaryRep.Complete[]) => filterBillsByState(data, [BillRep.State.Draft]),
    []
  );

  return useQuery({
    ...useBillsQueryOptions(),
    enabled,
    select,
  });
};

const useBills = () => {
  const { data } = useSuspenseQuery(useBillsQueryOptions());
  return data;
};

export default useBills;
