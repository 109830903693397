import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const LINE_OF_CREDIT_AGREEMENT_QUERY_KEY = "line-of-credit-agreement";

const useLineOfCreditAgreementQueryOptions = (lineOfCreditGuid: string | null) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [LINE_OF_CREDIT_AGREEMENT_QUERY_KEY, businessGuid, lineOfCreditGuid],
    queryFn: async () => {
      if (!lineOfCreditGuid) {
        return null;
      }
      return highbeamApi.lineOfCreditAgreement.getMetadata(businessGuid, lineOfCreditGuid);
    },
  });
};

export default useLineOfCreditAgreementQueryOptions;
