import moneyInOut from "assets/money-in-out.svg";
import BankAccountBar from "modules/bank-accounts/components/BankAccountBar";
import useCancelPaymentMutation from "modules/payment-approvals/mutations/useCancelPaymentMutation";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

import styles from "./CancelPaymentModal.module.scss";
import cancelPaymentModalState, { useCancelPaymentModal } from "./state/cancelPaymentModalState";

export type PaymentDirection = "Incoming" | "Outgoing";

type CancelSummaryMessageProps = {
  paymentDirection: PaymentDirection;
  counterpartyName: string;
  paymentAmountInCents: number;
  bankAccount: BankAccountRep.Complete;
};

const CancelSummaryMessage: FC<CancelSummaryMessageProps> = ({
  bankAccount,
  counterpartyName,
  paymentDirection,
  paymentAmountInCents,
}) => {
  if (paymentDirection === "Outgoing") {
    return (
      <>
        <div className={styles.transactionCancelCard}>
          <div className={styles.transactionCancelCardSummary}>
            <img src={moneyInOut} alt="money icon" />
            <Text size={16} color={colors.grey[600]}>
              <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> payment to{" "}
              <Text as="span" size={16} weight="medium" color={colors.grey[600]}>
                {counterpartyName}
              </Text>
            </Text>
          </div>
          <Pill color="grey-100" bordered>
            Pending
          </Pill>
        </div>
        <Text color={colors.grey[600]} size={16} weight="regular" className={styles.disclaimer}>
          After canceling, the funds will be returned to
          <BankAccountBar size={20} bankAccount={bankAccount} />
        </Text>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.transactionCancelCard}>
          <div className={styles.transactionCancelCardSummary}>
            <img src={moneyInOut} alt="money icon" />
            <Text size={16} color={colors.grey[600]}>
              <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> payment from{" "}
              <Text as="span" size={16} weight="medium" color={colors.grey[600]}>
                {counterpartyName}
              </Text>
            </Text>
          </div>
          <Pill color="grey-100" bordered>
            Pending
          </Pill>
        </div>
        <Text color={colors.grey[600]} size={16} weight="regular" className={styles.disclaimer}>
          After canceling, the funds will no longer be deposited into
          <BankAccountBar size={20} bankAccount={bankAccount} />
        </Text>
      </>
    );
  }
};

type Props = {
  onSuccess: () => void;
  paymentDirection: PaymentDirection;
};

const CancelPaymentModal: FC<Props> = ({ onSuccess, paymentDirection }) => {
  const { close: onClose } = useCancelPaymentModal();
  const { mutateAsync: cancelPayment, isPending } = useCancelPaymentMutation();

  const state = useRecoilValue(cancelPaymentModalState);

  // NB(lev): Callers of <CancelPaymentModal> should not actually render the component
  // unless the modal is open. This conditional is here to satiate the type checker.
  if (!state.isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form
        onSubmit={async () => {
          await cancelPayment({ paymentGuid: state.paymentGuid });
          onSuccess();
        }}
      >
        <ModalV4.Header>Cancel payment?</ModalV4.Header>

        <ModalV4.Body>
          <CancelSummaryMessage
            bankAccount={state.account}
            counterpartyName={state.counterpartyName}
            paymentDirection={paymentDirection}
            paymentAmountInCents={state.paymentAmountInCents}
          />
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.SubmitButton variant="danger" isLoading={isPending}>
            Cancel payment
          </ModalV4.SubmitButton>
          <ModalV4.CloseButton onClick={onClose}>Close</ModalV4.CloseButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default CancelPaymentModal;
