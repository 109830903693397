import ExchangeProfileTimeDatum from "pages/accounts/ChatPage/ChatConversationSection/Exchange/ExchangeProfileSnippet/ExchangeProfileTimeDatum";
import React from "react";
import { ExchangeProfile } from "reps/chat/ExchangeProfile";
import { Paragraph } from "ui/typography";

type Props = {
  profile: ExchangeProfile | undefined;
};

const ExchangeProfileSnippet: React.FC<Props> = ({ profile }) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg border border-grey-200 p-2">
      <Paragraph className="text-sm font-medium">
        {profile ? "Debug info..." : "Debug info unavailable."}
      </Paragraph>
      {profile && (
        <>
          <div className="grid grid-cols-4 gap-2">
            <ExchangeProfileTimeDatum
              label="Total time"
              value={profile.endToEndTime}
              description="The total time from request to response."
            />
            <ExchangeProfileTimeDatum
              label="OpenAI"
              value={`${profile.numberOfLlmRequests} requests (${profile.timeSpentWaitingForLlm})`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ExchangeProfileSnippet;
